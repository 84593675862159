import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import {
    EnableAgentlessAwsAccount,
    GetAgentlessAwsOnboardingData,
    CheckForUpdate,
    GetHubs,
    CheckAzureAccount,
    GetAzureOnboardingData,
    EnableAzureSubAccount,
    GetWorkloadAgentessAccount,
    UpdateWorkloadAgentlessAccountSettings,
    DeleteWorkloadAgentessAccount,
    GetAwsAzureAgentlessAccountsStates,
    GetAwsAzureServerlessAccountsStates,
    RequestScan,
    EnableAwpAzureAccount,
    GetAwsAzureServerlessFunctionsGet,
    GetAwsOnboardingData,
    EnableAwsSubAccount,
    EnableHubAccount,
    GetAzureOffboarding,
    GetAgentlessSettings,
    UpdateAgentlessSettings,
    UpdateCentralizedWorkloafAgentlessAccountSettings
} from './awp.interface';
import { Vendors } from 'common/consts/vendors';
import { IFRAME_TAGS } from 'common/interface/IFrame.message.model';

const getWorkloadAgentessAccount: GetWorkloadAgentessAccount.Function = async (cloudAccountId, vendor) => {
    return await getHttpService().request<AxiosResponse<GetWorkloadAgentessAccount.Response>>(
        `workload/agentless/${vendor}/accounts/${cloudAccountId}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const updateCentralizedWorkloafAgentlessAccountSettings: UpdateCentralizedWorkloafAgentlessAccountSettings.Function = async (cloudAccountId, vendor, request) => {
    return await getHttpService().request<AxiosResponse<UpdateWorkloadAgentlessAccountSettings.Response>>(
        `workload/agentless/${vendor}/accounts/${cloudAccountId}/centralizedAccountSettings`,
        { method: 'PATCH', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
}; 

const updateWorkloadAgentessAccountSettings: UpdateWorkloadAgentlessAccountSettings.Function = async (cloudAccountId, vendor, request) => {
    return await getHttpService().request<AxiosResponse<UpdateWorkloadAgentlessAccountSettings.Response>>(
        `workload/agentless/${vendor}/accounts/${cloudAccountId}/settings`,
        { method: 'PATCH', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const deleteWorkloadAgentessAccount: DeleteWorkloadAgentessAccount.Function = async (cloudAccountId, vendor, forceDelete = false) => {
    return await getHttpService().request<AxiosResponse<DeleteWorkloadAgentessAccount.Response>>(
        `workload/agentless/${vendor}/accounts/${cloudAccountId}?forceDelete=${forceDelete}`,
        { method: 'DELETE' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const enableAgentlessAwsAccount: EnableAgentlessAwsAccount.Function = async (cloudAccountId, request) => {
    return await getHttpService().request<AxiosResponse<EnableAgentlessAwsAccount.Response>>(
        `workload/agentless/${Vendors.AWS}/accounts/${cloudAccountId}/enable`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const enableAwpAzureAccount: EnableAwpAzureAccount.Function = async (cloudAccountId, request) => {
    return await getHttpService().request<AxiosResponse<EnableAwpAzureAccount.Response>>(
        `workload/agentless/azure/accounts/${cloudAccountId}/enable`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const getAgentlessAwsOnboardingData: GetAgentlessAwsOnboardingData.Function = async () => {
    return await getHttpService().request<AxiosResponse<GetAgentlessAwsOnboardingData.Response>>(
        'workload/agentless/aws/onboarding',
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const checkForUpdate: CheckForUpdate.Function = async (id) => {
    return await getHttpService().request<AxiosResponse<CheckForUpdate.Response>>(
        `workload/agentless/${Vendors.AWS}/accounts/${id}/onboarding/checkForUpdate`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        e => { throw { message: e.response?.data, statusCode: e.response?.status }; }
    );
};

const checkAzureAccount: CheckAzureAccount.Function = async (cloudAccountId, useCache = true) => {
    return await getHttpService().request<AxiosResponse<CheckAzureAccount.Response>>(
        `workload/agentless/${Vendors.AZURE}/accounts/states/${cloudAccountId}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache } },
        e => { throw { message: e.response?.data }; }
    );
};

const getAzureOnboardingData: GetAzureOnboardingData.Function = async (cloudAccountId, request) => {
    return await getHttpService().request<AxiosResponse<GetAzureOnboardingData.Response>>(
        `workload/agentless/azure/accounts/${cloudAccountId}/generate-onboarding-data`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        e => { throw { message: e.response?.data }; }
    );
};
let awsOnboardingAbortCtrl = new AbortController();
const getAwsOnboardingData: GetAwsOnboardingData.Function = async (cloudAccountId, request) => {
    awsOnboardingAbortCtrl.abort();
    awsOnboardingAbortCtrl = new AbortController();
    return await getHttpService().request(
        `workload/agentless/${Vendors.AWS}/accounts/${cloudAccountId}/generate-onboarding-data`,
        { method: 'POST', data: request, signal: awsOnboardingAbortCtrl.signal },
        { returnAsAxiosResponse: true },
        e => { throw { message: e.response?.data }; }
    );
};

const getHubs: GetHubs.Function = async (id, platform, organizationId) => {
    return await getHttpService().request<AxiosResponse<GetHubs.Response>>(
        `workload/agentless/${platform}/accounts/${id}/centralizedAccounts${organizationId ? `?organizationId=${organizationId}` : ''}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        e => { throw { message: e.response?.data }; }
    );
};

const enableHubAccount: EnableHubAccount.Function = async (id, platform, request) => {// aka: Centralized
    return await getHttpService().request<AxiosResponse<EnableHubAccount.Response>>(
        `workload/agentless/${platform}/accounts/${id}/enableCentralizedAccount`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const enableAzureSubAccount: EnableAzureSubAccount.Function = async (id, request) => {
    return await getHttpService().request<AxiosResponse<EnableAzureSubAccount.Response>>(
        `workload/agentless/azure/accounts/${id}/enableSubAccount`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const enableAwsSubAccount: EnableAwsSubAccount.Function = async (id, request) => {
    return await getHttpService().request<AxiosResponse<EnableAwsSubAccount.Response>>(
        `workload/agentless/${Vendors.AWS}/accounts/${id}/enableSubAccount`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const getAwsAzureAgentlessAccountsStates: GetAwsAzureAgentlessAccountsStates.Function = async (vendor) => {
    return await getHttpService().request<AxiosResponse<GetAwsAzureAgentlessAccountsStates.Response>>(
        `workload/agentless/${vendor}/accounts/states`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true, tags: [IFRAME_TAGS.CLEAR_CACHE.ENVIRONMENTS] } },
        e => { throw { message: e.response?.data }; }
    );
};

const getAwsAzureServerlessAccountsStates: GetAwsAzureServerlessAccountsStates.Function = async (vendor) => {
    return await getHttpService().request<AxiosResponse<GetAwsAzureServerlessAccountsStates.Response>>(
        `serverless/${vendor}/accounts/states`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true, tags: [IFRAME_TAGS.CLEAR_CACHE.ENVIRONMENTS] } },
        e => { throw { message: e.response?.data }; }
    );
};


const getAwsAzureServerlessFunctionsGet: GetAwsAzureServerlessFunctionsGet.Function = async (id, vendor) => {
    return await getHttpService().request<AxiosResponse<GetAwsAzureServerlessFunctionsGet.Response>>(
        `serverless/${vendor}/functions/${id}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true, tags: [IFRAME_TAGS.CLEAR_CACHE.ENVIRONMENTS] } },
        err => { throw err; }
    );
};

const requestScan: RequestScan.Function = async (cloudAccountId, vendor, id) => {
    return await getHttpService().request<AxiosResponse<RequestScan.Response>>(
        `workload/agentless/${vendor}/accounts/${cloudAccountId}/scanEntity`,
        { method: 'POST', data: { entityId: id } },
        { returnAsAxiosResponse: true },
        e => { throw { statusCode: e.response?.status, message: e.response?.data }; }
    );
};

const getAzureOffboarding: GetAzureOffboarding.Function = async (cloudAccountId, centralizedAccounts) => {
    return await getHttpService().request<AxiosResponse<GetAzureOffboarding.Response>>(
        `workload/agentless/${Vendors.AZURE}/accounts/${cloudAccountId}/offboarding${centralizedAccounts ? `?centralizedAccounts=${centralizedAccounts}` : ''}`,
        { method: 'GET', data: { id: cloudAccountId } },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true } },
        e => { throw { message: e.response?.data }; }
    );

};

const getAgentlessSettings: GetAgentlessSettings.Function = async () => {
    return await getHttpService().request<AxiosResponse<GetAgentlessSettings.Response>>(
        'workload/agentless/accountSettings',
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        e => { throw { message: e.response?.data }; }
    );
};

const updateAgentlessSettings: UpdateAgentlessSettings.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<UpdateAgentlessSettings.Response>>(
        'workload/agentless/accountSettings',
        { method: 'PATCH', data: request },
        { returnAsAxiosResponse: true },
        e => { throw { message: e.response?.data }; }
    );
};

const AwpService = {
    enableAgentlessAwsAccount,
    enableAwpAzureAccount,
    getAgentlessAwsOnboardingData,
    checkForUpdate,
    checkAzureAccount,
    getAzureOnboardingData,
    getAwsOnboardingData,
    getHubs,
    enableHubAccount,
    enableAzureSubAccount,
    enableAwsSubAccount,
    getWorkloadAgentessAccount,
    updateWorkloadAgentessAccountSettings,
    deleteWorkloadAgentessAccount,
    getAwsAzureAgentlessAccountsStates,
    getAwsAzureServerlessAccountsStates,
    requestScan,
    getAwsAzureServerlessFunctionsGet,
    getAzureOffboarding,
    getAgentlessSettings,
    updateAgentlessSettings,
    updateCentralizedWorkloafAgentlessAccountSettings,
};

export default AwpService;
