import styled from 'styled-components';

export const InputsListWrapper = styled.div`
    display: grid;
    gap: 15px 10px;
    width: 100%;
    grid-template-columns: auto 1fr 10px auto 1fr;

    @media (max-width: 1200px) {
        grid-template-columns: auto 1fr;
    }
`;

export const InputsListLabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
`;

export const InputsListElementWrapper = styled.div`
`;

export const InputsListSeperator = styled.div`
    @media (max-width: 1200px) {
        display: none;
    }
`;

export const InputsListTooltipWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;