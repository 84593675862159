import { NotificationType } from 'common/interface/notifications';
import { getNotificationsService } from 'common/interface/services';
import { GetAzureOnboardingDataResponse, OnboardingCustomizations, ScanMode } from 'modules/workloads/services/awp/awp.interface';
import AwpService from 'modules/workloads/services/awp/awp.service';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnboardingCustomizationsFields from './useOnboardingCustomizationsFields';
import { getRelevantOnboardingCustomizations } from '../awpOnboarding.utils';
import { InAccountScannerVPC } from 'modules/workloads/aws/tabs/awp/components/Modals/AdvancedConfig/AdvancedConfig.types';

type UseAgentlessAzureOnboardingDataPayload = {
    agentlessAzureOnboardingData?: GetAzureOnboardingDataResponse;
    scanMode: ScanMode;
    setScanMode: (scanMode: ScanMode) => void;
    centralizedCloudAccountId?: string;
    setCentralizedCloudAccountId: (centralizedCloudAccountId: string) => void;
    skipFunctionAppsScan: boolean;
    setSkipFunctionAppsScan: Dispatch<SetStateAction<boolean>>;
    onboardingCustomizations?: OnboardingCustomizations;
    setOnboardingCustomizations: Dispatch<SetStateAction<OnboardingCustomizations | undefined>>;
    onboardingCustomizationsEnabled: boolean;
    setOnboardingCustomizationsEnabled: Dispatch<SetStateAction<boolean>>;
    managementGroupId: string;
    setManagementGroupId: Dispatch<SetStateAction<string>>;
    sseCmkEncryptedDisksScan: boolean;
    setSseCmkEncryptedDisksScan: Dispatch<SetStateAction<boolean>>;
    inAccountScannerVPC: InAccountScannerVPC,
    setInAccountScannerVPC: Dispatch<SetStateAction<InAccountScannerVPC>>;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

export type UseAgentlessAzureOnboardingData = (cloudAccountId: string) => UseAgentlessAzureOnboardingDataPayload;
const useAgentlessAzureOnboardingData: UseAgentlessAzureOnboardingData = (cloudAccountId) => {
    const { t } = useTranslation('k8s_awp');
    const [agentlessAzureOnboardingData, setAgentlessAzureOnboardingData] = useState<GetAzureOnboardingDataResponse | undefined>(undefined);
    const [scanMode, setScanMode] = useState<ScanMode>(ScanMode.InAccount);
    const [centralizedCloudAccountId, setCentralizedCloudAccountId] = useState<string>();
    const [skipFunctionAppsScan, setSkipFunctionAppsScan] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [onboardingCustomizations, setOnboardingCustomizations] = useState<OnboardingCustomizations>();
    const [managementGroupId, setManagementGroupId] = useState<string>('');
    const [onboardingCustomizationsEnabled, setOnboardingCustomizationsEnabled] = useState<boolean>(false);
    const [sseCmkEncryptedDisksScan, setSseCmkEncryptedDisksScan] = useState(false);
    const [inAccountScannerVPC, setInAccountScannerVPC] = useState(InAccountScannerVPC.ManagedByAWP);
    const { advancedFields: relevantFields } = useOnboardingCustomizationsFields(scanMode, sseCmkEncryptedDisksScan);

    const getNewOnboardingData = useCallback(async (scanMode: ScanMode) => {
        setLoading(true);
        const relevantOnboardingCustomizations = getRelevantOnboardingCustomizations(relevantFields, onboardingCustomizations);

        try {
            const res = await AwpService.getAzureOnboardingData(cloudAccountId, {
                scanMode,
                sseCmkEncryptedDisksScan,
                ...(centralizedCloudAccountId ? { centralizedId: centralizedCloudAccountId } : {}),
                ...(skipFunctionAppsScan ? { skipFunctionAppsScan } : {}),
                ...(onboardingCustomizationsEnabled ? { onboardingCustomizations: relevantOnboardingCustomizations } : {}),
                ...((managementGroupId && onboardingCustomizationsEnabled) ? { managementGroupId } : {})
            });
            setAgentlessAzureOnboardingData(res.data);
            setLoading(false);
        } catch (error) {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: t('awpOnboarding.azure.messages.missingAgentlessAzureOnboardingData'),
                text: '',
            });
        }
    }, [centralizedCloudAccountId, cloudAccountId, managementGroupId, onboardingCustomizations, onboardingCustomizationsEnabled, relevantFields, skipFunctionAppsScan, sseCmkEncryptedDisksScan, t]);
    
    useEffect(() => {
        if (scanMode === ScanMode.InAccountSub && !centralizedCloudAccountId) {
            setLoading(true);
            return;
        }

        if (scanMode !== ScanMode.InAccountSub && centralizedCloudAccountId) {
            setCentralizedCloudAccountId(undefined);
            return;
        }

        getNewOnboardingData(scanMode);
    }, [centralizedCloudAccountId, getNewOnboardingData, scanMode]);

    const onCentralizedCloudAccountIdChanged = async (newCentralizedCloudAccountId: string) => {
        if (newCentralizedCloudAccountId !== centralizedCloudAccountId) {
            setLoading(true);
            setCentralizedCloudAccountId(newCentralizedCloudAccountId);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (skipFunctionAppsScan) {
            setSkipFunctionAppsScan(false);
        }
        setSseCmkEncryptedDisksScan(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scanMode]);

    return {
        agentlessAzureOnboardingData,
        scanMode,
        setScanMode,
        centralizedCloudAccountId,
        setCentralizedCloudAccountId: onCentralizedCloudAccountIdChanged,
        skipFunctionAppsScan,
        setSkipFunctionAppsScan,
        onboardingCustomizations,
        setOnboardingCustomizations,
        onboardingCustomizationsEnabled,
        setOnboardingCustomizationsEnabled,
        managementGroupId,
        setManagementGroupId,
        sseCmkEncryptedDisksScan,
        setSseCmkEncryptedDisksScan,
        inAccountScannerVPC,
        setInAccountScannerVPC,
        loading,
        setLoading,
    };
};

export default useAgentlessAzureOnboardingData;
