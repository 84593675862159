import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { getNotificationsService, getHttpService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import ModalFeedback from '../ModalFeedback';
import { Button } from 'common/design-system/components-v2';

const LoggerObject = {
    log: 'log',
    tags: [],
    browserMetadata: (window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'NA'),
    targetUrl: (window.location && window.location.href ? window.location.href : 'NA'),
    screenResolution: (window.screen && window.screen.width ? window.screen.width : 'NA') + 'x' + (window.screen && window.screen.height ? window.screen.height : 'NA'),
    browserResolution: (window.innerWidth ? window.innerWidth : 'NA') + 'x' + (window.innerHeight ? window.innerHeight : 'NA'),
    language: (window.navigator && window.navigator.language ? window.navigator.language : 'NA'),
    clientTimeStamp: new Date().toUTCString(),
};

const Feedback = () => {
    const { t } = useTranslation();
    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const onConfirm = async (value: string) => {

        const message = {
            reportType: 'Dashboard-K8S-Feedback',
            description: value,
        };

        try {
            await getHttpService().request<AxiosResponse>(
                'LoggerService',
                {
                    method: 'POST',
                    data: {
                        ...LoggerObject,
                        message: JSON.stringify(message),
                    },
                },
                {},
                (error) => {
                    throw error;
                },
            );

            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                title: 'Thank you for providing your feedback!',
                text: '',
            });

        } catch (e) {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: 'Failed to send feedback, please try again',
                text: '',
            });
        }

        setModalOpen(false);
    };

    return (
        <div>
            <Button
                iconProps={{ name: 'comments' }}
                label={t('K8S.DASHBOARD.FEEDBACK.FEEDBACK_BUTTON')}
                onClick={() => setModalOpen(prevState => !prevState)}
                color='brandPrimary'
            />
            <ModalFeedback isOpen={isModalOpen} onClose={setModalOpen} onConfirm={onConfirm} />
        </div>
    );
};

export default Feedback;
