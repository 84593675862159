import React, { useMemo, useState } from 'react';
import { DisableAwpModalProps } from '../DisableAWP.types';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { Trans, useTranslation } from 'react-i18next';
import { Command, Icon, Message, Stack } from 'common/design-system/components-v2';
import { ALink } from 'common/components/ALink';
import AwpService from 'modules/workloads/services/awp/awp.service';
import { ICommandProps } from 'common/design-system/components-v2/Command/Command.types';

type AzureOffboardingScript = { type: 'LOADING' } | { type: 'ERROR', error: Error } | { type: 'SUCCESS', script: string };

const DisableAzureCli: React.FC<DisableAwpModalProps & { centralizedCloudAccountId?: string, cloudAccountId: string }> = ({ onClose, onDelete, isDeletePending, centralizedCloudAccountId, cloudAccountId }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));
    const [script, setScript] = useState<AzureOffboardingScript>({ type: 'LOADING' });

    React.useEffect(() => {
        AwpService.getAzureOffboarding(cloudAccountId, centralizedCloudAccountId ?? undefined ).then((response) => {
            setScript({ type: 'SUCCESS', script: response.data.offboardingScriptCommand });
        }).catch((error: Error) => {
            setScript({ type: 'ERROR', error });
        });
    }, [cloudAccountId, centralizedCloudAccountId, t]);

    const commandProps: ICommandProps = useMemo(() => {
        if (script.type === 'ERROR') {
            return {
                isLoading: true,
                customLoadingElement: (
                    <Stack
                        spacing={2}
                        direction='row'
                        fullWidth>
                        <Icon color='alert' name='error' />
                        {t('awpTab.modals.disableAWP.azure.errorDescription')}
                    </Stack>
                ),
                text: ''
            };
        }
        if (script.type === 'SUCCESS') {
            return {
                isLoading: false,
                text: script.script,
            };
        }
        return { isLoading: true, text: '' };
    }, [script, t]);

    return (
        <Message
            title={t('awpTab.modals.disableAWP.azure.title')}
            isOpen
            width='md'
            variant='danger'
            onClose={onClose}
            submitBtnDisabled={script.type !== 'SUCCESS' || isDeletePending}
            onConfirm={() => onDelete(true)}
            onCancel={onClose}
            submitBtnText={t('awpTab.modals.disableAWP.submitTextButton')}
        >
            <Trans
                i18nKey='awpTab.modals.disableAWP.azure.description'
                t={t}
                components={{
                    span: <span />,
                    link1: <ALink target={'_blank'} href='https://shell.azure.com/'></ALink>,
                    link2: <ALink target={'_blank'} href='https://learn.microsoft.com/en-us/cli/azure/install-azure-cli'></ALink>,
                    stack: <Stack spacing={[2]}>.</Stack>,
                    command: <Command {...commandProps} /> ,
                }}
            />
        </Message>
    );
};

export default DisableAzureCli;