import React from 'react';
import { AwpTabProps } from './AwpTab.consts';
import EnableAWP from './components/EnableAWP/EnableAWP';
import Styled from './AwpTab.styled';
import { useWorkloadAgentlessAccount } from 'modules/workloads/services/awp/hooks/useAgentlessAccount';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import { Stack } from 'common/design-system/components-v2';
import Modals, { ModalType } from './components/Modals';
import DataWidgets from './components/DataWidgets/DataWidgets';
import Toolbar from './components/Toolbar/Toolbar';
import AccountRegionIssues from './components/AccountRegionIssues/AccountRegionIssues';
import { ALink } from 'common/components/ALink';
import { getCheckpointUrl } from 'common/components/Forms/urls';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';

const AwpTab: React.FunctionComponent<AwpTabProps> = ({ cloudAccountId, platform }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));
    const [modalType, setModalType] = React.useState<ModalType | null>(null);
    const { isLoading, isError, data, reloadData } = useWorkloadAgentlessAccount(cloudAccountId, platform);

    if (isLoading) {
        return (
            <Styled.Wrapper>
                <Styled.LoaderWrapper>
                    <CloudAnimationLoader size='medium' />
                </Styled.LoaderWrapper>
            </Styled.Wrapper>
        );
    }

    if ((!isLoading && isError) || !data || !data.agentlessProtectionEnabled) {
        return (
            <Styled.Wrapper>
                <Stack fullHeight fullWidth alignItems='center'>
                    <EnableAWP cloudAccountId={cloudAccountId} platform={platform} />
                </Stack>
            </Styled.Wrapper>
        );
    }

    return (
        <Styled.Wrapper>
            <Stack spacing={4} fullWidth>
                <Toolbar agentlessAccount={data} setModalType={setModalType} />
                <Stack spacing={4} fullWidth>
                    <DataWidgets agentlessAccount={data} />
                    <AccountRegionIssues agentlessAccount={data} />
                    <Stack padding={[0, 0, 2, 0]}>
                        <ALink
                            href={getCheckpointUrl('AGENTLESS_WORKLOAD_POSTURE')} 
                            target='_blank'
                            rel='noreferrer'
                        >
                            {t('awpTab.toolbarActions.moreInfoAboutAWP')}
                        </ALink>
                    </Stack>
                </Stack>
            </Stack>
            {modalType && (
                <Modals
                    agentlessAccount={data}
                    modalType={modalType}
                    closeModal={() => setModalType(null)}
                    setModalType={setModalType}
                    reloadAgentlessAccount={reloadData}
                />
            )}
        </Styled.Wrapper>
    );
};

export default AwpTab;