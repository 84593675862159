import { UserRolesTypes } from 'common/enum/UserRoles';
import { addMainMenuItem } from 'common/helpers/menu';
import { getUserService } from 'common/interface/services';
import i18n from 'common/services/translations/translations';
import { isNotChina } from 'common/utils/dataCenter';
import { isExclusionsEnabled, isPolicyEnabled, isRemediationEnabled, isRulesetEnabled } from 'common/utils/userPermissionUtils';

export const initializeK8sMenu = () => {
    addMainMenuItem({
        id: 'workload protection',
        position: 80,
        icon: 'workload-protection',
        label: 'NAVIGATION_MENU.WORKLOAD_PROTECTION.TITLE',
        permission: [UserRolesTypes.ALL],
        descriptionKeys: ['NAVIGATION_MENU.ADMISSION_CONTROL.DESCRIPTION.1'],
        sections: [
            {
                id: 'default',
                position: 10,
                items: [
                    {
                        id: 'welcome',
                        position: 10,
                        label: 'NAVIGATION_MENU.ADMISSION_CONTROL.WELCOME',
                        state: '/workload/welcome-page',
                        urlAliases: ['/workload-protection/welcome-page'],
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'K8S_DASHBOARD',
                        position: 11,
                        label: 'NAVIGATION_MENU.DASHBOARD.TITLE',
                        state: '/workload/dashboard',
                        urlAliases: ['/workload-protection/dashboard'],
                        permission: [UserRolesTypes.ALL],
                    },
                ],
            },
            {
                id: 'assets',
                position: 20,
                title: 'NAVIGATION_MENU.WORKLOAD_PROTECTION.CONTAINER_ASSETS',
                items: [
                    {
                        id: 'environments',
                        position: 10,
                        label: 'NAVIGATION_MENU.WORKLOAD_PROTECTION.ENVIRONMENTS',
                        state: '/workload/environments',
                        urlAliases: [
                            /^\/workload\/environments\/containerregistry\/.+/,
                            /^\/workload\/environments\/kubernetes\/.+/,
                            /^\/workload\/environments\/shiftleft\/.+/,
                            /^\/workload\/cloud-add\/kubernetes/,
                            /^\/workload\/registry-onboarding/,
                            /^\/workload\/cloud-add\/shiftleft/,
                        ],
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'workloads',
                        position: 20,
                        label: 'NAVIGATION_MENU.WORKLOAD_PROTECTION.WORKLOADS',
                        state: '/workload/workloads',
                        urlAliases: [
                            '/workload/workloads/protected-asset',
                            '/workload/workloads/protected-asset-generic',
                        ],
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'images',
                        position: 30,
                        label: 'NAVIGATION_MENU.WORKLOAD_PROTECTION.IMAGES',
                        state: '/workload/images',
                        urlAliases: ['/workload/images/generic', '/workload/images/details'],
                        permission: [UserRolesTypes.ALL],
                    },
                ],
            },
            {
                id: 'image assurance',
                position: 30,
                title: 'NAVIGATION_MENU.WORKLOAD_PROTECTION.IMAGE_ASSURANCE',
                items: [
                    {
                        id: 'vulnerabilities',
                        position: 10,
                        label: 'NAVIGATION_MENU.WORKLOAD_PROTECTION.VULNERABILITIES',
                        state: '/workload/vulnerability',
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'rulesets',
                        position: 20,
                        label: 'NAVIGATION_MENU.WORKLOAD_PROTECTION.RULESETS',
                        state: '/workload/rulesets',
                        urlAliases: [/^\/workload\/rulesets\/.+/],
                        permission: () => isRulesetEnabled(),
                    },
                    {
                        id: 'exclusions IA',
                        position: 21,
                        label: 'NAVIGATION_MENU.POSTURE_MANAGEMENT.EXCLUSIONS',
                        state: '/workload/exclusion',
                        permission: () => isRemediationEnabled(),
                        chip: { label: i18n.t('GENERAL.PREVIEW') },
                    },
                    {
                        id: 'policies',
                        position: 30,
                        label: 'NAVIGATION_MENU.WORKLOAD_PROTECTION.POLICIES',
                        state: '/workload/policies',
                        permission: () => isPolicyEnabled(),
                    },
                    {
                        id: 'history',
                        position: 40,
                        label: 'NAVIGATION_MENU.WORKLOAD_PROTECTION.HISTORY',
                        state: '/workload/history',
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'gsl builder',
                        position: 50,
                        label: 'NAVIGATION_MENU.WORKLOAD_PROTECTION.GSL_BUILDER',
                        state: '/workload/assurance-gsl-builder',
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'cve search',
                        position: 60,
                        label: 'NAVIGATION_MENU.WORKLOAD_PROTECTION.CVE_SEARCH',
                        state: '/workload/cve-search',
                        permission: [UserRolesTypes.ALL],
                        chip: {
                            label: i18n.t('GENERAL.PREVIEW')
                        },
                    }
                ],
            },
            {
                id: 'admission control',
                position: 40,
                title: 'NAVIGATION_MENU.ADMISSION_CONTROL.TITLE',
                items: [
                    {
                        id: 'rulesets',
                        position: 10,
                        label: 'NAVIGATION_MENU.ADMISSION_CONTROL.ITEMS.RULESETS',
                        state: '/runtime-assurance',
                        urlAliases: [
                            '/runtime-assurance',
                            '/runtime-assurance/rulesets',
                            '/runtime-assurance/ruleset',
                            /^\/runtime-assurance\/ruleset\/.+/,
                        ],
                        permission: () => isRulesetEnabled(),
                    },
                    {
                        id: 'policies',
                        position: 20,
                        label: 'NAVIGATION_MENU.ADMISSION_CONTROL.ITEMS.POLICIES',
                        state: '/runtime-assurance/policies',
                        urlAliases: [/^\/runtime-assurance\/policy\/.+/],
                        permission: () => isPolicyEnabled(),
                    },
                    {
                        id: 'gsl builder',
                        position: 30,
                        label: 'NAVIGATION_MENU.ADMISSION_CONTROL.ITEMS.GSL_BUILDER',
                        state: '/runtime-assurance/gsl-builder',
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'exclusions',
                        position: 40,
                        label: 'NAVIGATION_MENU.POSTURE_MANAGEMENT.EXCLUSIONS',
                        state: '/runtime-assurance/exclusions',
                        permission: () => isExclusionsEnabled(),
                    },
                ],
            },
            {
                id: 'serverless',
                position: 50,
                title: 'NAVIGATION_MENU.SERVERLESS.TITLE',
                items: [
                    {
                        id: 'serverless functions',
                        position: 10,
                        label: 'NAVIGATION_MENU.SERVERLESS.SERVERLESS_FUNCTIONS',
                        state: '/workload/serverless/assets',
                        permission: (() => isNotChina() && getUserService().hasPermission([UserRolesTypes.ALL])),
                    },
                    {
                        id: 'ci cd',
                        position: 20,
                        label: 'NAVIGATION_MENU.SERVERLESS.SERVERLESS_CICD',
                        state: '/workload/serverless/ci-cd',
                        permission: (() => isNotChina() && getUserService().hasPermission([UserRolesTypes.ALL])),
                    },
                ],
            },
        ],
    });
};