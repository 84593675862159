import { Error, Warning, Ok } from '@dome9/berries/system-icons';
import { sendLogger } from './logger';
import { IProtectedAssetAdditionalField, IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { getAdditionalFieldValue } from 'common/utils/ColumnDisplayValueGetters';
import { getExternalAdditionalFieldValue } from 'common/module_interface/assets/utils';
import dayjs from 'dayjs';
import { AssetTypes } from 'common/assets/assets.const';

const STATUSES = {
    OK: Ok,
    PENDING: Warning,
    WARNING: Warning,
    ERROR: Error,
    default: Warning,
};

// @ts-ignore
export const getIconByStatus = (status: string) => STATUSES[status] || STATUSES['default'];
export const isCheckpointUser = (mail: string | undefined) => mail?.split('@')[1] === 'checkpoint.com';
export const isCustomerPlan = (plan: string) => ['Enterprise', 'FREE', 'Enterprise_Trial', 'MSP'].some(planItem => planItem === plan);

type GetFieldByNameFromAdditionalField = (additionalFields: Array<IProtectedAssetAdditionalField>) => (fieldName: string) => IProtectedAssetAdditionalField | undefined;
const getFieldByNameFromAdditionalField: GetFieldByNameFromAdditionalField = (additionalFields) => (fieldName) => additionalFields.find(field => field.name === fieldName);

export const isImageType = (typeByPlatform: string) => [
    AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE,
    AssetTypes.KUBERNETES_KUBERNETESIMAGE,
    AssetTypes.AWS_ECSIMAGE,
    AssetTypes.SHIFTLEFT_SHIFTLEFTIMAGE,
].some(type => type === typeByPlatform);

export const getScanStatus = (data: IProtectedAssetViewModel) => {
    const value = data?.vulnerability?.scanStatus || getAdditionalFieldValue(data, 'ScanStatus') || getExternalAdditionalFieldValue(data, 'ScanStatus');
    return value;
};

export const getScanStatusDetails = (data: IProtectedAssetViewModel) => {
    const initiatedTextPrefix = 'Scan initiated at: ';
    const value = data?.vulnerability?.scanStatusDetails || getAdditionalFieldValue(data, 'ScanStatusDetails') || getExternalAdditionalFieldValue(data, 'ScanStatusDetails');
    if (value?.startsWith(initiatedTextPrefix)) {
        const dateTime = new Date(value.slice(initiatedTextPrefix.length, value.length));
        return `${initiatedTextPrefix}${dayjs(dateTime).format('MM/DD/YYYY HH:mm')}`;
    }
    return value;
};

export {
    sendLogger,
    getFieldByNameFromAdditionalField,
};
