import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';

export const isFilterValuesEmpty = (filtersValues?: IFiltersValues) => {
    return filtersValues && Object.values(filtersValues).every(value => {
        if (typeof value === 'string') {
            return value.trim() === '';
        } else if (Array.isArray(value)) {
            return value.every(item => typeof item === 'string' && item.trim() === '');
        }
        return false;
    });
};
