import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const ColumnHeaderWrapper = styled(Stack)`
    font-weight: 500;
`;

const IconWrapper = styled.span`
`;

const TooltipHeaderRendererStyles = {
    ColumnHeaderWrapper,
    IconWrapper
};

export default TooltipHeaderRendererStyles;