import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import Modals from './components/Modals';
import { LoaderWrapper } from './ShiftLeftEnvironment.styled';
import { ModalType } from './ShiftLeftEnvironment.types';
import Toolbar from './components/Toolbar/Toolbar';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import { getUserService } from 'common/interface/services';
import EmptyState from 'common/components/EmptyState';
import { useTranslation } from 'react-i18next';
import { EntityViewerProps, Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import EntityViewer from 'common/components/EntityViewer/EntityViewer';
import { useInterval } from 'modules/workloads/hooks';
import { useGetShiftLeft } from 'modules/workloads/reducers/shiftLeft/hooks/useGetShiftLeft';
import { shiftLeftTabsMetadata } from './ShiftLeftEnvironment.consts';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';

const ShiftleftEnvironment: React.FunctionComponent = () => {
    const [modalType, setModalType] = useState<ModalType | null>(null);
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation('k8s_shiftleft');
    const { isLoading, data: shiftLeftData, isError, isDeleted, reloadData: reloadShiftLeftData } = useGetShiftLeft(id);
    const { account: accountData } = shiftLeftData || {};
    const { setBreadCrumbsLastChildren } = useBreadcrumbsLastChildren();

    useEffect(() => {
        if (shiftLeftData?.account.name) {
            setBreadCrumbsLastChildren([shiftLeftData?.account.name]);
        }
    }, [shiftLeftData, setBreadCrumbsLastChildren]);

    useInterval(reloadShiftLeftData, 15 * 1000);

    const tabsWithData = React.useMemo<Array<Tab>>(() => {
        return shiftLeftTabsMetadata(id).filter(tab => {
            let tabVisible = true;
            if (tab.propertyInAccount) {
                tabVisible = accountData?.[tab.propertyInAccount];
            }
            if (tabVisible && tab.guardByFeatureFlag) {
                tabVisible = getUserService().hasPermission([tab.guardByFeatureFlag]);
            }
            return tabVisible;
        }).map<Tab>(tab => ({
            isReactTab: tab.isReactTab,
            name: tab.name,
            label: t(tab.labelT),
            content: tab.content,
        }));
    }, [accountData, id, t]);

    const changeModalType = (type: ModalType) => {
        setModalType(type);
    };

    if (isLoading) {
        return (
            <LoaderWrapper>
                <CloudAnimationLoader size='medium' />
            </LoaderWrapper>
        );
    }

    if (isError || isDeleted || !accountData) {
        return (
            <EmptyState
                label={t('shiftLeftPage.notFound.title')}
                description={t('shiftLeftPage.notFound.description')}
                links={
                    [{ label: t('shiftLeftPage.notFound.backLink'), url: '/workload/environments', icon: { name: 'arrowLeft' } }]
                }
            />
        );
    }

    const shiftLeftInfoProps: EntityViewerProps = {
        title: accountData.name,
        levelIcon: {
            iconProps: {
                name: 'shiftleft'
            }
        },
        titleRightElements:
            <Toolbar
                changeModalType={changeModalType}
            />
        ,
        details: [
            { title: t('shiftLeftPage.info.cloudGuardId'), info: accountData.id, copyable: true },
            { title: t('shiftLeftPage.info.organizationalUnits'), info: accountData.organizationalUnitName, copyable: true },
            { title: t('shiftLeftPage.info.onboardingTime'), info: dayjs(new Date(accountData.creationDate)).format('MMM D, YYYY h:mm A Z') },
            { title: t('shiftLeftPage.info.description'), info: accountData.description },
        ],
        tabs: tabsWithData,
        isLoading,
    };

    return (
        <>
            <EntityViewer {...shiftLeftInfoProps} />
            <Modals
                modalType={modalType}
                closeModal={() => setModalType(null)}
                shiftLeftId={id}
            />
        </>
    );
};

export default ShiftleftEnvironment;
