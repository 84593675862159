import RegistryPageStyles from './RegistryPage.styled';
import Images from './Tabs/Images/Images';
// import Overview from './Tabs/Overview';
import Scanners from './Tabs/Scanners';

export interface RegistryPageTabs {
    name: string;
    labelT: string;
    isReactTab: boolean;
    content?: React.ReactElement;
    guardByFeatureFlag?: string;
}

export const registryPageTabsMetadata: (registryId: string) => Array<RegistryPageTabs> = (registryId) => [
    // { 
    //     name: 'overview',
    //     labelT: 'registryPage.tabs.overview',
    //     isReactTab: true,
    //     content: (
    //         <RegistryPageStyles.ReactTabContent>
    //             <Overview registryId={registryId} />                
    //         </RegistryPageStyles.ReactTabContent>
    //     )
    // },
    {
        name: 'scanners',
        labelT: 'registryPage.tabs.scanners',
        isReactTab: true,
        content: (
            <RegistryPageStyles.ReactTabContent>
                <Scanners registryId={registryId} />
            </RegistryPageStyles.ReactTabContent>
        )
    },
    {
        name: 'protected assets',
        labelT: 'registryPage.tabs.images',
        isReactTab: true,
        content: (
            <RegistryPageStyles.ReactTabContent removePadding>
                <Images id={registryId} />
            </RegistryPageStyles.ReactTabContent>
        )
    },
    {
        name: 'compliance policies',
        labelT: 'registryPage.tabs.policies',
        isReactTab: false,
    },
    {
        name: 'posture finding events',
        labelT: 'registryPage.tabs.postureFindings',
        isReactTab: false,
    },
    {
        name: 'assessment history',
        labelT: 'registryPage.tabs.assessmentHistory',
        isReactTab: false,
    },
];