import React from 'react';
import { useTranslation } from 'react-i18next';
import ScannerStyles from './Scanners.styled';
import { ScannerModalTypes, ScannersProps } from './Scanners.types';
import ScannersTable from './components/ScannersTable';
import ConnectEditClusterModal from './components/ConnectEditClusterModal';
import { RegistryAgentSummaryWithLinkedEnv } from './components/ScannersTable/ScannersTable.types';
import UnlinkClusterModal from './components/UnlinkClusterModal';
import AdvancedConfig from './components/AdvancedConfig';
import { Button, Stack } from 'common/design-system/components-v2';

const Scanners: React.FunctionComponent<ScannersProps> = ({ registryId }) => {
    const { t } = useTranslation('k8s_registry');
    const [modalType, setModalType] = React.useState<ScannerModalTypes>();
    const [linkedEnvToEdit, setLinkedEnvToEdit] = React.useState<RegistryAgentSummaryWithLinkedEnv>();

    const openModal = (modalType: ScannerModalTypes, linkedEnv?: RegistryAgentSummaryWithLinkedEnv) => {
        setLinkedEnvToEdit(linkedEnv);
        setModalType(modalType);
    };

    const closeModal = () => {
        setModalType(undefined);
        setLinkedEnvToEdit(undefined);
    };

    return (
        <ScannerStyles.Wrapper>
            <Stack direction='row' justifyContent='flex-end'>
                <Button
                    iconProps={{ name: 'plus' }}
                    variant='text'
                    onClick={() => openModal(ScannerModalTypes.EDIT)}
                >
                    {t('registryPage.scanners.connectNewCluster')}
                </Button>
                <Button
                    iconProps={{ name: 'configuration' }}
                    variant='text'
                    onClick={() => openModal(ScannerModalTypes.SETTINGS)}
                >
                    {t('registryPage.scanners.advancedConfigurations')}
                </Button>
            </Stack>
            <ScannersTable registryId={registryId} openModal={openModal} />
            {(modalType === ScannerModalTypes.EDIT) && (
                <ConnectEditClusterModal
                    closeModal={closeModal}
                    registryId={registryId}
                    linkedEnv={linkedEnvToEdit}
                />
            )}
            {((modalType === ScannerModalTypes.REMOVE && linkedEnvToEdit)) && (
                <UnlinkClusterModal
                    closeModal={closeModal}
                    registryId={registryId}
                    linkedEnv={linkedEnvToEdit}
                />
            )}
            {(modalType === ScannerModalTypes.SETTINGS) && (
                <AdvancedConfig
                    closeModal={closeModal}
                    registryId={registryId}
                />
            )}
        </ScannerStyles.Wrapper>
    );
};

export default Scanners;
