import React from 'react';
import { ToolbarWrapper } from './Toolbar.styled';
import { ToolBarProps } from './Toolbar.types';
import { ModalType } from '../../ShiftLeftEnvironment.types';
import { useTranslation } from 'react-i18next';
import { useScanEngineVersion } from 'modules/workloads/services/vulnerability/hooks/useScanEngineVersion';
import { Button } from 'common/design-system/components-v2';

const Toolbar: React.FunctionComponent<ToolBarProps> = ({
    changeModalType,
}) => {
    const { t } = useTranslation('k8s_shiftleft');
    const { data } = useScanEngineVersion();
    const isNewEngineVersion = data?.scanEngineVersion === '2.0.0';
    return (
        <ToolbarWrapper direction='row'>
            <Button variant='text' iconProps={{ name: 'delete' }} onClick={() => changeModalType(ModalType.RemoveShiftLeft)}>
                {t('shiftLeftPage.toolbar.remove')}
            </Button>
            <Button variant='text' iconProps={{ name: 'edit' }} onClick={() => changeModalType(ModalType.RenameShiftLeft)}>
                {t('shiftLeftPage.toolbar.edit')}
            </Button>

            {isNewEngineVersion && <Button variant='text' iconProps={{ name: 'download' }} onClick={() => changeModalType(ModalType.DownloadOS)}>
                {t('shiftLeftPage.toolbar.readInstructions')}
            </Button>}
        </ToolbarWrapper>
    );
};


export default Toolbar;
