import { Icon, Stack, Tooltip, Typography } from 'common/design-system/components-v2';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StepProps } from '../awpOnboardingAws.types';
import createStackImg from '../../../../../assets/images/create-stack-new.png';

const StyledImage = styled.img`
    box-shadow: ${({ theme }) => theme.shadows[0]};
    bottom-padding: ${({ theme }) => `${theme.spacing(1)}${theme.units}`};
    max-width: 300px;
`;

const RunStackStep: React.FC<StepProps> = () => {
    const { t } = useTranslation(getK8sNamespace('awp'));
    return (
        <Stack direction='row' key='step-2' spacing={2}>
            <Typography>{t('awpOnboarding.instructions.step2')}</Typography>
            <Tooltip content={<StyledImage src={createStackImg} alt='' />} placement='top'>
                <Icon name='info' size={16} />
            </Tooltip>
        </Stack>
    );
};

export default RunStackStep;