import React from 'react';
import { useSelector } from 'react-redux';
import RegistriesActions from '../registries.actions';
import { RegistryAccount } from 'modules/workloads/services/containerRegistry/containerRegistry.interface';
import { RegistriesSelector } from '../registries.selectors';

export interface UseGetRegistriesPayload {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    data: Array<RegistryAccount>;

    reloadData: () => void;
}

export type UseGetRegistries = () => UseGetRegistriesPayload;

export const useGetRegistries: UseGetRegistries = () => {
    const loading = useSelector(RegistriesSelector.getRegistriesLoading);
    const error = useSelector(RegistriesSelector.getRegistriesError);
    const data = useSelector(RegistriesSelector.getRegistries);

    const getData = () => RegistriesActions.getRegistries();
 
    React.useEffect(() => {
        if (!loading && !error && data.length === 0) {
            getData();
        }
    }, [data, loading, error]);

    const reloadData = React.useCallback(() => getData(), []);

    return ({
        isLoading: !!loading && !data,
        isFetching: !!loading,
        isError: !!error,
        data,

        reloadData
    });
};