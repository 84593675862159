import { FC, ReactElement } from 'react';
import { Stack, Typography } from 'common/design-system/components-v2';
import { CardWrapper, VerticalLine } from './ImageAdmissionCard.styled';

export interface ImageAdmissionItem{
    category?: 'unScanned'| 'nonCompliant';
    type?: string | null; // 'Detection' | 'Prevention'
    id: string;
    title: string;
    description: string;
    icon: ReactElement;
    selected?: boolean;
    onClick?: () => void;
    style?: { [key: string]: string };
}


export const ImageAdmissionCard :FC<ImageAdmissionItem> = ({ selected, id, title,description,icon, onClick, style }) => {
    return (
        <CardWrapper selected={!!selected} onClick={onClick} style={style}>
            <Stack fullHeight direction={'row'} alignItems={'flex-start'} >
                <Stack color={'black'} padding={2} fullHeight>
                    <VerticalLine/>
                </Stack>
                <Stack key={id} alignItems={'flex-start'} padding={3}>
                    <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent={'center'}>
                        {icon}
                        <Typography variant={'h2'}>{title}</Typography>
                    </Stack>
                    <Stack alignItems={'flex-start'} justifyContent={'flex-start'}>
                        <Typography variant={'bodyLg'}>{description}</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </CardWrapper>
    );
};