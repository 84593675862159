import React, { ReactElement } from 'react';
import { Select as _Select } from '@dome9/berries/react-components';
import { OnChangeValue } from 'react-select';

interface SelectOption {
    label: string;
    value: string;
    isSelected?: boolean;
}

export interface SelectProps {
    label?: string;
    required?: boolean;
    tooltip?: string;
    options: Array<SelectOption>;
    onChange: (value: string) => any;
    placeholder?: string;
    error?: boolean;
    disabled?: boolean;
    defaultValue?: string;
    value?: string;
    noOptionsMessage?: string | ReactElement;
    closeMenuOnSelect?: boolean;
}

const Select: React.FunctionComponent<SelectProps> = (props) => {
    const { label, required = false, tooltip, options, onChange, placeholder, error, disabled, value, defaultValue, noOptionsMessage } = props;

    const handleOnChange = async (optionSelected: OnChangeValue<SelectOption, false>) => {
        if (!optionSelected) return;
        onChange(optionSelected.value);
    };

    const customStyles = {
        menu: (base: any) => ({
            ...base,
            zIndex: 15
        }),
        control: (base: any) => ({
            ...base,
            background: '#ffffff !important',
            borderColor: error ? '#f2504f !important' : 'rgba(0, 0, 0, 0.09) !important'
        }),
        menuPortal: (base:any ) => ({
            ...base,
            zIndex: 9999
        }),
        option: (baseStyles: any, state: any) => ({
            ...baseStyles,
            ...state.isFocused ? {
                background: '#e5e9ed',
                color: '#333'
            } : {},
            ...state.isSelected && {
                background: '#bce3fe',
                color: '#333'
            },
        })
    };

    const getOptionByValue = (val?: string) => options.find(option => option.value === val);

    return (
        <_Select
            label={label}
            required={required}
            tooltipProps={{ content: tooltip, placement: 'right' }}
            options={options}
            onChange={handleOnChange}
            isDisabled={disabled}
            placeholder={placeholder}
            styles={customStyles}
            closeMenuOnSelect={true}
            value={getOptionByValue(value)}
            defaultValue={getOptionByValue(defaultValue)}
            noOptionsMessage={() => noOptionsMessage}
            menuPortalTarget={document.body}
            menuPlacement='auto'
        />
    );
};

export default Select;
