import styled from 'styled-components';

export const WrapperDashboardFilterPanel = styled.div`
  margin: 24px 24px 0 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  & > div:first-child {
    flex: 1;
  }
`;
