import React, { useCallback } from 'react';
import sbomService from '../sbom.service';
import { GetSbom, UseSbomState, SbomPackagesFlat } from '../sbom.interface';


const flattenSbomPackagesManager = (data: Array<SbomPackagesFlat>): Array<SbomPackagesFlat> =>
    data.map((item) => ({ ...item, packageManagerPath: item['package-manager'].path, packageManagerName: item['package-manager'].name }));

export type UseSbom = (data: GetSbom.Request, initialFilters?: { }) => { isLoading: boolean, data?: GetSbom.Response | undefined, error: boolean };
export const useSbom: UseSbom = (props) => {
    const [{ loading, error, data }, setState] = React.useState<UseSbomState>({
        loading: false,
        error: false
    });

    const getData = useCallback(async () => {
        setState(prevState => ({ ...prevState, loading: true, error: false }));
        try {
            const res = await sbomService.getSbom({ ...props });
            setState(({ loading: false, error: false, data: { ...res.data, sbomPackages: flattenSbomPackagesManager(res.data.sbomPackages) } }));
        } catch (error) {
            setState({ loading: false, error: true, data: undefined });
        }
    }, [props.entityId]);

    React.useEffect(() => {
        setState(prevState => ({ ...prevState, loading: false, error: false }));
    }, [props.entityId]);

    React.useEffect(() => {
        if (!loading && !error) {
            getData();
        }
    }, [loading, error, getData]);

    return ({
        isLoading: loading,
        data,
        error
    });
};