import React from 'react';
import DeleteRegistryModal from './DeleteRegistryModal';
import EditRegistryModal from './EditRegistryModal';

export enum RegistryPageModalsTypes {
    DELETE = 'DELETE',
    EDIT = 'EDIT'
}

export interface RegistryPageModalsProps {
    registryId: string;
    modalType: RegistryPageModalsTypes | undefined;
    closeModal: () => void;
}

const RegistryPageModals: React.FunctionComponent<RegistryPageModalsProps> = ({ modalType, ...rest }) => {
    switch (modalType) {
        case RegistryPageModalsTypes.DELETE:
            return <DeleteRegistryModal {...rest} />;
        case RegistryPageModalsTypes.EDIT:
            return <EditRegistryModal {...rest} />;
        default:
            return null;
    }
};

export default RegistryPageModals;
