import React, { useState } from 'react';
import { Button, Modal, TextField, Icon } from '@dome9/berries/react-components';
import { CreateServiceAccountResponse } from '../../../services/service-account/service-account.interface';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from './AddServiceAccountModal.styled';
import { Stack, Typography } from 'common/design-system/components-v2';

interface AddServiceAccountModalProps {
    onClose: () => void;
    modalOpen: boolean;
    onConfirm: (data: string) => Promise<void>;
    newServiceAccountDetails?: Pick<CreateServiceAccountResponse, 'apiKeySecret' | 'apiKeyId'> | null;
}

const AddServiceAccountModal: React.FC<AddServiceAccountModalProps> = ({
    onClose,
    modalOpen,
    onConfirm,
    newServiceAccountDetails
}) => {
    const [input, setInput] = useState('');
    const { t } = useTranslation(['k8s_common', 'k8s_service-account']);

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        getNotificationsService().addNotification({
            type: NotificationType.INFO,
            title: t('copiedToClipboard'),
            text: '',
        });
    };

    return (
        <Modal.ModalDialog isOpen={modalOpen} width='lg' shouldCloseOnOverlayClick onRequestClose={onClose}>
            <Modal.ModalHeader title={t('k8s_service-account:addServiceAccount.title')} onClose={onClose} />
            <Modal.ModalContent>
                {newServiceAccountDetails ? (
                    <Stack spacing={4}>
                        <Typography variant='bodyLg'>{t('k8s_service-account:addServiceAccount.subTitle')}</Typography>
                        <Stack spacing={2}>
                            <Typography>{t('k8s_service-account:fields.apiKey')}</Typography>
                            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                <Typography variant='bodyLg'>{newServiceAccountDetails.apiKeyId}</Typography>
                                <CopyToClipboard onClick={() => copyToClipboard(newServiceAccountDetails.apiKeyId)}>
                                    <Icon name={'copy'} />
                                </CopyToClipboard>
                            </Stack>

                        </Stack>
                        <Stack spacing={2}>
                            <Typography>{t('k8s_service-account:fields.apiSecret')}</Typography>
                            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                <Typography variant='bodyLg'>{newServiceAccountDetails.apiKeySecret}</Typography>
                                <CopyToClipboard onClick={() => copyToClipboard(newServiceAccountDetails.apiKeySecret)}>
                                    <Icon name={'copy'} />
                                </CopyToClipboard>
                            </Stack>

                        </Stack>
                    </Stack>
                ) : (
                    <Stack spacing={4}>
                        <TextField required label={t('k8s_service-account:fields.name')} onChange={(e) => setInput(e.target.value)} />
                    </Stack>
                )}
            </Modal.ModalContent>

            <Modal.ModalFooter>
                <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                    <Button onClick={onClose}>{!newServiceAccountDetails ? t('k8s_common:cancel') : t('k8s_common:ok')}</Button>
                    {!newServiceAccountDetails && <Button color='primary' disabled={!input.length} onClick={() => onConfirm(input)}>{t('k8s_common:ADD')}</Button>}
                </Stack>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};

export default AddServiceAccountModal;

