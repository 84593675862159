import { K8sStoreService } from 'modules/workloads/initialize.redux';
import { useParams } from 'react-router-dom';
import { CloudFormParams } from '../awpOnboardingAws.types';
import { InAccountScannerVPC } from 'modules/workloads/aws/tabs/awp/components/Modals/AdvancedConfig/AdvancedConfig.types';

const useInitialValues = (): CloudFormParams => {
    const { cloudAccountId } = useParams<{ cloudAccountId: string; }>();
    const { state } = K8sStoreService().getReduxTools();
    const { d9AwsAccountNumber } = state.user.cloudAccount;
    return {
        stackName: 'CloudguardAWPCrossAccountStack',
        crossAccountExternalId: btoa(`${d9AwsAccountNumber}-${cloudAccountId}`),
        crossAccountRoleName: 'CloudGuardAWPCrossAccountRole',
        hubId: null,
        customVpc: InAccountScannerVPC.ManagedByAWP,
    };
};

export default useInitialValues;