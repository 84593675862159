import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { AWSNodeGroupRoleAuthExplanation, GcpGkeInternalAuthExplanation } from 'modules/workloads/pages/registry/RegistryOnboarding/components';
import AWSCrossAccountRoleAuthExplanation from 'modules/workloads/pages/registry/RegistryOnboarding/components/AWSCrossAccountRoleAuthExplanation';
import AWSCrossAccountRoleAuthExplanationForEcsScanner from 'modules/workloads/pages/registry/RegistryOnboarding/components/AWSCrossAccountRoleAuthExplanationForEcsScanner';
import EcsTaskRoleAuthExplanation from 'modules/workloads/pages/registry/RegistryOnboarding/components/EcsTaskRoleAuthExplanation';
import { ReactElement } from 'react';

export enum RegistryType {
    ACR = 'ACR',
    ECR = 'ECR',
    GCR = 'GCR',
    GAR = 'GAR',
    JFrogArtifactory = 'Artifactory',
    Harbor = 'Harbor',
    Nexus = 'Nexus',
    GitHub = 'GitHub',
}

type AcrAuthMethod = 'AcrServicePrincipal' | 'AcrManagedId';
type EcrAuthMethod = 'EcrUserAccessKeys' | 'EcrNodeGroupRole' | 'EcrCustomRole' | 'EcrEcsTaskRole';
type GcrAuthMethod = 'GcpServiceAccountKey' | 'GcpGkeInternalAuth';
type GarAuthMethod = 'GcpServiceAccountKey' | 'GcpGkeInternalAuth';
type JfrogArtifactoryAuthMethod = 'ArtifactoryUserPassword' | 'ArtifactoryToken';
type HarborAuthMethod = 'HarborUserPassword';
type NexusAuthMethod = 'NexusUserPassword';
type GithubToken = 'GithubToken';
export type RegistryAuthMethod = 
    AcrAuthMethod
    | EcrAuthMethod
    | GcrAuthMethod
    | GarAuthMethod
    | JfrogArtifactoryAuthMethod
    | HarborAuthMethod
    | NexusAuthMethod
    | GithubToken;


export enum RegistryTypeTranslations {
    ACR = 'ON_BOARDING.AZURE_CONTAINER_REGISTRY',
    ECR = 'ON_BOARDING.ELASTIC_CONTAINER_REGISTRY',
    GCR = 'ON_BOARDING.GCR_CONTAINER_REGISTRY',
    GAR = 'ON_BOARDING.GAR_ARTIFACTORY_REGISTRY',
    Artifactory = 'ON_BOARDING.JFROG_ARTIFACTORY_CONTAINER_REGISTRY',
    Harbor = 'ON_BOARDING.HARBOR_CONTAINER_REGISTRY',
    Nexus = 'ON_BOARDING.NEXUS_CONTAINER_REGISTRY',
    GitHub = 'ON_BOARDING.GITHUB_CONTAINER_REGISTRY'
}

export enum AuthenticationInputType {
    PullSecretName,
    TenantId,
    ApplicationClientId,
    RoleARN,
}

export type AuthenticationMethod = {
    title: string,
    key: RegistryAuthMethod,
    inputs: Array<AuthenticationInputType>,
    component?: ReactElement,
};

export type RegistryScannerType = 'kubernetes' | 'ECS';
export enum ScanningEnvironmentType {
    Kubernetes = 'Kubernetes',
    AWS = 'AWS',
}

type Registry = {
    name: string,
    icon: AllIconsName,
    authMethods: {
        [key in ScanningEnvironmentType]: Array<AuthenticationMethod>;
    },
}

type Registries = {
    [key in RegistryType]: Registry
}

export const getRegistryOptions: () => Registries = () => { 
    const registryOptions: Registries = {
        [RegistryType.ACR]: {
            name: RegistryTypeTranslations.ACR,
            icon: 'azure',
            authMethods: {
                Kubernetes: [
                    { title: 'ON_BOARDING.AZURE_SERVICE_PRINCIPAL', key: 'AcrServicePrincipal', inputs: [AuthenticationInputType.PullSecretName, AuthenticationInputType.TenantId] },
                    { title: 'ON_BOARDING.AZURE_MANAGED', key: 'AcrManagedId', inputs: [AuthenticationInputType.ApplicationClientId] }
                ],
                AWS: [
                    { title: 'ON_BOARDING.AZURE_SERVICE_PRINCIPAL', key: 'AcrServicePrincipal', inputs: [AuthenticationInputType.TenantId] },
                ],
            },
        },
        [RegistryType.ECR]: {
            name: RegistryTypeTranslations.ECR,
            icon: 'aws',
            authMethods: {
                Kubernetes: [
                    { title: 'ON_BOARDING.AWS_USER_ACCESS_KEYS', key: 'EcrUserAccessKeys', inputs: [AuthenticationInputType.PullSecretName] },
                    { title: 'ON_BOARDING.AWS_NODE_GROUP_ROLE', key: 'EcrNodeGroupRole', inputs: [], component: <AWSNodeGroupRoleAuthExplanation/> },
                    { title: 'ON_BOARDING.AWS_CUSTOM_ROLE', key: 'EcrCustomRole', inputs: [AuthenticationInputType.RoleARN], component: <AWSCrossAccountRoleAuthExplanation/> },
                ],
                AWS: [
                    { title: 'ON_BOARDING.AWS_USER_ACCESS_KEYS', key: 'EcrUserAccessKeys', inputs: [] },
                    { title: 'ON_BOARDING.ECS_TASK_ROLE', key: 'EcrEcsTaskRole', inputs: [], component: <EcsTaskRoleAuthExplanation/> },
                    { title: 'ON_BOARDING.AWS_CUSTOM_ROLE', key: 'EcrCustomRole', inputs: [AuthenticationInputType.RoleARN], component: <AWSCrossAccountRoleAuthExplanationForEcsScanner/> },
                ],
            },
        },
        [RegistryType.GCR]: {
            name: RegistryTypeTranslations.GCR,
            icon: 'gcp',
            authMethods: {
                Kubernetes: [
                    { title: 'ON_BOARDING.GCP_SERVICE_ACCOUNT_KEY', key: 'GcpServiceAccountKey', inputs: [AuthenticationInputType.PullSecretName] },
                    { title: 'ON_BOARDING.GCP_GKE_INTERNAL_AUTH', key: 'GcpGkeInternalAuth', inputs: [], component: <GcpGkeInternalAuthExplanation /> }
                ],
                AWS: [
                    { title: 'ON_BOARDING.GCP_SERVICE_ACCOUNT_KEY', key: 'GcpServiceAccountKey', inputs: [] },
                ],
            },
        },
        [RegistryType.GAR]: {
            name: RegistryTypeTranslations.GAR,
            icon: 'gcp',
            authMethods: {
                Kubernetes: [
                    { title: 'ON_BOARDING.GCP_SERVICE_ACCOUNT_KEY', key: 'GcpServiceAccountKey', inputs: [AuthenticationInputType.PullSecretName] },
                    { title: 'ON_BOARDING.GCP_GKE_INTERNAL_AUTH', key: 'GcpGkeInternalAuth', inputs: [], component: <GcpGkeInternalAuthExplanation /> }
                ],
                AWS: []
            },
        },
        [RegistryType.JFrogArtifactory]: {
            name: RegistryTypeTranslations.Artifactory,
            icon: 'jfrog',
            authMethods: {
                Kubernetes: [
                    { title: 'ON_BOARDING.JFROG_ARTIFACTORY_BASIC_AUTHENTICATION', key: 'ArtifactoryUserPassword', inputs: [AuthenticationInputType.PullSecretName] },
                    { title: 'ON_BOARDING.JFROG_ARTIFACTORY_ACCESS_TOKEN', key: 'ArtifactoryToken', inputs: [AuthenticationInputType.PullSecretName] }
                ],
                AWS: [
                    { title: 'ON_BOARDING.JFROG_ARTIFACTORY_BASIC_AUTHENTICATION', key: 'ArtifactoryUserPassword', inputs: [] },
                    { title: 'ON_BOARDING.JFROG_ARTIFACTORY_ACCESS_TOKEN', key: 'ArtifactoryToken', inputs: [] }
                ]
            },
        },
        [RegistryType.Harbor]: {
            name: RegistryTypeTranslations.Harbor,
            icon: 'harbor',
            authMethods: {
                Kubernetes: [
                    { title: 'ON_BOARDING.HARBOR_CREDENTIALS', key: 'HarborUserPassword', inputs: [AuthenticationInputType.PullSecretName] },
                ],
                AWS: [
                    { title: 'ON_BOARDING.HARBOR_CREDENTIALS', key: 'HarborUserPassword', inputs: [] },
                ]
            },
        },
        [RegistryType.Nexus]: {
            name: RegistryTypeTranslations.Nexus,
            icon: 'sonataNexus',
            authMethods: {
                Kubernetes: [
                    { title: 'ON_BOARDING.NEXUS_BASIC_AUTH', key: 'NexusUserPassword', inputs: [AuthenticationInputType.PullSecretName] },
                ],
                AWS: [
                    { title: 'ON_BOARDING.NEXUS_BASIC_AUTH', key: 'NexusUserPassword', inputs: [] },
                ]
            },
        },
        [RegistryType.GitHub]: {
            name: RegistryTypeTranslations.GitHub,
            icon: 'github',
            authMethods: {
                Kubernetes: [
                    { title: 'ON_BOARDING.GITHUB_CONTAINER_REGISTRY_PERSONAL_ACCESS_TOKEN', key: 'GithubToken', inputs: [AuthenticationInputType.PullSecretName] },
                ],
                AWS: [
                    { title: 'ON_BOARDING.GITHUB_CONTAINER_REGISTRY_PERSONAL_ACCESS_TOKEN', key: 'GithubToken', inputs: [] },
                ]
            },
        },
    };

    return registryOptions;
};

const shouldUseInputOfType = (
    authInputType: AuthenticationInputType,
) => {
    return (
        registryType: RegistryType,
        scannerType: ScanningEnvironmentType = ScanningEnvironmentType.Kubernetes,
        authMethodKey: RegistryAuthMethod,
    ) => {
        const RegistryOptions = getRegistryOptions();
        const authMethod = RegistryOptions[registryType].authMethods[scannerType].find(authMethod => authMethod.key === authMethodKey);
        if (!authMethod) return false;
        return authMethod.inputs.includes(authInputType);
    };
};

export const getRegistryIconByType = (type: RegistryType) => {
    switch (type) {
        case RegistryType.ECR: {
            return 'aws__Architecture-Service-Icons__Arch_Compute__16__Arch_Amazon-Elastic-Container-Service_16';
        }
        case RegistryType.ACR: {
            return 'azure__maintained-by-azure__containers__10105-icon-service-Container-Registries';
        }
        case RegistryType.JFrogArtifactory: {
            return 'jfrog';
        }
        case RegistryType.GCR: {
            return 'gcp__container_registry__container_registry';
        }
        case RegistryType.GAR: {
            return 'gcp__container_registry__container_registry';
        }
        case RegistryType.Harbor: {
            return 'harbor';
        }
        case RegistryType.Nexus: {
            return 'sonataNexus';
        }
        case RegistryType.GitHub: {
            return './app-assets/vendor-icons/logos/github.svg';
        }
        default: {
            return 'containers-registry';
        }
    }
};

export const shouldUsePullSecretName = shouldUseInputOfType(AuthenticationInputType.PullSecretName);
export const shouldUseTenantId = shouldUseInputOfType(AuthenticationInputType.TenantId);
export const shouldUseApplicationClientId = shouldUseInputOfType(AuthenticationInputType.ApplicationClientId);
export const shouldUseRoleARN = shouldUseInputOfType(AuthenticationInputType.RoleARN);
