import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import {
    CreateShiftLeftAccount,
    UpdateShiftLeftAccountName,
    UpdateShiftLeftAccountOU,
    DeleteForceShiftLeftAccount,
    DeleteShiftLeftAccount,
    GetShiftLeftAccount,
    GetShiftLeftBlades,
    UpdateShiftLeftAccountDescription
} from './shiftLeftAccount.interface';

const createAccount: CreateShiftLeftAccount.Function = async (data) => {
    return await getHttpService().request<AxiosResponse<CreateShiftLeftAccount.Response>>(
        'ShiftLeftAccount', 
        { method: 'POST', data },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const updateName: UpdateShiftLeftAccountName.Function = async request => {
    return await getHttpService().request<AxiosResponse<UpdateShiftLeftAccountName.Response>>(
        `ShiftLeftAccount/${request.id}/accountName?name=${request.name}`,
        { method: 'PUT' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const updateDescription: UpdateShiftLeftAccountDescription.Function = async ({ id, description }) => {
    return await getHttpService().request<AxiosResponse<any>>(
        `ShiftLeftAccount/${id}/accountDescription?description=${description}`,
        { method: 'PUT' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const updateOU: UpdateShiftLeftAccountOU.Function = async request => {
    return await getHttpService().request<AxiosResponse<UpdateShiftLeftAccountOU.Response>>(
        `ShiftLeftAccount/${request.id}/organizationalUnit`,
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const deleteForce: DeleteForceShiftLeftAccount.Function = async request => {
    return await getHttpService().request<AxiosResponse<DeleteForceShiftLeftAccount.Response>>(
        `ShiftLeftAccount/${request.id}/DeleteForce`,
        { method: 'DELETE' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const deleteAccount: DeleteShiftLeftAccount.Function = async request => {
    return await getHttpService().request<AxiosResponse<DeleteShiftLeftAccount.Response>>(
        `ShiftLeftAccount/${request.id}`,
        { method: 'DELETE' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const getAccount: GetShiftLeftAccount.Function = async (request, useCache = false) => {
    return await getHttpService().request<AxiosResponse<GetShiftLeftAccount.Response>>(
        `ShiftLeftAccount/${request.id}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache } },
        err => { throw err; }
    );
};

const getBlades: GetShiftLeftBlades.Function = async (request, useCache = false) => {
    return await getHttpService().request<AxiosResponse<GetShiftLeftBlades.Response>>(
        `ShiftLeft/blades/${request.os}/${request.arch}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache } },
        err => { throw err; }
    );
};

const downloadFile: GetShiftLeftBlades.Function = async (request, useCache = false) => {
    return await getHttpService().request<AxiosResponse<any>>(
        `workload-client/modules/shiftleft/${request.os}/${request.arch}/download`,
        { method: 'GET', responseType: 'blob' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache } },
        err => { throw err; }
    );
};

const ShiftLeftAccountService = {
    createAccount,
    updateName,
    updateDescription,
    updateOU,
    deleteForce,
    getAccount,
    getBlades,
    deleteAccount,
    downloadFile
};

export default ShiftLeftAccountService;
