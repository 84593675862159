import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Icon, Label } from '@dome9/berries/react-components';
import { Title, ButtonWithLoader, AlertSecondary } from './OperatingSystemContent.styled';
import { OperatingSystemsEnum } from '../../ShiftleftOnboarding/steps/OperatingSystem/OperatingSystem.types';
import ShiftLeftAccountService from '../../../../services/shiftLeft/shiftLeftAccount.service';
import SelectOS from './SelectOS';
import { linuxCommand, macCommand } from './commands';
import { useTranslation } from 'react-i18next';
import { Stack, Command, Spinner } from 'common/design-system/components-v2';

const OperatingSystemContent: React.FC<{ isModal?: boolean }> = ({ isModal = false }) => {
    const [isDownload, setIsDownload] = useState<{ isLoading: boolean; archType: '386' | 'amd64' | '' }>({ isLoading: false, archType: '' });
    const { watch } = useFormContext();
    const operatingSystemsSelectedValue = watch('operatingSystems');
    const { t } = useTranslation('k8s_shiftleft');

    useEffect(() => {
        return () => {
            const element = document.getElementById('download-binary-file');
            element?.remove();
        };
    }, []);

    const downloadBinaryFile = async (os: OperatingSystemsEnum | 'darwin', arch: 'amd64') => {
        setIsDownload({ isLoading: true, archType: arch });
        if(os === OperatingSystemsEnum.Mac){
            os = 'darwin';
        }

        try {
            const osLowerCase = os.toLowerCase();
            const response = await ShiftLeftAccountService.downloadFile({ os: osLowerCase, arch });
            const aTag = document.createElement('a');
            const contentDisposition = response.headers['content-disposition'];
            let fileName = contentDisposition
                ? contentDisposition.split('filename=')[1].split(';')[0]
                : 'shiftleft';
            if(os === OperatingSystemsEnum.Windows && !fileName.endsWith('.exe')){
                fileName += '.exe' ; 
            }
            const blob = new Blob([response.data as any], {
                type: (response.data as any).type,
            });

            const url = window.URL.createObjectURL(blob);
            aTag.setAttribute('href', url);
            aTag.setAttribute('target', '_blank');
            aTag.setAttribute('id', 'download-binary-file');
            aTag.setAttribute('download', fileName);
            aTag.style.display = 'none';
            aTag.click();
            setIsDownload({ isLoading: false, archType: '' });
        } catch (e) {
            setIsDownload({ isLoading: false, archType: '' });
        }
    };
    

    return (
        <div>
            <Stack spacing={4}>
                {!isModal && <Title>{t('shiftLeftOnboarding.steps.operatingSystem.title')}</Title>}
                <Stack padding={isModal ? [0] : [0,0,0,4]} spacing={isModal ? 4 : 8}>
                    <SelectOS />

                    {operatingSystemsSelectedValue && <Stack spacing={1}>
                        <Label label={`${t('shiftLeftOnboarding.steps.operatingSystem.subtitle')}:`} />
                        <Stack direction={'row'} alignItems={'center'} spacing={2}>
                            <ButtonWithLoader
                                color='primary'
                                onClick={() => downloadBinaryFile(operatingSystemsSelectedValue, 'amd64')}
                            >
                                <>{isDownload && isDownload.archType === 'amd64' && <span><Spinner/></span>}</>
                                x64 for {operatingSystemsSelectedValue}
                            </ButtonWithLoader>
                        </Stack>
                    </Stack>}
                    <Stack spacing={4}>
                        {
                            operatingSystemsSelectedValue === OperatingSystemsEnum.Mac ? (
                                <>
                                    <Stack spacing={1} style={{ width: '500px' }}>
                                        <Label label={`${t('shiftLeftOnboarding.steps.operatingSystem.executable')}:`}/>
                                        <Command text={macCommand} />
                                    </Stack>
                                </>

                            ) : (operatingSystemsSelectedValue === OperatingSystemsEnum.Windows) ? (
                                <Stack spacing={1}>
                                    <Label label={`${t('shiftLeftOnboarding.steps.operatingSystem.executable')}:`}/>
                                    <AlertSecondary>
                                        <Icon size={14} color={'info'} name={'info'}/>
                                        <span>{t('shiftLeftOnboarding.steps.operatingSystem.windows.command')}</span>
                                    </AlertSecondary>
                                </Stack>

                            ) : (operatingSystemsSelectedValue === OperatingSystemsEnum.Linux) ? (
                                <Stack spacing={1}>
                                    <Label label={`${t('shiftLeftOnboarding.steps.operatingSystem.executable')}:`}/>
                                    <div style={{ width: '400px' }}><Command text={linuxCommand} /></div>
                                </Stack>
                            ) : null
                        }
                    </Stack>
                </Stack>
            </Stack>
        </div>
    );
};

export default OperatingSystemContent;