import { Button } from 'common/design-system/components-v2';
import { getCloudAccountsService } from 'common/interface/data_services';
import { NotificationType } from 'common/interface/notifications';
import { getNotificationsService } from 'common/interface/services';
import { changeUrl } from 'common/utils/http';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import AwpService from 'modules/workloads/services/awp/awp.service';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonsWrapper } from '../awpUpdate.styled';

const ValidateButton: React.FC<{ cloudAccountId: string }> = ({ cloudAccountId }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));
    const [waitingOnUpdate, setWaitingOnUpdate] = useState<boolean>(false);

    const checkForUpdate = async () => {
        setWaitingOnUpdate(true);
        try {
            const res = await AwpService.checkForUpdate(cloudAccountId);

            setWaitingOnUpdate(false);

            if (res.data.shouldUpdate) {
                getNotificationsService().addNotification({
                    type: NotificationType.WARNING,
                    title: t('update.stack.messages.stackHasNotBeenUpdatedYet.title'),
                    text: t('update.stack.messages.stackHasNotBeenUpdatedYet.text'),
                });
                return;
            }
            
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                title: t('update.stack.messages.success'),
                text: '',
            });
            getCloudAccountsService().clearCache();
            changeUrl('/cloud-account/index');
        } catch (error: any) {
            setWaitingOnUpdate(false);
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: t('update.stack.messages.failure'),
                text: '',
            });
        }
    };

    return (
        <ButtonsWrapper>
            <Button onClick={checkForUpdate} color='brandPrimary' disabled={waitingOnUpdate} loading={waitingOnUpdate}>{waitingOnUpdate ? t('update.checkButton.pendingLabel') : t('update.checkButton.idleLabel')}</Button>
        </ButtonsWrapper>
    );

};

export default ValidateButton;