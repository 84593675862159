import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExampleState, ExmapleInitialState } from './example.interface';

const ExampleSlice = createSlice({
    name: 'example',
    initialState: ExmapleInitialState,
    reducers: {
        setTest: (state: ExampleState, action: PayloadAction<ExampleState['test']>) => {
            state.test = action.payload;
        },
        setLoading: (state: ExampleState, action: PayloadAction<ExampleState['loading']>) => {
            state.loading = action.payload;
        },
        setLoadingError: (state: ExampleState, action: PayloadAction<ExampleState['loadingError']>) => {
            state.loadingError = action.payload;
        }
    }
});

export default ExampleSlice;