import { ClickEventData, EventType, Widget } from '@dome9/cloudguard-widgets-dashboard';
import { DeltaCounterTypes, ResourceType, TopRiskItemsType } from '@dome9/cloudguard-widgets-widgets';
import { findingPageFilters, threatAndSecurityEventsPageFilters, vulnerabilityPageFilters } from './events.filters';
import { BaseUrl, FilterField, Status, Time, TimeData, ResourceType as ResourceTypeQuery } from './events.interface';
import { capitalizeFirstLetter, generateUrl, getTimeQueryData, getUrlOf, openWidgetLink, parseIdString, relevantFilterFields, severityNumberToString } from './utils';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { generateHref } from 'common/utils/http';

const getUrlOfThreatAndSecurityEventsPage = (filterFields: FilterField[], time: Time): string => {
    return getUrlOf(BaseUrl.ThreatAndSecurityEventsPage, filterFields, time);
};

const getUrlOfAlertsFindingsPage = (filterFields: FilterField[], time: Time): string => {
    return getUrlOf(BaseUrl.AlertsFindings, filterFields, time);
};

const getUrlOfVulnerabilitiesPage = (filterFields: FilterField[], time: Time): string => {
    return getUrlOf(BaseUrl.ImageAssurance_Vulnerabilities, filterFields, time, [`${vulnerabilityPageFilters.IS_HAS_REMEDIATION.name}=${vulnerabilityPageFilters.IS_HAS_REMEDIATION.value}`]);
};

const handleComplianceScoreClicked = (eventData: ClickEventData): string => {
    return getUrlOf(BaseUrl.PostureManagement_AssessmentHistory, [], eventData.time as Time);
};

const handleSecretsClicked = (eventData: ClickEventData): string => {
    const relevantFilters = relevantFilterFields(
        {
            organizationalUnitId: 'organizationalUnitId',
            platform: 'cloudAccountType',
            cloudAccountId: 'cloudAccountId_calc',
            category: 'category',
        },
        eventData.filters || []
    );
    
    return getUrlOfAlertsFindingsPage([
        ...relevantFilters,
        findingPageFilters.SHOW_ACKNOWLEDGED.FALSE,
        findingPageFilters.SHOW_EXCLUDED.FALSE,
    ], eventData.time as Time);
};

const handleSecurityEventsClicked = (eventData: ClickEventData): string => {
    const relevantFilters = relevantFilterFields(
        { 
            organizationalUnitId: 'organizationalUnitId',
            platform: 'cloudAccountType',
            cloudAccountId: 'cloudAccountId_calc',
            isExcluded: 'isExcluded',
            acknowledged: 'acknowledged',
            origin: 'origin',
            severity: 'severity',
        },
        eventData.filters || []
    );

    relevantFilters.forEach(filter => {
        if (filter.name === 'origin') {
            filter.value = filter.value.toString();
        }
    });

    return getUrlOfThreatAndSecurityEventsPage([
        ...relevantFilters,
    ], eventData.time as Time);
};

const handleRuntimeProtectionClicked = (eventData: ClickEventData): string => {
    const relevantFilters = relevantFilterFields(
        { platform: 'cloudAccountType' },
        eventData.filters || []
    );
    return getUrlOf(BaseUrl.WorkloadProtection_Environments, relevantFilters);
};

const handleCriticalFixableClicked = (eventData: ClickEventData): string => {
    const relevantFilters = relevantFilterFields(
        { organizationalUnitId: 'organizationalUnitId', platform: 'cloudAccountType', cloudAccountId: 'cloudAccountId_calc' },
        eventData.filters || []
    );
    return getUrlOfVulnerabilitiesPage([
        ...relevantFilters,
        findingPageFilters.SEVERITY.CRITICAL,
        findingPageFilters.SHOW_ACKNOWLEDGED.FALSE,
        findingPageFilters.SHOW_EXCLUDED.FALSE,
    ], eventData.time as Time);
};

const handleImageViolationsClicked = (eventData: ClickEventData): string => {
    const baseUrl = BaseUrl.ImageAssurance_Vulnerabilities;
    const relevantFilters = relevantFilterFields(
        {
            organizationalUnitId: 'organizationalUnitId',
            cloudAccountId: 'cloudAccountId_calc',
            category: 'category',
            alertType: 'alertType',
            entityTypeByEnvironmentType: 'entityTypeByEnvironmentType',
        },
        eventData.filters || []
    );

    relevantFilters.forEach(filter => {
        if (filter.name === 'alertType') {
            filter.value = filter.value.toString();
        }
    });

    const time = typeof eventData.time !== 'string' ? eventData.time as TimeData : eventData.time;
    const timeQueryData = getTimeQueryData(time);

    const queryData = {
        pageNumber: 1,
        sorting: {
            fieldName: 'createdTime',
            direction: -1
        },
        filter: {
            fields: relevantFilters,
        }
    };

    return generateUrl({ baseUrl, queryData, timeData: timeQueryData });
};

const handleCounterResourcesClicked = (eventData: ClickEventData): string | void => {
    switch (eventData.entityType) {
        case ResourceType.Clusters: // kubernetes
        case ResourceType.Registries: // containerregistry
        case ResourceType.Pipelines: { // shiftleft
            let filterFields: Array<any> = [{
                name: 'resource-type',
                value: ResourceTypeQuery[eventData.entityType]
            }];

            const status = eventData.filters?.find(filter => filter.name === 'status')?.value;

            if (status && status !== 'all') {
                filterFields = [...filterFields, {
                    name: 'status',
                    value: Status[status as keyof typeof Status]
                }];
            }

            const queryData = { filter: { filterFields } };

            return generateUrl({ baseUrl: BaseUrl.WorkloadProtection_Environments, queryData });
        }
        case ResourceType.KubernetesNode: {
            const nodesQueryData = {
                filter: {
                    fields: [
                        {
                            name: 'type',
                            value: 'kubernetes|KubernetesNode'
                        },
                    ]
                }
            };

            return generateUrl({ baseUrl: BaseUrl.Assets_ProtectedAssets, queryData: nodesQueryData });
        }}
};

const handleDeltaCounterClicked = (eventData: ClickEventData): string | void => {
    switch (eventData.entityType) {
        case DeltaCounterTypes.SECURITY_EVENTS: return handleSecurityEventsClicked(eventData);
        case DeltaCounterTypes.SECRETS: return handleSecretsClicked(eventData);
        case DeltaCounterTypes.CRITICAL_FIXABLE_VUL: return handleCriticalFixableClicked(eventData);
        case DeltaCounterTypes.IMAGE_VIOLATIONS: return handleImageViolationsClicked(eventData);
        case DeltaCounterTypes.RUNTIME_PROTECTION: return handleRuntimeProtectionClicked(eventData);
    }
};

const imagePageUrl = (cloudAccountId: string, assetType: string, assetId: string, platform: string): string => {
    return `/workload/images/generic?cloudAccountId=${cloudAccountId}&assetType=${assetType}&assetId=${assetId}&platform=${platform}`;
};

const workloadPageUrl = (cloudAccountId: string, assetType: string, assetId: string, platform: string, instanceid: string, isGeneric: boolean, type?: number): string => {
    return `/workload/workloads/protected-asset${isGeneric ? '-generic' : ''}?cloudaccountid=${cloudAccountId}&instanceid=${instanceid}&assetType=${assetType}&type=${type}&assetid=${assetId}&platform=${platform}`;
};

const handleSecurityIncidentsClicked = (eventData: ClickEventData): string | void => {
    const relevantFilters = relevantFilterFields(
        {
            organizationalUnitId: 'organizationalUnitId',
            platform: 'cloudAccountType',
            cloudAccountId: 'cloudAccountId_calc',
            Severity: 'severity',
            acknowledged: 'acknowledged',
            isExcluded: 'isExcluded',
            alertType: 'alertType',
            origin: 'origin'
        },
        eventData.filters || []
    );

    relevantFilters.forEach(filter => {
        if (filter.name === 'severity') {
            filter.value = capitalizeFirstLetter(filter.value);
        }
    });

    switch (eventData.entityType) {
        case 'all':
            return getUrlOfThreatAndSecurityEventsPage([
                ...relevantFilters,
                threatAndSecurityEventsPageFilters.SOURCE.INTELLIGENCE,
                threatAndSecurityEventsPageFilters.SOURCE.RUNTIME_PROTECTION,
                threatAndSecurityEventsPageFilters.SOURCE.ADDMISION_CONTROL,
            ], eventData.time as Time);
        case 'intelligence':
            return getUrlOfThreatAndSecurityEventsPage([
                ...relevantFilters,
                threatAndSecurityEventsPageFilters.SOURCE.INTELLIGENCE,
            ], eventData.time as Time);
        case 'runtimeProtection':
            return getUrlOfThreatAndSecurityEventsPage([
                ...relevantFilters,
                threatAndSecurityEventsPageFilters.SOURCE.RUNTIME_PROTECTION,                
            ], eventData.time as Time);
        case 'admissionControl':
            return getUrlOfThreatAndSecurityEventsPage([
                ...relevantFilters,
                threatAndSecurityEventsPageFilters.SOURCE.ADDMISION_CONTROL,
            ], eventData.time as Time);
    }
};

const handleCreatedAndResolvedIssuesClicked = (eventData: ClickEventData): string => {
    const relevantFilters = relevantFilterFields(
        {
            organizationalUnitId: 'organizationalUnitId',
            platform: 'cloudAccountType',
            cloudAccountId: 'cloudAccountId_calc',
            origin: 'origin',
            severity: 'severity',
        },
        eventData.filters || []
    );

    relevantFilters.forEach(filter => {
        if (filter.name === 'severity') {
            filter.value = capitalizeFirstLetter(severityNumberToString(filter.value));
        }
        if (filter.name === 'origin') {
            filter.value = filter.value.toString();
        }
    });

    return getUrlOfAlertsFindingsPage([
        ...relevantFilters,
        findingPageFilters.SHOW_ACKNOWLEDGED.FALSE,
        findingPageFilters.SHOW_EXCLUDED.FALSE,
    ], eventData.time as Time);
};

const handleComplianceByFrameworkClicked = (eventData: ClickEventData): string => {
    const baseUrl = BaseUrl.PostureManagement_AssessmentHistory;

    const relevantFilters = relevantFilterFields(
        { bundleId: 'bundleId' },
        eventData.filters || []
    );

    const queryData = {
        pageNumber: 1,
        sorting: {
            fieldName: 'createdTime',
            direction: -1
        },
        filterFields: [
            ...relevantFilters
        ],
        rulesetTypes: ['imageassurance'],
        HideCount: true
    };
    
    const timeQueryData = {
        time: '7 Days'
    };

    return generateUrl({ baseUrl, queryData, timeData: timeQueryData });
};

const handleVulnerabilitiesClicked = (eventData: ClickEventData): string => {
    const relevantFilters = relevantFilterFields(
        { organizationalUnitId: 'organizationalUnitId', platform: 'cloudAccountType', cloudAccountId: 'cloudAccountId_calc', severity: 'severity', category: 'category' },
        eventData.filters || []
    );

    relevantFilters.forEach(filter => {
        if (filter.name === 'severity') {
            filter.value = capitalizeFirstLetter(filter.value);
        }
    });
    
    return getUrlOf(BaseUrl.ImageAssurance_Vulnerabilities, relevantFilters);
};

const PlatformNumberToString: { [num: number]: string } = {
    1: 'aws',
    11: 'kubernetes',
    15: 'shiftleft',
    20: 'containerregistry'
};

const handleTopRiskiestItemsClicked = (eventData: ClickEventData): string => {
    const clickedItemId = eventData.filters?.find(filter => filter.name === 'id');
    const isImage = eventData.entityType === TopRiskItemsType.Images;
    if (clickedItemId) {
        const { cloudAccountId, assetId } = parseIdString(clickedItemId.value);
        const clickedItemType = eventData.filters?.find(filter => filter.name === 'type')?.value || '';
        const asset = getProtectedAssetsService().getAssetByType(clickedItemType);
        if (!asset) return '';
        const itemPageUrl = isImage
            ? imagePageUrl(cloudAccountId, clickedItemType, assetId, asset.platform)
            : workloadPageUrl(cloudAccountId, clickedItemType, assetId, asset.platform, clickedItemId.value, asset.isGenericObject, asset.typeNumber);
        return itemPageUrl;
    } else {
        const relevantFilters = relevantFilterFields(
            {
                organizationalUnitId: 'organizationalUnitId',
                platform: 'platform',
                cloudAccountId: 'cloudAccountId',
                isRunning: 'additionalFields|IsRunning'
            },
            eventData.filters || []
        );

        relevantFilters.forEach(filter => {
            if (filter.name === 'platform') {
                filter.value = PlatformNumberToString[Number(filter.value)];
            }

            if (filter.name === 'cloudAccountId') {
                filter.value = parseIdString(filter.value).cloudAccountId;
            }
        });

        return getUrlOf(isImage ? BaseUrl.WorkloadProtection_Assets_Images : BaseUrl.WorkloadProtection_Assets_Workloads, relevantFilters);
    }
};

const widgetUrl = (widget: Widget, eventData: ClickEventData): string | void => {
    let url;

    switch (widget) {
        case 'ComplianceScore': // Summary - ComplianceScore
            url = handleComplianceScoreClicked(eventData);
            break;
        case 'DeltaCounter': // Summary - Exposed Secrets, security-events, runtime-protection, critical-fixable-vul and image-violations
            url = handleDeltaCounterClicked(eventData);
            break;
        case 'CounterResources': // Inventory
            url = handleCounterResourcesClicked(eventData);
            break;
        case 'SecurityIncidents': // Security incidents over time
            url = handleSecurityIncidentsClicked(eventData);
            break;
        case 'CreatedIssues': // Created and Resolved issues
            url = handleCreatedAndResolvedIssuesClicked(eventData);
            break;
        case 'Vulnerabilities': // Vulnerabilities
            url = handleVulnerabilitiesClicked(eventData);
            break;
        case 'ComplianceByFrameWork': // Compliance By FrameWork
            url = handleComplianceByFrameworkClicked(eventData);
            break;
        case 'TopRiskItems': // Top Riskiest Images
            url = handleTopRiskiestItemsClicked(eventData);
            break;
    }

    return url && generateHref(url);
};

const handleClickEvent = (widget: Widget, eventData: ClickEventData) => {
    const url = widgetUrl(widget, eventData);
    
    if (url) {
        return openWidgetLink(url);
    }
};

export const handleDashboardEvent = (
    widget: Widget,
    eventType: EventType,
    eventData: ClickEventData
): void => {
    switch (eventType) {
        case EventType.Click:
            handleClickEvent(widget, eventData);
            break;
    }
};

export const generateWidgetUrlMain = (
    widget: Widget,
    eventData: ClickEventData,
) => {
    return widgetUrl(widget, eventData);
};
