import React from 'react';
import { getCloudAccountsService, ICloudAccount, getOrganizationalUnitService, IOrganizationalUnit } from 'common/interface/data_services';
import { ClusterData, GenerateHelmCommandRequest, KOBMainLogicHook, KOBMainLogicHookStatus } from './KOB-MainLogic.types';
import { CreateServiceAccountResponse } from 'modules/workloads/services/service-account/service-account.interface';
import KubernetesAccountService from 'modules/workloads/services/kubernetesAccount/kubernetesAccount.service';
import { createServiceAccount, updateServiceAccount, deleteServiceAccount } from 'modules/workloads/services/service-account/service-account.service';
import { getCloudGuardRoles } from 'modules/workloads/services/cloudGuard-roles/cloudGuard-roles.service';
import { getHelmCommand, getNonHelmCommand } from 'modules/workloads/utils/KubernetesOnBoarding.helm.service';
import { KubernetesAccount, KubernetesAccountFeatureStatusChange, KubernetesFeatureStatus } from 'modules/workloads/services/kubernetesAccount/kubernetesAccount.interface';

export const useKOBMainLogic: KOBMainLogicHook = ({ features }) => {
    const [status, setStatus] = React.useState<KOBMainLogicHookStatus>(KOBMainLogicHookStatus.GateringData);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);

    const [clusterName, setClusterName] = React.useState<string | undefined>();
    const [clusterData, setClusterData] = React.useState<ClusterData>();

    const [serviceAccountName, setServiceAccountName] = React.useState<string>('randomClusterName000-serviceAccount0000');
    const [serviceAccountData, setServiceAccountData] = React.useState<CreateServiceAccountResponse | undefined>();

    const [selectedOU, setSelectedOU] = React.useState<string>('00000000-0000-0000-0000-000000000000');


    const [kubernetesCloudAccounts, setKubernetesCloudAccounts] = React.useState<Array<ICloudAccount>>([]);
    const [organizationalUnits, setOrganizationalUnits] = React.useState<IOrganizationalUnit>({ id: '', name: '', path: '' });
    const [k8sAgent, setK8sAgent] = React.useState<number | undefined>();

    // ~~ Initial data loading ~~
    const getAndSetAllCloudAccounts = React.useCallback(async () => {
        const getAllCloudAccountsRes = await getCloudAccountsService().getAllCloudAccounts(true);
        const kubernetesCloudAccountOnly = getAllCloudAccountsRes.filter(acc => acc.platform === 'kubernetes');
        setKubernetesCloudAccounts(kubernetesCloudAccountOnly);
    }, []);

    const getAndSetAllOrganizationalUnits = React.useCallback(async () => {
        const getAllOrganizationalUnitsRes = await getOrganizationalUnitService().getOrganizationalUnitsView();
        setOrganizationalUnits(getAllOrganizationalUnitsRes);
    }, []);

    const getK8sAgentRole = React.useCallback(async () => {
        const allDome9Roles = await getCloudGuardRoles();
        const k8sAgentRole = allDome9Roles.find(role => role.name.toLowerCase() === 'kubernetes agent');
        if (!k8sAgentRole) return;
        setK8sAgent(k8sAgentRole.id);
    }, []);

    React.useEffect(() => {
        getAndSetAllCloudAccounts();
        getAndSetAllOrganizationalUnits();
        getK8sAgentRole();
    }, [getAndSetAllCloudAccounts, getAndSetAllOrganizationalUnits, getK8sAgentRole]);
    // ~~ Initial data loading end ~~

    const checkClusterConnectivity = React.useCallback(async () => {
        if (!clusterData) return;
        try {
            const res = await KubernetesAccountService.getAccountSummary({ id: clusterData.id });
            if (res.data.agentsStatus === 'WORKING') {
                setStatus(KOBMainLogicHookStatus.ClusterConnected);
            }
        //eslint-disable-next-line
        } catch (error) { }
    }, [clusterData]);

    React.useEffect(() => {
        if (status === KOBMainLogicHookStatus.PendingForConnectivity) {
            checkClusterConnectivity();
            const checkActivityInterval = setInterval(() => checkClusterConnectivity(), 5000);

            return () => clearInterval(checkActivityInterval);
        }
    }, [status, checkClusterConnectivity]);

    const isDataReady = React.useMemo(() => {
        return kubernetesCloudAccounts.length > 0 &&
        organizationalUnits.id &&
        !!k8sAgent;
    }, [kubernetesCloudAccounts, organizationalUnits, k8sAgent]);

    const createCluster = React.useCallback(async () => {
        if (!k8sAgent) return; // Just for TS
        setLoading(true);

        setStatus(KOBMainLogicHookStatus.GeneratingClusterName);
        const randomName = `CloudAccount_00${kubernetesCloudAccounts.length}`; // TODO: verify name is valid
        setClusterName(randomName);

        setStatus(KOBMainLogicHookStatus.CreatingCluster);
        let newCluster: KubernetesAccount;
        try {
            const newClusterResp = await KubernetesAccountService.createAccount({ name: randomName, description: '', organizationalUnitId: selectedOU });
            newCluster = newClusterResp.data;
            setClusterData({ ...newCluster, organizationalUnitId: selectedOU });
        } catch (error) {
            setError(true);
            return;
        }

        setStatus(KOBMainLogicHookStatus.UpdatingFeatures);
        await Promise.all(features.filter(feature => feature.defaultActive && !feature.viewOnly).map(feature => changeFeatureStatus({ id: newCluster.id, featureType: feature.name, status: KubernetesFeatureStatus.ENABLE })));

        setStatus(KOBMainLogicHookStatus.GeneratingServiceAccountName);
        const newServiceAccountName = `${randomName}-serviceAccount`;
        setServiceAccountName(newServiceAccountName);

        setStatus(KOBMainLogicHookStatus.CreatingServiceAccount);
        const { data } = await createServiceAccount({ name: newServiceAccountName, roleIds: [k8sAgent] });
        setServiceAccountData(data);

        setStatus(KOBMainLogicHookStatus.PendingForUserAction);
        setLoading(false);

        getAndSetAllCloudAccounts();
    }, [k8sAgent, selectedOU, kubernetesCloudAccounts, features, getAndSetAllCloudAccounts]);


    React.useEffect(() => {
        if (!isDataReady || clusterName) return;
        createCluster();
    }, [isDataReady, clusterName, createCluster]);

    const clusterNameChange = async (name: string) => {
        if (!name) return false;
        if (!clusterData || !serviceAccountData) return false;
        const isSameAsBefore = clusterName?.toLowerCase() === name.toLowerCase();
        if (isSameAsBefore) return true;
        const isNameExist = kubernetesCloudAccounts.some(acc => acc.name.toLowerCase() === name.toLowerCase());
        if (isNameExist) return false;

        setLoading(true);
        try {
            await KubernetesAccountService.updateName({ id: clusterData.id , name });
        } catch (error) {
            return false;
        }
        setLoading(false);
        try {
            updateServiceAccount({ ...serviceAccountData, name: `${name}-serviceAccount` });
        // eslint-disable-next-line no-empty
        } catch (error) {}

        setClusterName(name);
        setClusterData({ ...clusterData, name });

        return true;
    };

    const clusterDescriptionChange = async (description: string) => {
        if (!clusterData || !serviceAccountData) return false;
        const isSameAsBefore = clusterData.description.toLowerCase() === description.toLowerCase();
        if (isSameAsBefore) return true;

        setLoading(true);
        try {
            await KubernetesAccountService.updateDescription({ id: clusterData.id , description });
            setClusterData({ ...clusterData, description });
        } catch (error) {
            return false;
        }

        setLoading(false);

        return true;
    };

    const organiztionUnitChange = async (id: string) => {
        if (!id) return false;
        if (!clusterData) return false;
        const isSameAsBefore = selectedOU === id;
        if (isSameAsBefore) return true;
        
        setLoading(true);
        try {
            await KubernetesAccountService.updateOU({ id: clusterData.id , organizationalUnitId: id });
            setSelectedOU(id);
            setClusterData({ ...clusterData, organizationalUnitId: id });
        } catch (error) {
            return false;
        }

        setLoading(false);

        return true;
    };

    const generateHelmCommand = (req: GenerateHelmCommandRequest) => {
        if (!serviceAccountData || !clusterData) return undefined;
        return getHelmCommand({
            apiKey: serviceAccountData.apiKeyId,
            apiSecret: serviceAccountData.apiKeySecret,
            namespace: req.namespace,
            cluster: {
                id: clusterData.id,
                ...req.features
            }
        });
    };

    const generateNonHelmCommand = (req: GenerateHelmCommandRequest) => {
        if (!serviceAccountData || !clusterData) return undefined;
        return getNonHelmCommand({
            apiKey: serviceAccountData.apiKeyId,
            apiSecret: serviceAccountData.apiKeySecret,
            namespace: req.namespace,
            cluster: {
                id: clusterData.id,
                ...req.features
            }
        });
    };

    const changeFeatureStatus = async (req: KubernetesAccountFeatureStatusChange.Request) => {
        setLoading(true);
        try {
            await KubernetesAccountService.updateFeatureStatus(req);
        } catch (error) {
            return false;
        } finally {
            setLoading(false);
        }
        return true;
    };

    const deleteClusterAndService = async () => {
        if (!clusterData || !serviceAccountData) return false;
        setLoading(true);
        try {
            await KubernetesAccountService.deleteAccount({ id: clusterData?.id });
            await deleteServiceAccount({ id: serviceAccountData?.id });
        } catch (error) {
            return false;
        } finally {
            setLoading(false);
        }
        return true;
    };

    const startConnectivityIntervals = () => {
        if (!clusterData || !serviceAccountData) return false;
        setStatus(KOBMainLogicHookStatus.PendingForConnectivity);
        return true;
    };

    const flatOrganizationalUnits = React.useMemo(() => {
        const flatten = (arr: Array<IOrganizationalUnit>): Array<IOrganizationalUnit> => {
            return arr.reduce<Array<IOrganizationalUnit>>((acc, org) => {
                const { children, ...rest } = org;
                acc.push(rest);
                if (children) {
                    acc.push(...(flatten(children)));
                }
                return acc;
            }, []);
        };
        return flatten([organizationalUnits]);
    }, [organizationalUnits]);

    return ({
        clusterName,
        clusterDescription: clusterData?.description || '',
        clusterData,
        clusterNameChange,
        organiztionUnitChange,
        status,
        loading,
        error,
        flatOrganizationalUnits,
        serviceAccountName,
        serviceAccountData,
        generateHelmCommand,
        generateNonHelmCommand,
        changeFeatureStatus,
        clusterDescriptionChange,
        deleteClusterAndService,
        startConnectivityIntervals
    });
};