import styled from 'styled-components';
import { Info } from '@dome9/berries/system-icons';

export const LabelWithToolTipWrapper = styled.div`
    display: flex;
    gap: 3px;
    align-items: center;
`;

export const InfoIconStyled = styled(Info)`
  width: 11px;
  height: 11px;
  cursor: pointer;
  margin-left: 2px;
`;
