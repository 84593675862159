import { StatusCellRenderer_Status, StatusCellRenderer_StatusType } from 'common/module_interface/assets/Environments';
import { ContainerRegistryType, GetWorkloadEnvironment, KubernetesAccountFeatureSummary, KubernetesPlatformType, VersionStatus, WorkloadEnvironmentStatus } from '../services/workload/workload.interface';
import { CustomDataById, EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import { Vendors } from 'common/consts/vendors';
import AwpService from '../services/awp/awp.service';
import { GetAwsAzureAgentlessAccountsStatesResponse, GetAwsAzureServerlessAccountsStatesResponse, GetAwsAzureServerlessAccountsStatesResponseState } from '../services/awp/awp.interface';
import { isChina } from 'common/utils/dataCenter';
import WorkloadService from '../services/workload/workload.service';
import { IFRAME_TAGS } from 'common/interface/IFrame.message.model';
import { agentlessSupportedVendors, serverlessSupportedVendors } from './initialize';

const agentStatusToRendererStatus = (agentStatus: WorkloadEnvironmentStatus | undefined): StatusCellRenderer_Status => {
    switch (agentStatus) {
        case WorkloadEnvironmentStatus.PENDING:
        case WorkloadEnvironmentStatus.WARNING:
            return StatusCellRenderer_Status.warning;
        case WorkloadEnvironmentStatus.ERROR:
            return StatusCellRenderer_Status.error;
        case WorkloadEnvironmentStatus.OK:
        case WorkloadEnvironmentStatus.INITIALIZING:
            return StatusCellRenderer_Status.success;
        default:
            return StatusCellRenderer_Status.none;
    }
};

export const initializeEnvironmentsTableData = () => {
    EnvironmentsTableRegistry.addCustomDataByPlatform([
        {
            id: 'add-env-table-workload-status-loading',
            content: {
                customData: async () => ({
                    platforms: [Vendors.KUBERNETES, Vendors.CONTAINER_REGISTRY],
                    customData: {
                        statusLoading: true,
                        workloadEnvironmentLoading: true
                    }
                })
            }
        }
    ]);
    EnvironmentsTableRegistry.addCustomDataById([
        {
            id: 'add-env-table-agentless-data',
            content: {
                customData: async (allCloudAccounts) => {
                    const states = await Promise.all([
                        AwpService.getAwsAzureAgentlessAccountsStates(Vendors.AWS),
                        AwpService.getAwsAzureAgentlessAccountsStates(Vendors.AZURE),
                    ]);
                    const [agentlessAws, agentlessAzure] = states;
                    const indexedAgentless = [...agentlessAws.data, ...agentlessAzure.data].reduce<{ [id: string]: GetAwsAzureAgentlessAccountsStatesResponse }>((acc, curr) => {
                        acc[curr.cloudAccountId] = curr;
                        return acc;
                    }, {});
                    const agentlessIdNameMap = allCloudAccounts.reduce<Record<string,string>>((accum, current) => {
                        return { ...accum, [current.id]: current.name };
                    }, {});
                    const getCentralizedCloudAccountName = (accountId: string) => {
                        const centralizedAccountId = indexedAgentless[accountId]?.centralizedCloudAccountId;
                        if (!centralizedAccountId) {
                            return undefined;
                        }
                        const name = agentlessIdNameMap[centralizedAccountId];
                        return {
                            name,
                            id: centralizedAccountId
                        };
                    };
                    return allCloudAccounts.map(account => {
                        const agentlessSupported = agentlessSupportedVendors.some(vendor => vendor === account.platform);
                        return {
                            id: account.id,
                            customData: {
                                agentlessEnabled: agentlessSupported ? indexedAgentless[account.id]?.agentlessProtectionEnabled || false : undefined,
                                agentlessShouldUpdate: agentlessSupported ? indexedAgentless[account.id]?.shouldUpdate : undefined,
                                agentlessScanMode: agentlessSupported ? indexedAgentless[account.id]?.scanMode : undefined,
                                agentlessCentralizedAccountId: agentlessSupported ? getCentralizedCloudAccountName(account.id) : undefined,
                            }
                        };
                    });
                }
            }
        },
        {
            id: 'add-env-table-serverless-data',
            content: {
                isRelevant: () => !isChina(),
                customData: async (allCloudAccounts) => {
                    const states = await Promise.all([
                        AwpService.getAwsAzureServerlessAccountsStates(Vendors.AWS),
                        AwpService.getAwsAzureServerlessAccountsStates(Vendors.AZURE),
                    ]);
                    const [serverlessAws, serverlessAzure] = states;
                    const indexedServerless = [...serverlessAws.data, ...serverlessAzure.data].reduce<{ [id: string]: GetAwsAzureServerlessAccountsStatesResponse }>((acc, curr) => {
                        acc[curr.cloudAccountId] = curr;
                        return acc;
                    }, {});
                    return allCloudAccounts.map(account => {
                        const serverlessSupported = serverlessSupportedVendors.some(vendor => vendor === account.platform);

                        return {
                            id: account.id,
                            customData: {
                                serverlessEnabled: serverlessSupported ? indexedServerless[account.id]?.state === GetAwsAzureServerlessAccountsStatesResponseState.Active : undefined,
                                serverlessShouldUpdate: serverlessSupported ? indexedServerless[account.id]?.shouldUpdate : undefined,
                            }
                        };
                    });
                }
            }
        },
        {
            id: 'add-env-table-workload-tab-k8s-registry-data', // status, statusDetails, clusterVersion, type, features(AC, PM, IA, TI, RP)
            content: {
                customData: async (allCloudAccounts) => {
                    const workloadEnvironment = await WorkloadService.getWorkloadEnvironment({
                        cachingConfig: { useCache: true, tags: [IFRAME_TAGS.CLEAR_CACHE.ENVIRONMENTS] },
                    }).catch(() => {
                        const emptyData: GetWorkloadEnvironment.Response = {
                            containerRegistryAccounts: [],
                            kubernetesAccounts: []
                        };
                        return { data: emptyData };
                    });

                    const onboardedKubernetesAccountsCustomData: { [id: string]: {
                        status: StatusCellRenderer_Status;
                        statusDetails: string;
                        kubernetesClusterVersion: { version?: string, status: VersionStatus, statusDescription?: string };
                        workloadPlatformType: KubernetesPlatformType;
                        kubernetesFeatures: KubernetesAccountFeatureSummary[];
                    
                    } } = {};
                    const onboardedRegistryAccountsCustomData: { [id: string]: {
                        status: StatusCellRenderer_Status;
                        statusDetails: string;
                        workloadPlatformType: ContainerRegistryType;
                    } } = {};

                    workloadEnvironment.data.kubernetesAccounts.forEach(account => {
                        if (account.onBoardedKubernetesAccountDetails) {
                            onboardedKubernetesAccountsCustomData[account.onBoardedKubernetesAccountDetails.id] = {
                                status: agentStatusToRendererStatus(account.onBoardedKubernetesAccountDetails.status),
                                statusDetails: account.onBoardedKubernetesAccountDetails.statusDescription,
                                kubernetesClusterVersion: {
                                    version: account.onBoardedKubernetesAccountDetails.clusterVersion,
                                    status: account.onBoardedKubernetesAccountDetails.clusterVersionStatus,
                                    statusDescription: account.onBoardedKubernetesAccountDetails.clusterVersionStatusDescription
                                
                                },
                                workloadPlatformType: account.onBoardedKubernetesAccountDetails.type,
                                kubernetesFeatures: account.onBoardedKubernetesAccountDetails.features
                            };
                        }
                    });
                    workloadEnvironment.data.containerRegistryAccounts.forEach(account => {
                        if (account.onBoardedRegistriesAccountDetails) {
                            onboardedRegistryAccountsCustomData[account.onBoardedRegistriesAccountDetails.id] = {
                                status: agentStatusToRendererStatus(account.onBoardedRegistriesAccountDetails.status),
                                statusDetails: account.onBoardedRegistriesAccountDetails.statusDescription,
                                workloadPlatformType: account.onBoardedRegistriesAccountDetails.type
                            };
                        }
                    });

                    return allCloudAccounts
                        .filter(({ platform }) => platform === Vendors.KUBERNETES || platform === Vendors.CONTAINER_REGISTRY)
                        .map<CustomDataById>(account => {
                            const customData = account.platform === Vendors.KUBERNETES ? onboardedKubernetesAccountsCustomData[account.id] : onboardedRegistryAccountsCustomData[account.id];
                            return {
                                id: account.id,
                                customData: {
                                    ...(customData || { status: StatusCellRenderer_Status.none }),
                                    workloadEnvironmentLoading: false,
                                    statusType: StatusCellRenderer_StatusType.workloadStatus,
                                    statusLoading: false
                                }
                            };
                        });
                }
            }
        }
    ]);
};