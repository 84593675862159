import { Stack } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';

const GcpGkeInternalAuthExplanation = () => {
    const { t } = useTranslation('k8s_registry');
    return (
        <Stack spacing={1}>
            {t('ON_BOARDING.GcpGkeInternalAuthExplanation.toUseThisOption')}
            <Stack direction={'row'} style={{ marginLeft: '10px' }}>
                <li></li>
                <Stack>{t('ON_BOARDING.GcpGkeInternalAuthExplanation.thisRequirementIsMet')}</Stack>
            </Stack>
            <Stack spacing={1} style={{ marginLeft: '40px' }} >
                <li>{t('ON_BOARDING.GcpGkeInternalAuthExplanation.theGkeCluster')}</li>
                <li>{t('ON_BOARDING.GcpGkeInternalAuthExplanation.theClusterUses')}</li>
            </Stack>
        </Stack>
    );
};

export default GcpGkeInternalAuthExplanation;