import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import WidgetCard from 'common/components/Dashboard/WidgetCard/WidgetCard';
import EmptyState from 'common/components/EmptyState';
import { ProtectedAssetsResponse } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import WidgetPieChart from 'common/components/Widgets/Pie/PieWidget';
import { getExternalAdditionalFieldValue } from 'common/module_interface/assets/utils';
import { IGenericWidgetData, IGenericWidgetDataPropsBase } from 'common/module_interface/overview/Interface';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface ScanStatusesWidgetProps {
    isLoading: boolean;
    data?: ProtectedAssetsResponse;
}

export const SkippedScansBreakdownWidget: React.FC<ScanStatusesWidgetProps> = ({ isLoading, data }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));

    const skippedScansBreakdown = React.useMemo<IGenericWidgetData & IGenericWidgetDataPropsBase | undefined>(() => {
        if (!data) return undefined;
        const skippedSumStatuses = data.assets.reduce<{ [key: string]: number }>((acc, asset) => {
            const status = asset.vulnerability?.scanStatus || getExternalAdditionalFieldValue(asset, 'ScanStatus');
            const statusDetails = asset.vulnerability?.scanStatusDetails || getExternalAdditionalFieldValue(asset, 'ScanStatusDetails');
            if (!status || !['Skipped', 'Not Scanned'].includes(status) || !statusDetails) return acc;
            if (!acc[statusDetails]) {
                acc[statusDetails] = 0;
            }
            acc[statusDetails]++;
            return acc;
        }, {});

        if (Object.keys(skippedSumStatuses).length === 0) return undefined;

        return {
            items: Object.entries(skippedSumStatuses).map(([key, value]) => ({ key, value })),
            title: Object.values(skippedSumStatuses).reduce<number>((acc, sum) => acc + sum, 0).toString(),
            subtitle: t('awpTab.total'),
        };
    }, [data, t]);


    return (
        <WidgetCard
            title={t('awpTab.skippedScansBreakdown')}
            content={isLoading ? (
                <CloudAnimationLoader size='small' />
            ) : (skippedScansBreakdown
                ? <WidgetPieChart data={skippedScansBreakdown} />
                : <EmptyState iconName='checkCircle' iconSize={32} label={t('awpTab.allAssetsHasBeedScanned')} />)}
        />

    );
};