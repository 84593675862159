import React, { useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { ControllerWrapper, Error, ClickForOnboardingRegistry, CustomA } from './ScanRegistryModal.styled';
import { FormValues } from './ScanRegistryModal.types';
import AuthMethodSelector from 'modules/workloads/components/AuthMethodSelector/AuthMethodSelector';
import { useTranslation } from 'react-i18next';
import { useGetRegistries } from 'modules/workloads/reducers/registries/hooks/useGetRegistries';
import { ModalsProps } from '..';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { useLinkEnvironment } from 'modules/workloads/reducers/registries/hooks/useLinkEnvironment';
import { useGetCluster } from 'modules/workloads/reducers/cluster-new/hooks/useGetCluster';
import {
    RegistryAuthMethod,
    getRegistryOptions,
    RegistryType,
    ScanningEnvironmentType,
    shouldUseApplicationClientId,
    shouldUsePullSecretName,
    shouldUseRoleARN,
    shouldUseTenantId
} from 'modules/workloads/utils/RegistryOptions/RegistryOptions';
import Select from 'modules/workloads/components/Select';
import { Message } from 'common/design-system/components-v2';

const ScanRegistryModal: React.FC<ModalsProps> = ({ closeModal, clusterId }) => {
    const { t } = useTranslation();
    const [selectedRegistryType, setSelectedRegistryType] = useState<RegistryType>(RegistryType.ACR);
    const RegistryOptions = getRegistryOptions();

    const {
        handleSubmit,
        control,
        formState: {
            errors,
            dirtyFields,
        },
        watch,
        reset,
        getValues,
        setValue
    } = useForm<FormValues>({
        defaultValues: {
            registry: '',
            authMethod: 'AcrServicePrincipal',
            tenantId: '',
            pullSecretName: '',
            applicationClientId: '',
            roleArn: '',
        },
    });

    const { data: allRegistries } = useGetRegistries();
    const { reloadData: reloadClusterData } = useGetCluster(clusterId);

    const handleOnLinkSuccess = () => {
        getNotificationsService().addNotification({
            text: 'The request was successful',
            type: NotificationType.SUCCESS,
            title: 'successful',
        });
        reloadClusterData();
    };
    const handleOnLinkError = (error: { message: string }) => {
        getNotificationsService().addNotification({
            text: error.message,
            type: NotificationType.ERROR,
            title: 'Error',
        });
    };

    const { isLoading: linkLoading, linkEnvironment } = useLinkEnvironment({ onSuccess: handleOnLinkSuccess, onError: handleOnLinkError });

    const unlinkedRegistries = React.useMemo(() => {
        return allRegistries.filter(registry => {
            return !registry.linkedScanningEnvironments.some(env => env.environmentId === clusterId);
        });
    }, [allRegistries, clusterId]);

    const registrySelectOptions = React.useMemo(() => {
        return unlinkedRegistries.map((registry) => ({
            label: registry.name,
            value: registry.id,
            icon: { name: RegistryOptions[registry.type].icon }
        }));
    }, [RegistryOptions, unlinkedRegistries]);

    const authMethod = watch('authMethod', 'AcrServicePrincipal');

    const onConfirmScanRegistryModal = async (data: FormValues, authMethodType: RegistryAuthMethod) => {
        const {
            registry,
            tenantId,
            pullSecretName,
            applicationClientId,
            roleArn,
        } = data;

        const payload = {
            environmentId: clusterId,
            pullSecretName:
                shouldUsePullSecretName(selectedRegistryType, ScanningEnvironmentType.Kubernetes, authMethod)// TODO: change to scanner type
                    ? pullSecretName
                    : '',
            tenantId: shouldUseTenantId(selectedRegistryType, ScanningEnvironmentType.Kubernetes, authMethod) ? tenantId : '',// TODO: change to scanner type
            applicationClientId: shouldUseApplicationClientId(selectedRegistryType, ScanningEnvironmentType.Kubernetes, authMethod) ? applicationClientId : '',// TODO: change to scanner type
            roleArn: shouldUseRoleARN(selectedRegistryType, ScanningEnvironmentType.Kubernetes, authMethod) ? roleArn : '',
            registryAuthType: authMethodType,
        };
        await linkEnvironment(registry, payload);
    };

    const onRegistryChange = (field: ControllerRenderProps<FormValues, 'registry'>, registryId: string) => {
        // const value: RegistryAccount = event.detail.value;
        const selectedRegistry = unlinkedRegistries.find((registry) => registry.id === registryId);

        if (!selectedRegistry) return;

        setSelectedRegistryType(selectedRegistry.type);
        field.onChange(registryId);
        setValue('authMethod', RegistryOptions[selectedRegistry.type].authMethods[ScanningEnvironmentType.Kubernetes][0].key);// TODO: change to scanner type
    };

    const onSubmit = async (data: FormValues) => {
        await onConfirmScanRegistryModal(data, getValues('authMethod'));
        onClose();
    };

    const onClose = () => {
        closeModal();
        reset();
    };

    return (
        <Message
            width='lg'
            onClose={onClose}
            isOpen={true}
            title={t('K8S.CLUSTER_PAGE.SCAN_REGISTRY_MODAL.TITLE')}
            isLoading={linkLoading}
            onConfirm={handleSubmit((data) => onSubmit(data))}
            onCancel={onClose}
            submitBtnText='Save'
            cancelBtnText='Cancel'
            cancelBtnDisabled={linkLoading}
        >
            <form>
                <ClickForOnboardingRegistry>
                    {t('K8S.CLUSTER_PAGE.SCAN_REGISTRY_MODAL.DROPDOWN_LABEL')}
                    <CustomA
                        href={`/workload/registry-onboarding?selectedCluster=${clusterId}`}
                    >
                        <span>{t('K8S.CLUSTER_PAGE.SCAN_REGISTRY_MODAL.ONBOARD_NEW_CONTAINER_REGISTRY')}</span>
                    </CustomA>
                </ClickForOnboardingRegistry>

                <ControllerWrapper>
                    <Controller
                        name={'registry'}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                placeholder={t('K8S.CLUSTER_PAGE.SCAN_REGISTRY_MODAL.REGISTRY_FILED_PLACEHOLDER')}
                                options={registrySelectOptions}
                                onChange={(value: string) => onRegistryChange(field, value)}
                                error={!!errors.registry}
                            />
                        )}
                    />
                    {errors.registry && <Error>Required</Error>}
                </ControllerWrapper>
                {dirtyFields.registry && (
                    <AuthMethodSelector
                        registryType={selectedRegistryType}
                        scanningEnvironmentType={ScanningEnvironmentType.Kubernetes}
                        selectedAuthMethod={authMethod}
                        control={control}
                        errors={errors}
                    />
                )}
            </form>
        </Message>
    );
};

export default ScanRegistryModal;
