import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

export const ToolbarWrapper = styled(Stack)`

    & > button:not(:last-child) {
        position: relative;
        
        &:after {
            content: '';
            display: block;
            position: absolute;
            right: -1px;
            top: 50%;
            width: 1px;
            height: 22px;
            background-color: ${({ theme }) => theme.palette.border.normal};
            transform: translate(50%, -50%);
        }
    }
`;
