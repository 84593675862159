import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const Wrapper = styled(Stack)`
    flex-wrap: wrap;
`;

const ComponentWrapper = styled.div`
    min-width: calc(33% - 5px);
    max-width: calc(33% - 5px);
    display: flex;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.xl}) {
        min-width: calc(50% - 5px);
        max-width: calc(50% - 5px);
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}) {
        min-width: 100%;
        max-width: 100%;
    }
`;

export default {
    Wrapper,
    ComponentWrapper
};