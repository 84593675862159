import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectEditClusterModalFormValues, ConnectEditClusterModalProps } from './ConnectEditClusterModal.types';
import AuthMethodSelector from 'modules/workloads/components/AuthMethodSelector/AuthMethodSelector';
import { useGetRegistry } from 'modules/workloads/reducers/registries/hooks/useGetRegistry';
import { Controller, useForm } from 'react-hook-form';
import { RegistryAuthMethod, getRegistryOptions, RegistryType, ScanningEnvironmentType, shouldUseApplicationClientId, shouldUsePullSecretName, shouldUseTenantId } from 'modules/workloads/utils/RegistryOptions/RegistryOptions';
import { getCloudAccountsService, ICloudAccount } from 'common/interface/data_services';
import Select from 'modules/workloads/components/Select';
import { useLinkEnvironment } from 'modules/workloads/reducers/registries/hooks/useLinkEnvironment';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import ScanningEnvironmentTypeSelector from 'modules/workloads/pages/registry/RegistryOnboarding/components/ScanningEnvironmentTypeSelector';
import { ALink } from 'common/components/ALink';
import { A } from 'common/components/Anchor/Anchor';
import { Message } from 'common/design-system/components-v2';

const ConnectEditClusterModal: React.FunctionComponent<ConnectEditClusterModalProps> = ({ closeModal, registryId, linkedEnv }) => {
    const { t } = useTranslation('k8s_registry');
    
    const { isLoading, isError, data: registry, reloadData } = useGetRegistry(registryId);
    const isEditMode = React.useMemo(() => !!linkedEnv, [linkedEnv]);

    const [kubernetesCloudAccounts, setKubernetesCloudAccounts] = React.useState<{ data: Array<ICloudAccount>; loading: boolean; }>({ data: [], loading: false });
    const [awsCloudAccounts, setAwsCloudAccounts] = React.useState<{ data: Array<ICloudAccount>; loading: boolean; }>({ data: [], loading: false });
    const [scanningEnvironmentType, setScanningEnvironmentType] = useState<ScanningEnvironmentType>(linkedEnv?.authMethods?.scanningEnvironmentType || ScanningEnvironmentType.Kubernetes);
    const RegistryOptions = getRegistryOptions();

    const scanningEnvironmentTypeSelectorDisabled = !!linkedEnv?.authMethods?.scanningEnvironmentType;

    const filterCloudAccountsByScanningEnvType = (allCloudAccounts: Array<ICloudAccount>, scanningEnvironmentType: ScanningEnvironmentType) => 
        allCloudAccounts.filter(
            (cloudAccount) => cloudAccount.platform === scanningEnvironmentType.toLowerCase()
        );

    const getAllCloudAccounts = async () => {
        setKubernetesCloudAccounts({ data: [], loading: true });
        setAwsCloudAccounts({ data: [], loading: true });

        const result = await getCloudAccountsService().getAllCloudAccounts();
        
        setKubernetesCloudAccounts({ data: filterCloudAccountsByScanningEnvType(result, ScanningEnvironmentType.Kubernetes), loading: false });
        setAwsCloudAccounts({ data: filterCloudAccountsByScanningEnvType(result, ScanningEnvironmentType.AWS), loading: false });
    };

    React.useEffect(() => {
        getAllCloudAccounts();
    }, []);

    const handleOnLinkEnvironmentSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t(`registryPage.scanners.modals.${isEditMode ? 'editScanningEnvironment' : 'connectScanningEnvironment'}.successMessage`),
            text: '',
        });
        reloadData();
        closeModal();
    };

    const handleOnLinkEnvironmentError = ({ message }: { message: string }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t(`registryPage.scanners.modals.${isEditMode ? 'editScanningEnvironment' : 'connectScanningEnvironment'}.failMessage`),
            text: message,
        });
    };

    const { isLoading: isLinkEnvLoading, linkEnvironment } = useLinkEnvironment({ onSuccess: handleOnLinkEnvironmentSuccess, onError: handleOnLinkEnvironmentError });
 
    const handleOnConfirm = (data: ConnectEditClusterModalFormValues) => {
        linkEnvironment(registryId, {
            environmentId: data?.scanningEnvironment,
            pullSecretName:
                shouldUsePullSecretName(registryType, ScanningEnvironmentType.Kubernetes, data.authMethod)
                    ? data.pullSecretName
                    : '',
            tenantId: shouldUseTenantId(registryType, ScanningEnvironmentType.Kubernetes, data.authMethod) ? data.tenantId : '',
            applicationClientId: shouldUseApplicationClientId(registryType, ScanningEnvironmentType.Kubernetes, data.authMethod) ? data.applicationClientId : '',
            registryAuthType: data.authMethod,
            scanningEnvironmentType,
        });
    };

    const registryType: RegistryType = registry?.account.type || RegistryType.ACR;
    const registryAuthType: RegistryAuthMethod = linkedEnv?.authMethods?.registryAuthType || RegistryOptions[registryType].authMethods[ScanningEnvironmentType.Kubernetes][0].key;

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        watch,
        setValue,
        reset,
    } = useForm<ConnectEditClusterModalFormValues>({
        defaultValues: {
            scanningEnvironment: linkedEnv?.kubernetesAccountId,
            authMethod: registryAuthType || RegistryOptions[registryType].authMethods[scanningEnvironmentType][0].key,
            tenantId: linkedEnv?.authMethods?.tenantId || '',
            pullSecretName: linkedEnv?.authMethods?.pullSecretName || '',
            applicationClientId: linkedEnv?.authMethods?.applicationClientId || '',
            roleArn: linkedEnv?.authMethods?.roleArn || '',
        },
    });

    const authMethod = watch('authMethod');
    const scanningEnvironment = watch('scanningEnvironment');    

    const onScanningEnvironmentTypeChanged = React.useCallback(() => {
        setValue('authMethod', RegistryOptions[registryType].authMethods[scanningEnvironmentType][0].key);
    }, [setValue, registryType, scanningEnvironmentType]);

    React.useEffect(() => {
        onScanningEnvironmentTypeChanged();
    }, [onScanningEnvironmentTypeChanged]);

    React.useEffect(() => {
        reset();
    }, [scanningEnvironmentType]);

    const accountSelectOptions = React.useMemo(() => {
        const linkedIds: any = registry?.account.linkedScanningEnvironments.reduce<{ [envId: string]: true }>((acc, env) => ({ ...acc, [env.environmentId]: true }), {}) || {};
        const allFilteredByTypeAccounts = (scanningEnvironmentType === ScanningEnvironmentType.Kubernetes ? kubernetesCloudAccounts : awsCloudAccounts).data;
        const unlinkedAccounts = [...allFilteredByTypeAccounts].filter(account => !linkedIds[account.id]);

        let relevantAccountToDisplay = [];
        if (isEditMode) {
            relevantAccountToDisplay = allFilteredByTypeAccounts;
        } else {
            relevantAccountToDisplay = unlinkedAccounts;
        }

        return relevantAccountToDisplay.map(account => ({
            label: account.name,
            value: account.id,
            icon: { name: scanningEnvironmentType.toLowerCase() },
        }));
    }, [registry?.account.linkedScanningEnvironments, scanningEnvironmentType, kubernetesCloudAccounts, awsCloudAccounts, isEditMode]);

    if (isLoading || isError || !registry) return null;

    
    return (
        <Message
            isLoading={isLinkEnvLoading}
            width='lg'
            onCancel={closeModal}
            onClose={closeModal}
            onConfirm={handleSubmit((data) => handleOnConfirm(data))}
            title={t(`registryPage.scanners.modals.${isEditMode ? 'editScanningEnvironment' : 'connectScanningEnvironment'}.title`)}
        >
            <ScanningEnvironmentTypeSelector
                registryScanner={scanningEnvironmentType}
                onChange={setScanningEnvironmentType}
                disabled={scanningEnvironmentTypeSelectorDisabled}
                awsDisabled={!awsCloudAccounts.data.length}
            />
            <div>
                <Controller
                    name='scanningEnvironment'
                    control={control}
                    render={({ field }) => (
                        <Select
                            key={scanningEnvironment}
                            {...field}
                            {...register('scanningEnvironment', { validate: (value) => !!value, required: true })}
                            options={accountSelectOptions}
                            onChange={(value: string) => field.onChange(value)}
                            placeholder={t(`registryPage.scanners.selectScanningEnvironmentPlaceHolder.${scanningEnvironmentType}`)}
                            error={!!errors.scanningEnvironment}
                            disabled={isEditMode}
                            noOptionsMessage={<>{t('ON_BOARDING.thereAreNoOnboardedKubernetesClusters')}    <ALink><A href='/workload/cloud-add/kubernetes'>{t('ON_BOARDING.onBoardANewKubernetesCluster')}</A></ALink></>}
                        />
                    )}
                />
                {scanningEnvironmentType === ScanningEnvironmentType.Kubernetes && (
                    <ALink><A href='/workload/cloud-add/kubernetes'>{t('ON_BOARDING.onBoardANewKubernetesCluster')}</A></ALink>
                )}
            </div>
            <AuthMethodSelector
                registryType={registry.account.type}
                scanningEnvironmentType={scanningEnvironmentType}
                selectedAuthMethod={authMethod}
                control={control}
                errors={errors}
            />
        </Message>
    );
};

export default ConnectEditClusterModal;
