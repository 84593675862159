import React, { useEffect, useMemo, useState } from 'react';
import { Button, Icon } from '@dome9/berries/react-components';
import { isCheckpointUser, sendLogger } from '../../../utils';
import {
    AdditionalUserInfoWrapper,
    InfoTextHeader,
    SendButtonWrapper,
    Wrapper,
} from './KubernetesOnboardingFirstTime.styled';
import Loader from 'modules/workloads/components/Loader';
import { Log } from 'modules/workloads/utils/logger';
import { getUserService } from 'common/interface/services';

const enum Step {
    INITIAL,
    ASK_FOR_HELP,
    ADDITIONAL_INFO,
    THANK_YOU
}

const generateLog = (step: Step, additionalInfo?: string): Log | undefined => {
    switch (step) {
        case Step.INITIAL:
            return {
                description: 'This account does not have existing k8s clusters',
                toasterMessageOnSuccess: undefined,
                toasterMessageOnError: 'Failed to send request, please try again',
                reportType: 'K8S-First-Cluster-Onboarding',
                withToaster: false,
            };
        case Step.ASK_FOR_HELP:
            return {
                description: 'Yes, the user does want help with the onboarding cluster.',
                toasterMessageOnSuccess: 'Your request has been successfully received',
                toasterMessageOnError: 'Failed to send request, please try again',
                reportType: 'K8S-First-Cluster-Onboarding',
                withToaster: true,
            };
        case Step.ADDITIONAL_INFO:
            return {
                description: `User sent additional info: ${additionalInfo}`,
                toasterMessageOnSuccess: 'Additional information sent successfully',
                toasterMessageOnError: 'Failed to send additional information, please try again',
                reportType: 'K8S-First-Cluster-Onboarding',
                withToaster: true,
            };
        default:
            return undefined;
    }
};

const KubernetesOnboardingFirstTime: React.FC = () => {
    const [currentStep, setCurrentStep] = useState<Step>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { email } = getUserService().getUser();

    const isSendingLogEnabled = useMemo(() => {
        return !isCheckpointUser(email);
    }, [email]);

    const handleNextStep = React.useCallback(async (additionalUserInfo?: string) => {
        setIsLoading(true);
        const log = generateLog(currentStep, additionalUserInfo);
        if (log && isSendingLogEnabled) {
            await sendLogger(log);
        }
        setIsLoading(false);
        setCurrentStep(current => current + 1);
    }, [currentStep, isSendingLogEnabled]);

    useEffect(() => {
        handleNextStep();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRender = () => {
        switch (currentStep) {
            case Step.INITIAL:
                return <Loader />;
            case Step.ASK_FOR_HELP:
                return (
                    <>
                        <Icon color={'info'} size={64} name={'info'} />
                        <p className='text-info'>There are no onboarded environments in your account yet.</p>
                        <p className='sub-title'>To set up your first cluster, please contact our experts</p>
                        {isLoading ? <Loader style={{ marginTop: '20px' }} /> :
                            <Button variant={'default'} onClick={() => handleNextStep()}>Ask for an expert</Button>}
                    </>
                );
            case Step.ADDITIONAL_INFO:
                return (
                    <>
                        <Icon color={'warning'} colorLightness='light' size={64} name={'checkCircle'} />
                        <InfoTextHeader className='text-success'>Thank you for contacting us,<br /> our experts will
                            contact you within one business day via your registered email.</InfoTextHeader>
                        <AdditionalUserInfo
                            onSend={(additionalInfo: string) => handleNextStep(additionalInfo)}
                            isLoading={isLoading}
                        />
                    </>
                );
            case Step.THANK_YOU:
                return (
                    <>
                        <Icon color={'warning'} colorLightness='light' size={64} name={'checkCircle'} />
                        <InfoTextHeader className='text-success'>Thank you for contacting us,<br /> our experts will
                            contact you within one business day via your registered email.</InfoTextHeader>
                        <div style={{ marginTop: '25px', fontSize: '18px' }}>Additional information sent successfully.
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Wrapper>
            {getRender()}
        </Wrapper>
    );
};

type AdditionalUserInfoProps = {
    onSend: (additionalInfo: string) => void,
    isLoading: boolean,
}

const AdditionalUserInfo: React.FunctionComponent<AdditionalUserInfoProps> = ({ onSend, isLoading }) => {
    const [inputText, setInputText] = useState('');
    return (
        <AdditionalUserInfoWrapper>
            <label>Additional Information</label>
            <textarea disabled={isLoading} rows={3} onChange={(event: any) => setInputText(event.target.value)} />
            <div className='helper-text'>
                You can provide us your phone number or a different email address as well as any other information -
                e.g., specific time zone hours, during which to call you.
            </div>
            <SendButtonWrapper>
                {isLoading ? <Loader style={{ margin: '28px 85px 0 0' }} /> :
                    <Button disabled={!inputText} onClick={() => onSend(inputText)}>Send Additional
                        Information</Button>}
            </SendButtonWrapper>
        </AdditionalUserInfoWrapper>
    );
};

export default KubernetesOnboardingFirstTime;
