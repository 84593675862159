import React from 'react';
import {
    getRiskLevelByScore,
    RiskLevelsMap
} from 'common/module_interface/RiskManagement/RiskLevel';
import { Stack, Chip, Typography, LevelIcon } from 'common/design-system/components-v2';
import { Image } from '../../services/workload/workload.interface';
import { ImageCardWrapper } from './ImageCard.styled';

interface ImageCardProps extends Image {
    onClickSelectImage: (image: Image) => void;
    isSelectedImage: boolean
}

const ImageCard: React.FC<ImageCardProps> = ({ onClickSelectImage, isSelectedImage, ...restImage }) => {

    const riskLevelInfo = getRiskLevelByScore(Number(restImage.riskScore)) || RiskLevelsMap.unknown;
    return(
        <ImageCardWrapper isSelectedImage={isSelectedImage} title={''} direction={'row'}
            onClick={() => onClickSelectImage(restImage)}>
            <Stack padding={[4, 4, 4, 4]} style={{ overflow: 'hidden' }} direction={'row'} spacing={3}>
                <LevelIcon
                    iconProps={{ name: 'containerImage' ,customColor: riskLevelInfo.color }}
                    size={'xl'}
                    customBackgroundColor={riskLevelInfo.bg}
                />
                <Stack style={{ overflow: 'hidden' }} direction={'column'} spacing={2}>
                    <Stack className={'image-details'}>
                        <Typography variant={'subtitleLg'}>{restImage.repository}</Typography>
                        <Typography variant={'bodyLg'}>{restImage.imageId}</Typography>
                    </Stack>
                    <Stack spacing={2} direction={'row'}>
                        <Chip
                            leadingIconProps={{ name: riskLevelInfo.gaugeIconName }}
                            customColor={riskLevelInfo.bg}
                            customTextColor={riskLevelInfo.color}
                            label={restImage.riskScore || 'N/A'} />
                        <Chip label={`Tag: ${restImage.tag}`}></Chip>
                    </Stack>
                </Stack>
            </Stack>
        </ImageCardWrapper>
    );
};

export default ImageCard;