import React from 'react';
import { ILastEvent, IRenderedFilter, IRenderedHandler } from 'common/components/FilterPanel/FilterPanel.interface';
import { renderAddFilter, renderClearAll, renderMultiSelectFilter, renderTreeFilter } from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import FilterPanel from 'common/components/FilterPanel/FilterPanel';
import Feedback from '../../components/Feedback';
import { DashboardFilterPanelProps } from './DashboardFilterPanel.types';
import { getFilterParamsFromURL, getFiltersFromLocalStorage, setFilterParamsToURL, setFiltersInitialValues, setFiltersToLocalStorage } from './DashboardFilterPanel.utils';
import { WrapperDashboardFilterPanel } from './DashboardFilterPanel.styled';
import { getCloudAccountsService, getOrganizationalUnitService, ICloudAccount, IOrganizationalUnit } from 'common/interface/data_services';
import { ITreeFilterObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';

const DashboardFilterPanel: React.FunctionComponent<DashboardFilterPanelProps> = props => {
    const { axiosInstance, onFilterChange, activeFilters } = props;

    const [organizationalUnits, setOrganizationalUnits] = React.useState<IOrganizationalUnit>({
        id: '',
        name: '',
        path: '',
        children: [],
    });
    const [platforms] = React.useState<Array<{ value: string; label: string; name: string; icon: string; }>>([
        { label: 'Kubernetes', value: '11', name: 'kubernetes', icon: 'kubernetes' },
        { label: 'AWS', value: '1', name: 'aws', icon: 'aws' },
        { label: 'Container Registry', value: '20', name: 'containerregistry', icon: 'containers-registry' },
        { label: 'ShiftLeft', value: '15', name: 'shiftleft', icon: 'shiftleft' },
    ]);
    const platformNameToValue = React.useCallback((name: string) => platforms.find(plat => plat.name === name)?.value || '', [platforms]);

    const [environments, setEnvironments] = React.useState<Array<ICloudAccount>>([]);

    const filteredEnvironments = React.useMemo(() => {
        let tempEnvironments = environments;

        Object.keys(activeFilters).forEach(filterKey => {
            const value = activeFilters[filterKey];
            if (!value || (Array.isArray(value) && value.length === 0)) {
                return;
            }
            const valueIsArray = Array.isArray(value);
            switch (filterKey) {
                case 'platform':
                    tempEnvironments = tempEnvironments.filter(env => {
                        if (valueIsArray) {
                            const valueForPlatformArr = value.map(val => platforms.find(plat => plat.value === val)?.name);
                            return valueForPlatformArr.some(val => val === env.platform);
                        }
                        const valueForPlatform = platforms.find(plat => plat.value === value)?.name;
                        return valueForPlatform === env.platform;
                    });
                    break;
                case 'organizationalUnitId':
                    tempEnvironments = tempEnvironments.filter(env => {
                        if (valueIsArray) {
                            return value.some(val => val === env.organizationalUnitId);
                        }
                        return value === env.organizationalUnitId;
                    });
                    break;
                default:
                    break;
            }
        });
        return tempEnvironments.map(env => ({
            value: `${platformNameToValue(env.platform)}|${env.id}`,
            label: `${env.name} (${env.id})`,
            icon: platforms.find(plat => plat.name === env.platform)?.icon,
        }));
    }, [activeFilters, environments, platforms, platformNameToValue]);

    const setDataToOrganizationalUnits = React.useCallback(async () => {
        const organizationalUnitsResp = await getOrganizationalUnitService().getOrganizationalUnitsView();
        const environmentsResp = await getCloudAccountsService().getAllCloudAccounts();
        setOrganizationalUnits(organizationalUnitsResp);
        setEnvironments(environmentsResp?.filter(env => platforms.some(plat => plat.name === env.platform)));
    }, [axiosInstance, platforms]);

    React.useEffect(() => {
        setDataToOrganizationalUnits();
    }, [setDataToOrganizationalUnits]);

    const filtersElements = React.useMemo<Array<IRenderedFilter | IRenderedHandler>>(() => [
        renderAddFilter({ key: FILTERS_KEYS.ADD_FILTER }),
        // renderSavedFilters({key: 'kk', savedFilters: []}),
        renderClearAll({ key: FILTERS_KEYS.CLEAR_BUTTON }),
        renderTreeFilter({
            initialData: organizationalUnits as ITreeFilterObject,
            key: 'organizationalUnitId',
            title: 'Organizational Units',
            defaultValue: '',
        }),
        renderMultiSelectFilter({
            initialData: platforms.map(({ value }) => ({ value })),
            displayMapping: platforms.reduce((acc, plat) => {
                return ({
                    ...acc,
                    [plat.value]: {
                        displayText: plat.label,
                        icon: plat.icon,
                    },
                });
            }, {}),
            key: 'platform',
            title: 'Platform',
            value: [],
            values: [],

        }),
        renderMultiSelectFilter({
            initialData: filteredEnvironments.map(env => ({ value: env.value })),
            displayMapping: filteredEnvironments.reduce((acc, env) => {
                return ({
                    ...acc,
                    [env.value]: {
                        displayText: env.label,
                        icon: env.icon,
                    },
                });
            }, {}),
            key: 'cloudAccountId',
            title: 'Environment',
            value: [],
            values: [],
        }),
    ], [organizationalUnits, platforms, filteredEnvironments]);

    const filters = React.useMemo(() => {
        const filtersFromQueryParams = getFilterParamsFromURL();
        let filtersInitialValues: any = {};
        if (Object.keys(filtersFromQueryParams).length > 0) {
            filtersInitialValues = filtersFromQueryParams;
        } else {
            filtersInitialValues = getFiltersFromLocalStorage() || {};
        }

        return setFiltersInitialValues(filtersInitialValues, filtersElements);
    }, [filtersElements]);

    const onFilterPanelChange = async (lastEvent: ILastEvent) => {
        if (lastEvent.filtersValues) {
            setFilterParamsToURL(lastEvent.filtersValues!, filtersElements as any);
            setFiltersToLocalStorage(lastEvent.filtersValues!);
            if (JSON.stringify(activeFilters) !== JSON.stringify(lastEvent.filtersValues)) {
                onFilterChange(lastEvent.filtersValues);
            }
        }
        return true;
    };

    return (
        <WrapperDashboardFilterPanel>
            <FilterPanel filterPanelProps={{ filters, onFilterPanelChange, containerQuery: {} }} />
            {/* <TimeFilterDropDown timeframeInDays={summaryTimeframeInDays} setTimeframeInDays={setSummaryTimeframeInDays} />
            <Information text='Time filter applies to the following widgets: Compliance Results, Security Events, Exposed Secrets, Critical Fixable Vulnerabilities and Image Violations' /> */}
            <Feedback />
        </WrapperDashboardFilterPanel>
    );
};

export default DashboardFilterPanel;
