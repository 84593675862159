import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import EditShiftLeftModalStyles, { TextFieldWithValidation } from './EditShiftLeftModal.styled';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { FormValues } from './EditShiftLeftModal.types';
import { ModalsProps } from '..';
import { useGetShiftLeft } from 'modules/workloads/reducers/shiftLeft/hooks/useGetShiftLeft';
import { useUpdateShiftLeft } from 'modules/workloads/reducers/shiftLeft/hooks/useUpdateShiftLeft';
import { Message, Typography, Input, Stack } from 'common/design-system/components-v2';
import TreeData from 'common/components/TreeData/TreeData';
import { useOrganizationalUnits } from 'common/hooks/useOrganizationalUnit';

const EditShiftLeftModal: React.FC<ModalsProps> = ({ closeModal, shiftLeftId }) => {
    const { data: shiftLeftData } = useGetShiftLeft(shiftLeftId);
    const { isLoading: isLoadingOrganizationalUnits, organizationalUnits } = useOrganizationalUnits();
    const { t } = useTranslation('k8s_shiftleft');

    const handleOnUpdateSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('shiftLeftPage.editModal.onSubmit.success'),
            text: '',
        });
        onClose();
    };
    const handleOnUpdateError = (error: { message: string }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('shiftLeftPage.editModal.onSubmit.failed'),
            text: error.message,
        });
    };

    const { updateShiftLeft, isLoading: isUpdateLoading } = useUpdateShiftLeft({ onError: handleOnUpdateError, onSuccess: handleOnUpdateSuccess });
    const isLoading = isUpdateLoading || isLoadingOrganizationalUnits; 

    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        getValues
    } = useForm<FormValues>({
        defaultValues: {
            name: shiftLeftData?.account.name,
            organizationalUnitId: shiftLeftData?.account.organizationalUnitId,
            description: shiftLeftData?.account.description
        }
    });
    
    const onClickEditShiftLeft = async (data: FormValues) => {
        const { name, organizationalUnitId, description } = data;
        updateShiftLeft(shiftLeftId, { name, organizationalUnitId, description });
    };

    const onClose = () => {
        closeModal();
    };

    return (
        <Message
            title={t('shiftLeftPage.editModal.header')}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={handleSubmit((data) => onClickEditShiftLeft(data))}
            isLoading={isLoading}
            width='lg'
        >
            <Stack spacing={4}>
                <Controller
                    name='name'
                    rules={{
                        required: 'Is required.',
                        minLength: {
                            value: 2,
                            message: 'Min Length is 2.'
                        }                    
                    }}
                    control={control}
                    render={({ field }) => (
                        <TextFieldWithValidation>
                            <Input
                                {...field}
                                required
                                label={t('shiftLeftPage.editModal.content.fields.name.label')}
                                placeholder={t('shiftLeftPage.editModal.content.fields.name.placeholder')}
                                helperText={errors['name'] ? errors['name']['message'] : ''}
                                isError={!!errors['name']}
                                clearable
                                fullWidth
                            />
                        </TextFieldWithValidation>
                    )}
                />
                <Controller
                    name='description'
                    control={control}
                    rules={{
                        pattern: {
                            value: /^[A-Za-z0-9\s\-_'.,#/@;:!=]*$/,
                            message: 'Invalid characters'
                        }
                    }}
                    render={({ field }) => (
                        <TextFieldWithValidation>
                            <Input
                                {...field}
                                label={t('shiftLeftPage.editModal.content.fields.description.label')}
                                placeholder={t('shiftLeftPage.editModal.content.fields.description.placeholder')}
                                helperText={errors['description'] ? errors['description']['message'] : ''}
                                isError={!!errors['description']}
                                clearable
                                fullWidth
                            />
                        </TextFieldWithValidation>
                    )}
                />
                <EditShiftLeftModalStyles.FormItemWrapper>
                    <Typography color='light'>{t('shiftLeftPage.editModal.content.fields.organizationalUnitId.label')}</Typography>
                    <Controller
                        name='organizationalUnitId'
                        control={control}
                        render={({ field }) => (
                            <TreeData
                                {...field}
                                value={getValues('organizationalUnitId')}
                                placeholder={t('shiftLeftPage.editModal.content.fields.organizationalUnitId.placeholder')}
                                options={organizationalUnits}
                                onChange={(organizationalUnitId) => {
                                    setValue('organizationalUnitId', organizationalUnitId);
                                    return true;
                                }}
                                isLoading={isLoadingOrganizationalUnits}
                            />
                        )}
                    />
                </EditShiftLeftModalStyles.FormItemWrapper>
            </Stack>
        </Message>
    );
};

export default EditShiftLeftModal;
