import React from 'react';
import { FooterWrapper } from './Footer.styled';
import { FooterProps } from './Footer.types';

const Footer: React.FC<FooterProps> = ({ children }) => (
    <FooterWrapper>
        <div className='footer-actions'>
            {children}
        </div>
    </FooterWrapper>
);

export default Footer;
