import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const CveSerachContainer = styled(Stack)`
`;

const SearchBarWrapper = styled(Stack)`
  width: 383px;
`;

const CveHeader = styled(Stack)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.border.normal };
  border-radius: ${({ theme }) => `${theme.border.radius(3)}${theme.units}`};
`;

const CveSearchStyles = {
    CveSerachContainer,
    SearchBarWrapper,
    CveHeader,
};

export default CveSearchStyles;