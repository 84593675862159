import { IAssetDetailsPageAliases } from 'common/module_interface/assets/ProtectedAssets';
import { isUrlStartsWith } from 'common/utils/http';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';

const assetWorkloadsTabsMetadata = {
    tabsId: 'workloadsProtectedAssets',
    closeAllUrl: '/workload/workloads',
};

const assetWorkloadsImagesTabsMetadata = {
    tabsId: 'workloadsImagesProtectedAssets',
    closeAllUrl: '/workload/images',
};

export function initializeAssetDetailsRoutes() {
    ProtectedAssetsPageRegistry.addAliasesAddins([
        {
            id: 'workloads',
            position: 20,
            content: (): IAssetDetailsPageAliases => {
                return {
                    urlAliases: [
                        '/workload/workloads/protected-asset',
                        '/workload/workloads/protected-asset-generic',
                    ],
                    getTabsMetadata: () => {
                        return assetWorkloadsTabsMetadata;
                    },
                    isRelevant: () => isUrlStartsWith('/workload/workloads/protected-asset'),
                };
            }
        },
        {
            id: 'workloads-images',
            position: 30,
            content: (): IAssetDetailsPageAliases => {
                return {
                    urlAliases: [
                        '/workload/images/generic'
                    ],
                    getTabsMetadata: () => {
                        return assetWorkloadsImagesTabsMetadata;
                    },
                    isRelevant: () => isUrlStartsWith('/workload/images/generic'),
                };
            }
        },
    ]);
}