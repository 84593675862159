import styled from 'styled-components';

export const CardMediaWrapper = styled.div<{ disabled: boolean; }>`
    display: flex;
    gap: 4px;
    justify-content: space-between;
    align-items: center;
    pointer-events:  ${props => props.disabled ? 'none' : 'all'};

    & input {
        cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    }
`;

export const CardContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const CardMediaDescription = styled.p`
    font-size: 13px;

    & > a {
        white-space: nowrap;
        color: rgb(0,106,255);
        text-decoration: underline;

        &:visited {
        color: rgb(0,106,255);
        }
    }
`;

export const SubCardsWrapper = styled.div`
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const SubCardsCheckboxWrapper = styled.div<{ disabled: boolean; }>`
    display: flex;
    gap: 10px;
    align-items: center;
    
    & label {
        pointer-events:  ${props => props.disabled ? 'none' : 'all'};
        cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    }
`;