import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

export const FindingsRowWrapper = styled(Stack)`
    height: 46px;
`;

export const Category = styled(Stack)`
    white-space: nowrap;
    border-bottom: 1px solid ${({ theme }) => theme.palette.border.light};
`;