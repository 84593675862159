import { getNotificationsService, getStoreService } from 'common/interface/services';
import { addKubernetesFlowlogsAccount, enableMagellanKubernetes } from '../reducers/magellan/magellan.service';
import { getServiceAccounts } from '../reducers/serviceAccount/serviceAccount.service';
import {
    deleteRegistry,
    getAllRegistries,
    getContainerRegistryAccountSummary,
    getRegistry,
    getRegistryAccountSummary,
    getRegistryAgentSummary,
    linkClusterToRegistryService,
    saveRegistry,
    unlinkClusterToRegistryService,
} from '../reducers/registry/registry.service';
import {
    deleteCluster,
    disableClusterImageAssurance,
    enableClusterAdmissionControl,
    enableClusterImageAssurance,
    enableClusterRuntimeProfiling,
    enableClusterRuntimeProtection,
    getAllKubernetesAccount,
    getClustersFromServer,
    getKubernetesAccount,
    getKubernetesAccountSummary,
    getKubernetesAgentSummary,
    saveCluster,
    updateKubernetesDescription,
    updateKubernetesName,
} from '../reducers/cluster/cluster.service';
import {
    goBackStep,
    goNextStep,
    goToSpecificStep,
    setAllRegistries,
    setImageScanAgentVersion,
    setNewRegistry,
    setRegistryAccountSummary,
    setRegistryAgentSummary,
    setRegistryApplicationClientIdErrorStatus,
    setRegistryPullSecretNameErrorStatus,
    setRegistryRoleARNErrorStatus,
    setRegistryTenantIdErrorStatus,
    setRegistryUrlErrorStatus,
    setSelectedRegistry,
    setServiceAccounts,
    toggleImageAssuranceEnablePopup,
    toggleIsLoading,
} from '../reducers/registry';
import {
    setAllKubernetesAccount,
    setClusterKeyErrorStatus,
    setClusterNamespaceErrorStatus,
    setClusters,
    setClusterSecretErrorStatus,
    setIsLoadingClusters,
    setKubernetesAccount,
    setKubernetesAccountSummary,
    setKubernetesAgentSummary,
    setNewCluster,
    setSelectedCluster,
    setSelectedClusterErrorStatus,
} from '../reducers/cluster';
import { DispatchAction, RootState } from 'common/services/store/store';
import { NotificationType } from 'common/interface/notifications';
import { ClusterForm, KubernetesAccount } from 'modules/workloads/reducers/cluster/cluster.interface';
import { Registry, RegistryAgentSummary, RegistryForm } from 'modules/workloads/reducers/registry/registry.interface';
import { changeUrl } from 'common/utils/http';
import { versionsCompare } from '../utils/versionsCompare';
import {
    ScanningEnvironmentType,
    shouldUseApplicationClientId,
    shouldUsePullSecretName,
    shouldUseRoleARN,
    shouldUseTenantId
} from '../utils/RegistryOptions/RegistryOptions';
import { getCloudAccountsService } from 'common/interface/data_services';

type ExtendRegistryAgentSummaryWithAuthMethod = (
    registryAgentSummary: Array<RegistryAgentSummary>,
    selectedRegistry: Registry,
) => Array<RegistryAgentSummary>;
const extendRegistryAgentSummaryWithAuthMethod: ExtendRegistryAgentSummaryWithAuthMethod = (
    registryAgentSummary,
    selectedRegistry,
) => {
    const registryAgentSummaryCopy = [...registryAgentSummary];

    registryAgentSummaryCopy.forEach((registry) => {
        selectedRegistry.linkedKubernetesEnvironments.forEach((kubernetesLink) => {
            if (registry.kubernetesAccountId === kubernetesLink.environmentId) {
                registry.authMethod = { ...kubernetesLink };
            }
        });
    });
    return registryAgentSummaryCopy;
};

export const getRegistryFromServerById = async (id: string) => {
    const { dispatch } = getStoreService();
    try {
        const registryInfo = await getRegistry(id);
        dispatch(setSelectedRegistry(registryInfo));
    } catch (error) {
        dispatch(setSelectedRegistry({}));
        console.log('Error fetch registry:', error);
    }
};

export const getAllRegistriesFromServer = async () => {
    const { dispatch } = getStoreService();

    try {
        const allRegistries = await getAllRegistries();
        dispatch(setAllRegistries(allRegistries));
    } catch (error) {
        dispatch(setAllRegistries([]));
    }
};

export const getAllKubernetesAccountFromServer = async () => {
    const { dispatch } = getStoreService();
    // we need to do order in type of Registry and Kubernetes
    const KubernetesAccountById: { [key: string]: Registry } = {};

    try {
        const allKubernetesAccount = await getAllKubernetesAccount();
        allKubernetesAccount.forEach((registry) => {
            const { id } = registry;
            KubernetesAccountById[id] = registry;
        });
        dispatch(setAllKubernetesAccount(KubernetesAccountById));
    } catch (error) {
        setAllKubernetesAccount({});
        console.log(error, 'error');
    }
};

export const getRegistryAgentSummaryById = async (id: string) => {
    const { dispatch } = getStoreService();
    try {
        const [registryAgentSummary, registryInfo] = await Promise.all([getRegistryAgentSummary(id), getRegistry(id)]);
        dispatch(setRegistryAgentSummary(extendRegistryAgentSummaryWithAuthMethod(registryAgentSummary, registryInfo)));
    } catch (error) {
        console.log('Error fetch agent summary:', error);
        dispatch(setRegistryAgentSummary([]));
    }
};

export const unlinkKubernetesFromRegistry = async (id: string, linkedKubernetesEnvironmentId: string) => {
    try {
        await unlinkClusterToRegistryService(id, linkedKubernetesEnvironmentId);
        await getRegistryAgentSummaryById(id);
    } catch (error) {
        // TODO: we need to see what we want to do when we have an error.
        getRegistryFromServerById(id);
    }
};

export const linkClusterToRegistry = async (id: string, data: any) => {
    const alertsService = getNotificationsService();
    try {
        await linkClusterToRegistryService(id, data);
        await getRegistryAgentSummaryById(id);
        alertsService.addNotification({
            text: 'The request was successful',
            type: NotificationType.SUCCESS,
            title: 'successful',
        });
    } catch (error: any) {
        alertsService.addNotification({
            text: error.response.data,
            type: NotificationType.ERROR,
            title: 'Error',
        });
    }
};

export const getRegistryAccountSummaryById = async (id: string) => {
    const { dispatch } = getStoreService();
    try {
        const registryAccountSummary = await getRegistryAccountSummary(id);
        dispatch(setRegistryAccountSummary(registryAccountSummary || {}));
    } catch (error) {
        console.log('Error fetch account summary:', error);
        dispatch(setRegistryAccountSummary({}));
    }
};

export const getKubernetesAccountSummaryById = async (id: string) => {
    const { dispatch } = getStoreService();
    try {
        const kubernetesAccountSummary = await getKubernetesAccountSummary(id);
        dispatch(setKubernetesAccountSummary(kubernetesAccountSummary || {}));
        return !!kubernetesAccountSummary;
    } catch (error) {
        console.log('Error fetch account summary:', error);
        dispatch(setKubernetesAccountSummary({}));
        return false;
    }
};

export const getClustersFromServerAndSaveToState = async (dispatch: DispatchAction) => {
    dispatch(setIsLoadingClusters(true));
    const clusters = await getClustersFromServer();
    dispatch(setClusters(clusters));
    dispatch(setIsLoadingClusters(false));
};

export const getServiceAccountsFromServer = async () => {
    const dispatch = getStoreService().dispatch;
    const serviceAccounts = await getServiceAccounts();
    dispatch(setServiceAccounts(serviceAccounts));
};

export const continueToClusterStep = async (dispatch: DispatchAction, getState: () => RootState) => {
    dispatch(toggleIsLoading(true));
    try {
        const state = getState();
        const selectedCluster = { ...state.cluster.selectedCluster };
        await enableClusterImageAssurance(selectedCluster.id);
        selectedCluster.imageAssuranceEnabled = true;
        dispatch(setSelectedCluster(selectedCluster));
        dispatch(goToStep(true));
    } catch (e) {
        dispatch(toggleIsLoading(false));
    }
};

export const exitOnBoardingWizard = async () => {
    const dispatch = getStoreService().dispatch;
    const { state } = getStoreService();
    const newCluster = state.registry.newCluster;

    if (newCluster) {
        try {
            await deleteCluster(newCluster.id);
            await getCloudAccountsService().clearCache();
        } catch (e) {
            console.error(e);
        }
    } else {
        if (state.registry.isImageAssuranceEnabledWhileOnboarding) {
            try {
                await disableClusterImageAssurance(state.registry.selectedCluster.id);
            } catch (e) {
                console.error(e);
            }
        }
        dispatch(setSelectedCluster(null));
    }

    const newRegistry = state.registry.newRegistry;

    if (newRegistry) {
        try {
            await deleteRegistry(newRegistry.id);
            await getCloudAccountsService().clearCache();
        } catch (e) {
            console.error(e);
        }
    }
    const isWorkloadPage = window.location.pathname.includes('/workload/');
    changeUrl(isWorkloadPage ? '/workload/environments' : '/cloud-account/index');
};

export const goToStep = (forward: boolean) => {
    return async function(dispatch: DispatchAction, getState: () => RootState) {
        const alertsService = getNotificationsService();
        const state = getState();
        const currentStep = state.registry.step;
        const selectedCluster = state.cluster.selectedCluster;

        if (!selectedCluster) {
            dispatch(setSelectedClusterErrorStatus(true));
        }

        if (state.registry.registryForm.scanningEnvironmentType === ScanningEnvironmentType.AWS) {
            goToStepAwsFlow(dispatch, state, forward);
            return;
        }

        switch (currentStep) {
            case 0: {
                // Registry step
                const registryForm = state.registry.registryForm;
                if (!isRegistryFormValid(registryForm)) {
                    return;
                }
                if (selectedCluster) {
                    if (
                        selectedCluster.imageAssuranceEnabled ||
                        state.registry.isImageAssuranceEnabledWhileOnboarding
                    ) {
                        dispatch(toggleIsLoading(true));
                        try {
                            const registryObject = createRegistryObjectByForm(registryForm, selectedCluster.id);
                            const newRegistry = await saveRegistry(registryObject);
                            await getCloudAccountsService().clearCache();
                            dispatch(setNewRegistry(newRegistry));

                            const agentSummary = await getKubernetesAgentSummary(selectedCluster.id);
                            dispatch(setKubernetesAgentSummary(agentSummary));

                            const imageScanAgent = agentSummary.filter(
                                ({ agentType }: any) => agentType === 'ImageScanEngine',
                            );
                            const agentVersion = imageScanAgent[0].agentSummary[0].version;
                            dispatch(setImageScanAgentVersion(agentVersion));

                            if (agentVersion && versionsCompare(agentVersion, '2.29.0') > -1 || registryForm.scanningEnvironmentType === ScanningEnvironmentType.AWS) {
                                const registryAccountSummary = await getContainerRegistryAccountSummary(
                                    newRegistry?.id,
                                );
                                dispatch(setRegistryAccountSummary(registryAccountSummary));
                                const kubernetesAccountSummary = await getKubernetesAccountSummary(selectedCluster.id);
                                dispatch(setKubernetesAccountSummary(kubernetesAccountSummary || {}));
                                dispatch(goToSpecificStep(3));
                            } else {
                                dispatch(goNextStep());
                            }
                        } catch (e: any) {
                            console.error('catch error:', e);
                            dispatch(toggleIsLoading(false));
                            alertsService.addNotification({
                                text: e?.message || 'error',
                                type: NotificationType.ERROR,
                                title: 'Error',
                            });
                        }
                        dispatch(toggleIsLoading(false));
                    } else {
                        dispatch(toggleImageAssuranceEnablePopup(true));
                    }
                } else {
                    return;
                }
                break;
            }
            case 1: {
                // Cluster step
                const registryForm = state.registry.registryForm;
                const clusterForm: ClusterForm = state.cluster.clusterForm;
                if (forward) {
                    if (!selectedCluster) {
                        if (!isClusterFormValid(clusterForm)) {
                            return;
                        }
                        dispatch(toggleIsLoading(true));
                        try {
                            const clusterObject = {
                                name: clusterForm.name.value,
                                organizationalUnitId: '00000000-0000-0000-0000-000000000000',
                            };
                            const newCluster = await saveCluster(clusterObject);
                            if (clusterForm.imageAssuranceEnabled) {
                                await enableClusterImageAssurance(newCluster.id);
                                newCluster.imageAssuranceEnabled = true;
                            }
                            if (clusterForm.admissionControlEnabled) {
                                await enableClusterAdmissionControl(newCluster.id);
                                newCluster.admissionControlEnabled = true;
                            }
                            if (clusterForm.runtimeProtectionEnabled) {
                                await enableClusterRuntimeProtection(newCluster.id);
                                newCluster.runtimeProtectionEnabled = true;
                            }
                            if (clusterForm.runtimeProtectionProfiling) {
                                await enableClusterRuntimeProfiling(newCluster.id);
                                newCluster.runtimeProtectionProfiling = true;
                            }
                            if (clusterForm.threatIntelligenceEnabled) {
                                await addKubernetesFlowlogsAccount(state.user.user.id, newCluster.id);
                                await enableMagellanKubernetes(newCluster.id);
                                newCluster.threatIntelligenceEnabled = true;
                            }
                            dispatch(setNewCluster(newCluster));
                            const registryObject = createRegistryObjectByForm(registryForm, newCluster.id);
                            const newRegistry = await saveRegistry(registryObject);
                            await getCloudAccountsService().clearCache();
                            dispatch(setNewRegistry(newRegistry));
                        } catch (e: any) {
                            dispatch(toggleIsLoading(false));
                            alertsService.addNotification({
                                text: e?.message || 'error',
                                type: NotificationType.ERROR,
                                title: 'Error',
                            });
                            return;
                        }
                        dispatch(toggleIsLoading(false));
                    } else {
                        if (!isApiKeySecretValid(clusterForm)) {
                            return;
                        }
                    }
                    dispatch(goNextStep());
                } else {
                    const newRegistry = state.registry.newRegistry;
                    if (newRegistry) {
                        try {
                            await deleteRegistry(newRegistry.id);
                            await getCloudAccountsService().clearCache();
                        } catch (e) {
                            console.error(e);
                        }
                        dispatch(setNewRegistry(null));
                    }
                    dispatch(goBackStep());
                }
                break;
            }
            case 2: {
                // Instructions step
                if (forward) {
                    const registryAccountSummary = await getContainerRegistryAccountSummary(
                        state.registry.newRegistry.id,
                    );
                    dispatch(setRegistryAccountSummary(registryAccountSummary));
                    const relevantClusterId = state.cluster.newCluster?.id || state.cluster.selectedCluster?.id;
                    const kubernetesAccountSummary = await getKubernetesAccountSummary(relevantClusterId);
                    dispatch(setKubernetesAccountSummary(kubernetesAccountSummary || {}));
                    dispatch(goNextStep());
                } else {
                    if (!selectedCluster) {
                        dispatch(toggleIsLoading(true));
                        const newCluster = state.cluster.newCluster;
                        try {
                            await deleteCluster(newCluster.id);
                            await getCloudAccountsService().clearCache();
                        } catch (e) {
                            console.error(e);
                        }
                        dispatch(setNewCluster(null));
                        const newRegistry = state.registry.newRegistry;
                        try {
                            await deleteRegistry(newRegistry.id);
                            await getCloudAccountsService().clearCache();
                        } catch (e) {
                            console.error(e);
                        }
                        dispatch(setNewRegistry(null));
                        dispatch(toggleIsLoading(false));
                    }
                    dispatch(goBackStep());
                }
                break;
            }
            case 3: {
                // Onboarding Summary
                if (forward) {
                    // aka 'Finish'
                    changeUrl(`/workload/environments/containerregistry/${state.registry.newRegistry.id}`);
                } else {
                    if (state.registry?.imageScanAgentVersion && versionsCompare(state.registry?.imageScanAgentVersion, '2.4') > -1) {
                        // return to Registry config step (0)
                        dispatch(toggleIsLoading(true));
                        const newRegistry = state.registry.newRegistry;
                        if (newRegistry) {
                            try {
                                await deleteRegistry(newRegistry.id);
                                await getCloudAccountsService().clearCache();
                                dispatch(setNewRegistry(null));
                            } catch (e) {
                                console.error(e);
                            }
                        }
                        dispatch(toggleIsLoading(false));
                        dispatch(goToSpecificStep(0));
                    } else {
                        // return to Instructions step (2)
                        dispatch(goBackStep());
                    }
                }
                break;
            }
        }

        async function goToStepAwsFlow (dispatch: DispatchAction, state: RootState, forward: boolean) {
            const registryForm = state.registry.registryForm;
            const currentStep = state.registry.step;
        
            if (!isRegistryFormValid(registryForm)) {
                return;
            }
           
            switch (currentStep) {
                case 0: {
                    dispatch(toggleIsLoading(true));
                    try {
                        const registryObject = createRegistryObjectByForm(registryForm, selectedCluster.id);
                        const newRegistry = await saveRegistry(registryObject);
                        await getCloudAccountsService().clearCache();
                        dispatch(setNewRegistry(newRegistry));
                        dispatch(goNextStep());
                        dispatch(toggleIsLoading(false));
                    } catch (e: any) {
                        console.error(e);
                        dispatch(toggleIsLoading(false));
                        alertsService.addNotification({
                            text: e?.message || 'error',
                            type: NotificationType.ERROR,
                            title: 'Error',
                        });
                    }
                    break;
                }
                case 1: {
                    const clusterForm: ClusterForm = state.cluster.clusterForm;
                    if (forward) {
                        if (!isApiKeySecretValid(clusterForm)) return;
                        dispatch(goNextStep());
                    } else {
                        const newRegistry = state.registry.newRegistry;
                        if (newRegistry) {
                            try {
                                await deleteRegistry(newRegistry.id);
                                await getCloudAccountsService().clearCache();
                            } catch (e) {
                                console.error(e);
                            }
                            dispatch(setNewRegistry(null));
                        }
                        dispatch(goBackStep());
                    }
                    break;
                }
                case 2: {
                    if (forward) {
                        // aka finish
                        changeUrl(`/cloud-account/containerregistry/${state.registry.newRegistry.id}`);
                    } else {
                        const newRegistry = state.registry.newRegistry;
                        if (newRegistry) {
                            try {
                                await deleteRegistry(newRegistry.id);
                                await getCloudAccountsService().clearCache();
                            } catch (e) {
                                console.error(e);
                            }
                            dispatch(setNewRegistry(null));
                        }
                        dispatch(goBackStep());
                    }
                }
        
            }
        }

        function isRegistryFormValid (
            registryForm: RegistryForm,
        ) {
            const { registryType, scanningEnvironmentType, authMethod } = registryForm;
            let isValid = true;

            if (
                shouldUsePullSecretName(registryType, scanningEnvironmentType, authMethod)
                && registryForm.pullSecretName.value.trim() === ''
            ) {
                dispatch(setRegistryPullSecretNameErrorStatus());
                isValid = false;
            }

            if (
                shouldUseTenantId(registryType, scanningEnvironmentType, authMethod)
                && registryForm.tenantId.value.trim() === ''
            ) {
                dispatch(setRegistryTenantIdErrorStatus());
                isValid = false;
            }

            if (
                shouldUseApplicationClientId(registryType, scanningEnvironmentType, authMethod)
                && registryForm.applicationClientId.value.trim() === ''
            ) {
                dispatch(setRegistryApplicationClientIdErrorStatus()); 
                isValid = false;
            }

            if (
                shouldUseRoleARN(registryType, scanningEnvironmentType, authMethod)
                && registryForm.roleARN.value.trim() === ''
            ) {
                dispatch(setRegistryRoleARNErrorStatus()); 
                isValid = false;
            }

            if (registryForm.name.value.trim() === '' || registryForm.registryType === null) {
                isValid = false;
            }

            if (registryForm.registryUrl.value.trim() === '' || registryForm.registryUrl.isError) {
                dispatch(setRegistryUrlErrorStatus());
                isValid = false;
            }

            return isValid;
        }

        function isClusterFormValid(clusterForm: ClusterForm): boolean {
            if (clusterForm.apiKey.value.trim() === '') {
                dispatch(setClusterKeyErrorStatus());
                return false;
            }
            if (clusterForm.apiSecret.value.trim() === '') {
                dispatch(setClusterSecretErrorStatus());
                return false;
            }
            if (clusterForm.namespace.value.trim() === '') {
                dispatch(setClusterNamespaceErrorStatus());
                return false;
            } else if (clusterForm.name.value.trim() === '') {
                return false;
            }
            return true;
        }

        function isApiKeySecretValid(clusterForm: ClusterForm): boolean {
            if (clusterForm.apiKey.value.trim() === '') {
                dispatch(setClusterKeyErrorStatus());
                return false;
            }
            if (clusterForm.apiSecret.value.trim() === '') {
                dispatch(setClusterSecretErrorStatus());
                return false;
            }
            return true;
        }
    };
};

function createRegistryObjectByForm(registryForm: RegistryForm, clusterId: string) {
    const { organizationalUnitId, description, scanningEnvironmentType ,registryType, authMethod } = registryForm;
    
    const sendPullSecretName = shouldUsePullSecretName(registryType, scanningEnvironmentType,authMethod);
    const sendTenantId = shouldUseTenantId(registryType, scanningEnvironmentType,authMethod);
    const sendAppClientId = shouldUseApplicationClientId(registryType, scanningEnvironmentType,authMethod);
    const sendRoleArn = shouldUseRoleARN(registryType, scanningEnvironmentType,authMethod);

    const pullSecretName = sendPullSecretName ? registryForm.pullSecretName.value : null;
    const tenantId = sendTenantId ? registryForm.tenantId.value : null;
    const applicationClientId = sendAppClientId ? registryForm.applicationClientId.value : null;
    const roleArn = sendRoleArn ? registryForm.roleARN.value : null;

    return {
        name: registryForm.name.value,
        description,
        organizationalUnitId,
        registryType: registryForm.registryType,
        registryUrl: registryForm.registryUrl.value,
        linkedScanningEnvironments: [
            {
                environmentId: clusterId,
                applicationClientId,
                pullSecretName,
                tenantId,
                roleArn,
                scanningEnvironmentType,
                registryAuthType: registryForm.authMethod,
            },
        ]
    };
}

export const getKubernetesAccountById = async (id: string) => {
    const { dispatch } = getStoreService();
    try {
        const kubernetesAccount = await getKubernetesAccount(id);
        dispatch(setKubernetesAccount(kubernetesAccount || {}));
        return !!kubernetesAccount;
    } catch (error) {
        console.log('Error fetch kubernetes account:', error);
        dispatch(setKubernetesAccount({}));
        return false;
    }
};

export const deleteClusterById = async (id: string) => {
    const { dispatch } = getStoreService();
    try {
        await deleteCluster(id);
        await getCloudAccountsService().clearCache();
        await dispatch(setSelectedCluster(null));
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: 'Environment Deleted Successfully',
            text: '',
        });
    } catch (error) {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: 'Failed To Delete Environment',
            text: '',
        });
    }
};

export const updateKubernetesDescriptionAndName = async (
    id: Pick<KubernetesAccount, 'id'>['id'],
    description: Pick<KubernetesAccount, 'description'>['description'],
    name: Pick<KubernetesAccount, 'name'>['name'],
) => {
    const { dispatch } = getStoreService();
    const kubernetesAccount = await Promise.all([updateKubernetesName(id, name), updateKubernetesDescription(id, description)]);
    const [kubernetesAccountName, kubernetesAccountDescription] = kubernetesAccount;

    if (kubernetesAccountName && kubernetesAccountDescription) {
        dispatch(setKubernetesAccount({ ...kubernetesAccountDescription, name: kubernetesAccountName.name }));
    }

    return !!(kubernetesAccountName && kubernetesAccountDescription);
};
