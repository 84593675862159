import { RegistryType, ScanningEnvironmentType } from '../../utils/RegistryOptions/RegistryOptions';
import { RegistryState } from './registry.interface';

export const initialState: RegistryState = {
    step: 0,
    isLoading: false,
    selectedRegistry: {},
    registryAgentSummary: [],
    registryAccountSummary: {},
    serviceAccounts: [],
    registryForm: {
        name: {
            value: `registry-${Math.floor(Math.random() * 999999999)}`,
            errorMessage: 'Registry name can\'t be empty',
            isDirty: true,
            isError: false,
        },
        description: undefined,
        organizationalUnitId: '',
        scanningEnvironmentType: ScanningEnvironmentType.Kubernetes,
        registryUrl: {
            value: '',
            errorMessage: 'Registry URI can\'t be empty',
            isDirty: false,
            isError: false,
        },
        registryType: RegistryType.ACR,
        authMethod: 'AcrServicePrincipal',
        applicationClientId: {
            value: '',
            errorMessage: 'Application client Id can\'t be empty',
            isDirty: false,
            isError: false,
        },
        pullSecretName: {
            value: '',
            errorMessage: 'Pull secret name can\'t be empty',
            isDirty: false,
            isError: false,
        },
        tenantId: {
            value: '',
            errorMessage: 'Tenant Id can\'t be empty',
            isDirty: false,
            isError: false,
        },
        roleARN: {
            value: '',
            errorMessage: 'Role ARN can\'t be empty',
            isDirty: false,
            isError: false,
        },
    },
    newRegistry: null,
    isImageAssuranceEnablePopupOpen: false,
    isImageAssuranceEnabledWhileOnboarding: false,
    imageScanAgentVersion: null,
    allRegistries: [],
};