import { RegisterOptions } from 'react-hook-form';
import { TFunction } from 'i18next';

export interface AdvancedConfigProps {
    registryId: string;
    closeModal: () => void;
}

export const fieldsValidations: (t: TFunction) => { [field: string]: RegisterOptions } = (t: TFunction) => ({
    maxImagesPerRepo: {
        min: {
            value: 1,
            message: t('k8s_common:validations.min', { min: 1 })
        },
        max: {
            value: 10000,
            message: t('k8s_common:validations.max', { max: 10000 })
        },
        required: t('k8s_common:validations.required'),
    },
    imageListIntervalSeconds: {
        min: {
            value: 1,
            message: t('k8s_common:validations.min', { min: 1 })
        },
        max: {
            value: 24,
            message: t('k8s_common:validations.max', { max: 24 })
        },
        required: t('k8s_common:validations.required'),
    }
});