import { Icon } from '@dome9/berries/react-components';
import React from 'react';
import { KOBCommandsWrapper, KOBCommandsLabel, KOBCommandsCopyButton, KOBCommandsHelmSection } from './KOBCommands.styles';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { useTranslation } from 'react-i18next';

interface KOBNonHelmCommandsProps {
    commands?: string;
    namespace: string;
}

const KOBNonHelmCommands: React.FunctionComponent<KOBNonHelmCommandsProps> = ({ commands, namespace }) => {
    const { t } = useTranslation('k8s_cluster');

    const alertsService = React.useMemo(() => getNotificationsService(), []);

    const handleOnCopyClick = (textToCopy: string, alertMessage: string) => {
        navigator.clipboard.writeText(textToCopy);
        alertsService.addNotification({
            text: alertMessage,
            type: NotificationType.SUCCESS,
            title: t('ON_BOARDING.PAGE.COMMAND.COPY_ALERT.TITLE'),
        });
    };

    return (
        <KOBCommandsWrapper>
            <p>Run the following command</p>
            <KOBCommandsHelmSection>
                <KOBCommandsLabel>{`kubectl create namespace ${namespace}`}</KOBCommandsLabel>
                <KOBCommandsCopyButton onClick={() => handleOnCopyClick(namespace, 'Copy success')}>
                    <Icon name='copy' size={12} />
                </KOBCommandsCopyButton>
            </KOBCommandsHelmSection>
            <p>Generate the needed yaml files:</p>
            <KOBCommandsHelmSection>
                <KOBCommandsLabel>{commands || t('ON_BOARDING.PAGE.COMMAND.DEFAULT_COMMAND')}</KOBCommandsLabel>
                {commands && (
                    <KOBCommandsCopyButton onClick={() => handleOnCopyClick(commands, t('ON_BOARDING.PAGE.COMMAND.COPY_ALERT.TEXT'))}>
                        <Icon name='copy' size={12} />
                    </KOBCommandsCopyButton>
                )}
            </KOBCommandsHelmSection>
            <p>If your cluster is using a Docker or CRI-O runtime environment please change the containerRuntime flag to: --set containerRuntime=docker or --set containerRuntime=cri-o</p>
            <p>If your cluster platform is OpenShift 4+ or Tanzu, please append before output redirect: --set platform=openshift or --set platform=tanzu</p>
            <p>Please set kube-version flag to your cluster's Kubernetes version, e.g. for version 1.24: --kube-version 1.24</p>

            <p>Deploy CloudGuard agent</p>
            <KOBCommandsHelmSection>
                <KOBCommandsLabel>kubectl apply -f cloudguard-install.yaml</KOBCommandsLabel>
                <KOBCommandsCopyButton onClick={() => handleOnCopyClick('kubectl apply -f cloudguard-install.yaml', 'Copy success')}>
                    <Icon name='copy' size={12} />
                </KOBCommandsCopyButton>
            </KOBCommandsHelmSection>
        </KOBCommandsWrapper>
    );
};

export default KOBNonHelmCommands;
