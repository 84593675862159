import { TFunction } from 'i18next';
import { ScanMode } from 'modules/workloads/services/awp/awp.interface';
import * as yup from 'yup';
import { getMinScanMachineIntervalInHours, MAX_CONCURRENCE_SCANS_PER_REGION, MAX_SCAN_MACHINE_INTERVAL_IN_HOURS, MIN_CONCURRENCE_SCANS_PER_REGION } from './AdvancedConfig.consts';

export interface AdvancedConfigForm {
    scanMachineIntervalInHours: number;
    maxConcurrenceScansPerRegion: number;
    customTags: Array<{ key: string; value: string; }>;
    inAccountScannerVPC?: InAccountScannerVPC;
}

export const advancedConfigFormSchema = (t: TFunction, scanMode: ScanMode) => {
    const minScanMachineIntervalInHours = getMinScanMachineIntervalInHours(scanMode);
    return yup.object({
        scanMachineIntervalInHours: yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(t('k8s_common:validations.required'))
            .min(minScanMachineIntervalInHours, t('k8s_common:validations.min', { min: minScanMachineIntervalInHours }))
            .max(MAX_SCAN_MACHINE_INTERVAL_IN_HOURS, t('k8s_common:validations.max', { max: MAX_SCAN_MACHINE_INTERVAL_IN_HOURS }))
            .integer(t('k8s_common:validations.integer'))
            .nullable(false),
        maxConcurrenceScansPerRegion: yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(t('k8s_common:validations.required'))
            .min(MIN_CONCURRENCE_SCANS_PER_REGION, t('k8s_common:validations.min', { min: MIN_CONCURRENCE_SCANS_PER_REGION }))
            .max(MAX_CONCURRENCE_SCANS_PER_REGION, t('k8s_common:validations.max', { max: MAX_CONCURRENCE_SCANS_PER_REGION }))
            .integer(t('k8s_common:validations.integer'))
            .nullable(false),
        customTags: yup.array().of(yup.object({
            key: yup.string().required(t('k8s_common:validations.required')).matches(/^[a-zA-Z0-9_.-]{1,25}$/gm, t('k8s_common:validations.customTagKeyRegex')),
            value: yup.string().required(t('k8s_common:validations.required')).matches(/^[a-zA-Z0-9_.-]{1,30}$/gm, t('k8s_common:validations.customTagKeyRegex'))
        }))
    }).required();
};

export enum InAccountScannerVPC {
    ManagedByAWP = 'ManagedByAWP',
    ManagedByCustomer = 'ManagedByCustomer'
}