import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import KubernetesGeneralSectionNamespace from './components/Namespace';
import { AssetTypes } from 'common/assets/assets.const';
import PodOwner from './components/PodOwner/PodOwner';
import { getProtectedAssetUrlData } from './components/PodOwner/PodOwner.utils';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { getUserService } from 'common/interface/services';

export const generalMoreInfoGetters = (entity: ICloudEntityData, moreInfoType: moreInfoTypes): string | JSX.Element | undefined => {
    switch (moreInfoType) {
        case moreInfoTypes.namespace:
            return <KubernetesGeneralSectionNamespace entity={entity} />;
        case moreInfoTypes.created:
            return entity.cloudEntity.created || '';
        case moreInfoTypes.name:
            return entity.cloudEntity.name || '';
        case moreInfoTypes.podOwner: {
            return getUserService().hasPermission(['allow_linkable_more_info_podowner']) && entity.cloudEntity.owner?.rootOwner.kind ? (
                <PodOwner
                    owner={entity.cloudEntity.owner.rootOwner}
                    cloudAccountId={entity.cloudAccountId}
                    getUrl={getProtectedAssetUrlData(getProtectedAssetsService())}
                />
            ) : (entity.cloudEntity.owner?.rootOwner.kind || '');
        }
        case moreInfoTypes.nodeName:
            return entity.cloudEntity.spec?.nodeName || '';
        case moreInfoTypes.daemonSetPods:
            return `${entity.cloudEntity.status?.numberAvailable || 0}/${entity.cloudEntity.status?.numberReady || 0}`;
        case moreInfoTypes.deploymentPods:
            return `${entity.cloudEntity.status?.readyReplicas || 0}/${entity.cloudEntity.status?.replicas || 0}`;
        case moreInfoTypes.replicaSetPods:
            return `${entity.cloudEntity.status?.readyReplicas || 0}/${entity.cloudEntity.status?.replicas || 0}`;
        default:
            return undefined;
    }
};

export enum moreInfoTypes {
    namespace = 'namespace',
    created = 'created',
    name = 'name',
    podOwner = 'podOwner',
    nodeName = 'nodeName',
    daemonSetPods = 'daemonSetPods',
    deploymentPods = 'deploymentPods',
    replicaSetPods = 'replicaSetPods'
}

export enum generalFeatures {
    moreInfo = 'moreInfo',
    images = 'images',
    spec = 'spec',
    labels = 'labels',
    tolerations = 'tolerations',
    annotations = 'annotations',
    alerts = 'alerts'
}

export interface FeaturesAndInfoByType {
    [type: string]: {
        features: Array<generalFeatures>;
        moreInfo: Array<moreInfoTypes>;
    }
}

export const featuresAndInfoByType: FeaturesAndInfoByType = {
    [AssetTypes.KUBERNETES_KUBERNETESPOD]: {
        features: [generalFeatures.moreInfo, generalFeatures.images, generalFeatures.labels],
        moreInfo: [moreInfoTypes.name, moreInfoTypes.podOwner, moreInfoTypes.nodeName, moreInfoTypes.namespace]
    },
    [AssetTypes.KUBERNETES_KUBERNETESDAEMONSET]: {
        features: [generalFeatures.moreInfo, generalFeatures.images, generalFeatures.labels, generalFeatures.tolerations],
        moreInfo: [moreInfoTypes.namespace, moreInfoTypes.daemonSetPods]
    },
    [AssetTypes.KUBERNETES_KUBERNETESDEPLOYMENT]: {
        features: [generalFeatures.moreInfo, generalFeatures.images, generalFeatures.labels],
        moreInfo: [moreInfoTypes.namespace, moreInfoTypes.deploymentPods]
    },
    [AssetTypes.KUBERNETES_KUBERNETESREPLICASET]: {
        features: [generalFeatures.moreInfo, generalFeatures.images, generalFeatures.labels],
        moreInfo: [moreInfoTypes.namespace, moreInfoTypes.replicaSetPods]
    },
    [AssetTypes.KUBERNETES_KUBERNETESNODE]: {
        features: [generalFeatures.moreInfo, generalFeatures.spec, generalFeatures.labels, generalFeatures.annotations, generalFeatures.alerts],
        moreInfo: [moreInfoTypes.nodeName]
    }
};

export const isFeatureRelevant = (entity: ICloudEntityData, featureType: generalFeatures) => {
    return featuresAndInfoByType[entity.typeByPlatform]?.features.some(feature => feature === featureType);
};