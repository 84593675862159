import React from 'react';
import { Button, IconButton, Input, Stack } from 'common/design-system/components-v2';
import { Control, Controller, FieldErrors, UseFormRegister, useFieldArray } from 'react-hook-form';
import { AwpSettingsFields } from '../../AwpSettings.types';
import { useTranslation } from 'react-i18next';

export interface Tag {
    key: string;
    value: string;
}

export interface TagsProps {
    errors?: FieldErrors<AwpSettingsFields>;
    register: UseFormRegister<AwpSettingsFields>;
    control: Control<AwpSettingsFields>;
}


const AwpTags: React.FunctionComponent<TagsProps> = (({ errors, control, register }) => {
    const { fields: tags, append, remove } = useFieldArray({ name: 'customTags', control });
    const { t } = useTranslation();
    return (
        <Stack spacing={2}>
            {tags.map((tag, index) => {
                return (
                    <Stack key={`tag-${index}`} spacing={2} direction='row' justifyContent='space-between'>
                        <Stack fullWidth>
                            <Controller
                                name={`customTags.${index}.key`}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        {...register(field.name, { required: 'SETTINGS.WORK_LOAD.AWP.CUSTOM_TAGS.KEY.ERROR' })}
                                        value={tag.key}
                                        isError={!!errors?.customTags?.[index]?.key}
                                        helperText={t(errors?.customTags?.[index]?.key?.message ?? 'SETTINGS.WORK_LOAD.AWP.CUSTOM_TAGS.KEY.HELPER_TEXT')}
                                        label={index === 0 ? t('SETTINGS.WORK_LOAD.AWP.CUSTOM_TAGS.KEY.LABEL') : undefined}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack fullWidth>
                            <Controller
                                name={`customTags.${index}.value`}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        {...register(field.name, { required: 'SETTINGS.WORK_LOAD.AWP.CUSTOM_TAGS.VALUE.ERROR' })}
                                        value={tag.value}
                                        isError={!!errors?.customTags?.[index]?.value}
                                        helperText={t(errors?.customTags?.[index]?.value?.message ?? 'SETTINGS.WORK_LOAD.AWP.CUSTOM_TAGS.VALUE.HELPER_TEXT')}
                                        label={index === 0 ? t('SETTINGS.WORK_LOAD.AWP.CUSTOM_TAGS.VALUE.LABEL') : undefined}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack margin={index === 0 ? [5,0,0,0] : undefined}>
                            <IconButton iconProps={{ name: 'delete' }} onClick={() => remove(index)} />
                        </Stack>
                    </Stack>
                );
            })}
            <Button onClick={() => append({ key: '', value: '' })} iconProps={{ name: 'plus', size: 12 }} variant='text' size='small'>Add</Button>
        </Stack>
    );
});

AwpTags.displayName = 'AwpTags';
export default AwpTags;