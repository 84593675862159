import React from 'react';
import { useTranslation } from 'react-i18next';
import { RoleArnInputProps } from './RoleArnInput.interface';
import { AuthFieldInput } from '../index';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { Typography } from 'common/design-system/components-v2';

const RoleArnInput: React.FC<RoleArnInputProps> = (props) => {
    const { roleARN, setRoleARN } = props;
    const { t } = useTranslation(getK8sNamespace('registry'));
    const roleArnInputTooltip = `${t('ON_BOARDING.ROLE_ARN_INPUT_TOOLTIP')} arn:aws:iam::<b>aws_account_id</b>:role/<b>aws_role_name</b>`;
    const roleArnInputTooltipContent = <Typography><div dangerouslySetInnerHTML={{ __html: roleArnInputTooltip }}></div></Typography>;

    return (
        <AuthFieldInput
            authFieldName={t('ON_BOARDING.ROLE_ARN')}
            tooltipText={roleArnInputTooltipContent}
            authProp={roleARN}
            onChange={setRoleARN}
        />
    );
};

export default RoleArnInput;