import React from 'react';
import { Icon } from '@dome9/berries/react-components';
import { 
    PageWrapper,
    WrapableSection,
    ToggleArrow,
    InputsRow,
    FeaturesSection,
    BottomSection,
    InputsRowLeftSection,
    FeaturesTitle,
    AdvConfigSection,
    AdvConfigTitle,
    Media_,
    LoderFullScreenWrapper
} from './KubernetesOnBoarding.styles';
import KOBTextField from './components/KOBTextField';
import KOBInstructions from './components/KOBInstructions';
import FeatureCard from './components/FeatureCard';
import { useKOBMainLogic } from './hooks/KOB-MainLogic';
import { KOBMainLogicHookStatus } from './hooks/KOB-MainLogic/KOB-MainLogic.types';
import { KubernetesFeatureStatus, KubernetesFeatureType } from 'modules/workloads/services/kubernetesAccount/kubernetesAccount.interface';
import { SelectedFeatureState } from './KubernetesOnBoarding.types';
import ClusterLoading from './components/ClusterLoading';
import ClusterCreationFailed from './components/ClusterCreationFaild/ClusterCreationFaild';
import InputsList from './components/InputsList';
import { changeUrl } from 'common/utils/http';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import { features } from './KubernetesOnBoarding.consts';
import CloudKubernetesIcon from './components/CloudKubernetesIcon';
import { useTranslation } from 'react-i18next';
import KOBNonHelmCommands from './components/KOBNonHelmCommands';
import { useLocation } from 'react-router-dom';
import { Command } from 'common/design-system/components-v2';
import TreeData from 'common/components/TreeData/TreeData';

const KubernetesOnboarding: React.FC = () => {
    const { t } = useTranslation('k8s_cluster');
    const {
        status,
        loading,
        error,
        clusterData,
        clusterName,
        clusterDescription,
        flatOrganizationalUnits,
        organiztionUnitChange,
        generateHelmCommand,
        generateNonHelmCommand,
        changeFeatureStatus,
        clusterNameChange,
        clusterDescriptionChange,
        deleteClusterAndService
    } = useKOBMainLogic({ features: features(t) });
    const location = useLocation();


    const [isAdvOpen, setIsAdvOpen] = React.useState<boolean>(false);
    const [noneHelmSection, setNoneHelmSection] = React.useState<boolean>(false);

    const [namespace, setNamespace] = React.useState<string>('checkpoint');

    const toggleAdvSectionState = () => {
        setIsAdvOpen(current => !current);
    };

    const toggleNoneHelmSectionState = () => {
        setNoneHelmSection(current => !current);
    };

    const handleOnChangespaceChange = (str: string) => {
        if (!str) return false;
        setNamespace(str);
        return true;
    };
    
    const [selectedFeatures, setSelectedFeatures] = React.useState(
        features(t).reduce<SelectedFeatureState>((acc, featureProps) => {
            const newAcc = acc;
            newAcc[featureProps.name] = {
                active: featureProps.defaultActive,
                disabled: featureProps.defaultDisabled
            };

            if (featureProps.subFeatures) {
                const subFeatures = featureProps.subFeatures.reduce<SelectedFeatureState>((subAcc, subFeatureProps) => {
                    const newSubAcc = subAcc;
                    newSubAcc[subFeatureProps.name] = {
                        active: subFeatureProps.defaultActive,
                        disabled: subFeatureProps.defaultDisabled,
                        subFeatureParent: featureProps.name
                    };

                    return newSubAcc;
                }, {});
                
                return { ...newAcc, ...subFeatures };
            }

            return newAcc;
        }
        , {})
    );

    const handleOnFeatureChange = async (featureName: string, newStatus: boolean) => {
        if (status !== KOBMainLogicHookStatus.PendingForUserAction || !clusterData) return;
        const newSelectedFeaturesState = { ...selectedFeatures };

        newSelectedFeaturesState[featureName].active = newStatus;
        const updateStatus = await changeFeatureStatus({
            id: clusterData.id,
            featureType: featureName as KubernetesFeatureType,
            status: newStatus ? KubernetesFeatureStatus.ENABLE : KubernetesFeatureStatus.DISABLE
        });
        
        if (!updateStatus) {
            newSelectedFeaturesState[featureName].active = !newStatus;
            setSelectedFeatures(newSelectedFeaturesState);
            return;
        }

        Object.keys(selectedFeatures).forEach(subFeatureName => {
            if (newSelectedFeaturesState[subFeatureName].subFeatureParent === featureName) {
                newSelectedFeaturesState[subFeatureName] = {
                    ...newSelectedFeaturesState[subFeatureName],
                    disabled: !newStatus,
                    active: false
                };
            }
        });

        setSelectedFeatures(newSelectedFeaturesState);
    };

    const helmCommands = React.useMemo(() => {
        if (
            status !== KOBMainLogicHookStatus.PendingForUserAction &&
            status !== KOBMainLogicHookStatus.PendingForConnectivity
        ) return undefined;
        return generateHelmCommand({
            namespace,
            features: {
                admissionControlEnabled: selectedFeatures[KubernetesFeatureType.admissionControl].active,
                runtimeProtectionEnabled: selectedFeatures[KubernetesFeatureType.runtimeProtection].active,
                threatIntelligenceEnabled: selectedFeatures[KubernetesFeatureType.threatIntelligence].active,
                imageAssuranceEnabled: selectedFeatures[KubernetesFeatureType.imageAssurance].active,
                imageAccessRuntimeMonitorEnabled: selectedFeatures[KubernetesFeatureType.imageAssurance_fileAccessMonitor].active
            }
        });
    }, [status, namespace, selectedFeatures, generateHelmCommand]);

    const nonHelmCommands = React.useMemo(() => {
        if (
            status !== KOBMainLogicHookStatus.PendingForUserAction &&
            status !== KOBMainLogicHookStatus.PendingForConnectivity
        ) return undefined;
        return generateNonHelmCommand({
            namespace,
            features: {
                admissionControlEnabled: selectedFeatures[KubernetesFeatureType.admissionControl].active,
                runtimeProtectionEnabled: selectedFeatures[KubernetesFeatureType.runtimeProtection].active,
                threatIntelligenceEnabled: selectedFeatures[KubernetesFeatureType.threatIntelligence].active,
                imageAssuranceEnabled: selectedFeatures[KubernetesFeatureType.imageAssurance].active,
                imageAccessRuntimeMonitorEnabled: selectedFeatures[KubernetesFeatureType.imageAssurance_fileAccessMonitor].active
            }
        });
    }, [status, namespace, selectedFeatures, generateNonHelmCommand]);

    const cancleOnboarding = async () => {
        await deleteClusterAndService();
        const isWorkloadPage = location.pathname.includes('/workload/');
        changeUrl(isWorkloadPage ? '/workload/environments' : '/cloud-account/index');
    };

    const onSubmitClick = async () => {
        changeUrl(`/workload/environments/kubernetes/${clusterData?.id}`);
    };

    if (error) {
        return (
            <ClusterCreationFailed />
        );
    }

    if (
        !clusterData ||
        status !== KOBMainLogicHookStatus.PendingForUserAction
    ) return (
        <ClusterLoading status={status} />
    );

    return (
        <PageWrapper>
            <CloudKubernetesIcon title={t('ON_BOARDING.PAGE.TITLE')} size={isAdvOpen || noneHelmSection ? 'small' : 'large'} />
            <AdvConfigSection>
                <AdvConfigTitle onClick={toggleAdvSectionState}>
                    <Media_ title={t('ON_BOARDING.PAGE.ADVANCED_CONFIGURATION.TITLE')} icon='book' size='sm' iconSize='24' />
                    <ToggleArrow isOpen={isAdvOpen}>
                        <Icon name={'chevron-down'}/>
                    </ToggleArrow>
                </AdvConfigTitle>
                <WrapableSection isOpen={isAdvOpen}>
                    <InputsRow>
                        <InputsRowLeftSection>
                            <InputsList list={[
                                { 
                                    label: t('ON_BOARDING.PAGE.ADVANCED_CONFIGURATION.FIELDS.CLUSTER_NAME.LABEL'),
                                    tooltip: t('ON_BOARDING.PAGE.ADVANCED_CONFIGURATION.FIELDS.CLUSTER_NAME.TOOLTIP'),
                                    element: (
                                        <KOBTextField 
                                            value={clusterName}
                                            onChange={clusterNameChange}
                                        /> 
                                    )
                                },
                                { 
                                    label: t('ON_BOARDING.PAGE.ADVANCED_CONFIGURATION.FIELDS.NAMESPACE.LABEL'), 
                                    tooltip: t('ON_BOARDING.PAGE.ADVANCED_CONFIGURATION.FIELDS.NAMESPACE.TOOLTIP'),
                                    element: (
                                        <KOBTextField 
                                            value={namespace}
                                            onChange={handleOnChangespaceChange}
                                        /> 
                                    )
                                },
                                {
                                    label: t('ON_BOARDING.PAGE.ADVANCED_CONFIGURATION.FIELDS.ORG_UNIT.LABEL'), 
                                    tooltip: t('ON_BOARDING.PAGE.ADVANCED_CONFIGURATION.FIELDS.ORG_UNIT.TOOLTIP'),
                                    element: (
                                        <TreeData 
                                            value={clusterData.organizationalUnitId}
                                            options={flatOrganizationalUnits}
                                            onChange={organiztionUnitChange}
                                        />
                                    )
                                },
                                { 
                                    label: t('ON_BOARDING.PAGE.ADVANCED_CONFIGURATION.FIELDS.DESCRIPTION.LABEL'), 
                                    tooltip: t('ON_BOARDING.PAGE.ADVANCED_CONFIGURATION.FIELDS.DESCRIPTION.TOOLTIP'),
                                    element: (
                                        <KOBTextField 
                                            value={clusterDescription}
                                            onChange={clusterDescriptionChange}
                                        /> 
                                    )
                                }
                            ]} />
                        </InputsRowLeftSection>
                    </InputsRow>
                    <FeaturesTitle>{t('ON_BOARDING.PAGE.FEATURES.TITLE')}</FeaturesTitle>
                    <FeaturesSection>
                        {features(t).map((feature) => (
                            <FeatureCard
                                key={`feature-${feature.name}`}
                                feature={feature}
                                handleOnFeatureChange={handleOnFeatureChange}
                                selectedFeatures={selectedFeatures}
                            />
                        ))}
                    </FeaturesSection>
                </WrapableSection>
            </AdvConfigSection>
            <Media_
                size='md'
                title={t('ON_BOARDING.PAGE.COMMAND.COPY_THE_COMMAND')}
                icon='info'
                iconSize='24'
                fontWeight='300'
            />
            <BottomSection>
                <Command text={helmCommands || t('ON_BOARDING.PAGE.COMMAND.DEFAULT_COMMAND')} />
                <KOBInstructions noneHelmCommandClick={toggleNoneHelmSectionState} onCancelClick={cancleOnboarding} onSubmitClick={onSubmitClick} />
            </BottomSection>
            {noneHelmSection && <KOBNonHelmCommands commands={nonHelmCommands} namespace={namespace} />}
            {loading && (
                <LoderFullScreenWrapper>
                    <CloudAnimationLoader size={'medium'} />
                </LoderFullScreenWrapper>
            )}
        </PageWrapper>
    );
};

export default KubernetesOnboarding;
