import React from 'react';
import { DividerContainer, DividerText, DividerLine } from './HorizontalDivider.styled';
import { Typography } from 'common/design-system/components-v2';

interface HorizontalDividerProps {
  text: string;
}

const HorizontalDivider: React.FC<HorizontalDividerProps> = ({ text }) => {
    return (
        <DividerContainer>
            <DividerText>
                <Typography variant='bodyXs' color='light'>{text}</Typography>
            </DividerText>
            <DividerLine />
        </DividerContainer>
    );
};
  
export default HorizontalDivider;