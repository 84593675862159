import ImageAssurance from './components/ImageAssurance/ImageAssurance';
import AwpSettings from './components/Awp/AwpSettings';
import { ConfigCardWrapper } from './Settings.styled';

const Settings = () => {
    return (
        <ConfigCardWrapper padding={[6, 6, 0, 6]} direction='column' fullWidth spacing={4}>
            <ImageAssurance />
            <AwpSettings />
        </ConfigCardWrapper>

    );
};

export default Settings;