import React, { useState } from 'react';
import ImagesOnWorkload from '../ImagesOnWorkload/ImagesOnWorkload';
import { Image } from 'modules/workloads/services/workload/workload.interface';
import SbomTableWithFilter from '../SbomTableWithFilter/SbomTableWithFilter';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';

const WorkloadSbom: React.FC<{ dome9Id: string, environmentId: string, cloudEntityData: ICloudEntityData }> = ({ dome9Id, environmentId, cloudEntityData }) => {
    const [selectedImage, setSelectedImage] = useState<Image | null>(null);

    return (
        <>
            <ImagesOnWorkload onClickSelectImage={setSelectedImage} selectedImage={selectedImage} dome9Id={dome9Id} />
            {selectedImage !== null && (<SbomTableWithFilter
                entityName={selectedImage?.name || ''}
                cloudEntityData={cloudEntityData}
                entityId={selectedImage?.imageId || ''}
                entityType={selectedImage?.type || ''}
                environmentId={environmentId}
            />)}
        </>
    );
};

export default WorkloadSbom;