import { useState, useEffect } from 'react';
import * as serviceAccountsServer from 'modules/workloads/services/service-account/service-account.service';
import {
    CreateServiceAccount,
    GetServiceAccountsResponse,
    ServiceAccount
} from '../services/service-account/service-account.interface';

type DeleteServiceAccount = (id: Pick<ServiceAccount, 'id'>) => void
type Error = { isError: boolean, errorMessage: string }
type useServiceAccount = () => {
    serviceAccounts: GetServiceAccountsResponse,
    isLoading: boolean
    error: Error
    createServiceAccount: CreateServiceAccount,
    deleteServiceAccount: DeleteServiceAccount
}

const useServiceAccount: useServiceAccount = () => {
    const [serviceAccounts, setServiceAccount] = useState<GetServiceAccountsResponse>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>({ isError: false, errorMessage: '' });

    useEffect(() => {
        getServiceAccount();
    }, []);

    const getServiceAccount = async () => {
        setIsLoading(true);
        try {
            const res = await serviceAccountsServer.getServiceAccounts();
            setServiceAccount(res);
        } catch (error: any) {
            setError({ isError: true, errorMessage: error });
        } finally {
            setIsLoading(false);
        }
    };

    const createServiceAccount: CreateServiceAccount = async (dataServiceAccount) => {
        return new Promise((resolve, reject) => {
            serviceAccountsServer.createServiceAccount(dataServiceAccount)
                .then(res => {
                    getServiceAccount();
                    resolve(res);
                }).catch(error => {
                    reject(error);
                });
        });
    };

    const deleteServiceAccount: DeleteServiceAccount = async (id) => {
        return new Promise((resolve, reject) => {
            serviceAccountsServer.deleteServiceAccount(id)
                .then(() => {
                    getServiceAccount();
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    };

    return {
        serviceAccounts,
        isLoading,
        error,
        createServiceAccount,
        deleteServiceAccount
    };
};

export default useServiceAccount;