import React from 'react';
import { GetImages } from '../workload.interface';
import WorkloadService from '../workload.service';

export interface UseWorkloadImagesPayload {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    data?: GetImages.Response;

    reloadData: () => void;
}

export type UseImagesByWorkload = (dome9Id: string) => UseWorkloadImagesPayload;

export const useImagesByWorkload: UseImagesByWorkload = (dome9Id) => {
    const [{ loading, error, data }, setState] = React.useState<{ loading: boolean; error: boolean; data?: GetImages.Response }>({
        loading: false,
        error: false
    });

    const getData = async (dome9Id: string) => {
        setState(current => ({ loading: true, error: false, data: current.data }));
        try {
            const imagesResponse = await WorkloadService.getImages({ request: { dome9Id } });
            setState(({ loading: true, error: false, data: imagesResponse.data }));
        } catch (error) {
            setState(({ loading: true, error: true }));
        }
    };
 
    React.useEffect(() => {
        if (!loading && !data && !error) {
            getData(dome9Id);
        }
    }, [dome9Id, data, loading, error]);

    const reloadData = React.useCallback(() => getData(dome9Id), [dome9Id]);

    return ({
        isLoading: loading && !data,
        isFetching: loading,
        isError: error,
        data,

        reloadData
    });
};