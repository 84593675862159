import React from 'react';
import { Chip } from 'common/design-system/components-v2';
import { getRiskLevelColorByScore } from 'common/module_interface/RiskManagement/RiskLevel';

const RiskScore: React.FC<{ riskScore: number }> = ({ riskScore }) => (
    <Chip
        customColor={getRiskLevelColorByScore(riskScore)}
        leadingIconProps={{ name: 'riskManagement4' }}
        label={riskScore.toString()}
    />
);

export default RiskScore;