import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const CardWrapper = styled.div`
    display: flex;
    flex: 1;
`;

const CardDetailsWrapper = styled(Stack)`
    max-width: 300px;
`;

export default {
    CardWrapper,
    CardDetailsWrapper
};