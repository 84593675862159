import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import {
    GetImages,
    ImageAssuraceScanImage,
    ImageAssuraceScanEnvironment,
    GetWorkloadEnvironment,
    GetWorkloads,
    RuntimeProtectionAccountRules
} from './workload.interface';

const getImages: GetImages.Function = async (payload) => {
    return await getHttpService().request<AxiosResponse<GetImages.Response>>(
        `workload/imageAssurance/images?dome9id=${payload.request.dome9Id}`, 
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: payload.base?.cachingConfig },
        err => { throw err; }
    );
};

const imageAssuraceScanImage: ImageAssuraceScanImage.Function = async (payload) => {
    return await getHttpService().request<AxiosResponse<ImageAssuraceScanImage.Response>>(
        'workload/imageAssurance/scanImage', 
        {
            method: 'POST',
            data: payload.request
        },
        { returnAsAxiosResponse: true, cachingConfig: payload.base?.cachingConfig },
        err => { throw err; }
    );
};

const imageAssuraceScanEnvironment: ImageAssuraceScanEnvironment.Function = async (payload) => {
    return await getHttpService().request<AxiosResponse<ImageAssuraceScanEnvironment.Response>>(
        `workload/imageAssurance/scanEnvironment/${payload.request.environmentId}/${payload.request.scanAction}`, 
        { method: 'POST' },
        { returnAsAxiosResponse: true, cachingConfig: payload.base?.cachingConfig },
        err => { throw err; }
    );
};

const getWorkloadEnvironment: GetWorkloadEnvironment.Function = async (payload) => {
    return await getHttpService().request<AxiosResponse<GetWorkloadEnvironment.Response>>(
        'workload/environment',
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: payload?.cachingConfig },

        err => { throw err; }
    );
};


const getWorkloads: GetWorkloads.Function = async (payload) => {
    return await getHttpService().request<AxiosResponse<GetWorkloads.Response>>(
        `workload/imageAssurance/image/workloads?imageId=${payload.request.imageId}&imageIdOrDigest=${payload.request.digest}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: payload.base?.cachingConfig },
        err => { throw err; }
    );
};

const runtimeProtectionAccountRules: RuntimeProtectionAccountRules.Function = async (vendor, environmentId, payload) => {
    return await getHttpService().request<AxiosResponse<RuntimeProtectionAccountRules.Response>>(
        `workload/runtimeProtection/${vendor}/accounts/${environmentId}/rules`, 
        {
            method: 'POST',
            data: payload.request
        },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const WorkloadService = {
    getImages,
    imageAssuraceScanImage,
    imageAssuraceScanEnvironment,
    getWorkloadEnvironment,
    getWorkloads,
    runtimeProtectionAccountRules
};

export default WorkloadService;
