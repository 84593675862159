import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const Header = styled(Stack)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.border.normal };
  border-radius: ${({ theme }) => `${theme.border.radius(3)}${theme.units}`};
`;

const SearchCveTableStyles = {
    Header,
};

export default SearchCveTableStyles;