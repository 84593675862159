import React from 'react';
import { Stack } from 'common/design-system/components-v2';
import WidgetCard from 'common/components/Dashboard/WidgetCard/WidgetCard';
import { DataWidgetsProps } from './DataWidgets.types';
import { ProtectedAssetsResponse, SearchRequest } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { getHttpService } from 'common/interface/services';
import { ASSETS_SEARCH_URL } from 'common/module_interface/assets/AssetsConsts';
import Styled from './DataWidgets.styled';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { ScanMode } from 'modules/workloads/services/awp/awp.interface';
import { getOnboardingMethod, toDisplayOnboardingMethod } from './DataWidgets.utils';
import { Vendors } from 'common/consts/vendors';
import { CustomTagsDetail } from '../Details/CustomTagsDetail/CustomTagDetail';
import { DetailSection } from '../Details/DetailSection/DetailSection';
import { CentralizedAccountDetail } from '../Details/CentralizedAccountDetail/CentralizedAccountDetail';
import { DetailItem } from '../Details/Details.types';
import { ScanStatusesWidget } from '../Widgets/ScanStatusesWidget/ScanStatusesWidget';
import { SkippedScansBreakdownWidget } from '../Widgets/SkippedScansBreakdownWidget/SkippedScansBreakdownWidget';
import { OnboardingMethodDetail } from '../Details/OnboardingMethodDetail/OnboardingMethodDetail';
import { AssetTypes } from 'common/assets/assets.const';

const AwpScanAssetsByProvider: Partial<Record<Vendors, string[]>> = {
    [Vendors.AWS]: [AssetTypes.AWS_INSTANCE],
    [Vendors.AZURE]: [AssetTypes.AZURE_VIRTUALMACHINE, AssetTypes.AZURE_FUNCTIONAPP],
};

const DataWidgets: React.FunctionComponent<DataWidgetsProps> = ({ agentlessAccount }) => {
    const {
        isOrgOnboarding,
        isTerraform,
        isOrgOnboardingManagement,
        provider,
        centralizedCloudAccountId,
        scanMode,
        agentlessAccountSettings: { scanMachineIntervalInHours, maxConcurrenceScansPerRegion, customTags, inAccountScannerVPC },
    } = agentlessAccount;
    const { t } = useTranslation(getK8sNamespace('awp'));

    const [searchPayload, setSearchPayload] = React.useState<{ loading: boolean; data?: ProtectedAssetsResponse }>({ loading: true });

    React.useEffect(() => {
        const getLink = async () => {
            setSearchPayload({ loading: true });
            const requestObject: SearchRequest = {
                filter: {
                    fields: [
                        { name: 'cloudAccountId', value: agentlessAccount.cloudAccountId },
                        ...(AwpScanAssetsByProvider[provider] || []).map(type => ({ name: 'type', value: type })),
                    ],
                },
                pageSize: 1000,
                skipAggregations: true,
            };
            const searchResult = await getHttpService().request<ProtectedAssetsResponse>(ASSETS_SEARCH_URL, {
                data: requestObject,
                method: 'POST',
            }, { cachingConfig: { useCache: true } });

            setSearchPayload({ loading: false, data: searchResult });
        };
        getLink();
    }, [agentlessAccount, provider]);

    const onboardingMethod = React.useMemo(() =>
        getOnboardingMethod({ isOrgOnboarding, isTerraform, isOrgOnboardingManagement, provider }),
    [isOrgOnboarding, isOrgOnboardingManagement, isTerraform, provider]);

    const details = React.useMemo<DetailItem[]>(() => {
        return [
            {
                id: 'scan-mode',
                Component: DetailSection,
                props: { title: t('awpTab.details.scanMode'), body: t(`awpTab.scanModeTypes.${scanMode}`) },
            },
            {
                id: 'centralized-account',
                Component: CentralizedAccountDetail,
                props: { centralizedCloudAccountId, provider },
                isRelevant: scanMode === ScanMode.InAccountSub && Boolean(centralizedCloudAccountId),
            },
            {
                id: 'scan-interval',
                Component: DetailSection,
                props: { title: t('awpTab.details.scanInterval'), body: `${scanMachineIntervalInHours || 24}H` },
                isRelevant: scanMode !== ScanMode.InAccountHub,
            },
            {
                id: 'max-concurrence-scans',
                Component: DetailSection,
                props: { title: t('awpTab.details.maxConcurrenceScansPerRegion'), body: maxConcurrenceScansPerRegion || 20 },
                isRelevant: scanMode !== ScanMode.InAccountHub,
            },
            {
                id: 'custom-tags',
                Component: CustomTagsDetail,
                props: { customTags },
                isRelevant: scanMode !== ScanMode.InAccountHub,
            },
            {
                id: 'onboarding-method',
                Component: OnboardingMethodDetail,
                props: { onboardingMethod },
                isRelevant: toDisplayOnboardingMethod(onboardingMethod),
            },
            {
                id: 'in-account-scanner-vpc',
                Component: DetailSection,
                props: { title: t('awpTab.details.customVpc', { context: provider }), body: t(`inAccountScannerVPC.${inAccountScannerVPC}`) },
                isRelevant: ![ScanMode.Saas, ScanMode.InAccountSub].includes(scanMode),
            }
        ];
    }, [centralizedCloudAccountId, customTags, inAccountScannerVPC, maxConcurrenceScansPerRegion, onboardingMethod, provider, scanMachineIntervalInHours, scanMode, t]);

    const widgets = React.useMemo(() => {
        return [
            {
                id: 'scan-statuses',
                Component: ScanStatusesWidget,
                isRelevant: scanMode !== ScanMode.InAccountHub,
            },
            {
                id: 'skipped-scans-breakdown',
                Component: SkippedScansBreakdownWidget,
                isRelevant: scanMode !== ScanMode.InAccountHub,
            },
        ];
    }, [scanMode]);

    return (
        <Stack direction='row' spacing={4}>
            <Styled.CardDetailsWrapper>
                <WidgetCard content={
                    <Stack padding={[2, 8, 2, 4]} spacing={2}>
                        {details.filter(detail => detail.isRelevant ?? true).map(({ Component, props, id }) => {
                            return (
                                <Component key={`details-section-${id}`} {...props} />
                            );
                        })}
                    </Stack>
                } title={t('awpTab.details.title')} />
            </Styled.CardDetailsWrapper>
            {widgets.filter(widget => widget.isRelevant ?? true).map(({ Component, id }) => (
                <Styled.CardWrapper key={`widget-${id}`}>
                    <Component isLoading={searchPayload.loading} data={searchPayload.data} />
                </Styled.CardWrapper>
            ))}
        </Stack>
    );
};

export default DataWidgets;