import React, { useCallback, useMemo } from 'react';
import { ModalsProps } from '..';
import { Vendors } from 'common/consts/vendors';
import { DisableAwpModalType } from './DisableAWP.types';
import { ScanMode } from 'modules/workloads/services/awp/awp.interface';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { getCloudAccountsService } from 'common/interface/data_services';
import { useDeleteAgentlessAccount } from 'modules/workloads/services/awp/hooks/useDeleteAgentlessAccount';
import DisableAzureCli from './components/DisableAzureCli';
import DisableAzureTerraform from './components/DisableTerrafomAzure';
import DisableAwsTerraform from './components/DisableTerraformAws';
import DisableAwsInAccountSubTerraform from './components/DisableTerraformInAccountSubAws';
import DisableStack from './components/DisableStack';
import DisableInAccountSubStack from './components/DisableInAccountSubStack';

const RemoveAccount: React.FC<ModalsProps> = ({ agentlessAccount: { isTerraform, provider, scanMode, cloudAccountId, centralizedCloudAccountId }, closeModal, reloadAgentlessAccount }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));
    const handleOnRemoveSuccess = useCallback(() => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('awpTab.modals.disableAWP.onSubmit.success'),
            text: '',
        });
        getCloudAccountsService().clearCache();
        closeModal();
        reloadAgentlessAccount();
    }, [closeModal, reloadAgentlessAccount, t]);

    const handleOnRemoveError = useCallback(() => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('awpTab.modals.disableAWP.onSubmit.error'),
            text: '',
        });
        closeModal();
    }, [closeModal, t]);

    const { isLoading, deleteAccount } = useDeleteAgentlessAccount({ onSuccess: handleOnRemoveSuccess, onError: handleOnRemoveError });
    const onDelete = useCallback((verify: boolean) => deleteAccount(provider, cloudAccountId , verify), [cloudAccountId, deleteAccount, provider]);

    const modalType = useMemo(() => {
        if (provider === Vendors.AZURE) {
            return isTerraform ? DisableAwpModalType.TERRAFORM_AZURE : DisableAwpModalType.AZ_CLI;
        }
        if (isTerraform) {
            return scanMode === ScanMode.InAccountSub ? DisableAwpModalType.TERRAFORM_IN_ACCOUNT_SUB_AWS : DisableAwpModalType.TERRAFORM_AWS;
        }
        return scanMode === ScanMode.InAccountSub ? DisableAwpModalType.CLOUDFORMATION_STACK_IN_ACCOUNT_SUB : DisableAwpModalType.CLOUDFORMATION_STACK;
    }, [scanMode, provider, isTerraform]);

    switch (modalType) {
        case DisableAwpModalType.AZ_CLI:
            return <DisableAzureCli onClose={closeModal} onDelete={onDelete} isDeletePending={isLoading} cloudAccountId={cloudAccountId} centralizedCloudAccountId={centralizedCloudAccountId} />;
        case DisableAwpModalType.TERRAFORM_AZURE:
            return <DisableAzureTerraform onClose={closeModal} onDelete={onDelete} isDeletePending={isLoading} />;
        case DisableAwpModalType.TERRAFORM_IN_ACCOUNT_SUB_AWS:
            return <DisableAwsInAccountSubTerraform onClose={closeModal} onDelete={onDelete} isDeletePending={isLoading} />;
        case DisableAwpModalType.TERRAFORM_AWS:
            return <DisableAwsTerraform onClose={closeModal} onDelete={onDelete} isDeletePending={isLoading} />;
        case DisableAwpModalType.CLOUDFORMATION_STACK_IN_ACCOUNT_SUB:
            return <DisableInAccountSubStack onClose={closeModal} onDelete={onDelete} isDeletePending={isLoading} />;
        case DisableAwpModalType.CLOUDFORMATION_STACK:
            return <DisableStack onClose={closeModal} onDelete={onDelete} isDeletePending={isLoading} />;
        default:
            return null;
    }
};

export default RemoveAccount;
