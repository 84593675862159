import { useState } from 'react';
import { sendNotificationAndRedirectToEnvironments } from '../../utils';
import useOnInit from 'modules/workloads/hooks/useOnInit';
import { NotificationType } from 'common/interface/notifications';
import AzureCloudAccountService from 'modules/workloads/services/azure-cloud-account/azure-cloud-account.service';
import { useTranslation } from 'react-i18next';
import AwpService from 'modules/workloads/services/awp/awp.service';

type UseInitAwpOnboardingAzurePayload = {
    loading: boolean;
    subscriptionId?: string;
}

export type UseInitAwpOnboardingAzure = (cloudAccountId: string) => UseInitAwpOnboardingAzurePayload;
const useInitAwpOnboardingAzure: UseInitAwpOnboardingAzure = (cloudAccountId) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [subscriptionId, setSubscriptionId] = useState<string>();
    const { t } = useTranslation('k8s_awp');

    useOnInit(() => {
        const onInit = async () => {
            setLoading(true);

            try {
                const data = await Promise.allSettled([
                    AzureCloudAccountService.getAzureAccountDetails(cloudAccountId),
                    AwpService.checkAzureAccount(cloudAccountId, false),
                ]);

                const [d9AzureAccount, agentlessAccountStatus] = data;

                if (agentlessAccountStatus.status === 'fulfilled' && agentlessAccountStatus.value.data.agentlessProtectionEnabled) {
                    // Already exist
                    sendNotificationAndRedirectToEnvironments(
                        NotificationType.WARNING,
                        t('awpOnboarding.azure.messages.awpIsAlreadyEnabled'),
                    );
                }

                if (d9AzureAccount.status === 'rejected' || !d9AzureAccount.value.data) {
                    sendNotificationAndRedirectToEnvironments(
                        NotificationType.ERROR,
                        t('awpOnboarding.azure.messages.missingDome9AzureAccount'),
                    );
                }

                if (d9AzureAccount.status === 'fulfilled' && d9AzureAccount.value.data.subscriptionId) {
                    setSubscriptionId(d9AzureAccount.value.data.subscriptionId);
                }
            } catch (error: any) { /* empty */ }

            setLoading(false);
        };

        onInit();
    });
    
    return {
        loading,
        subscriptionId
    };
};

export default useInitAwpOnboardingAzure;
