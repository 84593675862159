import { ICloudAccount, getCloudAccountsService } from 'common/interface/data_services';
import { useCallback, useEffect, useState } from 'react';

type UseAllCloudAccountsPayload = {
    loading: boolean;
    error: boolean;
    allCloudAccounts?: Array<ICloudAccount>,
    getCloudAccountNameById: (cloudAccountId: string) => string
}

export type UseAllCloudAccounts = () => UseAllCloudAccountsPayload;
const useAllCloudAccounts: UseAllCloudAccounts = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    const [allCloudAccounts, setAllCloudAccounts] = useState<Array<ICloudAccount>>();
    
    useEffect(() => {
        const getAllCloudAccounts = async () => {
            try {
                const allCloudAccountsRes = await getCloudAccountsService().getAllCloudAccounts();
                setAllCloudAccounts(allCloudAccountsRes);
                setLoading(false);
            } catch (e) { 
                setError(true);
                return;
            }
        };
        getAllCloudAccounts();
    }, []);

    const getCloudAccountNameById = useCallback((cloudAccountId: string) => {
        const name = allCloudAccounts?.find((cloudAccount) => cloudAccount.id === cloudAccountId)?.name;

        if (name) {
            return `${name} (${cloudAccountId})`;
        }

        return cloudAccountId;
    }, [allCloudAccounts]);

    return {
        loading,
        error,

        allCloudAccounts,
        getCloudAccountNameById
    };
};

export default useAllCloudAccounts;
