import styled from 'styled-components';

export const KOBInstructionsWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
`;

export const Section = styled.div<{ gap?: number; }>`
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => gap && `${gap}px`};
`;

export const Text = styled.p`
    font-size: 12px;
`;

export const TextBold = styled(Text)`
    font-size: 14px;
    font-weight: bold;
`;

export const LinkButton = styled.a`
    width: fit-content;
    cursor: pointer;
    color: rgb(0, 106, 255);
    text-decoration: underline;

    &:visited {
        color: rgb(0, 106, 255);
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 8px;
    justify-content: center;
`;

export const KOBInstructionsActionButton = styled.button<{ color: 'green' | 'red', variant: 'outlined' | 'contained' }>`
    border: 1px solid ${({ color }) => color ==='green' ? '#56b30e' : '#f2504f'};
    border-radius: 4px;
    background: ${({ color, variant }) => variant === 'contained' ? color === 'green' ? '#56b30e' : '#f2504f' : 'transparent'};
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    color: ${({ color, variant }) => variant === 'contained' ? '#ffffff' : color === 'green' ? '#56b30e' : '#f2504f'};
    padding: 5px 15px;

    & svg {
        fill: ${({ color, variant }) => variant === 'contained' ? '#ffffff' : color === 'green' ? '#56b30e' : '#f2504f'}
    }
`;