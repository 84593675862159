import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { getExternalAdditionalFieldValue } from 'common/module_interface/assets/utils';
import i18n from 'common/services/translations/translations';
import React from 'react';

const ProtectionModeCellRenderer: React.FC<ICellRendererParams> = (params) => {    
    
    const getCellData = (isIncludesFSP: boolean , withFSPBlock: boolean)=> {
        if(isIncludesFSP && withFSPBlock) {
            return { text: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_PROTECTION_MODE.VALUE1'), icon: 'checkCircle' };
        } else if (isIncludesFSP) {
            return { text: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_PROTECTION_MODE.VALUE2'), icon: 'detect' };
        } else {
            return { text: '' };
        }
    };
    
    const isIncludesFSP = getExternalAdditionalFieldValue(params.data, 'IncludesFSP') === 'true';
    const withFSPBlock = getExternalAdditionalFieldValue(params.data, 'WithFSPBlock') === 'true';
    const cellData = getCellData(isIncludesFSP, withFSPBlock);
    
    const options: IIconLinkCellProps = {
        isLink: false,
        value: cellData.text,
    };

    if (cellData.icon) {
        options.iconProps = {
            vendorNameOrPath: cellData.icon,
            size: 14,
        };
    }

    return <IconLinkCellRenderer {...options} />;
};

export default ProtectionModeCellRenderer;