import { useTranslation } from 'react-i18next';
import { DisableAwpModalProps } from '../DisableAWP.types';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { Message, Typography } from 'common/design-system/components-v2';
import React from 'react';

const DisableInAccountSubStack: React.FC<DisableAwpModalProps> = ({ onClose, onDelete, isDeletePending }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));

    return (
        <Message
            title={t('awpTab.modals.disableAWP.aws.title')}
            isOpen
            width='md'
            variant='danger'
            onClose={onClose}
            submitBtnDisabled={isDeletePending}
            onConfirm={() => onDelete(true)}
            onCancel={onClose}
            submitBtnText={t('awpTab.modals.disableAWP.submitTextButton')}
        >
            <Typography>
                {t('awpTab.modals.disableAWP.aws.inAccountSubText')}
            </Typography>
        </Message>
    );
};

export default DisableInAccountSubStack;