import React from 'react';
import { useSelector } from 'react-redux';
import { Stack } from 'common/design-system/components-v2';
import { CveOverview } from '../../services/cveDrawerRegistry/cveDrawerRegistry.types';
import UpperPanel from '../../pages/cveDrawer/components/UpperPanel';
import DescriptionPanel from '../../pages/cveDrawer/components/DescriptionPanel';
import AttackVectorPanel from '../../pages/cveDrawer/components/AttackVectorPanel/AttackVectorPanel';
import { RemediationPanel } from '../../pages/cveDrawer/components/RemediationPanel/RemediationPanel';
import ResourcePanel from '../../pages/cveDrawer/components/ResourcePanel';
import CveReferences from '../../pages/cveDrawer/components/CveReferences/CveReferences';
import { getCveAndPackageById } from 'modules/workloads/reducers/vulnerabilities/selectors';

const VulnerabilityDrawer: React.FC<{ cveId: string, packageId: string }> = (props) => {
    const { cveId, packageId } = props;
    const packageAndCve = useSelector(getCveAndPackageById(cveId, packageId));

    if(!packageAndCve) return null;

    const cveParams: CveOverview = {
        cve: {
            ...packageAndCve.cve,
            isFixable: packageAndCve.cve.isFixedByPackageRemediation,
            cvssInfo: packageAndCve.cve['cvss-info'],
            vectorString: packageAndCve.cve['cvss-info']?.['vector-string'] || ''
        },
        package: {
            ...packageAndCve.package,
            isOsPackage: packageAndCve.package.is_os_package
        },
        accountId: '',
        cloudAccountId: '',
        affectedAsset: null,
        scannedAsset: null,
        dome9Id: '',
        cloudAccountName: '',
        platform: '',
    };

    return (
        <Stack direction='column' spacing={5} fullWidth>
            <UpperPanel overview={cveParams} />
            <DescriptionPanel overview={cveParams} />
            {!!cveParams.cve.cvssInfo && <AttackVectorPanel overview={cveParams} />}
            {!!cveParams.cve.remediation && <RemediationPanel remediation={cveParams.cve.remediation} />}
            <ResourcePanel overview={cveParams} />
            {cveParams.cve.urls?.length > 0 && <CveReferences overview={cveParams} />}
        </Stack>
    );
};

export default VulnerabilityDrawer;