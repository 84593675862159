import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { continueToClusterStep, getClustersFromServerAndSaveToState, } from '../../../K8sRegistry.utils';
import {
    getClusters,
    getIsLoadingClusters,
    getSelectedCluster,
    getSelectedClusterErrorStatus
} from '../../../../reducers/cluster/cluster.slice';
import {
    getImageAssuranceEnablePopupOpen,
    getOrganizationalUnitId,
    getRegistryApplicationClientId,
    getRegistryAuthMethod,
    getRegistryDescription,
    getRegistryName,
    getRegistryPullSecretName,
    getRegistryRoleARN,
    getRegistryTenantId,
    getRegistryType,
    getRegistryUrl,
    getScanningEnvironmentType,
} from '../../../../reducers/registry/registry.slice';
import {
    setIsImageAssuranceEnabledWhileOnboarding,
    setOrganizationalUnitId,
    setRegistryApplicationClientId,
    setRegistryAuthMethod,
    setRegistryDescription,
    setRegistryName,
    setRegistryPullSecretName,
    setRegistryRoleARN,
    setRegistryTenantId,
    setRegistryType,
    setRegistryUrl,
    setScanningEnvironmentType,
    toggleImageAssuranceEnablePopup,
} from '../../../../reducers/registry';
import { setSelectedCluster, setSelectedClusterErrorStatus } from '../../../../reducers/cluster';
import { getStoreService } from 'common/interface/services';
import {
    AppClientIdInput,
    AuthMethodSelector,
    EnableImageAssurancePopup,
    PullSecretNameInput,
    RegistryTypeSelector,
    RegistryUriField,
    RegistyNameInput,
    ScanningEnvironmentSelector,
    TenantIdInput,
} from '../components';
import { SetRegistryTypeAndAuthMethodFunc } from '../components/components.interface';
import {
    RegistryAuthMethod,
    getRegistryOptions,
    RegistryType,
    ScanningEnvironmentType,
    shouldUseApplicationClientId,
    shouldUsePullSecretName,
    shouldUseRoleARN,
    shouldUseTenantId
} from 'modules/workloads/utils/RegistryOptions/RegistryOptions';
import ScanningEnvironmentTypeSelector from '../components/ScanningEnvironmentTypeSelector';
import { ICloudAccount } from 'common/interface/data_services';
import { Stack, Typography } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import useDocumentSize from 'common/hooks/useDocumentSize';
import { ConfigPullSecretNameWrapper, Title } from './RegistryConfigurations.styled';
import KubectlCommand from './components/KubectlCommand';
import RegistryDescriptionInput from '../components/RegistryDescriptionInput/RegistryDescriptionInput';
import SeeDocumentation from 'modules/workloads/components/SeeDocumentation/SeeDocumentation';
import { getCheckpointUrl } from 'common/components/Forms/urls';
import RoleArnInput from '../components/RoleArnInput';
import useReactRouterQuery from 'common/hooks/useReactRouterQuery';
import useAllCloudAccounts from 'common/hooks/useAllCloudAccounts';
import OrganizationalUnit from 'common/components/OrganizationalUnit/OrganizationalUnit';

const RegistryConfigurations: FunctionComponent = () => {
    const dispatch = getStoreService().dispatch;
    const { t } = useTranslation('k8s_registry');
    const clusters = useSelector(getClusters);
    const isLoadingClusters = useSelector(getIsLoadingClusters);
    const selectedCluster = useSelector(getSelectedCluster);
    const selectedClusterError = useSelector(getSelectedClusterErrorStatus);
    const registryName = useSelector(getRegistryName);
    const registryDescription = useSelector(getRegistryDescription);
    const scanningEnvironmentType = useSelector(getScanningEnvironmentType);
    const registryType = useSelector(getRegistryType);
    const authMethod: RegistryAuthMethod = useSelector(getRegistryAuthMethod);
    const registryUrl = useSelector(getRegistryUrl);
    const pullSecret = useSelector(getRegistryPullSecretName);
    const tenantId = useSelector(getRegistryTenantId);
    const appClientId = useSelector(getRegistryApplicationClientId);
    const roleARN = useSelector(getRegistryRoleARN);
    const isImageAssuranceEnablePopupOpen = useSelector(getImageAssuranceEnablePopupOpen);
    
    const { allCloudAccounts, isLoading: isLoadingAllCloudAccounts } = useAllCloudAccounts();
    const [awsScanningEnvironments, setAwsScanningEnvironments] = useState<Array<ICloudAccount>>([]);
    const selectedOrganizationalUnitId = useSelector(getOrganizationalUnitId);
    
    const RegistryOptions = getRegistryOptions();

    const organizationalUnitChange = (id: string): boolean => {
        const isSameAsBefore = selectedOrganizationalUnitId === id;
        if (isSameAsBefore) return true;
        dispatch(setOrganizationalUnitId(id));
        return true;
    };

    useEffect(() => {
        dispatch(getClustersFromServerAndSaveToState);
    }, [dispatch]);

    useEffect(() => {
        const awsEnvs = filterCloudAccountsByScanningEnvType(allCloudAccounts, ScanningEnvironmentType.AWS);
        setAwsScanningEnvironments(awsEnvs);
    }, [allCloudAccounts]);

    const filterCloudAccountsByScanningEnvType = (allCloudAccounts: Array<ICloudAccount>, scanningEnvironmentType: ScanningEnvironmentType) => 
        allCloudAccounts.filter(
            (cloudAccount) => cloudAccount.platform === scanningEnvironmentType.toLowerCase()
        );
        
    function closeIAEnableModal() {
        dispatch(toggleImageAssuranceEnablePopup(false));
    }

    function enableImageAssuranceForCluster() {
        dispatch(setIsImageAssuranceEnabledWhileOnboarding(true));
        dispatch(continueToClusterStep);
        dispatch(toggleImageAssuranceEnablePopup(false));
    }

    const setRegistryTypeAndAuthMethod: SetRegistryTypeAndAuthMethodFunc = (
        newRegistryType: RegistryType,
        authMethod: RegistryAuthMethod,
    ) => {
        if (newRegistryType === RegistryType.GCR) {
            dispatch(setRegistryUrl('gcr.io'));
        }

        if (registryType === RegistryType.GCR) {
            dispatch(setRegistryUrl(''));
        }

        if (newRegistryType === RegistryType.GitHub) {
            dispatch(setRegistryUrl('ghcr.io'));
        }

        dispatch(setRegistryType(newRegistryType));
        dispatch(setRegistryAuthMethod(authMethod));
    };

    const onScanningEnvironmentTypeChanged = (scanningEnvironmentType: ScanningEnvironmentType) => {
        dispatch(setScanningEnvironmentType(scanningEnvironmentType));
        dispatch(setSelectedCluster(null));
        const newSelectedRegistryType = Object.keys(RegistryType).find((registryType: string) =>
            RegistryOptions[registryType as RegistryType].authMethods[scanningEnvironmentType].length
        ) as RegistryType;
        dispatch(setRegistryType(newSelectedRegistryType));
    };

    const authMethodAdditionalComponent = RegistryOptions[registryType].authMethods[scanningEnvironmentType].find((am) => am.key === authMethod)?.component;

    const kubectlCommand = useMemo<string | null>(() => {
        const baseCommand = `kubectl create secret docker-registry ${pullSecret.value || '<pull-secret-name>'} --namespace <namespace> --docker-server=${registryUrl.value || '<registry-URI>'}`;
        switch (authMethod) {
            case 'AcrServicePrincipal':
                return `${baseCommand} --docker-username=<service-principal-ID> --docker-password=<service-principal-password>`;
            case 'EcrUserAccessKeys':
                return `${baseCommand} --docker-username=<AWS_ACCESS_KEY> --docker-password=<AWS_SECRET_KEY>`;
            case 'GcpServiceAccountKey':
                return `${baseCommand} --docker-username=_json_key --docker-password="$(cat gcp-svc-acc-keyfile.json)`;
            case 'ArtifactoryUserPassword':
                return `${baseCommand} --docker-username=<username> --docker-password=<password>`;
            case 'ArtifactoryToken':
                return `${baseCommand} --docker-username=jfrog --docker-password=<reference_token>`;
            case 'HarborUserPassword':
                return `${baseCommand} --docker-username=<username> --docker-password=<password>`;
            case 'NexusUserPassword':
                return `${baseCommand} --docker-username=<username> --docker-password=<password>`;
            case 'GithubToken':
                return `${baseCommand} --docker-username=github --docker-password=<personal-access-token>`;
            default:
                return null;
        }
    }, [authMethod, pullSecret.value, registryUrl]);

    const stackDirection = useDocumentSize().width >= 1700 ? 'row' : 'column';
    const isVisible: boolean = !!(scanningEnvironmentType === ScanningEnvironmentType.Kubernetes && kubectlCommand) || false;  

    const { params: { selectedCluster: selectedClusterIdFromUrl }, deleteParams } = useReactRouterQuery();
    const scanningEnvironmentList = useMemo(() => {
        return scanningEnvironmentType === ScanningEnvironmentType.Kubernetes ? clusters : awsScanningEnvironments;
    }, [awsScanningEnvironments, clusters, scanningEnvironmentType]);
    
    const handleSelectedScanningEnvironment = useCallback((selectedEnvironmentId: string) => {
        const selectedEnvironment = scanningEnvironmentList.find((environment) => environment.id === selectedEnvironmentId); 
        if (!selectedEnvironment) {
            return;
        }
        if (selectedClusterError) {
            dispatch(setSelectedClusterErrorStatus(false));
        }
        dispatch(setSelectedCluster(selectedEnvironment));
    }, [scanningEnvironmentList, selectedClusterError, dispatch]);

    useEffect(() => {
        if (selectedClusterIdFromUrl && !isLoadingClusters) {
            handleSelectedScanningEnvironment(selectedClusterIdFromUrl);
            deleteParams(['selectedCluster']);
        }
    }, [isLoadingClusters, deleteParams, handleSelectedScanningEnvironment, scanningEnvironmentList, selectedClusterIdFromUrl]);

    return (
        <Stack direction={stackDirection}>
            <div className='k8s-registry-configurations registry-step'>
                <Title>Welcome To Registry Onboarding</Title>
                <Stack direction='row' alignItems='center' spacing={1} padding={[0, 0, 4, 0]}>
                    <Typography color='light'>{t('ON_BOARDING.forMoreInformationPlease')}</Typography>
                    <SeeDocumentation link={getCheckpointUrl('K8S_REGISTRY_ONBOARDING')} />
                </Stack>

                <RegistyNameInput
                    registryName={registryName}
                    setRegistryName={(registryName: string) => dispatch(setRegistryName(registryName))}
                />

                <RegistryDescriptionInput
                    registryDescription={registryDescription}
                    setRegistryDescription={(registryDescription: string) => dispatch(setRegistryDescription(registryDescription))}
                />

                <OrganizationalUnit
                    selectedOrganizationalUnit={selectedOrganizationalUnitId}
                    onChange={organizationalUnitChange}
                />

                <div className='input-container'>
                    <ScanningEnvironmentTypeSelector
                        label={t('ON_BOARDING.chooseScanningEnvironmentType')}
                        labelProps={{ required: true, tooltip: t('ON_BOARDING.scanningEnvironmentSelectorTooltip') }}
                        registryScanner={scanningEnvironmentType}
                        onChange={onScanningEnvironmentTypeChanged}
                        awsDisabled={!awsScanningEnvironments.length}
                    />
                </div>
                
                <ScanningEnvironmentSelector
                    selectedCluster={selectedCluster}
                    selectedClusterError={selectedClusterError}
                    setSelectedScanningEnvironment={handleSelectedScanningEnvironment}
                    scanningEnvironments={scanningEnvironmentList}
                    scanningEnvironmentTypeIcon={scanningEnvironmentType.toLowerCase()}
                    scanningEnvironmentType={scanningEnvironmentType}
                    isLoading={scanningEnvironmentType === ScanningEnvironmentType.Kubernetes ? isLoadingClusters : isLoadingAllCloudAccounts}
                />

                <RegistryTypeSelector
                    registryType={registryType}
                    scanningEnvironmentType={scanningEnvironmentType}
                    setRegistryTypeAndAuthMethod={setRegistryTypeAndAuthMethod}  
                />

                <RegistryUriField
                    registryType={registryType}
                    registryUrl={registryUrl}
                    onChange={(registryUrl: string) => dispatch(setRegistryUrl(registryUrl))}
                />
                
                <Stack direction={stackDirection}>
                    <Stack>
                        <AuthMethodSelector
                            registryType={registryType}
                            authMethod={authMethod}
                            scanningEnvironmentType={scanningEnvironmentType}
                            setRegistryAuthMethod={(authMethod: RegistryAuthMethod) => dispatch(setRegistryAuthMethod(authMethod))}
                        />

                        {authMethodAdditionalComponent}

                        {shouldUsePullSecretName(registryType, scanningEnvironmentType, authMethod) && (
                            <PullSecretNameInput
                                registryType={registryType}
                                authMethod={authMethod}
                                pullSecret={pullSecret}
                                setPullSecretName={(e: any) => dispatch(setRegistryPullSecretName(e.target.value))}
                            />
                        )}

                        {shouldUseTenantId(registryType, scanningEnvironmentType, authMethod) && (
                            <TenantIdInput
                                tenantId={tenantId}
                                setTenantId={(e: any) => dispatch(setRegistryTenantId(e.target.value))}
                            />
                        )}

                        {shouldUseApplicationClientId(registryType, scanningEnvironmentType, authMethod) && (
                            <AppClientIdInput
                                appClientId={appClientId}
                                setAppClientId={(e: any) => dispatch(setRegistryApplicationClientId(e.target.value))}
                            />
                        )}

                        {shouldUseRoleARN(registryType, scanningEnvironmentType, authMethod) && (
                            <RoleArnInput
                                roleARN={roleARN}
                                setRoleARN={(e: any) => dispatch(setRegistryRoleARN(e.target.value))}
                            />
                        )}
                    </Stack>

                    <ConfigPullSecretNameWrapper
                        isVisible={isVisible}
                        spacing={2}
                        padding={stackDirection === 'row' ? [0, 0, 0, 5] : [4, 0, 0, 0]}>
                        <Typography variant='bodyLg'>{t('ON_BOARDING.pullSecretNameConfig.title')}</Typography>
                        <Typography>{t('ON_BOARDING.pullSecretNameConfig.text')}</Typography>
                        {(isVisible && kubectlCommand) && <KubectlCommand command={kubectlCommand} />}
                    </ConfigPullSecretNameWrapper>
                </Stack>

                <EnableImageAssurancePopup
                    open={isImageAssuranceEnablePopupOpen}
                    close={() => closeIAEnableModal()}
                    enableImageAssuranceForCluster={() => enableImageAssuranceForCluster()}       
                />
            </div>
        </Stack>
    );
};

export default RegistryConfigurations;