import { ShiftLeftAccount } from 'modules/workloads/services/shiftLeft/shiftLeftAccount.interface';
import Images from './tabs/Images';
import { ReactTabContent } from './ShiftLeftEnvironment.styled';
export interface ShiftLeftTab {
    name: string;
    labelT: string;
    isReactTab: boolean;
    content?: React.ReactElement;
    propertyInAccount?: keyof ShiftLeftAccount;
    guardByFeatureFlag?: string;
}

export const shiftLeftTabsMetadata: (id: string) => Array<ShiftLeftTab> = (id) => [
    { 
        name: 'protected assets',
        labelT: 'shiftLeftPage.tabs.protectedAssets', 
        isReactTab: true,
        content: (
            <ReactTabContent>
                <Images id={id} />
            </ReactTabContent>
        )
    },
    { 
        name: 'compliance policies',
        labelT: 'shiftLeftPage.tabs.compliancePolicies', 
        isReactTab: false,
    },
    { 
        name: 'posture finding events',
        labelT: 'shiftLeftPage.tabs.postureFindingEvents', 
        isReactTab: false,
    },
    { 
        name: 'assessment history',
        labelT: 'shiftLeftPage.tabs.assessmentHistory', 
        isReactTab: false,
    },
];