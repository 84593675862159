import { IReactPageRoute } from 'common/interface/routing';
import { REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import DynamicProtectedAssetsTable from 'common/components/ProtectedAssets/DynamicProtectedAssetsTable/DynamicProtectedAssetsTable';
import { assetsTableBaseColumns, imagesTableBaseColumns, mainPageTableBaseColumns, sortTableColumns } from './ProtectedAssetsList/types/columns';
import { assetsTableBaseFilters, imagesTableBaseFilters, mainPageTableBaseFilters } from './ProtectedAssetsList/types/filters';
import { DynamicProtectedAssetsTableProps } from 'common/components/ProtectedAssets/DynamicProtectedAssetsTable/DynamicProtectedAssetsTable.types';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';

function initializeRoutes() {
    const protectedAssetsPageUrl = '/workload/workloads';
    const protectedAssetsPageProps: DynamicProtectedAssetsTableProps = {
        tableId: 'workloads-protected-assets-page-filter-panel-id',
        columns: sortTableColumns([
            ...mainPageTableBaseColumns,
            ...assetsTableBaseColumns,
        ]),
        includedEntityTypes: [
            'EcsCluster',
            'EcsService',
            'EcsTask',
            'KubernetesPod',
            'KubernetesJob',
            'KubernetesCronJob',
            'KubernetesStatefulSet',
            'KubernetesDaemonSet',
            'KubernetesDeployment',
            'KubernetesReplicaSet'
        ],
        withTabsPanel: {
            pageId: 'workloadsProtectedAssets',
            closeAllUrl: protectedAssetsPageUrl
        },
        withFilterPanel: {
            filters: [
                ...assetsTableBaseFilters,
                ...mainPageTableBaseFilters
            ]
        },
        tablePadding: 5,
    };
    const protectedAssetsPageRoute = (): IReactPageRoute => {
        return {
            condition: true,
            component: DynamicProtectedAssetsTable,
            componentProps: protectedAssetsPageProps,
            path: [protectedAssetsPageUrl],
            exact: true,
        };
    };

    const imagesPageUrl = '/workload/images';
    const imagesPageProps: DynamicProtectedAssetsTableProps = {
        tableId: 'workloads-images-page-filter-panel-id',
        columns: sortTableColumns([
            ...mainPageTableBaseColumns,
            ...imagesTableBaseColumns,
        ]),
        includedEntityTypes: [
            'KubernetesImage',
            'ContainerRegistryImage',
            'ShiftLeftImage',
            'EcsImage'
        ],
        withTabsPanel: {
            pageId: 'workloadsImagesProtectedAssets',
            closeAllUrl: imagesPageUrl
        },
        withFilterPanel: {
            filters: [
                ...imagesTableBaseFilters,
                ...mainPageTableBaseFilters
            ]
        },
        tablePadding: 5,
    };
    const imagesPageRoute = (): IReactPageRoute => {
        return {
            condition: true,
            component: DynamicProtectedAssetsTable,
            componentProps: imagesPageProps,
            path: [imagesPageUrl],
            exact: true,
        };
    };

    globalAddinContainer.add(REACT_PAGE_ROUTE, [
        { id: 'Workloads Protected Assets Page', content: protectedAssetsPageRoute },
        { id: 'Workloads Images Page', content: imagesPageRoute },
    ]);
}

export function initializeProtectedAssetsPage() {
    initializeRoutes();
}

