import { Button, CopiableLine, Stack, Typography } from 'common/design-system/components-v2';
import React, { useCallback, useMemo } from 'react';
import InstructionStep from 'modules/workloads/components/InstructionStep/InstructionStep';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import createStackImg from '../../../assets/images/update-stack.png';
import { AwpStackUpdateProps } from '../awpUpdate/awpUpdate.types';
import ValidateButton from '../awpUpdate/components/validateButton';
import { AwpUpdateStackWrapper } from '../awpUpdate/awpUpdate.styled';

const AwpUpdateStack: React.FC<AwpStackUpdateProps> = ({ cloudAccountId, url }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));

    const onUpdateCrossAccountClicked = useCallback(() => {
        window.open(url, '_blank');
    }, [url]);

    const instructionSteps = useMemo<Array<{ content: React.ReactElement, placement?: any }>>(() => [
        {
            placement: 'baseline',
            content: (
                <Stack key='step-1' spacing={2}>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Typography>{t('awpUpdateStack.instructions.step1.press')}</Typography>
                        <Button onClick={onUpdateCrossAccountClicked}>{t('awpUpdateStack.instructions.step1.updateCloudFormationStack')}</Button>
                        <Stack direction='row' spacing={1}>
                            <CopiableLine value={url}>
                                <Typography>{t('awpUpdateStack.instructions.step1.orCopyThisLinkToYourBrowser')}</Typography>
                            </CopiableLine>
                        </Stack>
                    </Stack>
                    <img src={createStackImg} alt='' style={{ boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.2)', maxWidth: '460px' }}/>
                </Stack>
            )
        },
        {
            content: <Typography key='step-2'>{t('awpUpdateStack.instructions.step2')}</Typography>
        },
        {
            content: <Typography key='step-3'>{t('awpUpdateStack.instructions.step3')}</Typography>
        },
        {
            content: <Typography key='step-4'>{t('awpUpdateStack.instructions.step4')}</Typography>
        }
    ], [onUpdateCrossAccountClicked, t, url]);

    return (
        <AwpUpdateStackWrapper padding={[5, 4, 4, 8]} spacing={4}>
            <Stack spacing={2}>
                <Typography variant='h2'>{t('awpUpdateStack.header.updateAwpCloudFormationStack')}</Typography>
                <Typography variant='subtitleLg'>{t('awpUpdateStack.header.YouNeedToUpdate')}</Typography>
            </Stack>
            <Stack spacing={2} padding={[0, 0, 0, 3]}>
                {instructionSteps.map(({ content, placement }, index) => 
                    <InstructionStep key={`step-${index + 1}`} stepNumber={index + 1} alignItems={placement}>
                        {content}
                    </InstructionStep>
                )}
            </Stack>
            <ValidateButton cloudAccountId={cloudAccountId} />
        </AwpUpdateStackWrapper>
    );
};

export default AwpUpdateStack;
