import React, { ReactElement, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Command, Icon, Stack, Typography } from 'common/design-system/components-v2';
import { StepWrapper } from './AwsConfiguration.styles';
import { getCurrentDataCenter } from 'common/utils/RuntimeEnvironment';
import { getRegistryAuthMethod, getRegistryType, getRegistryUrl } from '../../../../reducers/registry/registry.slice';
import { useSelector } from 'react-redux';
import { getClusterKey, getClusterSecret, getSelectedCluster } from 'modules/workloads/reducers/cluster/cluster.slice';
import { getCheckpointUrl } from 'common/components/Forms/urls';
import CopiableLine from '../../../../../../common/design-system/components-v2/CopiableLine';
import { ALink } from 'common/components/ALink';
import { RegistryType } from 'modules/workloads/utils/RegistryOptions/RegistryOptions';

const secretValueFormats: { [key in RegistryType]: { [key: string]: string } } = {
    [RegistryType.ACR]: {
        AcrServicePrincipal: '<service-principal-ID>:<service-principal-password>',
    },
    [RegistryType.ECR]: {
        EcrUserAccessKeys: '<AWS_ACCESS_KEY>:<AWS_SECRET_KEY>',
    },
    [RegistryType.GCR]: {
        GcpServiceAccountKey: '_json_key:<json-secret>',
    },
    [RegistryType.GAR]: {},
    [RegistryType.JFrogArtifactory]: {
        ArtifactoryUserPassword: '<JFROG_USERNAME>:<JFROG_PASSWORD>',
        ArtifactoryToken: '<JFROG_USERNAME>:<JFROG_ACCESS_TOKEN>',
    },
    [RegistryType.Harbor]: {
        HarborUserPassword: '<HARBOR_USERNAME>:<HARBOR_PASSWORD>',
    },
    [RegistryType.Nexus]: {
        NexusUserPassword: '<NEXUS_USERNAME>:<NEXUS_PASSWORD>',
    },
    [RegistryType.GitHub]: {
        GithubToken: '<GITHUB_USERNAME>:<GITHUB_PASSWORD>',
    },
};

type InstructionStepProps = {
    stepNumber: number | string,
    children: ReactElement
};

const InstructionStep: React.FC<InstructionStepProps> = ({ stepNumber, children }) => {
    return (
        <Stack direction='row' spacing={2}>
            <Typography>{`${stepNumber}.`}</Typography>
            {children}
        </Stack>
    );
};

const AwsConfigurations: React.FC = () => {
    const { t } = useTranslation('k8s_registry');
    const [isNewEcsCluster, setIsNewEcsCluster] = useState<boolean>(true);
    const dataCenter = getCurrentDataCenter()?.dataCenter;
    const cloudFormationUrl = `https://cloudguard-consec-onboarding.s3.us-west-2.amazonaws.com/consec/latest/templates/${dataCenter}/${isNewEcsCluster ? 'new' : 'existing'}-cluster/consec-ecs-scanner-cluster.yaml`;

    const registryUrl = useSelector(getRegistryUrl);
    const clusterKey = useSelector(getClusterKey);
    const clusterSecret = useSelector(getClusterSecret);
    const selectedScanningEnvironment = useSelector(getSelectedCluster);
    const registryType = useSelector(getRegistryType);
    const authMethod = useSelector(getRegistryAuthMethod);

    const instructions: Array<ReactElement> = [
        (
            <Stack spacing={2} key='step-1'>
                <Typography>
                    {t('ON_BOARDING.awsConfigurationSteps.step1')}
                </Typography>
                <Stack direction='row' spacing={2} >
                    <Button
                        onClick={() => setIsNewEcsCluster(true)}
                        className={isNewEcsCluster ? 'selected' : ''} >
                        <>    
                            <input type='radio' checked={isNewEcsCluster} readOnly />
                            {t('ON_BOARDING.newEcsCluster')}
                        </>
                    </Button>
                    <Button
                        onClick={() => setIsNewEcsCluster(false)}
                        className={!isNewEcsCluster ? 'selected' : ''} >
                        <>    
                            <input type='radio' checked={!isNewEcsCluster} readOnly />
                            {t('ON_BOARDING.existingEcsCluster')}
                        </>
                    </Button>
                </Stack>
            </Stack>
        ),
        (
            <Stack spacing={2} key='step-2'>
                <Typography>{t('ON_BOARDING.awsConfigurationSteps.step2')}</Typography>
                <Command text={cloudFormationUrl} />
            </Stack>
        ),
        (
            <Stack key='step-4'>
                <Typography>
                    <Trans
                        i18nKey={'k8s_registry:ON_BOARDING.awsConfigurationSteps.step4'}
                        components={{
                            aLink: <ALink target={'_blank'} href='https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create'></ALink>
                        }}
                    />
                </Typography>
                <Stack spacing={1} padding={[1, 0, 0, 2]} >
                    <InstructionStep stepNumber={'a'}>
                        <Typography>{t('ON_BOARDING.awsConfigurationSteps.step4_a')}</Typography>
                    </InstructionStep>
                    <InstructionStep stepNumber={'b'}>
                        <Typography>{t('ON_BOARDING.awsConfigurationSteps.step4_b')}</Typography>
                    </InstructionStep>
                    <InstructionStep stepNumber={'c'}>
                        <Typography>{t('ON_BOARDING.awsConfigurationSteps.step4_c')}</Typography>
                    </InstructionStep>
                </Stack>
            </Stack>
        ),
        (
            <Stack key='step-5'>
                <Typography>{t('ON_BOARDING.awsConfigurationSteps.step5')}</Typography>
                <Stack spacing={1} padding={[1, 0, 0, 2]} >
                    {selectedScanningEnvironment && (
                        <CopiableLine value={selectedScanningEnvironment.id}>
                            <Typography><b>Environment ID:</b> {selectedScanningEnvironment?.id || ''}</Typography>
                        </CopiableLine>
                    )}
                    <CopiableLine value={clusterKey.value}>
                        <Typography><b>CloudGuard API Key ID:</b> {clusterKey.value}</Typography>
                    </CopiableLine>
                    <CopiableLine value={clusterSecret.value}>
                        <Typography><b>CloudGuard API Key Secret:</b> {clusterSecret.value}</Typography>
                    </CopiableLine>                            
                </Stack>
            </Stack>
        )
    ];

    const stepThree = (
        <Stack key='step-3'>
            <Typography>
                <Trans
                    i18nKey={'k8s_registry:ON_BOARDING.awsConfigurationSteps.step3'}
                    components={{
                        aLink: <ALink target={'_blank'} href='https://us-east-1.console.aws.amazon.com/secretsmanager/newsecret'></ALink>
                    }}
                />
            </Typography>
            <Stack spacing={1} padding={[1, 0, 0, 2]}>
                <CopiableLine value={registryUrl.value}>
                    <Typography><b>Key:</b> {registryUrl.value}</Typography>
                </CopiableLine>
                <Typography><b>Value:</b> {secretValueFormats[registryType][authMethod]}</Typography>
            </Stack>
        </Stack>
    );


    if (secretValueFormats[registryType][authMethod]) {
        console.log({ registryType, authMethod });
        instructions.splice(2, 0, stepThree);
    }

    return (
        <StepWrapper direction={'column'} spacing={4} padding={[0, 0, 0, 2]}>
            <Stack direction='row' spacing={2} alignItems='center' >
                <Icon name={'aws'} size={32} />
                <Stack direction='row' alignItems='flex-end' spacing={2}>
                    <Typography><b>{t('ON_BOARDING.AwsConfigurations')}</b></Typography>
                    <ALink href={getCheckpointUrl('CONTAINER_REGISTRY_SCANNING')} target='_blank' rel='noreferrer'>
                        {t('ON_BOARDING.seeDocumentation')}
                    </ALink>
                </Stack>
            </Stack>
            <Stack spacing={5} margin={[0, 0, 0, 20]} >
                {instructions.map((instruction, index) => (
                    <InstructionStep stepNumber={index + 1} key={`step-${index + 1}`}>
                        {instruction}
                    </InstructionStep>
                ))}
            </Stack>
        </StepWrapper>
    );
};

export default AwsConfigurations;
