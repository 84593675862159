import React from 'react';
import { Markdown, Stack, Typography } from 'common/design-system/components-v2';
import { EventOverviewTabProps } from 'common/module_interface/events/EventsDrawerRegistry';

const ServerlessSecurityEvent: React.FC<EventOverviewTabProps> = ({ finding: event }) => {
    return (
        <Stack spacing={1}>
            <Markdown
                markdown={event.remediationActions[0]}
                customizeComponents={{
                    h3: (props) => <Typography variant='body500' color='strong'>{props.children?.toString() || ''}</Typography>,
                    strong: (props) => <Typography variant='body500' color='strong'>{props.children?.toString() || ''}</Typography>,
                }}
            />
        </Stack>
        
    );
};

export default ServerlessSecurityEvent;