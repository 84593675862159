import React from 'react';
import { Stack } from 'common/design-system/components-v2';
import HorizontalDivider from 'modules/workloads/components/HorizontalDivider/HorizontalDivider';
import { AwpScanModeSelectorProps } from './scanModeSelector.types';
import { ScanModeCard } from './ScanModeCard';

const ScanModeSelector: React.FC<AwpScanModeSelectorProps> = ({ scanModeSections, selectedScanMode, onSelectScanMode }) => {
    return (
        <Stack spacing={2}>
            {scanModeSections.map(section => {
                return (
                    <Stack key={`scan-mode-section-${section.title}`} direction='column' spacing={2}>
                        <HorizontalDivider text={section.title} />
                        {section.cards.map((card) => {
                            return (
                                <ScanModeCard
                                    key={`scan-mode-card-${card.label}`}
                                    scanMode={card.label}
                                    description={card.description}
                                    onCardSelected={() => onSelectScanMode(card.value)}
                                    selected={card.value === selectedScanMode}
                                    isPreview={card.isPreview}
                                />
                            );
                        })}
                    </Stack>
                );
            })}
        </Stack>
    );
};

export default ScanModeSelector;