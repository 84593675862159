import React from 'react';
import { Chip, Stack, Typography } from 'common/design-system/components-v2';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';
import { toList } from 'modules/workloads/pages/settings/components/Awp/AwpSettings.utils';

export const CustomTagsDetail = ({ customTags } : { customTags?: { [key: string]: string } }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));

    const customTagsAsArray = React.useMemo<Array<{ key: string; value: string }>>(() => {
        return toList(customTags ?? {});
    }, [customTags]);

    return (
        <Stack spacing={1}>
            <Typography color='light'>{t('awpTab.details.customTags')}</Typography>
            <Stack direction='row' spacing={1} flexWrap>
                {customTagsAsArray.length ? customTagsAsArray.map(({ key, value }) => (
                    <Chip key={key} label={`${key}: ${value}`} />
                )) : t('awpTab.details.noCustomTags')}
            </Stack>
        </Stack>
    );
};