import { GetVulnerabilityScan } from './vulnerability.interface';

const getCveAndPackageById = (cveId: string, packageId: string, data?: GetVulnerabilityScan.Response) => {
    if(!data) return undefined;

    const vulPackage = data.Package.find((vulPackage) => vulPackage.id === packageId.replace(' ', '+'));
    if(!vulPackage) return undefined;

    const cve = vulPackage.cves.find((cve) => cve.id === cveId);

    if(!cve) {
        return undefined;
    }

    return {
        package: vulPackage,
        cve
    };
};

export {
    getCveAndPackageById
};