import { Command } from 'common/design-system/components-v2';
import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';

const KubectlWrapper = styled.div<{ isMounted: boolean }>`
    opacity: ${ ({ isMounted }) => isMounted ? 1 : 0 };
    transition: opacity 100ms ease-in;
`;

let debounce: any;

const KubectlCommand: FunctionComponent<{ command: string }> = ({ command }) => {
    const [isMounted, setIsMounted] = useState(true);
    const [commandToDisplay, setCommandToDisplay] = useState(command);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (!initialized) return;
        setIsMounted(false);
        clearTimeout(debounce);
        debounce = setTimeout(() => {
            setCommandToDisplay(command);
            setIsMounted(true);
        }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [command]); // intentionally not listening to 'initialized'

    return (
        <KubectlWrapper isMounted={isMounted} >
            <Command text={commandToDisplay} />
        </KubectlWrapper>
    );
};

export default KubectlCommand;