import React from 'react';
import { EventOverviewTabProps } from 'common/module_interface/events/EventsDrawerRegistry';
import { Link, Stack, Typography } from 'common/design-system/components-v2';
import { createTaskAttribute, getActions, getBundleAttributes } from './ServerlessTaskEvent.utils';
import { TaskAttributeTypes } from './ServerlessTaskEvent.types';

const ServerlessTaskEvent: React.FC<EventOverviewTabProps> = ({ finding: event }) => {
    const attributes = React.useMemo(() => {
        const actions = getActions(event);
        const bundleAttributes = getBundleAttributes(event.bundleName, actions);
        return [...createTaskAttribute('Alert Description', actions.find(action => action?.Info?.length)?.Info || 'N/A'), ...bundleAttributes];
    }, [event]);
    return (
        <Stack spacing={8}>
            {attributes.map(({ title, value, type }) => {
                return (
                    <Stack spacing={4} key={title}>
                        <Typography variant='body500'>{title}</Typography>
                        {type === TaskAttributeTypes.link ? <Link externalUrl={value}>{value}</Link> : <Typography>{value}</Typography>}
                    </Stack>
                );
            })}
        </Stack>
    );
};

export default ServerlessTaskEvent;