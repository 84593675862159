import { useState, useEffect, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select, TextField, Button, Label } from '@dome9/berries/react-components';
import {
    CreateServiceAccountResponse
} from '../../services/service-account/service-account.interface';
import AddServiceAccountModal from './AddServiceAccountModal/AddServiceAccountModal';
import { getCloudGuardRoles } from '../../services/cloudGuard-roles/cloudGuard-roles.service';
import { useTranslation } from 'react-i18next';
import { FlexElement } from './AddServiceAccountModal/AddServiceAccountModal.styled';
import { Stack } from 'common/design-system/components-v2';
import { ISelectOption } from 'common/interface/general';
import { SingleValue } from 'react-select';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import useServiceAccount from '../../hooks/useServiceAccount';
import { CloudGuardRole } from '../../services/cloudGuard-roles/cloudGuard-roles.interface';

const ServiceAccount = () => {
    const { control, setValue, trigger, resetField } = useFormContext();
    const [serviceAccountsOptions, setServiceAccountsOptions] = useState<ISelectOption[]>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<ISelectOption | null>(null);
    const [shiftLeftAgentRoleId, setShiftLeftAgentRoleId] = useState<number | undefined>(undefined);
    const [newServiceAccountDetails, setNewServiceAccountDetails] = useState<Pick<CreateServiceAccountResponse, 'apiKeySecret' | 'apiKeyId'> | null>(null);
    const { t } = useTranslation(['k8s_shiftleft', 'k8s_common', 'k8s_service-account']);
    const { serviceAccounts, createServiceAccount, isLoading, error } = useServiceAccount();

    type GetServiceAccountByRoleName = (cloudGuardRoles: Array<CloudGuardRole>, roleName: string) => void
    const getServiceAccountByRoleName: GetServiceAccountByRoleName = useCallback((cloudGuardRoles, roleName) => {
        const shiftLeftAgentRoleId = cloudGuardRoles.find(role => role.name === roleName);
        if(shiftLeftAgentRoleId?.id) {
            setShiftLeftAgentRoleId(shiftLeftAgentRoleId.id);
        }
    }, []);
    
    useEffect(() => {
        (async () => {
            const cloudGuardRoles = await getCloudGuardRoles();
            getServiceAccountByRoleName(cloudGuardRoles, 'ShiftLeft');
            setServiceAccountsOptions(
                () => [{ label: 'Manual', value: 'manual' }, ...serviceAccounts.map(item => ({ label: item.name, value: item.apiKeyId }))]
            );
        })();
    }, [getServiceAccountByRoleName, serviceAccounts]);

    useEffect(() => {
        if(error.isError){
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: `${error.errorMessage}`,
                text: '',
            });
        }
    }, [error]);


    const onClickAddServiceAccount = async (name: string) => {
        setModalOpen(true);
        try {
            const { data } = await createServiceAccount({ name, roleIds: shiftLeftAgentRoleId ? [shiftLeftAgentRoleId] : [] });
            setNewServiceAccountDetails(data);
            setSelectedValue({ label: data.name, value: data.apiKeyId });
            setValue('apiKey', data.apiKeyId);
            setValue('apiSecret', data.apiKeySecret);
        } catch (error: any) {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: `${error.response.data}`,
                text: '',
            });
        }
    };

    const onClose = () => {
        setModalOpen(false);
        setNewServiceAccountDetails(null);
    };

    const onOpen = () => setModalOpen(true);

    const onChangeServiceAccount = (option: SingleValue<ISelectOption>) => {
        const { value } = option || {};
        if(value === 'manual') {
            setSelectedValue(null);
            resetField('apiKey');
            resetField('apiSecret');
        } else {
            setSelectedValue(option);
            setValue('apiKey', value, { shouldValidate: true });
            setValue('apiSecret', '');
        }
    };

    return (
        <div>
            <AddServiceAccountModal
                onClose={onClose}
                modalOpen={modalOpen}
                newServiceAccountDetails={newServiceAccountDetails}
                onConfirm={onClickAddServiceAccount}
            />
            <Stack spacing={4}>
                <Stack spacing={5}>
                    <Label label={`${t('k8s_service-account:label')}:`} />
                    <Stack padding={[0, 0, 0, 4]} direction={'column'} >
                        <Stack alignItems={'center'} spacing={2} direction={'row'}>
                            <Label label={`${t('k8s_service-account:selectLabel')}:`}/>
                            <FlexElement>
                                <Select
                                    closeMenuOnSelect={true}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
                                    required={true}
                                    value={selectedValue || { value: 'manual', label: 'Manual' }}
                                    onChange={onChangeServiceAccount}
                                    isLoading={isLoading}
                                    options={serviceAccountsOptions}
                                />
                            </FlexElement>
                            <Button
                                color='primary'
                                onClick={(e) => {
                                    e.preventDefault();
                                    onOpen();
                                }}
                            >
                                {t('k8s_service-account:buttonAdd')}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack padding={[0, 0, 0, 4]} direction={'row'} spacing={2}>
                    <FlexElement>
                        <Controller
                            control={control}
                            rules={{ required: 'API Key  can\'t be empty' }}
                            name='apiKey'
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    required
                                    state={error && 'error'}
                                    label={t('k8s_service-account:fields.apiKey')}
                                    onClear={async () => {
                                        setValue('apiKey', '');
                                        await trigger();
                                    }}
                                    helper={error?.message}
                                    {...field}
                                />
                            )}
                        />
                    </FlexElement>
                </Stack>

                <Stack padding={[0, 0, 0, 4]} direction={'row'} spacing={2}>
                    <FlexElement>
                        <Controller
                            control={control}
                            rules={{ required: 'API Secret can\'t be empty' }}
                            name='apiSecret'
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    required
                                    state={error && 'error'}
                                    onClear={async () => {
                                        setValue('apiSecret', '');
                                        await trigger();
                                    }}
                                    label={t('k8s_service-account:fields.apiSecret')}
                                    helper={error?.message}
                                    {...field}
                                />
                            )}
                        />
                    </FlexElement>
                </Stack>
            </Stack>
        </div>
    );
};

export default ServiceAccount;