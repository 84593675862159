import { Addin } from 'common/extensibility/AddinRegistry';
import { AzureEnvironmentAddinRegistry, AzureEnvironmentAddingBase } from 'common/helpers/azureEnvironment';
import { AwsEnvironmentTab } from 'common/module_interface/assets/AwsEnvironment';
import AwpTab from './aws/tabs/awp/AwpTab';
import { Vendors } from 'common/consts/vendors';

export const initialAzureEnvironmentTabs = () => {
    const tabs: Addin<AzureEnvironmentAddingBase<AwsEnvironmentTab>>[] = [
        {
            id: 'awp',
            position: 91,
            content: {
                id: 'awp',
                getValue: (awsData) => ({
                    name: 'awp',
                    label: 'AWP', 
                    isReactTab: true,
                    content: <AwpTab cloudAccountId={awsData.account.id} platform={Vendors.AZURE} />
                }),
            }
        }
    ];
    AzureEnvironmentAddinRegistry.addTabs(tabs);
};
