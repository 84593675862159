import React from 'react';
import { LoadingWrapper, LoadingDescriptionLabel, LoadingStatusLabel } from './ClusterLoading.styles';
import { KOBMainLogicHookStatus } from '../../hooks/KOB-MainLogic/KOB-MainLogic.types';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import { useTranslation } from 'react-i18next';

interface ClusterLoadingProps {
    status: KOBMainLogicHookStatus;
}

const ClusterLoading: React.FunctionComponent<ClusterLoadingProps> = props => {
    const { t } = useTranslation('k8s_cluster');
    const { status } = props;

    const loadingText = React.useMemo(() => {
        switch (status) {
            case KOBMainLogicHookStatus.GateringData:
                return 'GateringData';
            case KOBMainLogicHookStatus.GeneratingClusterName:
                return 'GeneratingClusterName';
            case KOBMainLogicHookStatus.CreatingCluster:
                return 'CreatingCluster';
            case KOBMainLogicHookStatus.UpdatingFeatures:
                return 'UpdatingFeatures';
            case KOBMainLogicHookStatus.GeneratingServiceAccountName:
                return 'GeneratingServiceAccountName';
            case KOBMainLogicHookStatus.CreatingServiceAccount:
                return 'CreatingServiceAccount';
            default:
                return '';
        }
    }, [status]);

    return (
        <LoadingWrapper>
            <div>
                <CloudAnimationLoader size='medium' />
            </div>
            <LoadingDescriptionLabel>{t('ON_BOARDING.PAGE.TITLE')}</LoadingDescriptionLabel>
            <LoadingStatusLabel>{t(`ON_BOARDING.PAGE.LOADING_STATUSES.${loadingText}`)}</LoadingStatusLabel>
        </LoadingWrapper>
    );
};

export default ClusterLoading;
