import WelcomePageTemplate from 'common/components/WelcomePage/WelcomePage';
import React from 'react';
import backgroundImage from 'assets/images/welcomePage/workload.svg';
import { useTranslation } from 'react-i18next';
import { workLoadWelcomePageSections } from './WelcomePage.scheme';

const WelcomePage: React.FunctionComponent = () => {
    const { t } = useTranslation(['k8s_welcome-page', 'k8s_common']);

    return (
        <WelcomePageTemplate
            title={t('TITLE')}
            subtitle={t('SUBTITLE')}
            backgroundImage={backgroundImage}
            sections={workLoadWelcomePageSections(t)}
        />
    );
};

export default WelcomePage;
