import { Stack, Typography } from 'common/design-system/components-v2';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { Trans, useTranslation } from 'react-i18next';
import { ALink } from 'common/components/ALink';
import { AwpUpdateStackWrapper } from '../awpUpdate.styled';

const AwpUpdateTerraform = ({ version }: { version: string }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));

    return (
        <AwpUpdateStackWrapper padding={[5, 4, 4, 8]} spacing={4}>
            <Stack spacing={2}>
                <Typography variant='h2'>{t('update.terraform.title')}</Typography>
                <Typography variant='subtitleLg'>{t('update.terraform.description', { version })}</Typography>
            </Stack>
            <Stack spacing={2}>
                <Trans
                    i18nKey={'update.terraform.instructions.step1'}
                    t={t}
                    components={{
                        tfdoc: <ALink>.</ALink>,
                        p: <p />,
                    }}
                />
            </Stack>
        </AwpUpdateStackWrapper>
    );
};

export default AwpUpdateTerraform;