import { KubernetesEnvironment } from '../cluster/cluster.interface';
import { FormFieldModel } from 'common/interface/formFieldModel';
import { RegistryAuthMethod, RegistryType, ScanningEnvironmentType } from 'modules/workloads/utils/RegistryOptions/RegistryOptions';

export interface RegistryState {
    step: number;
    isLoading: boolean;
    selectedRegistry: Registry | {};
    registryAgentSummary: Array<RegistryAgentSummary> | [];
    registryAccountSummary: RegistryAccountSummary | {};
    serviceAccounts: Array<any>;
    registryForm: RegistryForm;
    newRegistry: any;
    isImageAssuranceEnablePopupOpen: boolean;
    isImageAssuranceEnabledWhileOnboarding: boolean;
    imageScanAgentVersion: any;
    allRegistries: Array<Registry>;
}

export interface Registry {
    id: string;
    creationDate: string;
    vendor: string;
    organizationalUnitPath: string;
    organizationalUnitName: string;
    name: string;
    linkedKubernetesEnvironments: Array<KubernetesEnvironment>;
    linkedKubernetesEnvironmentsIds?: Array<string>;
    registryUrl: string;
    registryType: string;
    organizationalUnitId: null;
}

export interface RegistryAccountSummary {
    id: string;
    name: string;
    agentsStatus: string;
    agentsStatusDescription: string;
    registryType: string;
    registryUrl: string;
    numberOfImages: string;
    linkedAgentsStatuses: {
        statusOk: number;
        statusError: number;
        statusPending: number;
    };
}

export interface RegistryAgentSummary {
    version: string;
    isAgentUpToDate: boolean;
    lastCommunication: string;
    status: string;
    description: string;
    creationTime: string;
    kubernetesAccountId: string;
    kubernetesAccountName: string;
    authMethod?: KubernetesEnvironment;
}

export interface RegistryForm {
    name: FormFieldModel;
    description?: string;
    organizationalUnitId: string;
    scanningEnvironmentType: ScanningEnvironmentType;
    registryType: RegistryType;
    registryUrl: FormFieldModel;
    authMethod: RegistryAuthMethod;
    applicationClientId: FormFieldModel;
    pullSecretName: FormFieldModel;
    tenantId: FormFieldModel;
    roleARN: FormFieldModel;
}

export enum AgentsStatus {
    Ok = 'OK',
    Pending = 'PENDING',
    Warning = 'WARNING',
}
