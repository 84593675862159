import { Command, Link } from 'common/design-system/components-v2';
import styled from 'styled-components';

export const UrlLink = styled(Link)`
    wrap: break-all;
`;

export const SnippetCommand = styled(Command)`
    width: 100%;
    min-height: 102px;
    word-break: break-all;
`;