import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => `${theme.spacing(1)}${theme.units}`};
    & span {
        flex: 0.2;
    }
    & span + * {
        flex: 0.8;
    }
`;
