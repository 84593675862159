import { AssetTypes } from 'common/assets/assets.const';

const assets = new Set([
    AssetTypes.AWS_INSTANCE,
    AssetTypes.AZURE_VIRTUALMACHINE,
    AssetTypes.AZURE_FUNCTIONAPP
]);

export const isAwpAsset = (assetType: string) => {
    return assets.has(assetType);
};