import React, { useEffect, useState } from 'react';
import { extractFilterFieldsToNewModel, initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { FILTER_PANEL_QUERY_NAMES, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import FilterPanelPlaceholder from 'common/components/ProtectedAssets/DynamicProtectedAssetsTable/components/FilterPanelPlaceholder';
import { filterDefs } from '../Definitions/FilterDefinitions';
import CveService from 'modules/workloads/services/cve/cve.service';
import { getHttpService, ICustomzationResponse } from 'common/interface/services';

interface IWebAppFilterParams {
    filter?: {
        fields?: IWebAppFilter[];
        freeTextPhrase?: string;
        tags?: IWebAppKeyValue[]
    };
}

interface IWebAppFilter {
    name: string,
    value: string
}

interface IWebAppKeyValue {
    key: string,
    value: string
}

export interface Filters {
    fields?: { value: string; name: string }[];
    freeTextPhrase?: string;
}

export interface EnvironmentsFilterPanelProps {
    onFilterChange: (filters: Filters) => void;
    cveId: string;
}

const CveSearchFilterPanel: React.FC<EnvironmentsFilterPanelProps> = props => {
    const { onFilterChange, cveId } = props;

    const [filterElement, setFilterElement] = useState(<div></div>);
    const [isLoading, setIsLoading] = useState(true);

    const savedFiltersComponentName = 'cve_search_index_favs';
    const recentlyUsedComponentName = 'cve_search_index_recent';

    const FILTER_PANEL_ID = 'cve-search-filter-panel-id';

    useEffect(() => {

        async function getAggregations(filtersValues?: Filters): Promise<Aggregations> {
            if (filtersValues) {
                onFilterChange(filtersValues || {});
            }

            interface Aggregations {
                [key: string]: { value: any; count: number }[];
            }

            const aggregations: Aggregations = await CveService.getFilterAggregations(cveId);
            aggregations['scan.producer'] = aggregations['scan.producer']?.reduce<{ value: string; count: number; }[]>((acc, scan_source: { value: string, count: number }) => {
                if (scan_source.value === 'Inspector') {
                    acc[0].count += scan_source.count;
                } else {
                    acc[1].count += scan_source.count;
                }
                return acc;
            }, [{ value: 'Inspector', count: 0 }, { value: 'cloudGuard', count: 0 }]).filter((scan_source) => scan_source.count > 0);

            return aggregations;
        }

        const extractWebAppFiltersFromQueryParams = () => {
            const queryParams = new URLSearchParams(window.location.search);
            const webAppQueryParams = queryParams.get(FILTER_PANEL_QUERY_NAMES.QUERY);

            if (webAppQueryParams) {
                const urlFilters: IWebAppFilterParams = JSON.parse(webAppQueryParams);
                const filter = urlFilters?.filter;
                const fields = filter?.fields ? extractFilterFieldsToNewModel(filter.fields) : {};
                const freeTextPhrase = filter?.freeTextPhrase ? { [FILTERS_KEYS.FREE_TEXT]: filter?.freeTextPhrase } : {};
                const tags = filter?.tags ? { [FILTERS_KEYS.TAGS]: filter.tags } : [];

                return { ...fields, ...freeTextPhrase, ...tags };
            }
        };

        async function renderFilterPanel() {
            const filtersInitialData = await getFiltersInitialData();
            const webAppQueryParams = extractWebAppFiltersFromQueryParams();
            const aggregations = await getAggregations();
            const filterPanelElementsList = filterDefs({
                aggregations,
                filtersInitialData,
                savedFiltersComponentName,
                recentlyUsedComponentName
            }).map((filterDef) => filterDef());
            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations: getAggregations,
                shouldBuildObjectForAPI: true,
                webAppQueryParams: webAppQueryParams,
                filterId: FILTER_PANEL_ID,
            });
            setFilterElement(filterPanel);
        }

        async function initPage() {
            await renderFilterPanel();
            setIsLoading(false);
        }

        initPage();
    }, [cveId, onFilterChange]);

    const getFiltersInitialData = async () => {
        const savedFiltersPromise = getHttpService().request<ICustomzationResponse<any>>(`customization?component=${savedFiltersComponentName}`,
            { method: 'GET', }, { cachingConfig: { useCache: true } }
        );
        const recentlyUsedPromise = getHttpService().request<ICustomzationResponse<any>>(`customization?component=${recentlyUsedComponentName}`,
            { method: 'GET', }, { cachingConfig: { useCache: true } }
        );
        return Promise.all([savedFiltersPromise, recentlyUsedPromise]).then(
            ([savedFilters, recentlyUsed]) => {
                return {
                    savedFilters,
                    recentlyUsed,
                };
            },
        );
    };

    if (isLoading) {
        return <FilterPanelPlaceholder />;
    }

    return filterElement;
};

export default CveSearchFilterPanel;

