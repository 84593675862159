import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Textarea } from './ModalFeedback.styled';
import { Message } from 'common/design-system/components-v2';

interface ModalFeedbackProps {
    isOpen: boolean;
    onClose: (isOpen: boolean) => void;
    onConfirm: (value: string) => void;
}

const ModalFeedback: React.FC<ModalFeedbackProps> = ({ isOpen, onClose, onConfirm }) => {
    const { t } = useTranslation();
    const [textareaValue, setTextareaValue] = useState<string>('');

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => setTextareaValue(event.target.value);
    
    React.useEffect(() => {
        setTextareaValue('');
        return () => {
            setTextareaValue('');
        };
    }, [isOpen]);

    return (
        <Message
            title='Provide Feedback'
            text={t('K8S.DASHBOARD.FEEDBACK.FEEDBACK_MODAL_TITLE')}
            isOpen={isOpen}
            onClose={() => onClose(false)}
            onCancel={() => onClose(false)}
            onConfirm={() => onConfirm(textareaValue)}
            submitBtnText={t('K8S.DASHBOARD.FEEDBACK.SEND_BUTTON')}
            cancelBtnText={t('K8S.DASHBOARD.FEEDBACK.CANCEL_BUTTON')}
            width='md'
        >
            <Textarea
                onChange={handleOnChange}
                value={textareaValue}
                maxLength={400}
                autoFocus
            />
        </Message>
    );
};

export default ModalFeedback;