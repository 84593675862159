import styled from 'styled-components';
import WinIcon from './icons/WinIcon';
import MacIcon from './icons/MacIcon';
import LinuxIcon from './icons/LinuxIcon';

export const ErrorMessageStyled = styled.span`
  color: #bf1650;
  &::before {
    display: inline;
    content: "⚠ ";
  }
`;

export const IconWrapperMac = styled(MacIcon)`
    width: 20px;
    height: 20px;
`;

export const IconWrapperWindows = styled(WinIcon)`
    width: 20px;
    height: 20px;
`;

export const IconWrapperLinux = styled(LinuxIcon)`
    width: 20px;
    height: 20px;
`;
