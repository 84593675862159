import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Vendors } from 'common/consts/vendors';
import { IVendorRuleset } from 'common/interface/policy';
import { getHttpService } from 'common/interface/services';
import { IFRAME_TAGS } from 'common/interface/IFrame.message.model';
import { ImageAdmissionItem } from '../../helpers/interface';
import { saveAdmissionPolicyChanges } from '../../helpers/utils';
import { finishFlow, getPolicyRulesets } from 'common/components/policy/utils';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import Wizard, { RenderViewParams, WizardStep } from 'common/components/Wizard/Wizard';
import { OUSelectionComponent } from 'common/components/policy/OUSelectionComponent';
import { RulesetMultiSelectionComponent } from 'common/components/policy/RulesetMultiSelectionComponent';
import { ActionSelectionComponent } from '../../components/ActionSelectionComponent/ActionSelectionComponent';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { Done } from 'common/components/policy/Done/Done';
import { useReturnUrl } from 'common/hooks/useReturnUrl';

export const AddAdmissionOUPolicyPage: React.FC = () => {
    const { t } = useTranslation('k8s_policy');
    const [selectedOUs,setSelectedOUs] = useState<ListItem[]>();
    const [selectedRulesets,setSelectedRulesets] = useState<ListItem[]>();
    const [selectedNotifications,setSelectedNotifications] = useState<ListItem[]>();
    const [selectedAction, setSelectedAction] = useState<ImageAdmissionItem>();
    const [flowStatusMessage,setFlowStatusMessage] = useState<string>();
    const returnUrl = useReturnUrl();
    const selectedOUsChanged = (selectedItems: ListItem[]) => {
        selectedItems && setSelectedOUs(selectedItems);
        setSelectedRulesets([]);
        setSelectedNotifications([]);
    };

    const saveChanges = useCallback(async () => {
        try {
            if(selectedNotifications?.length && selectedOUs?.length && selectedRulesets?.length && selectedAction) {
                await saveAdmissionPolicyChanges(selectedNotifications, selectedOUs, selectedRulesets, selectedAction);
                setFlowStatusMessage(t('GENERAL.POLICY_ADDED_MESSAGE'));
                getHttpService().clearCacheByTag(IFRAME_TAGS.CLEAR_CACHE.POLICIES);
            }
        }catch (error) {
            setFlowStatusMessage(t('GENERAL.ERROR_OCCURRED'));
        }
    },[selectedNotifications, selectedOUs, selectedRulesets, selectedAction, t]);

    const rulesetProvider:IVendorRuleset[] = useMemo(() => ([
        {
            getRulesets: async () => {
                const allRulesets = await getPolicyRulesets();
                return allRulesets.filter((ruleset) => (ruleset.platform === Vendors.KUBERNETES_RUNTIME_ASSURANCE));
            },
            vendorId: Vendors.KUBERNETES_RUNTIME_ASSURANCE
        }
    ]),[]);

    const steps:WizardStep[] = useMemo(() => [
        {
            name: t('GENERAL.PLATFORM_SELECTION'),
            renderView: (onValidate:RenderViewParams) => {
                return <OUSelectionComponent preSelectedItems={selectedOUs} selectedOUsChanged={selectedOUsChanged} {...onValidate } />;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            name: t('GENERAL.RULESET_SELECTION'),
            renderView: (onValidate:RenderViewParams) => {
                return <RulesetMultiSelectionComponent vendorRulesets={rulesetProvider} preSelectedItems={selectedRulesets} {...onValidate } selectedRulesetsChanged={setSelectedRulesets}/>;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            name: t('GENERAL.ACTION_SELECTION'),
            renderView: (onValidate:RenderViewParams) => {
                return <ActionSelectionComponent {...onValidate} preSelectedAction={selectedAction} selectedActionChanged={setSelectedAction} />;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            name: t('GENERAL.NOTIFICATION_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <NotificationsSelectionComponent preSelectedItems={selectedNotifications} {...renderViewParams } selectedNotificationsChanged={setSelectedNotifications}/>;
            },
            onNext: async ()=>{
                saveChanges();
            },
        },
        {
            name: t('GENERAL.DONE'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <Done message={flowStatusMessage} {...renderViewParams}></Done>;
            },
            onNext: async ()=>{
                finishFlow(returnUrl);
            },
        }
    ],[t, selectedOUs, rulesetProvider, selectedRulesets, selectedAction, selectedNotifications, saveChanges, flowStatusMessage, returnUrl]);

    return (
        <Wizard
            onClose={()=>finishFlow(returnUrl)}
            steps={steps}
            title={t('GENERAL.ADD_POLICY')}
            doneButtonText={t('GENERAL.DONE')}
            ShowBackButtonInLastStep={false}
        />
    );
};
