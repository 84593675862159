import { SubmitHandler, FieldValues } from 'react-hook-form';

export interface OperatingSystemProps {
    onNext: SubmitHandler<FieldValues>;
    onBack?: () => void;
}

export enum OperatingSystemsEnum {
    Windows = 'Windows',
    Linux = 'Linux',
    Mac = 'Mac'
}