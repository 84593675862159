import { Icon } from '@dome9/berries/react-components';
import styled, { css } from 'styled-components';
import { Stack } from 'common/design-system/components-v2';
import { BladeStatus } from '../../Blades.types';

const ReadMore = styled.a`
  && {
    color: ${({ theme }) => theme.palette.status.info};
    padding: 0;
    min-height: unset;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Row_topleft = styled(Stack)<{ openAble: boolean; active: boolean; }>`
    flex: 1;

    ${p => p.openAble && css`
        cursor: pointer;
    `}
`;

const ChevronToggle = styled(Icon)<{ isOpen: boolean; isActive: boolean; }>`
    transition: transform 0.2s linear;
    opacity: ${p => p.isActive ? 1 : 0.4};

    ${p => p.isOpen && css`
        transform: rotate(90deg);
    `}
`;

const StatusIcon = styled(Icon)<{ status: BladeStatus; }>`
    ${p => p.status === BladeStatus.ERROR && css`
        & * {
            color: ${({ theme }) => theme.palette.status.error};
            fill: ${({ theme }) => theme.palette.status.error};
        }
    `}
    ${p => p.status === BladeStatus.WARNING && css`
        & * {
            color: ${({ theme }) => theme.palette.status.warning};
            fill: ${({ theme }) => theme.palette.status.warning};
        }
    `}
    ${p => p.status === BladeStatus.GOOD && css`
        & * {
            color: ${({ theme }) => theme.palette.status.success};
            fill: ${({ theme }) => theme.palette.status.success};
        }
    `}
`;

const InactiveIcon = styled.div`
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #000000;
    opacity: 0.4;
`;

const BladeHeaderStyles = {
    Row_topleft,
    ChevronToggle,
    StatusIcon,
    InactiveIcon,
    ReadMore
};

export default BladeHeaderStyles;