
const MacIcon = () => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="20px" height="20px" viewBox="0 0 924.000000 1038.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,1038.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M6436 10194 c-379 -59 -835 -286 -1144 -569 -198 -183 -424 -506
-535 -766 -105 -246 -137 -400 -144 -695 l-5 -221 70 -6 c171 -16 450 30 637
107 582 237 1101 891 1240 1563 31 149 48 370 40 501 l-7 102 -32 -1 c-17 -1
-71 -7 -120 -15z"/>
            <path d="M6295 7834 c-287 -32 -506 -91 -960 -257 -143 -52 -325 -116 -405
-143 -247 -81 -327 -71 -780 96 -107 40 -238 88 -290 107 -146 54 -341 111
-480 140 -111 24 -147 27 -320 27 -422 0 -787 -104 -1164 -332 -182 -111 -298
-202 -456 -361 -211 -212 -345 -398 -489 -681 -437 -859 -456 -2040 -54 -3275
237 -727 506 -1240 993 -1890 373 -498 698 -772 1035 -872 80 -24 106 -27 245
-27 134 0 171 4 273 28 144 33 286 80 477 156 301 120 527 179 765 200 294 27
649 -44 1010 -200 175 -75 448 -151 617 -171 452 -52 861 171 1289 706 453
566 763 1113 988 1744 29 79 50 145 49 146 -2 1 -57 30 -124 64 -336 170 -650
451 -857 766 -126 193 -237 459 -286 688 -47 218 -65 545 -41 762 26 238 97
489 194 687 170 348 393 587 840 901 19 13 16 18 -54 113 -90 120 -276 308
-385 391 -331 249 -679 390 -1145 464 -121 19 -401 32 -485 23z"/>
        </g>
    </svg>
);

export default MacIcon;
