import React, { useEffect } from 'react';
import Vulnerabilities from '../components/Vulnerabilities/Vulnerabilities';
import { SecurityData } from '../components/Vulnerabilities/Vulnerabilities.types';
import useVulnerabilityScanMetadata from 'modules/workloads/services/vulnerability/hooks/useVulnerabilityScanMetadata';
import { changeTab } from 'common/module_interface/assets/utils';

const severityWhiteList = ['critical', 'high', 'medium', 'low'];

const vulTypeWhiteList: Record<string, { label: string }> = {
    'cve': {
        label: 'CVEs',
    },
    'secret': {
        label: 'Secrets (EA)',
    },
    'threat': {
        label: 'Threats',
    }
};

const hasSeverityInList = (severityType: string) => severityWhiteList.includes(severityType);

export const buildVulnerabilityObject = (originalObject: any) => {
    const resultObject: any = {};
    for (const key in originalObject) {
        if (!vulTypeWhiteList[key]) {
            continue;
        }

        if (!key.endsWith('Fixable')) {
            const severityType = key.replace('Fixable', '');

            if (!resultObject[severityType]) {
                resultObject[severityType] = {
                    type: key,
                    title: vulTypeWhiteList[key]?.label || '',
                    fixable: 0,
                    vulnerability: []
                };
            }

            if (severityType === 'cve') {
                resultObject[severityType].fixable = originalObject['cveFixable'].all;
            } else {
                resultObject[severityType].fixable = originalObject[key].all;
            }

            for (const severity in originalObject[key]) {
                // Exclude "unknown" and "informational" from the vulnerability object
                if (hasSeverityInList(severity)) {
                    resultObject[severityType].vulnerability.push({
                        severity: severity,
                        count: originalObject[severityType][severity]
                    });
                }
            }
        }
    }
    // TODO: need to remove when API will return the correct data
    return filterOnlyLowSeverity(resultObject);
};

const filterOnlyLowSeverity = (obj: any) => {
    for (const key in obj) {
        if(key === 'threat' || key === 'secret') {
            obj[key].vulnerability = obj[key].vulnerability.filter((item: any) => item.severity === 'low');
        }
    }
    return obj;
};

interface ContainersVulnerabilitiesProps {
    isEmptyState?: boolean;
    entityId: string,
    entityType: string,
    environmentId: string;
}

const ContainersVulnerabilities: React.FC<ContainersVulnerabilitiesProps> = (props) => {
    const [vulnerability, setVulnerability] = React.useState<SecurityData | null>(null);
    const { isLoading, data } = useVulnerabilityScanMetadata(props);

    useEffect(() => {
        if (data) {
            setVulnerability(buildVulnerabilityObject(data.vulnerabilityStats));
        }
    }, [data]);

    return (
        <Vulnerabilities
            isLoading={isLoading}
            isEmptyState={props.isEmptyState}
            onClickGoVulnerability={() => changeTab('vulnerabilities')}
            VulnerabilityObject={vulnerability}
        />
    );
};

export default ContainersVulnerabilities;