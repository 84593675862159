import { AxiosResponse } from 'axios';
import { ICloudAccount, getCloudAccountsService } from 'common/interface/data_services';
import { GetWorkloadAgentessAccount } from 'modules/workloads/services/awp/awp.interface';
import AwpService from 'modules/workloads/services/awp/awp.service';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Vendors } from 'common/consts/vendors';
import { AwpUpdateState, AwpUpdateStateType } from '../awpUpdate.types';

const getEnvUpdate = async (cloudAccountId: string, isTerraform: boolean): Promise<AwpUpdateState> => {
    const result = await AwpService.checkForUpdate(cloudAccountId);
    if (result.data.shouldUpdate && isTerraform) {
        return {
            type: AwpUpdateStateType.TERRAFORM_UPDATE,
            data: {
                version: result.data.version,
                cloudAccountId,
            },
        };
    }
    if (result.data.shouldUpdate) {
        return {
            type: AwpUpdateStateType.CLOUDFORMATION_STACK_UPDATE,
            data: {
                url: result.data.updateStackCloudFormationTemplateURL,
                cloudAccountId,
            },
        };
    }
    return { type: AwpUpdateStateType.NONE };
};

const fallbackToOldUpdate = async (cloudAccountId: string): Promise<AwpUpdateState> => {
    try {
        const res = await AwpService.getAgentlessAwsOnboardingData();
        return {
            type: AwpUpdateStateType.CLOUDFORMATION_STACK_UPDATE_OLD,
            data: {
                url: res.data.crossAccountTemplateUrl,
                cloudAccountId,
            }
        };
    } catch (err: any) {
        return { type: AwpUpdateStateType.ERROR, error: err };
    }
};

const getUpdateData = async (cloudAccountId: string): Promise<AwpUpdateState> => {
    let cloudAccount: ICloudAccount | null = null;
    let agentlessAccountData: AxiosResponse<GetWorkloadAgentessAccount.Response, any> | null = null;
    try {
        cloudAccount = await getCloudAccountsService().getCloudAccountByAccountId(cloudAccountId);
        if (!cloudAccount) {
            return { type: AwpUpdateStateType.ERROR, error: new Error('Cloud account not found') };
        }
        agentlessAccountData = await AwpService.getWorkloadAgentessAccount(cloudAccountId, cloudAccount.platform as Vendors);
        if (!agentlessAccountData) {
            return { type: AwpUpdateStateType.ERROR, error: new Error('Agentless data not found') };
        }
    } catch (err: any) {
        return { type: AwpUpdateStateType.ERROR, error: new Error('Could not read update data') };
    }

    try {
        return await getEnvUpdate(cloudAccountId, agentlessAccountData.data.isTerraform);
    } catch (err: any) {
        // https://dome9-security.atlassian.net/browse/AL-1579
        if (err.statusCode !== 404) {
            return { type: AwpUpdateStateType.ERROR, error: err }; 
        }
        return await fallbackToOldUpdate(cloudAccountId);
    }
};

const useAwpUpdateData = () => {
    const { cloudAccountId } = useParams<{ cloudAccountId: string; }>();
    const [awpState, setAwpState] = useState<AwpUpdateState>({ type: AwpUpdateStateType.LOADING });
    useEffect(() => {
        getUpdateData(cloudAccountId).then((state) => {
            setAwpState(state);
        });
    }, [cloudAccountId]);
    return awpState;
};

export default useAwpUpdateData;