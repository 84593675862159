import { FunctionComponent, useEffect, useState } from 'react';
import {
    CpButton,
    CpTextBox,
    CpCommonButton,
    CpModalHeader,
    CpModalBody,
    CpModalFooter,
    CpModal,
    CpCopyToClipboard,
    CpTooltip,
    CpIcon,
} from '@dome9/components/react/components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getServiceAccountsFromServer } from '../../K8sRegistry.utils';
import { addServiceAccount } from '../../../reducers/serviceAccount/serviceAccount.service';
import { getServiceAccounts } from '../../../reducers/registry/registry.slice';
import {
    getClusterName,
    getClusterKey,
    getClusterSecret,
    getClusterNamespace,
    getSelectedCluster,
    getSelectedServiceAccount,
    getClusterForm
} from '../../../reducers/cluster/cluster.slice';
import {
    setClusterName,
    setClusterKey,
    setClusterSecret,
    setSelectedServiceAccount,
    setClusterNamespace,
    toggleClusterImageAssurance,
    toggleClusterAdmissionControl,
    toggleClusterRuntimeProtection,
    toggleClusterThreatIntelligence,
    toggleClusterRuntimeProfilingProtection,
} from '../../../reducers/cluster';
import { K8sStoreService } from 'modules/workloads/initialize.redux';
import { Button, Checkbox, Input, Label, SelectV2 as Select, Stack } from 'common/design-system/components-v2';

const ClusterConfigurations: FunctionComponent = () => {
    const { t } = useTranslation('k8s_registry');
    const { dispatch } = K8sStoreService().getReduxTools();
    const selectedCluster = useSelector(getSelectedCluster);
    const selectedSreviceAccount = useSelector(getSelectedServiceAccount);
    const serviceAccounts = useSelector(getServiceAccounts);
    const clusterName = useSelector(getClusterName);
    const clusterForm = useSelector(getClusterForm);
    const clusterKey = useSelector(getClusterKey);
    const clusterSecret = useSelector(getClusterSecret);
    const clusterNamespace = useSelector(getClusterNamespace);
    const [isAddServiceAccountPopupOpen, setIsAddServiceAccountPopupOpen] = useState<boolean>(false);
    const [serviceAccountName, setServiceAccountName] = useState<string>('');
    const [newServiceAccount, setNewServiceAccount] = useState<any>(null);

    useEffect(() => {
        getServiceAccountsFromServer();
    }, []);

    function openServiceAccountModal() {
        setIsAddServiceAccountPopupOpen(true);
    }

    function closeServiceAccountModal() {
        setIsAddServiceAccountPopupOpen(false);
        setServiceAccountName('');
        setNewServiceAccount(null);
    }

    async function addAccount() {
        const serviceAccount = await addServiceAccount(serviceAccountName);
        setNewServiceAccount(serviceAccount);
        await getServiceAccountsFromServer();
        dispatch(setSelectedServiceAccount(serviceAccount));
    }

    return (
        <div className='k8s-registry-configurations registry-step'>
            {!selectedCluster && (
                <div className='input-container'>
                    <div className='title-section'>
                        <Label text={`1. ${t('ON_BOARDING.ENTER_CLUSTER_NAME')}`} color='strong' size='xl' />
                    </div>
                    <Input
                        value={clusterName.value}
                        isError={clusterName.isDirty && clusterName.isError}
                        helperText={clusterName.isDirty && clusterName.isError ? clusterName.errorMessage : ''}
                        onChange={(ev) => dispatch(setClusterName(ev.target.value))}
                        fullWidth
                    />
                </div>
            )}

            <div className='input-container'>
                <div className='title-section'>
                    <Label text={`${selectedCluster ? '' : '2. '}${t('ON_BOARDING.SELECT_SERVICE_ACCOUNT')}`} color='strong' size='xl' />
                </div>
                <div className='indent-box'>
                    <div className='small-container'>
                        <Stack spacing={1}>
                            <Label text={t('ON_BOARDING.SERVICE_ACCOUNT')} />
                            <Stack direction='row' spacing={2} fullWidth>
                                <Stack fullWidth>
                                    <Select
                                        fullWidth
                                        isMulti={false}
                                        clearable
                                        options={serviceAccounts.map((serviceAccount) => ({
                                            value: `${serviceAccount.id}-${serviceAccount.name}`,
                                            label: serviceAccount.name,
                                        }))}
                                        onChange={(selectedOption: string | undefined) => {
                                            if (!selectedOption) {
                                                dispatch(setSelectedServiceAccount(null));
                                                return;
                                            }
                                            const selectedServiceAccount = serviceAccounts.find(
                                                (serviceAccount) => `${serviceAccount.id}-${serviceAccount.name}` === selectedOption
                                            );
                                            dispatch(setSelectedServiceAccount(selectedServiceAccount));
                                        }}
                                        placeholder={t('ON_BOARDING.MANUAL')}
                                    />
                                </Stack>
                                <Button onClick={() => openServiceAccountModal()} color='brandPrimary'>
                                    {t('ON_BOARDING.ADD_SERVICE_ACCOUNT')}
                                </Button>
                            </Stack>
                        </Stack>
                    </div>

                    <div className='small-container'>
                        <Input
                            label={t('ON_BOARDING.API_KEY')}
                            value={clusterKey.value}
                            required
                            readOnly={selectedSreviceAccount !== null}
                            onChange={(ev) => dispatch(setClusterKey(ev.target.value))}
                            isError={clusterKey.isDirty && clusterKey.isError}
                            helperText={(clusterKey.isDirty && clusterKey.isError) ? clusterKey.errorMessage : ''}
                            fullWidth
                        />
                    </div>

                    <div className='small-container'>
                        <Input
                            label={t('ON_BOARDING.API_SECRET')}
                            value={clusterSecret.value}
                            required
                            onChange={(ev) => dispatch(setClusterSecret(ev.target.value))}
                            isError={clusterSecret.isDirty && clusterSecret.isError}
                            helperText={(clusterSecret.isDirty && clusterSecret.isError) ? clusterSecret.errorMessage : ''}
                            fullWidth
                        />
                    </div>
                </div>
            </div>

            {!selectedCluster && (
                <div className='input-container'>
                    <div className='title-section'>
                        <Label text={`3. ${t('ON_BOARDING.ENTER_KUBERNETES_NAMESPACE')}`} color='strong' size='xl' />
                    </div>
                    <Input
                        value={clusterNamespace.value}
                        isError={clusterNamespace.isDirty && clusterNamespace.isError}
                        helperText={clusterNamespace.isDirty && clusterNamespace.isError ? clusterNamespace.errorMessage : ''}
                        onChange={(ev) => dispatch(setClusterNamespace(ev.target.value))}
                        fullWidth
                    />
                </div>
            )}

            {!selectedCluster && (
                <div className='input-container'>
                    <div className='title-section'>
                        <Label text={`4. ${t('ON_BOARDING.SELECT_DESIRED_FEATURES')}`} color='strong' size='xl' />
                    </div>
                    <div className='checkbox-container checkbox-container__disabled'>
                        <Checkbox disabled checked readOnly>
                            {t('ON_BOARDING.POSTURE_MANAGEMENT')}
                        </Checkbox>
                    </div>
                    <div className='checkbox-container checkbox-container__disabled'>
                        <Checkbox
                            disabled
                            checked
                            readOnly
                            onChange={(ev: any) => dispatch(toggleClusterImageAssurance(ev.target.checked))}>
                            {t('ON_BOARDING.IMAGE_ASSURANCE')}
                        </Checkbox>
                        <CpTooltip right={true} className='tooltip' text={t('ON_BOARDING.MORE_INFO_TOOLTIP')}>
                            <a
                                href='https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Workload-Protection/Kubernetes-Containers/Image-Assurance-Findings.htm?tocpath=Workload%20Protection%20%7CKubernetes%20Containers%7CImage%20Assurance%7C_____0'
                                target='_blank' rel="noreferrer">
                                <CpIcon className='tool-tip-icon' icon='info' />
                            </a>
                        </CpTooltip>
                    </div>
                    <div className='checkbox-container'>
                        <Checkbox
                            onChange={(ev: any) => dispatch(toggleClusterAdmissionControl(ev.target.checked))}
                        >
                            {t('ON_BOARDING.ADMISSION_CONTROL')}
                        </Checkbox>
                        <CpTooltip right className='tooltip' text={t('ON_BOARDING.MORE_INFO_TOOLTIP')}>
                            <a
                                href='https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Workload-Protection/Kubernetes-Containers/Admission-Control.htm?tocpath=Workload%20Protection%20%7CKubernetes%20Containers%7C_____2'
                                target='_blank' rel="noreferrer">
                                <CpIcon className='tool-tip-icon' icon='info' />
                            </a>
                        </CpTooltip>
                    </div>
                    <div className='checkbox-container'>
                        <Checkbox
                            checked={clusterForm.runtimeProtectionEnabled}
                            onChange={(ev: any) => dispatch(toggleClusterRuntimeProtection(ev.target.checked))}
                        >
                            {t('ON_BOARDING.RUNTIME_PROTECTION_PREVIEW')}
                        </Checkbox>
                        <CpTooltip right className='tooltip' text={t('ON_BOARDING.MORE_INFO_TOOLTIP')}>
                            <a
                                href='https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Workload-Protection/Kubernetes-Containers/Kubernetes-Runtime-Protection.htm?tocpath=Workload%20Protection%20%7CKubernetes%20Containers%7C_____3'
                                target='_blank' rel="noreferrer">
                                <CpIcon className='tool-tip-icon' icon='info' />
                            </a>
                        </CpTooltip>
                    </div>
                    <div
                        className={`checkbox-container checkbox-container__nested ${!clusterForm.runtimeProtectionEnabled ? 'checkbox-container__disabled' : ''}`}
                    >
                        <Checkbox
                            readOnly={!clusterForm.runtimeProtectionEnabled}
                            disabled={!clusterForm.runtimeProtectionEnabled}
                            checked={clusterForm.runtimeProtectionProfiling}
                            onChange={(ev: any) => dispatch(toggleClusterRuntimeProfilingProtection(ev.target.checked))}
                        >
                            {t('ON_BOARDING.RUNTIME_PROTECTION_PROFILING')}
                        </Checkbox>
                    </div>
                    <div className='checkbox-container'>
                        <Checkbox
                            onChange={(ev: any) => dispatch(toggleClusterThreatIntelligence(ev.target.checked))}>
                            {t('ON_BOARDING.THREAT_INTELLIGENCE')}
                        </Checkbox>
                        <CpTooltip right className='tooltip' text={t('ON_BOARDING.MORE_INFO_TOOLTIP')}>
                            <a
                                href='https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Workload-Protection/Kubernetes-Containers/Kubernetes-Intelligence.htm?tocpath=Intelligence%20%7C_____8'
                                target='_blank' rel="noreferrer">
                                <CpIcon className='tool-tip-icon' icon='info' />
                            </a>
                        </CpTooltip>
                    </div>
                </div>
            )}

            <CpModal size='lg' open={isAddServiceAccountPopupOpen} backdropClose={true}>
                <CpModalHeader onCpClose={() => closeServiceAccountModal()}>
                    {newServiceAccount
                        ? t('ON_BOARDING.NEW_SERVICE_ACCOUNT_DETAILS')
                        : t('ON_BOARDING.ADD_SERVICE_ACCOUNT')}
                </CpModalHeader>
                <CpModalBody>
                    {newServiceAccount ? (
                        <div>
                            <div className='title-section'>{t('ON_BOARDING.PLEASE_RECORD_DATA')}</div>
                            <div className='row'>
                                <span className='key'>{t('ON_BOARDING.API_KEY')}</span>
                                <span className='value'>
                                    <CpCopyToClipboard>
                                        <div>{newServiceAccount?.apiKeyId}</div>
                                    </CpCopyToClipboard>
                                </span>
                            </div>
                            <div className='row'>
                                <span className='key'>{t('ON_BOARDING.API_SECRET')}</span>
                                <span className='value'>
                                    <CpCopyToClipboard>
                                        <div>{newServiceAccount?.apiKeySecret}</div>
                                    </CpCopyToClipboard>
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div className='small-input-container'>
                            <div className='title-section'>{t('ON_BOARDING.NAME')}</div>
                            <CpTextBox
                                onCpChange={(ev: any) => {
                                    setServiceAccountName(ev.target.value);
                                }}
                                autofocus
                                value={serviceAccountName}
                            />
                        </div>
                    )}
                </CpModalBody>
                <CpModalFooter>
                    {newServiceAccount ? (
                        <CpButton onClick={() => closeServiceAccountModal()}>
                            {t('ON_BOARDING.CLOSE')}
                        </CpButton>
                    ) : (
                        <>
                            <CpButton onClick={() => closeServiceAccountModal()}>
                                {t('ON_BOARDING.CANCEL')}
                            </CpButton>
                            <CpCommonButton
                                disabled={serviceAccountName.length === 0}
                                onClick={() => {
                                    addAccount();
                                }}>
                                {t('ON_BOARDING.ADD')}
                            </CpCommonButton>
                        </>
                    )}
                </CpModalFooter>
            </CpModal>
        </div>
    );
};

export default ClusterConfigurations;
