import styled from 'styled-components';

export const CustomCloudGuardIconWrapper = styled.div<{ size: 'small' | 'large'; }>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: ${(props) => props.size === 'large' ? '10px' : '5px'};

    & > p {
        font-size: ${(props) => props.size === 'large' ? '20px' : '15px'};
    }
`;

export const CustomCloudGuardIcon = styled.div<{ size: 'small' | 'large'; }>`
    position: relative;
    width: fit-content;
    transform: translateX(${(props) => props.size === 'large' ? '-16px' : '-8px'});

    & * {
        transition: all 200ms linear;
    }

    & > div {
        position: absolute;
        right: -25%;
        bottom: 0;
        width: ${(props) => props.size === 'large' ? '64px' : '32px'};
        height: ${(props) => props.size === 'large' ? '64px' : '32px'};
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            content: '';
            width: 70%;
            height: 70%;
            background: white;
        }

        & i {
            position: absolute;
            right: 0;
            bottom: 0;

            &:first-child {
                transform: scale(1.2);
                & svg {
                    fill: white;
                }
            }
        }
    }
`;