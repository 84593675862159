import React from 'react';
import { Chip, RadioButton, Stack, Typography } from 'common/design-system/components-v2';
import { ScanModeCardProps } from './scanModeSelector.types';

export const ScanModeCard: React.FC<ScanModeCardProps> = ({ scanMode, description, selected = false, onCardSelected, isPreview }) => {
    return (
        <Stack direction='row' alignItems='flex-start' spacing={1}>
            <RadioButton checked={selected} onChange={onCardSelected}>
                <Stack spacing={1} padding={[2, 0, 0]}>
                    <Typography><b>{scanMode}</b></Typography>
                    <Typography color='light'>{description}</Typography>
                    {isPreview && <Chip disableInteraction size='sm' color='brandPrimary' label='Preview' />}
                </Stack>
            </RadioButton>
        </Stack>
    );
};
