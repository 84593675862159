import styled from 'styled-components';

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => `${theme.spacing(2)}${theme.units}`};
`;

const FormItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => `${theme.spacing(1)}${theme.units}`};
`;

export default {
    FormWrapper,
    FormItemWrapper
};
