import React from 'react';
import { WorkloadAgentlessAccount } from '../awp.interface';
import AwpService from '../awp.service';
import { Vendors } from 'common/consts/vendors';

export interface UseWorkloadAgentlessAccountPayload {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    data?: WorkloadAgentlessAccount;

    reloadData: () => void;
}

export type UseWorkloadAgentlessAccount = (cloudAccountId: string, vendor: Vendors) => UseWorkloadAgentlessAccountPayload;

export const useWorkloadAgentlessAccount: UseWorkloadAgentlessAccount = (cloudAccountId, vendor) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [data, setData] = React.useState<WorkloadAgentlessAccount>();

    const getData = React.useCallback(async (cloudAccountId: string, vendor: Vendors) => {
        setIsLoading(true);
        if (isError) {
            setIsError(false);
        }
        try {
            const response = await AwpService.getWorkloadAgentessAccount(cloudAccountId, vendor);
            setData(response.data);
        } catch (error) {
            setIsError(true);            
        } finally {
            setIsLoading(false);
        }
    }, [setData, setIsLoading, setIsError, isError]);
 
    React.useEffect(() => {
        if (!isLoading && !data && !isError) {
            getData(cloudAccountId, vendor);
        }
    }, [cloudAccountId, vendor, data, isLoading, isError, getData]);

    const reloadData = React.useCallback(() => getData(cloudAccountId, vendor), [cloudAccountId, vendor, getData]);

    return ({
        isLoading: !!isLoading && !data,
        isFetching: !!isLoading,
        isError: !!isError,
        data,

        reloadData
    });
};