import { K8SRootStore } from 'modules/workloads/initialize.redux';
import React from 'react';
import { useSelector } from 'react-redux';
import ClusterActions from '../cluster.actions';
import { Cluster } from '../cluster.interface';
import { ClusterSelector } from '../cluster.selectors';

export interface UseGetClusterPayload {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    isDeleted: boolean;
    data?: Cluster;

    reloadData: () => void;
}

export type UseGetCluster = (clusterId: string) => UseGetClusterPayload;

export const useGetCluster: UseGetCluster = (clusterId) => {
    const { loading, error, data, deleted } = useSelector((state: K8SRootStore) => ClusterSelector.getCluster(state, clusterId)) || {};

    const getData = (id: string) => ClusterActions.getCluster(id);
 
    React.useEffect(() => {
        if (!loading && !data && !error) {
            getData(clusterId);
        }
    }, [clusterId, data, loading, error]);

    const reloadData = React.useCallback(() => getData(clusterId), [clusterId]);

    return ({
        isLoading: !!loading && !data,
        isFetching: !!loading,
        isError: !!error,
        isDeleted: !!deleted,
        data,

        reloadData
    });
};