import { Icon, Stack, Typography } from 'common/design-system/components-v2';
import { getGenerateOnboardinDataRequestPayload, getMissingFields } from '../../awpOnboardingAws.utils';
import AwpService from 'modules/workloads/services/awp/awp.service';
import React from 'react';
import { useParams } from 'react-router-dom';
import { UrlSnippetProps, SnippetState } from './UrlSnippet.types';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { SnippetCommand, UrlLink } from './UrlSnippet.styled';

let timeId: NodeJS.Timeout;
const UrlSnippet = ({ watch, formState, scanMode }: UrlSnippetProps) => {
    const { t } = useTranslation(getK8sNamespace('awp'));
    const { cloudAccountId } = useParams<{ cloudAccountId: string; }>();
    const [stackName, crossAccountExternalId, hubId ] = watch(['stackName', 'crossAccountExternalId', 'hubId']);
    const [snippetState, setSnippetState] = React.useState<SnippetState>({ type: 'LOADING' });

    const missingFields = React.useMemo(() =>
        getMissingFields({
            scanMode,
            isHubIdDirty: Boolean(formState.dirtyFields.hubId),
            crossAccountRoleNameInvalid: Boolean(formState.errors.crossAccountRoleName),
            crossAccountExternalIdInvalid: Boolean(formState.errors.crossAccountExternalId),
            stackNameInvalid: Boolean(formState.errors.stackName),
        }).join(' ,'), [scanMode, formState.dirtyFields.hubId, formState.errors.crossAccountRoleName, formState.errors.crossAccountExternalId, formState.errors.stackName]);

    React.useEffect(() => {
        setSnippetState({ type: 'LOADING' });
        timeId = setTimeout(() => {
            const currentTimeId = timeId;
            if (missingFields) {
                clearTimeout(currentTimeId);
                setSnippetState({ type: 'ERROR', message: t('awpOnboarding.aws.generateUrl.formError', { fields: missingFields }) });
                return;
            }    
            const data = getGenerateOnboardinDataRequestPayload({ scanMode, stackName, crossAccountExternalId, cloudAccountId, hubId: hubId ?? undefined });
            AwpService.getAwsOnboardingData(cloudAccountId, data).then(response => {
                if (timeId === currentTimeId) {
                    setSnippetState({ type: 'SUCCESS', text: response.data.crossAccountCloudFormationQuickCreateUrl });
                }
            }).catch((error) => {
                setSnippetState({ type: 'ERROR', message: t('awpOnboarding.aws.generateUrl.apiError', { errorSuffix: error?.message ? `: ${error.message}` : '' }) });
            });
        }, 860);
        return () => clearTimeout(timeId);
    }, [missingFields, scanMode, stackName, crossAccountExternalId, cloudAccountId, hubId, t ]);

    switch(snippetState.type) {
        case 'LOADING':
            return (
                <SnippetCommand isLoading text={''} />
            );
        case 'ERROR':
            return (
                <SnippetCommand isLoading text='' customLoadingElement={
                    <Stack spacing={2} direction='row' justifyContent='center' alignItems='center' fullWidth>
                        <Icon name='info' size={16} />
                        <Typography>{snippetState.message}</Typography>
                    </Stack>
                } />
            );
        case 'SUCCESS':
            return (
                <SnippetCommand text={snippetState.text}>
                    <UrlLink removeExternalIcon externalUrl={snippetState.text}>{snippetState.text}</UrlLink>
                </SnippetCommand>
            );
    }    
};

export default UrlSnippet;