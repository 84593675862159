import { K8SRootStore } from 'modules/workloads/initialize.redux';
import React from 'react';
import { useSelector } from 'react-redux';
import ShiftLeftActions from '../shiftLeft.actions';
import { ShiftLeft } from '../shiftLeft.interface';
import { ShiftLeftSelector } from '../shiftLeft.selectors';

export interface UseGetShiftLeftPayload {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    isDeleted: boolean;
    data?: ShiftLeft;

    reloadData: () => void;
}

export type UseGetShiftLeft = (shiftLeftId: string) => UseGetShiftLeftPayload;
export const useGetShiftLeft: UseGetShiftLeft = (shiftLeftId) => {
    const { loading, error, data, deleted } = useSelector((state: K8SRootStore) => ShiftLeftSelector.getShiftLeft(state, shiftLeftId)) || {};

    const getData = (id: string) => ShiftLeftActions.getShiftLeft(id);
 
    React.useEffect(() => {
        if (!loading && !data && !error) {
            getData(shiftLeftId);
        }
    }, [shiftLeftId, data, loading, error]);

    const reloadData = React.useCallback(() => getData(shiftLeftId), [shiftLeftId]);

    return ({
        isLoading: !!loading && !data,
        isFetching: !!loading,
        isError: !!error,
        isDeleted: !!deleted,
        data,

        reloadData
    });
};