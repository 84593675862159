import { shiftLeft_actions } from '.';
import { batch } from 'react-redux';
import { K8sStoreService } from 'modules/workloads/initialize.redux';
import { ShiftLeft } from './shiftLeft.interface';
import { getMessageFromError } from 'modules/workloads/utils/errorMessage';
import ShiftLeftAccountService from 'modules/workloads/services/shiftLeft/shiftLeftAccount.service';
import { AxiosResponse } from 'axios';

type GetShiftLeft = (shiftLeftId: string) => Promise<boolean>;
const getShiftLeft: GetShiftLeft = async (shiftLeftId) => {
    const { dispatch } = K8sStoreService().getReduxTools();
    try {
        dispatch(shiftLeft_actions.setLoading({ shiftLeftId, loading: true }));
        const accountData = await ShiftLeftAccountService.getAccount({ id: shiftLeftId });
        if (!accountData?.data) throw new Error('');
        const shiftLeftData: ShiftLeft = {
            account: accountData.data,
        };
        batch(() => {
            dispatch(shiftLeft_actions.setData({ shiftLeftId, data: shiftLeftData }));
            dispatch(shiftLeft_actions.setError({ shiftLeftId, error: false }));
            dispatch(shiftLeft_actions.setLoading({ shiftLeftId, loading: false }));
        });
        return true;
    } catch (error) {
        batch(() => {
            dispatch(shiftLeft_actions.setError({ shiftLeftId, error: true }));
            dispatch(shiftLeft_actions.setLoading({ shiftLeftId, loading: false }));
        });
        throw Error(getMessageFromError(error));
    }
};

type DeleteForceShiftLeft = (shiftLeftId: string) => Promise<boolean>;
const deleteForce: DeleteForceShiftLeft = async (shiftLeftId) => {
    try {
        const { dispatch } = K8sStoreService().getReduxTools();
        await ShiftLeftAccountService.deleteForce({ id: shiftLeftId });
        dispatch(shiftLeft_actions.setDeleted({ shiftLeftId, deleted: true }));
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

export interface UpdateShiftLeftReq {
    name?: string;
    description?: string;
    organizationalUnitId?: string;
}
type UpdateShiftLeft = (shiftLeftId: string, req: UpdateShiftLeftReq) => Promise<boolean>;
const updateShiftLeft: UpdateShiftLeft = async (shiftLeftId, { name, organizationalUnitId, description }) => {
    try {
        const { state } = K8sStoreService().getReduxTools();
        const { name: _name, organizationalUnitId: _organizationalUnitId, description: _description } = state.k8s_shiftLeft.shiftLeftEvnironments[shiftLeftId]?.data?.account || {};

        const updatePromiseArr: Array<Promise<AxiosResponse<any>>> = [];

        if (name && name !== _name) {
            updatePromiseArr.push(ShiftLeftAccountService.updateName({ id: shiftLeftId, name: name }));
        }
        if (description !== undefined && description !== _description) {
            updatePromiseArr.push(ShiftLeftAccountService.updateDescription({ id: shiftLeftId, description: description }));
        }
        if (organizationalUnitId && organizationalUnitId !== _organizationalUnitId) {
            updatePromiseArr.push(ShiftLeftAccountService.updateOU({ id: shiftLeftId, organizationalUnitId }));
        }

        const result = await Promise.all(updatePromiseArr);
        
        if (result.length > 0) {
            getShiftLeft(shiftLeftId);
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

const ShiftLeftActions = {
    getShiftLeft,
    deleteForce,
    updateShiftLeft,
};

export default ShiftLeftActions;