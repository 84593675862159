import React from 'react';
import { Command, Message, Stack, Typography } from 'common/design-system/components-v2';
import { DisableAwpModalProps } from '../DisableAWP.types';
import { Trans, useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

const DisableAwsInAccountSubTerraform: React.FC<DisableAwpModalProps> = ({ onClose, onDelete, isDeletePending }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));

    return (
        <Message
            title={t('awpTab.modals.disableAWP.aws.title')}
            isOpen
            width='md'
            variant='danger'
            onClose={onClose}
            onCancel={onClose}
            onConfirm={() => onDelete(true)}
            isLoading={isDeletePending}
            submitBtnDisabled={isDeletePending}
        >
            <Stack spacing={4}>
                <Typography>
                    <Trans
                        i18nKey={'k8s_awp:awpTab.modals.disableAWP.aws.terraform'}
                        components={{
                            command: <Command text='terraform destroy' />,
                            div: <div />,
                            stack: <Stack spacing={2}>.</Stack>,
                        }}
                    />
                </Typography>
            </Stack>
        </Message>
    );
};

export default DisableAwsInAccountSubTerraform;