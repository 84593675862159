import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

export const CardWrapper = styled.div`
    flex: 0.6;
`;

export const ImageOverviewWrapper = styled(Stack)`
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}${theme.units}` }){
        flex-direction: column;
    }
`;