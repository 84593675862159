import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';
import useAwpUpdateData from './hooks/useAwpUpdateData';
import { AwpUpdateStateType } from './awpUpdate.types';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import { LoaderWrapper } from './awpUpdate.styled';
import { changeUrl } from 'common/utils/http';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { getCloudAccountsService } from 'common/interface/data_services';
import AwpUpdateStack from '../awpUpdateStack/awpUpdateStack';
import AwpUpdateTerraform from './components/awpUpdateTerraform';
import AwpUpdateStackOldVersion from '../awpUpdateStackOldVersion/awpUpdateStackOldVersion';

const AwpUpdate = () => {
    const { t } = useTranslation(getK8sNamespace('awp'));
    const updateState = useAwpUpdateData();

    if (updateState.type === AwpUpdateStateType.LOADING) {
        return (
            <LoaderWrapper>
                <CloudAnimationLoader size='medium' />
            </LoaderWrapper>
        );
    }

    if (updateState.type === AwpUpdateStateType.ERROR) {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('update.errorLoadingPage'),
            text: updateState.error.message,
        });
        changeUrl('/cloud-account/index');
        return null;
    }

    if (updateState.type === AwpUpdateStateType.NONE) {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            text: t('update.noUpdateRequired'),
        });
        getCloudAccountsService().clearCache();
        changeUrl('/cloud-account/index');
        return null;
    }

    switch (updateState.type) {
        case AwpUpdateStateType.TERRAFORM_UPDATE:
            return <AwpUpdateTerraform version={updateState.data.version} />;
        case AwpUpdateStateType.CLOUDFORMATION_STACK_UPDATE:
            return <AwpUpdateStack cloudAccountId={updateState.data.cloudAccountId} url={updateState.data.url} />;
        case AwpUpdateStateType.CLOUDFORMATION_STACK_UPDATE_OLD:
            return <AwpUpdateStackOldVersion cloudAccountId={updateState.data.cloudAccountId} url={updateState.data.url} />;
    }
};

export default AwpUpdate;