import React from 'react';
import { useTranslation } from 'react-i18next';
import { RegistryDescriptionInputProps } from './RegistryDescriptionInput.interface';
import { Input } from 'common/design-system/components-v2';

const RegistryDescriptionInput: React.FC<RegistryDescriptionInputProps> = (props) => {
    const { registryDescription, setRegistryDescription } = props;
    const { t } = useTranslation('k8s_registry');

    return (
        <div className='input-container'>
            <Input
                label={t('ON_BOARDING.ENTER_REGISTRY_DESCRIPTION')}
                value={registryDescription || ''}
                onChange={(e) => setRegistryDescription(e.target.value)}
                fullWidth
                clearable
            />
        </div>
    );
};

export default RegistryDescriptionInput;