import React from 'react';
import { RegistryURIInputProps } from './RegistryURIInput.interface';
import { useTranslation } from 'react-i18next';
import { Input } from 'common/design-system/components-v2';

const RegistryURIInput: React.FC<RegistryURIInputProps> = (props) => {
    const { registryUrl, onChange, tooltipContent } = props;
    const { t } = useTranslation('k8s_registry');

    return (
        <Input
            label={t('ON_BOARDING.registryConfigFormLabels.registryUri')}
            required
            value={registryUrl.value || ''}
            isError={registryUrl.isDirty && registryUrl.isError}
            helperText={registryUrl.isDirty && registryUrl.isError ? registryUrl.errorMessage : ''}
            onChange={(e) => onChange(e.target.value)}
            fullWidth
            tooltip={tooltipContent}
        />
    );
};

export default RegistryURIInput;