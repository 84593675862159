import { REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import RegistryOnboarding from './pages/registry/RegistryOnboarding/RegistryOnboarding';
import RegistryPage from './pages/registry/registryPage/RegistryPage';
import { getUserService } from 'common/interface/services';
import WelcomePage from './pages/welcomePage';
import Dashboard from './pages/dashboard/Dashboard';
import KubernetesEnvironment from './pages/kubernetesCluster/kubernetesEnvironment/KubernetesEnvironment';
import KubernetesOnboardingFirstTime from './pages/kubernetesCluster/kubernetesOnboardingFirstTime/KubernetesOnboardingFirstTime';
import KubernetesOnboarding from './pages/kubernetesCluster/kubernetesOnboarding';
import ShiftleftEnvironment from './pages/shiftLeft/shiftLeftEnvironment/ShiftLeftEnvironment';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import AwpOnboardingAzure from './pages/awp/awpOnboarding/awpOnboardingAzure/awpOnboardingAzure';
import ShiftleftOnboarding from './pages/shiftLeft/ShiftleftOnboarding/ShiftleftOnboarding';
import CveSearch from './pages/cveSearch';
import AwpOnboardingAws from './pages/awp/awpOnboarding/awpOnboardingAws/awpOnboardingAws';
import Settings from './pages/settings/Settings';
import AwpUpdate from './pages/awp/awpUpdate/awpUpdate';

const workLoadWelcomeRoute = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: WelcomePage,
        path: ['/workload/welcome-page', '/workload-protection/welcome-page'] as any,
    };
};

const onboardingRoute = () => {
    return {
        condition: true,
        component: RegistryOnboarding,
        path: '/workload/registry-onboarding',
    };
};

const scanningRoute = () => {
    return {
        condition: true,
        component: RegistryPage,
        path: ['/workload/environments/containerregistry/:registryId', '/cloud-account/containerregistry/:registryId'] as any,
    };
};

const workloadRoute = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: Settings,
        path: '/settings/workload',
    };
};

const dashboardRoute = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: Dashboard,
        path: ['/workload/dashboard', '/workload-protection/dashboard'] as any,
    };
};

const kubernetesEnvironmentRoute = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: KubernetesEnvironment,
        path: ['/workload/environments/kubernetes/:id', '/cloud-account/kubernetes/:id'] as any,
    };
};

const shiftleftEnvironmentRoute = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: ShiftleftEnvironment,
        path: ['/workload/environments/shiftleft/:id', '/cloud-account/shiftleft/:id'] as any,
    };
};

const KubernetesOnboardingRoute = () => {
    return {
        condition: true,
        component: KubernetesOnboarding,
        path: '/workload/cloud-add/kubernetes',
        exact: true,
    };
};

const ShiftleftOnboardingRoute = () => {
    return {
        condition: true,
        component: ShiftleftOnboarding,
        path: '/workload/cloud-add/shiftleft'
    };
};

const KubernetesOnboardingFirstTimeRoute = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: KubernetesOnboardingFirstTime,
        path: '/workload/cloud-add/kubernetes/first-time',
    };
};

const EnableAwp = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: AwpOnboardingAws,
        path: ['/cloud-add/agentless/aws/:cloudAccountId'] as any,
        exact: true,
    };
};

const EnableAwpAzure = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: AwpOnboardingAzure,
        path: ['/cloud-add/agentless/azure/:cloudAccountId'] as any,
        exact: true,
    };
};

const UpdateAwp = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: AwpUpdate,
        path: ['/cloud-update/agentless/:cloudAccountId'] as any,
        exact: true,
    };
};

const CveSearchPage = () => {
    return {
        condition: getUserService().hasPermission(['all']),
        component: CveSearch,
        path: ['/workload/cve-search'],
    };
};

export const initializeK8sPages = () => {
    globalAddinContainer.add(REACT_PAGE_ROUTE, [
        { id: 'K8s Welcome', content: workLoadWelcomeRoute },
        { id: 'K8s Registry Onboarding', content: onboardingRoute },
        { id: 'K8s Registry Scanning', content: scanningRoute },
        { id: 'K8s Workload', content: workloadRoute },
        { id: 'K8s Dashboard', content: dashboardRoute },
        { id: 'K8s Kubernetes Environment', content: kubernetesEnvironmentRoute },
        { id: 'K8s Shifleft Environment', content: shiftleftEnvironmentRoute },
        { id: 'K8s Onboarding Cluster', content: KubernetesOnboardingRoute },
        { id: 'K8s Onboarding Cluster First Time', content: KubernetesOnboardingFirstTimeRoute },
        { id: 'Awp Onboarding', content: EnableAwp },
        { id: 'Awp Onboarding Azure', content: EnableAwpAzure },
        { id: 'Awp Update', content: UpdateAwp },
        { id: 'k8s shiftleft Onboarding', content: ShiftleftOnboardingRoute },
        { id: 'cve-search', content: CveSearchPage },
    ]);
};
