import React from 'react';
import ImageAssuranceConfig from './ImageAssuranceConfig/ImageAssuranceConfig';
import { useScanEngineVersion } from '../../../../services/vulnerability/hooks/useScanEngineVersion';
import { Card, Chip } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';

const ImageAssurance: React.FC = () => {
    const { data, isLoading } = useScanEngineVersion();
    const { t } = useTranslation();

    return (
        <Card
            title={t('SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.TITLE')}
            rightContent={data?.scanEngineVersion && (
                <Chip
                    disableInteraction
                    color='brandPrimary'
                    size='md'
                    label={data.scanEngineVersion}
                    loading={isLoading}
                    tooltip={t('SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.SCAN_ENGINE_VERSION', { version: data.scanEngineVersion })}
                />
            )}
        >
            <ImageAssuranceConfig />
        </Card>
    );
};

export default ImageAssurance;
