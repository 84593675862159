import React from 'react';
import CveTableStyles from './CveTable.styled';
import { Table } from 'common/design-system/components-v2';
import { CveTableProps } from './CveTable.types';
import { ITableProps } from 'common/design-system/components-v2/Table/Table.types';
import EmptyState from 'common/components/EmptyState';
import { cveSearchColDefs } from '../Definitions/ColumnDefinitions';
import { GridApi, ColumnApi, GridReadyEvent, GridOptions } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

const CveTable: React.FunctionComponent<CveTableProps> = ({ cveId, rowData, isLoading, mode = 'cveSearch', onExport }) => {
    const apiRef = React.useRef<GridApi>();
    const columnApiRef = React.useRef<ColumnApi>();
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));

    React.useEffect(() => {
        if (apiRef.current) {
            if (isLoading) {
                apiRef.current.showLoadingOverlay();
            } else {
                apiRef.current.hideOverlay();
            }
        }
    }, [isLoading]);

    React.useEffect(() => {
        if (apiRef.current && rowData) {
            apiRef.current.setRowData([]);
            apiRef.current.setRowData(rowData);
        }
    }, [rowData]);

    const onGridReady = React.useCallback((params: GridReadyEvent) => {
        apiRef.current = params.api;
        columnApiRef.current = params.columnApi;
        if (rowData) {
            apiRef.current.setRowData([]);
            apiRef.current.setRowData(rowData);
        }
        if (isLoading) {
            apiRef.current.showLoadingOverlay();
        } else {
            apiRef.current.hideOverlay();
        }
    }, [rowData, isLoading]);

    const getActiveColumnsIds = () => {
        if (columnApiRef.current) {
            const allColumns = columnApiRef.current.getAllGridColumns();
            const visibleColumns = allColumns
                ? allColumns.filter(col => col.isVisible())
                : [];

            return visibleColumns.map(col => col.getColId());
        }
    };

    const onExportActiveColumns = React.useCallback(() => {
        const activeColumns = getActiveColumnsIds()?.filter(col => col !== 'scan.producer') || [];
        onExport && onExport(activeColumns);
    }, [onExport]);

    const onExportExtendedColumns = React.useCallback(() => {
        const extendedColumns = [
            'cve.id',
            'affectedAsset.entityName',
            'affectedAsset.entityType',
            'affectedAsset.dome9Id',
            'scannedAsset.entityName',
            'scannedAsset.entityType',
            'package.name',
            'package.version',
            'cloudAccountName',
            'cve.remediation',
            'cve.isFixable',
            'cve.vectorString',
            'package.path',
            'package.severity',
            'package.packageManagerName',
            'package.isOsPackage',
            'package.remediation',
            'cve.relatedIds',
            'cve.sourceUrl',
            'cve.description',
            'cve.source',
            'cve.baseScore',
            'cve.impactScore',
            'cve.exploitabilityScore',
            'cve.version',
            'platform'
        ];
        onExport && onExport(extendedColumns, 'Extended');
    }, [onExport]);

    const exportButtons: ITableProps['exportButtons'] = React.useMemo(() => {
        if (mode === 'topCves') return undefined;
        return [
            { onClick: () => onExportActiveColumns(), label: t('export.export.label'), tooltip: t('export.export.tooltip') },
            { onClick: () => onExportExtendedColumns(), label: t('export.exportExtended.label'), tooltip: t('export.exportExtended.tooltip') }
        ];
    }, [mode, onExportExtendedColumns, onExportActiveColumns]);

    const gridOptions: GridOptions = React.useMemo(() => ({
        getRowId: ({ data }) => `${data.id}${data.cveId}${data?.affectedAsset?.entityId}${data?.package.name}${data?.package.path}`,
        columnDefs: cveSearchColDefs(mode),
        noRowsOverlayComponent: () => (
            <EmptyState
                iconSize={32}
                iconName='magnify'
                description={(mode === 'cveSearch' && cveId) ? `No assets are directly associated with ${cveId}` : undefined}
                label='No Results Found'
            />),
        defaultColDef: {
            flex: 1,
        },
        onGridReady,
        autoGroupColumnDef: { flex: 3 },
    }), [onGridReady, cveId, mode]);

    return (
        <CveTableStyles.CveTableContainer spacing={6} fullHeight key={`${cveId}-${mode}`}>
            <Table
                tableId={`cve-table-${mode}`}
                saveColumnsState
                exportButtons={exportButtons}
                gridOptions={gridOptions}
                disableGrouping={mode === 'topCves'}
                disableColumnMenu={mode === 'topCves'}
            />
        </CveTableStyles.CveTableContainer>
    );
};

export default CveTable;
