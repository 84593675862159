import { getCloudAccountsService } from 'common/interface/data_services';
import { getNotificationsService } from 'common/interface/services';
import { changeUrl } from 'common/utils/http';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { ScanMode } from 'modules/workloads/services/awp/awp.interface';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CloudFormParams } from '../awpOnboardingAws.types';
import { enableAgentlessFunctions } from '../awpOnboardingAws.utils';

const useEnableAgentless = () => {
    const { t } = useTranslation(getK8sNamespace('awp'));
    const [isPending, setIsPending] = React.useState(false);
    const { cloudAccountId } = useParams<{ cloudAccountId: string; }>();

    const enableAgentlessAwsAccount = async (params: CloudFormParams, scanMode: ScanMode) => {
        setIsPending(true);
        try {
            await enableAgentlessFunctions[scanMode](cloudAccountId, params);
            getNotificationsService().success(t('awpOnboarding.messages.success'), '');
            getCloudAccountsService().clearCache();
            changeUrl('/cloud-account/index');
        } catch (error: any) {
            getNotificationsService().error(t('awpOnboarding.messages.failure'), error.response?.data?.message || error.response?.data || '');
            setIsPending(false);
        }
    };
    return {
        isPending,
        enableAgentlessAwsAccount,
    };
};

export default useEnableAgentless;