import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import { setIsReactPageWithAngular, setIsReact, } from '../../../../../App.reducer';
import { getCloudAccountsService } from 'common/interface/data_services';
import { getUserService } from 'common/interface/services';

import { isCheckpointUser, isCustomerPlan } from 'modules/workloads/utils';
import KubernetesOnBoardingPrePage from './KubernetesOnBoarding';
import { Stack } from 'common/design-system/components-v2';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';

const KubernetesOnboardingGuard: React.FC = () => {
    const [guardLoading, setGuardLoading] = React.useState<boolean>(true);

    const history = useHistory();
    const dispatch = useDispatch();

    const { name, email } = getUserService().getUser();
    const { plan } = getUserService().getAccount();

    const isOnboardingAllow = useCallback(
        (accountHasCluster: boolean) => {
            if (isCheckpointUser(name) || isCheckpointUser(email) || getUserService().hasPermission(['allow-k8s-onboarding']) || !isCustomerPlan(plan)) {
                return true;
            }
            return accountHasCluster;
        }, [name, email, plan]);
    useEffect(() => {
        (async () => {
            const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
            const accountHasCluster = !!allCloudAccounts.filter(({ platform }) => platform === 'kubernetes' || platform === 'registry').length;

            if (!isOnboardingAllow(accountHasCluster)) {
                // history.replace('/workload/cloud-add/kubernetes/first-time'); // Temporarily disabled (CON-7248)
            }
            setGuardLoading(false); //TODO: Could be a problem to do this if the histry.replace happen - need to check
        })();
    }, [dispatch, history, isOnboardingAllow]);

    if (guardLoading) {
        return (
            <Stack alignItems='center' fullHeight>
                <CloudAnimationLoader size='medium' />
            </Stack>
        );
    }

    return <KubernetesOnBoardingPrePage />;
};

export default KubernetesOnboardingGuard;
