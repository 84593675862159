import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

export const IconWrapper = styled(Stack)`
    background: ${({ theme }) => theme.palette.severity.informationalBg };
    color: ${({ theme }) => theme.palette.severity.informationalFg };
    border-radius: 50%;
`;

const IsFixableCellRendererStyles = {
    IconWrapper,
};

export default IsFixableCellRendererStyles;