export const macCommand = `chmod +x shiftleft
    spctl --add shiftleft
    sudo mv shiftleft /usr/local/bin/shiftleft
`;

export const linuxCommand =
    `chmod +x shiftleft
     sudo mv shiftleft /bin/shiftleft
    `;

export const runCommandLinuxMac = (envId: string) => `shiftleft image-scan -e ${envId} -i <path_to_image>`;
export const runCommandWindows = (envId: string) => `shiftleft.exe image-scan -e ${envId} -i <path_to_image>`;
