import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Wizard, { RenderViewParams, WizardStep } from 'common/components/Wizard/Wizard';
import { OUSelectionComponent } from 'common/components/policy/OUSelectionComponent';
import { RulesetMultiSelectionComponent } from 'common/components/policy/RulesetMultiSelectionComponent';
import { Vendors } from 'common/consts/vendors';
import { IVendorRuleset } from 'common/interface/policy';
import { getHttpService } from 'common/interface/services';
import { IFRAME_TAGS } from 'common/interface/IFrame.message.model';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { ImageAdmissionItem } from '../../helpers/interface';
import { saveVulnerabilitiesPolicyChanges } from '../../helpers/utils';
import { finishFlow, getPolicyRulesets } from 'common/components/policy/utils';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { ImageAdmissionSelectionComponent } from '../../components/ImageAdmissionSelectionComponent/ImageAdmissionSelectionComponent';
import { Done } from 'common/components/policy/Done/Done';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { useReturnUrl } from 'common/hooks/useReturnUrl';

export const AddOUPolicyPage: React.FC = () => {
    const { t } = useTranslation('k8s_policy');
    const [selectedOUs,setSelectedOUs] = useState<ListItem[]>();
    const [selectedRulesets,setSelectedRulesets] = useState<ListItem[]>();
    const [selectedNonCompliantAdmission, setSelectedNonCompliantAdmission] = React.useState<ImageAdmissionItem>();
    const [selectedUnScannedAdmission, setSelectedUnScannedAdmission] = React.useState<ImageAdmissionItem>();
    const [selectedNotifications,setSelectedNotifications] = useState<ListItem[]>();
    const [flowStatusMessage,setFlowStatusMessage] = useState<string>();
    useBreadcrumbsLastChildren([t('GENERAL.WORKLOAD_PROTECTION'),t('GENERAL.ADD_OU_POLICY')]);
    const returnUrl = useReturnUrl();
    const selectedOUsChanged = (selectedItems: ListItem[]) => {
        selectedItems && setSelectedOUs(selectedItems);
        setSelectedRulesets([]);
        setSelectedNotifications([]);
    };

    const saveChanges = useCallback(async () => {
        try {
            if (selectedNotifications?.length && selectedOUs?.length && selectedRulesets?.length && selectedUnScannedAdmission && selectedNonCompliantAdmission) {
                await saveVulnerabilitiesPolicyChanges(selectedNotifications, selectedOUs, selectedRulesets, selectedUnScannedAdmission, selectedNonCompliantAdmission);
                setFlowStatusMessage(t('GENERAL.POLICY_ADDED_MESSAGE'));
                getHttpService().clearCacheByTag(IFRAME_TAGS.CLEAR_CACHE.POLICIES);
                getHttpService().clearCacheByPath('ShiftLeftPolicy');
            }
        } catch (error) {
            setFlowStatusMessage(t('GENERAL.ERROR_OCCURRED'));
        }
    },[selectedNonCompliantAdmission, selectedNotifications, selectedOUs, selectedRulesets, selectedUnScannedAdmission, t]);

    const rulesetProvider:IVendorRuleset = useMemo(() => ({
        getRulesets: async () => {
            const allRulesets = await getPolicyRulesets();
            return allRulesets.filter((ruleset) => ruleset.platform === Vendors.IMAGE_ASSURANCE);
        },
        vendorId: Vendors.IMAGE_ASSURANCE
    }),[]);
    
    const steps:WizardStep[] = useMemo(() => [
        {
            name: t('GENERAL.PLATFORM_SELECTION'),
            renderView: (onValidate:RenderViewParams) => {
                return <OUSelectionComponent preSelectedItems={selectedOUs} selectedOUsChanged={selectedOUsChanged} {...onValidate } />;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            name: t('GENERAL.RULESET_SELECTION'),
            renderView: (onValidate:RenderViewParams) => {
                return <RulesetMultiSelectionComponent vendorRulesets={[rulesetProvider]} preSelectedItems={selectedRulesets} {...onValidate } selectedRulesetsChanged={setSelectedRulesets}/>;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            name: t('GENERAL.IMAGE_ADMISSION_SELECTION'),
            renderView: (onValidate:RenderViewParams) => {
                return <ImageAdmissionSelectionComponent {...onValidate}
                    preSelectedNonCompliant={selectedNonCompliantAdmission} 
                    preSelectedUnScanned={selectedUnScannedAdmission}
                    selectedNonCompliantItemChanged={setSelectedNonCompliantAdmission}
                    selectedUnScannedChanged={setSelectedUnScannedAdmission} />;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            name: t('GENERAL.NOTIFICATION_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <NotificationsSelectionComponent preSelectedItems={selectedNotifications} {...renderViewParams } selectedNotificationsChanged={setSelectedNotifications}/>;
            },
            onNext: async ()=>{
                saveChanges();
            },
        },
        {
            name: t('GENERAL.DONE'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <Done message={flowStatusMessage} {...renderViewParams}></Done>;
            },
            onNext: async ()=>{
                finishFlow(returnUrl);
            },
        }
    ],[flowStatusMessage, returnUrl, rulesetProvider, saveChanges, selectedNonCompliantAdmission, selectedNotifications, selectedOUs, selectedRulesets, selectedUnScannedAdmission, t]);

    return (
        <Wizard
            onClose={()=>finishFlow(returnUrl)}
            steps={steps}
            title={t('GENERAL.ADD_POLICY')}
            doneButtonText={t('GENERAL.DONE')}
            ShowBackButtonInLastStep={false}
        />
    );
};
