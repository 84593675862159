import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWorkloadImageScan } from 'modules/workloads/services/workload/hooks/useWorkloadImageScan';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { WorkloadRequestImageScanProps } from './WorkloadRequestImageScan.types';
import { useWorkloadEnvImagesScan } from '../../hooks/useWorkloadEnvImagesScan';
import { ImageAssuraceScanEnvironment, ImageAssuraceScanEnvironmentScanAction } from '../../workload.interface';
import { Button } from 'common/design-system/components-v2';

const WorkloadRequestImageScan: React.FC<WorkloadRequestImageScanProps> = props => {
    const { environmentId, imageIdOrDigest, scanAction = ImageAssuraceScanEnvironmentScanAction.Failed } = props;
    const { t } = useTranslation(getK8sNamespace('common'));

    // If imageIdOrDigest is provided, it means that we are in the image scan, otherwise we are in the env scan
    const isSingleImageScan = !!imageIdOrDigest;

    const tPrefix = isSingleImageScan ? 'workloadImageScan.imageScan' : 'workloadImageScan.envScan';

    const handleOnRescanSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t(`${tPrefix}.successMessage.title`),
            text: t(`${tPrefix}.successMessage.body`),
        });
        //  This is to fix the status code issue, the status code is 200 but the scan is not finished yet
        setTimeout(() => {
            props.successCallback && props.successCallback();
        }, 3000);
    };

    const handleOnRescanEnvSuccess = (response: ImageAssuraceScanEnvironment.Response) => {
        const noneImagesToScan = response.imagesToScanCount === 0;
        const messagePrefix = noneImagesToScan ? 'emptyMessage' : 'successMessage';
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t(`${tPrefix}.${messagePrefix}.title`),
            text: t(`${tPrefix}.${messagePrefix}.body`, { imagesToScanCount: response.imagesToScanCount }),
        });
        //  This is to fix the status code issue, the status code is 200 but the scan is not finished yet
        setTimeout(() => {
            props.successCallback && props.successCallback();
        }, 3000);
    };

    const handleOnRescanError = (error: { message: string; }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t(`${tPrefix}.errorMessage.title`),
            text: error.message || t(`${tPrefix}.errorMessage.body`),
        });
        props.failureCallback && props.failureCallback(error.message);
    };

    const { isLoading: isScanRequestLoading, scanImage } = useWorkloadImageScan({ onSuccess: handleOnRescanSuccess, onError: handleOnRescanError });
    const { isLoading: isScanEnvRequestLoading, scanEnvImages } = useWorkloadEnvImagesScan({ onSuccess: handleOnRescanEnvSuccess, onError: handleOnRescanError });

    const handleOnScanClick = () => {
        props.requestSentCallback && props.requestSentCallback();
        if (isSingleImageScan) {
            return scanImage({ environmentId, imageIdOrDigest });
        }
        return scanEnvImages({ environmentId, scanAction });
    };

    return (
        <Button iconProps={{ name: 'riskManagement4' }} variant='text' tooltip={t(`${tPrefix}.buttonTextTooltip`)} onClick={handleOnScanClick} loading={isScanRequestLoading || isScanEnvRequestLoading}>
            {t(`${tPrefix}.buttonText`)}
        </Button>
    );
};

export default WorkloadRequestImageScan;

