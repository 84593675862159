import {
    GetCveInfo,
    GetTopCves,
    GslRun,
    GslRunRequest,
    SearchVulnerabilitiesByCveId,
    Aggregations,
    GetAffectedAssetAggregation,
    GetExportCSV,
    GslQueryFilters,
} from './cve.interface';
import GslQuery from 'modules/workloads/pages/cveSearch/CveTable/GslQuery';
import { getHttpService } from 'common/interface/services';
import { AxiosResponse } from 'axios';

const gslRun = async <T>(request: GslRun.Request, timeout?: number, useCache = true): GslRun.Response<T> => {
    return await getHttpService().request<AxiosResponse<T>>(
        'gslws/gsl-run?action=fetch',
        { method: 'POST', data: request, timeout, timeoutErrorMessage: 'GSL query timed out' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache, dataAgeLimit: 300 } },
        err => { throw err; }
    );
};

type ReducedFilters = {
    [filterName: string]: Array<string>
};

const reduceFilters = (filters: GslQueryFilters): ReducedFilters => {
    return filters.reduce((acc: ReducedFilters, filter) => {
        if (!acc[filter.name]) {
            acc[filter.name] = [];
        }
        acc[filter.name].push(filter.value);
        return acc;
    }, {});
};

const addFiltersToGslQuery = ({ gslQuery, filters }: { gslQuery: GslQuery, filters: GslQueryFilters }) => {
    const reducedFilters = reduceFilters(filters);

    Object.entries(reducedFilters).forEach(([filterName, filterValues]) => {
        const queryConditions: string[] = [];
        filterValues.forEach((filterValue) => {
            let queryPart = '';
            
            if (filterName === 'scan.producer') {
                if (filterValue === 'Inspector') {
                    queryPart = 'scan.producer = \'Inspector\'';
                } else {
                    queryPart = 'scan.producer != \'Inspector\'';
                }
            } else {
                queryPart = `${filterName} = '${filterValue}'`;
            }

            queryConditions.push(queryPart);
        });

        if (queryConditions.length > 0) {
            gslQuery.bulkOr(queryConditions);
        }
    });
};

const searchVulnerabilitiesByCveId: SearchVulnerabilitiesByCveId.Function = async ({ cveId, filters }, useCache = true) => {
    const selectFields = [
        'cloudAccountId',
        'cve.id', 'cve.vectorString', 'cve.severityId', 'cve.severity', 'cve.isFixable',
        'affectedAsset.externalId', 'affectedAsset.entityName', 'affectedAsset.entityType', 'affectedAsset.region', 'affectedAsset.dome9Id',
        'scannedAsset.externalId', 'scannedAsset.entityName', 'scannedAsset.entityType', 'scannedAsset.region', 'scannedAsset.dome9Id',
        'package.id', 'package.version', 'package.path', 'package.name',
        'scan.producer'
    ];

    const gslQuery = new GslQuery()
        .from('vulnerability')
        .where(`(cve.id = '${cveId}' or cveInfo has_any('${cveId}'))`)
        .select(...selectFields);
        
    if (filters && filters.length > 0) {
        addFiltersToGslQuery({ gslQuery, filters });
    }

    const request: GslRunRequest = {
        gsl: gslQuery.build(),
        options: {
            source: 'vulnerability',
            limit: 10000,
            decorators: ['ct-raw-data-decorator'],
        }
    };

    const timeout = 1000 * 60;

    return await gslRun<SearchVulnerabilitiesByCveId.Response>(request, timeout, useCache);
};

const getCveInfo: GetCveInfo.Function = async ({
    cveId,
    cloudAccountId,
    scannedAssetExternalId,
    affectedAssetExternalId,
    packagePath,
    packageId,
    vectorString,
}) => {
    const gslQuery = new GslQuery()
        .from('vulnerability')
        .where(`(cve.id = '${cveId}' or cveInfo has_any('${cveId}'))`)
        .where(`cloudAccountId = '${cloudAccountId}'`)
        .where(`scannedAsset.externalId = '${scannedAssetExternalId}'`)
        .where(`affectedAsset.externalId = '${affectedAssetExternalId}'`)
        .where(`package.id = '${packageId}'`)
        .where(`package.path = '${packagePath}'`)
        .where(`cve.vectorString = '${vectorString}'`)
        .build({ stringify: true });
        
    const request: GslRunRequest = {
        gsl: gslQuery,
        options: {
            source: 'vulnerability',
            limit: 1,
            decorators: ['ct-raw-data-decorator'],
        }
    };

    return await gslRun<GetCveInfo.Response>(request);
};

const getTopCves: GetTopCves.Function = async () => {
    const request: GslRunRequest = {
        gsl: 'vulnerability where cve.severityId = 5',
        options: {
            source: 'vulnerability',
            limit: 100,
            decorators: ['ct-raw-data-decorator'],
        }
    };

    return await gslRun<GetTopCves.Response>(request);
};

const getFilterAggregations: GetAffectedAssetAggregation.Function = async (cveId: string) => {
    const aggregationFields = ['cve.id', 'affectedAsset.entityType', 'scannedAsset.entityType', 'cve.isFixable', 'scan.producer', 'cve.severity'];
    const aggregationGslQueryTemplate = (field: string) =>
        `vulnerability where (cve.id = '${cveId}' or cveInfo has_any('${cveId}')) summarize count() as Count by ${field}`;
    interface AssetAggregationsResponse {
        cols: {
            [key: string]: number;
            Count: number;
        };
        data: [any, any, any, number][];
    }

    const getAffectedAssetAggregation = async (assetField: 'affectedAsset' | 'scannedAsset') => {
        const gslQuery = new GslQuery()
            .from('vulnerability')
            .where(`(cve.id = '${cveId}' or cveInfo has_any('${cveId}')) summarize count() as Count by ${assetField}.externalId, ${assetField}.entityName, ${assetField}.entityType`);

        const request: GslRunRequest = {
            gsl: gslQuery.build(),
            options: {
                source: 'vulnerability',
            }
        };

        return await gslRun<AssetAggregationsResponse>(request);
    };

    const getEnvironmentAggregation = async () => {
        const gslQuery = new GslQuery()
            .from('vulnerability')
            .where(`(cve.id = '${cveId}' or cveInfo has_any('${cveId}')) summarize count() as Count by cloudAccountId, cloudAccountName, platform`);


        const request: GslRunRequest = {
            gsl: gslQuery.build(),
            options: {
                source: 'vulnerability',
            }
        };

        return await gslRun<AssetAggregationsResponse>(request);
    };

    const getPackageAggregation = async () => {
        const gslQuery = new GslQuery()
            .from('vulnerability')
            .where(`(cve.id = '${cveId}' or cveInfo has_any('${cveId}')) summarize count() as Count by package.id, package.name`);

        const request: GslRunRequest = {
            gsl: gslQuery.build(),
            options: {
                source: 'vulnerability',
            }
        };

        return await gslRun<AssetAggregationsResponse>(request);
    };

    const aggregationGslQuery = (field: string) => ({
        gsl: aggregationGslQueryTemplate(field),
        options: {
            source: 'vulnerability',
        }
    });

    interface AggregationsResponse {
        cols: {
            [key: string]: number;
            Count: number;
        };
        data: [any, number][];
    }
    
    const aggregationPromises = aggregationFields.map(field => {
        const request = aggregationGslQuery(field);
        return gslRun<AggregationsResponse>(request);
    });

    const aggregationResponses = await Promise.all([
        getAffectedAssetAggregation('affectedAsset'),
        getAffectedAssetAggregation('scannedAsset'),
        getEnvironmentAggregation(),
        getPackageAggregation(),
        ...aggregationPromises,
    ]);

    const [
        affectedAssetAggregation,
        scannedAssetAggregation,
        environmentAggregation,
        packageAggregation,
        ...restAggregationResponses
    ] = aggregationResponses;

    const aggregations: Aggregations = {};

    aggregationFields.forEach((field, index) => {
        restAggregationResponses[index].data.data.forEach(([value, count]) => {
            if (!aggregations[field]) {
                aggregations[field] = [];
            }
            aggregations[field].push({ value, count });
        });
    });

    const mapAssetAggregationData = (aggregationResponse: AxiosResponse<AssetAggregationsResponse, any>) => (
        aggregationResponse.data.data.map(([externalId, entityName, entityType, count]) => ({ value: externalId, count, additionalFields: { externalId, entityName, entityType } }))
    );

    const mapEnvironmentAggregationData = (aggregationResponse: AxiosResponse<AssetAggregationsResponse, any>) => (
        aggregationResponse.data.data.map(([cloudAccountId, cloudAccountName, platform, count]) => ({ value: cloudAccountId, count, environmentFields: { cloudAccountId, cloudAccountName, platform } }))
    );

    const mapPackageAggregationData = (aggregationResponse: AxiosResponse<AssetAggregationsResponse, any>) => (
        aggregationResponse.data.data.map(([packageId, packageName, count]) => ({ value: packageId, count, packageFields: { packageName } }))
    );

    aggregations['affectedAsset.externalId'] = mapAssetAggregationData(affectedAssetAggregation);
    aggregations['scannedAsset.externalId'] = mapAssetAggregationData(scannedAssetAggregation);
    aggregations['cloudAccountId'] = mapEnvironmentAggregationData(environmentAggregation);
    aggregations['package.id'] = mapPackageAggregationData(packageAggregation);
    
    return aggregations;
};

const getExportCsv: GetExportCSV.Function = async ({ cveId, filters, activeColumns }) => {
    const gslQuery = new GslQuery()
        .from('vulnerability')
        .where(`(cve.id = '${cveId}' or cveInfo has_any('${cveId}'))`);

    if (activeColumns && activeColumns.length > 0) {
        gslQuery.select(...activeColumns);
    }
        
    if (filters && filters.length > 0) {
        addFiltersToGslQuery({ gslQuery, filters });
    }

    const request: GslRunRequest = {
        gsl: gslQuery.build(),
        options: {
            source: 'vulnerability',
            limit: 10000,
            decorators: ['csv-data-decorator'],
        }
    };

    return await gslRun<GetExportCSV.Response>(request);
};


type GslQueryJson = {
    from: string,
    where?: Array<string>,
    or?: Array<string>,
}

const buildGslQueryFromJSON = (gslQueryJson: GslQueryJson) => {
    const query = new GslQuery().from(gslQueryJson.from);

    if (gslQueryJson.where && gslQueryJson?.where?.length > 0) {
        gslQueryJson.where.forEach((whereCondition) => {
            query.where(whereCondition);
        });
    }
    
    return query.build();
};

const CveService = {
    gslRun,
    getTopCves,
    searchVulnerabilitiesByCveId,
    getCveInfo,
    buildGslQueryFromJSON,
    getFilterAggregations,
    getExportCsv,
};

export default CveService;
