import { getNotificationsService, getHttpService } from 'common/interface/services';
import { AxiosResponse } from 'axios';
import { NotificationType } from 'common/interface/notifications';

const LoggerObject = () => ({
    log: 'log',
    tags: [],
    browserMetadata: (window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'NA'),
    targetUrl: (window.location && window.location.href ? window.location.href : 'NA'),
    screenResolution: (window.screen && window.screen.width ? window.screen.width : 'NA') + 'x' + (window.screen && window.screen.height ? window.screen.height : 'NA'),
    browserResolution: (window.innerWidth ? window.innerWidth : 'NA') + 'x' + (window.innerHeight ? window.innerHeight : 'NA'),
    language: (window.navigator && window.navigator.language ? window.navigator.language : 'NA'),
    clientTimeStamp: new Date().toUTCString(),
});


export type Log = {
    description: string,
    toasterMessageOnSuccess?: string,
    toasterMessageOnError?: string,
    reportType: string,
    withToaster?: boolean
}

type UseLogger = (log: Log) => Promise<boolean>

export const sendLogger: UseLogger = (
    {
        description,
        toasterMessageOnSuccess,
        toasterMessageOnError,
        reportType,
        withToaster
    }
) => {

    const message = {
        reportType: reportType,
        description: description,
        v: '1.0.1'
    };

    const sendLogger = async (): Promise<boolean> => {
        try {
            await getHttpService().request<AxiosResponse>(
                'LoggerService',
                {
                    method: 'POST',
                    data: {
                        ...LoggerObject(),
                        message: JSON.stringify(message),
                    },
                },
                { returnAsAxiosResponse: true },
                (error) => {
                    throw error;
                },
            );

            withToaster && getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                title: toasterMessageOnSuccess,
                text: '',
            });

            return true;
        } catch (error) {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: toasterMessageOnError,
                text: '',
            });
            
            return false;
        }
    };

    return sendLogger();
};