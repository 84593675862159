import { TextField } from '@dome9/berries/react-components';
import styled from 'styled-components';

export const KOBTextFieldWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
`;

export const EditIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, 0.07);
    }
`;

export const TextField_ = styled(TextField)<{ error: boolean; }>`
    & .text-field__body {
        border-color: ${props => props.error ? 'rgb(242, 80, 80) !important' : ''};
    }
`;