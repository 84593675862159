import { Stack, Typography, Command } from 'common/design-system/components-v2';
import React, { useMemo } from 'react';
import InstructionStep from 'modules/workloads/components/InstructionStep/InstructionStep';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import updateStackOldversionImage from '../../../assets/images/update-stack-old-version.png';
import { CommandWrapper, UpdateStackOldversionImage } from './awpUpdateStackOldVersion.styled';
import { AwpStackUpdateProps } from '../awpUpdate/awpUpdate.types';
import ValidateButton from '../awpUpdate/components/validateButton';
import { AwpUpdateStackWrapper } from '../awpUpdate/awpUpdate.styled';

const AwpUpdateStackOldVersion: React.FC<AwpStackUpdateProps> = ({ cloudAccountId, url }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));
    const region = useMemo(() => url.split('.')[2], [url]);

    const instructionSteps = useMemo<Array<{ content: React.ReactElement, placement?: any }>>(() => [
        {
            content: <Typography key='step-1'>{t('awpUpdateStack.oldVersionInstructions.step1')} <b>{region}</b></Typography>
        },
        {
            placement: 'baseline',
            content: (
                <Stack key='step-2' spacing={2}>
                    <CommandWrapper direction='column' spacing={2}>
                        <Typography>{t('awpUpdateStack.oldVersionInstructions.step2')}</Typography>
                        <Command text={url} />
                    </CommandWrapper>
                    <UpdateStackOldversionImage src={updateStackOldversionImage} alt=''/>
                </Stack>
            )
        },
        {
            content: <Typography key='step-3'>{t('awpUpdateStack.oldVersionInstructions.step3')}</Typography>
        },
        {
            content: <Typography key='step-4'>{t('awpUpdateStack.oldVersionInstructions.step4')}</Typography>
        }
    ], [region, t, url]);

    return (
        <AwpUpdateStackWrapper padding={[5, 4, 4, 8]} spacing={4}>
            <Stack spacing={2}> 
                <Typography variant='h2'>{t('awpUpdateStack.header.updateAwpCloudFormationStack')}</Typography>
                <Typography variant='subtitleLg'>{t('awpUpdateStack.header.YouNeedToUpdate')}</Typography>
            </Stack>
            <Stack spacing={2} padding={[0, 0, 0, 3]}>
                {instructionSteps.map(({ content, placement }, index) => 
                    <InstructionStep key={`step-${index + 1}`} stepNumber={index + 1} alignItems={placement}>
                        {content}
                    </InstructionStep>
                )}
            </Stack>
            <ValidateButton cloudAccountId={cloudAccountId} />
        </AwpUpdateStackWrapper>
    );
};

export default AwpUpdateStackOldVersion;