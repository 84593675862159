import React, { FunctionComponent, useEffect } from 'react';
import {
    CpCommonButton,
    CpTextButton,
    CpWizard,
    CpWizardBody,
    CpWizardFooter,
    CpWizardHeader,
    CpWizardStep,
    CpLoadingDots,
    CpIcon,
} from '@dome9/components/react/components';
import './RegistryOnboarding.scss';
import { goToStep, exitOnBoardingWizard } from '../../K8sRegistry.utils';
import { clearRegistryOnboardingState } from '../../../reducers/registry';
import { canGoBack, canGoForward, getStep, getIsLoading, getScanningEnvironmentType } from '../../../reducers/registry/registry.slice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ClusterConfigurations from './ClusterConfigurations';
import RegistryInstructions from './RegistryInstructions';
import RegistrySummary from './RegistrySummary';
import { getStoreService } from 'common/interface/services';
import { ScanningEnvironmentType } from 'modules/workloads/utils/RegistryOptions/RegistryOptions';
import AwsConfigurations from './AwsConfiguration/AwsConfiguration';
import RegistryConfigurations from './RegistryConfigurations';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';

interface WizardBody extends HTMLElement {
    updateSteps: Function;
}

const RegistryOnboarding: FunctionComponent = () => {
    const dispatch = getStoreService().dispatch;
    const { t } = useTranslation(['k8s_registry', 'k8s_common']);
    const canGoStepForward = useSelector(canGoForward);
    const canGoStepBack = useSelector(canGoBack);
    const currentStep = useSelector(getStep);
    const isLoading = useSelector(getIsLoading);
    const scanningEnvironmentType = useSelector(getScanningEnvironmentType);
    const bodyRef = React.useRef<WizardBody>();
    useBreadcrumbsLastChildren([t('k8s_registry:ON_BOARDING.containerRegistryOnboardingBreadcrumb')]);

    const rerenderBody = async () => {
        const wizardBody = bodyRef.current;
        if (wizardBody) await wizardBody.updateSteps();
    };

    useEffect(() => {
        rerenderBody();
    }, [scanningEnvironmentType]);

    useEffect(() => {
        return () => {
            dispatch(clearRegistryOnboardingState());
        };
    }, [dispatch]);

    const handleExitOnBoardingWizard = async () => {
        await exitOnBoardingWizard();
    };
    
    return (
        <div className='k8s-registry-wrapper'>
            <CpWizard current-step={currentStep}>
                <CpWizardHeader>
                    {t('k8s_registry:ON_BOARDING.TITLE')}
                    <div slot='actions' onClick={handleExitOnBoardingWizard} className='aws-onboarding-content-close'>
                        <CpIcon icon='close' />
                    </div>
                </CpWizardHeader>
                <CpWizardBody ref={bodyRef}>
                    <CpWizardStep
                        header={t('k8s_registry:ON_BOARDING.REGISTRY_CONFIGURATIONS')}
                        key={t('k8s_registry:ON_BOARDING.REGISTRY_CONFIGURATIONS')}>
                        <RegistryConfigurations />
                    </CpWizardStep>
                    {scanningEnvironmentType === ScanningEnvironmentType.Kubernetes && (
                        <>
                            <CpWizardStep
                                header={t('k8s_registry:ON_BOARDING.CLUSTER_CONFIGURATIONS')}
                                key={t('k8s_registry:ON_BOARDING.CLUSTER_CONFIGURATIONS')}>
                                <ClusterConfigurations />
                            </CpWizardStep>
                            <CpWizardStep
                                header={t('k8s_registry:ON_BOARDING.INSTRUCTIONS')}
                                key={t('k8s_registry:ON_BOARDING.INSTRUCTIONS')}>
                                <RegistryInstructions />
                            </CpWizardStep>
                            <CpWizardStep
                                header={t('k8s_registry:ON_BOARDING.ONBOARDING_SUMMARY')}
                                key={t('k8s_registry:ON_BOARDING.ONBOARDING_SUMMARY')}>
                                <RegistrySummary />
                            </CpWizardStep>
                        </>
                    )}
                    {scanningEnvironmentType === ScanningEnvironmentType.AWS && (
                        <>
                            <CpWizardStep
                                header={t('k8s_registry:ON_BOARDING.CLUSTER_CONFIGURATIONS')}
                                key={t('k8s_registry:ON_BOARDING.CLUSTER_CONFIGURATIONS')}>
                                <ClusterConfigurations />
                            </CpWizardStep>
                            <CpWizardStep
                                header={t('k8s_registry:ON_BOARDING.AWS_CONFIGURATION')}
                                key={t('k8s_registry:ON_BOARDING.AWS_CONFIGURATION')}>
                                <AwsConfigurations />
                            </CpWizardStep>
                        </>
                    )}
                </CpWizardBody>
                <CpWizardFooter>
                    {canGoStepBack && (
                        <CpTextButton
                            onClick={() => {
                                dispatch(goToStep(false));
                            }}
                            id='wizard-back-button'>
                            {t('k8s_common:BACK')}
                        </CpTextButton>
                    )}
                    {canGoStepForward ? (
                        <CpCommonButton
                            onClick={() => {
                                dispatch(goToStep(true));
                            }}
                            id='wizard-next-button'>
                            {t('k8s_common:NEXT')}
                        </CpCommonButton>
                    ) : (
                        <CpCommonButton
                            onClick={() => {
                                dispatch(goToStep(true));
                            }}>
                            {t('k8s_common:FINISH')}
                        </CpCommonButton>
                    )}
                </CpWizardFooter>
            </CpWizard>
            {isLoading ? <CpLoadingDots></CpLoadingDots> : <div />}
        </div>
    );
};
export default RegistryOnboarding;