import React from 'react';
import WorkloadService from '../workload.service';
import { RuntimeProtectionAccountRules } from '../workload.interface';

export interface UseWorkloadRPAccountRulesReq {
    onError?: (error: { message: string; }) => void;
    onSuccess?: (response: RuntimeProtectionAccountRules.Response) => void;
}

export interface UseWorkloadRPAccountRulesPayload {
    isLoading: boolean;
    isRequestSuccess: boolean;
    requestResponse?: RuntimeProtectionAccountRules.Response;
    isError?: { message: string; };
    updateRules: (vendor: string, environmentId: string, request: RuntimeProtectionAccountRules.Request) => Promise<void>;
    reset?: () => void;
}

export type UseWorkloadRPAccountRules = (req?: UseWorkloadRPAccountRulesReq) => UseWorkloadRPAccountRulesPayload;

export const useWorkloadRPAccountRules: UseWorkloadRPAccountRules = ({ onSuccess, onError } = {}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = React.useState(false);
    const [requestResponse, setRequestResponse] = React.useState<RuntimeProtectionAccountRules.Response>();
    const [isError, setIsError] = React.useState<{ message: string; }>();

    const updateRules = React.useCallback(async (vendor: string, environmentId: string, request: RuntimeProtectionAccountRules.Request) => {
        setIsLoading(true);
        try {
            const result = await WorkloadService.runtimeProtectionAccountRules(vendor, environmentId, { request });
            setIsRequestSuccess(true);
            setRequestResponse(result.data);
            onSuccess?.(result.data);
        } catch (error: any) {
            setIsError({ message: error.message });
            onError?.({ message: error.message });
        }
        setIsLoading(false);
    }, [onSuccess, onError]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsRequestSuccess(false);
        setIsError(undefined);
    }, []);

    return ({
        isLoading,
        isRequestSuccess,
        requestResponse,
        isError,
        updateRules,
        reset
    });
};