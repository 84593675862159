import { Command } from 'common/design-system/components-v2';
import styled from 'styled-components/macro';

export const LoaderWrapper = styled.div`
    height: calc(100vh - 71px);
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%; 
    position: absolute;
    z-index: 100000000;
    background-color: white;
`;

export const CustomCommand = styled(Command)`
    min-height: 102px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 8px;
    justify-content: flex-end;
`;