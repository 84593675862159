import { ALink } from 'common/components/ALink';
import { Stack, Typography } from 'common/design-system/components-v2';
import React from 'react';
import { AwpOnboardingWrapper, ButtonsWrapper, LoaderWrapper } from './AwpOnboardingPage.styled';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import { Trans, useTranslation } from 'react-i18next';
import { getCheckpointUrl } from 'common/components/Forms/urls';
import { AwpOnboardingPageProps } from './AwpOnboardingPage.interface';

const AwpOnboardingPage: React.FC<AwpOnboardingPageProps> = ({ isLoading, actions, children, bulletPointTransKey }) => {
    const { t } = useTranslation('k8s_awp');

    if (isLoading) {
        return (
            <LoaderWrapper>
                <CloudAnimationLoader size='medium' />
            </LoaderWrapper>
        );
    }

    return (
        <AwpOnboardingWrapper padding={[5, 4, 4, 8]} spacing={4}>
            <Stack spacing={2}>
                <Typography variant='h2'>{t('awpOnboarding.header.startProtectingYourVMInstances')}</Typography>
                <Typography variant='subtitleLg'>{t('awpOnboarding.header.inOnlyFewSteps')}</Typography>
                <Typography variant='subtitleLg'>
                    <ALink
                        href={getCheckpointUrl('AGENTLESS_WORKLOAD_POSTURE')}
                        target='_blank'
                        rel='noreferrer'>
                        {t('awpOnboarding.header.whatIsAwp')}
                    </ALink>
                </Typography>
                {bulletPointTransKey && (
                    <Trans
                        i18nKey={`k8s_awp:${bulletPointTransKey}`}
                        components={{
                            stack: <Stack>.</Stack>,
                            text: <Typography>.</Typography>,
                            li: <li />,
                            a: <ALink></ALink>
                        }}
                    />
                )}
            </Stack>
            {children}
            {actions && 
                <ButtonsWrapper>
                    {actions}
                </ButtonsWrapper>
            }
        </AwpOnboardingWrapper>
    );
};

export default AwpOnboardingPage;
