import styled from 'styled-components';

export const KOBCommandsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #ffffff;
    padding: 15px;
    border: 1px solid #e8e9eb;
`;

export const KOBCommandsHelmSection = styled.div`
    border-radius: 8px;
    border: 1px solid black;
    background: #2f405d;
    padding: 12px 40px 12px 12px;
    position: relative;
    display: flex;
    gap: 8px;
    width: fit-content;
`;

export const KOBCommandsLabel = styled.p`
    color: white;
    font-size: 14px;
    word-break: break-all;
`;

export const KOBCommandsCopyButton = styled.button`
    position: absolute;
    display: flex;
    right: 20px;
    top: 16px;
    border: unset;
    background: unset;

    & svg {
        fill: white;
    }
`;