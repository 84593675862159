import ClusterSlice from './cluster.slice';
export { ClusterSlice };

export const {
    setClusters,
    setIsLoadingClusters,
    setSelectedCluster,
    setSelectedClusterErrorStatus,
    setSelectedServiceAccount,
    setNewCluster,
    setClusterName,
    setClusterKey,
    setClusterSecret,
    setClusterNamespace,
    toggleClusterImageAssurance,
    toggleClusterAdmissionControl,
    toggleClusterRuntimeProtection,
    toggleClusterRuntimeProfilingProtection,
    toggleClusterThreatIntelligence,
    setClusterKeyErrorStatus,
    setClusterSecretErrorStatus,
    setClusterNamespaceErrorStatus,
    setKubernetesAccountSummary,
    setKubernetesAgentSummary,
    setAllKubernetesAccount,
    setKubernetesAccount
} = ClusterSlice.actions;

export default ClusterSlice.reducer;
