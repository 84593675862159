import React from 'react';
import { Button, Stack, Typography } from 'common/design-system/components-v2';
import AwpOnboardingPage from '../components/AwpOnboardingPage/AwpOnboardingPage';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';
import { ScanMode } from 'modules/workloads/services/awp/awp.interface';
import ScanModeSelector from '../components/ScanModeSelector/ScanModeSelector';
import { awsScanModeCardSections } from '../components/ScanModeSelector/scanModeSelectors.consts';
import { InstructionsWrapper, VerticalDivider } from '../components/Onboarding.styled';
import { useForm } from 'react-hook-form';
import InstructionStep from 'modules/workloads/components/InstructionStep/InstructionStep';
import { ButtonsWrapper } from '../components/AwpOnboardingPage/AwpOnboardingPage.styled';
import { getMissingFields, onCancel } from './awpOnboardingAws.utils';
import { CloudFormParams, StepMeta } from './awpOnboardingAws.types';
import SelectHubStep from './components/SelectHubStep';
import CreateCrossStackStep from './components/CreateCrossStackStep';
import RunStackStep from './components/RunStackStep';
import useInitialValues from './hooks/useInitialValues';
import useEnableAgentless from './hooks/useEnableAgentless';

const AwpOnboardingAws: React.FC = () => {
    const { t } = useTranslation(getK8sNamespace('awp'));
    const [selectedScanMode, setSelectedScanMode] = React.useState<ScanMode>(ScanMode.InAccount);
    const initialValues = useInitialValues();
    const useFromProps = useForm<CloudFormParams>({
        mode: 'onChange',
        defaultValues: initialValues,
    });
    const { isPending, enableAgentlessAwsAccount } = useEnableAgentless();
    const hasMissingFields = React.useMemo(() => getMissingFields({
        scanMode: selectedScanMode,
        isHubIdDirty: Boolean(useFromProps.formState.dirtyFields.hubId),
        crossAccountRoleNameInvalid: Boolean(useFromProps.formState.errors.crossAccountRoleName),
        crossAccountExternalIdInvalid: Boolean(useFromProps.formState.errors.crossAccountExternalId),
        stackNameInvalid: Boolean(useFromProps.formState.errors.stackName),
    }).length > 0, [selectedScanMode, useFromProps.formState.dirtyFields.hubId, useFromProps.formState.errors.crossAccountExternalId, useFromProps.formState.errors.crossAccountRoleName, useFromProps.formState.errors.stackName]);

    const scanModeSections = awsScanModeCardSections.map(section => ({
        title: t(section.title),
        cards: section.cards.filter(card => card?.isRelevant?.() ?? true).map(card => ({
            ...card,
            label: t(card.label),
            ...(card.description ? { description: t(card.description) } : {})
        })),
    })).filter(section => section.cards.length);
    const steps: StepMeta[] = [
        {
            StepComponent: SelectHubStep,
            id: 'select-hub',
            hide: (scanMode) => scanMode !== ScanMode.InAccountSub,
        },
        {
            StepComponent: CreateCrossStackStep,
            alignItems: 'baseline',
            id: 'create-cross-stack',
        },
        {
            StepComponent: RunStackStep,
            id: 'run-stack-on-aws',
        }
    ];

    return (
        <AwpOnboardingPage isLoading={false} bulletPointTransKey='awpOnboarding.header.awsPoints'>
            <Stack direction='row' spacing={10}>
                <InstructionStep stepNumber={1}>
                    <Stack spacing={3}>
                        <Typography>{t('awpOnboarding.instructions.scanModeStep.chooseScanMode')}</Typography>
                        <ScanModeSelector scanModeSections={scanModeSections} selectedScanMode={selectedScanMode} onSelectScanMode={setSelectedScanMode} />
                    </Stack>
                </InstructionStep>
                <VerticalDivider /> 
                <InstructionsWrapper spacing={2}>
                    {steps.filter(step => step?.hide ? !step.hide(selectedScanMode) : true).map(({ StepComponent, alignItems, id }, index) => {
                        return (
                            <InstructionStep stepNumber={index+2} key={`step-${id}`} alignItems={alignItems}>
                                <StepComponent formProps={useFromProps} scanMode={selectedScanMode} />
                            </InstructionStep>
                        );
                    })}
                    <ButtonsWrapper>
                        <Button variant='text' onClick={onCancel}>{t('awpOnboarding.cancel')}</Button>
                        <Button onClick={() => {
                            useFromProps.handleSubmit(async (data) => {
                                await enableAgentlessAwsAccount(data, selectedScanMode);
                            })();
                        }} disabled={hasMissingFields || isPending} loading={isPending} color='brandPrimary'>{t('awpOnboarding.enableAwp')}</Button>
                    </ButtonsWrapper>
                </InstructionsWrapper>
            </Stack>
        </AwpOnboardingPage>
    );
};

export default AwpOnboardingAws;