import axios from 'axios';
import { getHttpService, getStoreService } from 'common/interface/services';
import { getCsrf } from 'common/utils/http';

export const addKubernetesFlowlogsAccount = async (accountId: any, k8sAccountIds: any): Promise<any> => {
    const state = getStoreService().state;
    const magellanUrl = state.app.magellanUrl;
    const config = {
        withCredentials: true,
        headers: {
            csrf: getCsrf(),
        },
    };

    const data = { accountId, k8sAccountIds: [k8sAccountIds] };
    return await axios.post(`${magellanUrl}/onboarding/add-kubernetes-flowlogs-account`, data, config);
};

export const enableMagellanKubernetes = async (id: any): Promise<any> => {
    const data = [id];
    return await getHttpService().request('view/magellan/enable-magellan-kubernetes', { method: 'POST', data });
};
