import React, { useEffect, useState } from 'react';
import { KubernetesAlertType } from '../../KubernetesEnvironment.types';
import { Alert, AlertType } from '@dome9/berries/react-components';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { getCheckpointUrl } from 'common/components/Forms/urls';
import { Cluster } from 'modules/workloads/reducers/cluster-new/cluster.interface';
import i18n from 'common/services/translations/translations';
import { doubleOnboardingErrorMessagePrefix } from '../../KubernetesEnvironment.consts';
import { ALink } from 'common/components/ALink';
import { A } from 'common/components/Anchor/Anchor';
import styled from 'styled-components';

export const ALinkWithUnderline = styled(A)`
  ${ALink}
`;


export interface AlertsProps {
    clusterData?: Cluster
}

type AlertsConfig = {
    [key in KubernetesAlertType]: {
        type: AlertType,
        text: string,
        linkHref: string,
        linkText: string,
    };
};

const getAlertsConfigs = (): AlertsConfig => ({
    'double-onboarding': {
        type: AlertType.ERROR,
        text: i18n.t(`${getK8sNamespace('cluster')}:environment.alerts.doubleOnboarding.text`),
        linkHref: getCheckpointUrl('DOUBLE_ONBOARDING_DOCS'),
        linkText: i18n.t(`${getK8sNamespace('cluster')}:environment.alerts.doubleOnboarding.linkText`)
    }
});

const AlertClusters: React.FC<AlertsProps> = ({ clusterData }) => {
    const [alert, setAlert] = useState<KubernetesAlertType | null>(null);
    const { summary: summaryData } = clusterData || {};
    const alerts = getAlertsConfigs();

    useEffect(() => {
        const doubleOnboardingError = summaryData?.agentsStatusDescription.startsWith(doubleOnboardingErrorMessagePrefix);

        if (doubleOnboardingError) {
            setAlert(KubernetesAlertType.DoubleOnboarding);
        }
    }, [summaryData?.agentsStatusDescription]);

    const closeAlert = () => setAlert(null);

    return alert ? (
        <div data-aid='alerts-component'>
            <Alert
                type={alerts[alert].type}
                onClose={closeAlert}
            >
                <p>{alerts[alert].text}<ALinkWithUnderline href={alerts[alert].linkHref}>{alerts[alert].linkText}</ALinkWithUnderline></p>
            </Alert>
        </div>

    ) : null;
};

export default AlertClusters;
