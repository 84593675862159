import { IHeaderParams } from 'ag-grid-community';
import React from 'react';
import TooltipHeaderRendererStyles from './TooltipHeaderRenderer.styled';
import { IconButton, Tooltip, Stack } from 'common/design-system/components-v2';
import { ITooltipProps } from '@dome9/berries/react-components';

interface TooltipHeaderRendererProps {
    headerParams: IHeaderParams;
    tooltip?: ITooltipProps['content'];
    sortable?: boolean;
}

const TooltipHeaderRenderer: React.FC<TooltipHeaderRendererProps> = (props: TooltipHeaderRendererProps) => {
    const { headerParams, tooltip } = props;
    const { displayName } = headerParams;

    const onClickSort = () => {
        return;// sorting needs refactoring, so it's disabled for now
        headerParams.progressSort();
    };

    return (
        <TooltipHeaderRendererStyles.ColumnHeaderWrapper onClick={onClickSort} direction='row' alignItems='center' justifyContent='space-between' fullWidth>
            <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
                {displayName}
                {headerParams.column.isSorting() &&
                    <span className={`ag-icon ag-icon-${headerParams.column.isSortAscending() ? 'asc' : 'desc'}`} role="presentation"></span>
                }    
            </Stack>
            {tooltip &&
                <Tooltip content={tooltip}>
                    <Stack alignItems='center'>
                        <IconButton iconProps={{ name: 'info', size: 12 }} />
                    </Stack>
                </Tooltip>
            }
        </TooltipHeaderRendererStyles.ColumnHeaderWrapper>
    );
};

export default TooltipHeaderRenderer;
