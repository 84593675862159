import { FC } from 'react';
import { Icon } from 'common/design-system/components-v2';
import { ImageAdmissionItemProps } from './interfaces';
import Detect from './assets/detect.svg';
import Prevent from './assets/prevent.svg';
import { useTheme } from 'styled-components';

export const ImageAdmissionIcon : FC<ImageAdmissionItemProps>= ({ name } ) => {
    const cloudColor = useTheme().palette.brandColor;
    const getIcon = (name: string) => {
        switch (name) {
            case 'detect':
                return <img src={Detect} height='48px' width='48px' />;
            case 'prevent':
                return <img src={Prevent} height='48px' width='48px' />;
            case 'disable':
                return <Icon size={48} name='cloud' customColor={cloudColor} fill={cloudColor} />;
        }
    };
    return getIcon(name);

};