import { RouterChildContext } from 'react-router-dom';

export type History = RouterChildContext['router']['history'];

export enum BaseUrl {
    ThreatAndSecurityEventsPage = 'threat-and-security-events',
    AlertsFindings = 'alerts/findings',
    PostureManagement_AssessmentHistory = 'compliance-engine/history',
    ImageAssurance_AssessmentHistory = 'workload/history',
    ImageAssurance_Vulnerabilities ='workload/vulnerability',
    Assets_Environments = 'cloud-account/index',
    Assets_ProtectedAssets = 'protected-asset/index',
    WorkloadProtection_Assets_Images = 'workload/images',
    WorkloadProtection_Assets_Workloads = 'workload/workloads',
    Events_PostureFindings = 'posture-findings',
    WorkloadProtection_Environments = 'workload/environments'
}

export type UrlData = {
    baseUrl: string,
    queryData?: any,
    timeData?: any,
    additionalQueryData?: Array<string>,
}

export type FilterField = {
    name: string,
    value: string | number | any
}

export enum ResourceType {
    Clusters = 'kubernetes',
    Registries = 'containerregistry',
    Pipelines = 'shiftleft',
}

export enum Status {
    warnings = 'Warning',
    errors = 'Error',
    all = 'All'
}

export type TimeData = {
    from: string,
    to: string
}

export type Time = string | TimeData