import styled from 'styled-components';
import { A } from 'common/components/Anchor/Anchor';

export const ControllerWrapper = styled.div`
    margin-bottom: 10px;
`;

export const Error = styled.p`
    color: red;
`;

export const ClickForOnboardingRegistry = styled.label`
    margin-bottom: 10px;
    font-size: 14px;
    display: block;
  
   span {
      margin-left: 5px;
    }
`;

export const CustomA = styled(A)`
  color: ${({ theme }) => theme.palette.status.info};
  font-size: 12px;
  
  :visited, :hover {
    color: ${({ theme }) => theme.palette.status.info};
  }

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;