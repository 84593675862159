import styled from 'styled-components';

export const TextFieldWithValidation = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const FormItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => `${theme.spacing(1)}${theme.units}`};
`;

export default {
    FormItemWrapper
};
