import EmptyState from 'common/components/EmptyState';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';

interface ErrorStateProps {
    errorMessage?: string;
}

const ErrorState: React.FC<ErrorStateProps> = ({ errorMessage }: ErrorStateProps) => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    return (
        <EmptyState
            iconSize={64}
            iconName='error'
            label={errorMessage || t('sorrySomethingWentWrong')}
        />
    );
};

export default ErrorState;