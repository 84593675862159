import { useTranslation } from 'react-i18next';
import { Button, Icon, Input } from 'common/design-system/components-v2';
import React from 'react';
import CveSearchStyles from './CveSearch.styled';
import useReactRouterQuery from 'common/hooks/useReactRouterQuery';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import TopCvesTable from './TopCvesTable';
import useTopCves from './hooks/useTopCves';
import useSearchCveId from './hooks/useSearchCveId';
import SearchCveTable from './SearchCveTable';

const CveSearch: React.FunctionComponent = () => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    const { params: { cve: urlCve }, changeParam, deleteParams } = useReactRouterQuery();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const { setBreadCrumbsLastChildren } = useBreadcrumbsLastChildren();
    const { isLoading: topCvesLoading, topCvesData } = useTopCves();
    const { isLoading: searchCveIdLoading } = useSearchCveId();
    
    React.useEffect(() => {
        if (!inputRef.current) return;

        const onKeyUp = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                changeParam('cve', inputRef.current?.value || '');
            }
        };

        inputRef.current.addEventListener('keyup', onKeyUp);

        return () => {
            if (inputRef.current) {
                inputRef.current?.removeEventListener('keyup', onKeyUp);
            }
        };
    }, [changeParam, urlCve]);

    React.useEffect(() => {
        if (urlCve) {
            setBreadCrumbsLastChildren([urlCve]);
        }

        if (!urlCve) {
            setBreadCrumbsLastChildren();
            if (inputRef.current) inputRef.current.value = '';
        }
    }, [urlCve, setBreadCrumbsLastChildren]);
    
    const onInputChange = () => {// this is used to remove the cve url param if the input is empty
        const value = inputRef.current?.value;
        if (!value) {
            deleteParams(['cve']);
        }
    };

    const loading = React.useMemo(() => {
        if (urlCve) return searchCveIdLoading;
        return topCvesLoading;
    }, [topCvesLoading, searchCveIdLoading, urlCve]);

    return (
        <CveSearchStyles.CveSerachContainer fullHeight padding={[12, 12]} alignItems='center' spacing={6} >
            <CveSearchStyles.SearchBarWrapper alignItems='center' spacing={6}>
                <Input
                    key={urlCve}
                    ref={inputRef}
                    placeholder='Search CVEs'
                    type='text'
                    defaultValue={urlCve}
                    onChange={onInputChange}
                    endAdornment={<Button color='brandPrimary' onClick={() => changeParam('cve', inputRef.current?.value || '')} loading={loading}>{t('search')}</Button>}
                    clearable
                    startAdornment={<Icon name='magnify' />}
                    fullWidth
                    inputSize='large'
                    disabled={loading || (!urlCve && topCvesData.length === 0)}
                    maxLength={40}
                />
            </CveSearchStyles.SearchBarWrapper>
            {urlCve ? <SearchCveTable /> : <TopCvesTable />}
        </CveSearchStyles.CveSerachContainer>
    );
};

export default CveSearch;
