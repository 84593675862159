import { KubernetesAccount } from 'modules/workloads/reducers/cluster/cluster.interface';
import { ReactTabContent } from './KubernetesEnvironment.styled';
import Blades from './tabs/Blades';
import Overview from './tabs/Overview';
import Assets from './tabs/Assets/Assets';
import Images from './tabs/Images/Images';
import { K8sClusterVersionStatusTypes } from 'modules/workloads/services/kubernetesAccount/kubernetesAccount.interface';

export interface K8sTab {
    name: string;
    labelT: string;
    isReactTab: boolean;
    content?: React.ReactElement;
    propertyInAccount?: keyof KubernetesAccount;
    guardByFeatureFlag?: string;
}

export enum K8STabs {
    OVERVIEW = 'overview',
    BLADES = 'blades',
    PROTECTED_ASSETS = 'protected assets',
    IMAGES = 'images',
    COMPLIANCE_POLICIES = 'compliance policies',
    THREAT_AND_SECURITY_EVENTS = 'threat and security events',
    POSTURE_FINDING_EVENTS = 'posture finding events',
    ASSESSMENT_HISTORY = 'assessment history',
    ADMISSION_CONTROL = 'admission control',
    RUNTIME = 'runtime'
}

export const k8sTabsMetadata: (id: string) => Array<K8sTab> = (id) => [
    { 
        name: K8STabs.OVERVIEW,
        labelT: 'environment.tabs.overview',
        guardByFeatureFlag: 'k8s-overview-tab',
        isReactTab: true,
        content: (
            <ReactTabContent>
                <Overview id={id} />
            </ReactTabContent>
        )
    },
    { 
        name: K8STabs.BLADES,
        labelT: 'environment.tabs.blades',
        isReactTab: true,
        content: (
            <ReactTabContent>
                <Blades id={id} />
            </ReactTabContent>
        )
    },
    { 
        name: K8STabs.PROTECTED_ASSETS,
        labelT: 'environment.tabs.protectedAssets', 
        isReactTab: true,
        content: (
            <ReactTabContent>
                <Assets id={id} />
            </ReactTabContent>
        )
    },
    { 
        name: K8STabs.IMAGES,
        labelT: 'environment.tabs.images', 
        isReactTab: true,
        content: (
            <ReactTabContent>
                <Images id={id} />
            </ReactTabContent>
        )
    },
    { 
        name: K8STabs.COMPLIANCE_POLICIES,
        labelT: 'environment.tabs.compliancePolicies', 
        isReactTab: false,
    },
    { 
        name: K8STabs.THREAT_AND_SECURITY_EVENTS,
        labelT: 'environment.tabs.threatAndSecurityEvents', 
        isReactTab: false,
    },
    { 
        name: K8STabs.POSTURE_FINDING_EVENTS,
        labelT: 'environment.tabs.postureFindingEvents', 
        isReactTab: false,
    },
    { 
        name: K8STabs.ASSESSMENT_HISTORY,
        labelT: 'environment.tabs.assessmentHistory', 
        isReactTab: false,
    },
    { 
        name: K8STabs.ADMISSION_CONTROL,
        labelT: 'environment.tabs.admissionControl', 
        isReactTab: false,
    },
    { 
        name: K8STabs.RUNTIME,
        propertyInAccount: 'runtimeProtectionEnabled',
        labelT: 'environment.tabs.runtime', 
        isReactTab: false
    },
];

export const deprecatedAlertKeyword: Record<K8sClusterVersionStatusTypes, string | undefined> = {
    Error: 'clusterVersionAlert.error',
    Warning: 'clusterVersionAlert.warning',
    Ok: undefined,
    Unknown: undefined,
};

export const doubleOnboardingErrorMessagePrefix = 'More than one';