import { UserRolesTypes } from 'common/enum/UserRoles';
import { IMenuSectionItem } from 'common/interface/menu';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { AFTER_LOGIN_EVENT } from 'common/extensibility/WellKnownPaths';
import vulnerabilityService from './services/vulnerability/vulnerability.service';
import { getMenuService } from 'common/interface/services';

const ArrayAddItemsToMenu: Array<IMenuSectionItem> = [
    {
        id: 'CI_CD_SHIFT_LEFT',
        position: 60,
        label: 'NAVIGATION_MENU.SERVERLESS.CI_CD',
        state: '/workload/onboarding-shiftleft',
        permission: [UserRolesTypes.ALL],
    }
];

const addItemsToMenu = () => {
    globalAddinContainer.add(AFTER_LOGIN_EVENT, [{
        id: 'remove-items-from-menu',
        content: () => {
            vulnerabilityService.getScanEngineVersion().then(res => {
                const { data: { scanEngineVersion } } = res;
                if(scanEngineVersion === '1.0.0') {
                    getMenuService().addMenuItems('workload protection','image assurance', ArrayAddItemsToMenu);
                }
            }).catch(err => console.error('failed adding CI/CD shiftleft menu', err));
        }
    }]);
};

export { addItemsToMenu };
