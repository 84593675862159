import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetVulnerabilityScan } from '../../services/vulnerability/vulnerability.interface';

export type VulnerabilitiesState = {
    isLoading: boolean;
    error: boolean;
    entityId: string | null;
    vulnerabilitiesData?: GetVulnerabilityScan.Response
};

const initialState = {
    isLoading: false,
    error: false,
    entityId: null,
    vulnerabilitiesData: undefined
};

const VulnerabilitiesSlice = createSlice({
    name: 'vulnerabilities',
    initialState,
    reducers: {
        setVulnerabilities: (state: VulnerabilitiesState, action: PayloadAction<{ entityId: string | null, data: GetVulnerabilityScan.Response | null }>) => {
            const { entityId, data } = action.payload;
            if(entityId === null) {
                state.vulnerabilitiesData = undefined;
            } else {
                state.entityId = entityId;
                state.vulnerabilitiesData = data ?? undefined;
            }
        },
        setLoading: (state: VulnerabilitiesState, action) => {
            state.isLoading = action.payload;
        },
        setError: (state: VulnerabilitiesState, action) => {
            state.error = action.payload;
        },
        setReset: (state: VulnerabilitiesState) => {
            state.vulnerabilitiesData = undefined;
            state.entityId = null;
        }
    }
});

export default VulnerabilitiesSlice;
