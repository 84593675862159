import React from 'react';
import { Vulnerability } from '../Vulnerabilities.types';
import { Category, FindingsRowWrapper } from './FindingsRow.styled';
import { CountBySeverity, Typography, Button } from 'common/design-system/components-v2';

const FindingsRow: React.FC<Vulnerability> = ({ type, severityCounts, onClickGoVulnerability, totalFixable, title }) => (
    <FindingsRowWrapper alignItems={'flex-end'} spacing={2} direction={'row'}>
        <Category fullWidth direction={'row'} alignItems='center' padding={[0, 0, 2, 0]}>
            <Typography variant={'body500'}>{title}</Typography>
            {(type === 'cve' && totalFixable > 0 && onClickGoVulnerability) && (
                <Button variant='text' onClick={onClickGoVulnerability}>
                    <Typography color='strong' textDecoration='underline'>
                        Fixable: {totalFixable}
                    </Typography>
                </Button>
            )}
        </Category>
        {(type === 'secret' || type === 'threat') ? <Typography>{totalFixable}</Typography> : <CountBySeverity severityCounts={severityCounts} />}
    </FindingsRowWrapper>
);


export default FindingsRow;
