import styled from 'styled-components';
import { Button, Media } from '@dome9/berries/react-components';

export const PreSelectPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 20px;
    padding: 24px;
    align-items: center;
    justify-content: center;
`;

export const TextWithSideLines = styled.p`
    font-size: 16px;

    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;

    &:before {
        content: '';
        background: #e8e9eb;
        height: 2px;
        flex: 1;
    }
    &:after {
        content: '';
        background: #e8e9eb;
        height: 2px;
        flex: 1;
    }
`;

export const PreSelectPageButtonsWrapper = styled.div`
    display: flex;
    gap: 20px;

    p {
        text-indent: 10px;
    }
`;

export const PreSelectPageButton = styled(Button)`
    padding: 10px 20px;
    width: 300px;
`;

export const PreSelectNote = styled.div`
    background: rgba(86, 179, 15, 0.2);
    justify-content: center;
    padding: 5px 20px;
    border-radius: 5px;
`;


export const PageWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 24px;
    height: 100%;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
`;

export const AdvConfigSection = styled.div`
    background: white;
    padding: 15px;
    border: 1px solid #e8e9eb;
`;

export const AdvConfigTitle = styled.div`
    display: flex;
    gap: 8px;
    cursor: pointer;
    justify-content: space-between;
`;

export const ToggleArrow = styled.div<{ isOpen: boolean; }>`
    display: flex;
    align-items: center;
    transition: transform 0.2s linear;
    transform: rotate(${props => props.isOpen ? '-180deg' : '0deg'});
`;

export const WrapableSection = styled.div<{ isOpen: boolean; }>`
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;

    transition: max-height 0.2s linear, padding-top 0.2s linear, opacity 0.2s linear;
    max-height: ${props => props.isOpen ? '1000px' : '0px'};
    padding-top: ${props => props.isOpen ? '15px' : '0px'};
    opacity: ${props => props.isOpen ? '1' : '0'};
`;

export const InputsRow = styled.div`
    display: flex;
    gap: 8px;
`;

export const InputsRowLeftSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    flex: 2;
`;

export const InputsRowLeftSectionInputWrapper = styled.div`
    width: calc(50% - 6px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    @media (max-width: 1400px) {
        width: 100%;
    }

    p {
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    > * {
        width: calc(50% - 2px);
    }
`;

export const InputsRowRightSection = styled.div`
    flex: 1;
`;

export const FeaturesTitle = styled.p`
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const FeaturesSection = styled.div`
    display: flex;
    gap: 15px;
    flex-wrap: wrap;

    > * {
        flex-basis: calc(33.333% - 10px);
    }

    @media (max-width: 1400px) {
        > * {
            flex-basis: calc(50% - 8px);
        }
    }
`;

export const BottomSection = styled.div`
    display: flex;
    gap: 12px;
    justify-content: space-between;
`;

export const EditIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, 0.07);
    }
`;

export const Media_ = styled(Media)<{ fontWeight?: string; }>`
    & .media__title {
        margin-top: unset;
        font-weight: ${(props) => props.fontWeight};
    }
`;

export const LoderFullScreenWrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    opacity: 0.5;
    background: #fafafa;
`;