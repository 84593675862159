import { FunctionComponent } from 'react';
import { CpSnippet } from '@dome9/components/react/components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    getClusterNamespace,
    getNewCluster,
    getSelectedCluster,
} from '../../../reducers/cluster/cluster.slice';
import {
    getNewRegistry,
    getHelmCommand,
} from '../../../reducers/registry/registry.slice';

const RegistryInstructions: FunctionComponent = () => {
    const { t } = useTranslation('k8s_registry');
    const namespace = useSelector(getClusterNamespace);
    const newRegistry = useSelector(getNewRegistry);
    const selectedCluster = useSelector(getSelectedCluster);
    const newCluster = useSelector(getNewCluster);
    const relevantCluster = selectedCluster || newCluster;
    const helmCommand = useSelector(getHelmCommand);

    return (
        <div className='k8s-registry-instructions registry-step'>
            <div className='row'>
                <span className='key'>{t('ON_BOARDING.REGISTRY_NAME')}:</span> <span className='value'>{newRegistry?.name}</span>
            </div>

            <div className='row'>
                <span className='key'>{t('ON_BOARDING.REGISTRY_DESCRIPTION')}:</span> <span className='value'>{newRegistry?.description || 'N/A'}</span>
            </div>

            <div className='row'>
                <span className='key'>{t('ON_BOARDING.REGISTRY_ID')}:</span> <span className='value'>{newRegistry?.id}</span>
            </div>

            <div className='row'>
                <span className='key'>{t('ON_BOARDING.CLUSTER_NAME')}:</span> <span className='value'>{relevantCluster?.name}</span>
            </div>

            <div className='row'>
                <span className='key'>{t('ON_BOARDING.CLUSTER_ID')}:</span> <span className='value'>{relevantCluster?.id}</span>
            </div>

            <div className='row'>
                <span className='key'>{t('ON_BOARDING.NAMESPACE')}:</span> <span className='value'>{namespace.value}</span>
            </div>

            <div className='row'>
                <span className='key'>{t('ON_BOARDING.FEATURES')}:</span>
                <span className='value'>{t('ON_BOARDING.POSTURE_MANAGEMENT')},</span>
                {relevantCluster?.imageAssuranceEnabled && (
                    <span className='value'>{t('ON_BOARDING.IMAGE_ASSURANCE')},</span>
                )}
                {relevantCluster?.admissionControlEnabled && (
                    <span className='value'>{t('ON_BOARDING.ADMISSION_CONTROL')},</span>
                )}
                {relevantCluster?.runtimeProtectionEnabled && (
                    <span className='value'>{t('ON_BOARDING.RUNTIME_PROTECTION_PREVIEW')},</span>
                )}
                {relevantCluster?.threatIntelligenceEnabled && (
                    <span className='value'>{t('ON_BOARDING.THREAT_INTELLIGENCE')}</span>
                )}
            </div>
            <div className='row'>
                <span className='value'>{t('ON_BOARDING.COPY_THE_COMMANDS')}</span>
            </div>
            <div className='row'>
                {helmCommand && (
                    <CpSnippet>
                        <div>{helmCommand}</div>
                    </CpSnippet>
                )}
            </div>
            <div className='row'>
                <span className='value'>{t('ON_BOARDING.DEPLOY_AGENTS')}</span>
            </div>
            <div className='row'>
                <span className='value'>{t('ON_BOARDING.IF_YOU_PREFER')}</span>
            </div>
            <div className='row'>
                <span className='value'>{t('ON_BOARDING.NOTE_THAT_SINCE')}</span>
            </div>
        </div>
    );
};

export default RegistryInstructions;
