import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import {
    ENVIRONMENT_POLICY_RULESETS,
    IShallowRuleset,
    IVendorRuleset, OU_POLICY_RULESETS, POLICY_ENVIRONMENT_VENDORS,
    POLICY_RULESETS, POLICY_SERVICE,
    POLICY_VENDORS,
    Vendor
} from 'common/interface/policy';
import { Vendors } from 'common/consts/vendors';
import { REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import { getPolicyRulesets } from 'common/components/policy/utils';
import { getUserService } from 'common/interface/services';
import { AddOUPolicyPage } from './vulnerabilities/OU/AddOUPolicyPage';
import { AddEnvsPolicyPage } from './vulnerabilities/Environment/AddEnvsPolicyPage';
import { EditVulnerabilitiesPolicyPage } from './vulnerabilities/EditVulnerabilitiesPolicyPage';
import { AddAdmissionOUPolicyPage } from './admission/OU/AddAdmissionOUPolicyPage';
import { AddAdmissionEnvPolicyPage } from './admission/Environment/AddAdmissionEnvPolicyPage';
import { EditAdmissionPolicyPage } from './admission/EditAdmissionPolicyPage';

import { WorkloadsPolicyService } from './services/workloadsPolicyService';
export const AF_NEW_INTEGRATIONS = 'new-integrations';

const vendors : Vendor[] = [
    {
        id: Vendors.KUBERNETES,
        name: Vendors.KUBERNETES,
        icon: 'kubernetes',
        title: 'Kubernetes'
    },
    {
        id: Vendors.CONTAINER_REGISTRY,
        name: Vendors.CONTAINER_REGISTRY,
        icon: 'containers-registry',
        title: 'Container Registry'
    },
    {
        id: Vendors.KUBERNETES_RUNTIME_ASSURANCE,
        name: Vendors.KUBERNETES_RUNTIME_ASSURANCE,
        icon: 'containers-registry',
        title: 'Kubernetes Runtime Assurance'
    }
];

export const getRulesets = async (vendor?:Vendors) => {
    const shallowRulesets = await getPolicyRulesets();
    if(vendor) {
        return shallowRulesets.filter((ruleset: IShallowRuleset) => ruleset.platform === vendor);
    }else {
        return shallowRulesets;
    }
};

const getVendorsRulesets = () => {

    const getContainerRegistryRulesets = async () => {
        const shallowRulesets = await getRulesets();
        return shallowRulesets.filter((ruleset:IShallowRuleset) => ruleset.platform === Vendors.IMAGE_ASSURANCE);
    };

    const getKubernetesRulesets = async () => {
        const shallowRulesets = await getRulesets();
        return shallowRulesets.filter((ruleset:IShallowRuleset) => ruleset.platform === Vendors.KUBERNETES || ruleset.platform === Vendors.IMAGE_ASSURANCE );
    };
    const getKubernetesRuntimeAssuranceRulesets = async () => {
        const shallowRulesets = await getRulesets();
        return shallowRulesets.filter((ruleset:IShallowRuleset) => ruleset.platform === Vendors.KUBERNETES || ruleset.platform === Vendors.KUBERNETES_RUNTIME_ASSURANCE || ruleset.platform === Vendors.IMAGE_ASSURANCE );
    };

    const vendorsRulesetsList:IVendorRuleset[] = [];
    for (const vendor of vendors) {
        switch (vendor.id) {
            case Vendors.CONTAINER_REGISTRY:
                vendorsRulesetsList.push({ vendorId: vendor.id, getRulesets: ()=> getContainerRegistryRulesets() });
                break;
            case Vendors.KUBERNETES:
                vendorsRulesetsList.push({ vendorId: vendor.id, getRulesets: ()=> getKubernetesRulesets() });
                break;
            case Vendors.KUBERNETES_RUNTIME_ASSURANCE:
                vendorsRulesetsList.push({ vendorId: vendor.id, getRulesets: ()=> getKubernetesRuntimeAssuranceRulesets() });
                break;
            default:
                return [];
        }
    }
    return vendorsRulesetsList;
};

const vendorRulesetsList = getVendorsRulesets();

export const initializePolicies = () => {
    const getMappedRulesetItems = (rulesetsList: IVendorRuleset[]) => {
        return [...rulesetsList.map((item: IVendorRuleset) => ({
            id: item.vendorId, content: {
                getRulesets: item.getRulesets,
                vendorId: item.vendorId
            }
        }))];
    };

    const addVulnerabilityPolicyEnvsPageRoute = () =>{
        return {
            condition: getUserService().hasPermission([AF_NEW_INTEGRATIONS]),
            component: AddEnvsPolicyPage,
            path: '/workload/policy/environments/add',
        };
    };

    const addVulnerabilityPolicyOUPageRoute = () =>{
        return {
            condition: getUserService().hasPermission([AF_NEW_INTEGRATIONS]),
            component: AddOUPolicyPage,
            path: '/workload/policy/ou/add',
        };
    };

    const editVulnerabilityPolicyPageRoute = () =>{
        return {
            condition: getUserService().hasPermission([AF_NEW_INTEGRATIONS]),
            component: EditVulnerabilitiesPolicyPage,
            path: '/workload/policy/edit',
        };
    };

    const addAdmissionPolicyEnvsPageRoute = () =>{
        return {
            condition: getUserService().hasPermission([AF_NEW_INTEGRATIONS]),
            component: AddAdmissionEnvPolicyPage,
            path: '/runtime-assurance/policy/environments/add',
        };
    };

    const addAdmissionPolicyOUPageRoute = () =>{
        return {
            condition: getUserService().hasPermission([AF_NEW_INTEGRATIONS]),
            component: AddAdmissionOUPolicyPage,
            path: '/runtime-assurance/policy/ou/add',
        };
    };

    const editAdmissionPolicyPageRoute = () =>{
        return {
            condition: getUserService().hasPermission([AF_NEW_INTEGRATIONS]),
            component: EditAdmissionPolicyPage,
            path: '/runtime-assurance/policy/edit',
        };
    };
    
    const WORKLOADS = 'workloads';

    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'workload-vulnerability-policy-ou', content: addVulnerabilityPolicyOUPageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'workload-vulnerability-policy-envs', content: addVulnerabilityPolicyEnvsPageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'workload-vulnerability-policy-edit', content: editVulnerabilityPolicyPageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'workload-admission-policy-ou', content: addAdmissionPolicyEnvsPageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'workload-admission-policy-envs', content: addAdmissionPolicyOUPageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'workload-admission-policy-edit', content: editAdmissionPolicyPageRoute }]);
    globalAddinContainer.add(POLICY_ENVIRONMENT_VENDORS, [...vendors.map(vendor => ({ id: vendor.id, content: vendor }))]);
    globalAddinContainer.add(POLICY_VENDORS, [...vendors.map(vendor => ({ id: vendor.id, content: vendor }))]);
    globalAddinContainer.add(POLICY_RULESETS, getMappedRulesetItems(vendorRulesetsList));
    globalAddinContainer.add(ENVIRONMENT_POLICY_RULESETS, getMappedRulesetItems(vendorRulesetsList));
    globalAddinContainer.add(OU_POLICY_RULESETS, getMappedRulesetItems(vendorRulesetsList));
    globalAddinContainer.add(POLICY_SERVICE, [{ id: WORKLOADS, content: { moduleName: WORKLOADS,service: new WorkloadsPolicyService() } }]);
};