import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import EmptyState from 'common/components/EmptyState';

const FailureLoadingScanResults = () => {
    const { t } = useTranslation(getK8sNamespace('vulnerability'));
    return (
        <EmptyState
            label={t('tabStates.failure.title')}
            description={t('tabStates.failure.body')}
            iconName='error'
            iconSize={64}
        />
    );
};

export default FailureLoadingScanResults;
