import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import { getNotificationsService, getUserService } from 'common/interface/services';
import { FormValues } from './types';
import {
    getWorkloadAccountSettingFromServer,
    patchWorkloadAccountSettingFromServer,
} from '../../../../../reducers/settings/settings.service';
import { NotificationType } from 'common/interface/notifications';
import { Button, Input, Message, Spinner, Stack } from 'common/design-system/components-v2';

const DEFAULT_VALUES: FormValues = {
    daysToKeepNonActiveKubernetesImages: '',
    daysToKeepNonActiveShiftLeftImages: '',
    registryScanningIntervalInHours: '',
    maxImagesPerRepo: '',
    daysToKeepNonActiveAwsEcsImages: '',
};

interface SettingsFields {
    label: string;
    tooltipT?: string;
    textHelperT?: string;
    name: 'daysToKeepNonActiveKubernetesImages' | 'daysToKeepNonActiveShiftLeftImages' | 'registryScanningIntervalInHours' | 'maxImagesPerRepo' | 'daysToKeepNonActiveAwsEcsImages';
    validation?: Pick<RegisterOptions, 'required' | 'max' | 'min' | 'maxLength' | 'minLength' | 'pattern' | 'validate' >;
    isRelevant?: () => boolean;
}
const settingsInputFields: Array<SettingsFields> = [
    {
        label: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.DELETE_INACTIVE_K8S.LABEL',
        tooltipT: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.DELETE_INACTIVE_K8S.TOOLTIP',
        name: 'daysToKeepNonActiveKubernetesImages',
        validation: {
            required: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.REQUIRED',
            pattern: {
                value: /^(?:36[0-5]|3[0-5]\d|[12]\d{2}|[1-9]\d?)$/,
                message: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.DELETE_INACTIVE_K8S.ERROR',
            },
        }
    },
    {
        label: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.DELETE_INACTIVE_SHIFTLEFT.LABEL',
        tooltipT: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.DELETE_INACTIVE_SHIFTLEFT.TOOLTIP',
        name: 'daysToKeepNonActiveShiftLeftImages',
        validation: {
            required: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.REQUIRED',
            pattern: {
                value: /^(?:36[0-5]|3[0-5]\d|[12]\d{2}|[1-9]\d?)$/,
                message: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.DELETE_INACTIVE_SHIFTLEFT.ERROR',
            },
        }
    },
    {
        label: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.SCAN_INTERVAL.LABEL',
        tooltipT: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.SCAN_INTERVAL.TOOLTIP',
        name: 'registryScanningIntervalInHours',
        validation: {
            required: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.REQUIRED',
            pattern: {
                value: /^([1-9]|[1-9][0-9]|[1][0-5][0-9]|16[0-8])$/,
                message: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.SCAN_INTERVAL.ERROR',
            },
        },
        isRelevant: () => getUserService().hasPermission(['allow_global_settings']),
    },
    {
        label: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.SCAN_MAX_IMAGE.LABEL',
        tooltipT: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.SCAN_MAX_IMAGE.TOOLTIP',
        name: 'maxImagesPerRepo',
        validation: {
            required: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.REQUIRED',
            pattern: {
                value: /^([1-9]\d{0,5}|1000000)$/,
                message: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.SCAN_MAX_IMAGE.ERROR',
            },
        },
        isRelevant: () => getUserService().hasPermission(['allow_global_settings']),
    },
    {
        label: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.DELETE_INACTIVE_ECS.LABEL',
        tooltipT: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.DELETE_INACTIVE_ECS.TOOLTIP',
        name: 'daysToKeepNonActiveAwsEcsImages',
        validation: {
            required: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.REQUIRED',
            pattern: {
                value: /^(?:36[0-5]|3[0-5]\d|[12]\d{2}|[1-9]\d?)$/,
                message: 'SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.DELETE_INACTIVE_ECS.ERROR',
            },
        },
        isRelevant: () => getUserService().hasPermission(['allow_ecs_aws_image_tab']),
    },

];

const ImageAssuranceConfig = () => {
    const { t } = useTranslation();
    const [isFormDataReady, setIsFormDataReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, dirtyFields },
        setValue,
        getValues,
        resetField,
    } = useForm({
        mode: 'onChange',
        defaultValues: DEFAULT_VALUES,
    });

    const getWorkloadAccountSetting = useCallback(async () => {
        const alertsService = getNotificationsService();
        try {
            const workloadSettings = await getWorkloadAccountSettingFromServer();
            settingsInputFields.map(field => field.name).forEach((workloadSettingsField) => {
                const defaultValueFromServer = workloadSettings?.imageAssurance[workloadSettingsField]?.toString() || DEFAULT_VALUES[workloadSettingsField];
                resetField(workloadSettingsField, { defaultValue: defaultValueFromServer });
                setValue(workloadSettingsField, defaultValueFromServer, { shouldValidate: true });
            });
        } catch (error: any) {
            alertsService.addNotification({
                text: error.message,
                type: NotificationType.ERROR,
                title: 'Error',
            });
        }
    }, [resetField, setValue]);

    useEffect(() => {
        (async () => {
            await getWorkloadAccountSetting();
            setIsFormDataReady(true);
        })();
    }, [getWorkloadAccountSetting]);

    const onSubmit = useCallback(async () => {
        const changedFields = Object.keys(dirtyFields).reduce<Partial<FormValues>>((acc, key) => {
            const fieldName = key as keyof FormValues;
            return { ...acc, [fieldName]: getValues(fieldName) };
        }, {});
        const alertsService = getNotificationsService();
        setIsLoading(true);
        try {
            await patchWorkloadAccountSettingFromServer({
                imageAssurance: {
                    ...changedFields,
                }
            });
            await getWorkloadAccountSetting();
            alertsService.addNotification({
                text: t('SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.NOTIFICATION.SUCCESS.TEXT'),
                type: NotificationType.SUCCESS,
                title: t('SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.NOTIFICATION.SUCCESS.TITLE'),
            });
        } catch (error: any) {
            alertsService.addNotification({
                text: error.message,
                type: NotificationType.ERROR,
                title: t('SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.NOTIFICATION.ERROR.TITLE'),
            });
        } finally {
            setIsLoading(false);
        }
    }, [dirtyFields, getValues, getWorkloadAccountSetting, t]);

    const handleSave = useMemo(() => handleSubmit(async () => {
        const shouldShowConfirmationDialog = Boolean(dirtyFields?.registryScanningIntervalInHours || dirtyFields?.maxImagesPerRepo);
        if (shouldShowConfirmationDialog) {
            setIsConfirmDialogOpen(true);
            return;
        }
        onSubmit();
    }), [dirtyFields, handleSubmit, onSubmit]);

    if (!isFormDataReady) {
        return (
            <Stack fullWidth fullHeight alignItems='center' justifyContent='center'>
                <Spinner size={40} />
            </Stack>
        );
    }

    return (
        <>
            <Stack direction='column' spacing={4}>
                {settingsInputFields.filter(field => field?.isRelevant ? field.isRelevant() : true).map((settingsField, index) => {
                    return (
                        <Controller
                            key={`settings-${index}`}
                            name={settingsField.name}
                            control={control}
                            render={({ field }) => {
                                return (
                                    <Input
                                        fullWidth
                                        disabled={isLoading}
                                        label={`${t(settingsField.label)}`}
                                        tooltip={settingsField.tooltipT && t(settingsField.tooltipT)}
                                        {...field}
                                        {...register(field.name, { ...settingsField.validation })}
                                        type='text'
                                        isError={Boolean(errors[settingsField.name])}
                                        helperText={errors[settingsField.name]?.message && t(errors[settingsField.name]?.message ?? '') || settingsField.textHelperT && t(settingsField.textHelperT)}
                                    />
                                );
                            }}
                        />
                    );})}
                <Button
                    color='brandPrimary'
                    loading={isLoading}
                    disabled={Object.keys(errors).length > 0 || Object.keys(dirtyFields).length === 0 || isLoading}
                    onClick={handleSave}
                >
                    {t('SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.SAVE_BUTTON')}
                </Button>
            </Stack>
            <Message
                variant='warning'
                title={t('SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.CONFIRMATION.TITLE')}
                text={t('SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.CONFIRMATION.TEXT')}
                isOpen={isConfirmDialogOpen}
                onClose={() => setIsConfirmDialogOpen(false)}
                onConfirm={() => {
                    setIsConfirmDialogOpen(false);
                    onSubmit();
                }}
                onCancel={() => setIsConfirmDialogOpen(false)}
            />
        </>
    );
};

export default ImageAssuranceConfig;
