import { Icon } from '@dome9/berries/react-components';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { getExternalAdditionalFieldValue } from 'common/module_interface/assets/utils';
import React from 'react';

const AutoProtectCellRenderer: React.FC<ICellRendererParams> = (params) => {    
    
    const getInsertionStateIcon = (isInsertionState: string) => {
        switch (isInsertionState) {
            case 'Error':
                return 'error';
            case 'Warning':
                return 'warning';
            default:
                return null;
                
        }
    };

    const FSPStickyMode = getExternalAdditionalFieldValue(params.data, 'FSPStickyMode') === 'true';
    const insertionState = getExternalAdditionalFieldValue(params.data, 'InsertionState');
    const insertionStateIcon = insertionState && getInsertionStateIcon(insertionState);
    
    return <div className="text-center">
        { FSPStickyMode && <Icon color={'success'} name='checkCircle' size={14}></Icon>}
        { insertionStateIcon && <span className="mx-5">
            <Icon name={insertionStateIcon} size={14}></Icon>
        </span>}
    </div>;
};

export default AutoProtectCellRenderer;