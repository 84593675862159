import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useReactRouterQuery from 'common/hooks/useReactRouterQuery';
import { IPolicy } from 'common/interface/policy';
import { NotificationType } from 'common/interface/notifications';
import { IRuleset, RulesetVersion } from 'common/interface/ruleset';
import { getNotificationsService, getRulesetService } from 'common/interface/services';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { ImageAdmissionItem } from '../helpers/interface';
import { selectActionsObj } from '../helpers/consts';
import { getAdmissionControlPolicy, setAdmissionControlPolicies } from '../helpers/utils';
import Wizard, { RenderViewParams, WizardStep } from 'common/components/Wizard/Wizard';
import { RulesetVersionSelectComponent } from 'common/components/policy/RulesetVersionSelectComponent';
import { ActionSelectionComponent } from '../components/ActionSelectionComponent/ActionSelectionComponent';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { Done } from 'common/components/policy/Done/Done';
import { finishFlow } from 'common/components/policy/utils';
import { useReturnUrl } from 'common/hooks/useReturnUrl';

const stepIds: any ={
    rulesetVersion: false,
    actionSelection: false,
    notificationSelection: false,
    done: false,
};

export const EditAdmissionPolicyPage: React.FC = () => {
    const { params } = useReactRouterQuery();
    const { t } = useTranslation('k8s_policy');
    const [policy,setPolicy] = useState<IPolicy>();
    const [selectedNotifications,setSelectedNotifications] = useState<ListItem[]>();
    const [flowStatusOK,setFlowStatusOK] = useState<boolean>();
    const [flowStatusMessage,setFlowStatusMessage] = useState<string>();
    const [selectedRuleset,setSelectedRuleset] = useState<ListItem>();
    const [selectedAction, setSelectedAction] = useState<ImageAdmissionItem>();
    const [selectedVersion,setSelectedVersion] = useState<RulesetVersion>();
    const location = useLocation();
    const returnUrl = useReturnUrl();
    const saveChanges = useCallback(async () => {
        if(policy && selectedNotifications?.length){
            setFlowStatusMessage(t('GENERAL.PROCESSING'));
            const allRulesets = await getRulesetService().getAllRulesets();
            const ruleset = allRulesets.find((ruleset) => ruleset.id === policy.rulesetId);
            const updatedPolicy = {
                ...policy,
                rulesetPlatform: ruleset?.cloudVendor,
                targetId: policy.targetId || policy.targetInternalId,
                notificationIds: selectedNotifications.map((notification:ListItem) => notification.id),
                rulesetVersion: selectedVersion?.version,
                action: selectedAction?.type,
            };
            setFlowStatusMessage(t('GENERAL.PROCESSING'));
            const result = await setAdmissionControlPolicies([updatedPolicy]);
            if(result) {
                setFlowStatusOK(true);
                setFlowStatusMessage(t('GENERAL.POLICY_ADDED_MESSAGE'));
            }else {
                setFlowStatusOK(false);
                setFlowStatusMessage(t('GENERAL.ERROR_OCCURRED'));
            }
        }
    },[policy, selectedNotifications, selectedVersion?.version, selectedAction, t]);

    const steps :WizardStep[] = useMemo(() => [
        {
            id: 'rulesetVersion',
            name: t('GENERAL.SELECT_VERSION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <RulesetVersionSelectComponent onSelectedItemChanged={rulesetVersionChanged}
                    ruleset={selectedRuleset} preSelectedVersion={selectedVersion} {...renderViewParams}/>;
            },
            onNext: async ()=>{
                return;
            },
        },
        {
            id: 'actionSelection',
            name: t('GENERAL.ACTION_SELECTION'),
            renderView: (onValidate:RenderViewParams) => {
                return <ActionSelectionComponent {...onValidate} preSelectedAction={selectedAction} selectedActionChanged={setSelectedAction} />;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            id: 'notificationSelection',
            name: t('GENERAL.NOTIFICATION_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return(
                    <>
                        { selectedNotifications && <NotificationsSelectionComponent preSelectedItems={selectedNotifications} {...renderViewParams } selectedNotificationsChanged={setSelectedNotifications}/>}
                    </>
                );
            },
            onNext: async ()=>{
                saveChanges();
            },
        },{
            id: 'done',
            name: t('GENERAL.DONE'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <Done message={flowStatusMessage} {...renderViewParams} hasError={!flowStatusOK}></Done>;
            },
            onNext: async ()=>{
                finishFlow(returnUrl);
            },
        }
    ],[t, selectedRuleset, selectedVersion, selectedAction, selectedNotifications, saveChanges, flowStatusMessage, flowStatusOK, returnUrl]);


    useEffect(() => {
        const raisePolicyNotFoundErrorMessage = () => {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: t('GENERAL.ERROR_OCCURRED'),
                text: t('GENERAL.POLICY_NOT_FOUND'),
            });
        };
        const handlePolicyLoad = async (policyId: string) => {
            const _policy = await getAdmissionControlPolicy(policyId,false);
            if(_policy){
                setPolicy(_policy);
                setSelectedNotifications(_policy.notificationIds.map((notificationId:string) => ({ id: notificationId })) as ListItem[]);
                const allRuleset = await getRulesetService().getAllRulesets();
                const ruleset : IRuleset | undefined = allRuleset.find((ruleset:IRuleset) => ruleset.id === _policy.rulesetId);
                if(ruleset){
                    const item = {
                        id: ruleset.id.toString(),
                        name: ruleset.name,
                        icon: ruleset.icon,
                        availableVersions: [...ruleset.availableVersions, { version: 'Latest' }],
                        versionEnabled: true,
                        platform: ruleset.cloudVendor,
                    };
                    setSelectedRuleset(item);
                    setSelectedVersion({ version: _policy.rulesetVersion || '', createdTime: ruleset.availableVersions.find(ruleset=>ruleset.version === _policy.rulesetVersion)?.createdTime });
                    setSelectedAction(selectActionsObj(t)[_policy?.action || 'Detection']);
                    stepIds.rulesetVersion = !!(ruleset?.availableVersions.length);
                    stepIds.actionSelection = true;
                    stepIds.notificationSelection = true;
                    stepIds.done = true;
                }
            } else {
                raisePolicyNotFoundErrorMessage();
            }
        };
        if(!policy){
            const policyId = params?.policyId;
            if(!policyId){
                raisePolicyNotFoundErrorMessage();
                return;
            }else{
                handlePolicyLoad(policyId);
            }
        }
    }, [selectedRuleset, location, steps.length, policy, t, params]);

    const rulesetVersionChanged = (item: ListItem) => {
        setSelectedVersion(item.selectedVersion);
    };
    const getSteps = () => {
        return steps?.filter((step) => step.id && stepIds[step.id]);
    };

    return (
        <div>
            {getSteps().length ?
                <Wizard
                    onClose={()=>finishFlow(returnUrl)}
                    steps={getSteps()}
                    title={t('GENERAL.EDIT_POLICY')}
                    doneButtonText={t('GENERAL.DONE')}
                    ShowBackButtonInLastStep={false}
                /> : null}
        </div>
    );
};