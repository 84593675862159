import { Vendors } from 'common/consts/vendors';
import { Link, Spinner, Stack, Typography } from 'common/design-system/components-v2';
import { getCloudAccountsService } from 'common/interface/data_services';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CentralizedAccountDetailState } from './CentralizedAccountDetail.types';

export const CentralizedAccountDetail = ({ centralizedCloudAccountId, provider }: { centralizedCloudAccountId: string, provider: Vendors }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));
    const [hubAccount, setHubAccount] = React.useState<CentralizedAccountDetailState>({ type: 'LOADING' });

    const getAccountHubName = React.useCallback(async () => {
        if (!centralizedCloudAccountId) {
            return;
        }
        setHubAccount({ type: 'LOADING' });
        try {
            const searchResult = await getCloudAccountsService().getAllCloudAccounts(true);
            const account = searchResult.find((account) => account.id === centralizedCloudAccountId);
            if (account === undefined) {
                setHubAccount({ type: 'ERROR', error: new Error('Hub account not found') });
                return;
            }
            setHubAccount({ type: 'SUCCESS', data: account });
        } catch (error) {
            setHubAccount({ type: 'ERROR', error: error as Error });
        }
    }, [centralizedCloudAccountId]);

    React.useEffect(() => {
        getAccountHubName();
    }, [getAccountHubName]);

    return (
        <Stack>
            <Typography color='light'>{t('awpTab.details.hubAccount')}</Typography>
            {hubAccount.type === 'LOADING' && (
                <Spinner size={18} />
            )}
            {hubAccount.type === 'ERROR' && (
                <Typography>{t('awpTab.details.hubAccountError')}</Typography>
            )}
            {hubAccount.type === 'SUCCESS' && (
                <Link internalUrl={`/v2/cloud-account/${provider}/${centralizedCloudAccountId}`}>{`${hubAccount.data.name} (${centralizedCloudAccountId})`}</Link>
            )}
        </Stack>

    );
};