/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageAdmissionItem } from '../../helpers/interface';
import { selectActionsObj } from '../../helpers/consts';
import { ImageAdmissionCard } from '../ImageAdmissionCard/ImageAdmissionCard';
import { Stack, Typography } from 'common/design-system/components-v2';
import { OnValidate } from 'common/components/Wizard/Wizard';
interface ActionSelectionComponentProps {
    onValidate:OnValidate;
    selectedActionChanged:(item:ImageAdmissionItem) => void;
    preSelectedAction?:ImageAdmissionItem;
}
export const ActionSelectionComponent: React.FC<ActionSelectionComponentProps> =
    ({ onValidate,preSelectedAction,selectedActionChanged }) => {
        const { t } = useTranslation('k8s_policy');
        const selectActions :ImageAdmissionItem[] = Object.values(selectActionsObj(t));
        const [selectedAction, setSelectedAction] = useState<ImageAdmissionItem>(selectActions[0]);

        useEffect(() => {
            selectedActionChanged(selectedAction);
        }, []);

        useEffect(() => {
            setSelectedAction(preSelectedAction || selectActions[0]);
        }, [preSelectedAction, selectActions]);

        useEffect(() => {
            onValidate(!!selectedAction);
        }, [selectedAction]);

        const onSelectedActionChanged = (selectedItem: ImageAdmissionItem) => {
            setSelectedAction(selectedItem);
            selectedActionChanged(selectedItem);
        };


        return (
            <span data-aid={'rulesets-selection'}>
                {
                    <Stack spacing={3}>
                        <Typography variant={'bodyLg'}>{t('ADMISSION.SELECT_ACTION')}</Typography>
                        <Stack spacing={5} direction={'row'} flexWrap>
                            {
                                selectActions.map((admission) => {
                                    return (
                                        <ImageAdmissionCard {...admission}
                                            selected={selectedAction?.id === admission.id}
                                            onClick={() => onSelectedActionChanged(admission)} key={admission.id} 
                                            style={admission.style} />
                                    );
                                })
                            }
                        </Stack>
                    </Stack>
                }
            </span>
        );

    };