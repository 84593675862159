import { AssetTypes } from 'common/assets/assets.const';

const SBOM_SUPPORTED_WORKLOAD = [
    AssetTypes.KUBERNETES_KUBERNETESDAEMONSET,
    AssetTypes.KUBERNETES_KUBERNETESDEPLOYMENT,
    AssetTypes.KUBERNETES_KUBERNETESPOD,
    AssetTypes.KUBERNETES_KUBERNETESREPLICASET,
];

export const SBOM_SUPPORTED_WORKLOAD_SET = new Set(SBOM_SUPPORTED_WORKLOAD);

export const SBOM_SUPPORTED_ASSETS_SET = new Set([
    AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE,
    AssetTypes.AWS_ECSIMAGE,
    AssetTypes.KUBERNETES_KUBERNETESIMAGE,
    AssetTypes.SHIFTLEFT_SHIFTLEFTIMAGE,

    ...SBOM_SUPPORTED_WORKLOAD,

    AssetTypes.AWS_INSTANCE,
    AssetTypes.AZURE_VIRTUALMACHINE,
    AssetTypes.AZURE_FUNCTIONAPP,
]);