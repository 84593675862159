import React from 'react';
import { AuthFieldInputProps } from './AuthFieldInput.interface';
import { AuthFieldInputWrapper } from './AuthFieldInput.styled';
import SeeDocumentation from 'modules/workloads/components/SeeDocumentation/SeeDocumentation';
import { Input } from 'common/design-system/components-v2';

const AuthFieldInput: React.FC<AuthFieldInputProps> = (props) => {
    const { authFieldName, tooltipText, tooltipUrl, authProp, onChange } = props;
    
    return (
        <AuthFieldInputWrapper>
            <Input
                label={authFieldName}
                required
                value={authProp.value || ''}
                isError={authProp.isDirty && authProp.isError}
                helperText={authProp.isDirty && authProp.isError ? authProp.errorMessage : ''}
                onChange={(e) => onChange(e)}
                clearable
                fullWidth
                tooltip={tooltipText}
            />
            {tooltipUrl && <SeeDocumentation link={tooltipUrl} />}
        </AuthFieldInputWrapper>
    );
};

export default AuthFieldInput;