import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { Wizard } from '@dome9/berries/react-components';
import ShiftLeftConfigurations from './steps/ShiftLeftConfigurations/ShiftLeftConfigurations';
import OperatingSystem from './steps/OperatingSystem/OperatingSystem';
import { OperatingSystemsEnum } from './steps/OperatingSystem/OperatingSystem.types';
import Instructions from './steps/Instructions/Instructions';
import { changeUrl } from 'common/utils/http';
import { getNotificationsService } from 'common/interface/services';
import { getCloudAccountsService } from 'common/interface/data_services';
import { NotificationType } from 'common/interface/notifications';
import useWizardState from '../../../hooks/useWizardState';
import Steps from './steps';
import ShiftLeftAccountService from '../../../services/shiftLeft/shiftLeftAccount.service';
import vulnerabilityService from '../../../services/vulnerability/vulnerability.service';

const randomIntBetween = (min = 1, max = 99) => Math.floor(Math.random() * (max - min + 1) + min);

export type FormValues = {
    name: string,
    description: string,
    apiKey: string,
    apiSecret: string,
    organizationalUnitId: null | string;
    operatingSystems: OperatingSystemsEnum | '';
    envId: string
}

const ShiftleftOnboarding = () => {
    const {
        currentStep,
        handleOnNextClicked,
        handleOnBackClicked
    } = useWizardState();

    const { t } = useTranslation('k8s_shiftleft');

    const { url } = useRouteMatch();

    const methods = useForm<FormValues>({
        defaultValues: {
            name: `shiftleft-${randomIntBetween(1, 99999)}`,
            description: '',
            apiKey: '',
            apiSecret: '',
            organizationalUnitId: null,
            operatingSystems: '',
            envId: ''
        },
    });

    const { handleSubmit, getValues } = methods;

    const steps = useMemo(() => {
        return [
            {
                path: `${url}/configurations`,
                component: () => <ShiftLeftConfigurations onNext={handleOnNextClicked}/>,
            },
            {
                path: `${url}/operating-system`,
                component: () => <OperatingSystem onNext={handleOnNextClicked} onBack={handleOnBackClicked} />
            },
            {
                path: `${url}/instructions`,
                component: () => <Instructions onBack={handleOnBackClicked} onFinish={handleSubmit(handleOnFinishClicked)} />
            }
        ];
    }, [handleOnBackClicked, handleOnNextClicked, handleSubmit, url]);

    useEffect(() => {
        (async () => {
            try {
                const { data: { scanEngineVersion } } = await vulnerabilityService.getScanEngineVersion();
                if(scanEngineVersion !== '2.0.0') {
                    changeUrl('/cloud-add/shift-left');
                }
            } catch (e) {
                changeUrl('/cloud-add/shift-left');
            }
        })();
    }, []);

    useEffect(() => {
        changeUrl(steps[currentStep].path);
    }, [currentStep, steps]);

    const handleOnFinishClicked = async (formData: FormValues) => {
        const { envId } = formData;

        if(envId) {
            await getCloudAccountsService().clearCache();
            changeUrl(`/cloud-account/shiftleft/${envId}`);
        } else {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: t('shiftLeftOnboarding.steps.instructions.errorMessageEnvId'),
                text: '',
            });
        }
    };

    const onExit = async () => {
        const id = getValues('envId');

        try {
            if(currentStep !== 0) {
                await ShiftLeftAccountService.deleteAccount({ id });
            }
            await getCloudAccountsService().clearCache();
            changeUrl('/workload/environments?');
        } catch (e) {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: t('shiftLeftPage.removeModal.delete.failed'),
                text: t('shiftLeftOnboarding.steps.instructions.errorMessageDeleteEnv', { id: id }),
            });
        }
    };

    return(
        <div style={{ padding: '25px' }}>
            <FormProvider {...methods}>
                <Wizard.Wizard
                    title={'Shiftleft Onboarding'}
                    steps={[
                        t('shiftLeftOnboarding.steps.configurations.wizardTitle'),
                        t('shiftLeftOnboarding.steps.operatingSystem.wizardTitle'),
                        t('shiftLeftOnboarding.steps.instructions.wizardTitle')
                    ]}
                    currentStep={currentStep}
                    onExit={onExit}
                    stepBody={<Steps steps={steps}/>}
                />
            </FormProvider>
        </div>
    );
};

export default ShiftleftOnboarding;