import { ReactElement } from 'react';
export interface ImageAdmissionItem{
    category?: 'unScanned'| 'nonCompliant';
    type?: string | null; // 'Detection' | 'Prevention'
    id: string;
    title: string;
    description: string;
    icon: ReactElement;
    selected?: boolean;
    onClick?: () => void;
    style?: { [key: string]: string };
}
export enum TargetTypesEnum {
    organizationalUnit = 'OrganizationalUnit',
    environment = 'Environment',
    kubernetes = 'Kubernetes',
}