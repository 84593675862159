import { renderMultiSelectFilter } from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import i18n from 'common/services/translations/translations';
import { getK8sNamespace } from '../initialize.i18n';
import { IEnvironmentsFilterParams } from 'common/module_interface/assets/Environments';
import { KubernetesPlatformType } from '../services/workload/workload.interface';
import { isChina } from 'common/utils/dataCenter';
import { ScanMode } from '../services/awp/awp.interface';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { Addin } from 'common/extensibility/AddinRegistry';

export const initializeEnvironmentsFilters = () => {
    const filterDefs: Addin<IFilterProps>[] = [
        {
            id: 'customData|agentlessEnabled',
            content: function({ aggregations }: IEnvironmentsFilterParams) {
                const displayMapping = {
                    true: { displayText: i18n.t('ENVIRONMENTS_TABLE.TABLE_FILTERS.AGENTLESS.VALUES.ENABLED', { ns: getK8sNamespace('common') }) },
                    false: { displayText: i18n.t('ENVIRONMENTS_TABLE.TABLE_FILTERS.AGENTLESS.VALUES.DISABLED', { ns: getK8sNamespace('common') }) }
                };

                return {
                    filterProps: {
                        initialData: aggregations['customData|agentlessEnabled'],
                        key: 'customData|agentlessEnabled',
                        title: i18n.t('ENVIRONMENTS_TABLE.TABLE_FILTERS.AGENTLESS.TITLE', { ns: getK8sNamespace('common') }),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'customData|workloadPlatformType',
            content: function({ aggregations }: IEnvironmentsFilterParams) {
                const displayMapping = {
                    [KubernetesPlatformType.NA]: { displayText: 'N/A' },
                };

                return {
                    filterProps: {
                        initialData: aggregations['customData|workloadPlatformType'],
                        key: 'customData|workloadPlatformType',
                        title: i18n.t('ENVIRONMENTS_TABLE.TABLE_FILTERS.WORKLOAD_TYPE.TITLE', { ns: getK8sNamespace('common') }),
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'customData|agentlessScanMode',
            content: function({ aggregations }: IEnvironmentsFilterParams) {
                const displayMapping = Object.values(ScanMode).reduce<Record<string, { displayText: string }>>((acc, key) => {
                    acc[key] = { displayText: i18n.t(`ENVIRONMENTS_TABLE.TABLE_FILTERS.AGENTLESS_SCAN_MODE.VALUES.${key}`, { ns: getK8sNamespace('common') }) };
                    return acc;
                }, {});
                return {
                    filterProps: {
                        initialData: aggregations['customData|agentlessScanMode'],
                        key: 'customData|agentlessScanMode',
                        title: i18n.t('ENVIRONMENTS_TABLE.TABLE_FILTERS.AGENTLESS_SCAN_MODE.TITLE', { ns: getK8sNamespace('common') }),
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
            
        },
        {
            id: 'customData|serverlessEnabled',
            content: function({ aggregations }: IEnvironmentsFilterParams) {
                const displayMapping = {
                    true: { displayText: i18n.t('ENVIRONMENTS_TABLE.TABLE_FILTERS.SERVERLESS.VALUES.ENABLED', { ns: getK8sNamespace('common') }) },
                    false: { displayText: i18n.t('ENVIRONMENTS_TABLE.TABLE_FILTERS.SERVERLESS.VALUES.DISABLED', { ns: getK8sNamespace('common') }) }
                };

                return {
                    filterProps: {
                        initialData: aggregations['customData|serverlessEnabled'],
                        key: 'customData|serverlessEnabled',
                        title: i18n.t('ENVIRONMENTS_TABLE.TABLE_FILTERS.SERVERLESS.TITLE', { ns: getK8sNamespace('common') }),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
            permission: () => !isChina(),
        }
    ];
    EnvironmentsTableRegistry.addFilterAddins(filterDefs);
};