import { initializeK8sMenu } from './initialize.menu';
import { initializeK8sPages } from './initialize.pages';
import { initializeK8sRedux } from './initialize.redux';
import { initializeK8sGetStarted } from './initialize.getStarted';
import { initializeK8SProtectedAssetDetails } from './initialize.protectedAssetDetails';
import initializeK8SProtectedAssetTable from './protectedAsset/initialize';
import { addItemsToMenu } from './initializeK8sAddItemsToMenu';
import { initialAwsEnvironmentTabs } from './initialize.awsEnv';
import { initializeEnvironmetsDefs } from './EnvironmetsDefs/initialize';
import { initializeCveDrawer } from './services/cveDrawerRegistry/cveDrawerRegistry.initialize';
import { initialAzureEnvironmentTabs } from './initialize.azureEnv';
import { initializePolicies } from './pages/policy/initialize';
import { initializeEventsActions } from './eventsDefs/initialize';
import initializeExclusionWorkload from './exclusion/initialize';

export default function initialize() {
    initializeK8sRedux();
    initializeK8sPages();
    initializeK8sMenu();
    initializeK8sGetStarted();
    initializeK8SProtectedAssetDetails();
    initializeK8SProtectedAssetTable();
    addItemsToMenu();
    initialAwsEnvironmentTabs();
    initialAzureEnvironmentTabs();
    initializeEnvironmetsDefs();
    initializePolicies();
    initializeCveDrawer();
    initializeEventsActions();
    initializeExclusionWorkload();
}
