import { Addin } from 'common/extensibility/AddinRegistry';
import { AwsEnvironmentAddinRegistry, AwsEnvironmentAddingBase } from 'common/helpers/awsEnvironment';
import { AwsEnvironmentToolbarActions, AwsEnvironmentData, AwsEnvironmentDataExtraData, AwsEnvironmentTab } from 'common/module_interface/assets/AwsEnvironment';
import AwpTab from './aws/tabs/awp/AwpTab';
import { Vendors } from 'common/consts/vendors';
import EcsService from './services/ecs/ecs.service';
import { ecsImagesScanActivation, getEcsImageScanLabel } from './utils/ecsImageScan';
import { getUserService } from 'common/interface/services';

export const initialAwsEnvironmentTabs = () => {
    const actions: Addin<AwsEnvironmentAddingBase<AwsEnvironmentToolbarActions>>[] = [
        {
            id: 'ecs-image-scan',
            position: 7,
            content: {
                id: 'ecs-image-scan',
                isRelevant: () => getUserService().hasPermission(['allow_ecs_aws_image_tab']),
                getValue: (awsData) => {
                    return EcsService.getEcsImagesScanStatus(awsData.account.id).then(({ data: { ecsImageScan } }) => {
                        return {
                            type: 'button',
                            name: 'ecs-image-scan',
                            label: getEcsImageScanLabel(ecsImageScan),
                            callback: async (awsAccount: AwsEnvironmentData, extraData: AwsEnvironmentDataExtraData, reloadActions?: () => void) => {
                                ecsImagesScanActivation(ecsImageScan, awsData.account.id, reloadActions ?? (() => null));
                            },
                        };
                    });
                }
            }
        }
    ];
    const tabs: Addin<AwsEnvironmentAddingBase<AwsEnvironmentTab>>[] = [
        {
            id: 'serverless',
            position: 90,
            content: {
                id: 'serverless',
                isRelevant: (awsData, extraData) => extraData.serverlessAccount?.state.toLowerCase() === 'active',
                getValue: () => ({
                    name: 'serverless',
                    label: 'Serverless', 
                    isReactTab: false,
                })
            }
        },
        {
            id: 'awp',
            position: 91,
            content: {
                id: 'awp',
                getValue: (awsData) => ({
                    name: 'awp',
                    label: 'AWP', 
                    isReactTab: true,
                    content: <AwpTab cloudAccountId={awsData.account.id} platform={Vendors.AWS} />
                })
            }
        }
    ];
    AwsEnvironmentAddinRegistry.addTabs(tabs);
    AwsEnvironmentAddinRegistry.addActions(actions);
};
