import React from 'react';
import { getEntityActions, getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { generateAssetPageUrl } from 'common/module_interface/assets/utils';
import { PROTECTED_ASSETS_URL } from 'common/module_interface/assets/ProtectedAssets.consts';
import { getCloudEntityData } from 'common/components/ProtectedAssets/AssetUtils';
import { Asset, CveOverview } from 'modules/workloads/services/cveDrawerRegistry/cveDrawerRegistry.types';
import { EntityHeader, Spinner, Stack, Typography } from 'common/design-system/components-v2';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { InfoPanelActionProps } from 'common/components/InfoPane/InfoPane';
import { getEntityTypeValue } from 'common/components/ProtectedAssets/Renderers/ColumnDisplayValueGetters';
import { getVendorDisplayName } from 'common/consts/vendors';
import AssetPanelStyled from './AssetPanel.styled';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

export const AssetPanel: React.FC<{ asset: Asset, overview: CveOverview; title: string; }> = ({ asset, overview, title }) => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    const externalId = asset.externalId;
    const entityNameOrId = asset.entityName || asset.externalId;
    const selectedAsset = getProtectedAssetsService().getAssetByType(asset.entityType);
    
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [entityData, setEntityData] = React.useState<ICloudEntityData>();
    const [actions, setActions] = React.useState<InfoPanelActionProps[]>([]);

    const assetUrl = React.useMemo(() => {
        if (!selectedAsset) return undefined;

        const urlParms = selectedAsset.getUrl({
            asset: selectedAsset,
            entityId: asset.externalId,
            cloudAccountId: overview.cloudAccountId,
            entityType: asset.entityType,
            platform: selectedAsset.platform,
            dome9Id: asset.dome9Id,
            region: asset.region,
        });

        if (!urlParms) return undefined;
        return generateAssetPageUrl(selectedAsset, urlParms, PROTECTED_ASSETS_URL);
    }, [asset, overview, selectedAsset]);

    React.useEffect(() => {
        if (!selectedAsset) return;
        setIsLoading(true);
        getCloudEntityData(
            externalId,
            asset.entityType,
            selectedAsset.platform,
            overview.cloudAccountId,
            error => { throw error; },
        ).then((entityData) => {
            if (entityData) {
                setEntityData(entityData);
                getEntityActions(entityData, true).then((actions) => {
                    setActions(actions || []);
                });
            }
        }).catch((error) => {
            console.log(`CloudEntity could not find asset: ${`${asset.entityName}(${asset.externalId})`}, it might be deleted.`, error);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [asset.entityName, asset.entityType, asset.externalId, externalId, overview.cloudAccountId, selectedAsset]);

    const titleRightElement = React.useMemo(() => {
        if (actions.length === 0) return undefined;
        return (
            <Stack direction='row' spacing={2}>
                {actions.map((action, index) => (
                    <action.component key={index} {...action.componentProps} />
                ))}
            </Stack>
        );
    }, [actions]);

    if (!assetUrl || !selectedAsset) {
        return null;
    }

    if(!entityData) {
        return (
            <Stack spacing={2}>
                <Typography variant='body500'>{title}</Typography>
                <AssetPanelStyled.AssetPanelCard padding={6}>
                    <EntityHeader
                        title={entityNameOrId}
                        titleChip={isLoading ? undefined : {
                            label: t('cveDrawer.notFound'),
                        }}
                        levelIcon={{
                            iconProps: {
                                vendorNameOrPath: selectedAsset.icon,
                            },
                        }}
                        titleRightElements={isLoading ? <Spinner /> : undefined}
                        blocks={[
                            { title: t('cveDrawer.assetPanel.id'), info: asset.dome9Id, copyable: true },
                            { title: t('cveDrawer.assetPanel.type'), info: getEntityTypeValue(asset.entityType), copyable: true },
                            {
                                title: t('cveDrawer.assetPanel.environment'),
                                info: overview.cloudAccountName,
                                iconProps: { vendorNameOrPath: selectedAsset.platform },
                                internalUrl: `/cloud-account/${selectedAsset.platform}/${overview.cloudAccountId}`,
                                copyable: true,
                            },
                            { title: t('cveDrawer.assetPanel.platform'), info: getVendorDisplayName(overview.platform), copyable: true },
                        ]}
                        disableShowMore
                    />
                </AssetPanelStyled.AssetPanelCard>            
            </Stack>
        );
    }

    return (
        <Stack spacing={2}>
            <Typography variant='body500'>{title}</Typography>
            <AssetPanelStyled.AssetPanelCard padding={6}>
                <EntityHeader
                    title={entityNameOrId}
                    titleUrl={assetUrl}
                    levelIcon={{
                        iconProps: {
                            vendorNameOrPath: selectedAsset.icon,
                        },
                    }}
                    blocks={[
                        { title: t('cveDrawer.assetPanel.id'), info: entityData.protectedAsset.id, copyable: true },
                        { title: t('cveDrawer.assetPanel.type'), info: getEntityTypeValue(entityData.protectedAsset.type), copyable: true },
                        { title: t('cveDrawer.assetPanel.organizationalUnit'), info: entityData.organizationalUnitFullPath, copyable: true },
                        {
                            title: t('cveDrawer.assetPanel.environment'),
                            info: entityData.cloudAccount.name,
                            iconProps: { vendorNameOrPath: entityData.cloudAccount.platform },
                            internalUrl: `/cloud-account/${entityData.cloudAccount.platform}/${entityData.cloudAccountId}`,
                            copyable: true,
                        },
                        { title: t('cveDrawer.assetPanel.platform'), info: getVendorDisplayName(entityData.protectedAsset.platform), copyable: true },
                    ]}
                    disableShowMore
                    titleRightElements={titleRightElement}
                />
            </AssetPanelStyled.AssetPanelCard>            
        </Stack>
    );
};
