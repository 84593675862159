import { ImageAdmissionItem } from '../components/ImageAdmissionCard/ImageAdmissionCard';
import { ImageAdmissionIcon } from '../components/ImageAdmissionIcon/ImageAdmissionIcon';
import { TFunction } from 'i18next';

export const I18nK8sNamespace = 'k8s_policy';

export const unScannedVulnerabilitiesObj = (t: TFunction): { [key: string]: ImageAdmissionItem } => {
    return {
        Detection: {
            category: 'unScanned',
            id: '1',
            type: 'Detection',
            title: t('DETECT'),
            description: t('ADMISSION.UN_SCANNED_DETECT_DESCRIPTION'),
            icon: <ImageAdmissionIcon name={'detect'}/>,
            style: { minWidth: '250px' }
        },
        Prevention: {
            category: 'unScanned',
            type: 'Prevention',
            id: '2',
            title: t('PREVENT'),
            description: t('ADMISSION.UN_SCANNED_PREVENT_DESCRIPTION'),
            icon: <ImageAdmissionIcon name={'prevent'}/>,
            style: { minWidth: '250px' }
        },
        null: {
            category: 'unScanned',
            type: null,
            id: '3',
            title: t('DISABLE'),
            description: t('ADMISSION.UN_SCANNED_DISABLE_DESCRIPTION'),
            icon: <ImageAdmissionIcon name={'disable'}/>,
            style: { minWidth: '250px' }
        }
    };
};

export const nonCompliantAdmissionsObj = (t: TFunction): { [key: string]: ImageAdmissionItem } => {
    return {
        Detection: {
            category: 'nonCompliant',
            type: 'Detection',
            id: '4',
            title: t('DETECT'),
            description: t('ADMISSION.NON_COMPLIANT_DETECT_DESCRIPTION'),
            icon: <ImageAdmissionIcon name={'detect'}/>,
            style: { minWidth: '250px' }
        },
        Prevention: {
            category: 'nonCompliant',
            type: 'Prevention',
            id: '5',
            title: t('PREVENT'),
            description: t('ADMISSION.NON_COMPLIANT_PREVENT_DESCRIPTION'),
            icon: <ImageAdmissionIcon name={'prevent'}/>,
            style: { minWidth: '250px' }
        },
        null: {
            category: 'nonCompliant',
            type: null,
            id: '6',
            title: t('DISABLE'),
            description: t('ADMISSION.NON_COMPLIANT_DISABLE_DESCRIPTION'),
            icon: <ImageAdmissionIcon name={'disable'}/>,
            style: { minWidth: '250px' }
        },
    };
};

export const selectActionsObj = (t: TFunction): { [key: string]: ImageAdmissionItem } => {
    return {
        Detection: {
            id: '1',
            type: 'Detection',
            title: t('ADMISSION.SELECT_DETECTION_MODE'),
            description: t('ADMISSION.ACTION_DETECTION_DESCRIPTION'),
            icon: <ImageAdmissionIcon name={'detect'}/>,
            style: { minWidth: '350px' }
        },
        Prevention: {
            id: '2',
            type: 'Prevention',
            title: t('ADMISSION.SELECT_PREVENTION_MODE'),
            description: t('ADMISSION.ACTION_PREVENTION_DESCRIPTION'),
            icon: <ImageAdmissionIcon name={'prevent'}/>,
            style: { minWidth: '350px' }
        }
    };
};

