/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouterQuery from 'common/hooks/useReactRouterQuery';
import Wizard, { RenderViewParams } from 'common/components/Wizard/Wizard';
import { getRulesetService } from 'common/interface/services';
import { IPolicy, IShallowRuleset, IVendorRuleset } from 'common/interface/policy';
import { EnvironmentsSelectionComponent, resolveDisplayName } from 'common/components/policy/EnvironmentsSelectionComponent';
import { Vendors } from 'common/consts/vendors';
import { RulesetMultiSelectionComponent } from 'common/components/policy/RulesetMultiSelectionComponent';
import { getRulesets } from '../../initialize';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { Done } from 'common/components/policy/Done/Done';
import { getCloudAccountsService } from 'common/interface/data_services';
import { CloudAccount } from 'common/interface/environments';
import { finishFlow, getPolicyRulesets } from 'common/components/policy/utils';
import { TargetTypesEnum, ImageAdmissionItem } from '../../helpers/interface';
import { setAdmissionControlPolicies } from '../../helpers/utils';
import { I18nK8sNamespace } from '../../helpers/consts';
import { ActionSelectionComponent } from '../../components/ActionSelectionComponent/ActionSelectionComponent';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { useReturnUrl } from 'common/hooks/useReturnUrl';

const STEPS_IDS = {
    ENVIRONMENT: 'environment',
    RULESET: 'ruleset',
    NOTIFICATION: 'notification',
    ACTION: 'action',
    DONE: 'done'
};

export const AddAdmissionEnvPolicyPage: React.FC = () => {
    const { params } = useReactRouterQuery();
    const { t } = useTranslation(I18nK8sNamespace);
    const [selectedPlatform,setSelectedPlatform] = useState<Vendors>(Vendors.KUBERNETES);
    const [selectedEnvironments,setSelectedEnvironments] = useState<ListItem[]>([]);
    const [selectedRulesets,setSelectedRulesets] = useState<ListItem[]>();
    const [selectedNotifications,setSelectedNotifications] = useState<ListItem[]>();
    const [selectedAction, setSelectedAction] = React.useState<ImageAdmissionItem>();
    const [flowStatusMessage,setFlowStatusMessage] = useState<string>();
    const [flowStatusOK,setFlowStatusOK] = useState<boolean>();
    const returnUrl = useReturnUrl();
    const [stepsToDisplay, setStepsToDisplay] = useState({
        [STEPS_IDS.ENVIRONMENT]: true,
        [STEPS_IDS.RULESET]: true,
        [STEPS_IDS.ACTION]: true,
        [STEPS_IDS.NOTIFICATION]: true,
        [STEPS_IDS.DONE]: true
    });

    const selectedEnvironmentsChanged = (selectedItems: ListItem[]) => {
        setSelectedEnvironments(selectedItems);
    };
    useEffect(() => {
        const setPredefinedFields = async () => {
            const shouldDisplaySteps = { ...stepsToDisplay };
            const allEnvironments = await getCloudAccountsService().getAllCloudAccounts();
            const rulesetId = params?.rulesetId;
            const platformId = params?.platformId;
            rulesetId && handleRulsetPredefined(rulesetId, shouldDisplaySteps);
            platformId && handlePlatformPredefined(platformId, shouldDisplaySteps);
            if (allEnvironments.length === 1) {
                handleEnvironmentPredefined(allEnvironments[0], shouldDisplaySteps);
            }

            setStepsToDisplay(shouldDisplaySteps);
        };
        setPredefinedFields();
    }, []);

    const handleRulsetPredefined = async (rulesetId: string, shouldDisplaySteps: { [p:string]: boolean }) => {
        shouldDisplaySteps[STEPS_IDS.RULESET] = false;
        const allRulesets = await getRulesetService().getAllRulesets();
        const ruleset = allRulesets.find(ruleset => ruleset.id === +rulesetId);
        if (ruleset) {
            const rulesetItem: ListItem = {
                id: ruleset.id.toString(),
                name: ruleset.name
            };
            setSelectedRulesets([rulesetItem]);
        }
    };
    const handlePlatformPredefined = (platformId: string, shouldDisplaySteps: { [p:string]: boolean }) =>{
        setSelectedPlatform(platformId as Vendors);
        shouldDisplaySteps[STEPS_IDS.ENVIRONMENT] = false;
    };
    const handleEnvironmentPredefined = (environment: CloudAccount, shouldDisplaySteps: { [p:string]: boolean }) => {
        setSelectedEnvironments(
            [{
                id: environment.id,
                name: resolveDisplayName(environment),
                icon: selectedPlatform,
            }]
        );
        shouldDisplaySteps[STEPS_IDS.ENVIRONMENT] = false;
    };

    const rulesetProvider:IVendorRuleset[] = useMemo(() => ([
        {
            getRulesets: async () => {
                const allRulesets = await getPolicyRulesets();
                return allRulesets.filter((ruleset) => (ruleset.platform === Vendors.KUBERNETES_RUNTIME_ASSURANCE));
            },
            vendorId: Vendors.KUBERNETES_RUNTIME_ASSURANCE
        }
    ]),[]);

    const steps = [
        {
            id: STEPS_IDS.ENVIRONMENT,
            name: t('GENERAL.ENVIRONMENTS_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <EnvironmentsSelectionComponent preSelectedItems={selectedEnvironments} {...renderViewParams } platform={Vendors.KUBERNETES} selectedEnvironmentsChanged={selectedEnvironmentsChanged} />;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            id: STEPS_IDS.RULESET,
            name: t('GENERAL.RULESET_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <RulesetMultiSelectionComponent vendorRulesets={rulesetProvider} preSelectedItems={selectedRulesets} {...renderViewParams } selectedRulesetsChanged={setSelectedRulesets}/>;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            id: STEPS_IDS.ACTION,
            name: t('GENERAL.ACTION_SELECTION'),
            renderView: (onValidate:RenderViewParams) => {
                return <ActionSelectionComponent {...onValidate} preSelectedAction={selectedAction} selectedActionChanged={setSelectedAction} />;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            id: STEPS_IDS.ACTION,
            name: t('GENERAL.NOTIFICATION_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <NotificationsSelectionComponent preSelectedItems={selectedNotifications} {...renderViewParams } selectedNotificationsChanged={setSelectedNotifications}/>;
            },
            onNext: async ()=>{
                saveChanges();
            },
        },
        {
            id: STEPS_IDS.DONE,
            name: t('GENERAL.DONE'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <Done message={flowStatusMessage} {...renderViewParams} hasError={!flowStatusOK}></Done>;
            },
            onNext: async ()=>{
                finishFlow(returnUrl);
            },
        }
    ];
    const saveChanges = async () => {
        if (selectedRulesets?.length && selectedNotifications?.length && selectedAction){
            const allRulesets = await getRulesets();
            const policies:IPolicy[] = [];
            for (const ruleset of selectedRulesets) {
                for (const environment of selectedEnvironments) {
                    const _ruleset = allRulesets.find((_ruleset:IShallowRuleset) => _ruleset.id === ruleset.id);
                    const rulesetId = +ruleset.id;
                    const newPolicy:IPolicy = {
                        action: selectedAction?.type,
                        targetId: environment.id,
                        rulesetId: rulesetId,
                        rulesetPlatform: _ruleset?.platform,
                        notificationIds: selectedNotifications.map((notification:ListItem) => notification.id),
                        targetType: TargetTypesEnum.environment,
                    };
                    if (rulesetId < 0) {
                        newPolicy.rulesetVersion = ruleset.selectedVersion?.version;
                    }
                    policies.push(newPolicy);
                }
            }

            if (policies?.length) {
                setFlowStatusMessage(t('GENERAL.PROCESSING'));
                const result = await setAdmissionControlPolicies(policies);
                if(result) {
                    setFlowStatusOK(true);
                    setFlowStatusMessage(t('GENERAL.POLICY_ADDED_MESSAGE'));
                } else {
                    setFlowStatusOK(false);
                    setFlowStatusMessage(t('GENERAL.ERROR_OCCURRED'));
                }
            }
        }
    };

    return (
        <div>
            {steps?.length ? <Wizard
                onClose={()=>finishFlow(returnUrl)}
                steps={steps.filter(step => {return stepsToDisplay[step.id];})}
                title={t('GENERAL.ADD_POLICY')}
                doneButtonText={t('GENERAL.DONE')}
                ShowBackButtonInLastStep={false}
            /> : null}
        </div>
    );
};