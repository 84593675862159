import { A } from 'common/components/Anchor/Anchor';
import styled from 'styled-components';

const CustomAgGrid = styled.div`
    & .ag-center-cols-clipper {
        min-height: unset !important;
    }
    & .ag-details-row {
        padding: 10px;
    }
    & .ag-cell-wrapper {
        min-width: 5px;
        height: 100%;
    }
`;

const ALink = styled(A)`
    --opacity: 1;
    color: ${({ theme }) => theme.palette.status.info};
    &:visited {
        color: ${({ theme }) => theme.palette.status.info};
    }

    &:hover {
        text-decoration: underline;
    }
`;

export default {
    CustomAgGrid,
    ALink
};