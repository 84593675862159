import React from 'react';
import { Typography } from 'common/design-system/components-v2';
import { InstructionStepProps } from './InstructionStep.interface';
import { InstructionStepWrapper } from './InstructionStep.styled';

const InstructionStep: React.FC<InstructionStepProps> = ({ stepNumber, children, alignItems, style }) => {
    return (
        <InstructionStepWrapper direction='row' spacing={2} alignItems={alignItems} style={style}>
            <Typography>{`${stepNumber}.`}</Typography>
            {children}
        </InstructionStepWrapper>
    );
};

export default InstructionStep;
