import { FindingSeveritiesMap, getSafeFindingSeverityInfo } from 'common/consts/FindingSeverity';
import {
    Malware,
    Package,
    Category,
    RemediationSummary,
    Remediationflatten,
    Malwareflatten, FlatPackageAndCve,
    GetVulnerabilityScanMetadata
} from 'modules/workloads/services/vulnerability/vulnerability.interface';
import { capitalizeWords } from 'common/utils/helpFunctions';
import { isScanEngineVersion2 } from '../sbom/utils';
import { AssetTypes } from 'common/assets/assets.const';

export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc',
}

export const severityInfo = (text: string) => ({
    text: getSafeFindingSeverityInfo(text).displayText,
    color: getSafeFindingSeverityInfo(text).color,
    zeroBasedIndex: getSafeFindingSeverityInfo(text).level - FindingSeveritiesMap.informational.level,
    maxLevel: 5,
});

export const getThreatTypeDisplayName = (type: string) => {
    switch (type) {
        case 'MALICIOUS_URL':
            return 'Malicious URL';
        case 'MALICIOUS_IP':
            return 'Malicious IP';
        case 'MALICIOUS_FILE':
            return 'Malicious File';
        default:
            return pascalCase(type);
    }
};

export const pascalCase = (value: string) => value.replace(/\w+/g, (w) => w[0].toUpperCase() + w.slice(1).toLowerCase());

export const restructureThreats = (threats: Array<Malware>): Array<Malwareflatten> => {
    const threatsFlatten: Array<Malwareflatten> = [];
    threats.forEach((threat) => {
        if (!threat || !threat.files || !threat.files.length) {
            return;
        }

        threat.files.forEach((file: any) => {
            if (!file.contents || !file.contents.length) {
                threatsFlatten.push({
                    finding: file?.md5.length > 0 ? file.md5 : 'N/A',
                    type: getThreatTypeDisplayName(threat.type),
                    classification: threat.classification,
                    severity: pascalCase(threat.severity),
                    description: threat.description,
                    remediation: threat.remediation || '',
                    filePath: file['file-path'],
                    lines: 'N/A',
                    category: threat.category
                });
            }

            file.contents.forEach((content: any) => {
                threatsFlatten.push({
                    finding: content.payload,
                    type: getThreatTypeDisplayName(threat.type),
                    classification: threat.classification,
                    severity: pascalCase(threat.severity),
                    description: threat.description,
                    remediation: threat.remediation || '',
                    filePath: file['file-path'],
                    lines: content.lines.join(', '),
                    category: threat.category,
                });
            });

        });
    });
    return threatsFlatten;
};

const addPrefixToObjectKeys = (obj: { [key: string] : string | {} }, prefix: string) => {
    const objectWithPrefix: typeof obj = {};
    for (const key in obj) {
        if(key !== 'cves' && key !== 'relatedCves'){
            objectWithPrefix[prefix + capitalizeWords(key)] = obj[key];
        }
    }
    return objectWithPrefix;
};

export const flattenPackageAndCve = (packages: Array<Package>) => packages.reduce<Array<FlatPackageAndCve>>((acc, pack: any) => {
    return [ ...acc, ...pack.cves.map((cve: any) => ({ ...cve, ...addPrefixToObjectKeys(pack, 'package') }))];
}, []);

export const flattenRemediation = (remediationSummary: Array<RemediationSummary>) => {
    if(!remediationSummary.length) return [];

    const remediationflatten: Array<Remediationflatten> = [];

    remediationSummary.forEach(remediationSum => {
        remediationSum.categories.forEach(((categoryRemediation: Category) => {
            categoryRemediation.remediations.forEach((remediation) => {
                remediationflatten.push({
                    'category': categoryRemediation.category,
                    'file-path': remediationSum['file-path'],
                    ...remediation
                });
            });
        }));
    });
    return remediationflatten;
};

export enum VulnerabilityPageTabRender {
    NONE = 'NONE',
    V1 = 'V1',
    V2 = 'V2',
    AWP_EMPTY_STATE = 'AWP_EMPTY_STATE',
    FAILURE = 'FAILURE',
}

const awpAssetTypes = [
    AssetTypes.AWS_INSTANCE,
    AssetTypes.AZURE_VIRTUALMACHINE,
    AssetTypes.AZURE_FUNCTIONAPP,
];
        
const imagesAssetTypes = [
    AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE,
    AssetTypes.AWS_ECSIMAGE,
    AssetTypes.KUBERNETES_KUBERNETESIMAGE,
    AssetTypes.SHIFTLEFT_SHIFTLEFTIMAGE,
];
         
export const workloadAssetTypes = [
    AssetTypes.KUBERNETES_KUBERNETESDAEMONSET,
    AssetTypes.KUBERNETES_KUBERNETESDEPLOYMENT,
    AssetTypes.KUBERNETES_KUBERNETESPOD,
    AssetTypes.KUBERNETES_KUBERNETESREPLICASET,
    AssetTypes.KUBERNETES_KUBERNETESSTATEFULSET,
    AssetTypes.KUBERNETES_KUBERNETESCRONJOB,
];

export const getVulnerabilityPageTabType = async (
    typeByPlatform: string,
    vulnScanMetadataPromise: () => ReturnType<GetVulnerabilityScanMetadata.Function>
) => {
    if (![...workloadAssetTypes, ...imagesAssetTypes, ...awpAssetTypes].some(type => type === typeByPlatform)) {
        return Promise.resolve(VulnerabilityPageTabRender.NONE);
    }
    try {
        const { data: { scanInfo } } = await vulnScanMetadataPromise();
        return isScanEngineVersion2(scanInfo) ? VulnerabilityPageTabRender.V2 : VulnerabilityPageTabRender.V1;
    } catch (error: any) {
        if (error.response?.status === 404) {
            return awpAssetTypes.includes(typeByPlatform) ?
                VulnerabilityPageTabRender.AWP_EMPTY_STATE : VulnerabilityPageTabRender.V1;
        }
        return VulnerabilityPageTabRender.FAILURE;
    }
};