export const findingPageFilters = {
    ORGANIZATIONAL_UNIT_ID: {
        name: 'organizationalUnitId',
        value: '00000000-0000-0000-0000-000000000000'
    },
    PLATFORM: {
        KUBERNETES: {
            name: 'cloudAccountType',
            value: 11
        },
        CONTAINER_REGISTRY: {
            name: 'cloudAccountType',
            value: 20
        },
        SHIFT_LEFT: {
            name: 'cloudAccountType',
            value: 15
        },
    },
    CATEGORY: {
        INSECURE_CONTENT: {
            name: 'category',
            value: 'InsecureContent'
        }
    },
    SEVERITY: {
        HIGH: {
            name: 'severity',
            value: 'High'
        },
        CRITICAL: {
            name: 'severity',
            value: 'Critical'
        },
    },
    SHOW_ACKNOWLEDGED: {
        TRUE: {
            name: 'acknowledged',
            value: 'true'
        },
        FALSE: {
            name: 'acknowledged',
            value: 'false'
        },
    },
    SHOW_EXCLUDED: {
        TRUE: {
            name: 'isExcluded',
            value: 'true'
        },
        FALSE: {
            name: 'isExcluded',
            value: 'false'
        },
    },
    SOURCE: {
        COMPLIANCE_ENGINE: {
            name: 'origin',
            value: '0'
        },
    },
};

export const vulnerabilityPageFilters = {
    IS_HAS_REMEDIATION: {
        name: 'isHasRemediation',
        value: 'true'
    },
};

export const threatAndSecurityEventsPageFilters: any = {
    ORGANIZATIONAL_UNIT_ID: {
        name: 'organizationalUnitId',
        value: '00000000-0000-0000-0000-000000000000'
    },
    SOURCE: {
        INTELLIGENCE: { name: 'origin', value: '1' },
        IMAGE_SCANNING: { name: 'origin', value: '104' },
        ADDMISION_CONTROL: { name: 'origin', value: '105' },
        RUNTIME_PROTECTION: { name: 'origin', value: '106' },
    },
    SEVERITY: {
        CRITICAL: { name: 'severity', value: 'Critical' },
        HIGH: { name: 'severity', value: 'High' },
        MEDIUM: { name: 'severity', value: 'Medium' },
        LOW: { name: 'severity', value: 'Low' },
    },
    PLATFORM: {
        KUBERNETES: {
            name: 'cloudAccountType',
            value: 11
        },
        CONTAINER_REGISTRY: {
            name: 'cloudAccountType',
            value: 20
        },
        SHIFT_LEFT: {
            name: 'cloudAccountType',
            value: 15
        },
    },
    SHOW_ACKNOWLEDGED: {
        TRUE: {
            name: 'acknowledged',
            value: 'true'
        },
        FALSE: {
            name: 'acknowledged',
            value: 'false'
        },
    },
    SHOW_EXCLUDED: {
        TRUE: {
            name: 'isExcluded',
            value: 'true'
        },
        FALSE: {
            name: 'isExcluded',
            value: 'false'
        },
    },
};