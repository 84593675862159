import styled from 'styled-components';
import { Button } from '@dome9/berries/react-components';
import { Stack } from 'common/design-system/components-v2';

export const CustomStack = styled(Stack)`
  padding-left: 20px;
  margin-top: 30px;
  max-width: 50%;
`;

export const Title = styled.div`
  color: #333;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 10px;
`;

export const SubTitle = styled.div`
  color: #333;
  font-size: 13px;
  font-weight: 400;
`;

export const ButtonWithLoader = styled(Button)`
    gap: 5px;
    flex-direction: row-reverse;
`;