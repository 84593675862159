import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { Tooltip, Chip } from 'common/design-system/components-v2';
import { changeUrl } from 'common/utils/http';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

const AgentsVersionChipWidget: React.FC<{ entity: ICloudEntityData }> = (props) => {
    const { t } = useTranslation(getK8sNamespace('protected-asset-details'));
    const title = t('chips.agentNotSupported');
    const tooltip = t('chips.agentNotSupportedTooltip');
    const { entity: { cloudAccountId } } = props;

    return (
        <Tooltip placement='top' content={tooltip}>
            <Chip
                onClick={() => changeUrl(`/workload/environments/kubernetes/${cloudAccountId}?modalType=restore-install-command`)}
                leadingIconProps={{ name: 'warning' }}
                label={title}
            />
        </Tooltip>
    );
};

export default AgentsVersionChipWidget;

