import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContainerRegistryUserConfigurations, RegistryAccount, RegistryAccountSummary, RegistryAgentSummary } from 'modules/workloads/services/containerRegistry/containerRegistry.interface';
import { RegistriesInitialState, RegistriesState } from './registries.interface';

const RegistriesSlice = createSlice({
    name: 'k8s_registry',
    initialState: RegistriesInitialState,
    reducers: {
        setLoading: (state: RegistriesState, action: PayloadAction<RegistriesState['registriesLoading']>) => {
            state.registriesLoading = action.payload;
        },
        setError: (state: RegistriesState, action: PayloadAction<RegistriesState['registriesError']>) => {
            state.registriesError = action.payload;
        },
        setData: (state: RegistriesState, action: PayloadAction<RegistriesState['registries']>) => {
            state.registries = action.payload;
        },
        setAccountData: (state: RegistriesState, action: PayloadAction<{ 
            registryId: string;
            account: RegistryAccount;
            accountSummary: RegistryAccountSummary;
            agentSummary: Array<RegistryAgentSummary>;
        }>) => {
            const { registryId, account, accountSummary, agentSummary } = action.payload;
            if (!state.extendedRegistries[registryId]) {
                state.extendedRegistries[registryId] = {};
            }
            state.extendedRegistries[registryId].data = { account, accountSummary, agentSummary };
        },
        setAccountLoading: (state: RegistriesState, action: PayloadAction<{ registryId: string; loading: boolean; }>) => {
            const { registryId, loading } = action.payload;
            if (!state.extendedRegistries[registryId]) {
                state.extendedRegistries[registryId] = {};
            }
            state.extendedRegistries[registryId].loading = loading;
        },
        setAccountError: (state: RegistriesState, action: PayloadAction<{ registryId: string; error: boolean; }>) => {
            const { registryId, error } = action.payload;
            if (!state.extendedRegistries[registryId]) {
                state.extendedRegistries[registryId] = {};
            }
            state.extendedRegistries[registryId].error = error;
        },
        setAccountDeleted: (state: RegistriesState, action: PayloadAction<{ registryId: string; }>) => {
            const { registryId } = action.payload;
            if (!state.extendedRegistries[registryId]) {
                state.extendedRegistries[registryId] = {};
            }
            state.extendedRegistries[registryId].deleted = true;
        },
        setAccountSettingsData: (state: RegistriesState, action: PayloadAction<{ 
            registryId: string;
            accountSettings: ContainerRegistryUserConfigurations;
        }>) => {
            const { registryId, accountSettings } = action.payload;
            if (!state.registrySettings[registryId]) {
                state.registrySettings[registryId] = {};
            }
            state.registrySettings[registryId].data = accountSettings;
        },
        setAccountSettingsLoading: (state: RegistriesState, action: PayloadAction<{ registryId: string; loading: boolean; }>) => {
            const { registryId, loading } = action.payload;
            if (!state.registrySettings[registryId]) {
                state.registrySettings[registryId] = {};
            }
            state.registrySettings[registryId].loading = loading;
        },
        setAccountSettingsError: (state: RegistriesState, action: PayloadAction<{ registryId: string; error: boolean; }>) => {
            const { registryId, error } = action.payload;
            if (!state.registrySettings[registryId]) {
                state.registrySettings[registryId] = {};
            }
            state.registrySettings[registryId].error = error;
        },
    }
});

export default RegistriesSlice;