import { LinkedScanningEnvironment } from 'modules/workloads/services/containerRegistry/containerRegistry.interface';
import React from 'react';
import RegistriesActions from '../registries.actions';

export interface UseLinkEnvironmentReq {
    onError?: (error: { message: string; }) => void;
    onSuccess?: (result: boolean) => void;
}

export interface UseLinkEnvironmentPayload {
    isLoading: boolean;
    isSuccess: boolean;
    isError?: { message: string; };

    linkEnvironment: (registryId: string, body: LinkedScanningEnvironment.Request) => Promise<void>;
    reset?: () => void;
}

export type UseLinkEnvironment = (req?: UseLinkEnvironmentReq) => UseLinkEnvironmentPayload;

export const useLinkEnvironment: UseLinkEnvironment = ({ onSuccess, onError } = {}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isError, setIsError] = React.useState<{ message: string; }>();

    const linkEnvironment = React.useCallback(async (registryId: string, body: LinkedScanningEnvironment.Request) => {
        setIsLoading(true);
        try {
            const result = await RegistriesActions.linkCluster(registryId, body);
            setIsSuccess(result);
            onSuccess?.(result);
        } catch (error: any) {
            setIsError({ message: error.message });
            onError?.({ message: error.message });
        }
        setIsLoading(false);
    }, [onSuccess, onError]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(undefined);
    }, []);

    return ({
        isLoading,
        isSuccess,
        isError,

        linkEnvironment,
        reset
    });
};