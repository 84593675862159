import { GetAgentlessSettings, UpdateAgentlessSettings } from 'modules/workloads/services/awp/awp.interface';
import { AwpSettingsFields } from './AwpSettings.types';

export const toHash = (tagList: { key: string, value: string }[]) => {
    return tagList.reduce((acc, cur) => {
        return { ...acc, [cur.key]: cur.value };
    }, {});
};

export const toList = (tagHash: Record<string, string>) => {
    return Object.keys(tagHash).reduce<Array<{ key: string, value: string }>>((acc, cur) => {
        return [...acc, { key: cur, value: tagHash[cur] }];
    }, []);
};

export const DEFAULT_VALUES: AwpSettingsFields = {
    scanMachineIntervalInHours: '24',
    maxConcurrenceScansPerRegion: '20',
    customTags: [],
};

export const serializeAwpSettings = (settings: GetAgentlessSettings.Response) => {
    return Object.keys(settings).reduce<AwpSettingsFields>((acc, cur) => {
        const key = cur as keyof GetAgentlessSettings.Response;
        switch (key) {
            case 'customTags':
                return { ...acc, [key]: toList(settings[key] ?? {}) };
            case 'scanMachineIntervalInHours':
            case 'maxConcurrenceScansPerRegion':
                if(!settings[key]) {
                    return acc;
                }
                return { ...acc, [key]: settings[key]?.toString() ?? '' };
        }
    }, DEFAULT_VALUES);
};

export const deserializeAwpSettings = (settings: AwpSettingsFields) => {
    return Object.keys(settings).reduce<UpdateAgentlessSettings.Request>((acc, payloadKey) => {
        if (payloadKey === 'customTags') {
            return { ...acc, [payloadKey]: toHash(settings[payloadKey]) };
        }
        if (payloadKey === 'scanMachineIntervalInHours' || payloadKey === 'maxConcurrenceScansPerRegion' && settings[payloadKey] !== null) {    
            return { ...acc, [payloadKey]: parseInt(settings[payloadKey], 10) };
        }
        return acc;
    }, {});
};