import { IProtectedAssetDetailProvider } from 'common/module_interface/assets/ProtectedAssets';
import { InfoPaneFieldProps } from 'common/components/InfoPane/InfoPane';
import KubernetesEnvironmentLink from '../components/KubernetesEnvironmentLink';
import ImageScanInfo from '../components/MoreInfoImage';
import i18n from 'common/services/translations/translations';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';
import { getFieldByNameFromAdditionalField } from 'modules/workloads/utils';
import { AssetTypes } from 'common/assets/assets.const';

const isRelevantImageInfoPanel = (typeByPlatform: string) => [AssetTypes.KUBERNETES_KUBERNETESIMAGE, AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE, AssetTypes.SHIFTLEFT_SHIFTLEFTIMAGE, AssetTypes.AWS_ECSIMAGE].includes(typeByPlatform);

export function initializeInfoPaneFieldProps() {
    const kubernetesEnvironmentField: IProtectedAssetDetailProvider<InfoPaneFieldProps> = {
        isRelevant: ({ typeByPlatform }) => {
            return [AssetTypes.AWS_EKSCLUSTER, AssetTypes.AZURE_AKSCLUSTER, AssetTypes.GCP_GKECLUSTER].includes(typeByPlatform);
        },
        getValue({ protectedAsset }): InfoPaneFieldProps {
            return {
                title: i18n.t(`${getK8sNamespace('protected-asset-details')}:infoPane.title`),
                value: KubernetesEnvironmentLink,
                componentProps: { dome9Id: protectedAsset.id },
            };
        },
    };

    const imageInfoPanelScanStatus: IProtectedAssetDetailProvider<InfoPaneFieldProps> = {
        isRelevant: ({ typeByPlatform }) => isRelevantImageInfoPanel(typeByPlatform),
        getValue({ protectedAsset: { additionalFields, vulnerability } }): InfoPaneFieldProps {
            const scanStatus = vulnerability?.scanStatus || getFieldByNameFromAdditionalField(additionalFields)('ScanStatus')?.value;
            return {
                title: i18n.t(`${getK8sNamespace('protected-asset-details')}:infoPane.ScanStatus`),
                value: ImageScanInfo,
                componentProps: { field: scanStatus },
            };

        },
    };


    ProtectedAssetsPageRegistry.addFieldAddins([
        {
            id: 'kubernetesEnvironment',
            position: 90,
            content: kubernetesEnvironmentField,
        },
        {
            id: 'imageInfoPanelScanStatus',
            position: 90,
            content: imageInfoPanelScanStatus,
        },
    ]);
}