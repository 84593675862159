import { ScanMode } from 'modules/workloads/services/awp/awp.interface';

export const MIN_SCAN_MACHINE_INTERVAL_IN_HOURS_SAAS = 24;
export const MIN_SCAN_MACHINE_INTERVAL_IN_HOURS = 4;
export const MAX_SCAN_MACHINE_INTERVAL_IN_HOURS = 1000;
export const MAX_CONCURRENCE_SCANS_PER_REGION = 20;
export const MIN_CONCURRENCE_SCANS_PER_REGION = 1;

export const DEFAULT_SCAN_MACHINE_INTERVAL_IN_HOURS = 24;
export const DEFAULT_CONCURRENCE_SCANS_PER_REGION = 20;

export const getMinScanMachineIntervalInHours = (scanMode: ScanMode) => scanMode === ScanMode.Saas ? MIN_SCAN_MACHINE_INTERVAL_IN_HOURS_SAAS : MIN_SCAN_MACHINE_INTERVAL_IN_HOURS;