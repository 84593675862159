import React from 'react';
import { Icon } from '@dome9/berries/react-components';
import { useTranslation } from 'react-i18next';
import { SeeDocumentationWrapper } from './SeeDocumentation.styled';

export interface SeeDocumentationProps {
    link?: string;
    showText?: boolean
}

const SeeDocumentation: React.FunctionComponent<SeeDocumentationProps> = (props) => {
    const { link, showText = true } = props;
    const { t } = useTranslation('k8s_common');

    return (
        <SeeDocumentationWrapper href={link} target='_blank' rel="noreferrer">
            <Icon name='book' size={12} color='info' />
            {showText && t('seeDocumentation')}
        </SeeDocumentationWrapper>
    );
};

export default SeeDocumentation;
