import { BlockInfo } from 'common/design-system/components-v2';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { CveOverview } from 'modules/workloads/services/cveDrawerRegistry/cveDrawerRegistry.types';
import { useTranslation } from 'react-i18next';

const DescriptionPanel = ({ overview: { cve: { description } } }: { overview: CveOverview }) => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));

    return (
        <BlockInfo
            title={t('cveDrawer.descriptionPanel.title')}
            info={description}
        />
    );
};

export default DescriptionPanel;