import styled from 'styled-components';


const DashboardWrapper = styled.div`
  padding: 16px 24px;
`;


export const DashboardStyled = {
    DashboardWrapper
};