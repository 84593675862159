import { IWelcomePageSection } from 'common/components/WelcomePage/WelcomePage.interface';
import { changeUrl } from 'common/utils/http';
import { TFunction } from 'i18next';
import { whatsNewSection } from './WelcomePage.whatsNewSection';
import { isChina } from 'common/utils/dataCenter';

export const workLoadWelcomePageSections: (t: TFunction) => Array<IWelcomePageSection> = (t) => [
    ...(isChina() ? [] : [whatsNewSection(t)]),
    {
        title: t('OVERVIEW.TITLE'),
        subtitle: t('OVERVIEW.SUBTITLE'),
        items: [
            {
                title: t('OVERVIEW.CONTAINERS_DASHBOARD.TITLE'),
                subtitle: t('OVERVIEW.CONTAINERS_DASHBOARD.SUBTITLE'),
                icon: 'kubernetes',
                linkTo: '/workload/dashboard',
            },
            {
                title: t('OVERVIEW.GENERAL_DASHBOARD.TITLE'),
                subtitle: t('OVERVIEW.GENERAL_DASHBOARD.SUBTITLE'),
                icon: 'kubernetes',
                onClick: () => changeUrl('/?name=Kubernetes%20Default'),
            },
            {
                title: t('OVERVIEW.SERVERLESS_DASHBOARD.TITLE'),
                subtitle: t('OVERVIEW.SERVERLESS_DASHBOARD.SUBTITLE'),
                icon: 'cloudFunction',
                onClick: () => changeUrl('/?name=Serverless%20Default'),
            },
        ],
    },
    {
        title: t('ENVIRONMENTS.TITLE'),
        subtitle: t('ENVIRONMENTS.SUBTITLE'),
        items: [
            {
                title: t('ENVIRONMENTS.CLUSTERS.TITLE'),
                subtitle: t('ENVIRONMENTS.CLUSTERS.SUBTITLE'),
                icon: 'kubernetes',
                onClick: () =>
                    changeUrl(
                        '/workload/environments?query=%7B"filter":%7B"freeTextPhrase":"","filterFields":%5B%7B"name":"organizationalUnitId"%7D,%7B"name":"resource-type","value":"kubernetes"%7D%5D%7D%7D'
                    ),
                cardButton: {
                    text: t('k8s_common:ADD_ENVIRONMENT'),
                    icon: 'plus',
                    onClick: () => changeUrl('/workload/cloud-add/kubernetes'),
                },
            },
            {
                title: t('ENVIRONMENTS.REGISTRIES.TITLE'),
                subtitle: t('ENVIRONMENTS.REGISTRIES.SUBTITLE'),
                icon: 'containers-registry',
                onClick: () => {
                    changeUrl(
                        '/workload/environments?query=%7B"filter":%7B"freeTextPhrase":"","filterFields":%5B%7B"name":"organizationalUnitId"%7D,%7B"name":"resource-type","value":"containerregistry"%7D%5D%7D%7D'
                    );
                },
                cardButton: {
                    text: t('k8s_common:ADD_ENVIRONMENT'),
                    icon: 'plus',
                    onClick: () => {
                        changeUrl('/workload/registry-onboarding');
                    },
                },
            },
            {
                title: t('ENVIRONMENTS.SHIFTLEFT.TITLE'),
                subtitle: t('ENVIRONMENTS.SHIFTLEFT.SUBTITLE'),
                icon: 'shiftleft',
                onClick: () =>
                    changeUrl(
                        '/cloud-account/index?query=%7B"filter":%7B"freeTextPhrase":"","filterFields":%5B%7B"name":"organizationalUnitId"%7D,%7B"name":"resource-type","value":"shiftleft"%7D%5D%7D%7D'
                    ),
                cardButton: {
                    text: t('k8s_common:ADD_ENVIRONMENT'),
                    icon: 'plus',
                    onClick: () => changeUrl('/workload/cloud-add/shiftleft')
                },
            },
        ],
    },
    {
        title: t('ASSETS.TITLE'),
        subtitle: t('ASSETS.SUBTITLE'),
        items: [
            {
                title: t('ASSETS.SERVERLESS_FUNCTIONS.TITLE'),
                subtitle: t('ASSETS.SERVERLESS_FUNCTIONS.SUBTITLE'),
                icon: 'cloudFunction',
                onClick: () => changeUrl('/workload/serverless/assets'),
            },
            {
                title: t('ASSETS.CONTAINERS.TITLE'),
                subtitle: t('ASSETS.CONTAINERS.SUBTITLE'),
                icon: 'kubernetes',
                onClick: () => changeUrl('/workload/environments'),
            },
            {
                title: t('ASSETS.IMAGES.TITLE'),
                subtitle: t('ASSETS.IMAGES.SUBTITLE'),
                icon: 'container-image',
                onClick: () => changeUrl('/workload/images'),
            },
        ],
    },
    {
        title: t('IMAGE_ASSURANCE.TITLE'),
        subtitle: t('IMAGE_ASSURANCE.SUBTITLE'),
        items: [
            {
                title: t('IMAGE_ASSURANCE.RULESETS.TITLE'),
                subtitle: t('IMAGE_ASSURANCE.RULESETS.SUBTITLE'),
                icon: 'rule',
                onClick: () => changeUrl('/workload/rulesets'),
            },
            {
                title: t('IMAGE_ASSURANCE.POLICIES.TITLE'),
                subtitle: t('IMAGE_ASSURANCE.POLICIES.SUBTITLE'),
                icon: 'book',
                onClick: () => changeUrl('/workload/policies'),
            },
        ],
    },
    {
        title: t('KUBERNETES_ADMISSION_CONTROL.TITLE'),
        subtitle: t('KUBERNETES_ADMISSION_CONTROL.SUBTITLE'),
        items: [
            {
                title: t('KUBERNETES_ADMISSION_CONTROL.RULESETS.TITLE'),
                subtitle: t('KUBERNETES_ADMISSION_CONTROL.RULESETS.SUBTITLE'),
                icon: 'rule',
                onClick: () => changeUrl('/runtime-assurance'),
            },
            {
                title: t('KUBERNETES_ADMISSION_CONTROL.POLICIES.TITLE'),
                subtitle: t('KUBERNETES_ADMISSION_CONTROL.POLICIES.SUBTITLE'),
                icon: 'book',
                onClick: () => changeUrl('/runtime-assurance/policies'),
            },
        ],
    },
];
