import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
    ErrorMessageStyled, IconWrapperLinux,
    IconWrapperMac, IconWrapperWindows
} from '../../ShiftleftOnboarding/steps/OperatingSystem/OperatingSystem.styled';
import { OperatingSystemsEnum } from '../../ShiftleftOnboarding/steps/OperatingSystem/OperatingSystem.types';
import { Label } from '@dome9/berries/react-components';
import { Stack } from 'common/design-system/components-v2';
import { Direction } from 'common/design-system/components-v2/Stack/Stack.types';

const OPERATING_SYSTEMS = [
    {
        id: '0',
        label: 'Windows',
        name: OperatingSystemsEnum.Windows,
        value: OperatingSystemsEnum.Windows,
        icon: <IconWrapperWindows />

    },
    {
        id: '1',
        label: 'Linux',
        name: OperatingSystemsEnum.Linux,
        value: OperatingSystemsEnum.Linux,
        icon: <IconWrapperLinux />
    },
    {
        id: '2',
        label: 'Mac',
        name: OperatingSystemsEnum.Mac,
        value: OperatingSystemsEnum.Mac,
        icon: <IconWrapperMac />
    },
];

interface SelectOSProps {
    direction?: Direction;
}

const SelectOS: React.FC<SelectOSProps> = ({ direction = 'row' }) => {
    const { control, formState: { errors } } = useFormContext();
    
    return(
        <Stack spacing={2}>
            <Label label={'Choose your operating system:'}></Label>
            <Controller
                name={'operatingSystems'}
                control={control}
                rules={{ required: 'Please select an operating system.' }}
                render={({ field }) => (
                    <Stack spacing={8} direction={direction}>
                        {OPERATING_SYSTEMS.map(operatingSystem => (
                            <label key={operatingSystem.id} htmlFor={operatingSystem.id}>
                                <Stack spacing={1} direction={direction} alignItems={'center'}>
                                    <input
                                        {...field}
                                        required
                                        checked={field.value === operatingSystem.value}
                                        id={operatingSystem.id}
                                        type="radio"
                                        value={operatingSystem.value}
                                        name='operatingSystems'
                                    />
                                    {operatingSystem.icon}
                                    <span>{operatingSystem.label}</span>
                                </Stack>
                            </label>
                        ))}
                    </Stack>
                )}
            />
            <ErrorMessage
                errors={errors}
                name="operatingSystems"
                render={({ message }) => <ErrorMessageStyled>{message}</ErrorMessageStyled>}
            />
        </Stack>
    );
};

export default SelectOS;