import React from 'react';
import { useFormContext } from 'react-hook-form';
import Content from '../../WizardStep/Content';
import Footer from '../../WizardStep/Footer';
import Container from '../../WizardStep/Container';
import { Button } from '@dome9/berries/react-components';
import InstructionsContent from '../../../components/InstructionsContent/InstructionsContent';
import { useTranslation } from 'react-i18next';

interface InstructionsProps {
    onFinish?: () => void;
    onBack?: () => void;
}

const Instructions: React.FC<InstructionsProps> = ({ onFinish, onBack }) => {
    const { getValues } = useFormContext();
    const apiSecret = getValues('apiSecret');
    const apiKey = getValues('apiKey');
    const envId = getValues('envId');
    const { t } = useTranslation('k8s_common');

    return (
        <Container>
            <Content>
                <InstructionsContent
                    envId={envId}
                    apiKey={apiKey}
                    apiSecret={apiSecret}
                />
            </Content>
            <Footer>
                <Button onClick={onBack}>{t('BACK')}</Button>
                <Button color={'primary'} onClick={onFinish}>{t('FINISH')}</Button>
            </Footer>
        </Container>
    );
};

export default Instructions;