import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormFieldModel } from 'common/interface/formFieldModel';
import { RootState } from 'common/services/store/store';
import { getHelmCommand as _getHelmCommand } from '../../utils/KubernetesOnBoarding.helm.service';
import { RegistryAuthMethod, RegistryType, ScanningEnvironmentType } from '../../utils/RegistryOptions/RegistryOptions';
import { Registry, RegistryAgentSummary, RegistryForm, RegistryState } from './registry.interface';
import { initialState } from './registry.state';

export const RegistrySlice = createSlice({
    name: 'registry',
    initialState,
    reducers: {
        goNextStep: (state: RegistryState) => {
            state.step += 1;
        },
        goBackStep: (state: RegistryState) => {
            state.step -= 1;
        },
        goToSpecificStep: (state: RegistryState, action: PayloadAction<RegistryState['step']>) => {
            state.step = action.payload;
        },
        setServiceAccounts: (state: RegistryState, action: PayloadAction<any[]>) => {
            state.serviceAccounts = action.payload;
        },
        setNewRegistry: (state: RegistryState, action: PayloadAction<any>) => {
            state.newRegistry = action.payload;
        },
        setRegistryName: (state: RegistryState, action: PayloadAction<FormFieldModel['value']>) => {
            state.registryForm.name.value = action.payload;
            if ((action?.payload?.length ?? 0) === 0) {
                state.registryForm.name.isError = true;
            } else {
                state.registryForm.name.isDirty = true;
                state.registryForm.name.isError = false;
            }
        },
        setRegistryDescription: (state: RegistryState, action: PayloadAction<string>) => {
            state.registryForm.description = action.payload;
        },
        setOrganizationalUnitId: (state: RegistryState, action: PayloadAction<string>) => {
            state.registryForm.organizationalUnitId = action.payload;
        },
        setScanningEnvironmentType: (state: RegistryState, action: PayloadAction<ScanningEnvironmentType>) => {
            state.registryForm.scanningEnvironmentType = action.payload;
        },
        setRegistryUrl: (state: RegistryState, action: PayloadAction<FormFieldModel['value']>) => {
            const notAllowedPrefix = ['http://', 'https://'];
            const notAllowedSufix = ['/'];

            state.registryForm.registryUrl.value = action.payload;

            if (
                notAllowedPrefix.some((prefix) => state.registryForm.registryUrl.value.trim().startsWith(prefix))
                || notAllowedSufix.some((prefix) => state.registryForm.registryUrl.value.trim().endsWith(prefix))
            ) {
                state.registryForm.registryUrl.errorMessage = 'Registry URI cannot include prefix of \'http://\' , \'https://\' or suffix of \'/\'';
                state.registryForm.registryUrl.isError = true;
            } else if ((action?.payload?.length ?? 0) === 0) {
                state.registryForm.registryUrl.errorMessage = 'Registry URI can\'t be empty';
                state.registryForm.registryUrl.isError = true;
            } else {
                state.registryForm.registryUrl.isError = false;
                state.registryForm.registryUrl.isDirty = true;
            }
        },
        setRegistryUrlErrorStatus: (state: RegistryState) => {
            state.registryForm.registryUrl.isError = true;
            state.registryForm.registryUrl.isDirty = true;
        },
        setRegistryUrlErrorMessage: (state: RegistryState, action: PayloadAction<string>) => {
            state.registryForm.registryUrl.errorMessage = action.payload;
        },
        setRegistryType: (state: RegistryState, action: PayloadAction<RegistryForm['registryType']>) => {
            state.registryForm.registryType = action.payload;
        },
        setRegistryAuthMethod: (state: RegistryState, action: PayloadAction<RegistryAuthMethod>) => {
            state.registryForm.authMethod = action.payload;
        },
        setRegistryApplicationClientId: (state: RegistryState, action: PayloadAction<FormFieldModel['value']>) => {
            state.registryForm.applicationClientId.value = action.payload;
            if ((action?.payload?.length ?? 0) === 0) {
                state.registryForm.applicationClientId.isError = true;
            } else {
                state.registryForm.applicationClientId.isError = false;
                state.registryForm.applicationClientId.isDirty = true;
            }
        },
        setRegistryApplicationClientIdErrorStatus: (state: RegistryState) => {
            state.registryForm.applicationClientId.isError = true;
            state.registryForm.applicationClientId.isDirty = true;
        },
        setRegistryPullSecretName: (state: RegistryState, action: PayloadAction<FormFieldModel['value']>) => {
            state.registryForm.pullSecretName.value = action.payload;
            if ((action?.payload?.length ?? 0) === 0) {
                state.registryForm.pullSecretName.isError = true;
            } else {
                state.registryForm.pullSecretName.isError = false;
                state.registryForm.pullSecretName.isDirty = true;
            }
        },
        setRegistryPullSecretNameErrorStatus: (state: RegistryState) => {
            state.registryForm.pullSecretName.isError = true;
            state.registryForm.pullSecretName.isDirty = true;
        },
        setRegistryTenantId: (state: RegistryState, action: PayloadAction<any>) => {
            state.registryForm.tenantId.value = action.payload;
            if ((action?.payload?.length ?? 0) === 0) {
                state.registryForm.tenantId.isError = true;
            } else {
                state.registryForm.tenantId.isError = false;
                state.registryForm.tenantId.isDirty = true;
            }
        },
        setRegistryTenantIdErrorStatus: (state: RegistryState) => {
            state.registryForm.tenantId.isError = true;
            state.registryForm.tenantId.isDirty = true;
        },
        setRegistryRoleARN: (state: RegistryState, action: PayloadAction<any>) => {
            state.registryForm.roleARN.value = action.payload;
            if ((action?.payload?.length ?? 0) === 0) {
                state.registryForm.roleARN.isError = true;
            } else {
                state.registryForm.roleARN.isError = false;
                state.registryForm.roleARN.isDirty = true;
            }
        },
        setRegistryRoleARNErrorStatus: (state: RegistryState) => {
            state.registryForm.roleARN.isError = true;
            state.registryForm.roleARN .isDirty = true;
        },
        toggleImageAssuranceEnablePopup: (
            state: RegistryState,
            action: PayloadAction<RegistryState['isImageAssuranceEnablePopupOpen']>
        ) => {
            state.isImageAssuranceEnablePopupOpen = action.payload;
        },
        setIsImageAssuranceEnabledWhileOnboarding: (
            state: RegistryState,
            action: PayloadAction<RegistryState['isImageAssuranceEnabledWhileOnboarding']>
        ) => {
            state.isImageAssuranceEnabledWhileOnboarding = action.payload;
        },
        setRegistryAccountSummary: (
            state: RegistryState,
            action: PayloadAction<RegistryState['registryAccountSummary']>
        ) => {
            state.registryAccountSummary = action.payload;
        },
        setImageScanAgentVersion: (state: RegistryState, action: PayloadAction<any>) => {
            state.imageScanAgentVersion = action.payload;
        },
        toggleIsLoading: (state: RegistryState, action: PayloadAction<RegistryState['isLoading']>) => {
            state.isLoading = action.payload;
        },
        clearRegistryOnboardingState: () => initialState,
        setSelectedRegistry: (state: RegistryState, action: PayloadAction<RegistryState['selectedRegistry']>) => {
            state.selectedRegistry = action.payload;
        },
        setRegistryAgentSummary: (state: RegistryState, action: PayloadAction<Array<RegistryAgentSummary>>) => {
            state.registryAgentSummary = action.payload;
        },
        setAllRegistries: (state: RegistryState, action: PayloadAction<Array<Registry>>) => {
            state.allRegistries = action.payload;
        }
    },
});

export const getStep = (state: RootState): number => state.registry.step;
export const getIsLoading = (state: RootState): boolean => state.registry.isLoading;
export const canGoForward = (state: RootState): boolean => {
    const { scanningEnvironmentType } = state.registry.registryForm;
    if (scanningEnvironmentType === ScanningEnvironmentType.Kubernetes) return 3 > state.registry.step;
    if (scanningEnvironmentType === ScanningEnvironmentType.AWS) return 2 > state.registry.step;
    return false;
};
export const canGoBack = (state: RootState): boolean => state.registry.step > 0;
export const getServiceAccounts = (state: RootState): any[] => state.registry.serviceAccounts;
export const getRegistryName = (state: RootState): any => state.registry.registryForm.name;
export const getRegistryDescription = (state: RootState): any => state.registry.registryForm.description;
export const getOrganizationalUnitId = (state: RootState): any => state.registry.registryForm.organizationalUnitId;
export const getScanningEnvironmentType = (state: RootState): ScanningEnvironmentType => state.registry.registryForm.scanningEnvironmentType;
export const getSelectedRegistry = (state: RootState): any => state.registry.selectedRegistry;
export const getRegistryAccountSummary = (state: RootState): any => state.registry.registryAccountSummary;
export const getRegistryType = (state: RootState): RegistryType => state.registry.registryForm.registryType;
export const getRegistryUrl = (state: RootState): any => state.registry.registryForm.registryUrl;
export const getRegistryAuthMethod = (state: RootState): any => state.registry.registryForm.authMethod;
export const getRegistryApplicationClientId = (state: RootState): any => state.registry.registryForm.applicationClientId;
export const getRegistryPullSecretName = (state: RootState): any => state.registry.registryForm.pullSecretName;
export const getRegistryTenantId = (state: RootState): any => state.registry.registryForm.tenantId;
export const getRegistryRoleARN = (state: RootState): any => state.registry.registryForm.roleARN;
export const getNewRegistry = (state: RootState): any => state.registry.newRegistry;
export const getAllRegistries = (state: RootState): Array<Registry> => state.registry.allRegistries;
// export const getNewCluster = (state: RootState): any => state.registry.newCluster;
// export const getRelevantCluster = (state: RootState): any => state.registry.newCluster || state.registry.selectedCluster;
export const getImageAssuranceEnablePopupOpen = (state: RootState): any => state.registry.isImageAssuranceEnablePopupOpen;
export const getHelmCommand = (state: RootState): string | null => {
    const { namespace, apiKey, apiSecret } = state.cluster.clusterForm;
    const { newRegistry } = state.registry;
    const { selectedCluster, newCluster } = state.cluster;
    const registryUrl = state.registry.registryForm.registryUrl;
    const relevantCluster = selectedCluster || newCluster;

    const shouldRender =
        namespace?.value !== '' &&
        registryUrl?.value !== '' &&
        apiKey?.value !== '' &&
        apiSecret?.value !== '' &&
        newRegistry &&
        relevantCluster;

    if (shouldRender) {
        return _getHelmCommand({ 
            apiKey: apiKey.value,
            apiSecret: apiSecret.value,
            cluster: {
                id: relevantCluster?.id,
                admissionControlEnabled: relevantCluster?.admissionControlEnabled,
                imageAssuranceEnabled: relevantCluster?.imageAssuranceEnabled,
                runtimeProtectionEnabled: relevantCluster?.runtimeProtectionEnabled,
                threatIntelligenceEnabled: relevantCluster?.threatIntelligenceEnabled,
                imageAccessRuntimeMonitorEnabled: relevantCluster?.imageAccessRuntimeMonitorEnabled,
            },
            namespace: namespace.value,
        });
    }

    return null;
};