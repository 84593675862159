import initializeColumnDefs from './table/Definitions/ColumnDefinitions';
import initializeProtectedAssetPageColumns from './table/MainProtectedAssetsDefinition/columns';
import initializeFiltersDefs from './table/Definitions/FilterDefinitions';
import initializeProtectedAssetPageFilters from './table/MainProtectedAssetsDefinition/filters';
import { initializeProtectedAssetsPage } from './table/initialize.assetsTable.list';
import { initializeDetailsPaneItems } from './page/initalize.assetDetails.chipProvidres';

export default function initializeProtectedAssetsTable() {
    initializeColumnDefs();
    initializeProtectedAssetPageColumns();
    initializeFiltersDefs();
    initializeProtectedAssetPageFilters();
    initializeProtectedAssetsPage();
    initializeDetailsPaneItems();
}