import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetRegistry } from 'modules/workloads/reducers/registries/hooks/useGetRegistry';
import { changeUrl } from 'common/utils/http';
import { useDeleteRegistry } from 'modules/workloads/reducers/registries/hooks/useDeleteRegistry';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { DeleteRegistryModalProps } from './DeleteRegistryModal.types';
import { getCloudAccountsService } from 'common/interface/data_services';
import { useLocation } from 'react-router-dom';
import { Message } from 'common/design-system/components-v2';

const DeleteRegistryModal: React.FunctionComponent<DeleteRegistryModalProps> = ({ registryId, closeModal }) => {
    const { t } = useTranslation('k8s_registry');
    const { isLoading, isError, data } = useGetRegistry(registryId);
    const location = useLocation();

    const handleOnDeleteSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('registryPage.modals.removeRegistry.successMessage'),
            text: '',
        });
        closeModal();
        getCloudAccountsService().clearCache();
        const isWorkloadPage = location.pathname.includes('/workload/');
        changeUrl(isWorkloadPage ? '/workload/environments' : '/cloud-account/index');
    };
    const handleOnDeleteError = () => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('registryPage.modals.removeRegistry.failMessage'),
            text: '',
        });
    };

    const { deleteRegistry, isLoading: deleteIsLoading } = useDeleteRegistry({ onSuccess: handleOnDeleteSuccess, onError: handleOnDeleteError });

    if (isLoading || isError || !data) return null;

    return (
        <Message
            title={t('registryPage.modals.removeRegistry.title')}
            text={t('registryPage.modals.removeRegistry.text', { name: data.account.name })}
            variant='danger'
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={() => deleteRegistry(registryId)}
            isLoading={deleteIsLoading}
        />
    );
};

export default DeleteRegistryModal;
