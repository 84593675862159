import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { FormValues } from './EditClusterModal.types';
import { useUpdateCluster } from 'modules/workloads/reducers/cluster-new/hooks/useUpdateCluster';
import { useGetCluster } from 'modules/workloads/reducers/cluster-new/hooks/useGetCluster';
import { ModalsProps } from '..';
import { Message, Stack, Input } from 'common/design-system/components-v2';

const getRulesValidations = () => ({
    required: 'Is required.',
    minLength: {
        value: 2,
        message: 'Min Length is 2.'
    }
});

const EditClusterModal: React.FC<ModalsProps> = ({ closeModal, clusterId }) => {
    const { data: clusterData } = useGetCluster(clusterId);
    const { t } = useTranslation();

    const handleOnUpdateSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('K8S.CLUSTER_PAGE.EDIT_CLUSTER_MODAL.ON_SUBMIT.SUCCESS'),
            text: '',
        });
        onClose();
    };
    const handleOnUpdateError = (error: { message: string }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('K8S.CLUSTER_PAGE.EDIT_CLUSTER_MODAL.ON_SUBMIT.ERROR'),
            text: error.message,
        });
    };

    const { updateCluster, isLoading: isUpdateLoading } = useUpdateCluster({ onError: handleOnUpdateError, onSuccess: handleOnUpdateSuccess });

    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm<FormValues>({
        defaultValues: {
            name: clusterData?.account.name,
            description: clusterData?.account.description
        }
    });
    
    const onClickEditCluster = async (data: FormValues) => {
        const { name, description } = data;
        updateCluster(clusterId, { description, name });
    };

    const onClose = () => {
        closeModal();
    };

    return (
        <Message
            width='lg'
            onClose={onClose}
            isOpen={true}
            title={t('K8S.CLUSTER_PAGE.EDIT_CLUSTER_MODAL.TITLE')}
            isLoading={isUpdateLoading}
            onConfirm={handleSubmit((data) => onClickEditCluster(data))}
            onCancel={onClose}
            submitBtnDisabled={!!Object.keys(errors).length}
            submitBtnText='Save'
            cancelBtnText='Cancel'
            cancelBtnDisabled={isUpdateLoading}
        >
            <Stack spacing={3}>
                <Controller
                    name='name'
                    rules={getRulesValidations()}
                    control={control}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label={t('K8S.CLUSTER_PAGE.EDIT_CLUSTER_MODAL.BODY.NAME')}
                            required
                            placeholder={'Enter cluster name'}
                            isError={!!errors['name']}
                            helperText={errors['name'] ? errors['name']['message'] : ''}
                            fullWidth
                            clearable
                        />

                    )}
                />
                <Controller
                    name='description'
                    control={control}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label={t('K8S.CLUSTER_PAGE.EDIT_CLUSTER_MODAL.BODY.DESCRIPTION')}
                            placeholder={'Enter cluster description'}
                            fullWidth
                            clearable
                        />

                    )}
                />
            </Stack>
        </Message>
    );
};

export default EditClusterModal;
