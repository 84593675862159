import React from 'react';
import { useTranslation } from 'react-i18next';
import { UnlinkClusterModalProps } from './UnlinkClusterModal.types';
import { useGetRegistry } from 'modules/workloads/reducers/registries/hooks/useGetRegistry';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { useUnlinkEnvironment } from 'modules/workloads/reducers/registries/hooks/useUnlinkEnvironment';
import { Message } from 'common/design-system/components-v2';

const UnlinkClusterModal: React.FunctionComponent<UnlinkClusterModalProps> = ({ closeModal, registryId, linkedEnv }) => {
    const { t } = useTranslation('k8s_registry');
    
    const { isLoading, isError, data: registry, reloadData } = useGetRegistry(registryId);

    const handleOnUnlinkEnvironmentSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('registryPage.scanners.modals.removeScanningEnvironment.successMessage'),
            text: '',
        });
        reloadData();
        closeModal();
    };

    const handleOnUnlinkEnvironmentError = ({ message }: { message: string }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('registryPage.scanners.modals.removeScanningEnvironment.failMessage'),
            text: message,
        });
    };

    const { isLoading: isUnlinkEnvLoading, unLinkEnvironment } = useUnlinkEnvironment({ onSuccess: handleOnUnlinkEnvironmentSuccess, onError: handleOnUnlinkEnvironmentError });

    if (isLoading || isError || !registry) return null;

    const handleOnConfirm = () => {
        unLinkEnvironment(registryId, linkedEnv.kubernetesAccountId);
    };

    return (
        <Message
            isLoading={isUnlinkEnvLoading}
            onCancel={closeModal}
            variant='danger'
            onClose={closeModal}
            onConfirm={handleOnConfirm}
            title={t('registryPage.scanners.modals.removeScanningEnvironment.title')}
            text={t('registryPage.scanners.modals.removeScanningEnvironment.text', { name: linkedEnv.kubernetesAccountName })}
        />
    );
};

export default UnlinkClusterModal;
