const WinIcon = () => (
    <svg height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452.986 452.986" xmlSpace="preserve">
        <g>
            <g>
                <path d="M165.265,53.107L21.689,81.753v132.531l143.575-2.416V53.107 M431.297,245.583l-233.18-3.991
			v164.822l233.18,46.571V245.583 M165.265,241.097l-143.575-2.438v132.509l143.575,28.668V241.097 M431.297,0l-233.18,46.528
			v164.822l233.18-3.969V0"/>
            </g>
        </g>
    </svg>
);

export default WinIcon;
