import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import Modals from './components/Modals';
import { LoaderWrapper } from './KubernetesEnvironment.styled';
import { KubernetesTypeTranslation, ModalType } from './KubernetesEnvironment.types';
import Toolbar from './components/Toolbar/Toolbar';
import { useGetCluster } from 'modules/workloads/reducers/cluster-new/hooks/useGetCluster';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import { getUserService } from 'common/interface/services';
import { k8sTabsMetadata } from './KubernetesEnvironment.consts';
import EmptyState from 'common/components/EmptyState';
import { useTranslation } from 'react-i18next';
import EntityViewer from 'common/components/EntityViewer/EntityViewer';
import { EntityViewerProps, Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { useInterval } from 'modules/workloads/hooks';
import useRelatedEnvironments from 'modules/workloads/hooks/useRelatedEnvironments';
import { convertToProtectedAssetPageType, generateManagedKubernetesLink, getClusterVersionInfo, iconNameByType, platformByAssetType } from './KubernetesEnvironment.utils';
import AlertClusters from './components/AlertClusters/AlertClusters';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import useModalFromUrl from 'common/hooks/useModalFromUrl';
import { Cluster } from '../../../reducers/cluster-new/cluster.interface';

const KubernetesEnvironment: React.FunctionComponent = () => {
    const [modalType, setModalType] = useState<ModalType | null>(null);
    const { modalTypeFromUrl } = useModalFromUrl();
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation(getK8sNamespace('cluster'));
    const { isLoading, data: clusterData, isError, isDeleted, reloadData: reloadClusterData } = useGetCluster(id);
    const { account: accountData, summary: summaryData } = clusterData || {};
    const { isLoading: isLoadingRelatedEnvironment, data } = useRelatedEnvironments({ cloudAccountId: id });
    const { setBreadCrumbsLastChildren } = useBreadcrumbsLastChildren();

    useEffect(() => {
        if (accountData?.name) {
            setBreadCrumbsLastChildren([accountData?.name]);
        }
    }, [accountData, setBreadCrumbsLastChildren]);


    useEffect(() => {
        if (modalTypeFromUrl && !modalType) {
            setModalType(modalTypeFromUrl as ModalType);
        }
    }, [modalType, modalTypeFromUrl]);

    useInterval(reloadClusterData, 15 * 1000);

    const tabsWithData = React.useMemo<Array<Tab>>(() => {
        return k8sTabsMetadata(id).filter(tab => {
            let tabVisible = true;
            if (tab.propertyInAccount) {
                tabVisible = accountData?.[tab.propertyInAccount];
            }
            if (tabVisible && tab.guardByFeatureFlag) {
                tabVisible = getUserService().hasPermission([tab.guardByFeatureFlag]);
            }
            return tabVisible;
        }).map<Tab>(tab => ({
            isReactTab: tab.isReactTab,
            name: tab.name,
            label: t(tab.labelT),
            content: tab.content,
        }));
    }, [accountData, id, t]);

    const changeModalType = (type: ModalType) => {
        setModalType(type);
    };

    const managedKubernetesLink = useMemo(() => {
        if (
            data?.managedEnvironment?.cloudAccountId
            && data?.managedEnvironment?.type
            && data?.managedEnvironment?.managedClusterDome9EntityId
        ) {
            const { cloudAccountId, type, managedClusterDome9EntityId } = data.managedEnvironment;
            const platform = platformByAssetType(type);
            const protectedAssetType = convertToProtectedAssetPageType(type);
            return generateManagedKubernetesLink(cloudAccountId, protectedAssetType, managedClusterDome9EntityId, platform);
        }

        return '';
    }, [data?.managedEnvironment]);

    if (isLoading) {
        return (
            <LoaderWrapper>
                <CloudAnimationLoader size='medium' />
            </LoaderWrapper>
        );
    }

    if (isError || isDeleted || !accountData || !summaryData) {
        return (
            <EmptyState
                label={t('environment.notFound.title')}
                description={t('environment.notFound.description')}
                links={
                    [{ label: t('environment.notFound.backLink'), url: '/workload/environments', icon: { name: 'arrowLeft' } }]
                }
            />
        );
    }

    const isScanRegistryButtonDisabled = !accountData.imageAssuranceEnabled;

    const getClusterType = (clusterData: Cluster | undefined) => {
        const { type } = clusterData?.account || {};
        if (!type) return 'N/A';

        return (type === 'NA' ? 'N/A' : t(`environment.${KubernetesTypeTranslation[type || 'kubernetes']}`)) || 'N/A';
    };

    const clusterInfoProps: EntityViewerProps = {
        title: accountData.name,
        levelIcon: {
            iconProps: {
                name: 'kubernetes'
            }
        },
        titleRightElements:
            <Toolbar
                changeModalType={changeModalType}
                isScanRegistryDisabled={isScanRegistryButtonDisabled}
                cloudAccountId={id}
            />
        ,
        details: [
            { title: t('environment.info.cloudGuardId'), info: accountData.id, copyable: true },
            { title: t('environment.info.organizationalUnits'), info: accountData.organizationalUnitName, copyable: true },
            { title: t('environment.info.clusterVersion'), info: getClusterVersionInfo(accountData, t), copyable: true },
            { title: t('environment.info.onboardingTime'), info: dayjs(new Date(accountData.creationDate)).format('MMM D, YYYY h:mm A Z') },
            { title: t('environment.info.totalNodes'), info: summaryData.numberOfNodes },
            { title: t('environment.info.totalPods'), info: summaryData.numberOfPods },
            { title: t('environment.info.totalServices'), info: summaryData.numberOfServices },
            { title: t('environment.info.description'), info: accountData.description },
            { title: t('environment.info.type'), info: getClusterType(clusterData), iconProps: { vendorNameOrPath: iconNameByType(clusterData?.account.type) } },
            ...(
                data?.managedEnvironment
                    ? [{
                        title: t('environment.info.managedEnvironment'),
                        info: isLoadingRelatedEnvironment ? t('environment.info.loading') : (data?.managedEnvironment?.name),
                        internalUrl: managedKubernetesLink,
                        iconProps: { vendorNameOrPath: iconNameByType(data?.managedEnvironment?.type) },
                    }]
                    : []
            ),
        ],
        tabs: tabsWithData,
        isLoading,
    };

    return (
        <>
            <AlertClusters clusterData={clusterData} />
            <EntityViewer {...clusterInfoProps} />
            <Modals
                modalType={modalType}
                closeModal={() => setModalType(null)}
                clusterId={id}
            />
        </>
    );
};

export default KubernetesEnvironment;
