import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { AdvancedConfigForm, InAccountScannerVPC, advancedConfigFormSchema } from './AdvancedConfig.types';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Stack } from 'common/design-system/components-v2';
import { TextField, Label, Tooltip, Select } from '@dome9/berries/react-components';
import { ModalsProps } from '..';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { useUpdateAgentlessAccountSettings } from 'modules/workloads/services/awp/hooks/useUpdateAgentlessAccountSettings';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import Tags from 'common/components/Tags/Tags';
import { yupResolver } from '@hookform/resolvers/yup';
import { InfoIconStyled } from 'modules/workloads/components/LabelWithTooltip/LabelWithTooltip.styled';
import { DEFAULT_CONCURRENCE_SCANS_PER_REGION, DEFAULT_SCAN_MACHINE_INTERVAL_IN_HOURS, getMinScanMachineIntervalInHours, MAX_CONCURRENCE_SCANS_PER_REGION, MAX_SCAN_MACHINE_INTERVAL_IN_HOURS, MIN_CONCURRENCE_SCANS_PER_REGION } from './AdvancedConfig.consts';
import { ScanMode } from 'modules/workloads/services/awp/awp.interface';
import { ALink } from 'common/components/ALink';
import { Vendors } from 'common/consts/vendors';
import { toHash, toList } from 'modules/workloads/pages/settings/components/Awp/AwpSettings.utils';
import { getAwpVpcDocumentationUrl } from 'modules/workloads/pages/awp/awpOnboarding/utils';

const AdvancedConfig: React.FunctionComponent<ModalsProps> = ({ closeModal, agentlessAccount, reloadAgentlessAccount }) => {
    const {
        provider,
        scanMode,
        cloudAccountId,
        agentlessAccountSettings: { maxConcurrenceScansPerRegion, scanMachineIntervalInHours, customTags, inAccountScannerVPC }
    } = agentlessAccount;
    const { t } = useTranslation(getK8sNamespace('awp'));
    const customVpcOptions = React.useMemo(() => [
        { label: t('inAccountScannerVPC.ManagedByAWP'), value: InAccountScannerVPC.ManagedByAWP },
        { label: t('inAccountScannerVPC.ManagedByCustomer'), value: InAccountScannerVPC.ManagedByCustomer },
    ], [t]);

    const handleOnSettingsUpdateSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('awpTab.modals.advancedConfig.onSubmit.success'),
            text: '',
        });
        closeModal();
        reloadAgentlessAccount();
    };

    const handleOnSettingsUpdateError = ({ message }: { message: string }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('awpTab.modals.advancedConfig.onSubmit.error'),
            text: message,
        });
    };

    const { isLoading, updateAccountSettings } = useUpdateAgentlessAccountSettings({ scanMode, onError: handleOnSettingsUpdateError, onSuccess: handleOnSettingsUpdateSuccess });

    const handleOnConfirm = async (data: AdvancedConfigForm) => {
        await updateAccountSettings(cloudAccountId, provider, {
            ...data,
            customTags: toHash(data.customTags),
        });
    };

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm<AdvancedConfigForm>({
        mode: 'onChange',
        resolver: yupResolver(advancedConfigFormSchema(t, scanMode)) as any,
        defaultValues: {
            scanMachineIntervalInHours: scanMachineIntervalInHours || DEFAULT_SCAN_MACHINE_INTERVAL_IN_HOURS,
            maxConcurrenceScansPerRegion: maxConcurrenceScansPerRegion || DEFAULT_CONCURRENCE_SCANS_PER_REGION,
            customTags: toList(customTags ?? {}),
            ...(![ScanMode.Saas, ScanMode.InAccountSub].includes(scanMode) && { inAccountScannerVPC: customVpcOptions.find(option => option.value === inAccountScannerVPC)?.value || InAccountScannerVPC.ManagedByAWP }),
        },
    });

    const customTagsErrors = React.useMemo<Array<{ key?: string; value?: string; }>>(() => {
        const newErrors: Array<{ key?: string; value?: string; }> = [];
        if (errors && errors.customTags && Array.isArray(errors.customTags)) {
            errors.customTags.forEach((error) => {
                newErrors.push({ key: error?.key?.message, value: error?.value?.message });
            });
        }
        return newErrors;
    }, [errors]);

    return (
        <ConfirmationModal
            isLoading={isLoading}
            onCancel={closeModal}
            onClose={closeModal}
            onConfirm={handleSubmit((data) => handleOnConfirm(data))}
            title={t('awpTab.modals.advancedConfig.title')}
        >
            <Stack spacing={4}>
                {![ScanMode.InAccountSub, ScanMode.InAccountHub].includes(scanMode) && (
                    <>
                        <div>
                            <Controller
                                name='maxConcurrenceScansPerRegion'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        value={field.value || ''}
                                        type='number'
                                        allowClear={false}
                                        label={t('awpTab.modals.advancedConfig.fields.maxConcurrenceScansPerRegion.label')}
                                        tooltipProps={{ content: t('awpTab.modals.advancedConfig.fields.maxConcurrenceScansPerRegion.tooltip', { min: MIN_CONCURRENCE_SCANS_PER_REGION, max: MAX_CONCURRENCE_SCANS_PER_REGION }) }}
                                        placeholder={t('awpTab.modals.advancedConfig.fields.maxConcurrenceScansPerRegion.placeholder')}
                                        onClear={() => setValue('maxConcurrenceScansPerRegion', 20)}
                                        helper={errors['maxConcurrenceScansPerRegion'] ? errors['maxConcurrenceScansPerRegion']['message'] : ''}
                                        state={errors['maxConcurrenceScansPerRegion'] ? 'error' : 'success'}
                                    />

                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                name='scanMachineIntervalInHours'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        value={field.value || ''}
                                        type='number'
                                        allowClear={false}
                                        label={t('awpTab.modals.advancedConfig.fields.scanMachineIntervalInHours.label')}
                                        tooltipProps={{ content: t('awpTab.modals.advancedConfig.fields.scanMachineIntervalInHours.tooltip', { min: getMinScanMachineIntervalInHours(scanMode), max: MAX_SCAN_MACHINE_INTERVAL_IN_HOURS }) }}
                                        placeholder={t('awpTab.modals.advancedConfig.fields.scanMachineIntervalInHours.placeholder')}
                                        onClear={() => setValue('scanMachineIntervalInHours', 24)}
                                        helper={errors['scanMachineIntervalInHours'] ? errors['scanMachineIntervalInHours']['message'] : ''}
                                        state={errors['scanMachineIntervalInHours'] ? 'error' : 'success'}
                                    />
                                )}
                            />
                        </div>
                    </>
                )}
                {scanMode !== ScanMode.InAccountHub && (
                    <Stack spacing={1}>
                        <Stack direction='row' spacing={1} alignItems='center'>
                            <Label label={t('awpTab.modals.advancedConfig.fields.customTags.label')} />
                            <Tooltip content={t('awpTab.modals.advancedConfig.fields.customTags.tooltip')}><span><InfoIconStyled /></span></Tooltip>
                        </Stack>
                        <Stack padding={[0, 2]}>
                            <Controller
                                name='customTags'
                                control={control}
                                render={({ field }) => (
                                    <Tags
                                        tags={field.value || []}
                                        onChange={(value) => field.onChange(value)}
                                        errors={customTagsErrors}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                )}
                {![ScanMode.Saas, ScanMode.InAccountSub].includes(scanMode) && (
                    <Controller
                        name='inAccountScannerVPC'
                        control={control}
                        render={({ field }) => (
                            <Stack spacing={2}>
                                <Select
                                    {...field}
                                    value={customVpcOptions.find(option => option.value === field.value) || customVpcOptions[0]}
                                    label={t('awpTab.modals.advancedConfig.fields.customVpc.label', { context: provider })}
                                    tooltipProps={{ content: t('awpTab.modals.advancedConfig.fields.customVpc.tooltip') }}
                                    placeholder={t('awpTab.modals.advancedConfig.fields.customVpc.placeholder')}
                                    options={customVpcOptions}
                                    onChange={(option) => {
                                        field.onChange(option?.value || InAccountScannerVPC.ManagedByAWP);
                                    }}
                                    styles={{ menu: () => ({ zIndex: 1 }) }}
                                    closeMenuOnSelect
                                />
                                {field.value === InAccountScannerVPC.ManagedByCustomer && (
                                    <Alert type='warning'>
                                        <Trans
                                            t={t}
                                            i18nKey='awpTab.modals.advancedConfig.fields.customVpc.warning'
                                            values={{ field: provider === Vendors.AZURE ? 'VNet' : 'VPC' }}
                                            components={{ a: <ALink target='_blank' rel='noreferrer' href={getAwpVpcDocumentationUrl(provider as Vendors.AWS | Vendors.AZURE)}>.</ALink>, span: <span /> }}
                                        />
                                    </Alert>
                                )}
                            </Stack>
                        )}
                    />
                )}
            </Stack>
        </ConfirmationModal>
    );
};

export default AdvancedConfig;
