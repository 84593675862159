import { KubernetesFeatureType } from 'modules/workloads/services/kubernetesAccount/kubernetesAccount.interface';
import { TFunction } from 'i18next';
import { KOBFeature } from './KubernetesOnBoarding.types';
import { getCheckpointUrl } from 'common/components/Forms/urls';

export const features: (t: TFunction) => Array<KOBFeature> = (t) => ([
    {
        name: KubernetesFeatureType.postureManagement,
        title: t('ON_BOARDING.PAGE.FEATURES.CARDS.POSTURE_MANAGEMENT.LABEL'),
        description: t('ON_BOARDING.PAGE.FEATURES.CARDS.POSTURE_MANAGEMENT.DESCRIPTION'),
        link: getCheckpointUrl('POSTURE_MANAGEMENT'),
        icon: 'posture-management',
        defaultActive: true,
        defaultDisabled: true,
        viewOnly: true,
    },
    {
        name: KubernetesFeatureType.admissionControl,
        title: t('ON_BOARDING.PAGE.FEATURES.CARDS.ADMISSION_CONTROL.LABEL'),
        description: t('ON_BOARDING.PAGE.FEATURES.CARDS.ADMISSION_CONTROL.DESCRIPTION'),
        link: getCheckpointUrl('ADMISSION_CONTROL'),
        icon: 'admission-control',
        defaultActive: true,
        defaultDisabled: false,
    },
    {
        name: KubernetesFeatureType.threatIntelligence,
        title: t('ON_BOARDING.PAGE.FEATURES.CARDS.TREAT_INT.LABEL'),
        description: t('ON_BOARDING.PAGE.FEATURES.CARDS.TREAT_INT.DESCRIPTION'),
        link: getCheckpointUrl('THREAT_INTELLIGNCE'),
        icon: 'microscope',
        defaultActive: false,
        defaultDisabled: false,
    },
    {
        name: KubernetesFeatureType.imageAssurance,
        title: t('ON_BOARDING.PAGE.FEATURES.CARDS.IMAGE_ASSURANCE.LABEL'),
        description: t('ON_BOARDING.PAGE.FEATURES.CARDS.IMAGE_ASSURANCE.DESCRIPTION'),
        link: getCheckpointUrl('IMAGE_ASSURANCE'),
        icon: 'imageScan',
        defaultActive: true,
        defaultDisabled: false,
    },
    {
        name: KubernetesFeatureType.runtimeProtection,
        title: t('ON_BOARDING.PAGE.FEATURES.CARDS.RUNTIME_PROTECTION.LABEL'),
        description: t('ON_BOARDING.PAGE.FEATURES.CARDS.RUNTIME_PROTECTION.DESCRIPTION'),
        link: getCheckpointUrl('KUBERNETES_RUNTIME_PROTECTION'),
        icon: 'runtime-protection',
        defaultActive: true,
        defaultDisabled: false,
        subFeatures: [
            {
                name: KubernetesFeatureType.runtimeProtection_profiling,
                title: t('ON_BOARDING.PAGE.FEATURES.CARDS.BEHAVIORAL_PROFILING_.LABEL'),
                description: t('ON_BOARDING.PAGE.FEATURES.CARDS.BEHAVIORAL_PROFILING_.DESCRIPTION'),
                link: getCheckpointUrl('KUBERNETES_RUNTIME_PROTECTION'),
                icon: 'runtime-protection',
                defaultActive: false,
                defaultDisabled: false,
            }
        ]
    },
]);