import { OnboardingCustomizations, OnboardingCustomizationsKeys } from 'modules/workloads/services/awp/awp.interface';
import { OnboardingCustomizationsField } from './hooks/useOnboardingCustomizationsFields';

export const generateInitialFormValues = (subscriptionId: string) => {
    return {
        [OnboardingCustomizationsKeys.VMDataShareRolename]: `CloudGuard AWP VM Data Share ${subscriptionId}`,
        [OnboardingCustomizationsKeys.VMScanOperatorRolename]: `CloudGuard AWP VM Scan Operator ${subscriptionId}`,
        [OnboardingCustomizationsKeys.FunctionAppsOperatorRolename]: `CloudGuard AWP FunctionApp Scan Operator ${subscriptionId}`,
        [OnboardingCustomizationsKeys.FunctionAppsScannerRolename]: `CloudGuard AWP Function Apps Scanner ${subscriptionId}`,
        [OnboardingCustomizationsKeys.ResourceGroupName]: `cloudguard-AWP_${subscriptionId}`,
        [OnboardingCustomizationsKeys.ManagedIdentityName]: `CloudGuardAWPScannerManagedIdentity_${subscriptionId}`,
        [OnboardingCustomizationsKeys.ManagementResourceGroupName]: `cloudguard-AWP_${subscriptionId}`,
        'managementGroupId': '',
        [OnboardingCustomizationsKeys.CryptoCreatorRoleName]: `CloudGuard AWP Crypto Creator ${subscriptionId}`,
        [OnboardingCustomizationsKeys.DiskEncryptorRoleName]: `CloudGuard AWP Disk Encryptor ${subscriptionId}`,
    };
};

export const getRelevantOnboardingCustomizations = (
    relevantFields: Array<OnboardingCustomizationsField>,
    onboardingCustomizations?: OnboardingCustomizations
) => {
    const relevantOnboardingCustomizations: any = {};

    relevantFields.forEach((field) => {
        if (
            onboardingCustomizations
            && onboardingCustomizations[field.key]
        ) {
            relevantOnboardingCustomizations[field.key] = onboardingCustomizations[field.key];
        }
    });

    return relevantOnboardingCustomizations;
};