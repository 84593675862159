import React from 'react';
import { ToolbarWrapper } from './Toolbar.styled';
import { ToolBarProps } from './Toolbar.types';
import { ModalType } from '../../KubernetesEnvironment.types';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { List, Dropdown } from 'common/design-system/components-v2';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';
import { useWorkloadEnvImagesScan } from 'modules/workloads/services/workload/hooks/useWorkloadEnvImagesScan';
import { ImageAssuraceScanEnvironment, ImageAssuraceScanEnvironmentScanAction } from 'modules/workloads/services/workload/workload.interface';
import { NotificationType } from 'common/interface/notifications';
import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from 'common/interface/IFrame.message.model';
import { getNotificationsService, getWebAppIframeService } from 'common/interface/services';

const useScanAction = (environmentId: string) => {
    const { t } = useTranslation(getK8sNamespace('common'));
    const { scanEnvImages, isLoading } = useWorkloadEnvImagesScan({
        onError: (error: { message: string; }) => {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: t('workloadImageScan.envScan.errorMessage.title'),
                text: error.message || t('workloadImageScan.envScan.errorMessage.body'),
            });
        },
        onSuccess: (response: ImageAssuraceScanEnvironment.Response) => {
            const messagePrefix = response.imagesToScanCount === 0 ? 'emptyMessage' : 'successMessage';
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                title: t(`workloadImageScan.envScan.${messagePrefix}.title`),
                text: t(`workloadImageScan.envScan.${messagePrefix}.body`, { imagesToScanCount: response.imagesToScanCount }),
            });
            //  This is to fix the status code issue, the status code is 200 but the scan is not finished yet
            setTimeout(() => {
                getWebAppIframeService().emitMessage(
                    new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.RELOAD_STATE,
                    }),
                );
            }, 3000);
        },
    });

    const scanAction = () => {
        scanEnvImages({
            environmentId,
            scanAction: ImageAssuraceScanEnvironmentScanAction.Failed
        });
    };

    return { scanAction, isLoading };
};

const Toolbar: React.FunctionComponent<ToolBarProps> = ({
    changeModalType,
    isScanRegistryDisabled,
    cloudAccountId
}) => {
    const { isLoading: isRescanEnvLoading, scanAction } = useScanAction(cloudAccountId);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const { t } = useTranslation(getK8sNamespace('cluster'));
    const dropdownOptions: Array<IListItemProps> = [
        {
            label: t('environment.actions.editCluster'),
            value: 'edit-cluster',
            onClick: () => changeModalType(ModalType.EditCluster),
        },
        {
            label: t('environment.actions.removeCluster'),
            value: 'remove-cluster',
            onClick: () => changeModalType(ModalType.RemoveCluster),
        },
        {
            label: t('environment.actions.scanRegistry.text'),
            value: 'scan-registry',
            onClick: () => changeModalType(ModalType.ScanRegistry),
            tooltip: isScanRegistryDisabled ? t('environment.actions.scanRegistry.toolTipText') : undefined,
            disabled: isScanRegistryDisabled,
        },
        {
            label: t('environment.actions.restoreInstallCommand'),
            value: 'restore-install-command',
            onClick: () => changeModalType(ModalType.RestoreInstallCommand),
        },
        {
            label: t('environment.actions.imageScan'),
            value: 'image-scan',
            disabled: isRescanEnvLoading,
            onClick: scanAction,
        },
    ];

    return (
        <ToolbarWrapper direction='row'>
            <Dropdown
                open={isDropdownOpen}
                onStateChange={setIsDropdownOpen}
                buttonProps={{
                    iconButton: true,
                    iconProps: { name: 'more' },
                }}
                maxHeight={500}
            >
                <List
                    options={dropdownOptions}
                    onOptionClick={() => setIsDropdownOpen(false)}
                />
            </Dropdown>
        </ToolbarWrapper>
    );
};


export default Toolbar;
