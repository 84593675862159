import { getHttpService } from 'common/interface/services';
import {
    CreateServiceAccount,
    GetServiceAccounts,
    UpdateServiceAccount,
    DeleteServiceAccount,
    CreateServiceAccountResponse
} from './service-account.interface';
import { AxiosResponse } from 'axios';

export const createServiceAccount: CreateServiceAccount = async request => {
    return await getHttpService().request<AxiosResponse<CreateServiceAccountResponse>>(
        'service-account',
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

export const getServiceAccounts: GetServiceAccounts = async () => {
    return await getHttpService().get('service-account');
};

export const updateServiceAccount: UpdateServiceAccount = async request => {
    return await getHttpService().post('service-account/update', { data: request });
};

export const deleteServiceAccount: DeleteServiceAccount = async request => {
    return await getHttpService().delete(`service-account/${request.id}`, { data: request });
};