import React from 'react';
import { useSelector } from 'react-redux';
import RegistriesActions from '../registries.actions';
import { RegistriesSelector } from '../registries.selectors';
import { K8SRootStore } from 'modules/workloads/initialize.redux';
import { ContainerRegistryUserConfigurations } from 'modules/workloads/services/containerRegistry/containerRegistry.interface';

export interface UseRegistrySettingsPayload {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    data?: ContainerRegistryUserConfigurations;

    reloadData: () => void;
}

export type UseRegistrySettings = (registryId: string) => UseRegistrySettingsPayload;

export const useRegistrySettings: UseRegistrySettings = (registryId) => {
    const { loading, error, data } = useSelector((state: K8SRootStore) => RegistriesSelector.getRegisrySettings(state, registryId)) || {};

    const getData = (id: string) => RegistriesActions.getRegistrySettings(id);
 
    React.useEffect(() => {
        if (!loading && !error && !data) {
            getData(registryId);
        }
    }, [loading, error, data, registryId]);

    const reloadData = React.useCallback(() => getData(registryId), [registryId]);

    return ({
        isLoading: !!loading && !data,
        isFetching: !!loading,
        isError: !!error,
        data,

        reloadData
    });
};