import React, { useState } from 'react';
import ImagesOnWorkload from '../../sbom/ImagesOnWorkload/ImagesOnWorkload';
import { Image } from 'modules/workloads/services/workload/workload.interface';
import Vulnerability from '../Vulnerability';

interface VulnerabilityWorkloadsProps {
    dome9Id: string;
    environmentId: string;
}

const VulnerabilityWorkloads: React.FC<VulnerabilityWorkloadsProps> = ({ dome9Id, environmentId }) => {
    const [selectedImage, setSelectedImage] = useState<Image | null>(null);

    return (
        <>
            <ImagesOnWorkload dome9Id={dome9Id} onClickSelectImage={setSelectedImage} selectedImage={selectedImage} />
            {selectedImage !== null && <Vulnerability type={'KubernetesImage'} id={selectedImage?.imageId} environmentId={environmentId}/>}
        </>
    );
};

export default VulnerabilityWorkloads;