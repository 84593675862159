import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const AttackVectorPanelWrapper = styled(Stack)`
    background-color: ${({ theme }) => theme.palette.surface.secondary};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
`;

export const AttackVectorPanelStyled = {
    AttackVectorPanelWrapper,
};