import React from 'react';
import axios from 'axios';
import { KubernetesClusterDashboard } from '@dome9/cloudguard-widgets-dashboard';
import { OverviewProps } from './Overview.types';
import { getHttpService, getStoreService } from 'common/interface/services';
import { OverviewStyled } from './Overview.styled';
import { EventHandlerCallback, UrlGeneratorCallback } from '@dome9/cloudguard-widgets-widgets';
import { handleDashboardEvent, generateWidgetUrl } from './events/events.handlers';

const Overview: React.FunctionComponent<OverviewProps> = ({ id }) => {
    const axiosInstance = React.useMemo(() => {
        const httpService = getHttpService();
        const axiosInstance = axios.create();
        axiosInstance.post = (url, data, config) => {
            if (config?.baseURL === '/magellanApi') {
                const state = getStoreService().state;
                const magellanUrl = state.app.magellanUrl;
                return httpService.request(`${magellanUrl}/${url}`, { data, method: 'POST' }, { returnAsAxiosResponse: true }, err => { throw err; });
            }
            return httpService.request(url, { data, method: 'POST' }, { returnAsAxiosResponse: true }, err => { throw err; });
        };
        axiosInstance.get = (url) => httpService.request(url, { method: 'GET' }, { returnAsAxiosResponse: true }, err => { throw err; });
        axiosInstance.put = (url, data) => httpService.request(url, { data, method: 'PUT' }, { returnAsAxiosResponse: true }, err => { throw err; });
        axiosInstance.delete = (url) => httpService.request(url, { method: 'DELETE' }, { returnAsAxiosResponse: true }, err => { throw err; });
        return axiosInstance;
    }, []);

    const eventHandler: EventHandlerCallback = (widget, eventType, eventData): void => {
        handleDashboardEvent(widget, eventType, eventData, id);
    };

    const urlGenerator: UrlGeneratorCallback = (widget, eventData) => {
        return generateWidgetUrl(widget, eventData, id) || undefined;
    };

    return (
        <OverviewStyled.OverviewWrapper>
            <KubernetesClusterDashboard
                axiosInstance={axiosInstance}
                filters={{ cloudAccountId: `11|${id}` }}
                eventHandler={eventHandler}
                urlGenerator={urlGenerator}
            />
        </OverviewStyled.OverviewWrapper>
    );
};

export default Overview;
