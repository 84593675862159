import React from 'react';
import { K8sStoreService } from 'modules/workloads/initialize.redux';
import KubernetesOnboarding from './KubernetesOnBoardingNew';
import { setIsReact } from 'App.reducer';
import { Media_, PreSelectNote, PreSelectPageButton, PreSelectPageButtonsWrapper, PreSelectPageWrapper, TextWithSideLines } from './KubernetesOnBoarding.styles';
import CloudKubernetesIcon from './components/CloudKubernetesIcon';
import { useTranslation } from 'react-i18next';
import { getUserService } from 'common/interface/services';

enum KubernetesOnBoardingPageTypes {
    'PageSelect',
    'ReactPage',
    'AngularPage'
}

const KubernetesOnBoardingPrePage: React.FunctionComponent = () => {
    const { t } = useTranslation('k8s_cluster');
    const { dispatch } = K8sStoreService().getReduxTools();

    const [pageType, setPageType] = React.useState<KubernetesOnBoardingPageTypes>(() => {
        if (getUserService().hasPermission(['allow-k8s-oneclick-onboarding'])) {
            return KubernetesOnBoardingPageTypes.PageSelect;
        }
        return KubernetesOnBoardingPageTypes.AngularPage;
    });

    React.useEffect(() => {
        if (pageType === KubernetesOnBoardingPageTypes.AngularPage) {
            dispatch(setIsReact(false));
        }
    }, [pageType, dispatch]);

    if (pageType === KubernetesOnBoardingPageTypes.ReactPage) {
        return <KubernetesOnboarding />;
    }

    if (pageType === KubernetesOnBoardingPageTypes.AngularPage) {
        return null;
    }

    return (
        <PreSelectPageWrapper>
            <CloudKubernetesIcon title={t('ON_BOARDING.PRE_PAGE.TITLE')} />
            <TextWithSideLines>{t('ON_BOARDING.PRE_PAGE.SELECT_ONBOARDING_PATH')}</TextWithSideLines>
            <PreSelectPageButtonsWrapper>
                <div>
                    <PreSelectPageButton onClick={() => setPageType(KubernetesOnBoardingPageTypes.ReactPage)}>
                        {t('ON_BOARDING.PRE_PAGE.ONE_CLICK.TITLE')}
                    </PreSelectPageButton>
                    <p>{t('ON_BOARDING.PRE_PAGE.ONE_CLICK.INFO')}</p>
                </div>
                <div>
                    <PreSelectPageButton onClick={() => setPageType(KubernetesOnBoardingPageTypes.AngularPage)}>
                        {t('ON_BOARDING.PRE_PAGE.ADVANCED.TITLE')}
                    </PreSelectPageButton>
                    <p>{t('ON_BOARDING.PRE_PAGE.ADVANCED.INFO')}</p>
                </div>
            </PreSelectPageButtonsWrapper>
            <PreSelectNote>
                <Media_ title={t('ON_BOARDING.PRE_PAGE.INFO')} icon='info' size='sm' iconSize='24' />
            </PreSelectNote>
        </PreSelectPageWrapper>
    );
};

export default KubernetesOnBoardingPrePage;
