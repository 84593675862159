import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { ComponentOverflow, Typography } from 'common/design-system/components-v2';
import { SbomPackagesFlat } from '../../services/sbom/sbom.interface';

const LicenseCellRenderer: React.FC<ICellRendererParams<SbomPackagesFlat['licenses'], SbomPackagesFlat['licenses']>> = (params) => {
    if (!params.value) return;

    return (
        <ComponentOverflow
            commaSeperated
            components={params.value.map((license) => <Typography key={license.value}>{license.value.replace(/\\|"/g, '')}</Typography>)}
        />
    );
};

export default LicenseCellRenderer;
