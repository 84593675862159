
export enum ModalType {
    ScanRegistry = 'scan-registry',
    RemoveCluster = 'remove-cluster',
    EditCluster = 'edit-cluster',
    RestoreInstallCommand = 'restore-install-command',
}

export enum KubernetesAlertType {
    DoubleOnboarding = 'double-onboarding',
}

export enum ManagedKubernetesType {
    EKS = 'EKS',
    AKS = 'AKS',
    GKE = 'GKE',
    OpenShift = 'Openshift',
    NA = 'NA'
}

export enum KubernetesTypeTranslation {
    EKS = 'kubernetesType.elasticKubernetesService',
    AKS = 'kubernetesType.azureKubernetesService',
    GKE = 'kubernetesType.googleKubernetesEngine',
    Openshift = 'kubernetesType.openshift',
    kubernetes = 'kubernetesType.kubernetes',
    NA = 'kubernetesType.kubernetes'
}