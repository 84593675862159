import { A } from 'common/components/Anchor/Anchor';
import styled from 'styled-components';

const CustomAgGrid = styled.div`
    & .ag-root-wrapper {
        border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`} !important;
    }
    & .ag-center-cols-clipper {
        min-height: unset !important;
    }
    & .ag-details-row {
        padding: 10px;
    }
    & .ag-header {
        margin-bottom: unset !important;
    }
`;

const aLink = styled(A)`
    height: 100%;

    & span {
        color: ${({ theme }) => theme.palette.status.info};
        --opacity: 1;
    }
`;

export default {
    CustomAgGrid,
    aLink
};