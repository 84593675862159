import { cluster_actions } from '.';
import { batch } from 'react-redux';
import { K8sStoreService } from 'modules/workloads/initialize.redux';
import KubernetesAccountService from 'modules/workloads/services/kubernetesAccount/kubernetesAccount.service';
import { Cluster } from './cluster.interface';
import { KubernetesAccountFeatureStatusChange } from 'modules/workloads/services/kubernetesAccount/kubernetesAccount.interface';
import { AxiosResponse } from 'axios';
import { getMessageFromError } from 'modules/workloads/utils/errorMessage';
import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from 'common/interface/IFrame.message.model';
import { getStoreService, getWebAppIframeService } from 'common/interface/services';
import { getCloudAccountsService } from 'common/interface/data_services';

type GetCluster = (clusterId: string) => Promise<boolean>;
const getCluster: GetCluster = async (clusterId) => {
    const { dispatch } = K8sStoreService().getReduxTools();
    try {
        dispatch(cluster_actions.setLoading({ clusterId, loading: true }));
        const accountData = await Promise.all([
            KubernetesAccountService.getAccount({ id: clusterId }),
            KubernetesAccountService.getAccountSummary({ id: clusterId }),
            KubernetesAccountService.getAgentSummary({ id: clusterId })
        ]);
        if (!accountData[0]?.data || !accountData[1]?.data) throw new Error('');
        const clusterData: Cluster = {
            account: accountData[0].data,
            summary: accountData[1].data,
            agentSummary: accountData[2].data
        };
        batch(() => {
            dispatch(cluster_actions.setData({ clusterId, data: clusterData }));
            dispatch(cluster_actions.setError({ clusterId, error: false }));
            dispatch(cluster_actions.setLoading({ clusterId, loading: false }));
        });
        return true;
    } catch (error) {
        batch(() => {
            dispatch(cluster_actions.setError({ clusterId, error: true }));
            dispatch(cluster_actions.setLoading({ clusterId, loading: false }));
        });
        throw Error(getMessageFromError(error));
    }
};

type DeleteCluster = (clusterId: string) => Promise<boolean>;
const deleteCluster: DeleteCluster = async (clusterId) => {
    try {
        const { dispatch } = K8sStoreService().getReduxTools();
        await KubernetesAccountService.deleteAccount({ id: clusterId });
        dispatch(cluster_actions.setDeleted({ clusterId, deleted: true }));
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

export interface UpdateClusterReq {
    name?: string;
    description?: string;
    organizationalUnitId?: string;
    features?: Array<KubernetesAccountFeatureStatusChange.Request>;
}
type UpdateCluster = (clusterId: string, req: UpdateClusterReq) => Promise<boolean>;
const updateCluster: UpdateCluster = async (clusterId, { name, description, organizationalUnitId, features }) => {
    try {
        const { state } = K8sStoreService().getReduxTools();
        const { name: _name, description: _description, organizationalUnitId: _organizationalUnitId } = state.k8s_cluster.clusters[clusterId]?.data?.account || {};

        const updatePromiseArr: Array<Promise<AxiosResponse<any>>> = [];

        if (name && name !== _name) {
            updatePromiseArr.push(KubernetesAccountService.updateName({ id: clusterId, name: name }));
        }
        if (description !== undefined && description !== _description) {
            updatePromiseArr.push(KubernetesAccountService.updateDescription({ id: clusterId, description: description || '' }));
        }
        if (organizationalUnitId && organizationalUnitId !== _organizationalUnitId) {
            updatePromiseArr.push(KubernetesAccountService.updateOU({ id: clusterId, organizationalUnitId }));
        }
        if (features) {
            // to enable flowlogs features, we need to get magellanUrl and accountId (Logic from kubernetes onboarding)
            const state = getStoreService().state;
            const magellanUrl = state.app.magellanUrl;
            const accountId = state.user.user.id;
            features.forEach(feature => {
                updatePromiseArr.push(KubernetesAccountService.updateFeatureStatus({ ...feature, magellanUrl, accountId }));
            });
        }

        const result = await Promise.all(updatePromiseArr);
        
        if (result.length > 0) {
            getCloudAccountsService().clearCache();
            getWebAppIframeService().emitMessage(
                new IframeMessageModel({
                    action: IFRAME_MESSAGE_ACTIONS.UPDATE_TABPANEL_TABNAME,
                    data: { id: clusterId, newName: name }
                }),
            );
            getWebAppIframeService().emitMessage(
                new IframeMessageModel({
                    action: IFRAME_MESSAGE_ACTIONS.RELOAD_STATE,
                }),
            );
            getCluster(clusterId);
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

const ClusterActions = {
    getCluster,
    deleteCluster,
    updateCluster
};

export default ClusterActions;