import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AwpService from '../../awp.service';
import { getI18nKeyMessage } from '../../utils/scanRequestHandler';
import { UseScanRequestParam } from './useScanRequest.types';

export const useScanRequest = (props: UseScanRequestParam) => {
    const { t } = useTranslation(getK8sNamespace('common'));
    const [isRequestPending, setIsRequestPending] = React.useState(false);

    const onScanRequest = React.useCallback(async () => {
        setIsRequestPending(true);
        try {
            const response = await AwpService.requestScan(props.cloudAccountId, props.cloudProvider, props.entityId);
            const statusCode = response.status;
            const message = response.data;
            const [bodyKey, type] = getI18nKeyMessage(statusCode, message);
            props.addNotificationService.addNotification({
                type,
                // TODO: change the type of title to support links inline and other html tags.
                title: <Trans t={t} i18nKey={`workloadImageScan.agentlessScan.notification.title.${bodyKey}`} components={{ a: <a style={{ textDecorationLine: 'underline' }}></a>, br: <br></br> }}/> as any,
            });
        } catch (err: any) {
            const statusCode = err.statusCode;
            const message = err.message;
            const [bodyKey, type] = getI18nKeyMessage(statusCode, message);
            props.addNotificationService.addNotification({
                type,
                // TODO: change the type of title to support links inline and other html tags.
                title: <Trans t={t} i18nKey={`workloadImageScan.agentlessScan.notification.title.${bodyKey}`} components={{ a: <a style={{ textDecorationLine: 'underline' }}></a>, br: <br></br> }}/> as any,
            });
        }
        finally {
            setIsRequestPending(false);
        }
    }, [props.cloudAccountId, props.cloudProvider, props.entityId, props.addNotificationService, t]);
    return { onScanRequest, isRequestPending };
};