import React from 'react';
import { useTranslation } from 'react-i18next';
import { RegistryTypeDropDownOption, RegistryTypeSelectorProps } from './RegistryTypeSelector.interface';
import { getRegistryOptions, RegistryType, ScanningEnvironmentType } from 'modules/workloads/utils/RegistryOptions/RegistryOptions';
import { isChina } from 'common/utils/dataCenter';
import { getUserService } from 'common/interface/services';
import { SelectV2 as Select } from 'common/design-system/components-v2';

export const getRegistrySelectOptions = (registryTypes: Array<RegistryType>): Array<RegistryTypeDropDownOption> => {
    const RegistryOptions = getRegistryOptions();
    return registryTypes.map((registryType: RegistryType) => {
        return {
            type: registryType,
            defaultAuthMethod: RegistryOptions[registryType]?.authMethods[ScanningEnvironmentType.Kubernetes][0].key,
            title: RegistryOptions[registryType].name,
            icon: { name: RegistryOptions[registryType].icon },
        };
    });
};

const filterByScanningEnvType = (allowedRegistryTypes: Array<RegistryType>, scanningEnvironmentType: ScanningEnvironmentType) => {
    const RegistryOptions = getRegistryOptions();
    return allowedRegistryTypes.filter(registryType => RegistryOptions[registryType].authMethods[scanningEnvironmentType].length);
};

export const getAllowedRegistryDropDownOptions = (scanningEnvironmentType: ScanningEnvironmentType): Array<RegistryTypeDropDownOption> => {
    const allowedByFeatureFlagRegistryTypes = [].filter(// do not remove this line - it is used to filter out registry types that are not allowed by feature flag
        (registryType: RegistryType) => getUserService().hasPermission([`allow-registry-type-${registryType.toLocaleLowerCase()}`]),
    );
    const allowedRegistryTypes = filterByScanningEnvType([
        RegistryType.ACR,
        RegistryType.ECR,
        ...(isChina() ? [] : [RegistryType.GCR, RegistryType.GAR]),
        RegistryType.Harbor,
        RegistryType.JFrogArtifactory,
        RegistryType.Nexus,
        RegistryType.GitHub,
        ...allowedByFeatureFlagRegistryTypes
    ], scanningEnvironmentType);

    return getRegistrySelectOptions(allowedRegistryTypes);
};

const RegistryTypeSelector: React.FC<RegistryTypeSelectorProps> = (props) => {
    const { registryType, scanningEnvironmentType, setRegistryTypeAndAuthMethod } = props;
    const { t } = useTranslation('k8s_registry');
    const registryDropdownOptions = getAllowedRegistryDropDownOptions(scanningEnvironmentType);
    const RegistryOptions = getRegistryOptions();

    const options = registryDropdownOptions.map((dropDownOption) => {
        const { title, type, icon } = dropDownOption;
        return { label: t(title), value: type, labelProps: { leadingIconProps: icon } };
    });

    const handleOnSelect = (registryTypeString: string) => {
        const registryType = registryTypeString as RegistryType;
        const defaultAuthMethod = RegistryOptions[registryType].authMethods[scanningEnvironmentType][0].key;
        setRegistryTypeAndAuthMethod(registryType, defaultAuthMethod);
    };

    const handleOnChange = (value: string) => {
        if (value) {
            handleOnSelect(value);
        }
    };

    return (
        <div className='input-container'>
            <Select
                label={t('ON_BOARDING.registryConfigFormLabels.registryType')}
                fullWidth
                required={true}
                options={options}
                onChange={handleOnChange}
                value={registryType}
                isMulti={false}
            />
        </div>
    );
};

export default RegistryTypeSelector;
