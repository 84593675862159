import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import RegistryPageStyles from './RegistryPage.styled';
import { useGetRegistry } from 'modules/workloads/reducers/registries/hooks/useGetRegistry';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import EntityViewer from 'common/components/EntityViewer/EntityViewer';
import { EntityViewerProps } from 'common/components/EntityViewer/EntityViewer.interface';
import EmptyState from 'common/components/EmptyState';
import { useTranslation } from 'react-i18next';
import { registryPageTabsMetadata } from './RegistryPage.consts';
import RegistryPageModals, { RegistryPageModalsTypes } from './Modals';
import { RegistryTypeTranslations, getRegistryIconByType } from '../../../utils/RegistryOptions/RegistryOptions';
import { getUserService, getWebAppIframeService } from 'common/interface/services';
import { useInterval } from 'modules/workloads/hooks';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import WorkloadRequestImageScan from 'modules/workloads/services/workload/components/WorkloadRequestImageScan';
import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from 'common/interface/IFrame.message.model';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { Button } from 'common/design-system/components-v2';

const RegistryPage: React.FunctionComponent = () => {
    const { registryId } = useParams<{ registryId: string; }>();
    const { t } = useTranslation(getK8sNamespace('registry'));
    const [currentOpenModal, setCurrentOpenModal] = React.useState<RegistryPageModalsTypes | undefined>();
    const { isLoading, isError, isDeleted, data, reloadData } = useGetRegistry(registryId);
    const { setBreadCrumbsLastChildren } = useBreadcrumbsLastChildren();

    useEffect(() => {
        if (data?.account.name) {
            setBreadCrumbsLastChildren([data?.account.name]);
        }
    }, [data, setBreadCrumbsLastChildren]);

    useInterval(reloadData, 15 * 1000);

    const registryInfoProps: EntityViewerProps | undefined = React.useMemo(() => {
        if (!data) return undefined;

        return {
            title: data.account.name,
            levelIcon: {
                iconProps: {
                    name: 'containersRegistry'
                }
            },
            titleRightElements: (
                <RegistryPageStyles.ToolbarButtonsWrapper direction='row'>
                    <Button onClick={() => setCurrentOpenModal(RegistryPageModalsTypes.DELETE)} iconProps={{ name: 'delete' }} variant='text'>
                        {t('registryPage.actions.removeRegistry')}
                    </Button>
                    <Button onClick={() => setCurrentOpenModal(RegistryPageModalsTypes.EDIT)} iconProps={{ name: 'edit' }} variant='text'>
                        {t('registryPage.actions.editRegistry')}
                    </Button>
                    <WorkloadRequestImageScan environmentId={registryId} successCallback={() => {
                        getWebAppIframeService().emitMessage(
                            new IframeMessageModel({
                                action: IFRAME_MESSAGE_ACTIONS.RELOAD_STATE,
                            }),
                        );
                    }} />
                </RegistryPageStyles.ToolbarButtonsWrapper>
            ),
            details: [
                { title: t('registryPage.details.cloudguardId'), info: data.account.id, copyable: true },
                { title: t('registryPage.details.organiztionUnit'), info: data.account.organizationalUnitName },
                { title: t('registryPage.details.registryUrl'), info: data.account.registryUrl },
                { title: t('registryPage.details.repositories'), info: data.accountSummary.numberOfRepositories },
                { title: t('registryPage.details.images'), info: data.accountSummary.numberOfImages },
                { title: t('registryPage.details.scanningEnvironments'), info: data.agentSummary.length },
                { title: t('registryPage.details.onboardingTime'), info: dayjs(new Date(data.account.creationDate)).format('MMM D, YYYY h:mm A Z') },
                { title: t('registryPage.details.description'), info: data.account.description || 'N/A' },
                { title: t('registryPage.details.registryType'), info: t(RegistryTypeTranslations[data.account.type]), iconProps: { vendorNameOrPath: getRegistryIconByType(data.account.type) } },
            ],
            isLoading,
            tabs: registryPageTabsMetadata(registryId)
                .filter(tab => {
                    if (tab.guardByFeatureFlag) {
                        return getUserService().hasPermission([tab.guardByFeatureFlag]);
                    }
                    return true;
                })
                .map(tab => ({
                    isReactTab: tab.isReactTab,
                    name: tab.name,
                    label: t(tab.labelT),
                    content: tab.content,
                })),
        };
    }, [registryId, data, isLoading, t]);

    if (isLoading) {
        return (
            <RegistryPageStyles.LoaderWrapper>
                <CloudAnimationLoader size='medium' />
            </RegistryPageStyles.LoaderWrapper>
        );
    }

    if (!data || isError || isDeleted || !registryInfoProps) {
        return (
            <EmptyState
                label={t('registryPage.notFound.title')}
                description={t('registryPage.notFound.description')}
                links={
                    [{ label: t('registryPage.notFound.backLink'), url: '/workload/environments', icon: { name: 'arrowLeft' } }]
                }
            />
        );
    }

    return (
        <>
            <EntityViewer {...registryInfoProps} />
            <RegistryPageModals
                closeModal={() => setCurrentOpenModal(undefined)}
                modalType={currentOpenModal}
                registryId={registryId}
            />
        </>
    );
};

export default RegistryPage;
