import { OnboardingCustomizationsKeys, ScanMode } from 'modules/workloads/services/awp/awp.interface';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type OnboardingCustomizationsField = {
    key: OnboardingCustomizationsKeys,
    label: string,
    tooltip?: string,
};

type UseAdvancedFieldsPayload = {
    loading: boolean;
    error: boolean;

    advancedFields: Array<OnboardingCustomizationsField>;
};

export type UseOnboardingCustomizationsFields = (scanMode: ScanMode, sseCmkEncryptedDisksScan?: boolean) => UseAdvancedFieldsPayload;
const useOnboardingCustomizationsFields: UseOnboardingCustomizationsFields = (scanMode, sseCmkEncryptedDisksScan) => {
    const { t } = useTranslation('k8s_awp');
    const allAdvancedFields = useMemo<Array<OnboardingCustomizationsField>>(() => {
        return [
            {
                key: OnboardingCustomizationsKeys.VMDataShareRolename,
                label: t('awpOnboarding.azure.onboardingCustomizations.VMDataShareRolename.label'),
                tooltip: t('awpOnboarding.azure.onboardingCustomizations.VMDataShareRolename.tooltip'),
            },
            {
                key: OnboardingCustomizationsKeys.VMScanOperatorRolename,
                label: t('awpOnboarding.azure.onboardingCustomizations.VMScanOperatorRolename.label'),
                tooltip: t('awpOnboarding.azure.onboardingCustomizations.VMScanOperatorRolename.tooltip'),
            },
            {
                key: OnboardingCustomizationsKeys.FunctionAppsOperatorRolename,
                label: t('awpOnboarding.azure.onboardingCustomizations.FunctionAppsOperatorRolename.label'),
                tooltip: t('awpOnboarding.azure.onboardingCustomizations.FunctionAppsOperatorRolename.tooltip'),
            },
            {
                key: OnboardingCustomizationsKeys.FunctionAppsScannerRolename,
                label: t('awpOnboarding.azure.onboardingCustomizations.FunctionAppsScannerRolename.label'),
                tooltip: t('awpOnboarding.azure.onboardingCustomizations.FunctionAppsScannerRolename.tooltip'),
            },
            {
                key: OnboardingCustomizationsKeys.ResourceGroupName,
                label: t('awpOnboarding.azure.onboardingCustomizations.ResourceGroupName.label'),
                tooltip: t('awpOnboarding.azure.onboardingCustomizations.ResourceGroupName.tooltip'),
            },
            {
                key: OnboardingCustomizationsKeys.ManagedIdentityName,
                label: t('awpOnboarding.azure.onboardingCustomizations.ManagedIdentityName.label'),
                tooltip: t('awpOnboarding.azure.onboardingCustomizations.ManagedIdentityName.tooltip'),
            },
            {
                key: OnboardingCustomizationsKeys.ManagementResourceGroupName,
                label: t('awpOnboarding.azure.onboardingCustomizations.ManagementResourceGroupName.label'),
                tooltip: t('awpOnboarding.azure.onboardingCustomizations.ManagementResourceGroupName.tooltip'),
            },
            {
                key: OnboardingCustomizationsKeys.CryptoCreatorRoleName,
                label: t('awpOnboarding.azure.onboardingCustomizations.CryptoCreatorRoleName.label'),
                tooltip: t('awpOnboarding.azure.onboardingCustomizations.CryptoCreatorRoleName.tooltip'),
            },
            {
                key: OnboardingCustomizationsKeys.DiskEncryptorRoleName,
                label: t('awpOnboarding.azure.onboardingCustomizations.DiskEncryptorRoleName.label'),
                tooltip: t('awpOnboarding.azure.onboardingCustomizations.DiskEncryptorRoleName.tooltip'),
            },
        ];
    }, [t]);

    const relevantAdvancedFields = useMemo(() => {
        let keysToKeep: Array<OnboardingCustomizationsKeys> = [];

        if (scanMode === ScanMode.Saas) {
            keysToKeep = [OnboardingCustomizationsKeys.VMDataShareRolename];
        } else if (scanMode == ScanMode.InAccount) {
            keysToKeep = [
                OnboardingCustomizationsKeys.VMDataShareRolename,
                OnboardingCustomizationsKeys.VMScanOperatorRolename,
                OnboardingCustomizationsKeys.FunctionAppsOperatorRolename,
                OnboardingCustomizationsKeys.FunctionAppsScannerRolename,
                OnboardingCustomizationsKeys.ResourceGroupName,
                OnboardingCustomizationsKeys.ManagedIdentityName,
            ];
        } else if (scanMode === ScanMode.InAccountHub) {// aka: Centralized
            keysToKeep = [
                OnboardingCustomizationsKeys.VMDataShareRolename,
                OnboardingCustomizationsKeys.VMScanOperatorRolename,
                OnboardingCustomizationsKeys.FunctionAppsOperatorRolename,
                OnboardingCustomizationsKeys.FunctionAppsScannerRolename,
                OnboardingCustomizationsKeys.ResourceGroupName,
                OnboardingCustomizationsKeys.ManagedIdentityName,
                ...(sseCmkEncryptedDisksScan ? [
                    OnboardingCustomizationsKeys.CryptoCreatorRoleName,
                    OnboardingCustomizationsKeys.DiskEncryptorRoleName,
                ] : []),
            ];
        } else if (scanMode === ScanMode.InAccountSub) {
            keysToKeep = [OnboardingCustomizationsKeys.ResourceGroupName];
        }

        return allAdvancedFields.filter((field) => keysToKeep.includes(field.key));
    }, [allAdvancedFields, scanMode, sseCmkEncryptedDisksScan]);

    return {
        loading: false,
        error: false,
        advancedFields: relevantAdvancedFields,
    };
};

export default useOnboardingCustomizationsFields;
