import React from 'react';
import { CveOverview } from 'modules/workloads/services/cveDrawerRegistry/cveDrawerRegistry.types';
import { Link, Stack, Typography } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

const CveReferences: React.FC<{ overview: CveOverview }> = ({ overview }) => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    const { urls } = overview.cve;

    return (
        <Stack spacing={1}>
            <Typography variant='body500'>{t('cveDrawer.cveReferencesPanel.cveReferences')}</Typography>
            <Stack spacing={1}>
                {urls.map((url, index) => (
                    <Link key={`url-${index}`} externalUrl={url}>
                        <Typography>{url}</Typography>
                    </Link>
                ))}
            </Stack>
        </Stack>
    );
};

export default CveReferences;
