import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertType } from '@dome9/berries/react-components';
import WorkloadService from 'modules/workloads/services/workload/workload.service';
import { WorkloadEnvironment } from 'modules/workloads/services/workload/workload.interface';
import { ALinkWithUnderline } from './AlertUnsecured.styled';
import { generateUrl } from '../events/utils';
import { BaseUrl } from '../events/events.interface';
import { Typography } from 'common/design-system/components-v2';
import { Vendors } from 'common/consts/vendors';

const unsecuredLink = (vendor: Vendors) => generateUrl({
    baseUrl: BaseUrl.Assets_Environments,
    queryData: {
        filter: {
            fields: [
                {
                    name: 'customData|status',
                    value: 'unsecured'
                },
                {
                    name: 'platform',
                    value: vendor
                }
            ]
        }
    },
    additionalQueryData: [`envType=${vendor}`]
});

const AlertUnsecured: React.FC = () => {
    const { t } = useTranslation('k8s_cluster');
    const [alertUnsecured, setCountAlertUnsecuredEnv] = React.useState<{ countKubernetes: number, countContainerRegistry: number; isDisplay: boolean; }>({
        countKubernetes: 0,
        countContainerRegistry: 0,
        isDisplay: false
    });

    type CountUnmonitoredKubernetes = (environments: WorkloadEnvironment['kubernetesAccounts']) => number
    const countUnmonitoredKubernetes: CountUnmonitoredKubernetes = useCallback((environments) => {
        let unmonitoredEnvironmentCount = 0;

        if(!environments) return unmonitoredEnvironmentCount;

        environments.forEach(environment => {
            const { managedKubernetesAccountDetails, onBoardedKubernetesAccountDetails } = environment;

            if (managedKubernetesAccountDetails && !onBoardedKubernetesAccountDetails) {
                unmonitoredEnvironmentCount++;
            }
        });

        return unmonitoredEnvironmentCount;

    },[]);

    type CountUnmonitoredContainerRegistry = (environments: WorkloadEnvironment['containerRegistryAccounts']) => number
    const countUnmonitoredContainerRegistry: CountUnmonitoredContainerRegistry = useCallback((environments) => {
        let unmonitoredEnvironmentCount = 0;

        if(!environments) return unmonitoredEnvironmentCount;

        environments.forEach(environment => {
            const { managedRegistriesAccountDetails, onBoardedRegistriesAccountDetails } = environment;

            if (managedRegistriesAccountDetails && !onBoardedRegistriesAccountDetails) {
                unmonitoredEnvironmentCount++;
            }
        });

        return unmonitoredEnvironmentCount;
    }, []);

    const haveUnsecuredEnv = useCallback( async () => {
        try {
            const { data } = await WorkloadService.getWorkloadEnvironment();
            const countKubernetes = countUnmonitoredKubernetes(data?.kubernetesAccounts);
            const countContainerRegistry = countUnmonitoredContainerRegistry(data?.containerRegistryAccounts);

            if(countKubernetes + countContainerRegistry > 0) {
                setCountAlertUnsecuredEnv(() => ({ countKubernetes, countContainerRegistry, isDisplay: true }));
            }

        } catch (e) {
            setCountAlertUnsecuredEnv((prevState) => ({ ...prevState, isDisplay: false }));
        }
    }, [countUnmonitoredContainerRegistry, countUnmonitoredKubernetes]);

    useEffect(() => {
        haveUnsecuredEnv();
    }, [haveUnsecuredEnv]);

    const unsecuredEnvironments = useMemo(() => [
        {
            key: 'dashboard.alertUnsecured.k8sLinkText',
            count: alertUnsecured.countKubernetes,
            vendor: Vendors.KUBERNETES,
        },
        {
            key: 'dashboard.alertUnsecured.containerRegistryLinkText',
            count: alertUnsecured.countContainerRegistry,
            vendor: Vendors.CONTAINER_REGISTRY,
        },
    ]
        .filter(({ count }) => count > 0)
        .map(({ key, count, vendor }) => (
            <ALinkWithUnderline
                key={key}
                href={unsecuredLink(vendor)}
            >
                {t(key, { count })}
            </ALinkWithUnderline>
        )).reduce<JSX.Element[]>((acc, curr, index) => {
            if (index === 0) {
                return [curr];
            }
            return [...acc, <span key={index}>{t('dashboard.alertUnsecured.and')}</span>, curr];
        }, [])
    , [alertUnsecured.countContainerRegistry, alertUnsecured.countKubernetes, t]);

    if (!alertUnsecured.isDisplay) return null;

    return (
        <Alert
            type={AlertType.WARNING}
            onClose={() => setCountAlertUnsecuredEnv((prevState) => ({ ...prevState, isDisplay: false }))}
        >
            <Typography>
                {t('dashboard.alertUnsecured.prefix')}
                {unsecuredEnvironments}
            </Typography>
        </Alert>
    );
};

export default AlertUnsecured;
