import React from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';

const Steps: React.FC<{ steps: Array<RouteProps> }> = ({ steps }) => {
    return (
        <Switch>
            {
                // @ts-ignore
                steps.map((route) => <Route {...route} key={route.path} />)
            }
        </Switch>
    );
};

export default Steps;
