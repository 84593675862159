import React from 'react';
import { useTheme } from 'styled-components';
import { Stack, Typography } from 'common/design-system/components-v2';
import { EventOverviewTabProps } from 'common/module_interface/events/EventsDrawerRegistry';
import EmptyState from 'common/components/EmptyState/EmptyState';
import Vulnerabilities from 'modules/workloads/protectedAsset/page/tabs/ImageOverviewTab/components/Vulnerabilities/Vulnerabilities';
import { buildVulnerabilityObject } from 'modules/workloads/protectedAsset/page/tabs/ImageOverviewTab/containers/ContainersVulnerabilities';
import { generateAssetPageUrl } from 'common/module_interface/assets/utils';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { PROTECTED_ASSETS_URL } from 'common/module_interface/assets/ProtectedAssets.consts';
import { changeUrl } from 'common/utils/http';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

const VulnerabilitySection: React.FC<EventOverviewTabProps> = ({ finding: event }) => {
    const { entityObject: { vulnerabilityStats } } = event;
    const { t } = useTranslation(getK8sNamespace('image-overview'));
    const theme = useTheme();

    const onClickGoVulnerability = () => {
        const asset = getProtectedAssetsService().getAssetByType(event.entityType);
        if(!asset) return;

        const url = asset.getUrl({
            asset: asset,
            entityId: event.entityObject.scannedAsset.externalId,
            cloudAccountId: event.entityObject.scannedAsset.environmentId,
            entityType: event.entityObject.scannedAsset.entityType,
            platform: event.entityObject.scannedAsset.platform,
            dome9Id: event.entityObject.scannedAsset.id
        });

        if(!url) return;

        const finalUrl = generateAssetPageUrl(asset, url, PROTECTED_ASSETS_URL, undefined, 'vulnerabilities');
        changeUrl(finalUrl);
    };

    return (
        <Stack fullWidth spacing={2}>
            <Typography color="strong" variant="body500">Findings</Typography>
            {vulnerabilityStats ?
                <Stack style={{ maxWidth: '50%' }}>
                    <Vulnerabilities
                        VulnerabilityObject={buildVulnerabilityObject(vulnerabilityStats)}
                        onClickGoVulnerability={onClickGoVulnerability}
                    />
                </Stack> : <EmptyState iconSize={96} iconName={'imageProtection'} customColor={theme.palette.border.light} label={t('emptyState.title')} />}
        </Stack>
    );
};

export default VulnerabilitySection;
