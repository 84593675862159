import { ALink } from 'common/components/ALink';
import { Button, Checkbox, SelectV2, Stack, Typography } from 'common/design-system/components-v2';
import { Icon } from '@dome9/berries/react-components';
import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonsWrapper, CustomCommand, LoaderWrapper } from './awpOnboardingAzure.styled';
import { getNotificationsService } from 'common/interface/services';
import AwpService from 'modules/workloads/services/awp/awp.service';
import { OnboardingCustomizationsKeys, ScanMode } from 'modules/workloads/services/awp/awp.interface';
import { NotificationType } from 'common/interface/notifications';
import { changeUrl } from 'common/utils/http';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import { Trans, useTranslation } from 'react-i18next';
import Select from 'modules/workloads/components/Select/Select';
import AwpOnboardingPage from '../components/AwpOnboardingPage/AwpOnboardingPage';
import useHubs from './hooks/useHubs';
import useAgentlessAzureOnboardingData from './hooks/useAgentlessAzureOnboardingData';
import {
    centralizedSectionOptions,
    inAccountSectionOptions,
    saasSectionOptions,
    sendNotificationAndRedirectToEnvironments
} from '../utils';
import InstructionStep from 'modules/workloads/components/InstructionStep/InstructionStep';
import { getCloudAccountsService } from 'common/interface/data_services';
import Input from 'common/design-system/components-v2/Input/Input';
import { Controller, useForm } from 'react-hook-form';
import useInitAwpOnboardingAzure from './hooks/useInitAwpOnboardingAzure';
import useOnboardingCustomizationsFields from './hooks/useOnboardingCustomizationsFields';
import { FormValues, ScanModeCardsSection } from './awpOnboardingAzure.types';
import { generateInitialFormValues, getRelevantOnboardingCustomizations } from './awpOnboarding.utils';
import Switch from 'common/design-system/components-v2/Switch';
import CollapseSection from 'modules/workloads/components/CollapseSection/CollapseSection';
import { Vendors } from 'common/consts/vendors';
import ScanModeSelector from '../components/ScanModeSelector/ScanModeSelector';
import { VerticalDivider, InstructionsWrapper } from '../components/Onboarding.styled';
import { InAccountScannerVPC } from 'modules/workloads/aws/tabs/awp/components/Modals/AdvancedConfig/AdvancedConfig.types';

const AwpOnboardingAzure: React.FC = () => {
    const { t } = useTranslation('k8s_awp');
    const { cloudAccountId } = useParams<{ cloudAccountId: string; }>();
    const { loading: isLoading, subscriptionId } = useInitAwpOnboardingAzure(cloudAccountId);
    const [isLoadingEnableBlade, setIsLoadingEnableBlade] = useState<boolean>(false);
    const { hubs, loading: isHubsLoading } = useHubs(cloudAccountId, Vendors.AZURE);
    const {
        scanMode,
        setScanMode,
        centralizedCloudAccountId,
        setCentralizedCloudAccountId,
        agentlessAzureOnboardingData,
        skipFunctionAppsScan,
        setSkipFunctionAppsScan,
        setOnboardingCustomizations,
        onboardingCustomizations,
        onboardingCustomizationsEnabled,
        setOnboardingCustomizationsEnabled,
        managementGroupId,
        setManagementGroupId,
        sseCmkEncryptedDisksScan,
        setSseCmkEncryptedDisksScan,
        inAccountScannerVPC,
        setInAccountScannerVPC,
        loading: loadingScript,
        setLoading: setLoadingScript,
    } = useAgentlessAzureOnboardingData(cloudAccountId);
    const { advancedFields: relevantFields } = useOnboardingCustomizationsFields(scanMode, sseCmkEncryptedDisksScan);
    const [initialFormValues, setInitialFormValues] = useState<FormValues>();

    const scanModeSections: Array<ScanModeCardsSection> = [
        { title: 'In-Account', cards: inAccountSectionOptions() },
        { title: 'Saas', cards: saasSectionOptions() },
        { title: 'Centralized', cards: centralizedSectionOptions() },
    ];

    const hubsOptions = useMemo(() => {
        return hubs?.map((hub) => ({ value: hub.cloudAccountId, label: hub.name })) || [];
    }, [hubs]);

    const {
        control,
        setValue,
        resetField,
        getValues
    } = useForm<FormValues>({
        mode: 'onChange',
    });

    const setFormValues = useCallback((values: FormValues) => {
        (Object.keys(values) as Array<keyof FormValues>).forEach(key => {
            setValue(key, values[key]);
        });
    }, [setValue]);

    const onOnboardingCustomizationsEnabledChanged = useCallback(() => {
        if (onboardingCustomizationsEnabled && initialFormValues) {
            setFormValues(initialFormValues);
        }
        setOnboardingCustomizationsEnabled(!onboardingCustomizationsEnabled);
    }, [onboardingCustomizationsEnabled, initialFormValues, setFormValues, setOnboardingCustomizationsEnabled]);

    const debounceTimer = useRef<NodeJS.Timeout | null>(null);

    const updateOnboardingCustomizations = useCallback(() => {
        setLoadingScript(true);
        if (debounceTimer.current) clearTimeout(debounceTimer.current);

        debounceTimer.current = setTimeout(() => {
            const currentFormValues = getValues();
            const { managementGroupId, ...rest } = currentFormValues;
            setOnboardingCustomizations(rest);
            setManagementGroupId(managementGroupId);
        }, 3300);
    }, [getValues, setLoadingScript, setManagementGroupId, setOnboardingCustomizations]);

    const onResetFormValue = useCallback((fieldName: OnboardingCustomizationsKeys) => {
        if (!initialFormValues) return;
        const initialValue = initialFormValues[fieldName];
        resetField(fieldName);
        setValue(fieldName, initialValue);
        updateOnboardingCustomizations();
    }, [initialFormValues, resetField, setValue, updateOnboardingCustomizations]);

    useEffect(() => {
        if (initialFormValues) {
            setFormValues(initialFormValues);
        }

        if (onboardingCustomizationsEnabled) {
            setOnboardingCustomizationsEnabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scanMode]);

    useEffect(() => {
        if (subscriptionId) {
            const initialFormValues = generateInitialFormValues(subscriptionId);
            setInitialFormValues(initialFormValues);
            setOnboardingCustomizations(initialFormValues);
            setFormValues(initialFormValues);
        }
    }, [setFormValues, setOnboardingCustomizations, subscriptionId]);

    const enableAgentlessAzureAccount = async () => {
        setIsLoadingEnableBlade(true);
        const relevantOnboardingCustomizations = getRelevantOnboardingCustomizations(relevantFields, onboardingCustomizations);

        try {
            const request: any = {
                scanMode,
                ...(onboardingCustomizationsEnabled ? { onboardingCustomizations: relevantOnboardingCustomizations } : {}),
                ...([ScanMode.InAccount, ScanMode.InAccountHub].includes(scanMode) && { agentlessAccountSettings: { inAccountScannerVPC } })
            };

            switch (scanMode) {
                case ScanMode.InAccountHub: {// aka: Centralized
                    if (managementGroupId) {
                        request.managementGroupId = managementGroupId;
                    }
                    if (sseCmkEncryptedDisksScan) {
                        request.agentlessAccountSettings = { ...request.agentlessAccountSettings, sseCmkEncryptedDisksScan: true };
                    }

                    await AwpService.enableHubAccount(cloudAccountId, Vendors.AZURE, request);
                    break;
                }
                case ScanMode.InAccountSub: {
                    request.centralizedCloudAccountId = centralizedCloudAccountId;

                    if (skipFunctionAppsScan) {
                        request.agentlessAccountSettings = { ...(request.agentlessAccountSettings || {}), skipFunctionAppsScan: true };
                    }

                    if (centralizedCloudAccountId) {
                        await AwpService.enableAzureSubAccount(cloudAccountId, request);
                    }
                    break;
                }
                default: {
                    if (skipFunctionAppsScan && scanMode === ScanMode.InAccount) {
                        request.agentlessAccountSettings = { ...request.agentlessAccountSettings, skipFunctionAppsScan: true };
                    }

                    await AwpService.enableAwpAzureAccount(cloudAccountId, request);
                }
            }

            getCloudAccountsService().clearCache();
            sendNotificationAndRedirectToEnvironments(
                NotificationType.SUCCESS,
                t('awpOnboarding.messages.success'),
            );
        } catch (error: any) {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: t('awpOnboarding.messages.failure'),
                text: error?.response?.data || '',
            });

            setIsLoadingEnableBlade(false);
        }
    };

    const onCancel = () => {
        changeUrl('/cloud-account/index');
    };

    const loadingElement = useMemo(() => {
        if (scanMode === ScanMode.InAccountSub && !centralizedCloudAccountId) {
            return (
                <Stack spacing={2} direction='row' alignItems='center'>
                    <Icon name={'info'} size={16} color='info'/>
                    <Typography>{t('awpOnboarding.azure.pleaseChooseACentralizedAccountToContinue')}</Typography>
                </Stack>
            );
        }
        return undefined;
    }, [centralizedCloudAccountId, scanMode, t]);

    const showSkipFunctionAppCheckbox = useMemo(() => {
        return [ScanMode.InAccount, ScanMode.InAccountSub].includes(scanMode);
    }, [scanMode]);
    const showOnboardingCustomizations = useMemo(() => !!relevantFields.length, [relevantFields]);
    const showManagementGroupIdField = useMemo(() => scanMode === ScanMode.InAccountHub, [scanMode]);
    const showAdvancedOptions = useMemo(() => {
        return showSkipFunctionAppCheckbox || showOnboardingCustomizations || showManagementGroupIdField;
    }, [showManagementGroupIdField, showOnboardingCustomizations, showSkipFunctionAppCheckbox]);

    const instructionSteps = useMemo<Array<{ content: React.ReactElement, placement?: any }>>(() => [
        ...(scanMode === ScanMode.InAccountSub ? [{
            placement: 'baseline',
            content: (
                <Stack key='selection-step' direction='column' spacing={1} fullWidth>
                    <Typography>{t('awpOnboarding.azure.instructions.centralizedSelectionStep.chooseCentralizedAccount')}</Typography>
                    <Select
                        disabled={isHubsLoading}
                        options={hubsOptions}
                        onChange={(value) => setCentralizedCloudAccountId(value)}
                        defaultValue={ScanMode.Saas}
                        noOptionsMessage={t('awpOnboarding.azure.instructions.centralizedSelectionStep.noCentralizedAccountsOnboarded')}
                        placeholder={t(isHubsLoading ? 'loading' : 'awpOnboarding.azure.instructions.centralizedSelectionStep.selectCentralizedAccount')}
                        error={!centralizedCloudAccountId && !isHubsLoading}
                    />
                </Stack>
            )
        }] : []),
        {
            placement: 'baseline',
            content: (
                <Stack spacing={2}>
                    <Typography>
                        <Trans
                            i18nKey={'k8s_awp:awpOnboarding.azure.instructions.step1.firstPart'}
                            components={{
                                firstLink: <ALink target={'_blank'} href='https://shell.azure.com/'></ALink>,
                                secondLink: <ALink target={'_blank'} href='https://learn.microsoft.com/en-us/cli/azure/install-azure-cli'></ALink>
                            }}
                        />
                    </Typography>
                    {showAdvancedOptions && (
                        <CollapseSection title={t('awpOnboarding.azure.advanced')}>
                            <>
                                {showOnboardingCustomizations && (
                                    <Stack direction='column' spacing={4} padding={1}>
                                        {scanMode === ScanMode.InAccountHub && (
                                            <Checkbox
                                                labelProps={{ tooltip: t('awpOnboarding.azure.useSseCmkEncryptedDisksScan.tooltip') }}
                                                label={t('awpOnboarding.azure.useSseCmkEncryptedDisksScan.label')}
                                                checked={sseCmkEncryptedDisksScan}
                                                onChange={() => setSseCmkEncryptedDisksScan(!sseCmkEncryptedDisksScan)}
                                            />
                                        )}
                                        <Switch
                                            label={t('awpOnboarding.azure.useOnboardingCustomizations')}
                                            checked={onboardingCustomizationsEnabled}
                                            onChange={() => onOnboardingCustomizationsEnabledChanged()}
                                        />
                                        <Stack spacing={2}>
                                            {relevantFields.map((field) => {
                                                const { key, label, tooltip } = field;
                                                return (
                                                    <Controller
                                                        key={key}
                                                        control={control}
                                                        name={key}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            const isInitialValue = !!(
                                                                initialFormValues
                                                                && initialFormValues[key]
                                                                && value === initialFormValues[key]
                                                            );
                                                            return (
                                                                <Input
                                                                    key={key}
                                                                    label={label}
                                                                    disabled={!onboardingCustomizationsEnabled}
                                                                    onChange={(value) => {
                                                                        onChange(value);
                                                                        updateOnboardingCustomizations();
                                                                    }}
                                                                    tooltip={tooltip}
                                                                    placeholder={`Set ${label}`}
                                                                    fullWidth
                                                                    value={value}
                                                                    endAdornment={
                                                                        !isInitialValue 
                                                                            ? <Button
                                                                                size='small'
                                                                                variant={'contained'}
                                                                                onClick={() => onResetFormValue(name)}
                                                                            >Reset</Button>
                                                                            : undefined
                                                                    }
                                                                />
                                                            );
                                                        }}
                                                    />
                                                );
                                            })}
                                            {showManagementGroupIdField &&
                                                <Controller
                                                    control={control}
                                                    name={'managementGroupId'}
                                                    render={({ field: { onChange, value } }) => {
                                                        return (
                                                            <Input
                                                                label={t('awpOnboarding.azure.ManagementGroupId.label')}
                                                                disabled={!onboardingCustomizationsEnabled}
                                                                onChange={(value) => {
                                                                    onChange(value);
                                                                    updateOnboardingCustomizations();
                                                                }}
                                                                tooltip={t('awpOnboarding.azure.ManagementGroupId.tooltip')}
                                                                placeholder={t('awpOnboarding.azure.setManagementGroupId')}
                                                                fullWidth
                                                                value={value}
                                                            />
                                                        );
                                                    }}
                                                />
                                            }
                                        </Stack>
                                    </Stack>)}
                            </>
                            <>
                                {showSkipFunctionAppCheckbox && (
                                    <Stack padding={[0, 0, 0, 1]}>
                                        <Checkbox
                                            labelProps={{
                                                tooltip: t('awpOnboarding.azure.withFunctionApp.tooltip')
                                            }}
                                            label={t('awpOnboarding.azure.withFunctionApp.label')}
                                            checked={!skipFunctionAppsScan}
                                            onChange={() => setSkipFunctionAppsScan(currentState => !currentState)}
                                        />
                                    </Stack>
                                )}
                                {![ScanMode.Saas, ScanMode.InAccountSub].includes(scanMode) && (
                                    <SelectV2
                                        isMulti={false}
                                        fullWidth
                                        label={t('awpTab.modals.advancedConfig.fields.customVpc.label', { context: Vendors.AZURE })}
                                        tooltip={t('awpTab.modals.advancedConfig.fields.customVpc.tooltip')}
                                        value={inAccountScannerVPC}
                                        onChange={(value) => setInAccountScannerVPC(value as InAccountScannerVPC)}
                                        options={[
                                            { label: t('inAccountScannerVPC.ManagedByAWP'), value: InAccountScannerVPC.ManagedByAWP },
                                            { label: t('inAccountScannerVPC.ManagedByCustomer'), value: InAccountScannerVPC.ManagedByCustomer },
                                        ]}
                                    />
                                )}
                            </>
                        </CollapseSection>
                    )}
                    <Stack direction='column' spacing={2}>
                        <CustomCommand
                            isLoading={loadingScript}
                            customLoadingElement={loadingElement}
                            text={agentlessAzureOnboardingData?.onboardingScriptCommand || ''}
                        />
                    </Stack>
                    <Typography>{t('awpOnboarding.azure.instructions.step1.secondPart')}</Typography>
                    <Typography>
                        <Trans
                            i18nKey={'k8s_awp:awpOnboarding.azure.instructions.step1.thirdPart'}
                            components={{
                                downloadLink: <ALink target={'_blank'} href={agentlessAzureOnboardingData?.onboardingScriptURL}></ALink>,
                            }}
                        />
                    </Typography>
                </Stack>
            )
        },
        {
            content: <Typography>{t('awpOnboarding.azure.instructions.step2')}</Typography>
        }
    ], [scanMode, t, isHubsLoading, hubsOptions, centralizedCloudAccountId, showAdvancedOptions, showOnboardingCustomizations, sseCmkEncryptedDisksScan, onboardingCustomizationsEnabled, relevantFields, showManagementGroupIdField, control, showSkipFunctionAppCheckbox, skipFunctionAppsScan, inAccountScannerVPC, loadingScript, loadingElement, agentlessAzureOnboardingData?.onboardingScriptCommand, agentlessAzureOnboardingData?.onboardingScriptURL, setCentralizedCloudAccountId, setSseCmkEncryptedDisksScan, onOnboardingCustomizationsEnabledChanged, initialFormValues, updateOnboardingCustomizations, onResetFormValue, setSkipFunctionAppsScan, setInAccountScannerVPC]);

    if (isLoading) {
        return (
            <LoaderWrapper>
                <CloudAnimationLoader size='medium' />
            </LoaderWrapper>
        );
    }

    const actions = (
        <ButtonsWrapper>
            <Button variant='text' onClick={onCancel}>{t('awpOnboarding.azure.cancel')}</Button>
            <Button
                onClick={enableAgentlessAzureAccount}
                loading={isLoadingEnableBlade}
                color='brandPrimary'
                disabled={(scanMode === ScanMode.InAccountSub && !centralizedCloudAccountId) || !agentlessAzureOnboardingData?.onboardingScriptCommand}>
                {t('awpOnboarding.azure.enableAwp')}
            </Button>
        </ButtonsWrapper>
    );

    return (
        <AwpOnboardingPage
            isLoading={isLoading}
            bulletPointTransKey='awpOnboarding.header.azurePoints'
        >
            <Stack direction='row' spacing={10}>
                <>
                    <InstructionStep stepNumber={1} style={{ flex: 2 }}>
                        <Stack spacing={3}>
                            <Typography>{t('awpOnboarding.azure.instructions.scanModeStep.chooseScanMode')}:</Typography>
                            <ScanModeSelector scanModeSections={scanModeSections} selectedScanMode={scanMode} onSelectScanMode={setScanMode} />
                        </Stack>
                    </InstructionStep>
                    <VerticalDivider />
                </>
                <InstructionsWrapper spacing={2} >
                    {instructionSteps.map(({ content, placement }, index) =>
                        <InstructionStep key={`step-${index + 1}`} stepNumber={index + 2} alignItems={placement}>
                            {content}
                        </InstructionStep>,
                    )}
                    {actions}
                </InstructionsWrapper>
            </Stack>
        </AwpOnboardingPage>

    );
};

export default AwpOnboardingAzure;
