import React from 'react';
import { useTranslation } from 'react-i18next';
import { AdvancedConfigProps, fieldsValidations } from './AdvancedConfig.types';
import { Controller, useForm } from 'react-hook-form';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { useRegistrySettings } from 'modules/workloads/reducers/registries/hooks/useRegistrySettings';
import { ContainerRegistryUserConfigurations } from 'modules/workloads/services/containerRegistry/containerRegistry.interface';
import { useUpdateRegistrySettings } from 'modules/workloads/reducers/registries/hooks/useUpdateRegistrySettings';
import { Icon, Message, Stack, Typography } from 'common/design-system/components-v2';
import { TextField, Tooltip } from '@dome9/berries/react-components';

const RegistryScannersAdvancedConfig: React.FunctionComponent<AdvancedConfigProps> = ({ closeModal, registryId }) => {
    const { t } = useTranslation(['k8s_registry', 'k8s_common']);
    
    const { isLoading, isError, data: registrySettings } = useRegistrySettings(registryId);

    const handleOnSettingsUpdateSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('k8s_registry:registryPage.scanners.modals.advancedConfigurations.successMessage'),
            text: '',
        });
        closeModal();
    };

    const handleOnSettingsUpdateError = ({ message }: { message: string }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('k8s_registry:registryPage.scanners.modals.advancedConfigurations.failMessage'),
            text: message,
        });
    };

    const { updateRegistrySettings, isLoading: isUpdateLoading } = useUpdateRegistrySettings({ onError: handleOnSettingsUpdateError, onSuccess: handleOnSettingsUpdateSuccess });
 
    const handleOnConfirm = (data: ContainerRegistryUserConfigurations) => {
        updateRegistrySettings(registryId, {
            maxImagesPerRepo: data.maxImagesPerRepo,
            imageListIntervalSeconds: data.imageListIntervalSeconds * 3600 // Convert imageListIntervalSeconds as hours back to seconds
        });
    };

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm<ContainerRegistryUserConfigurations>({
        defaultValues: {
            maxImagesPerRepo: 10,
            imageListIntervalSeconds: 12
        },
    });

    React.useEffect(() => {
        if (registrySettings) {
            setValue('imageListIntervalSeconds', registrySettings.imageListIntervalSeconds / 3600); // Convert imageListIntervalSeconds to hours
            setValue('maxImagesPerRepo', registrySettings.maxImagesPerRepo);
        }
    }, [registrySettings, setValue]);

    if (isLoading || isError || !registrySettings) return null;
    
    return (
        <Message
            isLoading={isLoading || isUpdateLoading}
            width='lg'
            onCancel={closeModal}
            onClose={closeModal}
            onConfirm={handleSubmit((data) => handleOnConfirm(data))}
            title={t('k8s_registry:registryPage.scanners.modals.advancedConfigurations.title')}
        >
            <Stack spacing={2}>
                <Stack spacing={1}>
                    <Stack spacing={1} direction='row' alignItems='center'>
                        <Typography variant='bodyLg'>{t('k8s_registry:registryPage.scanners.modals.advancedConfigurations.maxImagesPerRepo.label')}</Typography>
                        <Tooltip content={t('k8s_registry:registryPage.scanners.modals.advancedConfigurations.maxImagesPerRepo.tooltip')}>
                            <Stack alignItems='center'>
                                <Icon size={14} name='info' />
                            </Stack>
                        </Tooltip>
                    </Stack>
                    <Controller
                        name='maxImagesPerRepo'
                        rules={fieldsValidations(t)['maxImagesPerRepo']}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                type='number'
                                placeholder={t('k8s_registry:registryPage.scanners.modals.advancedConfigurations.maxImagesPerRepo.placeholder')}
                                onClear={() => setValue('maxImagesPerRepo', 10)}
                                helper={errors['maxImagesPerRepo'] ? errors['maxImagesPerRepo']['message'] : ''}
                                state={errors['maxImagesPerRepo'] ? 'error' : 'success'}
                            />

                        )}
                    />
                </Stack>
                <Stack spacing={1}>
                    <Stack spacing={1} direction='row' alignItems='center'>
                        <Typography variant='bodyLg'>{t('k8s_registry:registryPage.scanners.modals.advancedConfigurations.imageListIntervalSeconds.label')}</Typography>
                        <Tooltip content={t('k8s_registry:registryPage.scanners.modals.advancedConfigurations.imageListIntervalSeconds.tooltip')}>
                            <Stack alignItems='center'>
                                <Icon size={14} name='info' />
                            </Stack>
                        </Tooltip>
                    </Stack>
                    <Controller
                        name='imageListIntervalSeconds'
                        rules={fieldsValidations(t)['imageListIntervalSeconds']}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                type='number'
                                placeholder={t('k8s_registry:registryPage.scanners.modals.advancedConfigurations.imageListIntervalSeconds.placeholder')}
                                onClear={() => setValue('imageListIntervalSeconds', 12)}
                                helper={errors['imageListIntervalSeconds'] ? errors['imageListIntervalSeconds']['message'] : ''}
                                state={errors['imageListIntervalSeconds'] ? 'error' : 'success'}
                            />
                        )}
                    />
                </Stack>
            </Stack>
        </Message>
    );
};

export default RegistryScannersAdvancedConfig;
