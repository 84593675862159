import { Trans, useTranslation } from 'react-i18next';
import { DisableAWPType, DisableAwpModalProps } from '../DisableAWP.types';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import React, { useState } from 'react';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { Command, GroupSelection, Stack, Typography } from 'common/design-system/components-v2';

const DisableAwsTerraform: React.FC<DisableAwpModalProps> = ({ onClose, onDelete, isDeletePending }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));

    const [selectedOption, setSelectedOption] = useState<DisableAWPType>(DisableAWPType.nonSelected);

    return (
        <ConfirmationModal
            title={t('awpTab.modals.disableAWP.aws.title')}
            onClose={onClose}
            onCancel={onClose}
            onConfirm={() => onDelete(selectedOption === DisableAWPType.skipStackValidation)}
            isLoading={isDeletePending}
            submitBtnDisabled={selectedOption === DisableAWPType.nonSelected || isDeletePending}
        >
            <Stack spacing={4}>
                <Typography>
                    <Trans
                        i18nKey={'k8s_awp:awpTab.modals.disableAWP.aws.terraform'}
                        components={{
                            command: <Command text='terraform destroy' />,
                            div: <div />,
                            stack: <Stack spacing={2}>.</Stack>,
                        }}
                    />
                </Typography>
                <Stack>
                    <GroupSelection
                        onChange={(value) => setSelectedOption(value as DisableAWPType)}
                        value={selectedOption}
                        direction='column'
                        options={[
                            {
                                dataAid: 'skipStackValidation',
                                value: DisableAWPType.skipStackValidation,
                                name: 'disableAWP',
                                label: t('awpTab.modals.disableAWP.aws.radioButtons.skipTerraformValidation'),
                            },
                            {
                                dataAid: 'validateStack',
                                value: DisableAWPType.validateStack,
                                name: 'disableAWP',
                                label: t('awpTab.modals.disableAWP.aws.radioButtons.validateTerraform'),
                            },
                        ]}
                    />
                </Stack>
            </Stack>
        </ConfirmationModal>
    );
};

export default DisableAwsTerraform;