import React, { useMemo } from 'react';
import { WorkloadTypes } from 'modules/workloads/services/workload/workload.interface';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import Vulnerability from './Vulnerability';
import VulnerabilityWorkloads from './VulnerabilityWorkloads/VulnerabilityWorkloads';

const VulnerabilityWrapper: React.FC<{ entity: ICloudEntityData }> = (props) => {
    const {
        entity: {
            cloudAccountId,
            protectedAsset: { entityId: id, type, id: dome9Id },
            typeByPlatform
        } } = props;

    const isWorkloadType = useMemo(() => Object.values(WorkloadTypes).includes(typeByPlatform)
        ,[typeByPlatform]);

    return (isWorkloadType) ?
        <VulnerabilityWorkloads environmentId={cloudAccountId} dome9Id={dome9Id} /> :
        <Vulnerability type={type} id={id} environmentId={cloudAccountId} />;
    
};

export default VulnerabilityWrapper;