import { Hub } from 'modules/workloads/services/awp/awp.interface';
import AwpService from 'modules/workloads/services/awp/awp.service';
import { useEffect, useMemo, useState } from 'react';
import useAllCloudAccounts from './useAllCloudAccounts';
import { Vendors } from 'common/consts/vendors';

type HubWithName = Hub & { name: string };

type UseAllCloudAccountsPayload = {
    loading: boolean;
    error: boolean;

    hubs?: Array<HubWithName>; 
}

export type UseHubs = (cloudAccountId: string, platform: Vendors.AZURE | Vendors.AWS, organizationId?: string) => UseAllCloudAccountsPayload;
const useHubs: UseHubs = (cloudAccountId, platform, organizationId) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const { allCloudAccounts, loading: isAllCloudAccountsLoading } = useAllCloudAccounts();
    const [hubs, setHubs] = useState<Array<Hub>>();
    
    useEffect(() => {
        const getHubs = async (cloudAccountId: string) => {
            if (isAllCloudAccountsLoading) {
                return;
            }
            try {
                setLoading(true);
                const hubsRes = await AwpService.getHubs(cloudAccountId, platform, organizationId);
                setHubs(hubsRes.data);
                setError(false);
            } catch (e) { 
                setError(true);
                return;
            } finally {
                setLoading(false);
            }
        };

        getHubs(cloudAccountId);
    }, [cloudAccountId, platform, isAllCloudAccountsLoading, organizationId]);

    const hubsWithName = useMemo(() => {
        return (hubs ?? []).map(hub => {
            const cloudAccountName = allCloudAccounts?.find((cloudAccount) => cloudAccount.id === hub.cloudAccountId)?.name;
            return {
                ...hub,
                name: cloudAccountName ? `${cloudAccountName} (${hub.externalAccountNumber})` : hub.externalAccountNumber,
            };
        });
    }, [allCloudAccounts, hubs]);

    return {
        loading,
        error,
        hubs: hubsWithName,
    };
};

export default useHubs;
