import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import OperatingSystemContent
    from 'modules/workloads/pages/shiftLeft/components/OperatingSystemContent/OperatingSystemContent';
import InstructionsContent from '../../../../components/InstructionsContent/InstructionsContent';
import { FormValues } from 'modules/workloads/pages/shiftLeft/ShiftleftOnboarding/ShiftleftOnboarding';
import { useParams } from 'react-router-dom';
import { Message, Stack } from 'common/design-system/components-v2';

interface DownloadOSModalProps {
    closeModal: () => void
}

const DownloadOSModal: React.FC<DownloadOSModalProps> = ({ closeModal }) => {
    const { id } = useParams<{ id: string }>();

    const methods = useForm<Pick<FormValues, 'operatingSystems' | 'envId'>>({
        defaultValues: {
            operatingSystems: '',
            envId: id
        }
    });

    const { getValues } = methods;

    return (
        <Message title='Download Instructions' onClose={closeModal} width='lg' onCancel={closeModal} cancelBtnText='Close'>
            <FormProvider {...methods}>
                <Stack spacing={4}>
                    <OperatingSystemContent isModal />
                    {getValues('operatingSystems') && <><hr/> <InstructionsContent
                        isModal
                        envId={id}
                    /></>}
                </Stack>
            </FormProvider>
        </Message>
    );
};

export default DownloadOSModal;
