import { GetVulnerabilityScan, GetVulnerabilityScanMetadata } from './vulnerability.interface';
import { GetScanEngineVersion } from './scanEngineVersion.interface';
import { getHttpService } from 'common/interface/services';
import { AxiosResponse } from 'axios';

const getScanEngineVersion: GetScanEngineVersion.Function = async () => {
    return await getHttpService().request<AxiosResponse<GetScanEngineVersion.Response>>(
        'vulnerability/scan-engine-version',
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true } },
        err => { throw err; }
    );
};

const getVulnerabilityScan: GetVulnerabilityScan.Function = async ({ entityId, entityType, environmentId, remediationOnly }) => {
    return await getHttpService().request<AxiosResponse<GetVulnerabilityScan.Response>>(
        `vulnerability/scan?entityId=${entityId}&entityType=${entityType}&environmentId=${environmentId}&hasRemediation=${remediationOnly}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true } },
        err => { throw err; }
    );
};

const getVulnerabilityScanMetadata: GetVulnerabilityScanMetadata.Function = async ({ entityId, entityType, environmentId }) => {
    return await getHttpService().request<AxiosResponse<GetVulnerabilityScanMetadata.Response>>(
        `vulnerability/scan-metadata?entityId=${entityId}&entityType=${entityType}&environmentId=${environmentId}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true } },
        err => { throw err; }
    );
};

const vulnerabilityService = {
    getScanEngineVersion,
    getVulnerabilityScanMetadata,
    getVulnerabilityScan
};

export default vulnerabilityService;
