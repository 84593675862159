import { Command, Message, Stack } from 'common/design-system/components-v2';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DisableAwpModalProps } from '../DisableAWP.types';

const DisableAzureTerraform: React.FC<DisableAwpModalProps> = ({ onClose, onDelete, isDeletePending }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));

    return (
        <Message
            title={t('awpTab.modals.disableAWP.azure.title')}
            isOpen
            width='md'
            variant='danger'
            onClose={onClose}
            submitBtnDisabled={isDeletePending}
            onConfirm={() => onDelete(true)}
            onCancel={onClose}
            submitBtnText={t('awpTab.modals.disableAWP.submitTextButton')}
        >
            <Trans
                i18nKey='awpTab.modals.disableAWP.azure.terraform'
                t={t}
                components={{
                    div: <div />,
                    stack: <Stack spacing={[2]}>.</Stack>,
                    command: <Command text={'terraform destroy'} />
                }}
            />
        </Message>
    );

};

export default DisableAzureTerraform;