import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getHelmCommand, GetHelmCommandBody } from 'modules/workloads/utils/KubernetesOnBoarding.helm.service';
import { useGetCluster } from 'modules/workloads/reducers/cluster-new/hooks/useGetCluster';
import { ModalsProps } from '..';
import { Message, Stack, Typography, Command } from 'common/design-system/components-v2';
import HelmNotes from '../../HelmNotes/HelmNotes';

const RestoreInstallCommandModal: React.FC<ModalsProps> = ({ closeModal, clusterId }) => {
    const { t } = useTranslation();
    const { data: clusterData } = useGetCluster(clusterId);

    const helmCommand = useMemo<string>(() => {
        if (!clusterData) return '';
        const { admissionControlEnabled, runtimeProtectionEnabled, threatIntelligenceEnabled, imageAssuranceEnabled, imageAccessRuntimeMonitorEnabled } = clusterData.account;
        const body: GetHelmCommandBody = {
            apiKey: '<API-KEY>',
            apiSecret: '<API-SECRET>',
            namespace: '<NAMESPACE>',
            cluster: {
                id: clusterId,
                admissionControlEnabled,
                runtimeProtectionEnabled,
                threatIntelligenceEnabled,
                imageAssuranceEnabled,
                imageAccessRuntimeMonitorEnabled
            }
        };

        return getHelmCommand(body);// TODO: Change import of 'getHelmCommand' once Or's changes merged to master
    }, [clusterId, clusterData]);

    const onClose = () => {
        closeModal();
    };

    return (
        <Message
            width='lg'
            onClose={onClose}
            isOpen={true}
            title={t('K8S.CLUSTER_PAGE.RESTORE_INSTALL_COMMAND_MODAL.TITLE')}
            cancelBtnText='Close'
            onCancel={onClose}
        >
            <Stack spacing={1}>
                <Typography>{t('K8S.CLUSTER_PAGE.RESTORE_INSTALL_COMMAND_MODAL.BODY')}</Typography>
                <Command text={helmCommand} />
                <HelmNotes />
            </Stack>
        </Message>
    );
};

export default RestoreInstallCommandModal;
