import React from 'react';
import axios from 'axios';
import { EventHandlerCallback, KubernetesAccountDashboard } from '@dome9/cloudguard-widgets-dashboard';
import { getHttpService, getStoreService } from 'common/interface/services';
import handleDashboardEvent from './events';
import { dashboardMock } from './dashboard.mock';
import DashboardFilterPanel from './components/DashboardFilterPanel';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { DashboardStyled } from './Dashboard.styled';
import { TopRiskItemsType, UrlGeneratorCallback } from '@dome9/cloudguard-widgets-widgets';
import { generateWidgetUrlMain } from './events/events.handlers';
import AlertUnsecured from './AlertUnsecured/AlertUnsecured';
import { getCloudAccountsService } from 'common/interface/data_services';

const defaultDashboardFilters: { [key: string]: any } = {
    platform: ['1', '11', '15', '20'],
};

const Dashboard: React.FunctionComponent = () => {
    const [summaryTimeframeInDays] = React.useState<Array<number>>([7]);
    const [filters, setFilters] = React.useState<{ [key: string]: any }>({});

    const axiosInstance = React.useMemo(() => {
        const httpService = getHttpService();
        const axiosInstance = axios.create();
        axiosInstance.post = (url, data, config) => {
            if (config?.baseURL === '/magellanApi') {
                const state = getStoreService().state;
                const magellanUrl = state.app.magellanUrl;
                return httpService.request(`${magellanUrl}/${url}`, { data, method: 'POST' }, { returnAsAxiosResponse: true }, err => {
                    throw err;
                });
            }
            return httpService.request(url, { data, method: 'POST' }, { returnAsAxiosResponse: true }, err => {
                throw err;
            });
        };
        axiosInstance.get = axiosInstance.get = async (url) => {
            if (url?.includes('view/common/all-cloud-accounts')) {
                const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
                return ({ data: allCloudAccounts, status: 200, statusText: 'OK' }) as any;
            }
            return httpService.request(url, { method: 'GET' }, { returnAsAxiosResponse: true }, err => {
                throw err;
            });
        };
        axiosInstance.put = (url, data) => httpService.request(url, { data, method: 'PUT' }, { returnAsAxiosResponse: true }, err => {
            throw err;
        });
        axiosInstance.delete = (url) => httpService.request(url, { method: 'DELETE' }, { returnAsAxiosResponse: true }, err => {
            throw err;
        });
        return axiosInstance;
    }, []);

    const eventHandler: EventHandlerCallback = (widget, eventType, eventData): void => {
        handleDashboardEvent(widget, eventType, eventData);
    };

    const urlGenerator: UrlGeneratorCallback = (widget, eventData) => {
        return generateWidgetUrlMain(widget, eventData) || undefined;
    };

    const filterHandler = (newFilters: IFiltersValues) => {
        setFilters(newFilters);
    };

    const filtersWithDefaults = React.useMemo(() => {
        const noneEmptyFilters = Object.keys(filters).reduce((acc, key) => {
            const currentValue = filters[key];
            if (!currentValue || currentValue === '') {
                return acc;
            }
            if (Array.isArray(currentValue) && currentValue.length === 0) {
                return acc;
            }
            return { ...acc, [key]: currentValue };
        }, {});
        return ({ ...defaultDashboardFilters, ...noneEmptyFilters, summaryTimeframeInDays: summaryTimeframeInDays[0] });
    }, [filters, summaryTimeframeInDays]);

    const widgetFilters = React.useMemo(() => {
        return { TopRiskItems: { itemTypes: [TopRiskItemsType.Workloads, TopRiskItemsType.Images] } };
    }, []);

    return (
        <div>
            <AlertUnsecured />

            <DashboardFilterPanel
                axiosInstance={axiosInstance}
                onFilterChange={filterHandler}
                activeFilters={filters}
            />
            <DashboardStyled.DashboardWrapper>
                <KubernetesAccountDashboard
                    axiosInstance={axiosInstance}
                    eventHandler={eventHandler}
                    urlGenerator={urlGenerator}
                    data={dashboardMock}
                    filters={filtersWithDefaults}
                    widgetFilters={widgetFilters}
                />
            </DashboardStyled.DashboardWrapper>
        </div>
    );
};

export default Dashboard;
