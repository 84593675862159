import styled from 'styled-components';

export const AuthFieldInputWrapper = styled.div`
  width: 600px;
  margin-top: 16px;
  margin-left: 50px;
  
  & > div {
    margin-bottom: 5px;
  }
  
  .tool-tip-icon {
    margin-left: 5px;
    position: relative;
    top: 2px;
  }
`;