import React from 'react';
import vulnerabilityService from '../vulnerability.service';
import { UseScanEngineVersionPayload, UseScanEngineVersionState } from '../scanEngineVersion.interface';

export type UseScanEngineVersion = () => UseScanEngineVersionPayload;
export const useScanEngineVersion: UseScanEngineVersion = () => {
    const [{ loading, error, data }, setState] = React.useState<UseScanEngineVersionState>({
        loading: false,
        error: false,
    });

    const getData = async () => {
        setState(prevState => ({ ...prevState, loading: true, error: false }));
        try {
            const res = await vulnerabilityService.getScanEngineVersion();
            setState(({ loading: false, error: false, data: res.data }));
        } catch (error) {
            setState({ loading: false, error: true, data: { scanEngineVersion: '1.0.0' } });
        }
    };

    React.useEffect(() => {
        if (!loading && !error) {
            getData();
        }
    }, [loading, error]);

    return ({
        isLoading: loading,
        data
    });
};