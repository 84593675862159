import React, { useState, useCallback } from 'react';
import fileDownload from 'js-file-download';
import moment from 'moment-mini';
import useVulnerabilityScan from 'modules/workloads/services/vulnerability/hooks/useVulnerabilityScan';
import useVulnerabilityScanMetadata from 'modules/workloads/services/vulnerability/hooks/useVulnerabilityScanMetadata';
import { Tabs , Stack, Label } from 'common/design-system/components-v2';
import { ITabItemProps } from 'common/design-system/components-v2/Tabs/Tabs.types';
import Threats from './VulnerabilityTabs/Threats/Threats';
import Cves from './VulnerabilityTabs/Cves/Cves';
import Remediations from './VulnerabilityTabs/Remediations/Remediations';
import { restructureThreats, flattenPackageAndCve, flattenRemediation } from './utils';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import { ITableExportButton } from 'common/design-system/components-v2/Table/Table.types';
import { getHttpService, getNotificationsService } from 'common/interface/services';

const TABS = [
    { label: 'CVEs', value: 'cves' },
    { label: 'Threats', value: 'threats' },
    { label: 'Secrets (EA)', value: 'secrets' },
    { label: 'Remediations Summary', value: 'remediations' }
];

const Vulnerability: React.FC<{ id: string, type: string, environmentId: string }> = ({ id, type, environmentId }) => {
    const [remediationOnly, setRemediationOnly] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<ITabItemProps>(TABS[0]);

    const { data, isLoading } = useVulnerabilityScan({ entityId: id, entityType: type, environmentId: environmentId, remediationOnly: false });
    const { data: scanMetadata } = useVulnerabilityScanMetadata({ entityId: id, entityType: type, environmentId: environmentId });


    const flatPackageAndCveMemo = React.useMemo(() => flattenPackageAndCve(data?.Package || []), [data?.Package]);

    const restructureThreatsMemo = React.useMemo(() => restructureThreats(data?.Malware || []), [data?.Malware]);

    const restructureSecretsMemo = React.useMemo(() => restructureThreats(data?.InsecureContent || []), [data?.InsecureContent]);

    const flattenRemediationMemo = React.useMemo(() => flattenRemediation(data?.ScanSummary['remediation-summary'] || []), [data?.ScanSummary]);

    const exportCSV = useCallback(async () => {
        const res = getHttpService().get<ArrayBuffer>('vulnerability/scan-export-csvs',
            {
                params: {
                    environmentId: environmentId,
                    entityType: type,
                    entityId: id,
                    ...(remediationOnly && { hasRemediation: remediationOnly }),
                },
                responseType: 'arraybuffer'
            }
        );
        getNotificationsService().runPromise({
            promise: res,
            pending: {
                title: 'Exporting Started',
                text: 'Please wait...',
            },
            error: {
                title: 'Error Occurred',
                text: 'Failed export CSV'
            },
            autoClose: 5000,
        });
        try {
            const csvData = await res;
            fileDownload(csvData, 'scan-result-csv.zip');
        } catch (e) {
            // nothing to do here
        }
    }, [environmentId, type, id, remediationOnly]);

    const exportCveAsJSONAndCSV: Array<ITableExportButton> = React.useMemo(() => {
        return [
            {
                label: 'CSV - Download',
                onClick: () => exportCSV()
            },
            {
                label: 'JSON - Download',
                onClick: () => {
                    const fileName = 'scan-data-vulnerabilities.json';
                    const json = JSON.stringify(data, null, 2);
                    const blob = new Blob([json], { type: 'application/json' });
                    const href = URL.createObjectURL(blob);
                    const link = document.createElement('a');

                    link.href = href;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                }
            }
        ];
    }, [data, exportCSV]);

    type CreateScanSource = (scanProducer: string | undefined) => { label: string };
    const createScanSource: CreateScanSource = (scanProducer) => {
        const isInspector = scanProducer === 'InspectorV2';
        return {
            label: isInspector ? 'AWS Inspector' : 'CloudGuard'
        };
    };

    const getSelectedTab = React.useCallback((tab: ITabItemProps) => {
        switch (tab.value) {
            case 'cves': {
                return <Cves setRemediationOnly={setRemediationOnly} exportCveAsJSONAndCSV={exportCveAsJSONAndCSV} cves={flatPackageAndCveMemo} />;
            }
            case 'threats': {
                return <Threats exportCveAsJSONAndCSV={exportCveAsJSONAndCSV} threats={restructureThreatsMemo} />;
            }
            case 'secrets': {
                return <Threats exportCveAsJSONAndCSV={exportCveAsJSONAndCSV} isThreats={false} threats={restructureSecretsMemo} />;
            }
            case 'remediations': {
                return <Remediations exportCveAsJSONAndCSV={exportCveAsJSONAndCSV} remediations={flattenRemediationMemo} />;
            }
            default: {
                return <Cves setRemediationOnly={setRemediationOnly} exportCveAsJSONAndCSV={exportCveAsJSONAndCSV} cves={flatPackageAndCveMemo} />;
            }
        }
    },[exportCveAsJSONAndCSV, flatPackageAndCveMemo, flattenRemediationMemo, restructureSecretsMemo, restructureThreatsMemo]);

    if(isLoading) return <Stack fullHeight><CloudAnimationLoader size={'medium'} /></Stack>;

    return (
        <>
            <Stack direction={'row'} spacing={2}>
                <Tabs selectedTab={'cves'} onTabSelected={setSelectedTab} tabs={TABS} />
                <Stack style={{ backgroundColor: '#F6F7FA', borderRadius: '8px' }} padding={2} spacing={4} direction={'row'}>
                    {data?.scanInfo.scanProducer === 'InspectorV2' ? (
                        <Label
                            text={`Scan Source: ${createScanSource(data?.scanInfo.scanProducer).label}`}
                            trailIconProps={{ vendorNameOrPath: 'aws__Architecture-Service-Icons__Arch_Security-Identity-Compliance__64__Arch_Amazon-Inspector_64', size: 16 }}
                        />
                    ) : (
                        <Label
                            text={`Scan Source: ${createScanSource(data?.scanInfo.scanProducer).label}`}
                            trailIconProps={{ color: 'brandPrimary', name: 'cloudGuard', size: 16 }}
                        />
                    )}
                    {scanMetadata?.scanInfo.scanDate && <Label text={`Scan Date: ${moment(scanMetadata?.scanInfo.scanDate).format('lll')}`}/>}
                </Stack>
            </Stack>
            {getSelectedTab(selectedTab)}
        </>
    );
};

export default Vulnerability;