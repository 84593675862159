import React from 'react';
import { Stack } from 'common/design-system/components-v2';
import { EventOverviewTabProps } from 'common/module_interface/events/EventsDrawerRegistry';
import { getSectionFromRemediation, getServerlessPermissionRemediationSections } from './ServerlessPermissionSection.utils';
import { PermissionSectionTypes } from './ServerlessPermissionSection.types';
import { RemediationItemSection } from './components/RemediationItemSection/RemediationItemSection';
import { GeneralItemSection } from './components/GeneralItemSection/GeneralItemSection';


const ServerlessPermissionSection: React.FC<EventOverviewTabProps> = ({ finding: { remediationActions, bundleName } }) => {
    const sections = getServerlessPermissionRemediationSections(bundleName);
    const remediationSections = getSectionFromRemediation(sections, remediationActions);
    if (remediationSections.length === 0) {
        return null;
    }
    return (
        <Stack spacing={8}>
            {remediationSections.map(([title, type, data]) => {
                switch(type) {
                    case PermissionSectionTypes.remediation:
                        return <RemediationItemSection title={title} data={data} />;
                    default:
                        return <GeneralItemSection title={title} data={data} />;
                }
            })}
        </Stack>
    );
};

export default ServerlessPermissionSection;