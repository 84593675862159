import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const AssetPanelCard = styled(Stack)`
    border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
    border-radius: ${({ theme }) => `${theme.border.radius(4)}${theme.units}`};
`;

const AssetPanelStyled = {
    AssetPanelCard,
};

export default AssetPanelStyled;
