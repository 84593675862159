import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IEnvironment } from 'common/interface/environmentsTable';
import { StatusCellRenderer_Status } from 'common/module_interface/assets/Environments';
import { Vendors } from 'common/consts/vendors';
import { A } from 'common/components/Anchor/Anchor';
import { formatDate } from 'common/utils/helpFunctions';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { Button } from 'common/design-system/components-v2';

interface IWorkloadOnBoardingTimeProps extends ICellRendererParams<IEnvironment> {}

const WorkloadOnBoardingTime = React.forwardRef<any, IWorkloadOnBoardingTimeProps>((params, ref) => {
    const { t } = useTranslation(getK8sNamespace('common'));

    const [creationDate, setCreationDate] = React.useState<string>(params.data?.['creationDate'] || '');
    const [status, setStatus] = React.useState<StatusCellRenderer_Status | undefined>(params.data?.['customData|status']);

    React.useImperativeHandle(ref, () => {
        return {
            refresh(params: IWorkloadOnBoardingTimeProps) {
                if (params.data?.['creationDate']) {
                    setCreationDate(params.data['creationDate']);
                }
                setStatus(params.data?.['customData|status']);
                return true;
            },
        };
    });

    const onboardingUrl = React.useMemo(() => {
        if (!params.data) return;
        const { platform, name, description, organizationalUnitId } = params.data;
        switch (platform) {
            case Vendors.KUBERNETES: {
                let path = `/workload/cloud-add/kubernetes?kubernetesClusterName=${name}`;
                if (description) {
                    path += `&kubernetesDescription=${description}`;
                }
                if (organizationalUnitId) {
                    path += `&organizationalUnitId=${organizationalUnitId}`;
                }
                return path;
            }
            case Vendors.CONTAINER_REGISTRY: {
                let path = `/workload/registry-onboarding?registryName=${name}&registryType=${params.data['customData|workloadPlatformType']}`;
                if (description) {
                    path += `&registryDescription=${description}`;
                }
                if (organizationalUnitId) {
                    path += `&organizationalUnitId=${organizationalUnitId}`;
                }
                return path;
            }
            default:
                break;
        }
    }, [params]);
    
    if (status === StatusCellRenderer_Status.unsecured && onboardingUrl) {

        return (
            <A href={onboardingUrl}>
                <Button variant='contained' color='brandPrimary' size='small'>
                    {t('ENVIRONMENTS_TABLE.CELL_RENDERERS.UNSECURED.CLICK_TO_ONBOARD')}
                </Button>
            </A>
        );
    }
    
    return formatDate(creationDate);
});
WorkloadOnBoardingTime.displayName = 'WorkloadOnBoardingTime';

export default WorkloadOnBoardingTime;