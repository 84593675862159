import { Icon, Stack, Typography } from 'common/design-system/components-v2';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

const EmptyState = () => {
    const theme = useTheme();
    const { t } = useTranslation(getK8sNamespace('image-overview'));

    return (
        <Stack spacing={4} padding={5} alignItems={'center'}>
            <Icon customColor={theme.palette.border.light} size={96} name={'imageProtection'} />
            <Typography color={'light'} variant={'subtitleLg'}>{t('emptyState.title')}</Typography>
        </Stack>
    );
};

export default EmptyState;