import { UserRolesTypes } from 'common/enum/UserRoles';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { IColumnUsageDef } from 'common/interface/general';
import { PATH_PROTECTED_ASSETS_PAGE_TABLE_COLUMNS } from 'common/module_interface/assets/ProtectedAssets.consts';

const serverlessColumnIds = [
    'includesFSP',
    'fspStickyMode',
    'withFSPBlock',
    'whiteListProgressProtego',
    'insertionState',
    'FspVersion',
];


const serverlessColumns = serverlessColumnIds.map((columnId) => {
    return {
        id: columnId,
        colDefOverride: {
            initialHide: true,
        },
        permission: [UserRolesTypes.ALL],
    } as IColumnUsageDef;
});

const awpColumnIds = ['scanStatus', 'scanStatusDetails', 'lastScanDate'];

const awpColumns = awpColumnIds.map((columnId) => {
    return {
        id: columnId,
        colDefOverride: {
            initialHide: true,
        },
        permission: [UserRolesTypes.ALL]
    } as IColumnUsageDef;
});

export default function initializeProtectedAssetPageColumns() {
    globalAddinContainer.addMap(PATH_PROTECTED_ASSETS_PAGE_TABLE_COLUMNS, [...serverlessColumns, ...awpColumns]);
}
