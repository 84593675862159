import React, { useEffect } from 'react';
import ImageCard from 'modules/workloads/components/ImageCard/ImageCard';
import { Stack } from 'common/design-system/components-v2';
import { Image } from 'modules/workloads/services/workload/workload.interface';
import { useImagesByWorkload } from 'modules/workloads/services/workload/hooks/useImagesByWorkload';

interface ImagesOnWorkloadProps {
    onClickSelectImage: (image: Image) => void;
    selectedImage: Image | null;
    dome9Id: string;
}

const ImagesOnWorkload:React.FC<ImagesOnWorkloadProps> = ({ onClickSelectImage, selectedImage, dome9Id }) => {
    const { data = [] } = useImagesByWorkload(dome9Id);

    useEffect(() => {
        if(data?.length > 0){
            onClickSelectImage(data[0]);
        }
    }, [data, onClickSelectImage]);

    return (
        <Stack direction={'row'} padding={[0, 0, 4, 0]} spacing={3}>
            {data?.map((image) =>
                <ImageCard
                    key={image.imageId}
                    {...image}
                    onClickSelectImage={onClickSelectImage}
                    isSelectedImage={selectedImage?.imageId === image.imageId}
                />)}
        </Stack>
    );
};

export default ImagesOnWorkload;