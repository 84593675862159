import { AssetLabel, AssetLabelTypes, CoreOwner, ProtectedAssetIdToUrlConverter } from './PodOwner.types';

export const getProtectedAssetUrlData = (protectedAssetIdToUrlConverter: ProtectedAssetIdToUrlConverter) => {
    const fetchAssetUrlData = async (owner: CoreOwner, cloudAccountId: string) => {
        const assetType = `Kubernetes${owner.kind}`;
        try {
            const response = await protectedAssetIdToUrlConverter.getProtectedAssetById(owner.uid, assetType, cloudAccountId);
            const assetUrl = response ? protectedAssetIdToUrlConverter.getProtectedAssetUrl(response) : null;
            return assetUrl ? { label: owner.kind, link: assetUrl } : null;
        } catch (error) {
            return null;
        }
    };
    return fetchAssetUrlData;
};

export const NULL_ASSET_LABEL: AssetLabel = { type: AssetLabelTypes.NotAvailable };