import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getNotificationsService } from 'common/interface/services';
import { Button } from '@dome9/berries/react-components';
import Content from '../../WizardStep/Content';
import Footer from '../../WizardStep/Footer';
import Container from '../../WizardStep/Container';
import ShiftLeftAccountService from '../../../../../services/shiftLeft/shiftLeftAccount.service';
import { NotificationType } from 'common/interface/notifications';
import { OperatingSystemProps } from './OperatingSystem.types';
import OperatingSystemContent from '../../../components/OperatingSystemContent/OperatingSystemContent';
import { ButtonWithLoader } from 'modules/workloads/pages/shiftLeft/ShiftleftOnboarding/steps/ShiftLeftConfigurations/ShiftLeftConfigurations.styled';
import { Spinner } from 'common/design-system/components-v2';

const OperatingSystem: React.FC<OperatingSystemProps> = ({ onNext, onBack }) => {
    const [isLoading, setISLoading] = useState(false);
    const { handleSubmit, getValues } = useFormContext();
    const { t } = useTranslation(['k8s_shiftleft', 'k8s_common']);

    const onBackDeletedAccount = async () => {
        setISLoading(true);
        const id = getValues('envId');
        try {
            await ShiftLeftAccountService.deleteAccount({ id });
            setISLoading(false);
            onBack && onBack();
        } catch (e) {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: t('k8s_shiftleft:shiftLeftPage.removeModal.delete.failed'),
                text: t('k8s_shiftleft:shiftLeftOnboarding.steps.instructions.errorMessageDeleteEnv', { id: id }),
            });
        }
        setISLoading(false);
    };

    return (
        <Container>
            <Content>
                <OperatingSystemContent />
            </Content>
            <Footer>
                <ButtonWithLoader disabled={isLoading} onClick={onBackDeletedAccount}>
                    <>{isLoading && <span><Spinner/></span>}</>
                    <span>{t('k8s_common:BACK')}</span>
                </ButtonWithLoader>
                <Button color={'primary'} disabled={isLoading} onClick={handleSubmit(onNext)}>{t('k8s_common:NEXT')}</Button>
            </Footer>
        </Container>
    );
};

export default OperatingSystem;