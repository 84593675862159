import { initializeAssetDetailsTabs } from './protectedAsset/page/initialize.assetDetails.tabs';
import { initializeAssetDetailsRoutes } from './protectedAsset/page/initialize.assetDetails.routes';
import { initializeAssetDetailsOverview } from './protectedAsset/page/initialize.assetDetails.overview';
import { initializeInfoPaneFieldProps } from './protectedAsset/page/initialize.assetDetails.infoPaneFieldProps';

export const initializeK8SProtectedAssetDetails = () => {
    initializeAssetDetailsTabs();
    initializeAssetDetailsRoutes();
    initializeAssetDetailsOverview();
    initializeInfoPaneFieldProps();
};