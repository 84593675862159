import { LevelIcon, Stack, Typography } from 'common/design-system/components-v2';
import React from 'react';
import CveTable from '../CveTable';
import SearchCveTableStyles from './SearchCveTable.styled';
import CveSearchFilterPanel from '../CveSearchFilterPanel/CveSearchFilterPanel';
import useSearchCveId from '../hooks/useSearchCveId';
import useReactRouterQuery from 'common/hooks/useReactRouterQuery';
import ErrorState from '../components/ErrorState/ErrorState';

const SearchCveTable: React.FunctionComponent = () => {
    const { isLoading, rowData, setFilters, onExportCsv, setUrlCve, error } = useSearchCveId();
    const { params: { cve: urlCve } } = useReactRouterQuery();
    const trimmedUrlCve = React.useMemo(() => urlCve.trim(), [urlCve]);

    React.useEffect(() => {
        setUrlCve(trimmedUrlCve);
    }, [trimmedUrlCve, setUrlCve]);

    return (
        <Stack fullWidth fullHeight spacing={4}>
            <SearchCveTableStyles.Header direction='row' padding={[3, 4]} alignItems='center' spacing={2}>
                <LevelIcon category='severity' level='unknown' iconProps={{ name: 'cve' }} />
                <Typography variant='subtitleLg'>{urlCve}</Typography>
            </SearchCveTableStyles.Header>
            <CveSearchFilterPanel
                cveId={trimmedUrlCve}
                onFilterChange={setFilters}
            />
            {error ? <ErrorState /> : <CveTable cveId={urlCve} rowData={rowData} isLoading={isLoading} onExport={onExportCsv} />}
        </Stack>
    );
};

export default SearchCveTable;
