export enum AwpUpdateType {
    TERRAFORM = 'TERRAFORM',
    CLOUDFORMATION_STACK = 'CLOUDFORMATION_STACK',
    CLOUDFORMATION_STACK_OLD = 'CLOUDFORMATION_STACK_OLD',
    NONE = 'NONE',
}

export enum AwpUpdateStateType {
    LOADING = 'LOADING',
    ERROR = 'ERROR',
    CLOUDFORMATION_STACK_UPDATE = 'CLOUDFORMATION_STACK_UPDATE',
    CLOUDFORMATION_STACK_UPDATE_OLD = 'CLOUDFORMATION_STACK_UPDATE_OLD',
    TERRAFORM_UPDATE = 'TERRAFORM_UPDATE',
    NONE = 'NONE',
}

export type AwpUpdateState =
    { type: AwpUpdateStateType.LOADING } |
    { type: AwpUpdateStateType.ERROR, error: Error } |
    { type: AwpUpdateStateType.CLOUDFORMATION_STACK_UPDATE, data: AwpStackUpdateProps } |
    { type: AwpUpdateStateType.CLOUDFORMATION_STACK_UPDATE_OLD, data: AwpStackUpdateProps } |
    { type: AwpUpdateStateType.TERRAFORM_UPDATE, data: AwpTerraformUpdateProps } |
    { type: AwpUpdateStateType.NONE }

export type AwpStackUpdateProps = {
    url: string,
    cloudAccountId: string,
}

export type AwpTerraformUpdateProps = {
    cloudAccountId: string,
    version: string,
}