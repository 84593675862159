import React from 'react';
import { EventsModalOpenFrom, EventsModalProps } from 'common/module_interface/events/EventsModalsRegistry';
import { useTranslation, Trans } from 'react-i18next';
import { Command, GroupSelection, Message, Typography } from 'common/design-system/components-v2';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { Controller, useForm } from 'react-hook-form';
import { useWorkloadRPAccountRules } from 'modules/workloads/services/workload/hooks/useWorkloadRPAccountRules';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { showRPPolicyUpdatedNotification } from 'modules/workloads/Modals/RPPolicyUpdatedNotification';

interface FormValues {
    applyTo: 'thisPodGroupOnly' | 'allPodsInCluster';
}

interface EcxeptionPayloadJson {
    Payloads: Array<string>;
    Name: string;
    PayloadsType: string;
    Target: string;
}

const AddDenyRuleAction: React.FC<EventsModalProps> = ({ closeModal, selectedRows, openFrom, resetSelectedRows }) => {
    const { t } = useTranslation(getK8sNamespace('events'));

    const onSuccess = () => {
        getNotificationsService().addNotification({
            text: t('MODALS.ADD_DENY_RULE.NOTIFICATIONS.SUCCESS'),
            type: NotificationType.SUCCESS
        });
        if (openFrom === EventsModalOpenFrom.TABLE && resetSelectedRows) {
            resetSelectedRows();
        }
        showRPPolicyUpdatedNotification();
        closeModal();
    };

    const onError = ({ message }: { message: string; }) => {
        if (message.includes('409')) {
            getNotificationsService().addNotification({
                type: NotificationType.WARNING,
                title: t('MODALS.ADD_DENY_RULE.NOTIFICATIONS.ALREADY_EXISTS'),
            });
        } else {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: t('MODALS.ADD_DENY_RULE.NOTIFICATIONS.ERROR'),
                text: message,
            });
        }
        showRPPolicyUpdatedNotification();
        closeModal();
    };

    const { isLoading, updateRules } = useWorkloadRPAccountRules({ onSuccess, onError });

    const {
        handleSubmit,
        control,
    } = useForm<FormValues>({
        defaultValues: {
            applyTo: 'thisPodGroupOnly'
        }
    });

    const ecxeptionPayloadJson = React.useMemo<EcxeptionPayloadJson | undefined>(() => {
        try {
            const signaturePayload = selectedRows[0].additionalFields.find((field: any) => field.name === 'ServerlessExceptionPayloadsSignatureName')?.value;
            if (!signaturePayload) return undefined;
            return JSON.parse(atob(signaturePayload));
        } catch (error) {
            return undefined;
        }
    }, [selectedRows]);

    const submitUpdate = async (data: FormValues) => {
        if (!ecxeptionPayloadJson) return;
        const { applyTo } = data;
        
        updateRules(selectedRows[0].cloudAccountType, selectedRows[0].cloudAccountId, {
            payloadsPatternType: ecxeptionPayloadJson.PayloadsType,
            name: ecxeptionPayloadJson.Name,
            payloads: ecxeptionPayloadJson.Payloads.map(pattern => ({ 'pattern': pattern, 'callStackInfo': '' })),
            target: ecxeptionPayloadJson.Target,
            workloadExternalIds: applyTo === 'allPodsInCluster' ? [] : selectedRows.map(row => row.entityExternalId),
        });
    };


    return (
        <Message
            title={t('MODALS.ADD_DENY_RULE.TITLE')}
            submitBtnText={t('MODALS.ADD_DENY_RULE.BUTTONS.SUBMIT')}
            width='lg'
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={handleSubmit((data) => submitUpdate(data))}
            isLoading={isLoading}
        >
            <Typography>
                <Trans
                    components={{ boldText: <Typography elementType='span' style={{ fontWeight: 'bold' }}>.</Typography> }}
                    i18nKey={'MODALS.ADD_DENY_RULE.TEXT'}
                    ns={getK8sNamespace('events')}
                />
            </Typography>
            {ecxeptionPayloadJson && <Command text={ecxeptionPayloadJson.Payloads[0]} />}
            <Controller
                name='applyTo'
                control={control}
                render={({ field }) => (
                    <GroupSelection
                        label={t('MODALS.ADD_DENY_RULE.INPUTS.APPLY_TO.LABEL')}
                        options={[
                            { label: t('MODALS.ADD_DENY_RULE.INPUTS.APPLY_TO.OPTIONS.THIS_POD_GROUP_ONLY'), value: 'thisPodGroupOnly' },
                            { label: t('MODALS.ADD_DENY_RULE.INPUTS.APPLY_TO.OPTIONS.ALL_PODS_IN_THIS_CLUSTER'), value: 'allPodsInCluster' },
                        ]}
                        {...field}
                    />
                )}
            />
        </Message>
    );
};

export default AddDenyRuleAction;
