import { Icon } from '@dome9/berries/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KOBInstructionsWrapper, Text, TextBold, LinkButton, KOBInstructionsActionButton, ButtonsWrapper, Section } from './KOBInstructions.styles';

interface KOBInstructionsProps {
    onSubmitClick: () => void;
    onCancelClick: () => void;
    noneHelmCommandClick: () => void;
}

const KOBInstructions: React.FunctionComponent<KOBInstructionsProps> = ({ onSubmitClick, onCancelClick, noneHelmCommandClick }) => {
    const { t } = useTranslation('k8s_cluster');
    return (
        <KOBInstructionsWrapper>
            <Section>
                <Text>{t('ON_BOARDING.PAGE.INSTRUCTIONS.INFO')}</Text>
                <LinkButton href={'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Assets/Kubernetes.htm'} target='_blank' rel="noreferrer">{t('ON_BOARDING.PAGE.INSTRUCTIONS.CLICK_TO_READ_MORE')}</LinkButton>
            </Section>
            <Section gap={4}>
                <TextBold>{t('ON_BOARDING.PAGE.INSTRUCTIONS.FURTHER_READING')}</TextBold>
                <LinkButton href={'https://helm.sh/docs/intro/install'} target='_blank' rel="noreferrer">{t('ON_BOARDING.PAGE.INSTRUCTIONS.HELM3_DOCUMENTATION')}</LinkButton>
                <LinkButton onClick={noneHelmCommandClick}>{t('ON_BOARDING.PAGE.INSTRUCTIONS.CLICK_FOR_NON_HELM')}</LinkButton>
            </Section>
            <ButtonsWrapper>
                <KOBInstructionsActionButton onClick={onSubmitClick} color='green' variant='contained'>
                    <Icon name={'check'} />
                    {t('ON_BOARDING.PAGE.INSTRUCTIONS.I_RAN_THE_HELM_COMMAND')}
                </KOBInstructionsActionButton>
                <KOBInstructionsActionButton onClick={onCancelClick} color='red' variant='outlined'>
                    <Icon name={'remove'} />
                    {t('ON_BOARDING.PAGE.INSTRUCTIONS.CANCEL_ONBOARDING')}
                </KOBInstructionsActionButton>
            </ButtonsWrapper>
        </KOBInstructionsWrapper>
    );
};

export default KOBInstructions;
