import React from 'react';
import { Card, Icon, Tooltip, SwitchButton } from '@dome9/berries/react-components';
import { CardMediaWrapper, CardContentWrapper, CardMediaDescription, SubCardsWrapper, SubCardsCheckboxWrapper } from './FeatureCard.styles';
import { KOBFeature, SelectedFeatureState } from '../../KubernetesOnBoarding.types';
import { Media_ } from '../../KubernetesOnBoarding.styles';
import { InputsListTooltipWrapper } from '../InputsList/InputsList.styles';
import { Checkbox } from 'common/design-system/components-v2';

interface FeatureCardProps {
    feature: KOBFeature;
    selectedFeatures: SelectedFeatureState;
    handleOnFeatureChange: (featureName: string, newStatus: boolean) => void;
}

const FeatureCard: React.FunctionComponent<FeatureCardProps> = props => {
    const { feature, selectedFeatures, handleOnFeatureChange } = props;

    return (
        <Card
            size='md'
            status={selectedFeatures[feature.name].active ? 'success' : 'inactive'}
            header={
                <CardMediaWrapper disabled={selectedFeatures[feature.name].disabled}>
                    <Media_
                        size='xs'
                        title={feature.title}
                        icon={feature.icon}
                    />
                    <SwitchButton
                        disabled={feature.viewOnly || false}
                        checked={selectedFeatures[feature.name].active}
                        onChange={value => handleOnFeatureChange(feature.name, value)}
                    />
                </CardMediaWrapper>
            }
        >
            <CardContentWrapper>
                <CardMediaDescription>
                    <span>{feature.description}</span>{' '}
                    {feature.link && ( <a href={feature.link} target="_blank" rel="noreferrer">Read more</a> )}
                </CardMediaDescription>
                {feature.subFeatures && (
                    <SubCardsWrapper>
                        {feature.subFeatures.map((subFeature, subFeatureIndex) => (
                            <SubCardsCheckboxWrapper
                                key={`sub-feature-${subFeature.name}-${subFeatureIndex}`}
                                disabled={selectedFeatures[subFeature.name].disabled}
                            >
                                <Checkbox
                                    label={subFeature.title}
                                    checked={selectedFeatures[subFeature.name].active}
                                    onChange={(e: any) => handleOnFeatureChange(subFeature.name, e.target.checked)}
                                />
                                <Tooltip
                                    content={subFeature.description}
                                    placement='bottom'
                                >
                                    <InputsListTooltipWrapper>
                                        <Icon name='info' size={12} />
                                    </InputsListTooltipWrapper>
                                </Tooltip>
                            </SubCardsCheckboxWrapper>
                        ))}
                    </SubCardsWrapper>
                )}
            </CardContentWrapper>
        </Card>
    );
};

export default FeatureCard;
