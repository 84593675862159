import { Stack, Table, Typography } from 'common/design-system/components-v2';
import React, { useCallback, useState } from 'react';
import { ColDef, GridApi, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { useSbom } from 'modules/workloads/services/sbom/hooks/useSbom';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import FilterPanelSBOM from './FilterPanelSBOM/FilterPanelSBOM';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import ScanResultEmptyState from 'modules/workloads/protectedAsset/components/ScanResultEmptyState';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { isScanEngineVersion2 } from '../utils';
import { useExportSbom } from 'modules/workloads/services/sbom/hooks/useExportSbom';
import { SbomPackagesFlat } from 'modules/workloads/services/sbom/sbom.interface';
import { Dot } from 'modules/workloads/components/Dot/Dot';
import LicenseCellRenderer from 'modules/workloads/CellRenders/LicenseCellRenderer/LicenseCellRenderer';
import { ICellRendererParams } from 'ag-grid-enterprise';

let objectFilter: IFiltersValues = {};

interface SbomTableWithFilterProps {
    entityName: string,
    entityId: string,
    entityType: string,
    environmentId: string,
    cloudEntityData: ICloudEntityData
}

const SbomTableWithFilter: React.FC<SbomTableWithFilterProps> = ({ entityName, entityId, entityType, environmentId, cloudEntityData }) => {
    const [itemDisplayedRowCount, setItemDisplayedRowCount] = useState<number>(0);
    const gridRef = React.useRef<GridApi>();
    const { data, isLoading, error } = useSbom({ entityId, entityType, environmentId });
    const { isExporting, sbomFormats, exportSbom } = useExportSbom({
        entityName,
        entityId,
        entityType,
        environmentId,
        isScanEngineVersion2: isScanEngineVersion2(data?.scanInfo),
    });
    const { t } = useTranslation(getK8sNamespace('sbom'));

    const getColumnDefs: ColDef<SbomPackagesFlat>[] = [
        {
            field: 'name',
            headerName: t('sbomTable.columns.packageName'),
            flex: 1,
            enableRowGroup: true,
            sortable: true,
            sortingOrder: ['desc', 'asc'],
        },
        {
            field: 'version',
            headerName: t('sbomTable.columns.packageVersion'),
            flex: 1,
            enableRowGroup: true,
            sortable: false,
        },
        {
            field: 'packageManagerName',
            headerName: t('sbomTable.columns.packageManager'),
            flex: 1,
            enableRowGroup: true,
            sortable: true,
        },
        {
            field: 'licenses',
            headerName: t('sbomTable.columns.licenses'),
            flex: 1,
            enableRowGroup: false,
            sortable: false,
            cellRenderer: (params: ICellRendererParams<SbomPackagesFlat['licenses'], SbomPackagesFlat['licenses']>) => <LicenseCellRenderer {...params} />
        },
        {
            field: 'packageManagerPath',
            headerName: t('sbomTable.columns.path'),
            flex: 2,
            enableRowGroup: true,
            sortable: true,
        },
        {
            field: 'id',
            headerName: t('sbomTable.columns.id'),
            flex: 1,
            sortable: true,
            hide: true
        },
        {
            field: 'is-os-package',
            headerName: t('sbomTable.columns.isOSPackage'),
            flex: 1,
            enableRowGroup: true,
            cellRenderer: ({ value }:{ value : boolean | string }) => {
                const isOsPackage = typeof value === 'string' ? value === 'true' : value;
                return (isOsPackage === undefined || isOsPackage === null) ? 'N/A' : <Dot isTrue={isOsPackage}/>;
            },
            sortable: true,
            hide: true
        }
    ];

    const onGridReady = useCallback(
        (event: GridReadyEvent) => {
            gridRef.current = event.api;
        },
        [],
    );

    const isFilterValuesEmpty = (filtersValues?: IFiltersValues) => {
        return filtersValues && Object.values(filtersValues).every(value => {
            if (typeof value === 'string') {
                return value.trim() === '';
            } else if (Array.isArray(value)) {
                return value.every(item => typeof item === 'string' && item.trim() === '');
            }
            return false;
        });
    };

    const externalFilterChanged = useCallback((newValue: IFiltersValues) => {
        objectFilter = newValue;
        gridRef.current?.onFilterChanged();
        setItemDisplayedRowCount(gridRef.current?.getDisplayedRowCount() || 0);
    }, []);

    const isExternalFilterPresent = useCallback(() => {
        return objectFilter && !isFilterValuesEmpty(objectFilter);
    }, []);

    const doesExternalFilterPass = useCallback(
        (node: IRowNode) => {
            let doesExternalFilterPassValue = true;
            if (node.data) {
                Object.keys(objectFilter).forEach((key) => {
                    if(!objectFilter[key] || objectFilter[key].length === 0) return;
                    if(key !== 'free-text') {
                        const values: Array<string> = objectFilter[key];
                        if(typeof node.data[key] === 'boolean') {
                            const doesFilterPassValues = values.some(value => node.data[key].toString() === value);
                            doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassValues;
                        } else {
                            const doesFilterPassValues = values.some(value => node.data[key] === value);
                            doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassValues;
                        }
                    } else {
                        const doesFilterPassFreeText = node.data.name.toLowerCase().includes(objectFilter[key].toLowerCase());
                        doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassFreeText;
                    }
                });
            }
            return doesExternalFilterPassValue;
        },
        []
    );

    if(isLoading) return <Stack fullHeight><CloudAnimationLoader size={'medium'}/></Stack>;

    if(error){
        return <ScanResultEmptyState
            entity={cloudEntityData}
        />;
    }

    return (
        <>
            <FilterPanelSBOM data={data?.sbomPackages || []} onFilterChange={externalFilterChanged} />
            <Table
                gridOptions={{
                    rowData: data?.sbomPackages.sort((a, b) => a.name > b.name ? 1 : (a.name === b.name ? 0 : -1)) || [],
                    columnDefs: getColumnDefs,
                    onGridReady,
                    isExternalFilterPresent: isExternalFilterPresent,
                    doesExternalFilterPass: doesExternalFilterPass
                }}
                exportButtons={sbomFormats.map(({ name, id }) => ({
                    label: t('sbomExport.exportOptionText', { format: name }),
                    disabled: isExporting,
                    onClick: () => exportSbom(id),
                }))}
                footer={<Typography>{t('sbomTable.footer', { itemDisplayedRowCount: itemDisplayedRowCount, totalRowCount: data?.sbomPackages.length || 0 })}</Typography>}
            />
        </>
    );
};

export default SbomTableWithFilter;