/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouterQuery from 'common/hooks/useReactRouterQuery';
import Wizard, { RenderViewParams } from 'common/components/Wizard/Wizard';
import { getRulesetService } from 'common/interface/services';
import { IPolicy, IShallowRuleset, IVendorRuleset } from 'common/interface/policy';
import { EnvironmentsSelectionComponent, resolveDisplayName } from 'common/components/policy/EnvironmentsSelectionComponent';
import { Vendors } from 'common/consts/vendors';
import { RulesetMultiSelectionComponent } from 'common/components/policy/RulesetMultiSelectionComponent';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { getRulesets } from '../../initialize';
import { WorkloadPlatformSelectionComponent } from './WorkloadPlatformSelectionComponent';
import { CardItem } from 'common/components/policy/Card';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { Done } from 'common/components/policy/Done/Done';
import { getCloudAccountsService } from 'common/interface/data_services';
import { CloudAccount } from 'common/interface/environments';
import {
    finishFlow,
    getPolicyRulesets,
    saveUpdatePolicies,
    SHIFTLEFT_RELATED_PLATFORMS
} from 'common/components/policy/utils';
import { useReturnUrl } from 'common/hooks/useReturnUrl';
import { ImageAdmissionSelectionComponent } from '../../components/ImageAdmissionSelectionComponent/ImageAdmissionSelectionComponent';
import { ImageAdmissionItem } from '../../helpers/interface';

const STEPS_IDS = {
    PLATFORM: 'platform',
    ENVIRONMENT: 'environment',
    RULESET: 'ruleset',
    IMAGE_ADMISSION: 'image_admission',
    NOTIFICATION: 'notification',
    DONE: 'done'
};

export const AddEnvsPolicyPage: React.FC = () => {
    const { params } = useReactRouterQuery();
    const { t } = useTranslation('k8s_policy');
    const [selectedPlatform,setSelectedPlatform] = useState<Vendors>(Vendors.GENERIC);
    const [selectedEnvironments,setSelectedEnvironments] = useState<ListItem[]>([]);
    const [selectedNonCompliantAdmission, setSelectedNonCompliantAdmission] = React.useState<ImageAdmissionItem>();
    const [selectedUnScannedAdmission, setSelectedUnScannedAdmission] = React.useState<ImageAdmissionItem>();
    const [selectedRulesets,setSelectedRulesets] = useState<ListItem[]>();
    const [selectedNotifications,setSelectedNotifications] = useState<ListItem[]>();
    const [flowStatusMessage,setFlowStatusMessage] = useState<string>();
    const [flowStatusOK,setFlowStatusOK] = useState<boolean>();
    const returnUrl = useReturnUrl();
    const [stepsToDisplay, setStepsToDisplay] = useState({
        [STEPS_IDS.PLATFORM]: true,
        [STEPS_IDS.ENVIRONMENT]: true,
        [STEPS_IDS.RULESET]: true,
        [STEPS_IDS.IMAGE_ADMISSION]: false,
        [STEPS_IDS.NOTIFICATION]: true,
        [STEPS_IDS.DONE]: true
    });
    const selectedPlatformChanged = (selectedItem: CardItem) => {
        selectedItem && setSelectedPlatform(selectedItem.id);
        setSelectedEnvironments([]);
        setSelectedRulesets([]);
        setSelectedNotifications([]);
        setStepsToDisplay(prev => ({ ...prev, [STEPS_IDS.IMAGE_ADMISSION]: selectedItem.id === Vendors.KUBERNETES }));
    };

    const selectedNotificationsChanged = (selectedItems: ListItem[]) => {
        setSelectedNotifications(selectedItems);
    };

    const selectedEnvironmentsChanged = (selectedItems: ListItem[]) => {
        setSelectedEnvironments(selectedItems);
    };
    useEffect(() => {
        const setPredefinedFields = async () => {
            const allEnvironments = await getCloudAccountsService().getAllCloudAccounts();
            const rulesetId = params?.rulesetId;
            const platformId = params?.platformId;
            rulesetId && handleRulesetPredefined(rulesetId);
            platformId && handlePlatformPredefined(platformId);
            if (allEnvironments.length === 1) {
                handleEnvironmentPredefined(allEnvironments[0]);
            }
        };
        setPredefinedFields();
    }, []);

    const handleRulesetPredefined = async (rulesetId: string) => {
        const allRulesets = await getRulesetService().getAllRulesets();
        const ruleset = allRulesets.find(ruleset => ruleset.id === +rulesetId);
        if (ruleset) {
            const rulesetItem: ListItem = {
                id: ruleset.id.toString(),
                name: ruleset.name
            };
            setSelectedRulesets([rulesetItem]);
            setStepsToDisplay(prev => ({ ...prev, [STEPS_IDS.RULESET]: false }));
        }
    };

    const handlePlatformPredefined = (platformId: string) =>{
        setSelectedPlatform(platformId as Vendors);
        setStepsToDisplay(prev => ({ ...prev, [STEPS_IDS.PLATFORM]: false, [STEPS_IDS.IMAGE_ADMISSION]: platformId === Vendors.KUBERNETES }));
    };

    const handleEnvironmentPredefined = (environment: CloudAccount) => {
        setSelectedEnvironments(
            [{
                id: environment.id,
                name: resolveDisplayName(environment),
                icon: selectedPlatform === Vendors.CONTAINER_REGISTRY ? 'containers-registry' : selectedPlatform,
            }]
        );
        setStepsToDisplay(prev => ({ ...prev, [STEPS_IDS.ENVIRONMENT]: false }));
    };

    const rulesetProvider:IVendorRuleset = {
        getRulesets: async () => {
            const allRulesets = await getPolicyRulesets();
            return allRulesets.filter((ruleset) => ruleset.platform === Vendors.IMAGE_ASSURANCE);
        },
        vendorId: Vendors.IMAGE_ASSURANCE
    };

    const steps = [
        {
            id: STEPS_IDS.PLATFORM,
            name: t('GENERAL.PLATFORM_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <WorkloadPlatformSelectionComponent selectedPlatformId={selectedPlatform} {...renderViewParams} selectedItemChanged={selectedPlatformChanged} />;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            id: STEPS_IDS.ENVIRONMENT,
            name: t('GENERAL.ENVIRONMENTS_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <EnvironmentsSelectionComponent preSelectedItems={selectedEnvironments} {...renderViewParams } platform={selectedPlatform} selectedEnvironmentsChanged={selectedEnvironmentsChanged} />;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            id: STEPS_IDS.RULESET,
            name: t('GENERAL.RULESET_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <RulesetMultiSelectionComponent vendorRulesets={[rulesetProvider]} preSelectedItems={selectedRulesets} {...renderViewParams } selectedRulesetsChanged={setSelectedRulesets}/>;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            id: STEPS_IDS.IMAGE_ADMISSION,
            name: t('GENERAL.IMAGE_ADMISSION_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return (
                    <ImageAdmissionSelectionComponent
                        {...renderViewParams}
                        preSelectedNonCompliant={selectedNonCompliantAdmission} 
                        preSelectedUnScanned={selectedUnScannedAdmission}
                        selectedNonCompliantItemChanged={setSelectedNonCompliantAdmission}
                        selectedUnScannedChanged={setSelectedUnScannedAdmission}
                    />
                );
            },
            onNext: async () => {
                return;
            },
        },
        {
            id: STEPS_IDS.NOTIFICATION,
            name: t('GENERAL.NOTIFICATION_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <NotificationsSelectionComponent preSelectedItems={selectedNotifications} {...renderViewParams } selectedNotificationsChanged={selectedNotificationsChanged}/>;
            },
            onNext: async ()=>{
                saveChanges();
            },
        },{
            id: STEPS_IDS.DONE,
            name: t('GENERAL.DONE'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <Done message={flowStatusMessage} {...renderViewParams} hasError={!flowStatusOK}></Done>;
            },
            onNext: async ()=>{
                finishFlow(returnUrl);
            },
        }
    ];

    const saveChanges = async () => {
        if (selectedRulesets?.length && selectedNotifications?.length) {
            const allRulesets = await getRulesets();
            const policies:IPolicy[] = [];
            for (const ruleset of selectedRulesets) {
                for (const environment of selectedEnvironments) {
                    const _ruleset = allRulesets.find((_ruleset:IShallowRuleset) => _ruleset.id === ruleset.id);
                    const rulesetId = +ruleset.id;
                    const newPolicy: IPolicy = {
                        targetId: environment.id,
                        rulesetId: rulesetId,
                        rulesetPlatform: _ruleset?.platform,
                        notificationIds: selectedNotifications.map((notification:ListItem) => notification.id),
                        targetType: SHIFTLEFT_RELATED_PLATFORMS.includes(selectedPlatform) ? 'Shiftleft' : selectedPlatform || '',
                        admissionControllerAction: selectedNonCompliantAdmission?.type,
                        admissionControlUnScannedAction: selectedUnScannedAdmission?.type,
                    };
                    if(rulesetId < 0){
                        newPolicy.rulesetVersion = ruleset.selectedVersion?.version;
                    }
                    policies.push(newPolicy);
                }
            }

            if(policies?.length){
                setFlowStatusMessage(t('GENERAL.PROCESSING'));
                const result = await saveUpdatePolicies(policies);
                if(result) {
                    setFlowStatusOK(true);
                    setFlowStatusMessage(t('GENERAL.POLICY_ADDED_MESSAGE'));
                }else {
                    setFlowStatusOK(false);
                    setFlowStatusMessage(t('GENERAL.ERROR_OCCURRED'));
                }
            }
        }
    };

    return (
        <div>
            {steps?.length ? <Wizard
                onClose={()=>finishFlow(returnUrl)}
                steps={steps.filter(step => stepsToDisplay[step.id])}
                title={t('GENERAL.ADD_POLICY')}
                doneButtonText={t('GENERAL.DONE')}
                ShowBackButtonInLastStep={false}
            /> : null}
        </div>
    );
};
