import React from 'react';
import { EventOverviewTabProps } from 'common/module_interface/events/EventsDrawerRegistry';
import { Table, Typography, Stack, CopiableLine } from 'common/design-system/components-v2';
import { TableWrapper } from './MalwareFiles.styled';
import { ICellRendererParams } from 'ag-grid-community';
import { EventCategory } from 'common/components/Findings/Findings.interface';

const MalwareFiles: React.FC<EventOverviewTabProps> = ({ finding: event }) => {
    const { entityObject: { files } } = event;

    const rebuildFiles = files.map((file) => ({
        'file-path': file['file-path'],
        lines: file.contents.length > 0 && file.contents[0]?.lines ? file.contents[0].lines.join(', ') : '',
        payload: file.contents.length > 0 && file.contents[0]?.payload ? file.contents[0].payload : '',
    }));

    return (
        <Stack spacing={1}>
            <Typography variant='body500'>Files</Typography>
            <TableWrapper>
                <Table
                    disableGrouping
                    disableColumnMenu
                    gridOptions={{
                        rowData: rebuildFiles,
                        columnDefs: [
                            { headerName: 'File path', flex: 1, field: 'file-path', cellRenderer: (params: ICellRendererParams) => <CopiableLine value={params.value}>{params.value}</CopiableLine> },
                            { headerName: 'Lines', flex: 1, maxWidth: 200, hide: event.category === EventCategory.MaliciousFile, field: 'lines', cellRenderer: (params: ICellRendererParams) => <CopiableLine value={params.value}>{params.value}</CopiableLine> },
                            { headerName: 'Payload', flex: 1, hide: event.category === EventCategory.MaliciousFile, field: 'payload', cellRenderer: (params: ICellRendererParams) => <CopiableLine value={params.value}>{params.value}</CopiableLine> }
                        ]
                    }}
                />
            </TableWrapper>
        </Stack>
    );
};

export default MalwareFiles;