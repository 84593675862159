import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { IEnvironment } from 'common/interface/environmentsTable';
import { A } from 'common/components/Anchor/Anchor';
import { Button, Icon, Spinner, Stack, Tooltip } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { KubernetesAccountFeatureSummary, KubernetesAccountFeatureSummaryName, WorkloadEnvironmentStatus } from 'modules/workloads/services/workload/workload.interface';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { ColorsWithState } from 'common/design-system/theme/theme';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

interface IWorkloadFeatureStatusRendererProps extends ICellRendererParams<IEnvironment> {
    type: KubernetesAccountFeatureSummaryName;
    name: string;
    disabledLink: (data: IEnvironment) => string;
}
  
const WorkloadFeatureStatusRenderer = React.forwardRef<any, IWorkloadFeatureStatusRendererProps>((props, ref) => {
    const { t } = useTranslation(getK8sNamespace('common'));
    const [status, setStatus] = React.useState<KubernetesAccountFeatureSummary>();
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [isHovered, setIsHovered] = React.useState<boolean>(false);
    const hoverDebounceTimeout = React.useRef<NodeJS.Timeout>();

    const setHovered = React.useCallback((action: 'mouseEnter' | 'mouseLeave') => {
        clearTimeout(hoverDebounceTimeout.current);
        hoverDebounceTimeout.current = setTimeout(() => {
            setIsHovered(action === 'mouseEnter');
        }, 100);
    }, []);

    const setValues = React.useCallback((data?: IEnvironment) => {
        const loading = data?.['customData|workloadEnvironmentLoading'] || false;
        const allFeatureValues = data?.['customData|kubernetesFeatures'] as KubernetesAccountFeatureSummary[] || [];
        const relevantValue = allFeatureValues.find((value) => value.name === props.type);
        setStatus(relevantValue);
        setIsLoading(loading);
    }, [props.type]);

    React.useImperativeHandle(ref, () => {
        return {
            refresh(params: IWorkloadFeatureStatusRendererProps) {
                setValues(params.data);
            },
        };
    });

    React.useEffect(() => {
        setValues(props.data);
    }, [props.data, setValues]);

    const content = React.useMemo<{ icon: AllIconsName; iconColor: ColorsWithState; tooltip: string; } | undefined>(() => {
        if (!status) return undefined;
        const defaultDescriptionKubernetesBlade = t('ENVIRONMENTS_TABLE.CELL_RENDERERS.FEATURE_STATUS.DEFAULT_K8S_DESCRIPTION_BLADE');
        switch (status.status) {
            case WorkloadEnvironmentStatus.ERROR:
                return {
                    icon: 'error',
                    iconColor: 'alert',
                    tooltip: status.statusDescription || defaultDescriptionKubernetesBlade,
                };
            case WorkloadEnvironmentStatus.PENDING:
            case WorkloadEnvironmentStatus.WARNING:
                return {
                    icon: 'warning',
                    iconColor: 'warning',
                    tooltip: status.statusDescription || defaultDescriptionKubernetesBlade,
                };
            case WorkloadEnvironmentStatus.OK:
                return {
                    icon: 'checkCircle',
                    iconColor: 'success',
                    tooltip: 'OK',
                };
            default:
                return undefined;
        }
    }, [status, t]);

    if (isLoading) {
        return (
            <Stack justifyContent='center' alignItems='flex-start' fullHeight fullWidth>
                <Spinner size={16} />
            </Stack>
        );
    }

    if (!status || !props.data) {
        return null;
    }

    if (!content) {
        return (
            <Stack fullHeight fullWidth justifyContent='center' onMouseEnter={() => setHovered('mouseEnter')} onMouseLeave={() => setHovered('mouseLeave')}>
                <A href={props.disabledLink(props.data)}>
                    <Stack fullHeight fullWidth justifyContent='center'>
                        <Button
                            variant={isHovered ? 'contained' : 'text'}
                            iconProps={{ name: isHovered ? 'checkCircle' : 'disable' }}
                            color='normal'
                            label={isHovered ? t('ENVIRONMENTS_TABLE.CELL_RENDERERS.FEATURE_STATUS.ENABLE') : ''}
                        />
                    </Stack>
                </A>
            </Stack>
        );
    }

    return (
        <Stack fullHeight fullWidth justifyContent='center' padding={[2,4]}>
            <Tooltip content={content.tooltip}>
                <Icon name={content.icon} color={content.iconColor} />
            </Tooltip>
        </Stack>
    );
});

WorkloadFeatureStatusRenderer.displayName = 'WorkloadFeatureStatusRenderer';


export default WorkloadFeatureStatusRenderer;