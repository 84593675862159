import styled from 'styled-components';
import { Stack, Button } from 'common/design-system/components-v2';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const TopRow_button = styled(Button)`
    &:not(:last-child) {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(50%, -50%);
            height: 22px;
            width: 1px;
            background-color: ${({ theme }) => theme.palette.border.light};
        }
    }
`;

const Rows = styled.div`
    background-color: #ffffff;
    border: 1px solid #e0e4e8;
`;

const Row = styled(Stack)`
    padding: 10px 15px;

    &:not(:last-child) {
        border-bottom: 1px solid #e0e4e8;
    }

    .blade-content {
        padding: 10px;
        background-color: #eee;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
`;

const Table = styled.div`
    min-height: 100px;
    width: 100%;
    border: 1px solid #e0e4e8;
`;

const BladeStyles = {
    Wrapper,
    TopRow_button,
    Rows,
    Row,
    Table,
};

export default BladeStyles;