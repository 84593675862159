import React from 'react';
import { useTranslation } from 'react-i18next';
import { CpButton, CpCommonButton, CpModal, CpModalBody, CpModalFooter, CpModalHeader } from '@dome9/components/react/components';
import { EnableImageAssurancePopupProps } from './EnableImageAssurancePopup.interface';

const EnableImageAssurancePopup: React.FC<EnableImageAssurancePopupProps> = (props) => {
    const { open, close, enableImageAssuranceForCluster } = props;
    const { t } = useTranslation();

    return (
        <CpModal open={open} backdropClose={true}>
            <CpModalHeader onCpClose={close}>
                {t('ON_BOARDING.REGISTRY.ENABLE_IMAGE')}
            </CpModalHeader>
            <CpModalBody>
                <div>
                    <div className='title-section'>{t('ON_BOARDING.REGISTRY.ENABLE_IMAGE_MODAL_TEXT')}</div>
                </div>
            </CpModalBody>
            <CpModalFooter>
                <CpButton onClick={close}>{t('ON_BOARDING.REGISTRY.CANCEL')}</CpButton>
                <CpCommonButton onClick={enableImageAssuranceForCluster}>
                    {t('ON_BOARDING.REGISTRY.ENABLE')}
                </CpCommonButton>
            </CpModalFooter>
        </CpModal>
    );
};

export default EnableImageAssurancePopup;