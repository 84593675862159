import {
    renderAddFilter,
    renderClearAll,
    renderMultiSelectFilter,
    renderSavedFilters,
    renderRecentlyUsedFilters,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { getVendor } from 'common/consts/vendors';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { Aggregations } from 'modules/workloads/services/cve/cve.interface';
import { ICustomzationResponse } from 'common/interface/services';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import IconLinkCellRenderer from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';

export interface IFilterDefs {
    aggregations: Aggregations;
    filtersInitialData: { savedFilters: ICustomzationResponse<any>, recentlyUsed: ICustomzationResponse<any> };
    savedFiltersComponentName: string;
    recentlyUsedComponentName: string;
}
export const filterDefs = ({ aggregations, filtersInitialData, recentlyUsedComponentName, savedFiltersComponentName }: IFilterDefs): Array<() => IFilterProps> => {
    return [
        () => ({
            filterProps: { key: 'add-filter' },
            renderFunction: renderAddFilter,
        }),
        // () => ({
        //     filterProps: {
        //         key: FILTERS_KEYS.FREE_TEXT, //This needs to be the same as in stored filter/query params
        //         title: i18n.t('TABLE_FILTERS.FREE_TEXT.TITLE', { ns: getEnvsNamespace('table') }),
        //     },
        //     keyInObjectForAPI: 'freeTextPhrase',
        //     renderFunction: renderDefaultFreeTextFilter,
        // }),
        () => {
            const displayMapping: IDisplayMappingObject = {};
            aggregations['cve.id']?.forEach((cveId: { value: string }) => {
                displayMapping[cveId.value] = {
                    displayText: cveId.value,
                    newIcon: 'cve',
                };
            });

            return {
                filterProps: {
                    initialData: aggregations['cve.id'],
                    key: 'cve.id',
                    title: 'CVE ID',
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
        () => {
            const displayMapping: IDisplayMappingObject = {};
            aggregations['cve.severity']?.forEach((cveSeverity: { value: string }) => {
                displayMapping[cveSeverity.value] = {
                    displayComponent: IconLinkCellRenderer as any,
                    displayComponentProps: {
                        value: cveSeverity.value,
                        isLink: false,
                        levelIconProps: {
                            iconProps: { name: 'cve' },
                            category: 'severity',
                            level: cveSeverity.value.toLowerCase(),
                            size: 'sm'
                        },
                        href: ''   
                    }
                };
            });


            return {
                filterProps: {
                    initialData: aggregations['cve.severity'],
                    key: 'cve.severity',
                    title: 'CVE Severity',
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
        () => {
            const displayMapping: IDisplayMappingObject = {};
            aggregations['affectedAsset.externalId']?.forEach((affectedAsset_externalId: { value: string, count: number, additionalFields?: { externalId: string, entityName: string, entityType: string } }) => {
                displayMapping[affectedAsset_externalId.value] = {
                    displayText: `${affectedAsset_externalId.additionalFields?.entityName} (${affectedAsset_externalId.additionalFields?.externalId})`,
                    icon: getProtectedAssetsService().getAssetByType(affectedAsset_externalId.additionalFields?.entityType || '')?.icon,
                };
            });

            return {
                filterProps: {
                    initialData: aggregations['affectedAsset.externalId'],
                    key: 'affectedAsset.externalId',
                    title: 'Affected Asset',
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
        () => {
            const displayMapping: IDisplayMappingObject = {};
            aggregations['affectedAsset.entityType']?.forEach((affectedAsset_entityType: { value: string }) => {
                const affectedAsset = getProtectedAssetsService().getAssetByType(affectedAsset_entityType.value);
                displayMapping[affectedAsset_entityType.value] = {
                    displayText: affectedAsset?.displayName || affectedAsset_entityType.value,
                    icon: affectedAsset?.icon,
                };
            });

            return {
                filterProps: {
                    initialData: aggregations['affectedAsset.entityType'],
                    key: 'affectedAsset.entityType',
                    title: 'Affected Asset Type',
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
        () => {
            const displayMapping: IDisplayMappingObject = {};
            aggregations['scannedAsset.externalId']?.forEach((scannedAsset_externalId: { value: string, count: number, additionalFields?: { externalId: string, entityName: string, entityType: string } }) => {
                displayMapping[scannedAsset_externalId.value] = {
                    displayText: `${scannedAsset_externalId.additionalFields?.entityName} (${scannedAsset_externalId.additionalFields?.externalId})`,
                    icon: getProtectedAssetsService().getAssetByType(scannedAsset_externalId.additionalFields?.entityType || '')?.icon,
                };
            });

            return {
                filterProps: {
                    initialData: aggregations['scannedAsset.externalId'],
                    key: 'scannedAsset.externalId',
                    title: 'Scanned Asset',
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
        () => {
            const displayMapping: IDisplayMappingObject = {};
            aggregations['cloudAccountId']?.forEach((cloudAccountId: { value: string, count: number, environmentFields?: { cloudAccountId: string, cloudAccountName: string, platform: string } }) => {
                displayMapping[cloudAccountId.value] = {
                    displayText: `${cloudAccountId.environmentFields?.cloudAccountName} (${cloudAccountId.environmentFields?.cloudAccountId})`,
                    icon: getVendor(cloudAccountId.environmentFields?.platform || '')?.icon,
                };
            });
            return {
                filterProps: {
                    initialData: aggregations['cloudAccountId'],
                    key: 'cloudAccountId',
                    title: 'Environment',
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
        () => {
            const displayMapping: IDisplayMappingObject = {};
            aggregations['scannedAsset.entityType']?.forEach((scannedAsset_entityType: { value: string }) => {
                const affectedAsset = getProtectedAssetsService().getAssetByType(scannedAsset_entityType.value);
                displayMapping[scannedAsset_entityType.value] = {
                    displayText: affectedAsset?.displayName || scannedAsset_entityType.value,
                    icon: affectedAsset?.icon,
                };
            });

            return {
                filterProps: {
                    initialData: aggregations['scannedAsset.entityType'],
                    key: 'scannedAsset.entityType',
                    title: 'Scanned Asset Type',
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
        () => {
            const displayMapping: IDisplayMappingObject = {};
            aggregations['package.id']?.forEach((packageId: { value: string, count: number, packageFields?: { packageName: string } }) => {
                displayMapping[packageId.value] = {
                    displayText: `${packageId.packageFields?.packageName} (${packageId.value})`,
                    newIcon: 'package',
                };
            });
            return {
                filterProps: {
                    initialData: aggregations['package.id'],
                    key: 'package.id',
                    title: 'Package',
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
        () => {
            const displayMapping: IDisplayMappingObject = {
                'Inspector': { displayText: 'AWS Inspector', icon: getVendor('aws')?.icon },
                'cloudGuard': { displayText: 'CloudGuard', newIcon: 'cloudGuard' },
            };

            return {
                filterProps: {
                    initialData: aggregations['scan.producer'],
                    key: 'scan.producer',
                    title: 'Scan Source',
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
        () => {
            const displayMapping: IDisplayMappingObject = {
                true: { displayText: 'Fixable' },
                false: { displayText: 'Not Fixable' }
            };

            return {
                filterProps: {
                    initialData: aggregations['cve.isFixable'],
                    key: 'cve.isFixable',
                    title: 'Fixable',
                    displayMapping,
                },
                renderFunction: renderMultiSelectFilter,
            };
        },
        () => ({
            filterProps: {
                savedFilters: filtersInitialData?.savedFilters || [],
                savedFiltersComponentName,
                selectedFilterID: '',
                key: FILTERS_KEYS.SAVED_FILTERS,
            },
            renderFunction: renderSavedFilters,
        }),
        () => ({
            filterProps: {
                key: FILTERS_KEYS.RECENTLY_USED_FILTERS,
                recentlyUsedList: filtersInitialData?.recentlyUsed,
                maxLength: 4,
                componentName: recentlyUsedComponentName,
                title: 'Recently Used Filters',
            },
            renderFunction: renderRecentlyUsedFilters,
        }),
        () => ({
            filterProps: {
                key: FILTERS_KEYS.CLEAR_BUTTON,
            },
            renderFunction: renderClearAll,
        })
    ];
};