import React from 'react';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { Stack, Typography } from 'common/design-system/components-v2';
import WidgetCard from 'common/components/Dashboard/WidgetCard/WidgetCard';
import { featuresAndInfoByType, generalMoreInfoGetters } from '../KubernetesGeneralSection.types';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

const KubernetesGeneralSectionMoreInfo: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    const { t } = useTranslation(getK8sNamespace('protected-asset-details'));

    const detailsInfo = React.useMemo(() => {
        const moreInfoByType = featuresAndInfoByType[entity.typeByPlatform]?.moreInfo || [];
        return moreInfoByType.map(info => ({
            label: t(`overviewGeneral.features.moreInfo.labels.${info}`),
            value: generalMoreInfoGetters(entity, info)
        })).filter(detail => detail.value);
    }, [entity, t]);

    return (
        <WidgetCard
            classNames={{}}
            title={t('overviewGeneral.features.moreInfo.title')}
            content={
                <Stack spacing={2} padding={[1, 3]}>
                    {detailsInfo.map((details, index) => (
                        <Stack key={`details-${index}`}>
                            <Typography color='light'>{details.label}</Typography>
                            {typeof details.value === 'string' ? <Typography variant='bodyLg'>{details.value}</Typography> : details.value}
                        </Stack>
                    ))}
                </Stack>
            }
        />
    );
};

export default KubernetesGeneralSectionMoreInfo;
