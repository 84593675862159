import React from 'react';
import AwpService from '../awp.service';
import { Vendors } from 'common/consts/vendors';
import { ScanMode, UpdateCentralizedWorkloafAgentlessAccountSettings, UpdateWorkloadAgentlessAccountSettings } from '../awp.interface';

export interface UseUpdateAgentlessAccountSettingsReq {
    scanMode?: ScanMode;
    onError?: (error: { message: string; }) => void;
    onSuccess?: (response: UpdateWorkloadAgentlessAccountSettings.Response) => void;
}

export interface UseUpdateAgentlessAccountSettingsPayload {
    isLoading: boolean;
    isSuccess: boolean;
    isError?: { message: string; };

    updateAccountSettings: (cloudAccountId: string, vendor: Vendors, request: UpdateWorkloadAgentlessAccountSettings.Request) => Promise<void>;
    reset?: () => void;
}

export type UseUpdateAgentlessAccountSettings = (req?: UseUpdateAgentlessAccountSettingsReq) => UseUpdateAgentlessAccountSettingsPayload;

export const useUpdateAgentlessAccountSettings: UseUpdateAgentlessAccountSettings = ({ scanMode, onSuccess, onError } = {}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isError, setIsError] = React.useState<{ message: string; }>();

    const updateAccountSettings = React.useCallback(async (cloudAccountId: string, vendor: Vendors, request: UpdateWorkloadAgentlessAccountSettings.Request | UpdateCentralizedWorkloafAgentlessAccountSettings.Request) => {
        setIsLoading(true);
        try {
            const updateFunction = scanMode === ScanMode.InAccountHub ? AwpService.updateCentralizedWorkloafAgentlessAccountSettings : AwpService.updateWorkloadAgentessAccountSettings;
            const payload = scanMode === ScanMode.InAccountHub ? { inAccountScannerVPC: request.inAccountScannerVPC } : request;
            const result = await updateFunction(cloudAccountId, vendor, payload);
            setIsSuccess(!!result.data);
            onSuccess?.(result.data);
        } catch (error: any) {
            setIsError({ message: error.message });
            onError?.({ message: error.message });
        } finally {
            setIsLoading(false);
        }
    }, [scanMode, onSuccess, onError]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(undefined);
    }, []);

    return ({
        isLoading,
        isSuccess,
        isError,

        updateAccountSettings,
        reset
    });
};