import React from 'react';
import { ClusterCreationFaildWrapper, ClusterCreationFaildLabel } from './ClusterCreationFaild.styles';
import { Icon } from '@dome9/berries/react-components';
import { useTranslation } from 'react-i18next';

const ClusterCreationFailed: React.FunctionComponent = () => {
    const { t } = useTranslation('k8s_cluster');

    return (
        <ClusterCreationFaildWrapper>
            <ClusterCreationFaildLabel>{t('ON_BOARDING.PAGE.FAILED_TO_CREATE')}</ClusterCreationFaildLabel>
            <Icon name='notFound' size={96} />
        </ClusterCreationFaildWrapper>
    );
};

export default ClusterCreationFailed;
