import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormFieldModel } from 'common/interface/formFieldModel';
import { initialState } from './cluster.state';
import {
    ClusterState,
    ClusterForm
} from './cluster.interface';
import { Registry } from '../registry/registry.interface';
import { RootState } from 'common/services/store/store';

const ClusterSlice = createSlice({
    name: 'cluster',
    initialState,
    reducers: {
        setClusters: (state: ClusterState, action: PayloadAction<any[]>) => {
            state.clusters = action.payload;
        },
        setIsLoadingClusters: (state: ClusterState, action: PayloadAction<boolean>) => {
            state.isLoadingClusters = action.payload;
        },
        setSelectedCluster: (state: ClusterState, action: PayloadAction<any>) => {
            state.selectedCluster = action.payload;
        },
        setNewCluster: (state: ClusterState, action: PayloadAction<any>) => {
            state.newCluster = action.payload;
        },
        setClusterName: (state: ClusterState, action: PayloadAction<FormFieldModel['value']>) => {
            state.clusterForm.name.value = action.payload;
            if ((action?.payload?.length ?? 0) === 0) {
                state.clusterForm.name.isError = true;
            } else {
                state.clusterForm.name.isDirty = true;
                state.clusterForm.name.isError = false;
            }
        },
        setClusterKey: (state: ClusterState, action: PayloadAction<FormFieldModel['value']>) => {
            state.clusterForm.apiKey.value = action.payload;
            if ((action?.payload?.length ?? 0) === 0) {
                state.clusterForm.apiKey.isError = true;
            } else {
                state.clusterForm.apiKey.isDirty = true;
                state.clusterForm.apiKey.isError = false;
            }
        },
        setClusterSecret: (state: ClusterState, action: PayloadAction<FormFieldModel['value']>) => {
            state.clusterForm.apiSecret.value = action.payload;
            if ((action?.payload?.length ?? 0) === 0) {
                state.clusterForm.apiSecret.isError = true;
            } else {
                state.clusterForm.apiSecret.isDirty = true;
                state.clusterForm.apiSecret.isError = false;
            }
        },
        setClusterNamespace: (state: ClusterState, action: PayloadAction<FormFieldModel['value']>) => {
            state.clusterForm.namespace.value = action.payload;
            if ((action?.payload?.length ?? 0) === 0) {
                state.clusterForm.namespace.isError = true;
            } else {
                state.clusterForm.namespace.isDirty = true;
                state.clusterForm.namespace.isError = false;
            }
        },
        setSelectedClusterErrorStatus: (state: ClusterState, action: PayloadAction<boolean>) => {
            state.selectedClusterError = action.payload;
        },
        setClusterKeyErrorStatus: (state: ClusterState) => {
            state.clusterForm.apiKey.isError = true;
            state.clusterForm.apiKey.isDirty = true;
        },
        setClusterSecretErrorStatus: (state: ClusterState) => {
            state.clusterForm.apiSecret.isError = true;
            state.clusterForm.apiSecret.isDirty = true;
        },
        setClusterNamespaceErrorStatus: (state: ClusterState) => {
            state.clusterForm.namespace.isError = true;
            state.clusterForm.namespace.isDirty = true;
        },
        toggleClusterImageAssurance: (
            state: ClusterState,
            action: PayloadAction<ClusterForm['imageAssuranceEnabled']>
        ) => {
            state.clusterForm.imageAssuranceEnabled = action.payload;
        },
        toggleClusterAdmissionControl: (
            state: ClusterState,
            action: PayloadAction<ClusterForm['admissionControlEnabled']>
        ) => {
            state.clusterForm.admissionControlEnabled = action.payload;
        },
        toggleClusterRuntimeProtection: (
            state: ClusterState,
            action: PayloadAction<ClusterForm['runtimeProtectionEnabled']>
        ) => {
            state.clusterForm.runtimeProtectionEnabled = action.payload;
            state.clusterForm.runtimeProtectionProfiling = false;
        },
        toggleClusterRuntimeProfilingProtection: (
            state: ClusterState,
            action: PayloadAction<ClusterForm['runtimeProtectionProfiling']>
        ) => {
            state.clusterForm.runtimeProtectionProfiling = action.payload;
        },
        toggleClusterThreatIntelligence: (
            state: ClusterState,
            action: PayloadAction<ClusterForm['threatIntelligenceEnabled']>
        ) => {
            state.clusterForm.threatIntelligenceEnabled = action.payload;
        },
        setKubernetesAccountSummary: (
            state: ClusterState,
            action: PayloadAction<ClusterState['kubernetesAccountSummary']>
        ) => {
            state.kubernetesAccountSummary = action.payload;
        },
        setKubernetesAgentSummary: (state: ClusterState, action: PayloadAction<any>) => {
            state.kubernetesAgentSummary = action.payload;
        },
        setAllKubernetesAccount: (state: ClusterState, action: PayloadAction<{ [key: string]: Registry }>) => {
            state.allKubernetesAccount = action.payload;
        },
        setSelectedServiceAccount: (state: ClusterState, action: PayloadAction<any>) => {
            state.selectedServiceAccount = action.payload;
            if (action.payload) {
                state.clusterForm.apiKey.value = action.payload.apiKeyId;
                state.clusterForm.apiKey.isDirty = true;
                if (action.payload.apiKeySecret) {
                    state.clusterForm.apiSecret.value = action.payload.apiKeySecret;
                    state.clusterForm.apiSecret.isDirty = true;
                } else {
                    state.clusterForm.apiSecret.value = '';
                }
            } else {
                state.clusterForm.apiKey.value = '';
                state.clusterForm.apiSecret.value = '';
            }
        },
        setKubernetesAccount: (state, action) => {
            state.kubernetesAccount = action.payload;
        }
    }
});

export const getClusters = (state: RootState): any[] => state.cluster.clusters;
export const getIsLoadingClusters = (state: RootState): boolean => state.cluster.isLoadingClusters;
export const getSelectedCluster = (state: RootState): any => state.cluster.selectedCluster;
export const getSelectedClusterErrorStatus = (state: RootState): boolean => state.cluster.selectedClusterError;
export const getClusterForm = (state: RootState): ClusterForm => state.cluster.clusterForm;
export const getClusterName = (state: RootState): any => state.cluster.clusterForm.name;
export const getClusterKey = (state: RootState): any => state.cluster.clusterForm.apiKey;
export const getClusterSecret = (state: RootState): any => state.cluster.clusterForm.apiSecret;
export const getClusterNamespace = (state: RootState): any => state.cluster.clusterForm.namespace;
export const getNewCluster = (state: RootState): any => state.cluster.newCluster;
export const getSelectedServiceAccount = (state: RootState): any[] => state.cluster.selectedServiceAccount;
export const getRelevantCluster = (state: RootState): any => state.cluster.newCluster || state.cluster.selectedCluster;
export const getKubernetesAccountSummary = (state: RootState) => state.cluster.kubernetesAccountSummary;
export const getKubernetesAccount = (state: RootState) => state.cluster.kubernetesAccount;
export const getTotalAgents = (state: RootState): number =>
    state.cluster.kubernetesAccountSummary?.featureStatuses?.reduce(
        (totalAgents: number, { statusError, statusInit, statusOk, statusPending }: any) =>
            totalAgents + statusError + statusInit + statusOk + statusPending - 1,
        1
    );

export default ClusterSlice;