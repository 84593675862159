import { Button, Card, Input, Label, Message, Spinner, Stack } from 'common/design-system/components-v2';
import { Controller, useForm } from 'react-hook-form';
import { AwpSettingsFields } from './AwpSettings.types';
import { SettingsFields } from '../../Settings.types';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getNotificationsService } from 'common/interface/services';
import AwpTags from './components/Tags/Tags';
import AwpService from 'modules/workloads/services/awp/awp.service';
import { NotificationType } from 'common/interface/notifications';
import { DEFAULT_VALUES, deserializeAwpSettings, serializeAwpSettings } from './AwpSettings.utils';

const settingsFields: Array<SettingsFields<Omit<AwpSettingsFields, 'customTags'>>> = [
    {
        label: 'SETTINGS.WORK_LOAD.AWP.SCAN_INTERVAL.LABEL',
        name: 'scanMachineIntervalInHours',
        tooltip: 'SETTINGS.WORK_LOAD.AWP.SCAN_INTERVAL.TOOLTIP',
        validation: {
            pattern: {
                value: /^\d+$/,
                message: 'SETTINGS.WORK_LOAD.AWP.SCAN_INTERVAL.ERROR.NOT_NUMBER',
            },
            min: {
                value: 24,
                message: 'SETTINGS.WORK_LOAD.AWP.SCAN_INTERVAL.ERROR.AT_LEAST',
            },
            max: {
                value: 1000,
                message: 'SETTINGS.WORK_LOAD.AWP.SCAN_INTERVAL.ERROR.AT_MOST',
            }
        }
    },
    {
        label: 'SETTINGS.WORK_LOAD.AWP.MAX_CONCURRENT_SCANS.LABEL',
        name: 'maxConcurrenceScansPerRegion',
        tooltip: 'SETTINGS.WORK_LOAD.AWP.MAX_CONCURRENT_SCANS.TOOLTIP',
        validation: {
            pattern: {
                value: /^\d+$/,
                message: 'SETTINGS.WORK_LOAD.AWP.MAX_CONCURRENT_SCANS.ERROR.NOT_NUMBER',
            },
            min: {
                value: 1,
                message: 'SETTINGS.WORK_LOAD.AWP.MAX_CONCURRENT_SCANS.ERROR.AT_LEAST',
            },
            max: {
                value: 50,
                message: 'SETTINGS.WORK_LOAD.AWP.MAX_CONCURRENT_SCANS.ERROR.AT_MOST',
            },
        }
    },
];

const AwpSettings = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [isFormDataReady, setIsFormDataReady] = useState(false);
    const {
        resetField,
        setValue,
        handleSubmit,
        control,
        register,
        formState: { errors, dirtyFields },
    } = useForm({
        mode: 'onChange',
        defaultValues: DEFAULT_VALUES,
    });

    const getSettings = useCallback(async () => {
        const alertsService = getNotificationsService();
        setIsLoading(true);
        try {
            const settings = (await AwpService.getAgentlessSettings()).data;
            const serialized = serializeAwpSettings(settings);
            Object.keys(DEFAULT_VALUES).forEach((fieldName) => {
                const key = fieldName as keyof AwpSettingsFields;
                const defaultValue = serialized[key];
                resetField(key, { defaultValue });
                setValue(key, defaultValue, { shouldValidate: true });
            });
        } catch(error: any) {
            alertsService.addNotification({
                text: error.message,
                type: NotificationType.ERROR,
                title: 'Error',
            });
        } finally {
            setIsLoading(false);
        }
    }, [resetField, setValue]);

    useEffect(() => {
        (async () => {
            await getSettings();
            setIsFormDataReady(true);
        })();
    }, [getSettings]);

    const handleSave = useMemo(() => handleSubmit(async (data) => {
        const alertsService = getNotificationsService();
        setIsLoading(true);
        try {
            await AwpService.updateAgentlessSettings(deserializeAwpSettings(data));
            await getSettings();
            alertsService.addNotification({
                text: t('SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.NOTIFICATION.SUCCESS.TEXT'),
                type: NotificationType.SUCCESS,
                title: t('SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.NOTIFICATION.SUCCESS.TITLE'),
            });
        } catch(error: any) {
            alertsService.addNotification({
                text: error.message,
                type: NotificationType.ERROR,
                title: t('SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.NOTIFICATION.ERROR.TITLE'),
            });
        } finally {
            setIsLoading(false);
        }
    }), [getSettings, handleSubmit, t]);

    return (
        <Card title={t('SETTINGS.WORK_LOAD.AWP.TITLE')}>
            {!isFormDataReady ? (
                <Stack fullWidth fullHeight alignItems='center' justifyContent='center'>
                    <Spinner size={40} />
                </Stack>            
            ) : (
                <Stack direction='column' spacing={4}>
                    {settingsFields.map(settingsField => {
                        return (
                            <Controller
                                key={`${settingsField.name}`}
                                name={settingsField.name}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        fullWidth
                                        label={t(settingsField.label)}
                                        tooltip={settingsField.tooltip && t(settingsField.tooltip)}
                                        {...field}
                                        {...register(field.name, settingsField.validation)}
                                        isError={Boolean(errors[settingsField.name])}
                                        helperText={errors[settingsField.name]?.message && t(errors[settingsField.name]?.message ?? '') || settingsField.textHelper && t(settingsField.textHelper)}
                                    />
                                )}
                            />
                        );
                    })}
                    <Label text={t('SETTINGS.WORK_LOAD.AWP.CUSTOM_TAGS.LABEL')} tooltip={t('SETTINGS.WORK_LOAD.AWP.CUSTOM_TAGS.TOOLTIP')} />
                    <Stack padding={[0, 0, 0, 3]}>
                        <Controller
                            name='customTags'
                            control={control}
                            render={() => (
                                <AwpTags
                                    control={control}
                                    register={register}
                                    errors={errors}
                                />
                            )}
                        />
                    </Stack>
                    <Button
                        color='brandPrimary'
                        loading={isLoading}
                        disabled={Object.keys(errors).length > 0 || Object.keys(dirtyFields).length === 0 || isLoading}
                        onClick={() => setIsConfirmDialogOpen(true)}
                    >
                        {t('SETTINGS.WORK_LOAD.IMAGE_ASSURANCE.SAVE_BUTTON')}
                    </Button>
                </Stack>
            )}
            <Message
                variant='warning'
                title={t('SETTINGS.WORK_LOAD.AWP.CONFIRMATION.TITLE')}
                text={t('SETTINGS.WORK_LOAD.AWP.CONFIRMATION.TEXT')}
                isOpen={isConfirmDialogOpen}
                onClose={() => setIsConfirmDialogOpen(false)}
                onConfirm={() => {
                    setIsConfirmDialogOpen(false);
                    handleSave();
                }}
                onCancel={() => setIsConfirmDialogOpen(false)}
            />
        </Card>
    );

};

export default AwpSettings;