import { ScanMode } from 'modules/workloads/services/awp/awp.interface';
import AwpService from 'modules/workloads/services/awp/awp.service';
import { CloudFormParams } from './awpOnboardingAws.types';
import { changeUrl } from 'common/utils/http';
import { Vendors } from 'common/consts/vendors';

export const enableAgentlessFunctions = {
    [ScanMode.Saas]:
        (cloudAccountId: string, params: CloudFormParams) => {
            return AwpService.enableAgentlessAwsAccount(cloudAccountId, {
                scanMode: ScanMode.Saas,
                crossAccountRoleExternalId: params.crossAccountExternalId,
                crossAccountRoleName: params.crossAccountRoleName,
                cloudGuardAWPStackName: params.stackName,
            });
        },
    [ScanMode.InAccount]:
        (cloudAccountId: string, params: CloudFormParams) => {
            return AwpService.enableAgentlessAwsAccount(cloudAccountId, {
                scanMode: ScanMode.InAccount,
                crossAccountRoleExternalId: params.crossAccountExternalId,
                crossAccountRoleName: params.crossAccountRoleName,
                cloudGuardAWPStackName: params.stackName,
                ...(params.customVpc && { agentlessAccountSettings: { inAccountScannerVPC: params.customVpc } }),
            });
        },
    [ScanMode.InAccountHub]:
        (cloudAccountId: string, params: CloudFormParams) => {
            return AwpService.enableHubAccount(cloudAccountId, Vendors.AWS, {
                crossAccountRoleExternalId: params.crossAccountExternalId,
                crossAccountRoleName: params.crossAccountRoleName,
                cloudGuardAWPStackName: params.stackName,
                ...(params.customVpc && { agentlessAccountSettings: { inAccountScannerVPC: params.customVpc } }),
            });
        },
    [ScanMode.InAccountSub]:
        (cloudAccountId: string, params: CloudFormParams) => {
            return AwpService.enableAwsSubAccount(cloudAccountId, {
                centralizedCloudAccountId: params.hubId ?? '',
                crossAccountRoleName: params.crossAccountRoleName,
                crossAccountRoleExternalId: params.crossAccountExternalId,
                cloudGuardAWPStackName: params.stackName,
                ...(params.customVpc && { agentlessAccountSettings: { inAccountScannerVPC: params.customVpc } }),
            });
        },
} as const;


export const getMissingFields = (args: {
    scanMode: ScanMode,
    isHubIdDirty?: boolean,
    crossAccountExternalIdInvalid: boolean,
    crossAccountRoleNameInvalid: boolean,
    stackNameInvalid: boolean,
  }) => {
    const fields = [];
    if (args.scanMode === ScanMode.InAccountSub && !args.isHubIdDirty) {
        fields.push('Centralized Account');
    }
    if (args.crossAccountExternalIdInvalid) {
        fields.push('Cross Account External Id');
    }
    if (args.crossAccountRoleNameInvalid) {
        fields.push('Cross Account Role Name');
    }
    if (args.stackNameInvalid) {
        fields.push('Cloud Formation Stack Name');
    }
    return fields;
};

export const getGenerateOnboardinDataRequestPayload = (data :{ scanMode: ScanMode, stackName: string, crossAccountExternalId: string, cloudAccountId: string, hubId?: string }) => {
    const { scanMode, stackName, crossAccountExternalId, hubId, cloudAccountId } = data;
    return (scanMode === ScanMode.Saas || scanMode === ScanMode.InAccount) ? ({
        scanMode,
        externalAccountId: cloudAccountId,
        crossAccountRoleExternalId: crossAccountExternalId,
        cloudGuardAWPStackName: stackName,
    }) : (scanMode === ScanMode.InAccountHub ? ({
        scanMode,
        externalAccountId: cloudAccountId,
        crossAccountRoleExternalId: crossAccountExternalId,
        cloudGuardAWPStackName: stackName,
    }) : ({
        scanMode,
        externalAccountId: cloudAccountId,
        crossAccountRoleExternalId: crossAccountExternalId,
        cloudGuardAWPStackName: stackName,
        centralizedCloudAccountId: hubId ?? '',
    }));
};

export const onCancel = () => {
    changeUrl('/cloud-account/index');
};

