import styled from 'styled-components';

export const PageWrapper = styled.div`
    padding: 20px 0px 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const Content = styled.div`
    flex-grow: 1;
    padding: 0px 20px;
    overflow: hidden;
`;

interface ContentItemProps {
    isActive: boolean;
}

export const TopSection = styled.div`
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const ContentItem = styled.div<ContentItemProps>`
    position: relative;
    width: 100%;
    height: 100%;
    display: ${props => props.isActive ? 'block' : 'none'};
`;

export const ReactTabContent = styled.div`
    height: 100%;
`;

export const LoaderWrapper = styled.div`
    height: calc(100vh - 71px);
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%; 
    position: absolute;
    z-index: 100000000;
    background-color: white;
`;