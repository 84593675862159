import {
    AWSNodeGroupRoleAuthExplanationWrapper,
    ExplanationRow
} from './AWSNodeGroupRoleAuthExplanation.styled';

const AWSNodeGroupRoleAuthExplanation = () => (
    <AWSNodeGroupRoleAuthExplanationWrapper>
        <ExplanationRow>To use this method, please make sure of the following:</ExplanationRow>
        <ExplanationRow>
            <div>1.</div>
            <div>Your host cluster is an EKS cluster.</div>
        </ExplanationRow>
        <ExplanationRow>
            <div>2.</div>
            <div>Your EKS cluster is on the same AWS account as the ECR registry.</div>
        </ExplanationRow>
        <ExplanationRow>
            <div>3.</div>
            <div>The Amazon EKS worker node IAM role (NodeInstanceRole) that you use on the Nodes in this Cluster, has the relevant IAM policy permissions for the Amazon ECR on that account.</div>
        </ExplanationRow>
    </AWSNodeGroupRoleAuthExplanationWrapper>
);

export default AWSNodeGroupRoleAuthExplanation;