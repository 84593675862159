import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { MagellanEnableMagellanKubernetes } from './magellan.interface';

const magellanEnableMagellanKubernetes: MagellanEnableMagellanKubernetes.Function = async (request: MagellanEnableMagellanKubernetes.Request) => {
    return await getHttpService().request<AxiosResponse<MagellanEnableMagellanKubernetes.Response>>(
        'view/magellan/enable-magellan-kubernetes',
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const MagellanService = {
    magellanEnableMagellanKubernetes
};

export default MagellanService;
