import React from 'react';
import WorkloadService from '../workload.service';
import { ImageAssuraceScanEnvironment } from '../workload.interface';

export interface UseWorkloadEnvImagesScanReq {
    onError?: (error: { message: string; }) => void;
    onSuccess?: (response: ImageAssuraceScanEnvironment.Response) => void;
}

export interface UseWorkloadEnvImagesScanPayload {
    isLoading: boolean;
    isRequestSuccess: boolean;
    requestResponse?: ImageAssuraceScanEnvironment.Response;
    isError?: { message: string; };
    scanEnvImages: (request: ImageAssuraceScanEnvironment.Request) => Promise<void>;
    reset?: () => void;
}

export type UseWorkloadEnvImagesScan = (req?: UseWorkloadEnvImagesScanReq) => UseWorkloadEnvImagesScanPayload;

export const useWorkloadEnvImagesScan: UseWorkloadEnvImagesScan = ({ onSuccess, onError } = {}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = React.useState(false);
    const [requestResponse, setRequestResponse] = React.useState<ImageAssuraceScanEnvironment.Response>();
    const [isError, setIsError] = React.useState<{ message: string; }>();

    const scanEnvImages = React.useCallback(async (request: ImageAssuraceScanEnvironment.Request) => {
        setIsLoading(true);
        try {
            const result = await WorkloadService.imageAssuraceScanEnvironment({ request });
            setIsRequestSuccess(true);
            setRequestResponse(result.data);
            onSuccess?.(result.data);
        } catch (error: any) {
            setIsError({ message: error.message });
            onError?.({ message: error.message });
        }
        setIsLoading(false);
    }, [onSuccess, onError]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsRequestSuccess(false);
        setIsError(undefined);
    }, []);

    return ({
        isLoading,
        isRequestSuccess,
        requestResponse,
        isError,
        scanEnvImages,
        reset
    });
};