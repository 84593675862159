import React from 'react';
import AdvancedConfig from './AdvancedConfig';
import DisableAWP from './DisableAWP';
import { WorkloadAgentlessAccount } from 'modules/workloads/services/awp/awp.interface';

export enum ModalType {
    AdvancedConfig = 'AdvancedConfig',
    DisableAWP = 'DisableAWP',
}

export interface ModalsProps {
    agentlessAccount: WorkloadAgentlessAccount;
    reloadAgentlessAccount: () => void;
    modalType: ModalType | null;
    closeModal: () => void
    setModalType: (modalType: ModalType) => void;
}

const Modals: React.FC<ModalsProps> = props => {
    switch (props.modalType) {
        case ModalType.AdvancedConfig:
            return <AdvancedConfig {...props} />;
        case ModalType.DisableAWP:
            return <DisableAWP {...props} />;
        default:
            return null;
    }
};

export default Modals;
