/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ALink } from 'common/components/ALink';
import { ImageAdmissionCard } from '../ImageAdmissionCard/ImageAdmissionCard';
import { Stack, Typography } from 'common/design-system/components-v2';
import { LineSeparator } from './LineSeparator.styled';
import { nonCompliantAdmissionsObj, unScannedVulnerabilitiesObj } from '../../helpers/consts';
import { ImageAdmissionItem } from 'common/interface/policy';
import { ImageAdmissionSelectionProps } from './ImageAdmissionSelectionComponent.types';

const imageAdmissionReadMoreLint = 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=image_admission';
export const ImageAdmissionSelectionComponent: React.FC<ImageAdmissionSelectionProps> =
    ({ onValidate, preSelectedNonCompliant, preSelectedUnScanned, selectedUnScannedChanged, selectedNonCompliantItemChanged }) => {
        const { t } = useTranslation('k8s_policy');
        const unScannedAdmissions: ImageAdmissionItem[] = Object.values(unScannedVulnerabilitiesObj(t));
        const nonCompliantAdmissions: ImageAdmissionItem[] = Object.values(nonCompliantAdmissionsObj(t));

        useEffect(() => {
            selectedNonCompliantItemChanged(preSelectedNonCompliant || nonCompliantAdmissions[nonCompliantAdmissions.length - 1]);
            selectedUnScannedChanged(preSelectedUnScanned || unScannedAdmissions[unScannedAdmissions.length - 1]);
        }, []);

        useEffect(() => {
            onValidate(!!(preSelectedNonCompliant && preSelectedUnScanned));
        }, [preSelectedNonCompliant, preSelectedUnScanned]);

        const onSelectedUnScannedChanged = (selectedItem: ImageAdmissionItem) => {
            selectedUnScannedChanged(selectedItem);
        };

        const onSelectedNonCompliantChanged = (selectedItem: ImageAdmissionItem) => {
            selectedNonCompliantItemChanged(selectedItem);
        };


        return (
            <span data-aid={'rulesets-selection'}>
                {
                    <Stack spacing={3}>
                        <Stack flexWrap={true} spacing={3} direction={'row'} alignItems={'center'}>
                            <Typography variant={'bodyLg'}>{t('ADMISSION.SELECT_ADMISSION_HEADER')}</Typography>
                            <Typography variant={'bodyLg'}><ALink href={imageAdmissionReadMoreLint} >{t('GENERAL.READ_MORE')}</ALink></Typography>
                        </Stack>
                        <LineSeparator />
                        <Typography variant={'bodyLg'}>{t('ADMISSION.SELECT_NON_COMPLIANT_HEADER')}</Typography>
                        <Stack spacing={5} direction={'row'} flexWrap={true}>
                            {
                                nonCompliantAdmissions?.map((admission) => {
                                    return (
                                        <ImageAdmissionCard {...admission} selected={preSelectedNonCompliant?.id === admission.id}
                                            onClick={() => onSelectedNonCompliantChanged(admission)}
                                            style={admission.style}
                                            key={admission.id}
                                        />
                                    );
                                })
                            }
                        </Stack>
                        <Typography variant={'bodyLg'}>{t('ADMISSION.SELECT_UN_SCANNED_HEADER')}</Typography>
                        <Stack spacing={5} direction={'row'} flexWrap={true}>
                            {
                                unScannedAdmissions?.map((admission) => (
                                    <ImageAdmissionCard {...admission} selected={preSelectedUnScanned?.id === admission.id}
                                        onClick={() => onSelectedUnScannedChanged(admission)}
                                        style={admission.style}
                                        key={admission.id}
                                    />
                                ))
                            }
                        </Stack>
                    </Stack>
                }
            </span>
        );

    };