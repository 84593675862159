import { ICellRendererParams } from 'ag-grid-community';
import { Link } from 'common/design-system/components-v2';

const AwpCentralizedAccountCellRenderer: React.FC<ICellRendererParams> = (params) => {
    if (!params.value) return null;
    const { name, id } = params.value;
    return (
        <Link internalUrl={`/v2/cloud-account/${params.data.platform}/${id}`}>{name}</Link>
    );
};

export default AwpCentralizedAccountCellRenderer;