import React from 'react';
import { Icon } from '@dome9/berries/react-components';
import { EditIconWrapper, KOBTextFieldWrapper, TextField_ } from './KOBTextField.styles';

export interface KOBTextFieldProps {
    value?: string;
    onChange: (value: string) => Promise<boolean> | boolean;
}

const KOBTextField: React.FunctionComponent<KOBTextFieldProps> = ({ value, onChange }) => {
    const [isFocused, setIsFocused] = React.useState<boolean>(false);
    const [changeError, setChangeError] = React.useState<boolean>(false);
    const [tempValue, setTempValue] = React.useState<string>(value || '');

    const toggleFocus = async () => {
        if (isFocused) {
            if (changeError) setChangeError(false);
            const updateResponse = await onChange(tempValue);
            
            if (!updateResponse) {
                setChangeError(true);
                return;
            }
        }

        setIsFocused(currentState => !currentState);
    };

    return (
        <KOBTextFieldWrapper>
            <TextField_
                value={tempValue}
                error={changeError}
                onChange={(e) => setTempValue(e.target.value)}
                onClear={() => setTempValue('')}
                disabled={!isFocused}
                endElements={
                    <EditIconWrapper onClick={toggleFocus}>
                        <Icon name={isFocused ? 'save' : 'edit'} size={12} />
                    </EditIconWrapper>
                }
            />
        </KOBTextFieldWrapper>
    );
};

export default KOBTextField;
