import React from 'react';
import { singletonHook } from 'react-singleton-hook';
import CveService from 'modules/workloads/services/cve/cve.service';
import { Vulnerability } from 'modules/workloads/services/cve/cve.interface';

interface IUseTopCves {
    topCvesData: Array<Vulnerability>;
    isLoading: boolean;
    error: boolean;
    getTopCves: () => void;
}

type UseTopCves = () => IUseTopCves;
const useTopCvesImple: UseTopCves = (): IUseTopCves => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [topCvesData, setTopCvesData] = React.useState<Array<Vulnerability>>([]);
    const [error, setError] = React.useState<boolean>(false);

    const getTopCves = React.useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await CveService.getTopCves();
            setTopCvesData(response?.data.data || []);
            setIsLoading(false);
        } catch (error: any) {
            setTopCvesData([]);
            setError(true);
            setIsLoading(false);
            console.error(error);
        }
    }, []);

    return {
        topCvesData,
        isLoading,
        error,
        getTopCves,
    };
};

const useTopCves: UseTopCves = singletonHook({ topCvesData: [], isLoading: false, getTopCves: () => null, error: false }, useTopCvesImple);
export default useTopCves;