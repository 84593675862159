import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import { CVE_DRAWER_ID, VULNERABILITY_DRAWER_ID } from './cveDrawerRegistry.consts';
import { CveParams } from './cveDrawerRegistry.types';
import { IDrawerContent } from 'common/components/DrawerInfra/Drawer/Drawer.interface';
import CveDrawer from 'modules/workloads/pages/cveDrawer/CveDrawer';
import VulnerabilityDrawer from 'modules/workloads/Drawers/VulnerabilityDrawer/VulnerabilityDrawer';

function initializeDrawerContent() {
    DrawerRegistry.addContentProvider({
        id: CVE_DRAWER_ID,
        getDrawerContent: async (cveParams: CveParams): Promise<IDrawerContent | undefined> => {
            return {
                component: () => <CveDrawer cveParams={cveParams} />
            };
        }
    });
    DrawerRegistry.addContentProvider({
        id: VULNERABILITY_DRAWER_ID,
        getDrawerContent: async ({ cveId, packageId }): Promise<IDrawerContent | undefined> => {
            return {
                component: () => <VulnerabilityDrawer cveId={decodeURIComponent(cveId)} packageId={decodeURIComponent(packageId)} />
            };
        }
    });
}

export function initializeCveDrawer() {
    initializeDrawerContent();
}