import { getHttpService } from 'common/interface/services';
import { Registry } from '../registry/registry.interface';
import { KubernetesAccount, kubernetesAccountSummary } from './cluster.interface';

export const getClustersFromServer = async (): Promise<any[]> => {
    return await getHttpService().request('kubernetes/account', { method: 'GET' });
};

export const getAllKubernetesAccount = async (): Promise<Array<Registry>> => {
    return await getHttpService().request('kubernetes/account', { method: 'GET' });
};

export const saveCluster = async (data: any): Promise<any> => {
    return await getHttpService().request('kubernetes/account', { method: 'POST', data }, {}, (e: any) => {
        throw { message: e.response.data.message };
    });
};

export const deleteCluster = async (id: any): Promise<any> => {
    return await getHttpService().request(`kubernetes/account/${id}`, { method: 'DELETE' });
};

export const enableClusterImageAssurance = async (id: any): Promise<any> => {
    return await getHttpService().request(`kubernetes/account/${id}/imageAssurance/enable`, { method: 'POST' });
};

export const disableClusterImageAssurance = async (id: any): Promise<any> => {
    return await getHttpService().request(`kubernetes/account/${id}/imageAssurance/disable`, { method: 'POST' });
};

export const enableClusterAdmissionControl = async (id: any): Promise<any> => {
    return await getHttpService().request(`kubernetes/account/${id}/admissionControl/enable`, { method: 'POST' });
};

export const enableClusterRuntimeProtection = async (id: any): Promise<any> => {
    return await getHttpService().request(`kubernetes/account/${id}/runtimeProtection/enable`, { method: 'POST' });
};

export const enableClusterRuntimeProfiling = async (id: any): Promise<any> => {
    return await getHttpService().request(`kubernetes/account/${id}/runtimeProtection/profiling/enable`, { method: 'POST' });
};

export const getKubernetesAccountSummary = async (id: any): Promise<kubernetesAccountSummary | undefined> => {
    return await getHttpService().request(`kubernetes/account/${id}/accountSummary`, { method: 'GET' }, undefined, () => undefined);
};

export const getKubernetesAgentSummary = async (id: any): Promise<any> => {
    return await getHttpService().request(`kubernetes/account/${id}/agentSummary`, { method: 'GET' });
};

export const getKubernetesAccount = async (id: Pick<KubernetesAccount, 'id'>['id']): Promise<KubernetesAccount> => {
    return await getHttpService().request(`kubernetes/account/${id}`, { method: 'GET' });
};

export const updateKubernetesDescription = async (id: Pick<KubernetesAccount, 'id'>['id'], description: Pick<KubernetesAccount, 'description'>['description']): Promise<KubernetesAccount | undefined> => {
    return await getHttpService().request(`kubernetes/account/${id}/accountDescription?description=${description}`, { method: 'PUT' }, undefined, () => undefined);
};

export const updateKubernetesName = async (id: Pick<KubernetesAccount, 'id'>['id'], name: Pick<KubernetesAccount, 'name'>['name']): Promise<KubernetesAccount | undefined> => {
    return await getHttpService().request(`kubernetes/account/${id}/accountName?name=${name}`, { method: 'PUT' }, undefined, () => undefined);
};