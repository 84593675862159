import React from 'react';
import { useSelector } from 'react-redux';
import RegistriesActions from '../registries.actions';
import { RegistriesSelector } from '../registries.selectors';
import { K8SRootStore } from 'modules/workloads/initialize.redux';
import { ExtendedRegistry } from '../registries.interface';

export interface UseGetRegistryPayload {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    isDeleted: boolean;
    data?: ExtendedRegistry

    reloadData: () => void;
}

export type UseGetRegistry = (registryId: string) => UseGetRegistryPayload;

export const useGetRegistry: UseGetRegistry = (registryId) => {
    const { loading, error, deleted, data } = useSelector((state: K8SRootStore) => RegistriesSelector.getRegisry(state, registryId)) || {};

    const getData = (id: string) => RegistriesActions.getRegistry(id);
 
    React.useEffect(() => {
        if (!loading && !error && !data) {
            getData(registryId);
        }
    }, [loading, error, data, registryId]);

    const reloadData = React.useCallback(() => getData(registryId), [registryId]);

    return ({
        isLoading: !!loading && !data,
        isFetching: !!loading,
        isError: !!error,
        isDeleted: !!deleted,
        data,

        reloadData
    });
};