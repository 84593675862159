import React from 'react';
import { EventsTableRegistry } from 'common/module_interface/events/EventsTableRegistry';
import { Addin } from 'common/extensibility/AddinRegistry';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { EventAction, IEvent } from 'common/module_interface/events/Events';
import i18n from 'common/services/translations/translations';
import { EventCategory, IFindingModelAlertTypeString, IFindingModelOriginString, IFindingModelStatus } from 'common/components/Findings/Findings.interface';
import { hasPermissionToEventActions } from 'common/module_interface/events/utils';
import {
    EventOverviewTabProps,
    EventsDrawerAddingBase,
    EventsDrawerAddingBaseValueComponent,
    EventsDrawerRegistry
} from 'common/module_interface/events/EventsDrawerRegistry';
import { ExclusionRegistry } from 'common/module_interface/exclusion/exclusionRegistry';
import VulnerabilitySection from './overviewSections/VulnerabilitySection';
import MalwareFiles from './overviewSections/Malware/MalwareFiles';
import { EventModal, EventsModalsRegistry } from 'common/module_interface/events/EventsModalsRegistry';
import AddDenyRuleAction from '../pages/events/Modals/AddDenyRuleAction';
import { changeUrlParam } from 'common/utils/http';
import ServerlessPermissionSection from './overviewSections/ServerlessPermissionSection/ServerlessPermissionSection';
import ServerlessSecurityEvent from './overviewSections/ServerlessSecurityEvent';
import { getServerlessPermissionRemediationSections } from './overviewSections/ServerlessPermissionSection/ServerlessPermissionSection.utils';
import ServerlessTaskEvent from './overviewSections/ServerlessTaskEvent/ServerlessTaskEvent';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';
import IARuleCardComponent from './overviewSections/IARuleCardComponent';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';

const maliciousBehaviorSignatureEvent = 'MaliciousBehaviorSignatureEvent';
const maliciousBehaviorSignatureBundleId = '17';
const addDenyRuleAction = 'addDenyRule';

const canExecuteAction = (selectedRows: IEvent[]) : boolean => {
    if(!hasPermissionToEventActions()){
        return false;
    }
    if(selectedRows.length === 1) {
        const selectedRow = selectedRows[0];
        const isKubernetesRuntimeProtectionFinding = selectedRow.origin === IFindingModelOriginString.ContainersRuntimeProtection;
        return isKubernetesRuntimeProtectionFinding &&
            (selectedRow.ruleName === maliciousBehaviorSignatureEvent || selectedRow.bundleId?.toString() === maliciousBehaviorSignatureBundleId);
    }
    return false;
};

const actionsDefs = () => {
    const actions: Addin<ITableAction<IEvent>>[] = [
        {
            id: addDenyRuleAction,
            position: 1000,
            content: {
                id: addDenyRuleAction,
                name: i18n.t('ACTIONS.ADD_DENY_RULE', { ns: 'k8s_events' }),
                callback: () => changeUrlParam('modalType', addDenyRuleAction),
                buttonProps: (selectedRows) => ({
                    iconProps: { name: 'notFound' },
                    disabled: !(hasPermissionToEventActions() && canExecuteAction(selectedRows)),
                    tooltip: selectedRows.length !== 1 ? 'Select only one event to enable this action' : undefined,
                }),
            }
        },
    ];
    return actions;
};

const actionsMenuDefs = () => {
    const actions: Addin<EventsDrawerAddingBase<EventAction>>[] = [
        {
            id: addDenyRuleAction,
            content: {
                id: addDenyRuleAction,
                isRelevant: (event: IEvent) => event.status !== IFindingModelStatus.Archived && canExecuteAction([event]),
                getValue: () => ({
                    label: i18n.t('ACTIONS.ADD_DENY_RULE', { ns: 'k8s_events' }),
                    icon: 'addSection',
                    callback: () => changeUrlParam('modalType', addDenyRuleAction),
                }),
            },
        },
    ];
    return actions;
};


const modalsDefs: () => Addin<EventModal>[] = () => {
    return [
        {
            id: addDenyRuleAction,
            content: {
                id: addDenyRuleAction,
                modalComponent: AddDenyRuleAction
            }
        }
    ];
};

const initializeExclusionEvents = () => {
    ExclusionRegistry.addExcludeEventEnablers([{
        id: IFindingModelOriginString.ImageAssurance,
        content: {
            enabler: (event: IEvent) => {
                return event.origin === IFindingModelOriginString.ImageAssurance;
            }
        }
    }]);
};

const isRuntimeProtection = (origin: IEvent['origin']) => [
    IFindingModelOriginString.Serverless,
    IFindingModelOriginString.ContainersRuntimeProtection
].includes(origin);

enum SecurityEventTypes {
    Alert = 'Alert',
    Block = 'Block',
}

const chipsOverviewSections: Addin<EventsDrawerAddingBase<IChipProps>>[] = [
    {
        id: 'workloads-security-event-type-chip',
        position: 11,
        content: {
            id: 'workloads-security-event-type-chip',
            isRelevant: (event) => {
                return isRuntimeProtection(event.origin) && event.alertType === IFindingModelAlertTypeString.SecurityEvent && event.remediationActions.length === 2;
            },
            getValue: (event) => {
                const securityEventType = event.remediationActions?.[1];
                return {
                    label: securityEventType.toLowerCase() === SecurityEventTypes.Block.toLowerCase() ? 'Blocked' : securityEventType,
                    disableInteraction: true,
                    leadingIconProps: { name: 'securityEvent' },
                    color: securityEventType.toLowerCase() === SecurityEventTypes.Block.toLowerCase() ? 'alert' : 'warning',
                };
            },
        }
    },
];

const tabsOverviewSections: Addin<EventsDrawerAddingBaseValueComponent<React.FC<EventOverviewTabProps>>>[] = [
    {
        id: 'imageAssuranceRuleCard',
        position: 0,
        content: {
            id: 'imageAssuranceRuleCard',
            getValue: () => IARuleCardComponent,
            isRelevant: (event) => event.origin === IFindingModelOriginString.ImageAssurance,
        }
    },
    {
        id: 'vulnerability',
        position: 11,
        content: {
            id: 'vulnerability',
            getValue: () => VulnerabilitySection,
            isRelevant: (event) => event.category === EventCategory.ScanSummary
        }
    },
    {
        id: 'files',
        position: 12,
        content: {
            id: 'files',
            getValue: () => MalwareFiles,
            isRelevant: (event) => event.category === EventCategory.MaliciousFile || event.category === EventCategory.MaliciousURL || event.category === EventCategory.InsecureContent
        }
    },
    {
        id: 'workloads-task-event-attributes',
        position: 13,
        content: {
            id: 'workloads-task-event-attributes',
            getValue: () => ServerlessTaskEvent,
            isRelevant: (event) => isRuntimeProtection(event.origin) && event.alertType !== IFindingModelAlertTypeString.SecurityEvent,
        }
    },
    {
        id: 'workloads-permissions-remediation',
        position: 13,
        content: {
            id: 'workloads-permissions-remediation',
            getValue: () => ServerlessPermissionSection,
            isRelevant: (event) => isRuntimeProtection(event.origin) && getServerlessPermissionRemediationSections(event.bundleName).length > 0,
        }
    },
    {
        id: 'workloads-security-event',
        position: 13,
        content: {
            id: 'workloads-security-event',
            getValue: () => ServerlessSecurityEvent,
            isRelevant: (event) => isRuntimeProtection(event.origin) && event.alertType === IFindingModelAlertTypeString.SecurityEvent,
        }
    },
];

const detailsPanelItems: Addin<EventsDrawerAddingBase<BlockInfoProps>>[] = [
    {
        id: 'contentSeverity',
        content: {
            id: 'contentSeverity',
            getValue: (event) => {
                return {
                    title: i18n.t('CONTENT_SEVERITY', { ns: 'k8s_events' }),
                    info: event.entityObject.severity
                };
            },
            isRelevant: (event) => event.category === EventCategory.InsecureContent,
        },
    },
];

export function initializeEventsActions() {
    EventsTableRegistry.addActions(actionsDefs());
    EventsDrawerRegistry.addActions(actionsMenuDefs());
    EventsModalsRegistry.addModals(modalsDefs());
    EventsDrawerRegistry.addOverviewSections(tabsOverviewSections);
    EventsDrawerRegistry.addChips(chipsOverviewSections);
    EventsDrawerRegistry.addDetailsPanelItems(detailsPanelItems);
    initializeExclusionEvents();
}