import { Vendors } from 'common/consts/vendors';
import React from 'react';
import AwpService from '../awp.service';

export interface UseDeleteAgentlessAccountReq {
    onError?: (error: { message: string; }) => void;
    onSuccess?: () => void;
}

export interface UseDeleteAgentlessAccountPayload {
    isLoading: boolean;
    isDeleted: boolean;
    isError?: { message: string; };

    deleteAccount: (vendor: Vendors, cloudAccountId: string, forceDelete?: boolean) => Promise<void>;
    reset?: () => void;
}

export type UseDeleteAgentlessAccount = (req?: UseDeleteAgentlessAccountReq) => UseDeleteAgentlessAccountPayload;

export const useDeleteAgentlessAccount: UseDeleteAgentlessAccount = ({ onSuccess, onError } = {}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDeleted, setIsDeleted] = React.useState(false);
    const [isError, setIsError] = React.useState<{ message: string; }>();

    const deleteAccount = React.useCallback(async (vendor: Vendors, cloudAccountId: string, forceDelete?: boolean) => {
        setIsLoading(true);
        try {
            await AwpService.deleteWorkloadAgentessAccount(cloudAccountId, vendor, forceDelete);
            setIsDeleted(true);
            onSuccess?.();
        } catch (error: any) {
            setIsError({ message: error.message });
            onError?.({ message: error.message });
        }
        setIsLoading(false);
    }, [onSuccess, onError]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsDeleted(false);
        setIsError(undefined);
    }, []);

    return ({
        isLoading,
        isDeleted,
        isError,

        deleteAccount,
        reset
    });
};