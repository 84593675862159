import React from 'react';
import { Typography } from 'common/design-system/components-v2';
import { ALink } from 'common/components/ALink';
import { A } from 'common/components/Anchor/Anchor';
import { AssetLabel, AssetLabelTypes, CoreOwner, KubernetesGeneralSectionPodOwnerProps } from './PodOwner.types';
import { NULL_ASSET_LABEL } from './PodOwner.utils';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

const PodOwner: React.FC<KubernetesGeneralSectionPodOwnerProps> = ({ getUrl, owner, cloudAccountId }) => {
    const [assetLink, setAssetLink] = React.useState<AssetLabel>(NULL_ASSET_LABEL);
    const { t } = useTranslation(getK8sNamespace('protected-asset-details'));

    React.useEffect(() => {
        const getAndStoreAssetLink = async (owner: CoreOwner) => {
            try {
                const assetLinkLabel = await getUrl(owner, cloudAccountId);
                setAssetLink(assetLinkLabel === null ? { type: AssetLabelTypes.Label, data: owner.kind } : { type: AssetLabelTypes.Linkable, data: assetLinkLabel });
            } catch (error) {
                setAssetLink({ type: AssetLabelTypes.Label, data: owner.kind });
            }
        };

        if (owner?.kind && owner?.uid) {
            getAndStoreAssetLink({ kind: owner.kind, uid: owner.uid });
            return;
        }
        if (owner?.kind) {
            setAssetLink({ type: AssetLabelTypes.Label, data: owner.kind });
            return;
        }

    }, [cloudAccountId, getUrl, owner]);

    return (
        <Typography variant='bodyLg'>
            {assetLink.type === AssetLabelTypes.NotAvailable && t('overviewGeneral.notAvailable')}
            {assetLink.type === AssetLabelTypes.Label && assetLink.data}
            {assetLink.type === AssetLabelTypes.Linkable && (
                <ALink title={assetLink.data.label} as={A} href={assetLink.data.link}>
                    {assetLink.data.label}
                </ALink>
            )}
        </Typography>
    );
};

export default PodOwner;