import { IconButton, Stack, Typography } from 'common/design-system/components-v2';
import React from 'react';

export const Expandable: React.FC<{ children: React.ReactNode, title: string }> = ({ title, children }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    return (
        <Stack spacing={4} fullWidth>
            <Stack spacing={1} direction='row' alignItems='center'>
                <IconButton iconProps={{ name: isExpanded ? 'chevronDown' : 'chevronRight', size: 10 }} onClick={() => setIsExpanded(prev => !prev)} />
                <Typography variant='body'>{title}</Typography>
            </Stack>
            {isExpanded && children}
        </Stack>
    );
};
