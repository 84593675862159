import styled from 'styled-components';

export const VerticalLine = styled.div`{
  height: 100%;
  min-height: 160px;
  width: 5px;
  background: ${({ theme }) => `${theme.palette.surface.blue}`};
  border-radius: 10px;
}`;

export const CardWrapper = styled.div<{ selected: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  border: 1px solid ${({ selected, theme }) => selected ? theme.palette.surface.brandPrimary : `${theme.palette.border.normal}`};
  cursor: pointer;
  &:hover {
    box-shadow: ${({ theme }) => `${theme.shadows.hover}`};
    border: 1px solid ${({ selected, theme }) => selected ? theme.palette.surface.brandPrimary : `${theme.palette.border.strong}`};
  }
  background: ${({ selected, theme }) => selected ? theme.palette.surface.brandLight : 'inherit'};
`;