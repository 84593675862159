import React from 'react';
import { useTranslation } from 'react-i18next';
import { TenantIdInputProps } from './TenantIdInput.interface';
import { AuthFieldInput } from '../index';
import { tooltipUrls } from '../components.consts';

const TenantIdInput: React.FC<TenantIdInputProps> = (props) => {
    const { tenantId, setTenantId } = props;
    const { t } = useTranslation();

    return (
        <AuthFieldInput
            authFieldName={t('ON_BOARDING.REGISTRY.TENANT_ID')}
            tooltipUrl={tooltipUrls.TENANT_ID}
            authProp={tenantId}
            onChange={setTenantId}
        />
    );
};

export default TenantIdInput;