/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { getCloudAccountsService, ICloudAccount } from 'common/interface/data_services';
import { getUniqueValues } from 'common/utils/objectUtils';
import { useTranslation } from 'react-i18next';
import { CardItem } from 'common/components/policy/Card';
import CardsSelectComponent from 'common/components/policy/CardsSelectComponent';
import { OnValidate } from 'common/components/Wizard/Wizard';
import { WorkloadSupportedPlatforms } from 'common/components/policy/utils';

export interface EnvironmentPlatformsSelectionProps{
    selectedPlatformId?: string;
    onValidate:OnValidate;
    selectedItemChanged:Function;
}
export const WorkloadPlatformSelectionComponent: React.FC<EnvironmentPlatformsSelectionProps> = ({ selectedPlatformId, onValidate, selectedItemChanged }) => {
    const { t } = useTranslation('k8s_policy');
    const [relevantVendors,setRelevantVendors] = useState<CardItem[]>([]);

    const init = async () => {
        onValidate(true);
        const allCloudAccounts:ICloudAccount[] = await getCloudAccountsService().getAllCloudAccounts();
        const allPlatforms = allCloudAccounts.map((cloudAccount:ICloudAccount) => cloudAccount.platform);
        const platformsInUse = getUniqueValues(allPlatforms);
        const _vendors: CardItem[] = WorkloadSupportedPlatforms.filter((vendor: CardItem) => platformsInUse.includes(vendor.id));
        setRelevantVendors(_vendors);
        selectedItemChanged(_vendors[0]);
    };

    useEffect(() => {
        init();
    }, []);

    return(
        <span data-aid={'platform-selection'} >
            <CardsSelectComponent selectedItemId={selectedPlatformId} header={t('GENERAL.SELECT_PLATFORM')} items={relevantVendors} onSelectedItemChanged={selectedItemChanged}/>
        </span>
    );
};
