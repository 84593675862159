import { RootState } from 'common/services/store/store';
import { createSelector } from '@reduxjs/toolkit';
import { GetVulnerabilityScan } from '../../services/vulnerability/vulnerability.interface';
import { getCveAndPackageById as getCveAndPackageByIdUtils } from '../../services/vulnerability/utils';

export const getVulnerabilitiesObject = (state: RootState): GetVulnerabilityScan.Response | undefined => state.vulnerabilities.vulnerabilitiesData;

export const getVulnerabilitiesData = createSelector(
    [getVulnerabilitiesObject],
    (vulObject) => vulObject
);

export const getVulnerabilitiesIsLoading = (state: RootState) => state.vulnerabilities.isLoading;

export const getVulnerabilitiesError = (state: RootState) => state.vulnerabilities.error;

export const getCveAndPackageById = (cveId: string, packageId: string) => createSelector(
    [getVulnerabilitiesData],
    (vulnerabilitiesData) => {
        return getCveAndPackageByIdUtils(cveId, packageId, vulnerabilitiesData);
    }
);