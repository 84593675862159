import styled from 'styled-components';

export const DividerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DividerText = styled.span`
  margin-right: 10px;
`;

export const DividerLine = styled.hr`
  flex-grow: 1;
  border: 0;
  height: 1px;
  background-color: rgba(128, 128, 128, 0.5);
`;