
import { IPolicy } from 'common/interface/policy';
import { getHttpService } from 'common/interface/services';

export class WorkloadsPolicyService {

    async getPolicies(useCache= true): Promise<IPolicy[]> {
        try {
            return await getHttpService().get<IPolicy[]>({
                path: 'ShiftLeftPolicy',
                cachingConfig: { useCache } });
        } catch {
            return[];
        }
    }
}
