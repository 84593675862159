import { ICellRendererParams } from 'ag-grid-enterprise';
import { AssetTypes } from 'common/assets/assets.const';
import { getExternalAdditionalFieldValue } from 'common/module_interface/assets/utils';
import React from 'react';

const LearningCellRenderer: React.FC<ICellRendererParams> = (params) => {    

    const isLambdaAssetType = params?.data?.typeByPlatform === AssetTypes.AWS_LAMBDA;
    const isIncludesFSP = getExternalAdditionalFieldValue(params.data, 'IncludesFSP') === 'true';
    const isShowWhiteListProgress = isLambdaAssetType && isIncludesFSP;

    if(isShowWhiteListProgress) {
        const whiteListProgressProtegoValue = getExternalAdditionalFieldValue(params.data, 'WhiteListProgressProtego');
        return <div className="white-list-progress-container text-center relative flex w-full h-full items-center">
            {whiteListProgressProtegoValue && 
                <>
                    <div className='white-list-progress text-md bg-success h-7/6' style={{ width: `${whiteListProgressProtegoValue}%` }}></div>
                    <div className="absolute w-full">{whiteListProgressProtegoValue}</div>
                </>
            } 
            {!whiteListProgressProtegoValue &&
                <>
                    <div className='white-list-progress text-md bg-canvas h-7/6 w-full'></div>
                    <div className="absolute w-full">0</div>
                </>
            }
        </div>;
    } else {
        return null;
    } 
};

export default LearningCellRenderer;