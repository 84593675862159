import React, { useMemo } from 'react';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { WorkloadTypes } from 'modules/workloads/services/workload/workload.interface';
import WorkloadSbom from './WorkloadSbom/WorkloadSbom';
import SbomTableWithFilter from './SbomTableWithFilter/SbomTableWithFilter';

const SbomTab: React.FC<{ entity: ICloudEntityData }>= (props) => {
    const {
        entity: {
            cloudAccountId,
            protectedAsset: { type, id: dome9Id, name, entityId },
            typeByPlatform
        } } = props;

    const isWorkloadType = useMemo(() => Object.values(WorkloadTypes).includes(typeByPlatform)
        ,[typeByPlatform]);

    return (
        <>
            {isWorkloadType ?
                <WorkloadSbom environmentId={cloudAccountId} dome9Id={dome9Id} cloudEntityData={props.entity} /> :
                <SbomTableWithFilter environmentId={cloudAccountId} entityId={entityId} entityType={type} cloudEntityData={props.entity} entityName={name} />
            }
        </>
    );
};

export default SbomTab;