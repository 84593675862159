import React from 'react';
import { Stack } from 'common/design-system/components-v2';
import WidgetCard from 'common/components/Dashboard/WidgetCard/WidgetCard';
import { AccountRegionIssuesProps } from './AccountRegionIssues.types';
import EmptyState from 'common/components/EmptyState';
import KubernetesGeneralSectionTable from 'modules/workloads/protectedAsset/page/widgets/KubernetesGeneralSection/components/Table';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';

const AccountRegionIssues: React.FunctionComponent<AccountRegionIssuesProps> = ({ agentlessAccount }) => {
    const { accountIssues: { regions } } = agentlessAccount;
    const { t } = useTranslation(getK8sNamespace('awp'));

    const hasRegionsIssues = regions && Object.keys(regions).length > 0;
    const tableData = React.useMemo(() => {
        return Object.entries(regions).map(([region, issue]) => {
            return {
                region,
                issue: t(`awpTab.regionIssues.issueTypes.${issue.issueType}`, { context: agentlessAccount.provider })
            };
        });
    },[agentlessAccount.provider, regions, t]);

    if (!hasRegionsIssues) return (
        <Stack>
            <WidgetCard
                title={t('awpTab.regionIssues.title')}
                content={
                    <EmptyState
                        label={t('awpTab.regionIssues.noIssuesTitle')}
                        iconName='checkCircle'
                        iconSize={32}
                    />
                }
            />
        </Stack>
    );

    return (
        <KubernetesGeneralSectionTable
            data={tableData}
            headers={[{ field: 'region', headerName: t('awpTab.regionIssues.tableHeaders.region') }, { field: 'issue', headerName: t('awpTab.regionIssues.tableHeaders.issue') }]}
            title={t('awpTab.regionIssues.title')}
        />
    );
};

export default AccountRegionIssues;