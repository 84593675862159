import { Alert, AlertType } from '@dome9/berries/react-components';
import { Vendors } from 'common/consts/vendors';
import { SelectV2 as Select, Stack, Typography } from 'common/design-system/components-v2';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useHubs from '../../awpOnboardingAzure/hooks/useHubs';
import { StepProps } from '../awpOnboardingAws.types';

const SelectHubStep: React.FC<StepProps> = ({ formProps: { setValue, resetField, control, formState } }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));
    const { cloudAccountId } = useParams<{ cloudAccountId: string; }>();
    const { loading: isLoading, error: hasError, hubs } = useHubs(cloudAccountId, Vendors.AWS);
    React.useEffect(() => {
        resetField('hubId');
        setValue('hubId', null);
    }, [resetField, setValue]);
    const hubsOptions = hubs?.map(hub => ({ value: hub.cloudAccountId, label: hub.name })) ?? [];

    return (
        <Controller
            control={control}
            name='hubId'
            rules={{ required: { value: true, message: t('awpOnboarding.fields.required') } }}
            render={({ field: { value, onChange } }) => {
                return (
                    <Stack direction='column' spacing={1} fullWidth>
                        {hasError && <Alert type={AlertType.ERROR}>{t('awpOnboarding.errorLoadingHubs')}</Alert>}
                        <Typography>{t('awpOnboarding.azure.instructions.centralizedSelectionStep.chooseCentralizedAccount')}</Typography>
                        <Select
                            fullWidth
                            isMulti={false}
                            disabled={isLoading}
                            options={hubsOptions}
                            onChange={onChange}
                            placeholder={t(isLoading ? 'loading' : 'awpOnboarding.azure.instructions.centralizedSelectionStep.selectCentralizedAccount')}
                            isError={hasError || Boolean(formState.errors.hubId)}
                            value={value ?? undefined}
                        />
                    </Stack>
                );}}
        />
    );
};

export default SelectHubStep;