import { IOrganizationalUnit } from 'common/interface/data_services';
import { KubernetesAccountFeatureStatusChange } from 'modules/workloads/services/kubernetesAccount/kubernetesAccount.interface';
import { CreateServiceAccountResponse } from 'modules/workloads/services/service-account/service-account.interface';
import { KOBFeature } from '../../KubernetesOnBoarding.types';

export enum KOBMainLogicHookStatus {
    'GateringData',
    'GeneratingClusterName',
    'CreatingCluster',
    'UpdatingFeatures',
    'GeneratingServiceAccountName',
    'CreatingServiceAccount',
    'PendingForUserAction',
    'PendingForConnectivity',
    'ClusterConnected',
}

export interface ClusterData {
    id: string;
    name: string;
    description: string;
    organizationalUnitId?: string;
}

export interface KOBMainLogicHookRequest {
    features: Array<KOBFeature>;
}

export interface GenerateHelmCommandRequest {
    namespace: string;
    features: {
        imageAssuranceEnabled: boolean;
        admissionControlEnabled: boolean;
        runtimeProtectionEnabled: boolean;
        threatIntelligenceEnabled: boolean;
        imageAccessRuntimeMonitorEnabled: boolean;
    }
}

export interface KOBMainLogicHookResponse {
    status: KOBMainLogicHookStatus;
    loading: boolean;
    error: boolean;
    clusterName?: string;
    clusterDescription: string;
    clusterData?: ClusterData;

    serviceAccountName: string;
    serviceAccountData?: CreateServiceAccountResponse;

    flatOrganizationalUnits: Array<IOrganizationalUnit>;

    clusterNameChange: (name: string) => Promise<boolean>;
    clusterDescriptionChange: (name: string) => Promise<boolean>;
    organiztionUnitChange: (id: string) => Promise<boolean>;
    generateHelmCommand: (req: GenerateHelmCommandRequest) => string | undefined;
    generateNonHelmCommand: (req: GenerateHelmCommandRequest) => string | undefined;

    changeFeatureStatus: (req: KubernetesAccountFeatureStatusChange.Request) => Promise<boolean>;
    deleteClusterAndService: () => Promise<boolean>;
    startConnectivityIntervals: () => boolean;
}

export type KOBMainLogicHook = (req: KOBMainLogicHookRequest) => KOBMainLogicHookResponse;