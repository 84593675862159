import { getCheckpointUrl } from 'common/components/Forms/urls';

export const tooltipUrls = {
    AD_PULL_SECRET_NAME:
        'https://docs.microsoft.com/en-us/azure/container-registry/container-registry-auth-kubernetes#create-an-image-pull-secret',
    AWS_PULL_SECRET_NAME:
        '', // TODO: add link to how to get pull secret name explanation
    GCR_PULL_SECRET_NAME:
        'https://cloud.google.com/container-registry/docs/advanced-authentication#json-key',
    TENANT_ID:
        'https://docs.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-how-to-find-tenant',
    APP_CLIENT_ID:
        'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=acr_auth',
    JFROG_ARTIFACTORY: getCheckpointUrl('JFROG_ARTIFACTORY_PULL_SECRET_NAME'),
    HARBOR_PUll_SECRET_NAME: getCheckpointUrl('HARBOR_ARTIFACTORY_PULL_SECRET_NAME'),
    NEXUS_PUll_SECRET_NAME: getCheckpointUrl('SONATYPE_NEXUS_REGISTRY_DOCUMENTATION'),
    GITHUB_PUll_SECRET_NAME: getCheckpointUrl('GITHUB_REGISTRY_DOCUMENTATION'),
};