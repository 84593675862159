import React, { useState } from 'react';

const useWizardState = () => {
    const [currentStep, setCurrentStep] = useState<number>(0);

    const handleOnBackClicked = React.useCallback(() => {
        setCurrentStep(prevState => prevState - 1);
    },[]);

    const handleOnNextClicked = React.useCallback(() => {
        setCurrentStep(prevState => prevState + 1);
    },[]);
    
    return {
        currentStep,
        handleOnNextClicked,
        handleOnBackClicked
    };
};


export default useWizardState;