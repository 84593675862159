import { ManagedKubernetesType } from './KubernetesEnvironment.types';
import { TFunction } from 'i18next';
import { KubernetesAccount } from 'modules/workloads/services/kubernetesAccount/kubernetesAccount.interface';
import { deprecatedAlertKeyword } from './KubernetesEnvironment.consts';

export const platformByAssetType = (type?: ManagedKubernetesType) => {
    switch (type) {
        case ManagedKubernetesType.EKS: return 'aws';
        case ManagedKubernetesType.AKS: return 'azure';
        case ManagedKubernetesType.GKE: return 'gcp';
        default: return '';
    }
};

export const convertToProtectedAssetPageType = (type?: ManagedKubernetesType) => {
    switch (type) {
        case ManagedKubernetesType.EKS: return 'EksCluster';
        case ManagedKubernetesType.AKS: return 'AksCluster';
        case ManagedKubernetesType.GKE: return 'GkeCluster';
        default: return '';
    }
};

export const iconNameByType = (type?: ManagedKubernetesType) => {
    switch (type) {
        case ManagedKubernetesType.EKS: return 'aws__Architecture-Service-Icons__Arch_Containers__16__Arch_Amazon-Elastic-Kubernetes-Service_16';
        case ManagedKubernetesType.AKS: return 'old-pack__azure-icons__aks';
        case ManagedKubernetesType.GKE: return 'gke';
        case ManagedKubernetesType.OpenShift: return 'old-pack__kubernetes__icons__openshift';
        case ManagedKubernetesType.NA: return '';
        default: return 'kubernetes';
    }
};

export const generateManagedKubernetesLink = (
    cloudAccountId: string,
    type: string,
    entityId: string,
    platform: string
) => {
    return `/workload/workloads/protected-asset-generic?cloudAccountId=${cloudAccountId}&assetType=${type}&assetId=${entityId}&platform=${platform}`;
};

export function getClusterVersionInfo<ClusterData extends Pick<KubernetesAccount,'clusterVersion' | 'clusterVersionStatus'>>(clusterData: ClusterData, t: TFunction) {
    if (!clusterData.clusterVersion) {
        return 'N/A';
    }
    const alertKeyword = deprecatedAlertKeyword[clusterData.clusterVersionStatus];
    const deprecatedAlert = (alertKeyword && ` (${t(alertKeyword)})`) || '';
    return `${clusterData.clusterVersion}${deprecatedAlert}`;
}