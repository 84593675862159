import styled, { css } from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

export const ConfigPullSecretNameWrapper = styled(Stack)<{ isVisible: boolean; }>`
    max-width: 650px;

    ${p => p.isVisible ? css`
        opacity: 1;
        transition: opacity 100ms ease-in;
        height: auto;
    ` : css`
        opacity: 0;
        transition: all 100ms ease-in;
        height: 0;
    `};
`;

export const Title = styled.div`
  color: #333;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 20px;
`;
