import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import {
    GetAzureAccountDetails
} from './azure-cloud-account.interface';

const getAzureAccountDetails: GetAzureAccountDetails.Function = async (cloudAccountId) => {
    return await getHttpService().request<AxiosResponse<GetAzureAccountDetails.Response>>(
        `AzureCloudAccount/${cloudAccountId}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: false } },
        err => { throw err; }
    );
};

const AzureCloudAccountService = {
    getAzureAccountDetails,
};

export default AzureCloudAccountService;
