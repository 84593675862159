import { IFiltersValues, IRenderedFilter } from 'common/components/FilterPanel/FilterPanel.interface';

const FILTER_PANEL_QUERY_NAME = 'filterPanel';
const FILTER_PANEL_LOCAL_STORAGE_KEY = 'filterPanelK8sDashboard';

const isFilterShouldBeInQuery = (key: string, filterValue: any, filters: IRenderedFilter[]): boolean => {
    const filterFromList = filters.filter((filter) => filter.filterProps.key === key);
    return filterFromList[0] && filterFromList[0].filterUtils.shouldBeInURLParams
        ? filterFromList[0].filterUtils.shouldBeInURLParams(filterValue)
        : !!filterValue.length;
};

export const getFilterParamsFromURL = (): any => {
    const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get(FILTER_PANEL_QUERY_NAME) || '';
    return query ? JSON.parse(query) : {};
};

export const setFilterParamsToURL = (filtersValues: IFiltersValues, filters: IRenderedFilter[]) => {
    const filterValuesForParams = {} as IFiltersValues;
    const queryParams = new URLSearchParams(window.location.search);
    Object.keys(filtersValues).forEach((key) => {
        if (isFilterShouldBeInQuery(key, filtersValues[key], filters)) {
            filterValuesForParams[key] = filtersValues[key];
        }
    });
    queryParams.delete(FILTER_PANEL_QUERY_NAME);
    window.history.replaceState(
        null,
        '',
        `${window.location.origin + window.location.pathname}?${FILTER_PANEL_QUERY_NAME}=${JSON.stringify(
            filterValuesForParams
        )}&${queryParams.toString()}`
    );
};

export const setFiltersInitialValues = (filtersInitialValues: any, filtersList: any) => {
    return filtersList.map((filter: any) => {
        if (filtersInitialValues[filter.filterProps.key]) {
            filter.filterProps.value = filtersInitialValues[filter.filterProps.key];
        }
        return filter;
    });
};

export const getFiltersFromLocalStorage = () => {
    try {
        const fromLocalStorage = localStorage.getItem(FILTER_PANEL_LOCAL_STORAGE_KEY);
        if (fromLocalStorage) {
            return JSON.parse(fromLocalStorage);
        }
        return undefined;
    } catch (error) {
        return undefined;
    }
};

export const setFiltersToLocalStorage = (filters: IFiltersValues) => {
    const stringifyFilters = JSON.stringify(filters);
    localStorage.setItem(FILTER_PANEL_LOCAL_STORAGE_KEY, stringifyFilters);
};