import React, { useCallback, useMemo, useState } from 'react';
import { Table, Typography, Tooltip, Stack } from 'common/design-system/components-v2';
import DataVolume from 'common/components/DataVolume/DataVolume';
import { SortDirection, severityInfo } from '../../utils';
import { isFilterValuesEmpty } from '../../filterUtils';
import { Malwareflatten } from 'modules/workloads/services/vulnerability/vulnerability.interface';
import { ColDef, GridApi, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { ICellRendererParams } from 'ag-grid-enterprise';
import FilterPanelVulnerability from '../../FilterPanelVulnerability/FilterPanelVulnerability';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { ITableExportButton } from 'common/design-system/components-v2/Table/Table.types';
import { severityComparator } from 'modules/workloads/utils/versionsCompare';

let objectFilter: IFiltersValues = {};

interface ThreatsProps {
    threats: Array<Malwareflatten>,
    isThreats?: boolean,
    exportCveAsJSONAndCSV: Array<ITableExportButton>
}
const Threats: React.FC<ThreatsProps> = ({ threats, isThreats = true, exportCveAsJSONAndCSV }) => {
    const [itemDisplayedRowCount, setItemDisplayedRowCount] = useState<number>(0);
    const gridRef = React.useRef<GridApi>();
    const { t } = useTranslation(getK8sNamespace('vulnerability'));

    const getColumnDefs: ColDef<Malwareflatten>[] = [
        {
            field: 'severity',
            headerName: 'Severity',
            initialSort: SortDirection.ASC,
            comparator: severityComparator,
            maxWidth: 120,
            enableRowGroup: true,
            cellRenderer: (params: ICellRendererParams) => {
                if (!params.data) {
                    return params.value;
                }
                return <DataVolume dataInfo={severityInfo(params.value)}/>;
            }
        },
        {
            field: 'finding',
            headerName: 'Finding'
        },
        {
            field: 'type',
            enableRowGroup: true,
            maxWidth: 120,
            headerName: 'Type',
            valueGetter: (params) => params.data?.category || 'N/A',
            hide: !isThreats
        },
        {
            field: 'classification',
            maxWidth: 120,
            headerName: 'Classification',
            hide: !isThreats
        },
        {
            field: 'description',
            headerName: 'Description',
        },
        {
            field: 'filePath',
            enableRowGroup: true,
            headerName: 'File Path',
            cellRenderer: (params: ICellRendererParams) => {
                if (!params.data) {
                    return params.value;
                }
                return (
                    <Stack>
                        <Tooltip content={params.value}><span>{params.value}</span></Tooltip>
                    </Stack>
                );
            }
        },
        {
            field: 'lines',
            headerName: 'Lines'
        },
    ];

    const onGridReady = useCallback(
        (event: GridReadyEvent) => {
            gridRef.current = event.api;
        },
        [],
    );

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            sortable: true,
        };
    }, []);

    const isExternalFilterPresent = useCallback(() => {
        return objectFilter && !isFilterValuesEmpty(objectFilter);
    }, []);

    const doesExternalFilterPass = useCallback(
        (node: IRowNode) => {
            let doesExternalFilterPassValue = true;
            if (node.data) {
                Object.keys(objectFilter).forEach((key) => {
                    if(!objectFilter[key] || objectFilter[key].length === 0) return;
                    if(key !== 'free-text') {
                        const values: Array<string> = objectFilter[key];
                        if(typeof node.data[key] === 'boolean') {
                            const doesFilterPassValues = values.some(value => node.data[key].toString() === value);
                            doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassValues;
                        } else {
                            const doesFilterPassValues = values.some(value => node.data[key] === value);
                            doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassValues;
                        }
                    } else {
                        const doesFilterPassFreeText = Object.values(node.data).some((item: any) => {
                            if(!item) return false;
                            return item.toLowerCase().includes(objectFilter[key].toLowerCase());
                        });
                        doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassFreeText;
                    }
                });
            }
            return doesExternalFilterPassValue;
        },
        []
    );

    const externalFilterChanged = useCallback((newValue: IFiltersValues) => {
        objectFilter = newValue;
        gridRef.current?.onFilterChanged();
        setItemDisplayedRowCount(gridRef.current?.getDisplayedRowCount() || 0);
    }, []);

    return (
        <>
            <FilterPanelVulnerability isRemediations={false} data={threats} onFilterChange={externalFilterChanged}></FilterPanelVulnerability>
            <Table
                tableId='threats'
                saveColumnsState
                exportButtons={exportCveAsJSONAndCSV}
                gridOptions={{
                    rowData: threats,
                    columnDefs: getColumnDefs,
                    onGridReady,
                    doesExternalFilterPass,
                    isExternalFilterPresent,
                    defaultColDef
                }}
                footer={<Typography>{t('VulnerabilityTable.footer', { itemDisplayedRowCount: itemDisplayedRowCount, totalRowCount: threats.length || 0 })}</Typography>}
            />
        </>
    );
};

export default Threats;