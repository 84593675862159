import React from 'react';
import ShiftLeftActions, { UpdateShiftLeftReq } from '../shiftLeft.actions';

export interface UseUpdateClusterReq {
    onError?: (error: { message: string; }) => void;
    onSuccess?: () => void;
}

export interface UseUpdateClusterPayload {
    isLoading: boolean;
    isSuccess: boolean;
    isError?: { message: string; };

    updateShiftLeft: (shiftLeftId: string, updatePayload: UpdateShiftLeftReq) => Promise<void>;
    reset?: () => void;
}

export type UseUpdateCluster = (req?: UseUpdateClusterReq) => UseUpdateClusterPayload;

export const useUpdateShiftLeft: UseUpdateCluster = ({ onSuccess, onError } = {}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isError, setIsError] = React.useState<{ message: string; }>();

    const updateShiftLeft = React.useCallback(async (clusterId: string, updatePayload: UpdateShiftLeftReq) => {
        setIsLoading(true);
        try {
            const result = await ShiftLeftActions.updateShiftLeft(clusterId, updatePayload);
            setIsSuccess(result);
            onSuccess?.();
        } catch (error: any) {
            setIsError({ message: error.message });
            onError?.({ message: error.message });
        }
        setIsLoading(false);
    }, [onSuccess, onError]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(undefined);
    }, []);

    return ({
        isLoading,
        isSuccess,
        isError,

        updateShiftLeft,
        reset
    });
};