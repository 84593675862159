import styled from 'styled-components';

export const FooterWrapper = styled.div`
  display: flex;
  background-color: #f7f7f7;
  width: 100%;

  align-self: flex-end;

  .footer-sidebar {
    width: 188px;
    background-color: #2e3f58;
  }
  .footer-actions {
    flex: 1;
    display: flex;
    height: 50px;
    width: 100%;
    padding: 10px;
    border-top: 1px solid #d2d5d9;
    justify-content: flex-end;
    gap: 10px;
  }
`;
