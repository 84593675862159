import styled from 'styled-components';

const Wrapper = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => `${theme.spacing(4)}${theme.units}`};
`;

const Table = styled.div`
    min-height: 100px;
    width: 100%;
    border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
`;

const BladeStyles = {
    Wrapper,
    Table,
};

export default BladeStyles;