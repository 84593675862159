import {
    getHttpService,
    getPolicyService,
    getRulesetService,
    getShiftLeftService,
    ISetPolicyResponse
} from 'common/interface/services';
import { IPolicy } from 'common/interface/policy';
import { Vendors } from 'common/consts/vendors';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { TargetTypesEnum, ImageAdmissionItem } from './interface';
export const updatePolicy = async(data: IPolicy[]): Promise<ISetPolicyResponse[]> => {
    return await getHttpService().put< Promise<ISetPolicyResponse[]>>('kubernetes/imageAssurance/policy/', { data });
};
export const saveVulnerabilitiesPolicyChanges = async (selectedNotifications: ListItem[],selectedOUs: ListItem[], selectedRulesets: ListItem[],selectedUnScannedAdmission: ImageAdmissionItem, selectedNonCompliantAdmission:ImageAdmissionItem) => {
    const policies:IPolicy[] = [];
    const allRulesets = await getRulesetService().getAllRulesets();

    if (selectedOUs.length && selectedRulesets.length && selectedNotifications.length ){
        for (const ou of selectedOUs) {
            for (const ruleset of selectedRulesets) {
                const rulesetId = +ruleset.id;
                const _ruleset = allRulesets.find((_ruleset) => _ruleset.id === rulesetId);
                const policy:IPolicy = {
                    targetId: ou.id,
                    rulesetPlatform: _ruleset?.cloudVendor,
                    targetType: TargetTypesEnum.organizationalUnit,
                    rulesetId: rulesetId,
                    notificationIds: selectedNotifications.map((notification:ListItem) => notification.id),
                    admissionControlUnScannedAction: selectedUnScannedAdmission?.type,
                    admissionControllerAction: selectedNonCompliantAdmission?.type
                };

                if(rulesetId < 0) {
                    policy.rulesetVersion = ruleset.selectedVersion?.version;
                }

                policies.push(policy);
            }
        }
        if(policies?.length){
            const { imageAssurancePolicies, platformPolicies } = policies.reduce((acc, policy) => {
                if (policy.rulesetPlatform === Vendors.IMAGE_ASSURANCE) {
                    acc.imageAssurancePolicies.push(policy);
                } else {
                    acc.platformPolicies.push(policy);
                }
                return acc;
            }, { imageAssurancePolicies: [] as IPolicy[], platformPolicies: [] as IPolicy[] });

            const platformRequestPromise = platformPolicies.length ? getPolicyService().setPolicy(platformPolicies) : Promise.resolve();
            const imageAdmissionRequestPromise = imageAssurancePolicies.length ? getShiftLeftService().setShiftLeftPolicy(imageAssurancePolicies): Promise.resolve();
            await Promise.all([platformRequestPromise, imageAdmissionRequestPromise]);
        }
    }
};

export const getAdmissionControlPolicy = async (id: string, useCache?:boolean) :Promise<IPolicy| undefined> => {
    const results = await getAdmissionControlPolicies(useCache);
    return results.find(policy => policy.id === id);
};
export const getAdmissionControlPolicies = async (useCache = true) =>{
    try {
        return await getHttpService().get<IPolicy[]>({
            path: 'kubernetes/admissionControl/policy',
            cachingConfig: { useCache } });
    } catch {
        return[];
    }
};

export const setAdmissionControlPolicies = async (data: IPolicy[]) => {
    return await getHttpService().post<Promise<ISetPolicyResponse[]>>('kubernetes/admissionControl/policy', { data });
};

export const saveAdmissionPolicyChanges = async (selectedNotifications: ListItem[],selectedOUs: ListItem[], selectedRulesets: ListItem[], selectedAction: ImageAdmissionItem) => {
    const policies:IPolicy[] = [];
    const allRulesets = await getRulesetService().getAllRulesets();
    if (selectedOUs.length && selectedRulesets.length && selectedNotifications.length && selectedAction){
        for (const ou of selectedOUs) {
            for (const ruleset of selectedRulesets) {
                const rulesetId = +ruleset.id;
                const _ruleset = allRulesets.find((_ruleset) => _ruleset.id === rulesetId);
                const policy:IPolicy = {
                    action: selectedAction.type,
                    targetId: ou.id,
                    rulesetPlatform: _ruleset?.cloudVendor,
                    targetType: TargetTypesEnum.organizationalUnit,
                    rulesetId: rulesetId,
                    notificationIds: selectedNotifications.map((notification:ListItem) => notification.id),
                };
                if(rulesetId < 0) {
                    policy.rulesetVersion = ruleset.selectedVersion?.version;
                }
                policies.push(policy);
            }
        }
        if (policies?.length) await setAdmissionControlPolicies(policies);
    }
};