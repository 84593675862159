import React from 'react';
import { AgGridReact, AgGridReactProps, AgReactUiProps } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { AgentsTableProps } from './AgentsTable.types';
import { AgentSummaryComponent, KubernetesAgentSummaryDetails, KubernetesAgentSummaryType } from 'modules/workloads/services/kubernetesAccount/kubernetesAccount.interface';
import AgentsTableStyles from './AgentsTable.styles';
import { Icon } from '@dome9/berries/react-components';
import { Button, Stack } from 'common/design-system/components-v2';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { useUnlinkEnvironment } from 'modules/workloads/reducers/registries/hooks/useUnlinkEnvironment';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { useGetCluster } from 'modules/workloads/reducers/cluster-new/hooks/useGetCluster';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const AgentsTable: React.FunctionComponent<AgentsTableProps> = props => {
    const { agents, clusterId } = props;
    const [unlinkModal, setUnlinkModal] = React.useState<AgentSummaryComponent>();
    const { reloadData: reloadClusterData } = useGetCluster(clusterId);
    const { t } = useTranslation(['k8s_common', 'k8s_cluster']);

    const handleOnUnlinkSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            text: t('k8s_cluster:bladeUnlinkConfirmation.unLinkSuccessMessage'),
        });
        setUnlinkModal(undefined);
        reloadClusterData();
    };

    const handleOnUnlinkError = ({ message }: { message: string }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('k8s_cluster:bladeUnlinkConfirmation.unLinkErrorMessage'),
            text: message,
        });
    };

    const { unLinkEnvironment, isLoading } = useUnlinkEnvironment({ onError: handleOnUnlinkError, onSuccess: handleOnUnlinkSuccess });

    const displayDate = (params: any) => params.value ? dayjs(new Date(params.value)).format('MMM D, YYYY h:mm A Z') : 'N/A';

    const flatAgentsData = agents.reduce<Array<KubernetesAgentSummaryDetails & { agentType: KubernetesAgentSummaryType }>>((acc, agent) => {
        return [...acc, ...agent.agentSummary.map(agentS => ({ ...agentS, agentType: agent.agentType }))];
    }, []);

    const agGridProps: AgGridReactProps & AgReactUiProps = React.useMemo(() => ({
        masterDetail: true,
        getRowId: (params: any) => `${params.data.agent}_${params.data.status}_${params.data.version}`,
        isRowMaster: (rowData: KubernetesAgentSummaryDetails) => rowData.components.length > 0,
        columnDefs: [
            { field: 'agent', headerName: t('k8s_cluster:bladeAgentTable.agent'), cellRenderer: 'agGroupCellRenderer' },
            { field: 'status', headerName: t('k8s_cluster:bladeAgentTable.status'), valueFormatter: (params: any) => t(`k8s_cluster:bladeAgentTable.statuses.${params.value}`, { defaultValue: params.value }) },
            {
                field: 'lastCommunicationDate',
                headerName: t('k8s_cluster:bladeAgentTable.lastCommunication'),
                valueFormatter: displayDate,
            },
            { field: 'version', headerName: t('k8s_cluster:bladeAgentTable.version') },
            {
                field: 'description',
                headerName: t('k8s_cluster:bladeAgentTable.description'),
                tooltipField: 'description',
                valueFormatter: (params: any) => params.data.status === 'OK' ? t('k8s_cluster:bladeAgentTable.noIssues') : params.value || 'N/A',
            },
        ],
        defaultColDef: {
            flex: 1,
            suppressMenu: true,
            suppressMovable: true,
            lockPosition: true,
            resizable: true
        },
        headerHeight: 25,
        detailCellRendererParams: {
            detailGridOptions: {
                tooltipShowDelay: 100,
                enableBrowserTooltips: true,
                columnDefs: [
                    { field: 'name', headerName: 'Image Source', cellRenderer: (params: any) => {
                        if (params.data && params.data.id && params.value !== 'Local Cluster') {
                            return (
                                <AgentsTableStyles.aLink href={`/workload/environments/containerregistry/${params.data.id}`}>
                                    <Stack direction='row' spacing={1} fullHeight alignItems='center' >
                                        <Icon name='docker' size={12} />
                                        <span>{params.data.name}</span>
                                    </Stack>
                                </AgentsTableStyles.aLink>
                            );
                        }
                        return t('k8s_cluster:bladeAgentTable.localCluster');
                    } },
                    { 
                        field: 'status', 
                        headerName: t('k8s_cluster:bladeAgentTable.status'), 
                        valueFormatter: (params: any) => params.value ? t(`k8s_cluster:bladeAgentTable.statuses.${params.value}`, { defaultValue: params.value }) : 'N/A' 
                    },
                    {
                        field: 'lastOkDate',
                        headerName: t('k8s_cluster:bladeAgentTable.lastCommunication'),
                        valueFormatter: displayDate
                    },
                    {
                        field: 'description',
                        headerName: t('k8s_cluster:bladeAgentTable.description'),
                        tooltipField: 'description',
                        valueFormatter: (params: any) => params.data.status === 'OK' ? t('k8s_cluster:bladeAgentTable.noIssues') : params.value || 'N/A',
                    },
                    { field: '', headerName: t('k8s_cluster:bladeAgentTable.actions'), cellRenderer: (params: any) => {
                        if (params.data.name === 'Local Cluster') return;
                        return (
                            <Button iconProps={{ name: 'unlink' }} variant='text' onClick={() => setUnlinkModal(params.data) }>{t('k8s_cluster:bladeAgentTable.unlink')}</Button>
                        );
                    } 
                    },
                    
                ],
                defaultColDef: {
                    flex: 1,
                    suppressMenu: true,
                    suppressMovable: true,
                    lockPosition: true
                },
                headerHeight: 25,
            },
            getDetailRowData: (params: any) => {
                params.successCallback(params.data.components);
            },
            detailRowAutoHeight: true,
        },
        detailRowAutoHeight: true,
        domLayout: 'autoHeight',
        sideBar: false,
        lockPinned: true,
        tooltipShowDelay: 100,
        enableBrowserTooltips: true,
        rowData: flatAgentsData.map(agent => ({
            agent: agent.agentIdentifier ? `${agent.agentType}: ${agent.agentIdentifier}` : agent.agentType || 'N/A',
            status: agent.status || 'N/A',
            lastCommunicationDate: agent.lastCommunicationDate,
            version: agent.version || 'N/A',
            description: agent.description,
            components: agent.components || [],
        }))
    }), [flatAgentsData, t]);
    
    return (
        <>
            <AgentsTableStyles.CustomAgGrid>
                <AgGridReact {...agGridProps} className='ag-theme-alpine' />
            </AgentsTableStyles.CustomAgGrid>
            {unlinkModal && (
                <ConfirmationModal
                    title={t('k8s_cluster:bladeUnlinkConfirmation.title')}
                    text={t('k8s_cluster:bladeUnlinkConfirmation.text', { name: unlinkModal.name })}
                    isLoading={isLoading}
                    onClose={() => setUnlinkModal(undefined)}
                    onCancel={() => setUnlinkModal(undefined)}
                    submitBtnText={t('k8s_common:save')}
                    onConfirm={() => unLinkEnvironment(unlinkModal.id, clusterId)}
                />
            )}
        </>
    );
};

export default AgentsTable;