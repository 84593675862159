import { Button } from 'common/design-system/components-v2';
import styled from 'styled-components';


export const ChipWidgetButton = styled(Button)`
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 2px;
    padding-bottom: 2px;
`;
