import { ScanMode } from 'modules/workloads/services/awp/awp.interface';
import { ScanModeCardsSection } from './scanModeSelector.types';

export const awsScanModeCardSections: Array<ScanModeCardsSection> = [
    {
        title: 'awpOnboarding.scanMode.card.inAccount',
        cards: [
            {
                value: ScanMode.InAccount,
                label: 'awpOnboarding.scanMode.inAccount.title',
                description: 'awpOnboarding.scanMode.inAccount.awsDescription',
            },
            {
                value: ScanMode.InAccountSub,
                label: 'awpOnboarding.scanMode.inAccountSub.title',
                description: 'awpOnboarding.scanMode.inAccountSub.awsDescription',
                isPreview: true,
            }
        ]
    },
    {
        title: 'awpOnboarding.scanMode.card.saas',
        cards: [
            {
                value: ScanMode.Saas,
                label: 'awpOnboarding.scanMode.saas.title',
                description: 'awpOnboarding.scanMode.saas.awsDescription',
            },
        ],
    },
    {
        title: 'awpOnboarding.scanMode.card.centralized',
        cards: [
            {
                value: ScanMode.InAccountHub,
                label: 'awpOnboarding.scanMode.inAccountHub.title',
                description: 'awpOnboarding.scanMode.inAccountHub.awsDescription',
                isPreview: true,
            },
        ],
    }
];