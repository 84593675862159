import React from 'react';
import { OverviewProps, getIncludedEntityTypes } from './Assets.types';
import { AssetsStyled } from './Assets.styled';
import DynamicProtectedAssetsTable from 'common/components/ProtectedAssets/DynamicProtectedAssetsTable';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { assetsTableBaseColumns } from 'modules/workloads/protectedAsset/table/ProtectedAssetsList/types/columns';
import { assetsTableBaseFilters } from 'modules/workloads/protectedAsset/table/ProtectedAssetsList/types/filters';

const Assets: React.FunctionComponent<OverviewProps> = ({ id }) => {

    const defaultFilters = React.useMemo<IFiltersValues>(() => {
        return ({
            fields: [{ name: 'cloudAccountId', value: id }]
        });
    }, [id]);

    return (
        <AssetsStyled.AssetsWrapper>
            <DynamicProtectedAssetsTable
                defaultFilters={defaultFilters}
                columns={assetsTableBaseColumns}
                includedEntityTypes={getIncludedEntityTypes()}
                tableId='k8s-assets-filter-panel-id'
                withFilterPanel={{
                    filters: assetsTableBaseFilters
                }}
            />
        </AssetsStyled.AssetsWrapper>
    );
};

export default Assets;
