import { IAsset, UrlFuncResult } from 'common/assets/common.assets';
import { toQueryString } from 'common/utils/http';
import ImageLinkRenderer from '../../CellRenderers/ImageLinkRenderer';
import EnvironmentCellRenderer from '../../CellRenderers/EnvironmentCellRenderer';
import { IColumnUsageDef } from 'common/interface/general';

export const assetsTableBaseColumns: Array<IColumnUsageDef & { order?: number; }> = [
    {
        id: 'entity',
        order: 0,
        colDefOverride: {
            cellRendererParams: {
                generateUrl: (asset: IAsset, assetUrl: UrlFuncResult): string => {
                    const url = `/workload/workloads/protected-asset${asset?.isGenericObject ? '-generic' : ''}`;
                    const query = {
                        ...(assetUrl?.query as object || {}),
                        type: asset?.typeNumber,
                    };
                    const queryParams = toQueryString({ query });
                    return `${url}?${queryParams}`;
                },
            }
        }
    },
    {
        id: 'ermRiskScore',
        order: 2,
        colDefOverride: {
            initialSort: 'desc',
            initialHide: true,
        }
    },
    {
        id: 'ermMisconfigurations',
        colDefOverride: {
            initialHide: true,
        }
    },
    {
        id: 'cves',
        colDefOverride: {
            initialHide: false,
        }
    },
    {
        id: 'threats',
        colDefOverride: {
            initialHide: false,
        }
    },
    {
        id: 'secrets',
        colDefOverride: {
            initialHide: false,
        }
    },
    {
        id: 'workloads',
        colDefOverride: {
            width: 100
        }
    },
    { id: 'namespace' },
    { id: 'type' },
];

export const imagesTableBaseColumns: Array<IColumnUsageDef & { order?: number; }> = [
    {
        id: 'riskScore',
        order: 0,
        colDefOverride: {
            initialSort: 'desc'
        }
    },
    { id: 'registry', order: 1 },
    {
        id: 'repository',
        order: 2,
        colDefOverride: {
            cellRenderer: ImageLinkRenderer
        }
    },
    {
        id: 'imageTag',
        order: 3,
        colDefOverride: {
            cellRenderer: ImageLinkRenderer
        }
    },
    { id: 'platform' },
    { 
        id: 'isRunning',
        colDefOverride: {
            enableRowGroup: true,
        }
    },
    {
        id: 'cves',
        colDefOverride: {
            initialHide: false,
        }
    },
    {
        id: 'threats',
        colDefOverride: {
            initialHide: false,
        }
    },
    {
        id: 'secrets',
        colDefOverride: {
            initialHide: false,
        }
    },
    { id: 'image_scanStatus' },
    { id: 'image_scanStatusDetails' },
    {
        id: 'imageId',
        colDefOverride: {
            cellRenderer: ImageLinkRenderer,
            initialHide: true
        }
    },
    {
        id: 'digest',
        colDefOverride: {
            cellRenderer: ImageLinkRenderer,
            initialHide: true
        }
    },
    { id: 'lastRunningDate', colDefOverride: { initialHide: true } },
    { id: 'cluster', colDefOverride: { initialHide: true } },
];

export const mainPageTableBaseColumns: Array<IColumnUsageDef & { order?: number; }> = [
    {
        id: 'environment',
        order: 1,
        colDefOverride: {
            cellRenderer: EnvironmentCellRenderer,
            enableRowGroup: true,
        },
    },
    {
        id: 'platform',
        order: 2,
        colDefOverride: {
            initialHide: true,
        }
    },
    {
        id: 'organizationalUnit',
        order: 3,
        colDefOverride: {
            initialHide: true,
        }
    },
];

export const sortTableColumns = (columns: Array<IColumnUsageDef & { order?: number; }>): Array<IColumnUsageDef> => {
    return columns.sort((a, b) => {
        if (typeof a.order === 'number' && typeof b.order === 'number') {
            return a.order > b.order ? 1 : -1;
        }
        if (typeof a.order === 'number') {
            return -1;
        }
        if (typeof b.order === 'number') {
            return 1;
        }
        return 0;
    }).map(column => {
        delete column.order;
        return column;
    });
};
