import { registries_actions } from '.';
import { batch } from 'react-redux';
import { K8sStoreService } from 'modules/workloads/initialize.redux';
import ContainerRegisryService from 'modules/workloads/services/containerRegistry/containerRegistry.service';
import { ContainerRegistryUserConfigurations, LinkedScanningEnvironment } from 'modules/workloads/services/containerRegistry/containerRegistry.interface';
import { getMessageFromError } from 'modules/workloads/utils/errorMessage';
import { AxiosResponse } from 'axios';
import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from 'common/interface/IFrame.message.model';
import { getWebAppIframeService } from 'common/interface/services';

type GetRegistries = () => Promise<boolean>;
const getRegistries: GetRegistries = async () => {
    const { dispatch } = K8sStoreService().getReduxTools();
    try {
        dispatch(registries_actions.setLoading(true));
        const registries = await ContainerRegisryService.getAllRegistryAccounts();
        batch(() => {
            dispatch(registries_actions.setData(registries.data));
            dispatch(registries_actions.setError(false));
            dispatch(registries_actions.setLoading(false));
        });
        return true;
    } catch (error) {
        batch(() => {
            dispatch(registries_actions.setError(true));
            dispatch(registries_actions.setLoading(false));
        });
        throw Error(getMessageFromError(error));
    }
};

type GetRegistry = (registryId: string) => Promise<boolean>;
const getRegistry: GetRegistry = async (registryId) => {
    const { dispatch } = K8sStoreService().getReduxTools();
    try {
        dispatch(registries_actions.setAccountLoading({ registryId, loading: true }));
        const accountData = await Promise.all([
            ContainerRegisryService.getRegistryAccount(registryId),
            ContainerRegisryService.getRegistryAccountSummary(registryId),
            ContainerRegisryService.getRegistryAgentSummary(registryId)
        ]);
        if (!accountData[0]?.data || !accountData[1]?.data || !accountData[2]?.data) throw new Error('');
        batch(() => {
            dispatch(registries_actions.setAccountData({
                registryId, account: accountData[0].data,
                accountSummary: accountData[1].data,
                agentSummary: accountData[2].data
            }));
            dispatch(registries_actions.setAccountError({ registryId, error: false }));
            dispatch(registries_actions.setAccountLoading({ registryId, loading: false }));
        });
        return true;
    } catch (error) {
        batch(() => {
            dispatch(registries_actions.setAccountError({ registryId, error: true }));
            dispatch(registries_actions.setAccountLoading({ registryId, loading: false }));
        });
        throw Error(getMessageFromError(error));
    }
};

type GetRegistrySettings = (registryId: string) => Promise<boolean>;
const getRegistrySettings: GetRegistrySettings = async (registryId) => {
    const { dispatch } = K8sStoreService().getReduxTools();
    try {
        dispatch(registries_actions.setAccountSettingsLoading({ registryId, loading: true }));
        const accountSettings = await ContainerRegisryService.getRegistryAccountSettings(registryId);
        batch(() => {
            dispatch(registries_actions.setAccountSettingsData({ registryId, accountSettings: accountSettings.data }));
            dispatch(registries_actions.setAccountSettingsError({ registryId, error: false }));
            dispatch(registries_actions.setAccountSettingsLoading({ registryId, loading: false }));
        });
        return true;
    } catch (error) {
        batch(() => {
            dispatch(registries_actions.setAccountSettingsError({ registryId, error: true }));
            dispatch(registries_actions.setAccountSettingsLoading({ registryId, loading: false }));
        });
        throw Error(getMessageFromError(error));
    }
};

type LinkCluster = (registryId: string, request: LinkedScanningEnvironment.Request) => Promise<boolean>;
const linkCluster: LinkCluster = async (registryId, request) => {
    try {
        await ContainerRegisryService.linkedScanningEnvironment(registryId, request);
        getRegistries();
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

type UnLinkCluster = (registryId: string, linkedEnvironmentId: string) => Promise<boolean>;
const unLinkCluster: UnLinkCluster = async (registryId, linkedEnvironmentId) => {
    try {
        await ContainerRegisryService.unLinkScanningEnvironment(registryId, linkedEnvironmentId);
        getRegistries();
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

type DeleteRegistry = (registryId: string) => Promise<boolean>;
const deleteRegistry: DeleteRegistry = async (registryId) => {
    try {
        const { dispatch } = K8sStoreService().getReduxTools();
        await ContainerRegisryService.deleteAccount(registryId);
        dispatch(registries_actions.setAccountDeleted({ registryId }));
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

export interface UpdateRegistryReq {
    name?: string;
    organizationalUnitId?: string;
    description?: string;
}
type UpdateRegistry = (registryId: string, req: UpdateRegistryReq) => Promise<boolean>;
const updateRegistry: UpdateRegistry = async (registryId, { name, organizationalUnitId, description }) => {
    try {
        const { state } = K8sStoreService().getReduxTools();
        const { name: oldName, organizationalUnitId: oldOrganizationalUnitId, description: oldDescription } = state.k8s_registry.extendedRegistries[registryId]?.data?.account || {};

        const updatePromiseArr: Array<Promise<AxiosResponse<any>>> = [];

        if (name && name !== oldName) {
            updatePromiseArr.push(ContainerRegisryService.updateName(registryId, name));
        }

        if (organizationalUnitId && organizationalUnitId !== oldOrganizationalUnitId) {
            updatePromiseArr.push(ContainerRegisryService.updateOrganizationalUnitId(registryId, organizationalUnitId));
        }
        if (description !== undefined && description !== oldDescription) {
            updatePromiseArr.push(ContainerRegisryService.updateDescription(registryId, description || ''));
        }

        const result = await Promise.all(updatePromiseArr);
        
        if (result.length > 0) {
            getWebAppIframeService().emitMessage(
                new IframeMessageModel({
                    action: IFRAME_MESSAGE_ACTIONS.UPDATE_TABPANEL_TABNAME,
                    data: { id: registryId, newName: name }
                }),
            );
            getWebAppIframeService().emitMessage(
                new IframeMessageModel({
                    action: IFRAME_MESSAGE_ACTIONS.RELOAD_STATE,
                }),
            );
            getRegistry(registryId);
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

type UpdateRegistrySettings = (registryId: string, request: ContainerRegistryUserConfigurations) => Promise<boolean>;
const updateRegistrySettings: UpdateRegistrySettings = async (registryId, request) => {
    try {
        await ContainerRegisryService.updateRegistryAccountSettings(registryId, request);
        getRegistrySettings(registryId);
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

const RegistriesActions = {
    getRegistries,
    getRegistry,
    getRegistrySettings,
    linkCluster,
    unLinkCluster,
    deleteRegistry,
    updateRegistry,
    updateRegistrySettings
};

export default RegistriesActions;