import { CGColDef, ColumnAdditionalFieldSource, ColumnType } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { getExternalAdditionalFieldValueGetter, getExternalAdditionalFieldValue } from 'common/module_interface/assets/utils';
import i18n from 'common/services/translations/translations';
import dayjs from 'dayjs';
import AutoProtectCellRenderer from '../CellRenderers/AutoProtectCellRenderer';
import LearningCellRenderer from '../CellRenderers/LearningCellRenderer';
import ProtectionModeCellRenderer from '../CellRenderers/ProtectionModeCellRenderer';
import RuntimeProtectionCellRenderer from '../CellRenderers/RuntimeProtectionCellRenderer';
import { getAdditionalFieldValue, getAdditionalFieldValueExport, getAdditionalFieldValueGetter } from 'common/utils/ColumnDisplayValueGetters';
import RiskScoreCellRenderer from 'common/components/ProtectedAssets/Renderers/RiskScoreCellRenderer';
import StatusCellRenderer from '../CellRenderers/StatusCellRenderer/StatusCellRenderer';
import LastRunningDateCellRenderer from 'common/components/ProtectedAssets/Renderers/LastRunningDateCellRenderer';
import { ProtectedAssetsTableRegistry } from 'common/module_interface/assets/ProtectedAssetsTableRegistry';
import ImageScanStatusCellRenderer from '../CellRenderers/ImageScanStatusCellRenderer/index';
import ImageScanStatusHeaderRenderer from '../HeaderRenderers/ImageScanStatusHeaderRenderer/ImageScanStatusHeaderRenderer';
import { getScanStatus, getScanStatusDetails } from 'modules/workloads/utils';

const columnDefs: CGColDef[] = [
    {
        colId: 'scanStatus',
        field: 'ScanStatus',
        headerName: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.SCAN_STATUS.HEADER'),
        headerTooltip: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.SCAN_STATUS.TOOLTIP'),
        sortable: false,
        valueGetter: (params) => getScanStatus(params.data),
        tooltipValueGetter: (params) => getScanStatus(params.data),
        width: 110,
    },
    {
        colId: 'scanStatusDetails',
        field: 'ScanStatusDetails',
        headerName: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.SCAN_STATUS_DETAILS.HEADER'),
        headerTooltip: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.SCAN_STATUS_DETAILS.TOOLTIP'),
        sortable: false,
        valueGetter: (params) => getScanStatusDetails(params.data),
        tooltipValueGetter: (params) => getScanStatusDetails(params.data),
        width: 110
    },
    {
        colId: 'image_scanStatusDetails',
        field: 'ScanStatusDetails',
        headerName: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.SCAN_STATUS_DETAILS.HEADER'),
        headerTooltip: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.SCAN_STATUS_DETAILS.TOOLTIP'),
        sortable: false, // needs to change back to 'true' once backend adds sort support on new scan status scheme
        valueGetter: (params) => getScanStatusDetails(params.data),
        tooltipValueGetter: (params) => getScanStatusDetails(params.data),
        width: 110,
    },
    {
        colId: 'image_scanStatus',
        field: 'ScanStatus',
        headerName: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.SCAN_STATUS.HEADER'),
        headerTooltip: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.SCAN_STATUS.TOOLTIP'),
        sortable: true,
        minWidth: 150,
        headerComponent: ImageScanStatusHeaderRenderer,
        // needs to change to use new scan status scheme once backend adds group-by and sort support on new scan status scheme
        // valueGetter: (params) => getScanStatus(params.data),
        valueGetter: getAdditionalFieldValueGetter('ScanStatus'),
        cellRenderer: ImageScanStatusCellRenderer,
        enableRowGroup: true,
        columnType: ColumnType.Additional,
        additionalFieldSource: ColumnAdditionalFieldSource.KubernetesImage,
    },
    {
        colId: 'isRunning',
        field: 'IsRunning',
        valueGetter: getAdditionalFieldValueGetter('IsRunning'),
        tooltipValueGetter: (params) => getAdditionalFieldValue(params.data, 'IsRunning'),
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IS_RUNNING.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IS_RUNNING.TOOLTIP'),
        sortable: true,
        csvExport: {
            calculateValue: (data) => {
                const value = getAdditionalFieldValue(data, 'IsRunning');
                if (value === null) {
                    return null;
                }
                if (value === 'True') {
                    return i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IS_RUNNING.RUNNING');
                } else {
                    return i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IS_RUNNING.STOPPED');
                }
            },
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IS_RUNNING.CSV_HEADER'),
            order: 12,
        },
        cellRenderer: StatusCellRenderer,
        columnType: ColumnType.Additional,
        additionalFieldSource: ColumnAdditionalFieldSource.KubernetesImage,
        width: 100,
    },
    {
        colId: 'lastRunningDate',
        field: 'LastRunningDate',
        cellRenderer: LastRunningDateCellRenderer,
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.LAST_RUNNING_DATE.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.LAST_RUNNING_DATE.TOOLTIP'),
        sortable: true,
        valueGetter: getAdditionalFieldValueGetter('LastRunningDate'),
        tooltipValueGetter: (params) => getAdditionalFieldValue(params.data, 'LastRunningDate'),
        columnType: ColumnType.Additional,
        additionalFieldSource: ColumnAdditionalFieldSource.KubernetesImage
    },
    {
        colId: 'registry',
        field: 'Registry',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.REGISTRY.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.REGISTRY.TOOLTIP'),
        sortable: true,
        valueGetter: getAdditionalFieldValueGetter('Registry'),
        tooltipValueGetter: (params) => getAdditionalFieldValue(params.data, 'Registry'),
        enableRowGroup: true,
        columnType: ColumnType.Additional,
        additionalFieldSource: ColumnAdditionalFieldSource.KubernetesImage
    },
    {
        colId: 'repository',
        field: 'Repository',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.REPOSITORY.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.REPOSITORY.TOOLTIP'),
        sortable: true,
        valueGetter: getAdditionalFieldValueGetter('Repository'),
        tooltipValueGetter: (params) => getAdditionalFieldValue(params.data, 'Repository'),
        enableRowGroup: true,
        columnType: ColumnType.Additional,
        additionalFieldSource: ColumnAdditionalFieldSource.KubernetesImage
    },
    {
        colId: 'imageTag',
        field: 'ImageTag',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IMAGE_TAG.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IMAGE_TAG.TOOLTIP'),
        valueGetter: getAdditionalFieldValueGetter('ImageTag'),
        tooltipValueGetter: (params) => getAdditionalFieldValue(params.data, 'ImageTag'),
        sortable: true,
        enableRowGroup: true,
        columnType: ColumnType.Additional,
        additionalFieldSource: ColumnAdditionalFieldSource.KubernetesImage,
    },
    {
        colId: 'imageId',
        field: 'ImageId',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IMAGE_ID.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IMAGE_ID.TOOLTIP'),
        valueGetter: getAdditionalFieldValueGetter('ImageId'),
        tooltipValueGetter: (params) => getAdditionalFieldValue(params.data, 'ImageId'),
        csvExport: {
            calculateValue: getAdditionalFieldValueExport('ImageId'),
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IMAGE_ID.CSV_HEADER'),
            order: 16,
        },
        sortable: true,
        enableRowGroup: true,   
        width: 250,
        columnType: ColumnType.Additional,
        additionalFieldSource: ColumnAdditionalFieldSource.KubernetesImage,
    },
    {
        colId: 'threats',
        field: 'Threats',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.THREATS.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.THREATS.TOOLTIP'),
        valueGetter: (data) => data.data?.vulnerability?.stats?.threat.all || data.data?.malware,
        width: 100,
    },
    {
        colId: 'secrets',
        field: 'Secrets',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.SECRETS.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.SECRETS.TOOLTIP'),
        valueGetter: (data) => data.data?.vulnerability?.stats?.secret.all || data.data?.secrets,
        width: 100,
    },
    {
        colId: 'riskScore',
        field: 'RiskScore',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.RISK.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.RISK.TOOLTIP'),
        sortable: true,
        cellRenderer: RiskScoreCellRenderer,
        width: 80,
        minWidth: 80,
        enableRowGroup: true,
        valueGetter: getAdditionalFieldValueGetter('RiskScore'),
        tooltipValueGetter: (params) => getAdditionalFieldValue(params.data, 'RiskScore'),
        columnType: ColumnType.Additional,
        additionalFieldSource: ColumnAdditionalFieldSource.KubernetesImage,
    },
    {
        colId: 'lastScanDate',
        field: 'lastScanDate',
        headerName: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.LAST_SCAN_DATE.HEADER'),
        headerTooltip: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.LAST_SCAN_DATE.TOOLTIP'),
        tooltipField: 'lastScanDate',
        sortable: false,
        valueFormatter: (params) => {
            const lastScanDate = getExternalAdditionalFieldValue(params.data, 'LastScanDate');
            if (lastScanDate) {
                return dayjs(lastScanDate).format('MM/DD/YYYY HH:mm');
            } else {
                return '';
            }
        },
        width: 110,
    },
    {
        colId: 'includesFSP',
        field: 'IncludesFSP',
        headerName: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.RUNTIME_PROTECTION.HEADER'),
        headerTooltip: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.RUNTIME_PROTECTION.TOOLTIP'),
        cellRenderer: RuntimeProtectionCellRenderer,
    },
    {
        colId: 'fspStickyMode',
        field: 'FSPStickyMode',
        headerName: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.AUTO_PROTECT.HEADER'),
        headerTooltip: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.AUTO_PROTECT.TOOLTIP'),
        cellRenderer: AutoProtectCellRenderer,
    },
    {
        colId: 'withFSPBlock',
        field: 'WithFSPBlock',
        headerName: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.PROTECTION_MODE.HEADER'),
        headerTooltip: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.PROTECTION_MODE.TOOLTIP'),
        cellRenderer: ProtectionModeCellRenderer,
    },
    {
        colId: 'whiteListProgressProtego',
        field: 'WhiteListProgressProtego',
        headerName: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.LEARNING.HEADER'),
        headerTooltip: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.LEARNING.TOOLTIP'),
        cellRenderer: LearningCellRenderer,
    },
    {
        colId: 'insertionState',
        field: 'insertionState',
        headerName: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.INSERTION_STATE.HEADER'),
        headerTooltip: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.INSERTION_STATE.TOOLTIP'),
        valueGetter: getExternalAdditionalFieldValueGetter('InsertionState'),
        tooltipValueGetter: (params) => getExternalAdditionalFieldValue(params.data, 'InsertionState'),
    },
    {
        colId: 'FspVersion',
        field: 'FspVersion',
        headerName: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.FSP_VERSION.HEADER'),
        headerTooltip: i18n.t('SERVERLESS.PROTECTED_ASSETS.COLUMNS.FSP_VERSION.TOOLTIP'),
        valueGetter: getExternalAdditionalFieldValueGetter('FspVersion'),
        tooltipValueGetter: (params) => getExternalAdditionalFieldValue(params.data, 'FspVersion'),
    },
];

export default function initializeColumnDefs() {
    ProtectedAssetsTableRegistry.addColumnDefs(columnDefs, 'colId');
}
