import React from 'react';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { changeUrl } from 'common/utils/http';
import { ModalsProps } from '..';
import { useGetShiftLeft } from 'modules/workloads/reducers/shiftLeft/hooks/useGetShiftLeft';
import { useDeleteForceShiftLeft } from 'modules/workloads/reducers/shiftLeft/hooks/useDeleteForceShiftLeft';
import { useTranslation } from 'react-i18next';
import { getCloudAccountsService } from 'common/interface/data_services';
import { useLocation } from 'react-router-dom';
import { Message } from 'common/design-system/components-v2';

const RemoveShiftLeftModal: React.FC<ModalsProps> = ({ closeModal, shiftLeftId }) => {
    const { data: shiftLeftData } = useGetShiftLeft(shiftLeftId);
    const { t } = useTranslation('k8s_shiftleft');
    const location = useLocation();

    const handleOnDeleteSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('shiftLeftPage.removeModal.delete.success'),
            text: '',
        });
        getCloudAccountsService().clearCache();
        onClose();
        const isWorkloadPage = location.pathname.includes('/workload/');
        changeUrl(isWorkloadPage ? '/workload/environments' : '/cloud-account/index');
    };

    const handleOnDeleteError = (error: { message: string; }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('shiftLeftPage.removeModal.delete.failed'),
            text: error.message,
        });
        onClose();
    };

    const { deleteShiftLeft, isLoading: isDeleteLoading } = useDeleteForceShiftLeft({ onSuccess: handleOnDeleteSuccess, onError: handleOnDeleteError });

    const onClickRemoveShiftLeft = async () => {
        if (!shiftLeftData) return;
        deleteShiftLeft(shiftLeftData.account.id);
    };

    const onClose = React.useCallback(() => {
        closeModal();
    }, [closeModal]);

    return (

        <Message
            title={t('shiftLeftPage.removeModal.header')}
            text={t('shiftLeftPage.removeModal.areYouSureYouWantToDelete', { shiftLeftEnvironment: shiftLeftData?.account.name })}
            variant='danger'
            onClose={onClose}
            cancelBtnText={t('shiftLeftPage.removeModal.footer.cancel')}
            onCancel={onClose}
            submitBtnText={t('shiftLeftPage.removeModal.footer.delete')}
            onConfirm={onClickRemoveShiftLeft}
            isLoading={isDeleteLoading}
        />
    );
};

export default RemoveShiftLeftModal;
