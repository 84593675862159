import { KubernetesAgentSummaryType, KubernetesFeatureType } from 'modules/workloads/services/kubernetesAccount/kubernetesAccount.interface';
import { BladeItem } from './Blades.types';
import { getCheckpointUrl } from 'common/components/Forms/urls';
import { isChina } from 'common/utils/dataCenter';
import { getUserService } from 'common/interface/services';

export const blades: () => Array<BladeItem> = () => [
    {
        labelT: 'blades.postureManagement',
        name: 'pm',
        updateName: KubernetesFeatureType.postureManagement,
        link: getCheckpointUrl('POSTURE_MANAGEMENT'),
        viewOnly: true,
        content: true,
        agentTypes: [
            KubernetesAgentSummaryType.AssetsManagement,
            KubernetesAgentSummaryType.Inventory
        ]
    },
    {
        labelT: 'blades.imageAssurance',
        name: 'ia',
        isActiveName: 'imageAssuranceEnabled',
        updateName: KubernetesFeatureType.imageAssurance,
        updateHelmOnUpdate: true,
        link: getCheckpointUrl('IMAGE_ASSURANCE'),
        content: true,
        agentTypes: [
            KubernetesAgentSummaryType.ImageScan,
            KubernetesAgentSummaryType.ImageScanEngine,
            KubernetesAgentSummaryType.ImageAccessRuntimeMonitor,
        ],
        nestedBlades: [
            {
                isHidden: !getUserService().hasPermission(['allow-in-use-package']),
                labelT: 'blades.imageAssurance_fileAccessMonitor',
                name: 'ia-fam',
                isActiveName: 'imageAccessRuntimeMonitorEnabled',
                updateName: KubernetesFeatureType.imageAssurance_fileAccessMonitor,
                isNestedBlade: true,
                updateHelmOnUpdate: true,
            }
        ]
    },
    {
        labelT: 'blades.admissionControl',
        name: 'ac',
        isActiveName: 'admissionControlEnabled',
        updateName: KubernetesFeatureType.admissionControl,
        updateHelmOnUpdate: true,
        link: getCheckpointUrl('ADMISSION_CONTROL'),
        content: true,
        agentTypes: [
            KubernetesAgentSummaryType.AdmissionControlEnforcer,
            KubernetesAgentSummaryType.AdmissionControlPolicy
        ]
    },
    {
        labelT: 'blades.runtimeProtection',
        name: 'rp',
        isActiveName: 'runtimeProtectionEnabled',
        isHidden: isChina(),
        updateName: KubernetesFeatureType.runtimeProtection,
        updateHelmOnUpdate: true,
        link: getCheckpointUrl('KUBERNETES_RUNTIME_PROTECTION'),
        content: true,
        agentTypes: [
            KubernetesAgentSummaryType.AssetsManagement,
            KubernetesAgentSummaryType.Inventory,
            KubernetesAgentSummaryType.RuntimeProtectionPolicy,
            KubernetesAgentSummaryType.RuntimeProtectionDaemon
        ],
        nestedBlades: [
            {
                labelT: 'blades.behaviolProfiling',
                name: 'rp-p',
                isActiveName: 'runtimeProtectionProfiling',
                updateName: KubernetesFeatureType.runtimeProtection_profiling,
                isNestedBlade: true
            }
        ]
    },
    {
        labelT: 'blades.threatIntelligence',
        name: 'ti',
        isActiveName: 'threatIntelligenceEnabled',
        isHidden: isChina(),
        updateName: KubernetesFeatureType.threatIntelligence,
        updateHelmOnUpdate: true,
        link: getCheckpointUrl('THREAT_INTELLIGNCE'),
        content: true,
        agentTypes: [
            KubernetesAgentSummaryType.AssetsManagement,
            KubernetesAgentSummaryType.Inventory,
            KubernetesAgentSummaryType.FlowLogs
        ]
    },
];