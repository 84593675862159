import styled from 'styled-components';

const Wrapper = styled.div`
    height: 100%;
`;

export const LoaderWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default {
    Wrapper,
    LoaderWrapper
};