import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import DataVolume from 'common/components/DataVolume/DataVolume';
import { severityInfo } from '../../protectedAsset/page/tabs/Vulnerability/utils';

const SeverityCellRenderer: React.FC<ICellRendererParams> = (params) => {
    if (!params.value) return;
    return (
        <DataVolume dataInfo={severityInfo(params.value)} />
    );
};

export default SeverityCellRenderer;