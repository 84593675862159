import { ClusterState } from './cluster.interface';

export const initialState: ClusterState = {
    clusters: [],
    isLoadingClusters: true,
    allKubernetesAccount: {},
    clusterForm: {
        name: {
            value: `cluster-${Math.floor(Math.random() * 999999999)}`,
            errorMessage: 'Cluster name can\'t be empty',
            isDirty: true,
            isError: false,
        },
        apiKey: {
            value: '',
            errorMessage: 'Api key can\'t be empty',
            isDirty: false,
            isError: false,
        },
        apiSecret: {
            value: '',
            errorMessage: 'Api Secret can\'t be empty',
            isDirty: false,
            isError: false,
        },
        namespace: {
            value: 'checkpoint',
            errorMessage: 'Namespace can\'t be empty',
            isDirty: true,
            isError: false,
        },
        imageAssuranceEnabled: true,
        admissionControlEnabled: false,
        runtimeProtectionEnabled: false,
        threatIntelligenceEnabled: false,
        runtimeProtectionProfiling: false,
    },
    newCluster: null,
    selectedCluster: null,
    selectedClusterError: false,
    kubernetesAccountSummary: {},
    kubernetesAgentSummary: null,
    selectedServiceAccount: null,
    kubernetesAccount: {}
};