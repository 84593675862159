import React, { useCallback } from 'react';
import vulnerabilityService from '../vulnerability.service';
import {
    GetVulnerabilityScanMetadata,
    UseVulnerabilityScanMetadataState
} from '../vulnerability.interface';

export type UseVulnerabilityScanMetadata = (data: GetVulnerabilityScanMetadata.Request) => UseVulnerabilityScanMetadataState;
const useVulnerabilityScanMetadata: UseVulnerabilityScanMetadata = (props) => {
    const [{ isLoading, error, data }, setState] = React.useState<UseVulnerabilityScanMetadataState>({
        data: undefined,
        isLoading: false,
        error: false,
    });

    const getData = useCallback(async () => {
        setState(prevState => ({ ...prevState, loading: true, error: false }));
        try {
            const res = await vulnerabilityService.getVulnerabilityScanMetadata({ ...props });
            setState(({ isLoading: false, error: false, data: res.data }));
        } catch (error) {
            setState({ isLoading: false, error: true, data: undefined });
        }
    }, [props]);

    React.useEffect(() => {
        if (!isLoading && !error && !data) {
            getData();
        }
    }, [isLoading, error, getData, data]);

    return ({
        isLoading: isLoading,
        data,
        error
    });
};

export default useVulnerabilityScanMetadata;
