import { IEvent } from 'common/module_interface/events/Events';
import { TaskAttributeTypes, TaskAttribute } from './ServerlessTaskEvent.types';

export const getActions = (event: IEvent) => {
    try {
        return event.remediationActions.map(remediationAction => JSON.parse(remediationAction));
    } catch {
        return [];
    }
};

export const createTaskAttribute = (title: string, value: string | undefined, type = TaskAttributeTypes.text) => {
    return value === undefined ? [] : [{ title, value, type }];
};

export const getBundleAttributes = (bundleName: string | undefined, actions: any[]) => {
    switch (bundleName) {
        case 'PermissiveRole': {
            return createTaskAttribute('Execution Role ARN', actions.find(action => action?.RoleArn?.length)?.RoleArn);
        }
        case 'CrossAccountTrigger':
        case 'UnlinkedResource':
        case 'UnlinkedTrigger':
        case 'ExcessiveTimeout':
        case 'ObsoleteRuntime': {
            return createTaskAttribute('How To Resolve', actions.find(action => action?.Link?.length)?.Link, TaskAttributeTypes.link);
        }
        case 'VulnerableDependency': {
            const vulnDepsItems = actions.find(action => action?.VulnerableDepsItems?.length)?.VulnerableDepsItems?.[0] || {};
            return [
                { title: 'Vulnerability Description', value: vulnDepsItems?.description, type: TaskAttributeTypes.text },
                { title: 'Vulnerability Link', value: vulnDepsItems['cve-id'] && `https://nvd.nist.gov/vuln/detail/${vulnDepsItems['cve-id']}`, type: TaskAttributeTypes.link },
            ].reduce<TaskAttribute[]>((accum, current) => [...accum, ...createTaskAttribute(current.title, current.value, current.type)], []);
        }
        default:
            return [];
    }
};