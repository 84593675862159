import React from 'react';
import { Button, Stack } from 'common/design-system/components-v2';
import { ToolbarProps } from './Toolbar.types';
import { ModalType } from '../Modals';
import { A } from 'common/components/Anchor/Anchor';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

const Toolbar: React.FunctionComponent<ToolbarProps> = ({ agentlessAccount, setModalType }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));

    return (
        <Stack direction='row' justifyContent='flex-end'>
            <Button
                iconProps={{ name: 'delete' }}
                variant='text'
                onClick={() => setModalType(ModalType.DisableAWP)}
            >
                {t('awpTab.toolbarActions.disableAWP')}
            </Button>
            <Button
                iconProps={{ name: 'configuration' }}
                variant='text'
                onClick={() => setModalType(ModalType.AdvancedConfig)}
            >
                {t('awpTab.toolbarActions.advancedConfig')}
            </Button>
            {agentlessAccount.shouldUpdate &&
                <A href={`/cloud-update/agentless/${agentlessAccount.cloudAccountId}`}>
                    <Button
                        iconProps={{ name: 'warning' }}
                        color='alert'
                    >
                        {agentlessAccount.isTerraform ? t('awpTab.toolbarActions.updateTerraform') : t('awpTab.toolbarActions.updateStack')}
                    </Button>
                </A>}
        </Stack>
    );
};

export default Toolbar;