export enum DisableAwpModalType {
    AZ_CLI = 'CLI_AZURE',
    CLOUDFORMATION_STACK_IN_ACCOUNT_SUB = 'IN_ACCOUNT_SUB_CLOUDFORMATION_STACK',
    CLOUDFORMATION_STACK = 'CLOUDFORMATION_STACK_AWS',
    TERRAFORM_AZURE = 'TERRAFORM_AZURE',
    TERRAFORM_IN_ACCOUNT_SUB_AWS = 'TERRAFORM_IN_ACCOUNT_SUB_AWS',
    TERRAFORM_AWS = 'TERRAFORM_AWS',
}

export interface DisableAwpModalProps {
    onClose: () => void,
    onDelete: (verify: boolean) => void,
    isDeletePending: boolean,
}

export enum DisableAWPType {
    skipStackValidation = 'skipStackValidation',
    validateStack = 'validateStack',
    nonSelected = 'nonSelected',
}