import { Icon, Tooltip } from '@dome9/berries/react-components';
import React, { ReactElement } from 'react';
import { InputsListWrapper, InputsListLabelWrapper, InputsListElementWrapper, InputsListSeperator, InputsListTooltipWrapper } from './InputsList.styles';

export interface InputsListProps {
    list: Array<{ label: string; tooltip?: string; element: ReactElement; }>;
}

const InputsList: React.FunctionComponent<InputsListProps> = ({ list }) => {

    return (
        <InputsListWrapper>
            {list.map((item, index) => (
                <>
                    <InputsListLabelWrapper>
                        <div>{item.label}</div>
                        {item.tooltip && <Tooltip
                            content={item.tooltip}
                            placement='bottom'
                        >
                            <InputsListTooltipWrapper>
                                <Icon name='info' size={12} />
                            </InputsListTooltipWrapper>
                        </Tooltip>}
                    </InputsListLabelWrapper>
                    <InputsListElementWrapper>{item.element}</InputsListElementWrapper>
                    {!(index % 2) && <InputsListSeperator />}
                </>
            ))}
        </InputsListWrapper>
    );
};

export default InputsList;
