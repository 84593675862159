import React from 'react';
import RemoveShiftLeftModal from './RemoveClusterModal/RemoveShiftLeftModal';
import { ModalType } from '../../ShiftLeftEnvironment.types';
import EditShiftLeftModal from './EditClusterModal/EditShiftLeftModal';
import DownloadOSModal from './DownloadOSModal/DownloadOSModal';

export interface ModalsProps {
    shiftLeftId: string;
    modalType: ModalType | null;
    closeModal: () => void
}

const Modals: React.FC<ModalsProps> = props => {
    switch (props.modalType) {
        case ModalType.RemoveShiftLeft:
            return <RemoveShiftLeftModal {...props} />;
        case ModalType.RenameShiftLeft:
            return <EditShiftLeftModal {...props} />;
        case ModalType.DownloadOS:
            return <DownloadOSModal {...props} />;

        default:
            return null;
    }
};

export default Modals;
