import { ClickEventData, EventType, Widget } from '@dome9/cloudguard-widgets-dashboard';
import { openWidgetLink, parseIdString } from 'modules/workloads/pages/dashboard/events/utils';
import { K8STabs } from '../../../KubernetesEnvironment.consts';
import { DeltaCounterTypes, ResourceType, TopRiskItemsType } from '@dome9/cloudguard-widgets-widgets';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { Vendors } from 'common/consts/vendors';
import { generateWidgetUrlMain } from 'modules/workloads/pages/dashboard/events/events.handlers';
import { generateHref } from 'common/utils/http';

const getK8sPageUrl = (cloudAccountId: string, tabName: string, filterPanel?: string): string => {
    let url = `/workload/environments/kubernetes/${cloudAccountId}?tabName=${tabName}&tabOnly=true`;
    if (filterPanel) {
        url += `&filterPanel=${filterPanel}`;
    }
    return url;
};

const widgetUrl = (widget: Widget, eventData: ClickEventData, cloudAccountId: string): string | undefined => {
    switch (widget) {
        case 'ComplianceScore':
        case 'ComplianceByFrameWork':
            return getK8sPageUrl(cloudAccountId, K8STabs.ASSESSMENT_HISTORY);
        case 'DeltaCounter': {
            if (!eventData.entityType) return undefined;
            const entityType = eventData.entityType as DeltaCounterTypes;
            switch (entityType) {
                case DeltaCounterTypes.SECURITY_EVENTS:
                    // filters: [{ name: 'severity', value: 'High' }, { name: 'severity', value: 'Critical' }]
                    // timeFilter: ClickEventData.time ('All' / { from: string, to: string })
                    return getK8sPageUrl(cloudAccountId, K8STabs.THREAT_AND_SECURITY_EVENTS);
                case DeltaCounterTypes.SECRETS:
                    return generateWidgetUrlMain(widget, eventData) || undefined;
                case DeltaCounterTypes.CRITICAL_FIXABLE_VUL:
                    return generateWidgetUrlMain(widget, eventData) || undefined;
                case DeltaCounterTypes.IMAGE_VIOLATIONS:
                    return getK8sPageUrl(cloudAccountId, K8STabs.POSTURE_FINDING_EVENTS);
                default:
                    return undefined;
            }
        }
        case 'CreatedIssues':
            return generateWidgetUrlMain(widget, eventData) || undefined;
        case 'Vulnerabilities':
            return generateWidgetUrlMain(widget, eventData) || undefined;
        case 'CounterResources': {
            if (!eventData.entityType) return undefined;
            const entityType = eventData.entityType as ResourceType;
            switch (entityType) {
                case ResourceType.KubernetesNode:
                case ResourceType.KubernetesPod:
                case ResourceType.KubernetesNamespace:
                    return getK8sPageUrl(cloudAccountId, K8STabs.PROTECTED_ASSETS, `{"type":["${Vendors.KUBERNETES}|${entityType}"]}`);
                case ResourceType.Images:
                    return getK8sPageUrl(cloudAccountId, K8STabs.IMAGES);
                default:
                    return undefined;
            }
        }
        case 'SecurityIncidents':
            return getK8sPageUrl(cloudAccountId, K8STabs.THREAT_AND_SECURITY_EVENTS);
        case 'TopRiskItems': {
            if (!eventData.entityType) return undefined;
            const entityType = eventData.entityType as TopRiskItemsType;
            const clickedItemId = eventData.filters?.find(filter => filter.name === 'id')?.value;
            const clickedItemType = eventData.filters?.find(filter => filter.name === 'type')?.value;
            if (clickedItemId && clickedItemType) {
                const { assetId } = parseIdString(clickedItemId);
                const asset = getProtectedAssetsService().getAssetByType(clickedItemType);
                if (!asset) return undefined;
                switch (entityType) {
                    case TopRiskItemsType.Images:
                        return `/workload/images/generic?cloudAccountId=${cloudAccountId}&assetType=${clickedItemType}&assetId=${assetId}&platform=${Vendors.KUBERNETES}`;
                    case TopRiskItemsType.Workloads:
                        return `/workload/workloads/protected-asset${asset.isGenericObject ? '-generic' : ''}?cloudaccountid=${cloudAccountId}&instanceid=${clickedItemId}&assetType=${clickedItemType}&type=${asset.typeNumber}&assetid=${assetId}&platform=${Vendors.KUBERNETES}`;
                    default:
                        return undefined;
                }
            } else {
                switch (entityType) {
                    case TopRiskItemsType.Images:
                        return getK8sPageUrl(cloudAccountId, K8STabs.IMAGES, '{"empty":true}');
                    case TopRiskItemsType.Workloads:
                        return getK8sPageUrl(cloudAccountId, K8STabs.PROTECTED_ASSETS, '{"empty":true}');
                    default:
                        return undefined;
                }
            }
        }
        default:
            return undefined;
    }
};

export const handleDashboardEvent = (
    widget: Widget,
    eventType: EventType,
    eventData: ClickEventData,
    cloudAccountId: string
): void => {
    const url = generateWidgetUrl(widget, eventData, cloudAccountId);
    if (!url) {
        console.error('Failed to generate widget url', { widget, eventData, cloudAccountId });
        return;
    }
    switch (eventType) {
        case EventType.Click:
            openWidgetLink(url);
            break;
    }
};

export const generateWidgetUrl = (
    widget: Widget,
    eventData: ClickEventData,
    cloudAccountId: string
) => {
    const url = widgetUrl(widget, eventData, cloudAccountId);
    return url && generateHref(url);
};