import { IProtectedAssetDetailPanelProvider } from 'common/module_interface/assets/ProtectedAssets';
import { getVulnerabilityPageTabType, VulnerabilityPageTabRender, workloadAssetTypes } from './utils';
import i18next from 'i18next';
import VulnerabilityWrapper from './VulnerabilityWrapper';
import ScanResultEmptyState from 'modules/workloads/protectedAsset/components/ScanResultEmptyState';
import vulnerabilityService from 'modules/workloads/services/vulnerability/vulnerability.service';
import { Addin } from 'common/extensibility/AddinRegistry';
import FailureLoadingScanResults from 'modules/workloads/protectedAsset/components/FailureLoadingScanResults';
import WorkloadService from 'modules/workloads/services/workload/workload.service';

const vulnerabilitiesPageTabs: Record<
    Exclude<keyof typeof VulnerabilityPageTabRender, 'NONE'>,
    IProtectedAssetDetailPanelProvider['getValue']
> = {
    [VulnerabilityPageTabRender.V1]: () => {
        return {
            name: 'vulnerabilities',
            title: i18next.t('k8s_protected-asset-details:tabs.vulnerabilities'),
            isReact: false,
        };
    },
    [VulnerabilityPageTabRender.V2]: () => {
        return {
            name: 'vulnerabilities',
            title: i18next.t('k8s_protected-asset-details:tabs.vulnerabilities'),
            component: VulnerabilityWrapper,
            isReact: true,
        };
    },
    [VulnerabilityPageTabRender.AWP_EMPTY_STATE]: () => {
        return {
            name: 'vulnerabilities',
            title: i18next.t('k8s_protected-asset-details:tabs.vulnerabilities'),
            component: ScanResultEmptyState,
            isReact: true,
        };
    },
    [VulnerabilityPageTabRender.FAILURE]: () => {
        return {
            name: 'vulnerabilities',
            title: i18next.t('k8s_protected-asset-details:tabs.vulnerabilities'),
            component: FailureLoadingScanResults,
            isReact: true,
        };
    },
};

export const vulnerabilitiesPageTabsFactory = (prefix: string, position: number): Addin<IProtectedAssetDetailPanelProvider>[] => {
    return Object.entries(vulnerabilitiesPageTabs).map(([type, getValue]) => {
        return {
            id: `${prefix}-${type}`,
            position,
            content: {
                isRelevant: async ({ typeByPlatform, protectedAsset, entityId, cloudAccountId }) => {
                    let id = '';
                    const isWorkloadType = workloadAssetTypes.some(type => type === typeByPlatform);
                    if (isWorkloadType) {
                        const { data } = await WorkloadService.getImages({ request: { dome9Id: protectedAsset.id } });
                        if (data.length > 0) {
                            id = data[0].imageId;
                        }
                    }
                    const tabType = await getVulnerabilityPageTabType(
                        typeByPlatform,
                        () => vulnerabilityService.getVulnerabilityScanMetadata({
                            entityId: id || entityId,
                            entityType: isWorkloadType ? 'KubernetesImage' : protectedAsset.type,
                            environmentId: cloudAccountId
                        })
                    );
                    return tabType === type;
                },
                getValue,
            },
        };
    });
};