import { Icon, Stack, Typography } from 'common/design-system/components-v2';
import { OnboardingMethod } from '../../DataWidgets/DataWidgets.utils';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';

export const OnboardingMethodDetail = ({ onboardingMethod }: { onboardingMethod: OnboardingMethod }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));

    return (
        <Stack>
            <Typography color='light'>{t('awpTab.details.onboardingMethod')}</Typography>
            <Typography>
                <Stack direction='row' spacing={1} alignItems='center'>
                    {onboardingMethod === OnboardingMethod.TERRAFORM && (<Icon name='terraform' />)}
                    <Stack fullHeight justifyContent='center'>{t(`awpTab.onboardingMethods.${onboardingMethod}`)}</Stack>
                </Stack>
            </Typography>
        </Stack>

    );
};