import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #2e3f58;
  width: 40px;
  height: 40px;
  animation: ${spin} 2s linear infinite;
`;

export default Loader;
