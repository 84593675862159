import { Alert, AlertType, AlertVariant } from '@dome9/berries/react-components';
import { Button, Input, SelectV2, Stack, Typography } from 'common/design-system/components-v2';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StepProps, CloudFormParams } from '../awpOnboardingAws.types';
import UrlSnippet from './UrlSnippet';
import { ScanMode } from 'modules/workloads/services/awp/awp.interface';
import { InAccountScannerVPC } from 'modules/workloads/aws/tabs/awp/components/Modals/AdvancedConfig/AdvancedConfig.types';
import { Vendors } from 'common/consts/vendors';
import CollapseSection from 'modules/workloads/components/CollapseSection/CollapseSection';

const CreateCrossStackStep: React.FC<StepProps> = ({ formProps: { formState, control, resetField, setValue, watch }, scanMode }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));
    const onResetFormValue = React.useCallback((fieldName: keyof Omit<CloudFormParams, 'hubId'>) => {
        resetField(fieldName);
        setValue(fieldName, formState.defaultValues?.[fieldName] ?? '');
    }, [resetField, setValue, formState.defaultValues]);

    return (
        <Stack fullWidth direction='column' spacing={2}>
            <Typography>{t('awpOnboarding.instructions.step1.press')}</Typography>
            <CollapseSection
                title={t('awpOnboarding.integrationParametersTitle')}
            >
                <Stack spacing={3} padding={[0, 1, 1, 1]}>
                    <Alert type={AlertType.INFO} variant={AlertVariant.LIGHT}>
                        <Typography>{t('awpOnboarding.ifAnyModifications')}</Typography>
                    </Alert>
                    <Controller
                        control={control}
                        name='stackName'
                        rules={{ required: { value: true, message: t('awpOnboarding.fields.required') } }}
                        render={({ field: { onChange, value, name } }) => (
                            <Input
                                fullWidth
                                label={t('awpOnboarding.fields.stackName')}
                                required={true}
                                value={value}
                                onChange={onChange}
                                isError={Boolean(formState.errors.stackName)}
                                helperText={formState.errors?.stackName?.message}
                                endAdornment={<Button variant='text' onClick={() => onResetFormValue(name)}>{t('awpOnboarding.fields.resetButtonText')}</Button>}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='crossAccountExternalId'
                        rules={{ required: { value: true, message: t('awpOnboarding.fields.required') } }}
                        render={({ field: { onChange, value, name } }) => (
                            <Input
                                fullWidth
                                label={t('awpOnboarding.fields.crossAccountExternalId')}
                                required={true}
                                value={value}
                                onChange={onChange}
                                isError={Boolean(formState.errors.crossAccountExternalId)}
                                helperText={formState.errors.crossAccountExternalId && formState.errors?.crossAccountExternalId?.message}
                                endAdornment={<Button variant='text' onClick={() => onResetFormValue(name)}>{t('awpOnboarding.fields.resetButtonText')}</Button>}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='crossAccountRoleName'
                        rules={{ required: { value: true, message: t('awpOnboarding.fields.required') } }}
                        render={({ field: { onChange, value, name } }) => (
                            <Input
                                fullWidth
                                label={t('awpOnboarding.fields.crossAccountRoleName')}
                                required={true}
                                value={value}
                                onChange={onChange}
                                isError={Boolean(formState.errors.crossAccountRoleName)}
                                helperText={formState.errors?.crossAccountRoleName?.message}
                                endAdornment={<Button variant='text' onClick={() => onResetFormValue(name)}>{t('awpOnboarding.fields.resetButtonText')}</Button>}
                            />
                        )}
                    />
                    {![ScanMode.Saas, ScanMode.InAccountSub].includes(scanMode) && (
                        <Controller
                            control={control}
                            name='customVpc'
                            render={({ field: { onChange, value } }) => (
                                <SelectV2
                                    isMulti={false}
                                    fullWidth
                                    options={[
                                        { label: t('inAccountScannerVPC.ManagedByAWP'), value: InAccountScannerVPC.ManagedByAWP },
                                        { label: t('inAccountScannerVPC.ManagedByCustomer'), value: InAccountScannerVPC.ManagedByCustomer },
                                    ]}
                                    label={t('awpOnboarding.fields.customVpc', { context: Vendors.AWS })}
                                    value={value ?? InAccountScannerVPC.ManagedByAWP}
                                    onChange={onChange}
                                    isError={Boolean(formState.errors.crossAccountRoleName)}
                                    helperText={formState.errors?.crossAccountRoleName?.message}
                                />
                            )}
                        />
                    )}
                </Stack>
            </CollapseSection>
            <Stack fullWidth direction='row' spacing={2} alignItems='center'>
                <UrlSnippet watch={watch} formState={formState} scanMode={scanMode} />
            </Stack>
        </Stack>
    );
};

export default CreateCrossStackStep;