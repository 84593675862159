import i18n from 'common/services/translations/translations';
import WorkloadTypeCellRenderer from './CellRenderers/WorkloadType';
import WorkloadFeatureStatusRenderer from './CellRenderers/FeatureStatusRenderer';
import { IEnvironment } from 'common/interface/environmentsTable';
import { generateHref } from 'common/utils/http';
import { Vendors } from 'common/consts/vendors';
import { KubernetesAccountFeatureSummaryName } from '../services/workload/workload.interface';
import { getK8sNamespace } from '../initialize.i18n';
import ClusterVersionCellRenderer from './CellRenderers/ClusterVersion';
import { agentlessSupportedVendors, serverlessSupportedVendors } from './initialize';
import FeatureStatusRenderer from 'common/components/EnvironmentsTable/CellRenderers/FeatureStatusRenderer';
import { FeatureStatusRenderer_Type } from 'common/module_interface/assets/Environments';
import { isChina } from 'common/utils/dataCenter';
import { ETColDef, EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import AwpCentralizedAccountCellRenderer from '../protectedAsset/table/CellRenderers/AwpCentralizedAccountCellRenderer';

export const initializeEnvironmentsColumnDefs = () => {
    const columnDefs: ETColDef<IEnvironment>[] = [
        {
            id: 'serverlessEnabled',
            position: 9,
            permission: () => !isChina(),
            colId: 'customData|serverlessEnabled',
            field: 'customData|serverlessEnabled',
            headerName: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.SERVERLESS.HEADER', { ns: getK8sNamespace('common') }),
            headerTooltip: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.SERVERLESS.TOOLTIP', { ns: getK8sNamespace('common') }),
            sortable: true,
            width: 125,
            cellRenderer: FeatureStatusRenderer,
            cellRendererParams: {
                type: FeatureStatusRenderer_Type.Serverless,
                name: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.SERVERLESS.HEADER', { ns: getK8sNamespace('common') }),
                supportedVendors: serverlessSupportedVendors,
                shouldUpdateField: 'customData|serverlessShouldUpdate',
            }
        },
        {
            id: 'agentlessEnabled',
            position: 8,
            colId: 'customData|agentlessEnabled',
            field: 'customData|agentlessEnabled',
            headerName: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.AGENTLESS.HEADER', { ns: getK8sNamespace('common') }),
            headerTooltip: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.AGENTLESS.TOOLTIP', { ns: getK8sNamespace('common') }),
            sortable: true,
            width: 125,
            cellRenderer: FeatureStatusRenderer,
            cellRendererParams: {
                type: FeatureStatusRenderer_Type.Agentless,
                name: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.AGENTLESS.HEADER', { ns: getK8sNamespace('common') }),
                supportedVendors: agentlessSupportedVendors,
                shouldUpdateField: 'customData|agentlessShouldUpdate',
            }
        },
        {
            id: 'agentlessScanMode',
            position: 12,
            colId: 'customData|agentlessScanMode',
            field: 'customData|agentlessScanMode',
            headerName: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.AGENTLESS_SCAN_MODE.HEADER', { ns: getK8sNamespace('common') }),
            headerTooltip: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.AGENTLESS_SCAN_MODE.TOOLTIP', { ns: getK8sNamespace('common') }),
            initialHide: true,
            valueFormatter: (params) => {
                const sentence = params?.value?.replace(/([A-Z])/g, ' $1').trim();
                return sentence && sentence.charAt(0).toUpperCase() + sentence.slice(1) || '';
            }
        },
        {
            id: 'agentlessCentralizedAccountId',
            position: 12,
            colId: 'customData|agentlessCentralizedAccountId',
            field: 'customData|agentlessCentralizedAccountId',
            headerName: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.AGENTLESS_CENTRALIZED_ACCOUNT_ID.HEADER', { ns: getK8sNamespace('common') }),
            headerTooltip: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.AGENTLESS_CENTRALIZED_ACCOUNT_ID.TOOLTIP', { ns: getK8sNamespace('common') }),
            initialHide: true,
            cellRenderer: AwpCentralizedAccountCellRenderer,
        },    
        {
            id: 'kubernetesClusterVersion',
            colId: 'customData|kubernetesClusterVersion',
            field: 'customData|kubernetesClusterVersion',
            headerName: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.CLUSTER_VERSION.HEADER', { ns: getK8sNamespace('common') }),
            headerTooltip: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.CLUSTER_VERSION.TOOLTIP', { ns: getK8sNamespace('common') }),
            cellRenderer: ClusterVersionCellRenderer
        },
        {
            id: 'workloadPlatformType',
            colId: 'customData|workloadPlatformType',
            field: 'customData|workloadPlatformType',
            headerName: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.WORKLOAD_TYPE.HEADER', { ns: getK8sNamespace('common') }),
            headerTooltip: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.WORKLOAD_TYPE.TOOLTIP', { ns: getK8sNamespace('common') }),
            cellRenderer: WorkloadTypeCellRenderer
        },
        ...Object.values(KubernetesAccountFeatureSummaryName).map((featureName) => ({
            id: `kubernetesFeatures_${featureName}`,
            colId: 'customData|kubernetesFeatures',
            field: 'customData|kubernetesFeatures',
            headerName: i18n.t(`ENVIRONMENTS_TABLE.TABLE_HEADERS.FEATURE_${featureName}.HEADER`, { ns: getK8sNamespace('common') }),
            headerTooltip: i18n.t(`ENVIRONMENTS_TABLE.TABLE_HEADERS.FEATURE_${featureName}.TOOLTIP`, { ns: getK8sNamespace('common') }),
            sortable: true,
            cellRenderer: WorkloadFeatureStatusRenderer,
            cellRendererParams: {
                type: featureName,
                name: i18n.t(`ENVIRONMENTS_TABLE.TABLE_HEADERS.FEATURE_${featureName}.HEADER`, { ns: getK8sNamespace('common') }),
                disabledLink: (data: IEnvironment) => generateHref(`/workload/environments/${Vendors.KUBERNETES}/${data.id}`),
            }
        }))
    ];
    EnvironmentsTableRegistry.addColumnDefs(columnDefs);
};