import { Alert, Stack, Typography } from 'common/design-system/components-v2';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';

const HelmNotes = () => {
    const { t } = useTranslation(getK8sNamespace('cluster'));

    return (
        <Stack spacing={1}>
            <Alert type='warning'>
                <Stack spacing={1}>
                    <Typography variant="body500">{t('bladeChangeConfirmation.noticeTitle')}</Typography>
                    <Typography>{t('bladeChangeConfirmation.k3sNotice')}</Typography>
                </Stack>
            </Alert>
            <Alert type='warning'>
                <Stack spacing={1}>
                    <Typography variant="body500">{t('bladeChangeConfirmation.noticeTitle')}</Typography>
                    <Typography>{t('bladeChangeConfirmation.gkeAutopilotNotice')}</Typography>
                </Stack>
            </Alert>
        </Stack>
    );
};

export default HelmNotes;