import React from 'react';
import { Label } from '@dome9/berries/react-components';
import { OperatingSystemsEnum } from '../../ShiftleftOnboarding/steps/OperatingSystem/OperatingSystem.types';
import { getCurrentDataCenter } from 'common/utils/RuntimeEnvironment';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack, Command } from 'common/design-system/components-v2';
import { runCommandLinuxMac, runCommandWindows } from '../OperatingSystemContent/commands';
import { Title, WithModalWidth } from './InstructionsContent.styled';

type InstructionsGetCommand = (cloudguardId: string, secret: string, region: string) => string
const instructions: { [key in OperatingSystemsEnum]: InstructionsGetCommand } = {
    [OperatingSystemsEnum.Windows]: (cloudguardId, secret, region) =>
        `set CHKP_CLOUDGUARD_ID=${cloudguardId}
set CHKP_CLOUDGUARD_SECRET=${secret}
set SHIFTLEFT_REGION=${region}`,
    [OperatingSystemsEnum.Linux]: (cloudguardId, secret, region) =>
        `export CHKP_CLOUDGUARD_ID=${cloudguardId}
export CHKP_CLOUDGUARD_SECRET=${secret}
export SHIFTLEFT_REGION=${region}`,
    [OperatingSystemsEnum.Mac]: (cloudguardId, secret, region) =>
        `export CHKP_CLOUDGUARD_ID=${cloudguardId}
export CHKP_CLOUDGUARD_SECRET=${secret}
export SHIFTLEFT_REGION=${region}`
};

interface InstructionsContentProps {
    envId: string; // need to check too from interface
    apiKey?: string; // need to check too from interface
    apiSecret?: string; // need to check too from interface;
    isModal?: boolean
}

const InstructionsContent: React.FC<InstructionsContentProps> = ({
    envId,
    apiKey = '<apiKey>',
    apiSecret = '<apiSecret>',
    isModal = false
}) => {
    const { getValues } = useFormContext();
    const operatingSystemsSelectedValue: OperatingSystemsEnum = getValues('operatingSystems');
    const getCommand = instructions[operatingSystemsSelectedValue];
    const { t } = useTranslation('k8s_shiftleft');

    const labelInstructions = `
    ${operatingSystemsSelectedValue === OperatingSystemsEnum.Mac ? ' ~/.bash_profile'
        : (operatingSystemsSelectedValue === OperatingSystemsEnum.Windows) ? ' Windows shell'
            : (operatingSystemsSelectedValue === OperatingSystemsEnum.Linux) ? ' ~/.profile'
                : null}`;

    return (
        <Stack spacing={4}>
            {!isModal && <Title>{`${t('shiftLeftOnboarding.steps.instructions.title')} ${operatingSystemsSelectedValue}`}</Title>}
            <Stack spacing={1}>
                <Label label={`${t('shiftLeftOnboarding.steps.instructions.instruction')} ${labelInstructions}`}/>
                <WithModalWidth isModal={isModal}>
                    <Command text={getCommand && getCommand(apiKey, apiSecret, getCurrentDataCenter()?.location || '<region>')} />
                </WithModalWidth>
            </Stack>
            <Stack spacing={2}>
                <Label label={'Run command:'} />
                <WithModalWidth isModal={isModal}>
                    {(operatingSystemsSelectedValue === OperatingSystemsEnum.Mac || operatingSystemsSelectedValue === OperatingSystemsEnum.Linux) ?
                        <Command text={runCommandLinuxMac(envId)}/> : <Command text={runCommandWindows(envId)}/>
                    }
                </WithModalWidth>
            </Stack>
        </Stack>
    );
};

export default InstructionsContent;
