import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './AuthMethodSelector.styled';
import { AuthInputFieldProps, AuthMethodSelectorProps, ErrorMessages } from './AuthMethodSelector.types';
import { getRegistryOptions, shouldUseApplicationClientId, shouldUsePullSecretName, shouldUseRoleARN, shouldUseTenantId } from 'modules/workloads/utils/RegistryOptions/RegistryOptions';
import Select from '../Select';
import { Stack, Typography, Input } from 'common/design-system/components-v2';

const AuthMethodSelector: React.FC<AuthMethodSelectorProps> = ({ registryType, scanningEnvironmentType, selectedAuthMethod, control, errors }) => {
    const { t } = useTranslation('k8s_registry');
    const RegistryOptions = getRegistryOptions();

    const options = RegistryOptions[registryType].authMethods[scanningEnvironmentType].map(({ title, key }) => {
        return { label: t(title), value: key };
    });

    return (
        <Stack spacing={2}>
            <Wrapper>
                <Typography elementType='span' color='light'>{t('ON_BOARDING.AUTH_METHOD')}</Typography>
                <Controller
                    name='authMethod'
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            closeMenuOnSelect
                            options={options}
                            onChange={(value: string) => field.onChange(value)}
                            error={false}
                            placeholder={t('registryPage.scanners.authMethodPlaceholder')}
                            value={selectedAuthMethod}
                        />
                    )}
                />
            </Wrapper>
            {shouldUsePullSecretName(registryType, scanningEnvironmentType, selectedAuthMethod) && (
                <AuthInputField
                    fieldName='pullSecretName'
                    label={t('ON_BOARDING.PULL_SECRET')}
                    control={control}
                    errors={errors}
                />
            )}
            {shouldUseTenantId(registryType, scanningEnvironmentType, selectedAuthMethod) && (
                <AuthInputField
                    fieldName='tenantId'
                    label={t('ON_BOARDING.TENANT_ID')}
                    control={control}
                    errors={errors}
                />
            )}
            {shouldUseApplicationClientId(registryType, scanningEnvironmentType, selectedAuthMethod) && (
                <AuthInputField
                    fieldName='applicationClientId'
                    label={t('ON_BOARDING.APP_CLIENT_ID')}
                    control={control}
                    errors={errors}
                />
            )}
            {shouldUseRoleARN(registryType, scanningEnvironmentType, selectedAuthMethod) && (
                <AuthInputField
                    fieldName='roleArn'
                    label={t('ON_BOARDING.ROLE_ARN')}
                    control={control}
                    errors={errors}
                />
            )}
        </Stack>
    );
};

const AuthInputField = ({ fieldName, label, control, errors }: AuthInputFieldProps) => (
    <Wrapper>
        <Controller
            name={fieldName}
            control={control}
            rules={{ required: true, minLength: 3 }}
            render={({ field }) => (
                <Input
                    {...field}
                    label={label}
                    required
                    direction='horizontal'
                    isError={!!errors[fieldName]}
                    onChange={field.onChange}
                    helperText={errors[fieldName] && ErrorMessages[errors[fieldName]?.type as keyof typeof ErrorMessages]}
                    fullWidth
                />
            )}
        />
    </Wrapper>
);

export default AuthMethodSelector;