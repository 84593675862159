import React from 'react';
import ScanRegistryModal from './ScanRegistryModal/ScanRegistryModal';
import RemoveClusterModal from './RemoveClusterModal/RemoveClusterModal';
import EditClusterModal from './EditClusterModal/EditClusterModal';
import { ModalType } from '../../KubernetesEnvironment.types';
import RestoreInstallCommandModal from './RestoreInstallCommandModal/RestoreInstallCommandModal';

export interface ModalsProps {
    clusterId: string;
    modalType: ModalType | null;
    closeModal: () => void
}

const Modals: React.FC<ModalsProps> = props => {
    switch (props.modalType) {
        case ModalType.ScanRegistry:
            return <ScanRegistryModal {...props} />;
        case ModalType.RemoveCluster:
            return <RemoveClusterModal {...props} />;
        case ModalType.EditCluster:
            return <EditClusterModal {...props} />;
        case ModalType.RestoreInstallCommand:
            return <RestoreInstallCommandModal {...props} />;
        default:
            return null;
    }
};

export default Modals;
