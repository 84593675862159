import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components/macro';

export const VerticalDivider = styled.div`
  width: 1px;
  min-height: 100%;
  background-color: ${({ theme }) => theme.palette.border.light};
`;

export const InstructionsWrapper = styled(Stack)`
  flex: 1;
  max-width: 685px;
  height: 100%;
`;