import { getHttpService } from 'common/interface/services';

export interface GetWorkloadAccountSettingFromServerResponse {
    [field: string]: {
        [field: string]: string;
    }
}
export const getWorkloadAccountSettingFromServer = async (): Promise<GetWorkloadAccountSettingFromServerResponse> =>
    await getHttpService().request('workload/accountSetting', { method: 'GET' });

export const putWorkloadAccountSettingFromServer = async (data: any): Promise<any> =>
    await getHttpService().request('workload/accountSetting', { method: 'PUT', data });

export const patchWorkloadAccountSettingFromServer = async (data: any): Promise<any> =>
    await getHttpService().request('workload/accountSetting', { method: 'PATCH', data });