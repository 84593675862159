import { useTranslation } from 'react-i18next';
import { LevelIcon, Stack, Typography } from 'common/design-system/components-v2';
import React from 'react';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import TopCvesTableStyles from './TopCvesTable.styled';
import useTopCves from '../hooks/useTopCves';
import CveTable from '../CveTable';
import ErrorState from '../components/ErrorState/ErrorState';

const TopCvesTable: React.FunctionComponent = () => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    const { topCvesData, isLoading, getTopCves, error } = useTopCves();

    React.useEffect(() => {
        getTopCves();
    }, [getTopCves]);

    return (
        <Stack fullWidth fullHeight spacing={4}>
            <TopCvesTableStyles.Header direction='row' padding={[3, 4]} alignItems='center' spacing={2}>
                <LevelIcon category='severity' level='critical' iconProps={{ name: 'cve' }} />
                <Stack spacing={1}>
                    <Typography variant='subtitleLg'>{t('topCves.title')}</Typography>
                    <Typography>{t('topCves.subtitle')}</Typography>
                </Stack>
            </TopCvesTableStyles.Header>
            {error ? <ErrorState /> : <CveTable mode='topCves' rowData={topCvesData} isLoading={isLoading} />}
        </Stack>
    );
};

export default TopCvesTable;
