import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useReactRouterQuery from 'common/hooks/useReactRouterQuery';
import { getNotificationsService, getRulesetService, getShiftLeftService } from 'common/interface/services';
import { IPolicy } from 'common/interface/policy';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { NotificationType } from 'common/interface/notifications';
import { finishFlow, saveUpdatePolicies } from 'common/components/policy/utils';
import { IRuleset, RulesetVersion } from 'common/interface/ruleset';
import { unScannedVulnerabilitiesObj, nonCompliantAdmissionsObj } from '../helpers/consts';
import { TargetTypesEnum, ImageAdmissionItem } from '../helpers/interface';
import Wizard, { RenderViewParams, WizardStep } from 'common/components/Wizard/Wizard';
import { RulesetVersionSelectComponent } from 'common/components/policy/RulesetVersionSelectComponent';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { ImageAdmissionSelectionComponent } from '../components/ImageAdmissionSelectionComponent/ImageAdmissionSelectionComponent';
import { Done } from 'common/components/policy/Done/Done';
import { useReturnUrl } from 'common/hooks/useReturnUrl';

const stepIds: any={
    notificationSelection: true,
    rulesetVersion: true,
    imageAdmissionSelection: true,
    done: true,
};

export const EditVulnerabilitiesPolicyPage: React.FC = () => {
    const { params } = useReactRouterQuery();
    const { t } = useTranslation('k8s_policy');
    const [policy,setPolicy] = useState<IPolicy>();
    const [selectedNotifications,setSelectedNotifications] = useState<ListItem[]>();
    const [flowStatusOK,setFlowStatusOK] = useState<boolean>();
    const [flowStatusMessage,setFlowStatusMessage] = useState<string>();
    const [selectedNonCompliantAdmission, setSelectedNonCompliantAdmission] = useState<ImageAdmissionItem>();
    const [selectedUnScannedAdmission, setSelectedUnScannedAdmission] = useState<ImageAdmissionItem>();
    const [selectedRuleset,setSelectedRuleset] = useState<ListItem>();
    const [selectedVersion,setSelectedVersion] = useState<RulesetVersion>();
    const location = useLocation();
    const returnUrl = useReturnUrl();
    const saveChanges = useCallback(async () => {
        if(policy && selectedNotifications?.length){
            setFlowStatusMessage(t('GENERAL.PROCESSING'));
            const allRulesets = await getRulesetService().getAllRulesets();
            const ruleset = allRulesets.find((ruleset) => ruleset.id === policy.rulesetId);
            policy.rulesetPlatform = ruleset?.cloudVendor;
            policy.targetId = policy.targetId || policy.targetInternalId;
            policy.notificationIds = selectedNotifications.map((notification:ListItem) => notification.id);
            policy.rulesetVersion = selectedVersion?.version;
            if (selectedUnScannedAdmission && selectedNonCompliantAdmission) {
                policy.admissionControlUnScannedAction = selectedUnScannedAdmission?.type;
                policy.admissionControllerAction = selectedNonCompliantAdmission?.type;
            }
            setFlowStatusMessage(t('GENERAL.PROCESSING'));
            const result = await saveUpdatePolicies([policy], true);
            if(result) {
                setFlowStatusOK(true);
                setFlowStatusMessage(t('GENERAL.POLICY_ADDED_MESSAGE'));
            }else {
                setFlowStatusOK(false);
                setFlowStatusMessage(t('GENERAL.ERROR_OCCURRED'));
            }
        }
    },[policy, selectedNotifications, selectedUnScannedAdmission, selectedNonCompliantAdmission, selectedVersion?.version, t]);
    
    const steps :WizardStep[] = useMemo(() => [
        {
            id: 'rulesetVersion',
            name: t('GENERAL.SELECT_VERSION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <RulesetVersionSelectComponent onSelectedItemChanged={rulesetVersionChanged}
                    ruleset={selectedRuleset} preSelectedVersion={selectedVersion} {...renderViewParams}/>;
            },
            onNext: async ()=>{
                return;
            },
        },
        {
            id: 'imageAdmissionSelection',
            name: t('GENERAL.IMAGE_ADMISSION_SELECTION'),
            renderView: (onValidate:RenderViewParams) => {
                return <ImageAdmissionSelectionComponent {...onValidate}
                    preSelectedNonCompliant={selectedNonCompliantAdmission}
                    preSelectedUnScanned={selectedUnScannedAdmission}
                    selectedNonCompliantItemChanged={setSelectedNonCompliantAdmission}
                    selectedUnScannedChanged={setSelectedUnScannedAdmission}
                />;
            },
            onNext: async ()=> {
                return ;
            },
        },
        {
            id: 'notificationSelection',
            name: t('GENERAL.NOTIFICATION_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <NotificationsSelectionComponent preSelectedItems={selectedNotifications} {...renderViewParams } selectedNotificationsChanged={setSelectedNotifications}/>;
            },
            onNext: async ()=>{
                saveChanges();
            },
        },
        {
            id: 'done',
            name: t('GENERAL.DONE'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <Done message={flowStatusMessage} {...renderViewParams} hasError={!flowStatusOK}></Done>;
            },
            onNext: async ()=>{
                finishFlow(returnUrl);
            },
        }
    ],[selectedRuleset, selectedVersion, selectedNonCompliantAdmission, selectedUnScannedAdmission, selectedNotifications, saveChanges, flowStatusMessage, flowStatusOK, history, t]);


    useEffect(() => {
        const raisePolicyNotFoundErrorMessage = () => {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: t('GENERAL.ERROR_OCCURRED'),
                text: t('GENERAL.POLICY_NOT_FOUND'),
            });
        };
        const handlePolicyLoad = async (policyId: string) => {
            const allPolicies = await getShiftLeftService().getPolicies(false);
            const _policy = allPolicies.find(policy => policy.id === policyId);
            if(_policy){
                setPolicy(_policy);
                setSelectedNotifications(_policy.notificationIds.map((notificationId:string) => ({ id: notificationId })) as ListItem[]);
                const allRuleset = await getRulesetService().getAllRulesets();
                const ruleset : IRuleset | undefined = allRuleset.find((ruleset:IRuleset) => ruleset.id === _policy.rulesetId);
                if (ruleset) {
                    const item = {
                        id: ruleset.id.toString(),
                        name: ruleset.name,
                        icon: ruleset.icon,
                        availableVersions: [...ruleset.availableVersions, { version: 'Latest' }],
                        versionEnabled: true,
                        platform: ruleset.cloudVendor,
                    };
                    setSelectedRuleset(item);
                    setSelectedVersion({ version: _policy.rulesetVersion || '', createdTime: ruleset.availableVersions.find(ruleset=>ruleset.version === _policy.rulesetVersion)?.createdTime });
                    setSelectedNonCompliantAdmission(nonCompliantAdmissionsObj(t)[_policy.admissionControllerAction || 'null']);
                    setSelectedUnScannedAdmission(unScannedVulnerabilitiesObj(t)[_policy.admissionControlUnScannedAction || 'null']);
                    stepIds.rulesetVersion = !!(ruleset?.availableVersions.length);
                    stepIds.imageAdmissionSelection = ([TargetTypesEnum.organizationalUnit, TargetTypesEnum.kubernetes] as string[]).includes(_policy.targetType ?? '');
                    stepIds.done = true;
                    stepIds.notificationSelection = true;
                }
            } else {
                raisePolicyNotFoundErrorMessage();
            }
        };
        if(!policy){
            const policyId = params?.policyId;
            if(!policyId){
                raisePolicyNotFoundErrorMessage();
                return;
            } else {
                handlePolicyLoad(policyId);
            }
        }
    }, [selectedRuleset, location, steps.length, policy, params, t]);

    const rulesetVersionChanged = (item: ListItem) => {
        setSelectedVersion(item.selectedVersion);
    };
    const getSteps = () => {
        return steps?.filter((step) => step.id && stepIds[step.id]);
    };

    return (
        <div>
            {getSteps().length ? <Wizard
                onClose={()=>finishFlow(returnUrl)}
                steps={getSteps()}
                title={t('GENERAL.EDIT_POLICY')}
                doneButtonText={t('GENERAL.DONE')}
                ShowBackButtonInLastStep={false}
            /> : null}
        </div>
    );
};