import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { Vendors, getVendor } from 'common/consts/vendors';
import { changeUrl, generateHref } from 'common/utils/http';
import React, { SyntheticEvent } from 'react';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { ICloudAccount, getCloudAccountsService } from 'common/interface/data_services';

const getEnvironmentLinkUrl = (platform: string, cloudAccountId: string) => {
    const workloadPlatformTypes = [Vendors.KUBERNETES, Vendors.CONTAINER_REGISTRY];
    if (workloadPlatformTypes.some(type => type === platform)) {
        return `/workload/environments/${platform}/${cloudAccountId}`;
    }
    return `/cloud-account/${platform}/${cloudAccountId}`;
};

const EnvironmentCellRenderer: React.FC<ICellRendererParams<IProtectedAssetViewModel>> = (params) => {
    const [relevantCloudAccount, setRelevantCloudAccount] = React.useState<ICloudAccount | null>(null);

    React.useEffect(() => {
        const setCloudAccount = async () => {
            const cloudAccount = await getCloudAccountsService().getCloudAccountByAccountId(params.value || '');
            setRelevantCloudAccount(cloudAccount);
        };
        setCloudAccount();
    }, [params.value]);

    const linkOptions = React.useMemo<IIconLinkCellProps | undefined>(() => {
        if (!relevantCloudAccount) {
            return undefined;
        }
        const platform = getVendor(relevantCloudAccount.platform);
        if (!platform) {
            return undefined;
        }

        const url = getEnvironmentLinkUrl(platform.name, relevantCloudAccount.id);
        return {
            isLink: true,
            value: relevantCloudAccount.name,
            href: generateHref(url),
            onClick: (event: SyntheticEvent) => {
                event.preventDefault();
                changeUrl(url);
            },
        };
    }, [relevantCloudAccount]);

    if (!linkOptions) return params.value;

    return <IconLinkCellRenderer {...linkOptions} />;
};

export default EnvironmentCellRenderer;