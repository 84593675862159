import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetRegistry } from 'modules/workloads/reducers/registries/hooks/useGetRegistry';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { EditRegistryFormValues, EditRegistryModalProps } from './EditRegistryModal.types';
import { useUpdateRegistry } from 'modules/workloads/reducers/registries/hooks/useUpdateRegistry';
import { useForm, Controller } from 'react-hook-form';
import EditRegistryModalStyles from './EditRegistryModal.styles';
import { Message, Typography, Input } from 'common/design-system/components-v2';
import TreeData from 'common/components/TreeData/TreeData';
import { useOrganizationalUnits } from 'common/hooks/useOrganizationalUnit';

const EditRegistryModal: React.FunctionComponent<EditRegistryModalProps> = ({ registryId, closeModal }) => {
    const { t } = useTranslation(['k8s_common', 'k8s_registry']);
    const { isLoading: isLoadingRegistry, isError, data } = useGetRegistry(registryId);
    const { isLoading: isLoadingOrganizationalUnits, organizationalUnits } = useOrganizationalUnits();
    const isLoading = isLoadingRegistry || isLoadingOrganizationalUnits; 

    const handleOnUpdateSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('k8s_registry:registryPage.modals.editRegistry.successMessage'),
            text: '',
        });
        closeModal();
    };
    const handleOnUpdateError = () => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('k8s_registry:registryPage.modals.editRegistry.failMessage'),
            text: '',
        });
    };

    const { updateRegistry, isLoading: updateIsLoading } = useUpdateRegistry({ onSuccess: handleOnUpdateSuccess, onError: handleOnUpdateError });


    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        getValues,
    } = useForm<EditRegistryFormValues>({
        defaultValues: {
            name: data?.account.name,
            organizationalUnitId: data?.account.organizationalUnitId,
            description: data?.account.description
        },
    });

    if (isLoading || isError || !data) return null;
    
    const OnEditRegistryConfirm = async (data: EditRegistryFormValues) => {
        const { name, description, organizationalUnitId } = data;
        updateRegistry(registryId, { description, name, organizationalUnitId });
    };

    return (
        <Message
            title={t('k8s_registry:registryPage.modals.editRegistry.title')}
            width='lg'
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={handleSubmit((data) => OnEditRegistryConfirm(data))}
            isLoading={updateIsLoading}
        >
            <EditRegistryModalStyles.FormWrapper>
                <Controller
                    name='name'
                    rules={{
                        required: t('k8s_common:validations.required'),
                        minLength: {
                            value: 2,
                            message: t('k8s_common:validations.minLength', { num: 2 })
                        }
                    }}
                    control={control}
                    render={({ field }) => (
                        <Input
                            {...field}
                            required
                            label={t('k8s_registry:registryPage.modals.editRegistry.name.label')}
                            placeholder={t('k8s_registry:registryPage.modals.editRegistry.name.placeholder')}
                            helperText={errors['name'] ? errors['name']['message'] : ''}
                            isError={!!errors['name']}
                            clearable
                            fullWidth
                        />

                    )}
                />
                <EditRegistryModalStyles.FormItemWrapper>
                    <Typography color='light'>{t('k8s_registry:registryPage.modals.editRegistry.organizationalUnitId.label')}</Typography>
                    <Controller
                        name='organizationalUnitId'
                        control={control}
                        render={({ field }) => (
                            <TreeData
                                {...field}
                                value={getValues('organizationalUnitId')}
                                placeholder={t('k8s_registry:registryPage.modals.editRegistry.organizationalUnitId.placeholder')}
                                options={organizationalUnits}
                                onChange={(organizationalUnitId) => {
                                    setValue('organizationalUnitId', organizationalUnitId);
                                    return true;
                                }}
                                isLoading={isLoading}
                            />
                        )}
                    />
                </EditRegistryModalStyles.FormItemWrapper>
                <Controller
                    name='description'
                    control={control}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label={t('k8s_registry:registryPage.modals.editRegistry.description.label')}
                            placeholder={t('k8s_registry:registryPage.modals.editRegistry.description.placeholder')}
                            helperText={errors['description'] ? errors['description']['message'] : ''}
                            isError={!!errors['description']}
                            clearable
                            fullWidth
                        />

                    )}
                />
            </EditRegistryModalStyles.FormWrapper>
        </Message>
    );
};

export default EditRegistryModal;
