import { RegistrySlice } from './registry.slice';
export { RegistrySlice };

export const {
    clearRegistryOnboardingState,
    toggleIsLoading,
    goNextStep,
    goBackStep,
    goToSpecificStep,
    setServiceAccounts,
    setNewRegistry,
    setRegistryName,
    setRegistryDescription,
    setOrganizationalUnitId,
    setRegistryType,
    setRegistryUrl,
    setRegistryApplicationClientId,
    setRegistryApplicationClientIdErrorStatus,
    setRegistryPullSecretName,
    setRegistryTenantId,
    setRegistryRoleARN,
    setRegistryAuthMethod,
    setRegistryUrlErrorStatus,
    setRegistryUrlErrorMessage,
    setRegistryPullSecretNameErrorStatus,
    setRegistryTenantIdErrorStatus,
    setRegistryRoleARNErrorStatus,
    toggleImageAssuranceEnablePopup,
    setIsImageAssuranceEnabledWhileOnboarding,
    setRegistryAccountSummary,
    setRegistryAgentSummary,
    setImageScanAgentVersion,
    setSelectedRegistry,
    setAllRegistries,
    setScanningEnvironmentType
} = RegistrySlice.actions;

export default RegistrySlice.reducer;
