import styled from 'styled-components';

export const Title = styled.div`
  color: #333;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 10px;
`;

export const WithModalWidth = styled.div<{ isModal: boolean }>`
    width: ${({ isModal }) => isModal ? '100%' : '700px' };
`;