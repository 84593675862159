import { ColDef, ICellRendererParams, IHeaderParams } from 'ag-grid-community';
import IconLinkCellRenderer from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import EnvironmentCellRenderer from 'modules/workloads/protectedAsset/table/CellRenderers/EnvironmentCellRenderer';
import IsFixableCellRenderer from 'modules/workloads/CellRenders/IsFixableCellRenderer/IsFixableCellRenderer';
import { SyntheticEvent } from 'react';
import { openCveSearchDrawer } from 'modules/workloads/services/cveDrawerRegistry/cveDrawerRegistry.utils';
import { changeUrl } from 'common/utils/http';
import { PROTECTED_ASSETS_URL } from 'common/module_interface/assets/ProtectedAssets.consts';
import { generateAssetPageUrl } from 'common/module_interface/assets/utils';
import TooltipHeaderRenderer from 'modules/workloads/CellRenders/TooltipHeaderRenderer/TooltipHeaderRenderer';
import i18n from 'common/services/translations/translations';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

export const cveSearchColDefs = (mode: 'topCves' | 'cveSearch'): Array<ColDef> => [
    { 
        headerName: 'CVE ID',
        field: 'cve.id',
        enableRowGroup: false,
        valueGetter: (params) => {
            if (!params.data) return;
            return params.data.cve.id;
        },
        cellRenderer: ({ data, value }: ICellRendererParams) => {
            if (!data && !value) return null;
            if (!data && value) return value;
            return (
                <IconLinkCellRenderer
                    value={data.cve.id}
                    isLink={true}
                    levelIconProps={{
                        iconProps: { name: 'cve' },
                        category: 'severity',
                        level: data.cve.severity.toLowerCase(),
                        size: 'md'
                    }}
                    href={''}
                    onClick={ (event: SyntheticEvent) => {
                        event.preventDefault();
                        openCveSearchDrawer({
                            cveId: data.cve.id,
                            cloudAccountId: data.cloudAccountId,
                            scannedAssetExternalId: data.scannedAsset.externalId,
                            affectedAssetExternalId: data.affectedAsset.externalId,
                            packageId: data.package.id,
                            packagePath: data.package.path,
                            vectorString: data.cve.vectorString,
                        });
                    }}
                />
            );
        },
        flex: 2,
    },
    { 
        headerName: 'Affected Asset',
        field: 'affectedAsset.entityName',
        enableRowGroup: mode === 'cveSearch',
        headerComponent: (params: IHeaderParams) => (
            <TooltipHeaderRenderer
                headerParams={params}
                tooltip={i18n.t('cols.affectedAsset.tooltip', { ns: getK8sNamespace('cve-explorer') })}
            />
        ),
        valueGetter: (params) => {
            if (!params.data) return;
            return `${params.data?.affectedAsset?.entityName} (${params.data?.affectedAsset?.externalId})`;
        },
        cellRenderer: (params: ICellRendererParams) => {
            if (!params.value) return null;
            const asset = getProtectedAssetsService().getAssetByType(params.data?.affectedAsset?.entityType);
            if (!asset) return params.value;

            const urlParms = asset.getUrl({
                asset: asset,
                entityId: params.data?.affectedAsset?.externalId,
                cloudAccountId: params.data?.cloudAccountId,
                entityType: params.data?.affectedAsset?.entityType,
                platform: asset.platform,
                dome9Id: params.data?.affectedAsset?.dome9Id,
                region: params.data?.affectedAsset?.region,
            });

            if (!urlParms) return params.value;
            const finalUrl = generateAssetPageUrl(asset, urlParms, PROTECTED_ASSETS_URL);
         
            return (
                <IconLinkCellRenderer
                    value={params.value}
                    isLink={!!finalUrl}
                    href={finalUrl}
                    iconProps={{ vendorNameOrPath: asset?.icon }}
                    onClick={(event: SyntheticEvent) => {
                        event.preventDefault();
                        changeUrl(finalUrl);
                    }}
                />
            );
        },
        flex: 3,
    },
    {
        headerName: 'Affected Asset Type',
        field: 'affectedAsset.entityType',
        valueGetter: (params) => {
            if (!params.data) return;
            return getProtectedAssetsService().getAssetByType(params.data?.affectedAsset?.entityType)?.displayName;
        },
        enableRowGroup: mode === 'cveSearch',
    },
    { 
        headerName: 'Scanned Asset',
        field: 'scannedAsset.entityName',
        enableRowGroup: mode === 'cveSearch',
        headerComponent: (params: IHeaderParams) => (
            <TooltipHeaderRenderer
                headerParams={params}
                tooltip={i18n.t('cols.scannedAsset.tooltip', { ns: getK8sNamespace('cve-explorer') })}
            />
        ),
        valueGetter: (params) => {
            if (!params.data) return;
            return `${params.data?.scannedAsset?.entityName} (${params.data?.scannedAsset?.externalId})`;
        },
        cellRenderer: (params: ICellRendererParams) => {
            if (!params.value) return;
            const asset = getProtectedAssetsService().getAssetByType(params.data?.scannedAsset?.entityType);
            if (!asset) return params.value;

            const urlParms = asset.getUrl({
                asset: asset,
                entityId: params.data?.scannedAsset?.externalId,
                cloudAccountId: params.data?.cloudAccountId,
                entityType: params.data?.scannedAsset?.entityType,
                platform: asset.platform,
                dome9Id: params.data?.scannedAsset?.dome9Id,
                region: params.data?.scannedAsset?.region,
            });

            if (!urlParms) return params.value;
            const finalUrl = generateAssetPageUrl(asset, urlParms, PROTECTED_ASSETS_URL);
         
            return (
                <IconLinkCellRenderer
                    value={params.value}
                    isLink={!!finalUrl}
                    href={finalUrl}
                    iconProps={{ vendorNameOrPath: asset.icon }}
                    onClick={(event: SyntheticEvent) => {
                        event.preventDefault();
                        changeUrl(finalUrl);
                    }}
                />
            );
        },
        flex: 3,
    },
    {
        headerName: 'Scanned Asset Type ',
        field: 'scannedAsset.entityType',
        valueGetter: (params) => {
            if (!params.data) return;
            return getProtectedAssetsService().getAssetByType(params.data?.scannedAsset?.entityType)?.displayName;
        },
        enableRowGroup: mode === 'cveSearch'
    },
    { headerName: 'Package Name', field: 'package.name', enableRowGroup: mode === 'cveSearch' },
    { headerName: 'Package Version', field: 'package.version', enableRowGroup: mode === 'cveSearch' },
    {
        headerName: 'Environment',
        field: 'cloudAccountId',
        cellRenderer: EnvironmentCellRenderer,
        enableRowGroup: mode === 'cveSearch',
    },
    { 
        headerName: 'Remediation',
        field: 'cve.remediation',
        cellRenderer: (params: ICellRendererParams) => <IsFixableCellRenderer {...params} />,
        maxWidth: 75,
    },
    {
        headerName: 'Scan Source',
        field: 'scan.producer',
        valueGetter: (params) => {
            if (!params.data) return;
            return params.data?.scan.producer === 'Inspector' ? 'AWS Inspector' : 'CloudGuard';
        },
        hide: true,
    },
];