import { EnvironmentTab, EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import i18n from 'common/services/translations/translations';
import { KubernetesAccountFeatureSummaryName } from '../services/workload/workload.interface';
import { getK8sNamespace } from '../initialize.i18n';
import WorkloadEnvironmentCellRenderer from './CellRenderers/EnvironmentCellRenderer';
import { Vendors } from 'common/consts/vendors';
import WorkloadOnBoardingTime from './CellRenderers/WorkloadOnBoardingTime';

export const initializeEnvironmentsTabsDefs= () => {
    const tabsDefs: EnvironmentTab[] = [
        {
            id: Vendors.KUBERNETES,
            label: i18n.t('ENVIRONMENTS_TABLE.TABLE_TABS.K8S_CLUSTER', { ns: getK8sNamespace('common') }),
            defaultFilters: 
            {
                fields: [
                    {
                        name: 'platform',
                        value: Vendors.KUBERNETES
                    }
                ]
            }
            ,
            actions: [
                'add-new-environment-kubernetes',
                'associateToOU',
                'multiDelete'
            ],
            columns: [
                { id: 'checkbox' },
                { id: 'name', overrides: { cellRenderer: WorkloadEnvironmentCellRenderer } },
                { id: 'description', overrides: { initialHide: false } },
                { id: 'organizationalUnit' },
                { id: 'status', overrides: { position: 1, width: 140 } },
                { id: 'kubernetesClusterVersion' },
                { id: 'workloadPlatformType' },
                { id: 'onboardingTime', overrides: { initialHide: false, cellRenderer: WorkloadOnBoardingTime } },
                ...Object.values(KubernetesAccountFeatureSummaryName).map((featureName) => ({
                    id: `kubernetesFeatures_${featureName}`,
                })),
            ],
            filters: [
                'organizational unit',
                'customData|status',
                'customData|workloadPlatformType'
            ],
            exportOptions: [
                'workload-export-all',
                'workload-export-filtered',
                'workload-export-kubernetes-agents'
            ]
        },
        {
            id: Vendors.CONTAINER_REGISTRY,
            label: i18n.t('ENVIRONMENTS_TABLE.TABLE_TABS.CONTAINER_REGISTRIES', { ns: getK8sNamespace('common') }),
            defaultFilters: 
            {
                fields: [
                    {
                        name: 'platform',
                        value: Vendors.CONTAINER_REGISTRY
                    }
                ]
            }
            ,
            actions: [
                'add-new-environment-container-registries',
                'associateToOU',
                'multiDelete'
            ],
            columns: [
                { id: 'checkbox' },
                { id: 'name', overrides: { cellRenderer: WorkloadEnvironmentCellRenderer } },
                { id: 'description', overrides: { initialHide: false } },
                { id: 'organizationalUnit' },
                { id: 'onboardingTime', overrides: { initialHide: false, cellRenderer: WorkloadOnBoardingTime } },
                { id: 'status', overrides: { position: 1, width: 140 } },
                { id: 'workloadPlatformType' }
            ],
            filters: [
                'organizational unit',
                'customData|status',
                'customData|workloadPlatformType'
            ],
            exportOptions: [
                'workload-export-all',
                'workload-export-filtered'
            ]
        },
        {
            id: Vendors.SHIFT_LEFT,
            label: i18n.t('ENVIRONMENTS_TABLE.TABLE_TABS.SHIFTLEFT', { ns: getK8sNamespace('common') }),
            defaultFilters: 
            {
                fields: [
                    {
                        name: 'platform',
                        value: Vendors.SHIFT_LEFT
                    }
                ]
            }
            ,
            actions: [
                'add-new-environment-shiftleft',
                'associateToOU',
                'multiDelete'
            ],
            columns: [
                { id: 'checkbox' },
                { id: 'name' },
                { id: 'description', overrides: { initialHide: false } },
                { id: 'organizationalUnit' },
                { id: 'onboardingTime', overrides: { initialHide: false, cellRenderer: WorkloadOnBoardingTime } },
            ],
            filters: [
                'organizational unit',
            ],
            exportOptions: [
                'workload-export-all',
                'workload-export-filtered'
            ]
        },
    ];
    EnvironmentsTableRegistry.addTabs(tabsDefs);
};