import { Stack } from 'common/design-system/components-v2';
import React from 'react';
import UpperPanel from './components/UpperPanel';
import DescriptionPanel from './components/DescriptionPanel';
import { CveDrawerProps } from './CveDrawer.types';
import AttackVectorPanel from './components/AttackVectorPanel/AttackVectorPanel';
import { RemediationPanel } from './components/RemediationPanel/RemediationPanel';
import { AssetPanel } from './components/AssetPanel/AssetPanel';
import ResourcePanel from './components/ResourcePanel';
import CveReferences from './components/CveReferences/CveReferences';
import CveService from 'modules/workloads/services/cve/cve.service';
import { CveOverview } from 'modules/workloads/services/cveDrawerRegistry/cveDrawerRegistry.types';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import CveDrawerStyled from './CveDrawer.styled';
import EmptyState from 'common/components/EmptyState/EmptyState';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import useReactRouterQuery from 'common/hooks/useReactRouterQuery';
import useSearchCveId from '../cveSearch/hooks/useSearchCveId';

const CveDrawer: React.FunctionComponent<CveDrawerProps> = ({ cveParams }) => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [cveOverview, setCveOverview] = React.useState<CveOverview>();
    const { deleteParams } = useReactRouterQuery();
    const { refetch } = useSearchCveId();

    React.useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const cveOverviews = await CveService.getCveInfo(cveParams);
                const data = cveOverviews.data.data.length > 0 ? cveOverviews.data.data[0] : undefined;
                setCveOverview(data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [cveParams]);

    if (isLoading) {
        return (
            <CveDrawerStyled.LoaderWrapper>
                <CloudAnimationLoader size='medium' />
            </CveDrawerStyled.LoaderWrapper>
        );
    }

    const onRefresh = () => {
        deleteParams(['drawer']);
        refetch();
    };

    if (!cveOverview) {
        return (
            <EmptyState
                label={t('cveDrawer.noData')}
                buttons={[
                    { iconProps: { name: 'refresh' }, label: t('cveDrawer.closeAndRefresh'), onClick: onRefresh }
                ]}
            />
        );
    }

    const isWorkloadAsset = cveOverview.scannedAsset?.externalId !== cveOverview.affectedAsset?.externalId;

    return (
        <Stack direction='column' spacing={5} fullWidth>
            <UpperPanel isCveExplorer overview={cveOverview} />
            <DescriptionPanel overview={cveOverview} />
            {!!cveOverview.cve.cvssInfo && <AttackVectorPanel overview={cveOverview} />}
            {!!cveOverview.cve.remediation && <RemediationPanel remediation={cveOverview.cve.remediation} />}
            {cveOverview.affectedAsset && <AssetPanel asset={cveOverview.affectedAsset} overview={cveOverview} title={isWorkloadAsset ? t('cveDrawer.affectedAsset') : t('cveDrawer.affectedWorkload')}/>}
            {isWorkloadAsset && cveOverview.scannedAsset && <AssetPanel asset={cveOverview.scannedAsset} overview={cveOverview} title={t('cveDrawer.scannedAsset')} />}
            <ResourcePanel overview={cveOverview} />
            {cveOverview.cve.urls?.length > 0 && <CveReferences overview={cveOverview} />}
        </Stack>
    );
};

export default CveDrawer;
