import { getHttpService } from 'common/interface/services';

export const saveRegistry = async (data: any): Promise<any> => {
    return await getHttpService().request('containerRegistry/account', { method: 'POST', data }, {}, (e: any) => {
        throw { message: e.response.data.message };
    });
};

export const deleteRegistry = async (id: any): Promise<any> => {
    return await getHttpService().request(`containerRegistry/account/${id}`, { method: 'DELETE' });
};

export const getRegistry = async (id: any): Promise<any> => {
    return await getHttpService().request(`containerRegistry/account/${id}`, { method: 'GET' });
};

export const getRegistryAgentSummary = async (id: string): Promise<any> => {
    return await getHttpService().request(`containerRegistry/account/${id}/agentSummary`, { method: 'GET' });
};

export const getRegistryAccountSummary = async (id: string): Promise<any> => {
    return await getHttpService().request(`containerRegistry/account/${id}/accountSummary`, { method: 'GET' }, undefined, () => undefined);
};

export const renameRegistry = async (id: any, newName: string): Promise<any> => {
    return await getHttpService().request(`containerRegistry/account/${id}/accountName/${newName}`, { method: 'PUT' });
};

export const unlinkClusterToRegistryService = async (
    id: string,
    linkedKubernetesEnvironmentId: string
): Promise<any> => {
    return await getHttpService().request(
        `containerRegistry/account/${id}/linkedKubernetesEnvironment/${linkedKubernetesEnvironmentId}`,
        { method: 'DELETE' }
    );
};

export const linkClusterToRegistryService = async (id: string, data: any): Promise<any> => {
    return await getHttpService().request(`containerRegistry/account/${id}/linkedKubernetesEnvironment`, {
        method: 'PUT',
        data,
    });
};

export const getContainerRegistryAccountSummary = async (id: any): Promise<any> => {
    return await getHttpService().request(`containerRegistry/account/${id}/accountSummary`, { method: 'GET' });
};

export const getAllRegistries = async (): Promise<any> => {
    return await getHttpService().request('containerRegistry/account', { method: 'GET' });
};
