import React from 'react';
import { Icon } from '@dome9/berries/react-components';
import { CustomCloudGuardIconWrapper, CustomCloudGuardIcon } from './CloudKubernetesIcon.styles';

interface CloudKubernetesIconProps {
    size?: 'small' | 'large';
    title?: string;
}

const CloudKubernetesIcon: React.FunctionComponent<CloudKubernetesIconProps> = ({ size = 'large', title }) => {
    return (
        <CustomCloudGuardIconWrapper size={size}>
            <CustomCloudGuardIcon size={size}>
                <Icon name='cloudGuard' size={size === 'large' ? 128 : 64} />
                <div>
                    <Icon name='kubernetes' size={size === 'large' ? 64 : 32} />
                    <Icon name='kubernetes' size={size === 'large' ? 64 : 32} />
                </div>
            </CustomCloudGuardIcon>
            {title && <p>{title}</p>}
        </CustomCloudGuardIconWrapper>
    );
};

export default CloudKubernetesIcon;
