import React from 'react';
import { AgGridReact, AgGridReactProps, AgReactUiProps } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { RegistryAgentSummaryWithLinkedEnv, ScannersTableProps } from './ScannersTable.types';
import AgentsTableStyles from './ScannersTable.styles';
import dayjs from 'dayjs';
import { useGetRegistry } from 'modules/workloads/reducers/registries/hooks/useGetRegistry';
import { LinkedEnvironment } from 'modules/workloads/services/containerRegistry/containerRegistry.interface';
import EmptyState from 'common/components/EmptyState';
import { Vendors } from 'common/consts/vendors';
import { ScannerModalTypes } from '../../Scanners.types';
import { IconButton, Icon, Stack, BlockInfo } from 'common/design-system/components-v2';
import SeeDocumentation from 'modules/workloads/components/SeeDocumentation';
import { getCheckpointUrl } from 'common/components/Forms/urls';

const ScannersTable: React.FunctionComponent<ScannersTableProps> = ({ registryId, openModal }) => {
    const { t } = useTranslation('k8s_registry');
    const { isLoading, isError, data } = useGetRegistry(registryId);

    const dataForTable = React.useMemo<Array<RegistryAgentSummaryWithLinkedEnv>>(() => {
        if (!data) return [];

        const linkedEnvsObject = data.account.linkedScanningEnvironments.reduce<{ [envId: string]: LinkedEnvironment }>((acc, linkedEnv) => {
            acc[linkedEnv.environmentId] = linkedEnv;
            return acc;
        }, {});

        return data.agentSummary.map<RegistryAgentSummaryWithLinkedEnv>(agent => {
            const mappedData: RegistryAgentSummaryWithLinkedEnv = { ...agent };
            if (linkedEnvsObject[agent.kubernetesAccountId]) {
                mappedData.authMethods = linkedEnvsObject[agent.kubernetesAccountId];
            }
            return mappedData;
        });
    }, [data]);

    const agGridProps: AgGridReactProps & AgReactUiProps = React.useMemo(() => ({
        masterDetail: true,
        getRowId: (params: any) => params.data.kubernetesAccountId,
        columnDefs: [
            { field: 'kubernetesAccountName', headerName: '', cellRenderer: 'agGroupCellRenderer', minWidth: 40, maxWidth: 40, valueFormatter: () => '', resizable: false },
            {
                field: 'kubernetesAccountName',
                headerName: t('registryPage.scanners.table.name'),
                headerTooltip: t('registryPage.scanners.table.name'),
                cellRenderer: (params: any) => {
                    const scanningEnvironmentType = params.data.authMethods.scanningEnvironmentType;
                    const newUrlPrefix = (scanningEnvironmentType === Vendors.KUBERNETES || scanningEnvironmentType === Vendors.CONTAINER_REGISTRY) ? 'workload/environments' : 'cloud-account';
                    return (
                        <AgentsTableStyles.ALink href={`/${newUrlPrefix}/${params.data.authMethods.scanningEnvironmentType}/${params.data.kubernetesAccountId}`}>
                            {params.value}
                        </AgentsTableStyles.ALink>
                    );
                }
            },
            {
                field: 'authMethods.scanningEnvironmentType',
                headerName: t('registryPage.scanners.table.scannerType'),
                headerTooltip: t('registryPage.scanners.table.scannerType'),
                cellRenderer: (params: any) => (
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <Icon name={params.value.toLowerCase()} size={20} />
                        <span>{params.value}</span>
                    </Stack>
                )
            },
            {
                field: 'status',
                headerName: t('registryPage.scanners.table.status'),
                headerTooltip: t('registryPage.scanners.table.status'),
                valueFormatter: (params: any) => params.value ? t(`registryPage.scanners.statuses.${params.value}`, { defaultValue: params.value }) : 'N/A'
            },
            {
                field: 'lastCommunicationDate',
                headerName: t('registryPage.scanners.table.lastCommunication'),
                headerTooltip: t('registryPage.scanners.table.lastCommunication'),
                valueFormatter: (params: any) => params.value ? dayjs(new Date(params.value)).format('MMM D, YYYY h:mm A Z') : 'N/A',
            },
            {
                field: 'version',
                headerName: t('registryPage.scanners.table.version'),
                headerTooltip: t('registryPage.scanners.table.version'),
                valueFormatter: (params: any) => params.value || 'N/A',
            },
            {
                field: 'description',
                headerName: t('registryPage.scanners.table.description'),
                headerTooltip: t('registryPage.scanners.table.description'),
                tooltipField: 'description',
                cellRenderer: (params: any) => {
                    const { description } = params.data;
                    if (description && description.includes('Agent is disconnected')) {
                        return (
                            <Stack direction='row' alignItems='center' spacing={2}>
                                {t('registryPage.scanners.table.agentIsDisconnected')}
                                <SeeDocumentation
                                    showText={false}
                                    link={getCheckpointUrl('ERRORS_TROUBLESHOOTING_IN_IMAGESCAN_AGENT')}
                                />
                            </Stack>  
                        );
                    }
                    return params.value || 'N/A';
                },
            },
            {
                field: 'actions',
                headerName: t('registryPage.scanners.table.actions'),
                headerTooltip: t('registryPage.scanners.table.actions'),
                cellRenderer: (params: any) => (
                    <Stack direction='row' fullHeight>
                        <IconButton iconProps={{ name: 'unlink' }} tooltip='Unlink' onClick={() => openModal(ScannerModalTypes.REMOVE, dataForTable[params.rowIndex])} />
                        <IconButton iconProps={{ name: 'edit' }} tooltip='Edit' onClick={() => openModal(ScannerModalTypes.EDIT, dataForTable[params.rowIndex])} />
                    </Stack>
                )
            },
        ],
        defaultColDef: {
            flex: 1,
            suppressMenu: true,
            suppressMovable: true,
            lockPosition: true,
            resizable: true
        },
        enableCellTextSelection: true,
        headerHeight: 25,
        detailRowAutoHeight: true,
        domLayout: 'autoHeight',
        sideBar: false,
        lockPinned: true,
        rowData: dataForTable,
        tooltipShowDelay: 100,
        enableBrowserTooltips: true,
        suppressContextMenu: true,
        detailCellRenderer: (params: any) => {
            const { pullSecretName, tenantId, applicationClientId, registryAuthType, roleARN } = params.data.authMethods;
            return (
                <Stack direction='row' spacing={8} padding={[2, 10]}>
                    <BlockInfo title={t('registryPage.scanners.table.authMethod')} info={registryAuthType} />
                    {pullSecretName && (
                        <BlockInfo title={t('registryPage.scanners.table.pullSecretName')} info={pullSecretName} />
                    )}
                    {tenantId && (
                        <BlockInfo title={t('registryPage.scanners.table.tenantId')} info={tenantId} />
                    )}
                    {applicationClientId && (
                        <BlockInfo title={t('registryPage.scanners.table.applicationClientId')} info={applicationClientId} />
                    )}
                    {roleARN && (
                        <BlockInfo title={t('registryPage.scanners.table.roleARN')} info={roleARN} />
                    )}
                </Stack>
            );
        }
    }), [t, dataForTable, openModal]);

    if (isLoading || isError || !data) return null;

    if (dataForTable.length === 0) {
        return (
            <EmptyState
                iconSize={48}
                iconName={'noResults'}
                label={t('registryPage.scanners.noData.label')}
                description={t('registryPage.scanners.noData.description')}
            />
        );
    }

    return (
        <>
            <AgentsTableStyles.CustomAgGrid>
                <AgGridReact {...agGridProps} className='ag-theme-alpine' />
            </AgentsTableStyles.CustomAgGrid>
        </>
    );
};

export default ScannersTable;