import { createSelector } from '@reduxjs/toolkit';
import { K8SRootStore } from 'modules/workloads/initialize.redux';

const registriesReducer = (state: K8SRootStore) => state.k8s_registry;

const getRegistries = createSelector(registriesReducer, state => state.registries);
const getRegistriesLoading = createSelector(registriesReducer, state => state.registriesLoading);
const getRegistriesError = createSelector(registriesReducer, state => state.registriesError);

const getRegisry = createSelector([registriesReducer, (_state: K8SRootStore, id: string) => id], (state, id) => state.extendedRegistries[id]);

const getRegisrySettings = createSelector([registriesReducer, (_state: K8SRootStore, id: string) => id], (state, id) => state.registrySettings[id]);

export const RegistriesSelector = {
    getRegistries,
    getRegistriesLoading,
    getRegistriesError,
    getRegisry,
    getRegisrySettings
};