import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
    height: calc(100vh - 71px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface ReactTabContentProps {
    removePadding?: boolean;
}

const ReactTabContent = styled.div<ReactTabContentProps>`
    padding: ${({ removePadding, theme }) => removePadding ? 'unset' : `${theme.spacing(4)}${theme.units}`};
    height: 100%;
`;

const ToolbarButtonsWrapper = styled(Stack)`

    & > button:not(:last-child) {
        position: relative;
        
        &:after {
            content: '';
            display: block;
            position: absolute;
            right: -1px;
            top: 50%;
            width: 1px;
            height: 22px;
            background-color: ${({ theme }) => theme.palette.border.normal};
            transform: translate(50%, -50%);
        }
    }
`;

export default {
    LoaderWrapper,
    ReactTabContent,
    ToolbarButtonsWrapper
};
