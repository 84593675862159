import React from 'react';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { useTranslation } from 'react-i18next';
import { ChipWidgetButton } from './AwpScanChip.styles';
import { AwpScanChipProps, EnabledAwpScanChipProps } from './AwpScanChip.types';
import { useScanRequest } from 'modules/workloads/services/awp/hooks/useScanRequest/useScanRequest';
import { getNotificationsService } from 'common/interface/services';

export const AwpRescanChip: React.FC<AwpScanChipProps> = ({ tooltip, onClick, disabled }) => {
    const { t } = useTranslation(getK8sNamespace('common'));
    return (
        <ChipWidgetButton
            variant='text'
            tooltip={tooltip}
            disabled={disabled}
            onClick={onClick}
            size='small'
            iconProps={{ name: 'riskManagement4' }}
        >
            {t('workloadImageScan.agentlessScan.buttonText')}
        </ChipWidgetButton>
    );
};

export const EnableAwpScanChip: React.FC<EnabledAwpScanChipProps> = ({ cloudAccountId, entityId, platform }) => {
    const { t } = useTranslation(getK8sNamespace('common'));
    const { onScanRequest, isRequestPending } = useScanRequest({ cloudAccountId, entityId, cloudProvider: platform, addNotificationService: getNotificationsService() });

    return (
        <ChipWidgetButton
            variant='text'
            tooltip={t('workloadImageScan.agentlessScan.body')}
            disabled={isRequestPending}
            loading={isRequestPending}
            onClick={onScanRequest}
            size='small'
            iconProps={{ name: 'riskManagement4' }}
        >
            {t('workloadImageScan.agentlessScan.buttonText')}
        </ChipWidgetButton>
    );
};