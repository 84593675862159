import { IProtectedAssetDetailProvider } from 'common/module_interface/assets/ProtectedAssets';
import { InfoPanelActionProps } from 'common/components/InfoPane/InfoPane';
import { Vendors } from 'common/consts/vendors';
import KubernetesAccountService from '../../services/kubernetesAccount/kubernetesAccount.service';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import AssetDetailsAgentsVersionChipWidget from './widgets/Chips//AgentsVersionChip';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';
import ImageRescanChip from './widgets/Chips/ImageRescanChip';
import RiskScore from './widgets/Chips/RiskScore';
import { getFieldByNameFromAdditionalField } from '../../utils';
import { awpScanChipAddinActionFactory } from './widgets/Chips/AwpRescanChip/AwpRescanChip';
import { AssetTypes } from 'common/assets/assets.const';

const initializeDetailsPaneItems = () => {
    const K8sAgentsVersion: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: async (entity) => {
            const { cloudAccountId, cloudAccount: { platform } } = entity;

            if(platform !== Vendors.KUBERNETES) return false;
            try {
                const { data } = await KubernetesAccountService.getAgentSummary({ id: cloudAccountId });
                return data.some(agent => agent.agentSummary.some((agent) => !agent.isAgentUpToDate));
            } catch (error) {
                return false;
            }
        },
        getValue(entity: ICloudEntityData): InfoPanelActionProps {
            return {
                component: AssetDetailsAgentsVersionChipWidget,
                componentProps: { entity },

            };
        },
    };

    const ImageRescan: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: async ({ typeByPlatform }, isSummaryView) => {
            if (isSummaryView) return false;
            const relevantTypes = [
                AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE,
                AssetTypes.KUBERNETES_KUBERNETESIMAGE,
            ];
            return relevantTypes.some(type => type === typeByPlatform);
        },
        getValue(entity: ICloudEntityData): InfoPanelActionProps {
            return {
                component: ImageRescanChip,
                componentProps: entity,

            };
        },
    };

    const riskScore: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: async ({ protectedAsset: { typeByPlatform, additionalFields } }) => {
            const relevantTypes = [
                AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE,
                AssetTypes.KUBERNETES_KUBERNETESIMAGE,
                AssetTypes.AWS_ECSIMAGE,
                AssetTypes.SHIFTLEFT_SHIFTLEFTIMAGE,            
            ];
            return relevantTypes.some(type => type === typeByPlatform) && !!getFieldByNameFromAdditionalField(additionalFields)('RiskScore')?.value;
        },
        getValue(entity: ICloudEntityData): InfoPanelActionProps {
            return {
                component: RiskScore,
                componentProps: {
                    riskScore: getFieldByNameFromAdditionalField(entity.protectedAsset.additionalFields)('RiskScore')?.value }
            };
        },
    };

    ProtectedAssetsPageRegistry.addChipProviders([
        {
            id: 'K8s Agents version',
            position: 3,
            content: K8sAgentsVersion,
        },
        {
            id: 'Workloads Images Rescan',
            position: 4,
            content: ImageRescan,
        },
        {
            id: 'risk score image',
            position: 2,
            content: riskScore,
        },
        ...awpScanChipAddinActionFactory('rescan request chip', 1000),
    ]);
};

export { initializeDetailsPaneItems };