import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PullSecretNameInputProps, Tooltip } from './PullSecretNameInput.interface';
import { AuthFieldInput } from '../index';
import { tooltipUrls } from '../components.consts';
import { RegistryType } from 'modules/workloads/utils/RegistryOptions/RegistryOptions';

const PullSecretNameInput: React.FC<PullSecretNameInputProps> = (props) => {
    const { pullSecret, authMethod, setPullSecretName, registryType } = props;
    const { t } = useTranslation('k8s_registry');

    const tooltip = useMemo<Tooltip | undefined>(() => {
        switch (registryType) {
            case RegistryType.ACR:
                return {
                    text: t('ON_BOARDING.AD_PULL_SECRET_TOOLTIP'),
                    url: tooltipUrls.AD_PULL_SECRET_NAME,
                };
            case RegistryType.ECR:
                return {
                    text: t('ON_BOARDING.AWS_PULL_SECRET_TOOLTIP'),
                    url: tooltipUrls.AWS_PULL_SECRET_NAME,
                };
            case RegistryType.GCR:
                return {
                    text: t('ON_BOARDING.GCP_PULL_SECRET_TOOLTIP'),
                    url: tooltipUrls.GCR_PULL_SECRET_NAME,
                };
            case RegistryType.GAR:
                return {
                    text: t('ON_BOARDING.GCP_PULL_SECRET_TOOLTIP'),
                    url: tooltipUrls.GCR_PULL_SECRET_NAME,
                };
            case RegistryType.JFrogArtifactory:
                switch (authMethod) {
                    case 'ArtifactoryUserPassword':
                        return {
                            text: t('ON_BOARDING.JFROG_ARTIFACTORY_BASIC_AUTHENTICATION_PULL_SECRET_TOOLTIP'),
                            url: tooltipUrls.JFROG_ARTIFACTORY,
                        };
                    case 'ArtifactoryToken':
                        return {
                            text: t('ON_BOARDING.JFROG_ARTIFACTORY_ACCESS_TOKEN_PULL_SECRET_TOOLTIP'),
                            url: tooltipUrls.JFROG_ARTIFACTORY,
                        };
                }
                break;
            case RegistryType.Harbor:
                return {
                    text: t('ON_BOARDING.HARBOR_PULL_SECRET_TOOLTIP'),
                    url: tooltipUrls.HARBOR_PUll_SECRET_NAME,
                };
            case RegistryType.Nexus:
                return {
                    text: t('ON_BOARDING.NEXUS_PULL_SECRET_TOOLTIP'),
                    url: tooltipUrls.NEXUS_PUll_SECRET_NAME
                };
            case RegistryType.GitHub:
                return {
                    text: t('ON_BOARDING.GITHUB_PULL_SECRET_TOOLTIP'),
                    url: tooltipUrls.GITHUB_PUll_SECRET_NAME
                };
        }
    }, [t, registryType, authMethod]);

    return (
        <AuthFieldInput
            authFieldName={t('ON_BOARDING.PULL_SECRET')}
            tooltipText={tooltip?.text}
            tooltipUrl={tooltip?.url}
            authProp={pullSecret}
            onChange={setPullSecretName}
        />
    );
};

export default PullSecretNameInput;