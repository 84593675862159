
import { RegistryAuthMethod, RegistryType, ScanningEnvironmentType } from 'modules/workloads/utils/RegistryOptions/RegistryOptions';
import { Control } from 'react-hook-form';
export interface AuthMethodObject {
    key: RegistryAuthMethod;
    translation: string;
    registryTypes: Array<RegistryType>;
}

export interface AuthMethodSelectorProps {
    registryType: RegistryType;
    scanningEnvironmentType: ScanningEnvironmentType;
    selectedAuthMethod: RegistryAuthMethod;
    control: Control<any>;
    errors: any;
}

export interface AuthInputFieldProps {
    fieldName: string;
    label: string;
    control: Control<any>;
    errors: any;
}

export enum ErrorMessages {
    required = 'Required',
    minLength = 'Must be at least 3 letters'
}