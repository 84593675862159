import React from 'react';
import { Icon, Stack, Tooltip } from 'common/design-system/components-v2';
import IsFixableCellRendererStyles from './IsFixableCellRenderer.styled';
import { ICellRendererParams } from 'ag-grid-community';

const IsFixableCellRenderer: React.FC<ICellRendererParams> = ({ value }) => {
    if(typeof value === 'string') {
        if(value === 'false' || !value) return;
    } else {
        if(!value) return;
    }

    return (
        <Stack direction='row' alignItems='center' fullHeight >
            <Tooltip content={'Fixable'}>
                <IsFixableCellRendererStyles.IconWrapper padding={1}>
                    <Icon name='remedy' />
                </IsFixableCellRendererStyles.IconWrapper>
            </Tooltip>
        </Stack>
    );
};

export default IsFixableCellRenderer;
