import { A } from 'common/components/Anchor/Anchor';
import { Vendors } from 'common/consts/vendors';
import { Button } from 'common/design-system/components-v2';
import { UserRolesTypes } from 'common/enum/UserRoles';
import { getUserService } from 'common/interface/services';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type AddEnvironmentRelevantTypes = Vendors.KUBERNETES | Vendors.CONTAINER_REGISTRY | Vendors.SHIFT_LEFT;

const getVendorHref = (vendor: AddEnvironmentRelevantTypes) => {
    switch (vendor) {
        case Vendors.KUBERNETES:
            return '/workload/cloud-add/kubernetes';
        case Vendors.CONTAINER_REGISTRY:
            return '/workload/registry-onboarding';
        case Vendors.SHIFT_LEFT:
            return '/workload/cloud-add/shiftleft';
    }
};

interface AddEnvironmentActionProps {
    type: AddEnvironmentRelevantTypes;
}

const AddEnvironmentAction = React.forwardRef<HTMLAnchorElement, AddEnvironmentActionProps>((params, ref) => {
    const { t } = useTranslation(getK8sNamespace('common'));

    const isAddNewDisabled = React.useMemo(() => {
        return !getUserService().hasAnyPermission([UserRolesTypes.SUPER_USER, UserRolesTypes.ONBOARDING_PERMITTED]);
    }, []);

    const buttonComponent = React.useMemo(() => {
        return (
            <Button
                label={t('ENVIRONMENTS_TABLE.TABLE_ACTIONS.ADD.TITLE')}
                iconProps={{ name: 'plus' }}
                disabled={isAddNewDisabled}
                variant='text'
            />
        );
    }, [t, isAddNewDisabled]);

    if (isAddNewDisabled) {
        return buttonComponent;
    }

    return (
        <A ref={ref} href={getVendorHref(params.type)}>
            {buttonComponent}
        </A>
    );
});
AddEnvironmentAction.displayName = 'AddEnvironmentAction';

export default AddEnvironmentAction;