import React, { useCallback } from 'react';
import vulnerabilityService from '../vulnerability.service';
import { GetVulnerabilityScan, UseVulnerabilityScanState } from '../vulnerability.interface';
import { setVulnerabilities, setLoading, setError, setReset } from '../../../reducers/vulnerabilities';
import { useDispatch, useSelector } from 'react-redux';
import {
    getVulnerabilitiesData,
    getVulnerabilitiesError,
    getVulnerabilitiesIsLoading
} from '../../../reducers/vulnerabilities/selectors';

export type UseVulnerabilityScan = (data: GetVulnerabilityScan.Request) => UseVulnerabilityScanState;
const useVulnerabilityScan: UseVulnerabilityScan = (props) => {
    const error = useSelector(getVulnerabilitiesError);
    const isLoading = useSelector(getVulnerabilitiesIsLoading);
    const data = useSelector(getVulnerabilitiesData);
    const dispatch = useDispatch();

    const getData = useCallback(async () => {
        dispatch(setLoading(true));
        try {
            const { data } = await vulnerabilityService.getVulnerabilityScan({
                entityId: props.entityId,
                entityType: props.entityType,
                environmentId: props.environmentId,
                remediationOnly: props.remediationOnly
            });
            dispatch(setVulnerabilities({ entityId: props.entityId, data: data }));
            dispatch(setLoading(false));
            dispatch(setError(false));
        } catch (error) {
            dispatch(setVulnerabilities({ entityId: null, data: null }));
            dispatch(setLoading(false));
            dispatch(setError(true));
        }
    }, [dispatch, props.entityId, props.entityType, props.environmentId, props.remediationOnly]);

    React.useEffect(() => {
        getData();
        return () => {
            dispatch(setReset());
        };
    }, [dispatch, getData]);

    return {
        data,
        error,
        isLoading
    };
};

export default useVulnerabilityScan;
