import { Vendors } from 'common/consts/vendors';
import { getCloudAccountsService } from 'common/interface/data_services';
import { NotificationType } from 'common/interface/notifications';
import { getNotificationsService } from 'common/interface/services';
import i18n from 'common/services/translations/translations';
import { changeUrl } from 'common/utils/http';
import { getIsCloudInfra } from 'common/utils/RuntimeEnvironment';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import { ScanMode } from 'modules/workloads/services/awp/awp.interface';

export const saasScanModeOptions = () => [
    {
        value: ScanMode.Saas,
        label: i18n.t(`${getK8sNamespace('awp')}:awpOnboarding.azure.instructions.scanModeStep.tooltip.bullet1`),
    },
];

export const inAccountScanModeOptions = () => [
    {
        value: ScanMode.InAccount,
        label: i18n.t(`${getK8sNamespace('awp')}:awpOnboarding.azure.instructions.scanModeStep.tooltip.bullet2`),
    }
];

export const scanModeOptionsHubs = () => [
    {
        value: ScanMode.InAccountHub,
        label: i18n.t(`${getK8sNamespace('awp')}:awpOnboarding.azure.instructions.scanModeStep.tooltip.bullet3`),
    },
    {
        value: ScanMode.InAccountSub,
        label: i18n.t(`${getK8sNamespace('awp')}:awpOnboarding.azure.instructions.scanModeStep.tooltip.bullet4`),
    }
];

export const saasSectionOptions = () => [
    {
        value: ScanMode.Saas,
        label: i18n.t(`${getK8sNamespace('awp')}:awpOnboarding.azure.scanModeOptions.saas.title`),
        description: i18n.t(`${getK8sNamespace('awp')}:awpOnboarding.azure.scanModeOptions.saas.description`),
    },
];

export const inAccountSectionOptions = () => [
    {
        value: ScanMode.InAccount,
        label: i18n.t(`${getK8sNamespace('awp')}:awpOnboarding.azure.scanModeOptions.inAccount.title`),
        description: i18n.t(`${getK8sNamespace('awp')}:awpOnboarding.azure.scanModeOptions.inAccount.description`),
    },
    {
        value: ScanMode.InAccountSub,
        label: i18n.t(`${getK8sNamespace('awp')}:awpOnboarding.azure.scanModeOptions.inAccountSub.title`),
        description: i18n.t(`${getK8sNamespace('awp')}:awpOnboarding.azure.scanModeOptions.inAccountSub.description`),
    }
];

export const centralizedSectionOptions = () => [
    {
        value: ScanMode.InAccountHub,
        label: i18n.t(`${getK8sNamespace('awp')}:awpOnboarding.azure.scanModeOptions.inAccountHub.title`),
        description: i18n.t(`${getK8sNamespace('awp')}:awpOnboarding.azure.scanModeOptions.inAccountHub.description`),
    },
];

export const sendNotificationAndRedirectToEnvironments = (type: NotificationType, title: string, text?: string) => {
    getNotificationsService().addNotification({
        type,
        title,
        text: text || '',
    });
    getCloudAccountsService().clearCache();
    changeUrl('/cloud-account/index');
};

export const getAwpVpcDocumentationUrl = (provider: Vendors.AWS | Vendors.AZURE) => {
    const urls = {
        [Vendors.AZURE]: {
            'CG': 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Workload-Protection/AWP/AWP-Azure-SaaS-and-In-Account.htm?tocpath=Workload%20Protection%20%7CAgentless%20Workload%20Posture%7C_____2#azure_custom_vnet',
            'CI': 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Documentation/Workload-Protection/AWP/AWP-Azure-SaaS-and-In-Account.htm?tocpath=Workload%20Protection%20%7CAgentless%20Workload%20Posture%7C_____2#azure_custom_vnet',
        },
        [Vendors.AWS]: {
            'CG': 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Workload-Protection/AWP/AWP-Amazon-SaaS-and-In-Account.htm?tocpath=Workload%20Protection%20%7CAgentless%20Workload%20Posture%7C_____1#aws_custom_vpc',
            'CI': 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Documentation/Workload-Protection/AWP/AWP-Amazon-SaaS-and-In-Account.htm?tocpath=Workload%20Protection%20%7CAgentless%20Workload%20Posture%7C_____1#aws_custom_vpc',
        },
    };
    return getIsCloudInfra() ? urls[provider]['CI'] : urls[provider]['CG'];
};