import { Vendors } from 'common/consts/vendors';
import { WorkloadAgentlessAccount } from 'modules/workloads/services/awp/awp.interface';

export enum OnboardingMethod {
    ORG = 'ORG',
    TERRAFORM = 'TERRAFORM',
    ORG_MANAGEMENT = 'ORG_MANAGEMENT',
    AZURE_CLI = 'AZURE_CLI',
    AWS_CLOUDFORMATION = 'AWS_CLOUDFORMATION',
    UNDETERMINED = 'UNDETERMINED',
}

export const getOnboardingMethod = (agentlessAccount: Pick<WorkloadAgentlessAccount, 'isOrgOnboarding' | 'isTerraform' | 'isOrgOnboardingManagement' | 'provider'>) => {
    switch (true) {
        case agentlessAccount.isOrgOnboarding:
            return OnboardingMethod.ORG;
        case agentlessAccount.isTerraform:
            return OnboardingMethod.TERRAFORM;
        case agentlessAccount.isOrgOnboardingManagement:
            return OnboardingMethod.ORG_MANAGEMENT;
        case agentlessAccount.provider === Vendors.AZURE:
            return OnboardingMethod.AZURE_CLI;
        case agentlessAccount.provider === Vendors.AWS:
            return OnboardingMethod.AWS_CLOUDFORMATION;
        default:
            return OnboardingMethod.UNDETERMINED;
    }
};

export const toDisplayOnboardingMethod = (onboardingMethod: OnboardingMethod) => {
    return [OnboardingMethod.TERRAFORM, OnboardingMethod.ORG, OnboardingMethod.ORG_MANAGEMENT].includes(onboardingMethod);
};