import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClusterState, ClusterInitialState, Cluster } from './cluster.interface';

const ClusterSlice = createSlice({
    name: 'k8s_cluster',
    initialState: ClusterInitialState,
    reducers: {
        setLoading: (state: ClusterState, action: PayloadAction<{ clusterId: string; loading: boolean; }>) => {
            const { clusterId, loading } = action.payload;
            if (!state.clusters[clusterId]) {
                state.clusters[clusterId] = {};
            }
            state.clusters[clusterId].loading = loading;
        },
        setError: (state: ClusterState, action: PayloadAction<{ clusterId: string; error: boolean; }>) => {
            const { clusterId, error } = action.payload;
            if (!state.clusters[clusterId]) {
                state.clusters[clusterId] = {};
            }
            state.clusters[clusterId].error = error;
        },
        setData: (state: ClusterState, action: PayloadAction<{ clusterId: string; data: Cluster; }>) => {
            const { clusterId, data } = action.payload;
            if (!state.clusters[clusterId]) {
                state.clusters[clusterId] = {};
            }
            state.clusters[clusterId].data = data;
        },
        setDeleted: (state: ClusterState, action: PayloadAction<{ clusterId: string; deleted: boolean }>) => {
            const { clusterId } = action.payload;
            if (!state.clusters[clusterId]) {
                state.clusters[clusterId] = { deleted: true };
            }
            state.clusters[clusterId].deleted = true;
        },
    }
});

export default ClusterSlice;