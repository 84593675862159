import { Button } from '@dome9/berries/react-components';
import { A } from 'common/components/Anchor/Anchor';
import { getCheckpointUrl } from 'common/components/Forms/urls';
import { IWelcomePageSection } from 'common/components/WelcomePage/WelcomePage.interface';
import { TFunction } from 'i18next';
import { sendLogger } from 'modules/workloads/utils';
import { isAfEnabled } from 'common/utils/debugUtils';
import { NEW_NOTIFICATIONS_PAGE_AF } from 'common/module_interface/settings/integrations/Integrations';

const logButtonClick = (description: string, reportType: string) => {
    sendLogger({
        description: `k8s-welcome-page_${description}`,
        reportType,
    });
};

export const whatsNewSection: (t: TFunction) => IWelcomePageSection = (t) => ({
    title: t('NEWS.TITLE'),
    carousel: true,
    items: [
        // IMPORTANT: Newest items should be added at the start of this array
        {
            title: t('NEWS.CARD_1.TITLE'),
            subtitle: t('NEWS.CARD_1.SUBTITLE'),
            icon: 'kubernetes',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <a
                        target='_blank'
                        href={getCheckpointUrl('SBOM_DOCS')}
                        rel='noreferrer'
                    >
                        <Button
                            onClick={() => logButtonClick('SBOM Read More', 'READ_MORE')}
                            className='btn--respond-to-media-query'
                            icon={'info'}
                            label={t('k8s_common:READ_MORE')}
                        />
                    </a>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_2.TITLE'),
            subtitle: t('NEWS.CARD_2.SUBTITLE'),
            icon: 'old-pack__kubernetes__icons__kubernetes',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href='/compliance-engine/policy/-254'>
                        <Button
                            onClick={() => logButtonClick('CIS Kubernetes Benchmark v1.9.0', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_3.TITLE'),
            subtitle: t('NEWS.CARD_3.SUBTITLE'),
            icon: 'old-pack__kubernetes__icons__kubernetes',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href='/compliance-engine/policy/-245'>
                        <Button
                            onClick={() => logButtonClick('CIS EKS Benchmark v1.4.0', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_4.TITLE'),
            subtitle: t('NEWS.CARD_4.SUBTITLE'),
            icon: 'old-pack__kubernetes__icons__openshift',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href='/compliance-engine/policy/-255'>
                        <Button
                            onClick={() => logButtonClick('CIS OpenShift Benchmark v1.4.0', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_5.TITLE'),
            subtitle: t('NEWS.CARD_5.SUBTITLE'),
            icon: 'gcp',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href='/compliance-engine/policy/-267'>
                        <Button
                            onClick={() => logButtonClick('CIS GKE Benchmark v1.5.0', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_6.TITLE'),
            subtitle: t('NEWS.CARD_6.SUBTITLE'),
            icon: 'containers-registry',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={'/workload/registry-onboarding'}>
                        <Button
                            onClick={() => logButtonClick('github registry', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                    <a
                        target='_blank'
                        href={getCheckpointUrl('GITHUB_REGISTRY_DOCUMENTATION')}
                        rel='noreferrer'
                    >
                        <Button
                            onClick={() => logButtonClick('github registry docs', 'READ_MORE')}
                            className='btn--respond-to-media-query'
                            icon={'info'}
                            label={t('k8s_common:READ_MORE')}
                        />
                    </a>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_7.TITLE'),
            subtitle: t('NEWS.CARD_7.SUBTITLE'),
            icon: 'kubernetes',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <a
                        target='_blank'
                        href={'https://nvd.nist.gov/vuln/detail/CVE-2024-21626'}
                        rel='noreferrer'
                    >
                        <Button
                            onClick={() => logButtonClick('CVE-2024-21626 - New high-severity vulnerability affecting container-based workloads', 'READ_MORE')}
                            className='btn--respond-to-media-query'
                            icon={'info'}
                            label={t('k8s_common:READ_MORE')}
                        />
                    </a>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_8.TITLE'),
            subtitle: t('NEWS.CARD_8.SUBTITLE'),
            icon: 'kubernetes',
            cardColor: 'warning',
        },
        {
            title: t('NEWS.CARD_9.TITLE'),
            subtitle: t('NEWS.CARD_9.SUBTITLE'),
            icon: 'containers-registry',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={'/workload/registry-onboarding'}>
                        <Button
                            onClick={() => logButtonClick('Role-based authentication ECS scanner', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                    {/* <a
                        target='_blank'
                        href={getCheckpointUrl('ONBOARDING_AWS_ELASTIC_CONTAINER_REGISTRY_DOCS')}
                        rel='noreferrer'
                    >
                        <Button
                            onClick={() => logButtonClick('Role-based authentication ECS scanner', 'READ_MORE')}
                            className='btn--respond-to-media-query'
                            icon={'info'}
                            label={t('k8s_common:READ_MORE')}
                        />
                    </a> */}
                </div>
            )
        },
        {
            title: t('NEWS.CARD_10.TITLE'),
            subtitle: t('NEWS.CARD_10.SUBTITLE'),
            icon: 'containers-registry',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={'/workload/registry-onboarding'}>
                        <Button
                            onClick={() => logButtonClick('Sonatype Nexus', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                    <a
                        target='_blank'
                        href={getCheckpointUrl('K8S_REGISTRY_ONBOARDING_NEXUS')}
                        rel='noreferrer'
                    >
                        <Button
                            onClick={() => logButtonClick('Sonatype Nexus', 'READ_MORE')}
                            className='btn--respond-to-media-query'
                            icon={'info'}
                            label={t('k8s_common:READ_MORE')}
                        />
                    </a>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_11.TITLE'),
            subtitle: t('NEWS.CARD_11.SUBTITLE'),
            icon: 'old-pack__kubernetes__icons__kubernetes',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={'/compliance-engine/policy/-190'}>
                        <Button
                            onClick={() => logButtonClick('CIS Benchmark v1.8.0', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_12.TITLE'),
            subtitle: t('NEWS.CARD_12.SUBTITLE'),
            icon: 'containers-registry',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={'/workload/registry-onboarding'}>
                        <Button
                            onClick={() => logButtonClick('ECR scanning using custom IAM Role', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                    <a
                        target='_blank'
                        href={getCheckpointUrl('AWS_CUSTOM_ROLE')}
                        rel='noreferrer'
                    >
                        <Button
                            onClick={() => logButtonClick('ECR scanning using custom IAM Role', 'READ_MORE')}
                            className='btn--respond-to-media-query'
                            icon={'info'}
                            label={t('k8s_common:READ_MORE')}
                        />
                    </a>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_13.TITLE'),
            subtitle: t('NEWS.CARD_13.SUBTITLE'),
            icon: 'imageScan',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <a
                        target='_blank'
                        href={getCheckpointUrl('IMAGE_SCAN_ON_DEMAND')}
                        rel='noreferrer'
                    >
                        <Button
                            onClick={() => logButtonClick('Image on-demand scan', 'READ_MORE')}
                            className='btn--respond-to-media-query'
                            icon={'info'}
                            label={t('k8s_common:READ_MORE')}
                        />
                    </a>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_14.TITLE'),
            subtitle: t('NEWS.CARD_14.SUBTITLE'),
            icon: 'old-pack__kubernetes__icons__openshift',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={'/compliance-engine/policy/-174'}>
                        <Button
                            onClick={() => logButtonClick('CIS OpenShift Benchmark v1.4.0', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_15.TITLE'),
            subtitle: t('NEWS.CARD_15.SUBTITLE'),
            icon: 'containers-registry',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A
                        target='_blank'
                        href={'/workload/environments?query=%7B"filter":%7B"freeTextPhrase":"","filterFields":%5B%7B"name":"organizationalUnitId"%7D,%7B"name":"sub-platform","value":"ECR"%7D,%7B"name":"sub-platform","value":"ACR"%7D%5D%7D%7D'}
                        rel='noreferrer'
                    >
                        <Button
                            onClick={() => logButtonClick('Unsecured ACR and ECR registries', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_16.TITLE'),
            subtitle: t('NEWS.CARD_16.SUBTITLE'),
            icon: 'cloudGuard',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <a
                        target='_blank'
                        href={getCheckpointUrl('AWP_DOCS')}
                        rel='noreferrer'
                    >
                        <Button
                            onClick={() => logButtonClick('Agentless Workload Posture (AWP)', 'READ_MORE')}
                            className='btn--respond-to-media-query'
                            icon={'info'}
                            label={t('k8s_common:READ_MORE')}
                        />
                    </a>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_17.TITLE'),
            subtitle: t('NEWS.CARD_17.SUBTITLE'),
            icon: 'gcp',
            cardColor: 'success',
            // badges: <Chip color="success" label={t('k8s_common:NEW')} size="xs" />,
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={'/compliance-engine/policy/-164'}>
                        <Button
                            onClick={() => logButtonClick('CIS Google Kubernetes Engine (GKE) Benchmark v1.4.0', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_18.TITLE'),
            subtitle: t('NEWS.CARD_18.SUBTITLE'),
            icon: 'aws',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={'/compliance-engine/policy/-166'}>
                        <Button
                            onClick={() => logButtonClick('CIS AWS Kubernetes Engine (EKS) Benchmark v1.3.0', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_19.TITLE'),
            subtitle: t('NEWS.CARD_19.SUBTITLE'),
            icon: 'imageScan',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={isAfEnabled(NEW_NOTIFICATIONS_PAGE_AF) ? '/notifications-list' : '/notifications'}>
                        <Button
                            onClick={() => logButtonClick('ImageScan findings now contain a detailed summary of vulnerabilities and remediation', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                </div>
            )
        },
        //
        {
            title: t('NEWS.CARD_20.TITLE'),
            subtitle: t('NEWS.CARD_20.SUBTITLE'),
            icon: 'aws',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={'/workload/registry-onboarding'}>
                        <Button
                            onClick={() => logButtonClick('ECS AWS scanning environment', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_21.TITLE'),
            subtitle: t('NEWS.CARD_21.SUBTITLE'),
            icon: 'azure',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={'/compliance-engine/policy/-160'}>
                        <Button
                            onClick={() => logButtonClick('Unsecured K8s Clusters', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_22.TITLE'),
            subtitle: t('NEWS.CARD_22.SUBTITLE'),
            icon: 'risk-management_4',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={'/risk-management/protected-assets?query={"filter":{"fields":[{"name":"platform","value":"kubernetes"}]}}'}>
                        <Button
                            onClick={() => logButtonClick('New-Dashboard', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_23.TITLE'),
            subtitle: t('NEWS.CARD_23.SUBTITLE'),
            icon: 'cluster',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={'/workload/environments?query={"filter":{"filterFields":[{"name":"status","value":"Unsecured"}]}}'}>
                        <Button
                            onClick={() => logButtonClick('Unsecured K8s Clusters', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_24.TITLE'),
            subtitle: t('NEWS.CARD_24.SUBTITLE'),
            icon: 'containers-registry',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={'/workload/registry-onboarding'}>
                        <Button
                            onClick={() => logButtonClick('JFrog, Harbor', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                    <a
                        target='_blank'
                        href={getCheckpointUrl('K8S_REGISTRY_ONBOARDING')}
                        rel='noreferrer'
                    >
                        <Button
                            onClick={() => logButtonClick('JFrog, Harbor', 'READ_MORE')}
                            className='btn--respond-to-media-query'
                            icon={'info'}
                            label={t('k8s_common:READ_MORE')}
                        />
                    </a>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_25.TITLE'),
            subtitle: t('NEWS.CARD_25.SUBTITLE'),
            icon: 'gcp',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={'/workload/registry-onboarding'}>
                        <Button
                            onClick={() => logButtonClick('GCR, GAR', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                    <a
                        target='_blank'
                        href={getCheckpointUrl('K8S_REGISTRY_ONBOARDING')}
                        rel='noreferrer'
                    >
                        <Button
                            onClick={() => logButtonClick('GCR, GAR', 'READ_MORE')}
                            className='btn--respond-to-media-query'
                            icon={'info'}
                            label={t('k8s_common:READ_MORE')}
                        />
                    </a>
                </div>
            )
        },
        {
            title: t('NEWS.CARD_26.TITLE'),
            subtitle: t('NEWS.CARD_26.SUBTITLE'),
            icon: 'cloudGuard',
            cardColor: 'success',
            cardBody: (
                <div className='flex flex-row gap-5 justify-end h-full items-end'>
                    <A href={'/workload/dashboard'}>
                        <Button
                            onClick={() => logButtonClick('New-Dashboard', 'TAKE_ME_THERE')}
                            className='btn--respond-to-media-query'
                            icon={'external-link'}
                            label={t('k8s_common:TAKE_ME_THERE')}
                        />
                    </A>
                </div>
            )
        }

    ],
});