import React from 'react';
import { ScanningEnvironmentType } from 'modules/workloads/utils/RegistryOptions/RegistryOptions';
import { ScanningEnvironmentTypeSelectorProps } from './ScanningEnvironmentTypeSelector.interface';
import ButtonGroup from 'common/design-system/components-v2/ButtonGroup/ButtonGroup';

const ScanningEnvironmentTypeSelector: React.FC<ScanningEnvironmentTypeSelectorProps> = (props) => {
    const { registryScanner, onChange, disabled = false, awsDisabled = false, label, labelProps } = props;
    
    const handleOnClick = (scanningEnvironmentType: ScanningEnvironmentType) => {
        if (disabled || (scanningEnvironmentType === ScanningEnvironmentType.AWS && awsDisabled)) return;
        onChange(scanningEnvironmentType);
    };

    const awsDisabledTooltip = (
        <span>There are no <b>AWS ECS</b> connected to this account</span>
    );
    
    return (
        <ButtonGroup
            options={[
                { label: ScanningEnvironmentType.Kubernetes, value: ScanningEnvironmentType.Kubernetes, iconProps: { name: 'kubernetes' } },
                { label: 'ECS Scanner', value: ScanningEnvironmentType.AWS, iconProps: { name: 'aws' }, disabled: awsDisabled, tooltip: awsDisabled ? awsDisabledTooltip : undefined },
            ]}
            value={registryScanner}
            onChange={(value) => handleOnClick(value as ScanningEnvironmentType)}
            label={label}
            {...labelProps}
        />
    );
};

export default ScanningEnvironmentTypeSelector;