import React from 'react';
import { useTranslation } from 'react-i18next';
import BladeStyles from './Blades.styled';
import { BladeItemWithData, BladesProps, BladeStatus } from './Blades.types';
import { blades } from './Blades.consts';
import { Stack, Typography, Command } from 'common/design-system/components-v2';
import { useGetCluster } from 'modules/workloads/reducers/cluster-new/hooks/useGetCluster';
import { KubernetesAccount } from 'modules/workloads/reducers/cluster/cluster.interface';
import { useUpdateCluster } from 'modules/workloads/reducers/cluster-new/hooks/useUpdateCluster';
import { KubernetesAgentSummaryDetailsStatus, KubernetesAgentSummaryType, KubernetesFeatureStatus } from 'modules/workloads/services/kubernetesAccount/kubernetesAccount.interface';
import ConfirmationModal from 'common/components/ConfirmationModal';
import AgentsTable from './components/AgentsTable';
import { getHelmCommand } from 'modules/workloads/utils/KubernetesOnBoarding.helm.service';
import BladeHeader from './components/BladeHeader';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { Alert, AlertType } from '@dome9/berries/react-components';
import { ALink } from 'common/components/ALink';
import styled from 'styled-components';
import { getCheckpointUrl } from 'common/components/Forms/urls';
import HelmNotes from '../../components/HelmNotes/HelmNotes';

export const ALinkWithUnderline = styled.a`
  ${ALink}
`;

type AlertConfig = {
  type: AlertType,
  text: string,
  linkUrl: string,
  linkText: string
}

const Blades: React.FunctionComponent<BladesProps> = ({ id }) => {
    const { t } = useTranslation(['k8s_cluster', 'k8s_common']);
    const [bladesStates, setBladesStates] = React.useState<{ [name: string]: boolean }>({});
    const [confirmationModalInfo, setConfirmationModalInfo] = React.useState<{ blade: BladeItemWithData, newValue: boolean }>();
    const { data: clusterData } = useGetCluster(id);

    const handleOnUpdateError = ({ message }: { message: string }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            text: message,
            title: t('k8s_cluster:bladeUpdateError')
        });
    };

    const { updateCluster, isLoading: isUpdateLoading } = useUpdateCluster({ onError: handleOnUpdateError });

    const clusterVersionAlert: AlertConfig | null = React.useMemo(() => {
        if (['Error', 'Warning'].includes(clusterData?.account.clusterVersionStatus || '')) {
            return {
                type: AlertType.WARNING,
                text: clusterData?.account?.clusterVersionStatusDescription?.split('Learn more')[0].trim() || '',
                linkUrl: getCheckpointUrl('CLUSTER_VERSION_STATUS_DOCS'),
                linkText: t('k8s_cluster:clusterVersionAlert.learnMore'),
            };
        }
        return null;
    }, [clusterData?.account.clusterVersionStatus, clusterData?.account.clusterVersionStatusDescription, t]);

    const bladesWithData = React.useMemo(() => {
        if (!clusterData?.account || !clusterData.agentSummary) return [];
        return blades().map<BladeItemWithData>((blade) => {
            const relevantAgents = clusterData.agentSummary.filter(agent => blade.agentTypes?.some(type => type === agent.agentType));
            let isErrorExist = false;
            let isPendingExist = false;
            let isPendingCleanupExist = false;
            let isInfoExist = false;
            relevantAgents.forEach(agent => {
                agent.agentSummary.forEach(agentSummary => {
                    switch (agentSummary.status) {
                        case KubernetesAgentSummaryDetailsStatus.ERROR:
                            if (agent.agentType === KubernetesAgentSummaryType.ImageAccessRuntimeMonitor) {
                                isPendingExist = true;
                                break;
                            }
                            isErrorExist = true;
                            break;
                        case KubernetesAgentSummaryDetailsStatus.PENDING:
                            isPendingExist = true;
                            break;
                        case KubernetesAgentSummaryDetailsStatus.PENDING_CLEANUP:
                            isPendingCleanupExist = true;
                            break;
                        default:
                            break;
                    }
                    if (!agentSummary.isAgentUpToDate) isInfoExist = true;
                });
            });
            const status = (isErrorExist ? BladeStatus.ERROR : ((isPendingExist || isPendingCleanupExist || isInfoExist) ? BladeStatus.WARNING : BladeStatus.GOOD));
            return ({
                ...blade,
                active: blade.isActiveName ? clusterData.account[blade.isActiveName] : blade.name === 'pm',
                status: status,
                agents: relevantAgents,
                nestedBlades: blade.nestedBlades?.map<Omit<BladeItemWithData, 'nestedBlades'>>(nestedBlade => ({
                    ...nestedBlade,
                    active: clusterData.account[nestedBlade.isActiveName as keyof KubernetesAccount] || false,
                    agents: [],
                }))
            });
        });
    }, [clusterData]);

    const bladesWithDataAsObj = React.useMemo(() => bladesWithData.reduce<{ [name: string]: BladeItemWithData }>((acc, blade) => {
        if (blade.nestedBlades) {
            blade.nestedBlades.forEach(nBlade => {
                acc[nBlade.name] = nBlade;
            });
        }
        acc[blade.name] = blade;
        return acc;
    }, {}), [bladesWithData]);

    const helmCommand = React.useMemo<string>(() => {
        if (!confirmationModalInfo) return '';
        return getHelmCommand({
            apiKey: '<API-KEY>',
            apiSecret: '<API-SECRET>',
            namespace: '<NAMESPACE>',
            cluster: {
                id,
                admissionControlEnabled: !!clusterData?.account?.admissionControlEnabled,
                imageAssuranceEnabled: !!clusterData?.account?.imageAssuranceEnabled,
                runtimeProtectionEnabled: !!clusterData?.account?.runtimeProtectionEnabled,
                threatIntelligenceEnabled: !!clusterData?.account?.threatIntelligenceEnabled,
                imageAccessRuntimeMonitorEnabled: !! clusterData?.account.imageAccessRuntimeMonitorEnabled,
                [confirmationModalInfo.blade.isActiveName as string]: confirmationModalInfo.newValue
            }
        });
    }, [clusterData, confirmationModalInfo, id]);

    const handleOnBladeChange = async (name: string, newValue: boolean) => {
        setConfirmationModalInfo({ blade: bladesWithDataAsObj[name], newValue });
    };

    const handleOnBladeConfirmChange = async () => {
        if (!confirmationModalInfo) return;
        const { blade, newValue } = confirmationModalInfo;
        await updateCluster(id, { features: [
            { 
                id,
                featureType: blade.updateName,
                status: newValue ? KubernetesFeatureStatus.ENABLE : KubernetesFeatureStatus.DISABLE
            }
        ] });
        if (!newValue) {
            setBladesStates(current => ({ ...current, [blade.name]: false }));
        }
        setConfirmationModalInfo(undefined);
    };

    const handleOnBladeStateChange = (name: string) => {
        setBladesStates({ ...bladesStates, [name]: !(bladesStates[name] || false) });
    };

    const handleOnExpandAllClick = () => {
        const allBladesOn = bladesWithData.reduce<{ [name:string]: boolean }>((acc, blade) => ({ ...acc, [blade.name]: blade.active }) , {});
        setBladesStates(allBladesOn);
    };

    return (
        <BladeStyles.Wrapper>
            {clusterVersionAlert !== null && (
                <Alert type={clusterVersionAlert.type}>
                    <p>{clusterVersionAlert.text} <ALinkWithUnderline href={clusterVersionAlert.linkUrl}>{clusterVersionAlert.linkText}</ALinkWithUnderline></p>
                </Alert>
            )}
            <Stack direction='row' justifyContent='flex-end'>
                <BladeStyles.TopRow_button variant='text' onClick={handleOnExpandAllClick}>{t('k8s_cluster:common.expandAll')}</BladeStyles.TopRow_button>
                <BladeStyles.TopRow_button variant='text' onClick={() => setBladesStates({})}>{t('k8s_cluster:common.collapseAll')}</BladeStyles.TopRow_button>
            </Stack>
            <BladeStyles.Rows>
                {bladesWithData.filter(bladeData => !bladeData.isHidden).map(blade => (
                    <BladeStyles.Row key={`blade-row-${blade.name}`} spacing={2}>
                        <BladeHeader
                            blade={blade}
                            isOpen={bladesStates[blade.name]}
                            handleOnBladeChange={handleOnBladeChange}
                            handleOnBladeStateChange={handleOnBladeStateChange}
                        />
                        {bladesStates[blade.name] && (
                            <div className='blade-content'>
                                {(blade.nestedBlades && blade.nestedBlades.filter(nestedBlade => !nestedBlade.isHidden).length > 0) && (
                                    <BladeStyles.Rows>
                                        {blade.nestedBlades.filter(nestedBlade => !nestedBlade.isHidden).map(nestedBlade => (
                                            <BladeStyles.Row key={`blade-row-${blade.name}-${nestedBlade.name}`} spacing={2}>
                                                <BladeHeader
                                                    blade={nestedBlade}
                                                    isOpen={bladesStates[nestedBlade.name]}
                                                    handleOnBladeChange={handleOnBladeChange}
                                                    handleOnBladeStateChange={handleOnBladeStateChange}
                                                />
                                            </BladeStyles.Row>
                                        ))}
                                    </BladeStyles.Rows>
                                )}
                                {blade.content && (
                                    <AgentsTable key={`agents-table_${blade.name}`} agents={blade.agents} clusterId={id} />
                                ) }
                            </div>
                        )}
                    </BladeStyles.Row>
                ))}
            </BladeStyles.Rows>
            {(confirmationModalInfo && clusterData) && (
                <ConfirmationModal
                    isLoading={isUpdateLoading}
                    onCancel={() => setConfirmationModalInfo(undefined)}
                    onClose={() => setConfirmationModalInfo(undefined)}
                    onConfirm={handleOnBladeConfirmChange}
                    title={t(`k8s_cluster:${confirmationModalInfo.blade.labelT}`)}
                    text={t('k8s_cluster:bladeChangeConfirmation.areYouSure', { newState: t(`k8s_common:${confirmationModalInfo.newValue ? 'on' : 'off'}`) })}
                >
                    {confirmationModalInfo.blade.updateHelmOnUpdate && <>
                        <Typography variant='bodyLg'>
                            {t(`k8s_cluster:bladeChangeConfirmation.${confirmationModalInfo.newValue ? 'activeFeature' : 'removeFeature'}`)}
                        </Typography>
                        <Command text={helmCommand} />
                        <HelmNotes />
                    </>}
                </ConfirmationModal>
            )}
        </BladeStyles.Wrapper>
    );
};

export default Blades;
