import styled from 'styled-components';
import { Button } from '@dome9/berries/react-components';

export const Title = styled.div`
  color: #333;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 10px;
`;

export const ButtonWithLoader = styled(Button)`
    gap: 5px;
    flex-direction: row-reverse;
`;

export const AlertSecondary = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  font-size: 13px;
  gap: 10px;
  border: 1px solid #3B8FD6;
  background: #3B8FD61A;
  width: fit-content;
  height: 30px;
`;