import { getVendorDisplayName } from 'common/consts/vendors';
import { IEnvironment } from 'common/interface/environmentsTable';
import { formatDate } from 'common/utils/helpFunctions';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import { KubernetesAccountFeatureSummary } from 'modules/workloads/services/workload/workload.interface';

export const exportWorkloadTable = (cloudAccounts: IEnvironment[]) => {
    const mappedData: { [key: string]: string }[] = cloudAccounts.map((cloudAccount) => ({
        'Cloud Account ID': cloudAccount.externalId || 'N/A',
        'Cloud Account Name': cloudAccount.name,
        'Status': cloudAccount['customData|status'],
        'Platform': getVendorDisplayName(cloudAccount.platform),
        'Type': cloudAccount['customData|workloadPlatformType'] === 'NA' ? 'N/A' : cloudAccount['customData|workloadPlatformType'],
        'Onboarding Time': formatDate(cloudAccount.creationDate),
        'Organizational Unit Path': cloudAccount['customData|organizationalUnitPath'] || cloudAccount.organizationalUnitId,
        'Is Intelligence Enabled': cloudAccount['customData|intelligenceEnabled'] ? 'Yes' : 'No',
        'Is Iam Safety Enabled': cloudAccount['customData|iamSafety'] ? 'Yes' : 'No',
        'Is Serverless Protection Enabled': cloudAccount['customData|serverlessEnabled'] ? 'Yes' : 'No',
        'Is AWP Enabled': cloudAccount['customData|agentlessEnabled'] ? 'Yes' : 'No',
        ...(((cloudAccount['customData|kubernetesFeatures'] || []) as KubernetesAccountFeatureSummary[]).reduce<{ [key: string]: string; }>((acc, feature) => {
            acc[`${feature.name} Status`] = feature.status;
            acc[`${feature.name} Description`] = feature.statusDescription;
            return acc;
        }, {}))
    }));

    const allRows: string[] = [Object.keys(mappedData[0]).join(',')];
    mappedData.forEach((row) => {
        allRows.push(Object.values(row).join(','));
    });
    const fileName = `Dome9_CloudAccounts_${dayjs(new Date()).format('YYYY MM DD hh:mm A').replace(/\s+/g, '_').replace(/,/g, '')}.csv`;
    const csvStr = allRows.join('\n');
    fileDownload(csvStr, fileName);
};

export const exportKubernetesAgentsTable = (agentsStr: string) => {
    const fileName = `Dome9_All_Agents_Statuses_${dayjs(new Date()).format('YYYY MM DD hh:mm A').replace(/\s+/g, '_').replace(/,/g, '')}.csv`;
    fileDownload(agentsStr, fileName);
};
