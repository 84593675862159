import { getStoreService } from 'common/interface/services';
import { changeUrl } from 'common/utils/http';
import { getIsCloudInfra, getIsCloudInfraAvailable } from 'common/utils/RuntimeEnvironment';
import { UrlData, FilterField, BaseUrl, Time, TimeData } from './events.interface';

enum PathPrefix {
    CLOUD_INFRA_PATH_PREFIX = 'dashboard/cloudguard#',
    V2_PATH_PREFIX = 'v2'
}

const urlToLink = (url: string) => {
    const isInCloudInfra = getIsCloudInfra();
    const baseUrl = (isInCloudInfra && getIsCloudInfraAvailable())
        ? getStoreService().state.app.cloudInfraUrl
        : window.location.origin;
    return isInCloudInfra
        ? `${baseUrl}/${url}`
        : `${baseUrl}/${PathPrefix.V2_PATH_PREFIX}/${url}`;
};

export const openWidgetLink = async (url: string, openInNewTab = false) => {
    if (openInNewTab) {
        window.open(`${urlToLink(url)}`, '_blank');
    } else {
        changeUrl(url);
    }
};

export const generateQuery = (data: any, key = 'query'): string => {
    return new URLSearchParams(`${key}=${JSON.stringify(data)}`).toString();
};

export const generateUrl: (urlData: UrlData) => string = ({ baseUrl, queryData, timeData, additionalQueryData }): string => {
    const url = Object.values({
        slash: '/',
        baseUrl,
        questionMark: queryData || timeData || additionalQueryData ? '?' : false,
        query: queryData ? generateQuery(queryData) : false,
        andSign: (queryData && timeData) ? '&' : false,
        time: timeData ? (timeData !== 'All' ? generateQuery(timeData, 'time') : 'time=All') : false,
        andSign2: (additionalQueryData && (queryData || timeData)) ? '&' : false,
        additionalQueryData
    }).filter(Boolean).join('');
    
    return url;
};

export const getUrlOf = (baseUrl: BaseUrl, filterFields: FilterField[], timeData: Time = 'All', additionalQueryData: Array<string> | undefined = undefined): string => {
    const queryData = {
        sorting: {
            fieldName: 'createdTime',
            direction: -1
        },
        filter: { fields: filterFields }
    };

    const timeQuery = getTimeQueryData(timeData);
    const url = generateUrl({ baseUrl, queryData, timeData: timeQuery, additionalQueryData });
    return url; 
};

const getUnixTimestamp = (date: string): number => {
    return (new Date(date)).getTime();
};

export const getTimeQueryData = (time: Time) => {
    let timeQueryData;

    if (typeof time !== 'string') {
        const timeData = time as TimeData;

        timeQueryData = {
            time: {
                Custom: {
                    from: getUnixTimestamp(timeData.from),
                    to: getUnixTimestamp(timeData.to)
                }
            }
        };
    } else {
        timeQueryData = time;
    }

    return timeQueryData;
};

const parseValue = (value: string): number | string => {
    return (+value).toString() === value ? +value : value;
};

export const relevantFilterFields = (validFilterKeys: { [key: string]: string }, filters: Array<FilterField>): Array<FilterField> => {
    if (!validFilterKeys) {
        return [];
    }
    
    let relevantFilters: Array<FilterField> = [];

    filters.forEach(({ name, value }) => {
        if (validFilterKeys[name]) {
            relevantFilters = [...relevantFilters, { name: validFilterKeys[name], value: parseValue(value) }];
        }
    });

    return relevantFilters;
};

export const severityNumberToString = (severityNumber: number) => {
    const numericalSeverities: { [num: number]: string } = {
        1: 'low',
        2: 'medium',
        3: 'high',
        4: 'critical',
    };

    return numericalSeverities[severityNumber];
};

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const parseIdString = (idString: string) => {
    const [vendor, cloudAccountId, type, assetId] = idString.split('|');
    return { vendor, cloudAccountId, type, assetId };
};