import React from 'react';
import { singletonHook } from 'react-singleton-hook';
import CveService from 'modules/workloads/services/cve/cve.service';
import { GslQueryFilters, Vulnerability } from 'modules/workloads/services/cve/cve.interface';
import { Filters } from '../CveSearchFilterPanel/CveSearchFilterPanel';
import { getNotificationsService } from 'common/interface/services';
import fileDownload from 'js-file-download';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

interface IUseSearchCveId {
    rowData: Array<Vulnerability> | undefined;
    isLoading: boolean;
    error: boolean;
    setFilters: (filters: Filters) => void;
    setUrlCve: (urlCve: string) => void;
    onExportCsv: (activeColumns?: Array<string>, filenameSufix?: string) => void;
    refetch: () => void;
}

type UseSearchCveId = () => IUseSearchCveId;
const useSearchCveIdImple: UseSearchCveId = () => {
    const { t } = useTranslation(getK8sNamespace('cve-explorer'));
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState<boolean>(false);
    const [rowData, setRowData] = React.useState<Array<Vulnerability>>([]);
    const [filters, setFilters] = React.useState<Filters>({ fields: [], freeTextPhrase: '' });
    const [filtersInit, setFiltersInit] = React.useState<boolean>(false);
    const [urlCve, setUrlCve] = React.useState<string>('');

    const onFiltersChange = React.useCallback((filters: Filters) => {
        setFiltersInit(true);
        setFilters(filters);
    }, []);

    const onUrlCveChange = React.useCallback((urlCve: string) => {
        setUrlCve(urlCve);
    }, []);

    const onSearch = React.useCallback(async (searchText: string, filters?: GslQueryFilters, useCache = true) => {
        setIsLoading(true);
        setError(false);
        const trimmedSearchText = searchText.trim();
        try {
            const response = await CveService.searchVulnerabilitiesByCveId({ cveId: trimmedSearchText, filters }, useCache);
            setRowData(response.data.data || []);
        } catch (error: any) {
            setRowData([]);
            console.error(error);
            setError(true);
        }
        setIsLoading(false);
    }, []);

    const refetch = React.useCallback(() => {
        if (urlCve && filtersInit) {
            onSearch(urlCve, filters.fields, false);
        }
    }, [filters.fields, filtersInit, onSearch, urlCve]);

    React.useEffect(() => {// deep linking
        if (urlCve && filtersInit) {
            onSearch(urlCve, filters.fields);
        }

        return () => {
            setRowData([]);
        };
    }, [urlCve, filters, filtersInit, onSearch]);

    const onExportCsv = async (activeColumns?: Array<string>, filenameSufix?: string) => {
        getNotificationsService().info(t('export.exporting'), t('export.pleaseWait'));
        const sufix = filenameSufix ? `_${filenameSufix}` : '';

        try {
            const response = await CveService.getExportCsv({ cveId: urlCve, filters: filters.fields, activeColumns });
            const fileName = `CloudGuard_CVE_Search_${dayjs(new Date()).format('YYYY MM DD hh:mm A').replace(/\s+/g, '_').replace(/,/g, '')}${sufix}.csv`;
            fileDownload(response.data, fileName);
        } catch (err) {
            console.error(err);
            getNotificationsService().error(t('export.export'), t('export.exportFailed'));
        }
    };

    return {
        urlCve,
        rowData: isLoading ? undefined : rowData,
        isLoading,
        error,
        setFilters: onFiltersChange,
        setUrlCve: onUrlCveChange,
        onExportCsv,
        refetch,
    };
};

const useSearchCveId: UseSearchCveId = singletonHook({
    rowData: [],
    isLoading: true,
    error: false,
    setFilters: () => null,
    onExportCsv: () => null,
    setUrlCve: () => null,
    refetch: () => null,
}, useSearchCveIdImple);
export default useSearchCveId;