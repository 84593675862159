import React from 'react';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { useGetCluster } from 'modules/workloads/reducers/cluster-new/hooks/useGetCluster';
import { useDeleteCluster } from 'modules/workloads/reducers/cluster-new/hooks/useDeleteCluster';
import { changeUrl } from 'common/utils/http';
import { ModalsProps } from '..';
import { getCloudAccountsService } from 'common/interface/data_services';
import { useLocation } from 'react-router-dom';
import { Message, Stack, Typography, Command } from 'common/design-system/components-v2';

const uninstallCommand = 'helm uninstall asset-mgmt --namespace <NAMESPACE>';

const RemoveClusterModal: React.FC<ModalsProps> = ({ closeModal, clusterId }) => {
    const { data: clusterData } = useGetCluster(clusterId);
    const location = useLocation();

    const handleOnDeleteSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: 'Deleted successfully',
            text: '',
        });
        getCloudAccountsService().clearCache();
        onClose();
        const isWorkloadPage = location.pathname.includes('/workload/');
        changeUrl(isWorkloadPage ? '/workload/environments' : '/cloud-account/index');
    };

    const handleOnDeleteError = (error: { message: string; }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: 'Failed to delete',
            text: error.message,
        });
        onClose();
    };

    const { deleteCluster, isLoading: isDeleteLoading } = useDeleteCluster({ onSuccess: handleOnDeleteSuccess, onError: handleOnDeleteError });

    const onClickRemoveCluster = async () => {
        if (!clusterData) return;
        deleteCluster(clusterData.account.id);
    };

    const onClose = React.useCallback(() => {
        closeModal();
    }, [closeModal]);

    return (
        <Message
            width='lg'
            variant='danger'
            onClose={onClose}
            isOpen={true}
            title='Remove Cluster'
            text={[`Are you sure you want to remove ${clusterData?.account.name} Cluster?`, 'Note that any attached policies will be unassociated']}
            isLoading={isDeleteLoading}
            onConfirm={onClickRemoveCluster}
            onCancel={onClose}
            submitBtnText='Delete'
            cancelBtnText='Cancel'
            cancelBtnDisabled={isDeleteLoading}
        >
            <Stack spacing={1}>
                <Typography>To complete the process, run the following command</Typography>
                <Command text={uninstallCommand} />
            </Stack>
        </Message>
    );
};

export default RemoveClusterModal;
