import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    margin-top: 200px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
  
    p {
        font-size: 25px;
        margin-top: 15px;
        text-align: center;
        font-weight: 500;
    }

    .sub-title {
      font-size: 18px;
      font-weight: normal;
    }
  
    button {
        margin-top: 30px;
        align-self: center;
    }
`;

export const InfoTextHeader = styled.p`
    font-size: 18px;
    margin-top: 28px;
    text-align: center;
`;

export const InfoText = styled.p`
    font-size: 15px;
    margin-top: 28px;
    text-align: center;
    color: black;
`;

export const AdditionalUserInfoWrapper = styled.div`
    text-align: left;
    width: 600px;
    margin-top: 25px;

    label {
        color: #7C7C7C;
        font-size: 12px;
    }

    textarea {
        margin: 5px 0;
        display: block;
        width: 100%;
        border: 1px solid #D2D5D9;
        padding: 5px;
    }
    
    .helper-text {
        font-size: 13px;
    }
`;

export const SendButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;