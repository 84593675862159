import React, { useMemo, useCallback } from 'react';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { SbomPackagesFlat } from 'modules/workloads/services/sbom/sbom.interface';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { FILTERS_API_OBJECT_KEYS, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { FilterPanelSBOMProps } from './FilterPanelSBOM.types';
import {
    renderAddFilter,
    renderClearAll, renderDefaultFreeTextFilter,
    renderMultiSelectFilter
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';

const FilterPanelSBOM: React.FC<FilterPanelSBOMProps> = (props) => {
    const { data, onFilterChange } = props;
    const { t } = useTranslation(getK8sNamespace('sbom'));

    const getAggregations = useCallback(async (filtersValues?: IFiltersValues): Promise<Aggregations> => {
        if(filtersValues) {
            onFilterChange(filtersValues);
        }

        return data.reduce<{ [key: string]: Array<{ value: string, count: number }> }>((acc, sbomItem) => {
            Object.keys(sbomItem).forEach((key) => {
                let value = sbomItem[key as keyof SbomPackagesFlat ];
                if(typeof value === 'object'){
                    return;
                }
                if(typeof value === 'boolean') {
                    value = value.toString();
                }
                if(!acc[key]) {
                    acc[key] = [{
                        value: value,
                        count: 1
                    }];
                } else {
                    const findItem = acc[key].find(item => item.value === value);
                    if(findItem) {
                        findItem.count++;
                    } else {
                        acc[key].push({
                            value: value,
                            count: 1
                        });
                    }
                }
            });
            return acc;
        }, {});
    }, [data, onFilterChange]);

    const buildFilterPropsByProperty = useCallback((property: keyof SbomPackagesFlat) => {
        const groupBy: Record<string, { displayText: string }> = {};
        data.forEach((item) => {
            let key = item[property];
            if(typeof key === 'boolean') {
                key = key.toString();
            }
            if(typeof key === 'object') {
                return;
            }
            if (!groupBy[key]) {
                groupBy[key] = { displayText: key };
            }
        });
        return groupBy;
    }, [data]);

    const buildValueCountArray = useCallback((property: keyof SbomPackagesFlat) => {
        const countMap: { [key: string]: { value: keyof SbomPackagesFlat, count: number } } = {};

        data.forEach(obj => {
            const value = obj[property] as keyof SbomPackagesFlat;
            if(!countMap[value]) {
                countMap[value] = {
                    value,
                    count: 1
                };
            } else {
                countMap[value].count++;
            }
        });

        return Object.keys(countMap).map(value => ({
            value,
            count: countMap[value]
        }));
    },[data]);

    const filterPanel = useMemo(() => {
        return initFilterPanel({
            filterPanelElementsList: [
                {
                    filterProps: { key: FILTERS_KEYS.ADD_FILTER },
                    renderFunction: renderAddFilter,
                },
                {
                    filterProps: {
                        key: 'packageManagerName',
                        title: t('sbomTable.columns.packageManager'),
                        initialData: buildValueCountArray('packageManagerName'),
                        displayMapping: buildFilterPropsByProperty('packageManagerName'),
                    },
                    renderFunction: renderMultiSelectFilter,
                },
                {
                    filterProps: {
                        key: 'is-os-package',
                        title: t('sbomTable.columns.isOSPackage'),
                        initialData: buildValueCountArray('is-os-package'),
                        displayMapping: buildFilterPropsByProperty('is-os-package'),
                    },
                    renderFunction: renderMultiSelectFilter,
                },
                {
                    filterProps: {
                        key: FILTERS_KEYS.CLEAR_BUTTON,
                    },
                    renderFunction: renderClearAll,
                },
                {
                    filterProps: {
                        key: FILTERS_KEYS.FREE_TEXT,
                        title: 'Free Text',
                    },
                    keyInObjectForAPI: FILTERS_API_OBJECT_KEYS.FREE_TEXT,
                    renderFunction: renderDefaultFreeTextFilter,
                },
            ],
            getAggregations: getAggregations,
            shouldBuildObjectForAPI: false,
            filterId: 'filter-panel-sbom',
        });
    }, [buildFilterPropsByProperty, buildValueCountArray, getAggregations, t]);

    return (
        <>
            {filterPanel}
        </>
    );
};

export default FilterPanelSBOM;