import i18n, { validLanguages } from 'common/services/translations/translations';

export const K8sNamespacePrefix = 'k8s';
export type K8sTermTypes = 'events' | 'common' | 'welcome-page' | 'registry' | 'cluster' | 'protected-asset-details' | 'shiftleft' | 'awp' | 'service-account' | 'aws' | 'image-overview' | 'policy' | 'cve-explorer' | 'sbom' | 'vulnerability';
const termsFolders: Array<K8sTermTypes> = ['events', 'common', 'welcome-page', 'registry', 'cluster', 'protected-asset-details', 'shiftleft', 'awp', 'service-account', 'aws', 'image-overview','policy', 'cve-explorer', 'sbom', 'vulnerability'];

export const getK8sNamespace = (term: K8sTermTypes) => `${K8sNamespacePrefix}_${term}`;

const jsonFilesPaths = validLanguages.reduce<Array<{ path: string; language: string; term: K8sTermTypes; }>>((acc, language) => {
    const filesPAth = termsFolders.map(term => ({ path: `${language}/${term}`, language, term }));
    return [...acc, ...filesPAth];
}, []);

export const initializeK8sI18n = async () => {
    await Promise.all(jsonFilesPaths.map(async (file) => {
        try {
            const contents = await require(`./languages/${file.path}.json`);
            i18n.addResourceBundle(file.language, getK8sNamespace(file.term), contents);
        // eslint-disable-next-line
        } catch (error) { }
    }));
};