import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Dot } from '../../components/Dot/Dot';

const DotCellRenderer: React.FC<ICellRendererParams> = ({ node, value }) => {
    if (node.group) {
        return undefined;
    }
    if (value === undefined || value === null) {
        return '';
    }
    const isValueTruthy = typeof value === 'string' ? value === 'true' : Boolean(value);
    return <Dot isTrue={isValueTruthy}/>;
};

export default DotCellRenderer;
