import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import WidgetCard from 'common/components/Dashboard/WidgetCard/WidgetCard';
import EmptyState from 'common/components/EmptyState';
import { ProtectedAssetsResponse } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import WidgetPieChart from 'common/components/Widgets/Pie/PieWidget';
import { IGenericWidgetData, IGenericWidgetDataPropsBase } from 'common/module_interface/overview/Interface';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface ScanStatusesWidgetProps {
    isLoading: boolean;
    data?: ProtectedAssetsResponse;
}

export const ScanStatusesWidget: React.FC<ScanStatusesWidgetProps> = ({ isLoading, data }) => {
    const { t } = useTranslation(getK8sNamespace('awp'));
    const scanStatuses = React.useMemo<IGenericWidgetData & IGenericWidgetDataPropsBase | undefined>(() => {
        if (!data) return undefined;
        const sumStatuses = data.assets.reduce<{ [key: string]: number }>((acc, asset) => {
            const status = asset.vulnerability?.scanStatus || asset.externalAdditionalFields?.find(field => field.name === 'ScanStatus')?.value;
            if (!status) return acc;
            if (!acc[status]) {
                acc[status] = 0;
            }
            acc[status]++;
            return acc;
        }, {});

        return {
            items: Object.entries(sumStatuses).map(([key, value]) => ({ key, value })),
            title: Object.values(sumStatuses).reduce<number>((acc, sum) => acc + sum, 0).toString(),
            subtitle: t('awpTab.total'),
        };
    }, [data, t]);


    return (
        <WidgetCard
            title={t('awpTab.scanStatus')}
            content={
                isLoading ? (
                    <CloudAnimationLoader size='small' />
                ) : (scanStatuses
                    ? <WidgetPieChart data={scanStatuses} />
                    : <EmptyState label={t('awpTab.noScans')} />)}
        />

    );
};