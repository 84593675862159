import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

export const CommandWrapper = styled(Stack)`
    max-width: 460px;
`;

export const UpdateStackOldversionImage = styled.img`
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
    max-width: 460px;
`;