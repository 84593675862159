import { SwitchButton } from '@dome9/berries/react-components';
import { Stack } from 'common/design-system/components-v2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BladeStatus } from '../../Blades.types';
import BladeHeaderStyles from './BladeHeader.styled';
import { BladeHeaderProps } from './BladeHeader.types';

const BladeHeader: React.FunctionComponent<BladeHeaderProps> = props => {
    const { blade, isOpen, handleOnBladeStateChange, handleOnBladeChange } = props;
    const { t } = useTranslation(['k8s_common', 'k8s_cluster']);

    const openAble = React.useMemo(() => {
        return blade.active && (!!blade.content);
    }, [blade]);

    return (
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <BladeHeaderStyles.Row_topleft
                direction='row'
                alignItems='center'
                spacing={2}
                onClick={() => openAble && handleOnBladeStateChange(blade.name)}
                openAble={openAble}
                active={blade.active}
            >
                {blade.content && <BladeHeaderStyles.ChevronToggle name={'chevron-right'} isOpen={isOpen} size={12} isActive={blade.active} />}
                {(!blade.active && !blade.isNestedBlade) && <BladeHeaderStyles.InactiveIcon />}
                {(blade.active && blade.status) && <BladeHeaderStyles.StatusIcon name={(blade.status === BladeStatus.GOOD) ? 'checkCircle' : 'warning'} status={blade.status} />}
                <p>{t(`k8s_cluster:${blade.labelT}`)}</p>
                {blade.link && (
                    <BladeHeaderStyles.ReadMore
                        target={'_blank'}
                        href={blade.link}
                        rel='noreferrer' >
                        {t('k8s_common:READ_MORE')}
                    </BladeHeaderStyles.ReadMore>
                )}
            </BladeHeaderStyles.Row_topleft>
            {!blade.viewOnly && (
                <SwitchButton
                    checked={blade.active}
                    disabled={false}
                    onChange={(value) => handleOnBladeChange(blade.name, value)}
                />
            )

            }
        </Stack>
    );
};

export default BladeHeader;