import { renderMultiSelectFilter } from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { Addin } from 'common/extensibility/AddinRegistry';
import i18n from 'i18next';
import { ProtectedAssetsTableRegistry } from 'common/module_interface/assets/ProtectedAssetsTableRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';

const GENERAL_BOOLEAN_VALUES = {
    TRUE: 'true',
    FALSE: 'false',
    TRUE_CAPITAL_T: 'True',
    FALSE_CAPITAL_F: 'False',
};

const serverlessFilterDefsAddins: Addin<IFilterProps>[] =
    [

        {
            id: 'serverlessProtectionMode',
            content: () => {
                const initialData = [
                    { value: GENERAL_BOOLEAN_VALUES.TRUE },
                    { value: GENERAL_BOOLEAN_VALUES.FALSE },
                ];

                const displayMapping = {
                    [GENERAL_BOOLEAN_VALUES.TRUE]: { displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_PROTECTION_MODE.VALUE1') },
                    [GENERAL_BOOLEAN_VALUES.FALSE]: { displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_PROTECTION_MODE.VALUE2') },
                };

                return {
                    filterProps: {
                        initialData: initialData,
                        key: 'externalAdditionalFields|WithFSPBlock',
                        title: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_PROTECTION_MODE.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'serverlessRuntimeProtection',
            content: () => {

                const initialData = [{ value: GENERAL_BOOLEAN_VALUES.TRUE }];

                const displayMapping = {
                    [GENERAL_BOOLEAN_VALUES.TRUE]: { displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_RUNTIME_PROTECTION.VALUE1') },
                };

                return {
                    filterProps: {
                        initialData: initialData,
                        key: 'externalAdditionalFields|IncludesFSP',
                        title: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_RUNTIME_PROTECTION.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'serverlessEnable',
            content: () => {

                const initialData = [
                    { value: GENERAL_BOOLEAN_VALUES.TRUE },
                    { value: GENERAL_BOOLEAN_VALUES.FALSE },
                ];

                const displayMapping = {
                    [GENERAL_BOOLEAN_VALUES.TRUE]: { displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_ENABLE.VALUE1') },
                    [GENERAL_BOOLEAN_VALUES.FALSE]: { displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_ENABLE.VALUE2') },
                };

                return {
                    filterProps: {
                        initialData: initialData,
                        key: 'externalAdditionalFields|ServerlessEnable',
                        title: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_ENABLE.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'serverlessAutoProtect',
            content: () => {
                const initialData = [
                    { value: GENERAL_BOOLEAN_VALUES.TRUE },
                    { value: GENERAL_BOOLEAN_VALUES.FALSE },
                ];

                const displayMapping = {
                    [GENERAL_BOOLEAN_VALUES.TRUE]: { displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_AUTO_PROTECT.VALUE1') },
                    [GENERAL_BOOLEAN_VALUES.FALSE]: { displayText: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_AUTO_PROTECT.VALUE2') },
                };

                return {
                    filterProps: {
                        initialData: initialData,
                        key: 'externalAdditionalFields|FSPStickyMode',
                        title: i18n.t('SERVERLESS.PROTECTED_ASSETS.FILTERS.SERVERLESS_AUTO_PROTECT.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
    ];

export default function initializeFilters() {
    ProtectedAssetsTableRegistry.addFilterAddins(serverlessFilterDefsAddins);
}
