import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const CveTableContainer = styled(Stack)`
`;

export const LoaderWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CveTableStyles = {
    CveTableContainer,
    LoaderWrapper
};

export default CveTableStyles;