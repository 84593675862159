import styled from 'styled-components';

export const AWSCrossAccountRoleAuthExplanationWrapper = styled.div`
  max-width: 650px;
`;

export const Bullet = styled.div`
  display: flex;
  gap: 10px;

  &::before {
    content: "•";
    display: inline;
  }
`;

export const InfoBox = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    align-items: baseline;
    padding: 15px;
    background:hsla(207, 65%, 54%, 0.1);
    border: 1px solid hsla(207, 65%, 54%, 1);
`;