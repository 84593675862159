import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext, Controller, SubmitHandler } from 'react-hook-form';
import { TextField } from '@dome9/berries/react-components';
import ServiceAccount from 'modules/workloads/components/ServiceAccount/ServiceAccount';
import Container from '../../WizardStep/Container';
import Footer from '../../WizardStep/Footer';
import Content from '../../WizardStep/Content';
import '../../../../registry/RegistryOnboarding/RegistryOnboarding.scss';
import { getOrganizationalUnitId } from '../../../../../reducers/registry/registry.slice';
import { setOrganizationalUnitId } from '../../../../../reducers/registry';
import { CustomStack, Title, SubTitle, ButtonWithLoader } from './ShiftLeftConfigurations.styled';
import { FormValues } from '../../ShiftleftOnboarding';
import ShiftLeftAccountService from '../../../../../services/shiftLeft/shiftLeftAccount.service';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { useTranslation } from 'react-i18next';
import { Stack, Spinner } from 'common/design-system/components-v2';
import OrganizationalUnit from 'common/components/OrganizationalUnit/OrganizationalUnit';
import { getCheckpointUrl } from 'common/components/Forms/urls';
import { ALink } from 'common/components/ALink';

interface StepOneProps {
    onNext: () => void
}

const ShiftLeftConfigurations: React.FC<StepOneProps> = ({ onNext }) => {
    const { control, handleSubmit, setValue, formState: { isSubmitting }, trigger } = useFormContext();
    const dispatch = useDispatch();
    const selectedOrganizationalUnitId = useSelector(getOrganizationalUnitId);
    const { t } = useTranslation(['k8s_shiftleft','k8s_common']);

    const organizationalUnitChange = (id: string) => {
        setValue('organizationalUnitId', id);
        dispatch(setOrganizationalUnitId(id));
        return true;
    };

    const onSubmit: SubmitHandler<FormValues> = async (formData) => {

        const { name, description, organizationalUnitId } = formData;

        try {
            const res = await ShiftLeftAccountService.createAccount({ name, description, organizationalUnitId });

            const { status, data } = res;

            if(status === 200 && data.id) {
                setValue('envId', data.id);
                onNext();
            } else {
                getNotificationsService().addNotification({
                    type: NotificationType.ERROR,
                    title: 'something went wrong try again',
                    text: '',
                });
            }
        } catch (e) {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: 'A shiftleft with the specified name (a) already exists',
                text: '',
            });
        }
    };

    return (
        <Container>
            <Content>
                <Title>{t('k8s_shiftleft:shiftLeftOnboarding.steps.configurations.title')}</Title>
                <Stack direction={'column'} spacing={2}>
                    <SubTitle>{t('k8s_shiftleft:shiftLeftOnboarding.steps.configurations.subTitle')}</SubTitle>
                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                        <SubTitle>{t('k8s_shiftleft:shiftLeftOnboarding.steps.configurations.documentationText')}</SubTitle>
                        <ALink
                            href={getCheckpointUrl('SHIFTLEFT_DOCS')}
                            target='_blank'
                            rel='noreferrer'
                        >
                            {t('k8s_shiftleft:shiftLeftOnboarding.steps.configurations.documentationLink')}
                        </ALink>
                    </Stack>
                </Stack>
                <CustomStack spacing={4}>
                    <Stack direction={'row'} spacing={2}>
                        <div style={{ flex: 1 }}>
                            <Controller
                                name='name'
                                control={control}
                                rules={{ required: 'Shiftleft name can\'t be empty' }}
                                render={({ field, fieldState: { error } }) =>
                                    <TextField
                                        {...field}
                                        label={`${t('k8s_shiftleft:shiftLeftOnboarding.steps.configurations.fields.name')}:`}
                                        state={error && 'error'}
                                        required
                                        onClear={async () => {
                                            setValue('name', '');
                                            await trigger();
                                        }}
                                        helper={error?.message}
                                    />}
                            />
                        </div>
                    </Stack>
                    <Stack direction={'row'} spacing={2}>
                        <div style={{ flex: 1 }}>
                            <Controller
                                name='description'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={`${t('k8s_shiftleft:shiftLeftOnboarding.steps.configurations.fields.description')}:`}
                                        onClear={() => setValue('description', '')}
                                    />
                                )}
                            />
                        </div>
                    </Stack>

                    <Stack direction={'row'} spacing={2}>
                        <div style={{ flex: 1 }}>
                            <OrganizationalUnit
                                selectedOrganizationalUnit={selectedOrganizationalUnitId}
                                onChange={organizationalUnitChange}
                            />
                        </div>
                    </Stack>

                    <ServiceAccount/>

                </CustomStack>
            </Content>
            <Footer>
                {/* @ts-ignore */}
                <ButtonWithLoader color={'primary'} disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
                    <>{isSubmitting && <span><Spinner/></span>}</>
                    <span>{t('k8s_common:NEXT')}</span>
                </ButtonWithLoader>
            </Footer>
        </Container>
    );
};

export default ShiftLeftConfigurations;