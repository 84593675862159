import { KubernetesAccount, KubernetesAgentSummary, KubernetesAgentSummaryType, KubernetesFeatureType } from 'modules/workloads/services/kubernetesAccount/kubernetesAccount.interface';

export interface BladesProps {
    id: string;
}

export enum BladeStatus {
    GOOD = 'good',
    WARNING = 'warning',
    ERROR = 'error'
}

export interface BladeItem {
    labelT: string;
    name: string;
    isActiveName?: keyof KubernetesAccount;
    updateName: KubernetesFeatureType;
    link?: string;
    viewOnly?: boolean;
    content?: boolean;
    updateHelmOnUpdate?: boolean;
    agentTypes?: Array<KubernetesAgentSummaryType>;
    isHidden?: boolean;

    nestedBlades?: Array<Omit<BladeItem, 'nestedBlades'>>;
    isNestedBlade?: boolean;
}

export interface BladeItemWithData extends BladeItem {
    active: boolean;
    status?: BladeStatus;
    agents: Array<KubernetesAgentSummary>;

    nestedBlades?: Array<Omit<BladeItemWithData, 'nestedBlades'>>;
}