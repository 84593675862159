import { CpIcon, CpSpinner } from '@dome9/components/react/components';
import useInterval from 'common/hooks/useInterval';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    getNewRegistry,
    getRegistryAccountSummary,
    getRegistryApplicationClientId,
    getRegistryAuthMethod,
    getRegistryPullSecretName,
    getRegistryTenantId,
    getRegistryType,
    getStep
} from '../../../reducers/registry/registry.slice';
import {
    getClusterNamespace,
    getKubernetesAccountSummary,
    getNewCluster,
    getRelevantCluster,
    getTotalAgents
} from '../../../reducers/cluster/cluster.slice';
import { getKubernetesAccountSummaryById, getRegistryAccountSummaryById } from '../../K8sRegistry.utils';
import { AgentsStatus } from '../../../reducers/registry/registry.interface';
import { RegistryAuthMethod, RegistryTypeTranslations } from 'modules/workloads/utils/RegistryOptions/RegistryOptions';

const RegistrySummary: FunctionComponent = () => {
    const { t } = useTranslation('k8s_registry');
    const newRegistry = useSelector(getNewRegistry);
    const newCluster = useSelector(getNewCluster);
    const registryType = useSelector(getRegistryType);
    const authMethod: RegistryAuthMethod = useSelector(getRegistryAuthMethod);
    const pullSecretName = useSelector(getRegistryPullSecretName);
    const tenantId = useSelector(getRegistryTenantId);
    const clientId = useSelector(getRegistryApplicationClientId);
    const relevantCluster = useSelector(getRelevantCluster);
    const clusterNamespace = useSelector(getClusterNamespace);
    const registryAccountSummary = useSelector(getRegistryAccountSummary);
    const kubernetesAccountSummary = useSelector(getKubernetesAccountSummary);
    const totalAgents = useSelector(getTotalAgents);
    const currentStep = useSelector(getStep);

    useInterval(async () => {
        if (newRegistry?.id && registryAccountSummary?.agentsStatus !== AgentsStatus.Ok) {
            await getRegistryAccountSummaryById(newRegistry.id);
        }
        if (relevantCluster?.id && kubernetesAccountSummary?.agentsStatus !== AgentsStatus.Ok) {
            await getKubernetesAccountSummaryById(relevantCluster.id);
        }
    }, 3000, currentStep === 3);

    return (
        <div className='k8s-registry-summary registry-step'>
            <div className='info-text-container'>
                <CpIcon className={'info-icon'} icon='info' size={'xl'}></CpIcon>
                <div className='info-text'>
                    {t('ON_BOARDING.SUMMARY_INFO.ROW_ONE', { name: newRegistry?.name })}
                    <br />
                    {t('ON_BOARDING.SUMMARY_INFO.ROW_TWO')}
                </div>
            </div>

            <div className='summary-row'>
                <div className='key'>{t('ON_BOARDING.REGISTRY_NAME')}:</div>
                <div className='value'>{newRegistry?.name}</div>
            </div>

            <div className='summary-row'>
                <div className='key'>{t('ON_BOARDING.REGISTRY_DESCRIPTION')}:</div>
                <div className='value'>{newRegistry?.description || 'N/A'}</div>
            </div>

            <div className='summary-row'>
                <div className='key'>{t('ON_BOARDING.REGISTRY_ID')}:</div>
                <div className='value'>{newRegistry?.id}</div>
            </div>

            <div className='summary-row'>
                <div className='key'>{t('ON_BOARDING.REGISTRY_TYPE')}:</div>
                <div className='value'>{t(RegistryTypeTranslations[registryType])}</div>
            </div>

            <div className='summary-row'>
                <div className='key'>{t('ON_BOARDING.AUTH_METHOD')}:</div>
                <div className='value'>{authMethod}</div>
            </div>

            {(authMethod === 'AcrServicePrincipal' || authMethod === 'EcrUserAccessKeys') && (
                <div className='summary-row'>
                    <div className='key'>{t('ON_BOARDING.PULL_SECRET')}:</div>
                    <div className='value'>{pullSecretName.value}</div>
                </div>
            )}

            {authMethod === 'AcrServicePrincipal' && (
                <div className='summary-row'>
                    <div className='key'>{t('ON_BOARDING.TENANT_ID')}:</div>
                    <div className='value'>{tenantId.value}</div>
                </div>
            )}
            
            {authMethod === 'AcrManagedId' && (
                <div className='summary-row'>
                    <div className='key'>{t('ON_BOARDING.CLIENT_ID')}:</div>
                    <div className='value'>{clientId.value}</div>
                </div>
            )}

            <div className='summary-row'>
                <div className='key'>{t('ON_BOARDING.CONNECTED_CLUSTER')}:</div>
                <div className='value'>
                    {relevantCluster?.name}({relevantCluster?.id})
                </div>
            </div>

            <div className='summary-row'>
                <div className='key'>{t('ON_BOARDING.AGENT_NAMESPACE')}:</div>
                <div className='value'>{clusterNamespace.value}</div>
            </div>

            <div className='summary-row'>
                <div className='key'>{t('ON_BOARDING.KUBERNETES_VERSION')}:</div>
                <div className='value'>{kubernetesAccountSummary?.clusterVersion || 'N/A'}</div>
            </div>

            <div className='summary-row'>
                <div className='key'>Features:</div>
                <div className='feature'>{t('ON_BOARDING.POSTURE_MANAGEMENT')}</div>
                {relevantCluster?.imageAssuranceEnabled && (
                    <div className='feature'>{t('ON_BOARDING.IMAGE_ASSURANCE')}</div>
                )}
                {relevantCluster?.admissionControlEnabled && (
                    <div className='feature'>{t('ON_BOARDING.ADMISSION_CONTROL')}</div>
                )}
                {relevantCluster?.runtimeProtectionEnabled && (
                    <div className='feature'>{t('ON_BOARDING.RUNTIME_PROTECTION_PREVIEW')}</div>
                )}
                {relevantCluster?.threatIntelligenceEnabled && (
                    <div className='feature'>{t('ON_BOARDING.THREAT_INTELLIGENCE')}</div>
                )}
            </div>

            <div className='summary-row'>
                <div className='key'>{t('ON_BOARDING.CLUSTER_AGENTS')}:</div>
                <div className='value'>{totalAgents}</div>
            </div>

            <AgentStatusRow label={'Registry Status'} status={registryAccountSummary?.agentsStatus} />

            {newCluster && <AgentStatusRow label={'Cluster Status'} status={kubernetesAccountSummary?.agentsStatus} />}
        </div>
    );
};
interface AgentStatusRowProps {
    label: string;
    status: AgentsStatus;
}

const AgentStatusRow: React.FC<AgentStatusRowProps> = ({ label, status }) => {
    const loading = status === AgentsStatus.Pending;

    return (
        <div className='summary-row'>
            <div className='key'>{label}:</div>
            <div className='value'>
                <StatusIcon status={status} />
                {status}
            </div>
            {loading && <CpSpinner className='spinner' />}
        </div>
    );
};

interface StatusIconProps {
    status: AgentsStatus;
}

const StatusIcon: React.FC<StatusIconProps> = ({ status }) => {
    const icon = status === AgentsStatus.Ok ? 'check' : 'warning';
    const color = status === AgentsStatus.Ok ? '#56b40a' : status === AgentsStatus.Pending ? '#ffd546' : '#f24e4e';

    return <CpIcon icon={icon} color={color} style={{ marginRight: '4px' }} />;
};

export default RegistrySummary;
