import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import WorkloadRequestImageScan from 'modules/workloads/services/workload/components/WorkloadRequestImageScan';
import { IconButton, Stack, Tooltip } from 'common/design-system/components-v2';
import { AssetTypes } from 'common/assets/assets.const';

const ImageScanStatusCellRenderer: React.FC<ICellRendererParams<IProtectedAssetViewModel>> = (params) => {
    const relevantTypesForScan = [
        AssetTypes.CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE,
        AssetTypes.KUBERNETES_KUBERNETESIMAGE,    
    ];

    const [isCellMenuOpen, setIsCellMenuOpen] = React.useState(false);

    if (!params.data || !relevantTypesForScan.includes(params.data.typeByPlatform)) {
        return params.value;
    }
    
    const handleOnIconClick = () => {
        params.node?.setSelected(false);
        setIsCellMenuOpen(!isCellMenuOpen);
    };

    const isStatusUnmatched = params.value.toLowerCase() === 'unmatched';

    if (isStatusUnmatched) return params.value;
    
    return (
        <Stack justifyContent='space-between' alignItems='center' direction='row' spacing={2} fullHeight>
            <span>{params.value}</span>
            <Tooltip
                onClickOutside={() => setIsCellMenuOpen(false)}
                visible={isCellMenuOpen}
                content={(
                    <WorkloadRequestImageScan
                        environmentId={params.data?.cloudAccountId || ''}
                        imageIdOrDigest={params.data?.entityId || ''}
                        successCallback={() => {
                            setIsCellMenuOpen(false);
                            params.api?.refreshServerSide();
                        }}
                    />
                )}
                interactive={true}
                appendTo={document.body}
            >
                <IconButton iconProps={{ name: 'more' }} onClick={handleOnIconClick} />
            </Tooltip>
        </Stack>
    );
};

export default ImageScanStatusCellRenderer;