import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { Vendors } from 'common/consts/vendors';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import EmptyState from 'common/components/EmptyState';
import { ScanMode } from 'modules/workloads/services/awp/awp.interface';
import { useScanRequest } from 'modules/workloads/services/awp/hooks/useScanRequest/useScanRequest';
import { getNotificationsService } from 'common/interface/services';

const ScanResultEmptyState = ({ entity }: { entity: ICloudEntityData }) => {
    const { t } = useTranslation(getK8sNamespace('common'));
    const { protectedAsset, cloudAccount } = entity;
    const status = protectedAsset.vulnerability?.scanStatus || protectedAsset.externalAdditionalFields?.find(({ name, source }) => name === 'ScanStatus' && source === 'agentless')?.value;
    const details = protectedAsset.vulnerability?.scanStatusDetails || protectedAsset.externalAdditionalFields?.find(({ name, source }) => name === 'ScanStatusDetails' && source === 'agentless')?.value;
    const isScanModeSaaS = protectedAsset.externalAdditionalFields?.find(({ name, source }) => name === 'ScanMode' && source === 'agentless')?.value === ScanMode.Saas;

    const { onScanRequest, isRequestPending } = useScanRequest({ cloudAccountId: cloudAccount.id, cloudProvider: cloudAccount.platform as Vendors, entityId: protectedAsset.entityId, addNotificationService: getNotificationsService() });

    return (
        <EmptyState
            label={`${t('workloadImageScan.agentlessScan.header')} ${status}`}
            description={`${details ? `${details}\n` : ''}${!isScanModeSaaS ? t('workloadImageScan.agentlessScan.body') : ''}`}
            iconName='objectExplorer'
            iconSize={64}
            buttons={!isScanModeSaaS ? [{
                color: 'brandPrimary',
                onClick: onScanRequest,
                children: t('workloadImageScan.agentlessScan.buttonText'),
                disabled: isRequestPending,
            }] : undefined}
        />
    );
};

export default ScanResultEmptyState;
