import { AxiosResponse } from 'axios';
import { AssetTypes } from 'common/assets/assets.const';
import { ICachingConfig } from 'common/interface/services';
import { ManagedKubernetesType } from 'modules/workloads/pages/kubernetesCluster/kubernetesEnvironment/KubernetesEnvironment.types';

export interface BaseRequest {
    cachingConfig?: ICachingConfig;
}

// ~~~ GetImages ~~~
export namespace GetImages {
    export type Function = (payload: { request: GetImages.Request, base?: BaseRequest }) =>
        Promise<AxiosResponse<GetImages.Response>>;
    export interface Request {
        dome9Id: string;
    }
    export interface Response extends Array<Image> { }
}

// ~~~ ImageAssuraceScanImage ~~~
export namespace ImageAssuraceScanImage {
    export type Function = (payload: { request: ImageAssuraceScanImage.Request, base?: BaseRequest }) =>
        Promise<AxiosResponse<ImageAssuraceScanImage.Response>>;
    export interface Request {
        environmentId: string;
        imageIdOrDigest: string;
    }
    export type Response = undefined;
}

// ~~~ ImageAssuraceScanEnvironment ~~~
export namespace ImageAssuraceScanEnvironment {
    export type Function = (payload: { request: ImageAssuraceScanEnvironment.Request, base?: BaseRequest }) =>
        Promise<AxiosResponse<ImageAssuraceScanEnvironment.Response>>;
    export interface Request {
        environmentId: string;
        scanAction: ImageAssuraceScanEnvironmentScanAction;
    }
    export interface Response {
        imagesToScanCount: number;
    }
}


// ~~~ GetWorkloads ~~~
export namespace GetWorkloads {
    export type Function = (payload: { request: GetWorkloads.Request, base?: BaseRequest }) =>
        Promise<AxiosResponse<GetWorkloads.Response>>;
    export interface Request {
        imageId: string;
        digest: string;
    }
    export interface Response {
        kubernetesImageWorkloads: Array<KubernetesImageWorkloads>,
        ecsImageWorkloads: Array<EcsImageWorkloads>,
    }
}

// ~~~ RuntimeProtectionAccountRules ~~~
export namespace RuntimeProtectionAccountRules {
    export type Function = (vendor: string, environmentId: string, payload: { request: RuntimeProtectionAccountRules.Request }) =>
        Promise<AxiosResponse<RuntimeProtectionAccountRules.Response>>;
    export interface Request {
        name: string;
        payloads: Array<RuntimeProtectionAccountRulesPayloads>;
        payloadsPatternType: string;
        target: string;
        workloadExternalIds: Array<string>;
    }
    export type Response = undefined;
}

export interface RuntimeProtectionAccountRulesPayloads {
    callStackInfo: string;
    pattern: string;
}

export interface Image {
  id: string;
  imageId: string;
  name: string;
  registry: string;
  repository: string;
  tag: string;
  type: string;
  digest: string;
  riskScore?: string;
  scanStatus: string;
}

export enum ImageAssuraceScanEnvironmentScanAction {
    Failed = 'Failed',
    All = 'All'
}

// ~~~ GetWorkloadEnvironment ~~~
export namespace GetWorkloadEnvironment {
    export type Function = (payload?: BaseRequest) => Promise<AxiosResponse<GetWorkloadEnvironment.Response>>;
    export interface Response extends WorkloadEnvironment { }
}

export interface WorkloadEnvironment {
  kubernetesAccounts: WorkloadEnvironmentKubernetesAccount[];
  containerRegistryAccounts: WorkloadEnvironmentContainerRegistryAccount[];
}

export interface WorkloadEnvironmentContainerRegistryAccount {
  managedRegistriesAccountDetails: ManagedRegistriesAccountDetails;
  onBoardedRegistriesAccountDetails?: OnBoardedRegistriesAccountDetails;
}

export interface OnBoardedRegistriesAccountDetails {
  name: string;
  id: string;
  status: WorkloadEnvironmentStatus;
  statusDescription: string;
  description?: string;
  type: ContainerRegistryType;
  registryUri: string;
}

export interface ManagedRegistriesAccountDetails {
  name: string;
  providerAccountId: string;
  cloudAccountId: string;
  cloudAccountName: string;
  dome9EntityId: string;
  type: string;
  organizationalUnitId?: string;
  organizationalUnitPath?: string;
  organizationalUnitName: string;
  platform: string;
}

export interface WorkloadEnvironmentKubernetesAccount {
  managedKubernetesAccountDetails: ManagedKubernetesAccountDetails | null;
  onBoardedKubernetesAccountDetails: OnBoardedKubernetesAccountDetails | null;
}

export interface OnBoardedKubernetesAccountDetails {
  name: string;
  id: string;
  kubernetesAccountId: string;
  status: WorkloadEnvironmentStatus;
  statusDescription: string;
  description: string;
  clusterVersion?: string;
  clusterVersionStatus: VersionStatus;
  clusterVersionStatusDescription?: any;
  features: KubernetesAccountFeatureSummary[];
  type: KubernetesPlatformType;
}

export interface ManagedKubernetesAccountDetails {
  name: string;
  providerAccountId: string;
  cloudAccountId: string;
  managedClusterDome9EntityId: string;
  dome9EntityId: string;
  type: ManagedKubernetesType;
  cloudAccountName: string;
  organizationalUnitId?: string;
  organizationalUnitPath?: string;
  organizationalUnitName: string;
  platform: string;
}

export enum WorkloadEnvironmentStatus {
    OK = 'OK',
    INITIALIZING = 'INITIALIZING',
    PENDING_CLEANUP = 'PENDING_CLEANUP',
    PENDING = 'PENDING',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    DISABLED = 'DISABLED',
}

export enum KubernetesPlatformType {
    NA = 'NA',
    GKE = 'GKE',
    EKS = 'EKS',
    AKS = 'AKS',
    Openshift = 'Openshift',
}

export enum ContainerRegistryType {
    ACR = 'ACR',
    ECR = 'ECR',
    GCR = 'GCR',
    GAR = 'GAR',
    Artifactory = 'Artifactory',
    Harbor = 'Harbor',
    Nexus = 'Nexus',
    GitHub = 'GitHub',
}

export const getKubernetesPlatformFullTypeName = (type: KubernetesPlatformType) => {
    switch (type) {
        case KubernetesPlatformType.GKE:
            return 'GkeCluster';
        case KubernetesPlatformType.EKS:
            return 'EksCluster';
        case KubernetesPlatformType.AKS:
            return 'AksCluster';
        case KubernetesPlatformType.Openshift:
            return 'OpenshiftSecurityContextConstraint';
        case KubernetesPlatformType.NA:
            return 'NA';
    }
};

export interface KubernetesAccountFeatureSummary {
    name: KubernetesAccountFeatureSummaryName;
    status: WorkloadEnvironmentStatus;
    statusDescription: string;
  }
  
export enum KubernetesAccountFeatureSummaryName { // This is defined in BE as string (defined here for type safety)
      Inventory = 'Inventory',
      AdmissionControl = 'AdmissionControl',
      ThreatIntelligence = 'ThreatIntelligence',
      ImageAssurance = 'ImageAssurance',
      RuntimeProtection = 'RuntimeProtection',
  }

export enum VersionStatus {
    Ok = 'Ok',
    Warning = 'Warning',
    Error = 'Error',
    Unknown = 'Unknown'
}

interface KubernetesImageWorkloads {
    annotations: { };
    createdAt: Date;
    desiredPodsNumber: Number;
    environmentId: string;
    externalId: string;
    id: string;
    kind: string;
    labels: { };
    name: string;
    namespace: string;
    readyPodsNumber: number
}

interface EcsImageWorkloads {
    id: string;
    externalId: string;
    type: string;
    awsAccount: string
    region: string
    cluster: string;
    name: string;
    tags: { };
    environmentId: string;
    desiredTasksNumber: number;
    createdAt: Date;
    desiredPodsNumber: Number;
}

export const WorkloadTypes: Record<string, string> = {
    KubernetesDaemonSet: AssetTypes.KUBERNETES_KUBERNETESDAEMONSET,
    KubernetesDeployment: AssetTypes.KUBERNETES_KUBERNETESDEPLOYMENT,
    KubernetesPod: AssetTypes.KUBERNETES_KUBERNETESPOD,
    KubernetesReplicaSet: AssetTypes.KUBERNETES_KUBERNETESREPLICASET,
};
