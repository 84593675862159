import React, { useCallback, useMemo, useState } from 'react';
import { Table, Stack, Tooltip, Typography, ComponentOverflow } from 'common/design-system/components-v2';
import { ITableExportButton } from 'common/design-system/components-v2/Table/Table.types';
import { GridApi, GridReadyEvent, IRowNode, ITooltipParams } from 'ag-grid-community';
import { isFilterValuesEmpty } from '../../filterUtils';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import IconLinkCellRenderer from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { useTranslation } from 'react-i18next';
import { getK8sNamespace } from 'modules/workloads/initialize.i18n';
import FilterPanelVulnerabilityCve from '../../FilterPanelVulnerability/FilterPanelVulnerabilityCve';
import { FlatPackageAndCve } from 'modules/workloads/services/vulnerability/vulnerability.interface';
import IsFixableCellRenderer from 'modules/workloads/CellRenders/IsFixableCellRenderer/IsFixableCellRenderer';
import SeverityCellRenderer from 'modules/workloads/CellRenders/SeverityCellRenderer/SeverityCellRenderer';
import DotCellRenderer from 'modules/workloads/CellRenders/DotCellRenderer/DotCellRenderer';
import { ICellRendererParams, ValueFormatterParams } from 'ag-grid-enterprise';
import { severityComparator } from 'modules/workloads/utils/versionsCompare';
import { SortDirection } from '../../utils';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { VULNERABILITY_DRAWER_ID } from 'modules/workloads/services/cveDrawerRegistry/cveDrawerRegistry.consts';
import { floatToPercentage } from 'modules/workloads/utils/floatToPercentage';

interface CvesProps {
    cves: Array<FlatPackageAndCve>,
    exportCveAsJSONAndCSV: Array<ITableExportButton>
    setRemediationOnly: (remediationOnly: boolean) => void;
}

let objectFilter: IFiltersValues = {};
const fieldsToSearchTextFree = ['id', 'description', 'packageVersion', 'packageName'];

const Cves: React.FC<CvesProps> = ({ cves, exportCveAsJSONAndCSV, setRemediationOnly }) => {
    const gridRef = React.useRef<GridApi>();
    const [itemDisplayedRowCount, setItemDisplayedRowCount] = useState<number>(0);
    const { t } = useTranslation(getK8sNamespace('vulnerability'));

    const getColumnDefs = React.useMemo(() => [
        {
            field: 'id',
            headerName: t('VulnerabilityTable.cves.id'),
            enableRowGroup: false,
            cellRenderer: (params: any) => {
                if (!params.data) {
                    return params.value;
                }
                const { data: { severity } } = params;
                return (
                    <IconLinkCellRenderer
                        value={params.value}
                        isLink={true}
                        levelIconProps={{
                            iconProps: { name: 'cve' },
                            category: 'severity',
                            level: severity.toLowerCase(),
                            size: 'md'
                        }}
                        href={''}
                        onClick={(event) => {
                            event.preventDefault();
                            // TODO: open alert if no cveId
                            showDrawer(VULNERABILITY_DRAWER_ID, { cveId: encodeURIComponent(params.data['id']), packageId: encodeURIComponent(params.data['packageId']) });
                        }}
                    />
                )
                ;
            }
        },
        {
            field: 'severity',
            initialSort: SortDirection.ASC,
            headerName: t('VulnerabilityTable.commonColumns.severity'),
            maxWidth: 120,
            comparator: severityComparator,
            cellRenderer: (params: any) => <SeverityCellRenderer {...params} />
        },
        {
            field: 'isFixedByPackageRemediation',
            headerName: t('VulnerabilityTable.cves.fixable'),
            textAlign: 'center',
            maxWidth: 120,
            cellRenderer: (params: ICellRendererParams) => <IsFixableCellRenderer {...params} />
        },
        {
            field: 'knownExploit',
            headerName: t('VulnerabilityTable.cves.knownExploit'),
            maxWidth: 120,
            cellRenderer: (params: ICellRendererParams) => <DotCellRenderer {...params} />,
            tooltipValueGetter: (params: ITooltipParams) => {
                return params.value === undefined || params.value === null ? 'N/A' : undefined;
            },
        },
        {
            field: 'remediation',
            headerName: t('VulnerabilityTable.cves.remediation'),
            textAlign: 'center',
            maxWidth: 150,
            hide: true,
            enableRowGroup: false,
            cellRenderer: (params: ICellRendererParams) => <Stack><Tooltip content={params.value}><span>{params.value}</span></Tooltip></Stack>
        },
        {
            field: 'packageVersion',
            headerName: t('VulnerabilityTable.cves.packageVersion'),
        },
        {
            field: 'cvss-info.vector-string',
            headerName: t('VulnerabilityTable.cves.vector'),
            valueFormatter: (params: ValueFormatterParams) => params.value ? params.value : 'N/A',
            hide: true
        },

        {
            field: 'source',
            headerName: t('VulnerabilityTable.cves.source'),
            hide: true
        },
        {
            field: 'cvss-info.source',
            headerName: t('VulnerabilityTable.cves.CVSSSource'),
            hide: true,
            enableRowGroup: false
        },
        {
            field: 'description',
            headerName: t('VulnerabilityTable.commonColumns.description'),
            hide: true,
            enableRowGroup: false,
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <Stack>
                        <Tooltip content={params.value}><span>{params.value}</span></Tooltip>
                    </Stack>
                );
            }
        },
        // Package fields
        {
            field: 'packageName',
            headerName: t('VulnerabilityTable.cves.packageName'),
            rowGroup: true
        },
        {
            field: 'packageSeverity',
            headerName: t('VulnerabilityTable.cves.packageSeverity'),
            hide: true,
        },
        {
            field: 'packageType',
            headerName: t('VulnerabilityTable.cves.packageType'),
            hide: true,
        },
        {
            field: 'packagePackage-manager.name',
            headerName: t('VulnerabilityTable.cves.packageManager'),
            hide: true,
        },
        {
            field: 'packageIs-os-package',
            headerName: t('VulnerabilityTable.cves.isOSPackage'),
            cellRenderer: (params: ICellRendererParams) => <DotCellRenderer {...params} />,
            hide: true,
        },
        {
            field: 'packagePackage-manager.path',
            headerName: t('VulnerabilityTable.cves.packagePath'),
        },
        {
            field: 'epssScore',
            headerName: t('VulnerabilityTable.cves.epssScore'),
            cellRenderer: (params: ICellRendererParams) => {
                if (params.node.group) {
                    return undefined;
                }
                if (params.value === undefined || params.value === null) {
                    return '';
                }
                return floatToPercentage(params.value);
            },
            tooltipValueGetter: (params: ITooltipParams) => {
                return params.value === undefined || params.value === null ? 'N/A' : undefined;
            },
            hide: true,
        },
        {
            field: 'relatedIds',
            enableRowGroup: false,
            headerName: t('VulnerabilityTable.cves.relatedIDs'),
            cellRenderer: (params: ICellRendererParams) => {
                if (!params.data || !params.value) {
                    return params.value;
                }
                return (
                    <ComponentOverflow commaSeperated components={params.value.map((item: string) => <Typography key={item}>{item}</Typography>)} />
                );
            }
        },
    ],[t]);

    const onGridReady = useCallback(
        (event: GridReadyEvent) => {
            gridRef.current = event.api;
        },
        [],
    );

    const isExternalFilterPresent = useCallback(() => {
        return objectFilter && !isFilterValuesEmpty(objectFilter);
    }, []);

    const doesExternalFilterPass = useCallback(
        (node: IRowNode) => {
            let doesExternalFilterPassValue = true;
            if (node.data) {
                Object.keys(objectFilter).forEach((key) => {
                    if(!objectFilter[key] || objectFilter[key].length === 0) return;
                    if(key !== 'free-text') {
                        const values: Array<string> = objectFilter[key];
                        if(typeof node.data[key] === 'boolean') {
                            const doesFilterPassValues = values.some(value => node.data[key].toString() === value);
                            doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassValues;
                        } else {
                            const doesFilterPassValues = values.some(value => node.data[key] === value);
                            doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassValues;
                        }
                    } else {
                        const doesFilterPassFreeText = fieldsToSearchTextFree.some(field => {
                            if(!node.data[field]) return false;
                            return node.data[field].toLowerCase().includes(objectFilter[key].toLowerCase());
                        });
                        doesExternalFilterPassValue = doesExternalFilterPassValue && doesFilterPassFreeText;
                    }
                });
            }
            return doesExternalFilterPassValue;
        },
        []
    );
    
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            enableRowGroup: true,
            sortable: true,
        };
    }, []);

    const externalFilterChanged = useCallback((newValue: IFiltersValues) => {
        objectFilter = newValue;
        gridRef.current?.onFilterChanged();
        if(newValue.isFixedByPackageRemediation[0] === 'true') {
            setRemediationOnly(true);
        } else {
            setRemediationOnly(false);
        }

        let count = 0;
        gridRef.current?.forEachNodeAfterFilter((node) => {
            !node.group && count++;
        });
        setItemDisplayedRowCount(count);
    }, [setRemediationOnly]);

    return (
        <>
            <FilterPanelVulnerabilityCve data={cves} onFilterChange={externalFilterChanged}></FilterPanelVulnerabilityCve>
            <Table
                tableId='cves'
                saveColumnsState
                exportButtons={exportCveAsJSONAndCSV}
                gridOptions={{
                    rowData: cves,
                    columnDefs: getColumnDefs,
                    defaultColDef,
                    groupDefaultExpanded: 1,
                    onGridReady,
                    doesExternalFilterPass,
                    isExternalFilterPresent,
                }}
                footer={<Typography>{t('VulnerabilityTable.footer', { itemDisplayedRowCount: itemDisplayedRowCount, totalRowCount: cves.length || 0 })}</Typography>}
            />
        </>

    );
};

export default Cves;